import * as React from 'react';
import styled from 'styled-components';
import { PlaceholderLeaf } from '$state/types/contexts';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Text } from '$ui/Flo/Text';
import { FormGrid } from '$ui/Settings/Forms/FormGrid';
import { ChannelConfig, ChannelField, Channels } from '$state/types';
import { mix } from '$ui/Flo/util';
import { SaveButton } from '$ui/Flo/AsyncButton';
import { useAppDispatch, useAppSelector } from '$state';
import * as templates from '$state/concerns/templates';
import {
    selectPatientPlaceholders,
    selectStatus,
} from '$state/concerns/placeholders';
import { selectChannel } from '$state/concerns/channels';
import { SelectedStatus, Template } from '$state/types/templates';
import { AsyncStatus } from '$types';
import { useTemplateParams } from '$ui/Templates/hooks';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router';
import { spliceAtCursor, useFocusedField } from '@/utils/fields';
import { ConfirmDeleteButton } from '$ui/Flo/Button/ConfirmDeleteButton';

const SAVED_TO_INIT_DELAY_MS = 2000;

export const ConnectedTemplateEditor = () => {
    const history = useHistory();
    const { templateId, channelName } = useTemplateParams();
    const dispatch = useAppDispatch();
    const selectedStatus = useAppSelector(templates.selectSelectedState);
    const templateStatus = useAppSelector(templates.selectStatus);
    const template = templateId
        ? useAppSelector(templates.selectTemplate(templateId))
        : undefined;
    const channel = useAppSelector(
        selectChannel(template?.channel ?? channelName ?? Channels.Email),
    );
    const placeholders = useAppSelector(selectPatientPlaceholders);
    const placeholderStatus = useAppSelector(selectStatus);

    const emptyTemplate: Template = {
        id: uuid(),
        name: '',
        subject: '',
        body: '',
        channel: channel.channel,
        approval: false,
        status: 'active',
        reason: null,
    };

    const [draft, setDraft] = React.useState<Template>(
        template ?? emptyTemplate,
    );

    React.useEffect(() => {
        setDraft(template ?? emptyTemplate);
    }, [templateId, channelName, templateStatus]);

    React.useEffect(() => {
        if (selectedStatus === 'deleted') {
            history.push(`/settings/templates/${channelName}`);
            dispatch(templates.complete());
            return;
        }

        if (selectedStatus !== 'saved') {
            return;
        }

        const timeoutId = setTimeout(() => {
            history.push(`/settings/templates/${channelName}/${draft.id}`);
            dispatch(templates.complete());
        }, SAVED_TO_INIT_DELAY_MS);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [selectedStatus]);

    return (
        <TemplateEditor
            status={selectedStatus}
            template={draft}
            channel={channel}
            placeholderStatus={placeholderStatus}
            placeholders={placeholders}
            onChange={(template: Template) => setDraft(template)}
            onSave={() => dispatch(templates.saveTemplate(draft))}
            onDelete={() => {
                dispatch(templates.deleteTemplate(draft));
            }}
        />
    );
};

interface TemplateEditorProps {
    status: SelectedStatus;
    template: Template;
    channel: ChannelConfig;
    placeholderStatus: AsyncStatus;
    placeholders: PlaceholderLeaf[];
    onChange: (template: Template) => void;
    onSave: () => void;
    onDelete: () => void;
}

export const TemplateEditor = (props: TemplateEditorProps) => {
    const {
        status,
        template,
        channel,
        placeholderStatus,
        placeholders,
        onSave,
        onDelete,
    } = props;

    const fields: ChannelField[] = channel.fields;

    const refs = {
        subject: React.useRef<HTMLInputElement>(null),
        body: React.useRef<HTMLInputElement>(null),
    };

    const active = window.location.pathname.includes(template.id);

    const [focused, focusedRef, setFocus] = useFocusedField(refs);

    const disabled = status !== 'init';

    const onChange = (value: Partial<Template>) => {
        props.onChange({
            ...template,
            ...value,
        });
    };

    const inputFields = fields.map((field: ChannelField) => {
        const name = field.name === 'subject' ? 'subject' : 'body';

        return (
            <FloatingTextField
                {...field}
                disabled={disabled}
                key={field.placeholder}
                label={field.placeholder}
                value={template[name] ?? ''}
                onChange={(value) => {
                    onChange({ [name]: value });
                }}
                ref={refs[name]}
                onFocus={setFocus(name)}
            />
        );
    });

    const insertTag = (tag: string) => {
        if (!focused) {
            return;
        }

        const spliced = spliceAtCursor(tag, focusedRef);

        if (!spliced) {
            return;
        }

        onChange({
            [focused]: spliced,
        });

        focusedRef?.current?.focus();
    };

    React.useLayoutEffect(() => {
        const { current } = refs.body;

        if (!current) {
            return;
        }

        if (current.type === 'textarea' && current.value) {
            current.style.height = 'auto';
            current.style.height = current.scrollHeight + `px`;
        }
    }, [template.body]);

    return (
        <>
            <FormGrid>
                <FloatingTextField
                    key="templateName"
                    disabled={disabled}
                    label="Template Name"
                    value={template.name ?? ''}
                    onChange={(v) => onChange({ name: v })}
                />

                {inputFields}

                <PlaceholdersContainer
                    status={placeholderStatus}
                    placeholders={placeholders}
                    onPlaceholderClicked={insertTag}
                />
            </FormGrid>
            <ActionBar>
                <SaveButton
                    label="Save Template"
                    disabled={disabled}
                    doing={status === 'saving'}
                    done={status === 'saved'}
                    size="medium"
                    onClick={onSave}
                />
                {active && (
                    <ConfirmDeleteButton onDelete={onDelete} status={status} />
                )}
            </ActionBar>
        </>
    );
};

interface PlaceholdersContainerProps {
    status: AsyncStatus;
    placeholders: PlaceholderLeaf[];
    onPlaceholderClicked: (tag: string) => void;
}

const PlaceholdersContainer = (props: PlaceholdersContainerProps) => {
    const { status, placeholders, onPlaceholderClicked } = props;

    if (placeholders.length < 1) {
        return null;
    }

    if (status !== 'loaded') {
        return null;
    }

    const pills = placeholders.flatMap((type) => (
        <Tag
            onClick={() => onPlaceholderClicked(type.value)}
            {...type}
            key={type.name}
        >
            {type.name}
        </Tag>
    ));

    return (
        <>
            <TagBarLabel>
                <Text level="small" profile="body" bold>
                    <span>Placeholders</span>
                </Text>
            </TagBarLabel>
            <TagBar>{pills}</TagBar>
        </>
    );
};

const ActionBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${mix.bg({ hue: 'grey', shade: '10' })};
    ${mix.padding({ padding: 4 })};
`;

const TagBarLabel = styled.div`
    ${mix.margin({ margin: [1, 0, 0] })};
`;

const TagBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${mix.unit({ size: 1 })};
    align-items: center;
`;

const Tag = styled.div`
    display: flex;
    user-select: none;
    ${mix.padding({ padding: 1 })};
    ${mix.bg({ hue: 'primary', shade: '10' })};
    ${mix.type({ level: 'body2', bold: false })};
    ${mix.round({ rounded: true })};

    &:hover {
        cursor: pointer;
    }
`;
