import { mix } from '$ui/Flo/util';
import { Text } from '$ui/Flo/Text';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Toggle } from '$ui/Flo/Toggle';
import { Button } from '$ui/Flo/Button';
import { Shadow } from '$ui/Flo/Shadow';
import { RequiresCustomisation  } from './RequiresCustomisation';
interface Props {
    name: string;
    enabled: boolean;
    onView: () => void;
    onEnable: (state: boolean) => void;
    loading?: boolean;
    requiresCustomisation?: boolean;
}

export const Workflow = (props: Props) => {
    const { name, enabled, onEnable, onView, loading, requiresCustomisation = false } = props;

    return (
        <Container requiresCustomisation={requiresCustomisation}>
            {!loading ? (
                <Text level="button" profile="body">
                    {name.toUpperCase()}
                </Text>
            ) : (
                <Shadow rounded width={Math.random() * 20 + 20} height={3} />
            )}
            <Controls>
                <RequiresCustomisation show={requiresCustomisation} />
                <Toggle
                    enabled={enabled}
                    onChange={onEnable}
                    disabled={loading}
                />
                <Spacer />
                <Button
                    mode="outline"
                    rounded
                    size="small"
                    align="center"
                    onClick={onView}
                    disabled={loading}
                >
                    View
                </Button>
            </Controls>
        </Container>
    );
};

const Container = styled.div<{requiresCustomisation?: boolean}>`
    background: white;
    ${mix.round({ rounded: true })}
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    ${mix.padding({ padding: [2, 3] })}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${({requiresCustomisation}) =>
        requiresCustomisation &&
        css`
            border: 1px solid ${mix.palette({ hue: 'red', shade: '4' })};
        `
    };
`;

const Spacer = styled.span`
    width: ${mix.unit({ size: 3 })};
`;

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
`;
