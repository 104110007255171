import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { IntegrationMode } from '$ui/Integrations/types';
import { Shadow } from '$ui/Flo/Shadow';
import { BackButton } from '$ui/Integrations/Elements';

export interface HeaderProps {
    integration: string;
    mode: IntegrationMode;
    onExit: () => void;
    loading?: boolean;
}

export const Header = ({
    integration,
    mode,
    onExit,
    loading = false
}: HeaderProps) => {
    const subtitle = loading ? (
        <SubtitleShadow rounded width="6.25" height={1.75} />
    ) : (
        mode
    );

    return (
        <Container>
            <BackButtonContainer>
                <BackButton onExit={onExit} />
            </BackButtonContainer>
            <Title>
                {integration}
                <Subtitle>/ {subtitle}</Subtitle>
            </Title>
        </Container>
    );
};

const SubtitleShadow = styled(Shadow)`
    vertical-align: bottom;
`;

const Container = styled.div`
    ${mix.padding({ padding: 2 })};
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const BackButtonContainer = styled.div`
    position: absolute;
    left: 0;
`;

const Title = styled.div`
    ${mix.type({ level: 'body1' })};
    ${mix.color({ profile: 'title' })};
    ${mix.gap({ size: 1 })};
    display: inline-flex;
    align-items: baseline;
    line-height: 1;
`;

const Subtitle = styled.div`
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'secondary' })};
    line-height: 1;
    text-transform: capitalize;
`;
