import {
    changeSegments,
    createSegmentedTemplate,
    deleteSegmentedTemplate
} from '$api/automations';
import { loadAction, updateEmailMetadata } from '$state/concerns/automations';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import {
    editingTemplate,
    showTemplateEditor
} from '$state/queries/automations';
import {
    Action,
    CommTemplate,
    EmailTemplate,
    LoadedAction,
    LoadedEmail,
    LoadedSMS,
    SegmentedTemplate,
    SMSTemplate,
    UnloadedAction
} from '$state/types/automations';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Icon } from '$ui/Flo/Icon';
import { Text } from '$ui/Flo/Text';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { SegmentEditor } from './SegmentEditor/SegmentEditor';

interface Props {
    action: Action;
    selectedTab: string | null;
    onTabChange: (templateId: string) => void;
    types: string[];
}

export const TemplateHeader = (props: Props) => {
    const dispatch = useAppDispatch();

    const { action, selectedTab, onTabChange, types } = props;
    const [expanded, setExpanded] = React.useState(false);

    if (!action.loaded) {
        return <LoadingHeader action={action} />;
    }

    const findTemplate = ((action: LoadedAction) => {
        const templateInd = action.templates.findIndex(
            (t: SegmentedTemplate) => t.id == selectedTab
        );
        return action.templates[templateInd];
    }) as ((action: LoadedEmail) => SegmentedTemplate<EmailTemplate>) &
        ((action: LoadedSMS) => SegmentedTemplate<SMSTemplate>);

    const usedTypes = new Set(
        action.templates.flatMap((t: SegmentedTemplate) => t.types)
    );

    const selectableTypes = types.filter((type: string) => type !== 'General');

    const tabs = action.templates.map((t: SegmentedTemplate) => {
        const checklistItems = selectableTypes.map((name: string) => ({
            name,
            checked: usedTypes.has(name) && Boolean(t.types?.includes(name)),
            disabled: usedTypes.has(name) && Boolean(!t.types?.includes(name))
        }));

        return (
            <Tab
                selected={t.id === selectedTab}
                key={t.id}
                onClick={() => onTabChange(t.id)}
            >
                <TabLabel>{tabName(t)}</TabLabel>
                {t.types && action.action === 'email' && (
                    <SegmentEditor
                        mode="edit"
                        segments={checklistItems}
                        saveFn={(segments: string[]) =>
                            changeSegments(action.id, t.id, segments)
                        }
                        deleteFn={() =>
                            deleteSegmentedTemplate(action.id, t.id)
                        }
                        onDone={() =>
                            dispatch(loadAction({ action: action.id }))
                        }
                    />
                )}
            </Tab>
        );
    });

    const addChecklistItems = selectableTypes.map((name: string) => ({
        name,
        checked: usedTypes.has(name),
        disabled: usedTypes.has(name)
    }));

    return (
        <Container>
            <Tabs>
                {action.action === 'email' && (
                    <SegmentEditor
                        mode="add"
                        segments={addChecklistItems}
                        saveFn={(segments: string[]) =>
                            createSegmentedTemplate(action.id, segments)
                        }
                        deleteFn={() => Promise.resolve()}
                        onDone={() =>
                            dispatch(loadAction({ action: action.id }))
                        }
                    />
                )}
                {tabs}
            </Tabs>
            {action.action == 'email' && (
                <EmailMetadata
                    expanded={expanded}
                    action={action}
                    template={findTemplate(action)}
                />
            )}
            {action.action == 'sms' && (
                <SMSMetadata
                    expanded={false}
                    action={action}
                    template={findTemplate(action)}
                />
            )}
            {action.action == 'email' && (
                <MoreBar onClick={() => setExpanded(!expanded)}>
                    <MoreButton expanded={expanded} />
                </MoreBar>
            )}
        </Container>
    );
};

const Container = styled.div`
    background: white;
    width: 100%;
    position: relative;
    flex: 0 1 auto;
    ${mix.shadow()}
`;

const Metadata = styled.div<{ expanded?: boolean }>`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ${mix.unit({ size: 3 })};
    grid-row-gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [2, 4] })};
    box-sizing: content-box;
    max-height: 63px;
    height: auto;
    overflow: hidden;
    transition: max-height 300ms ease-out;

    ${({ expanded }) =>
        expanded &&
        css`
            max-height: 200px;
        `}
`;

interface MetadataProps<
    A extends LoadedEmail | LoadedSMS,
    T extends CommTemplate
> {
    expanded: boolean;
    action?: A;
    template?: SegmentedTemplate<T>;
    loading?: boolean;
}

const SMSMetadata = (props: MetadataProps<LoadedSMS, SMSTemplate>) => {
    const { expanded, action, loading } = props;
    const onChangeMetadata = (_: string) => () => null;
    return (
        <Metadata expanded={loading ? false : expanded}>
            <FloatingTextField
                label="Name"
                value={action?.name ?? ''}
                disabled
                onChange={onChangeMetadata('name')}
                loading={loading}
            />
            <FloatingTextField
                label="To"
                value={action?.to ?? ''}
                disabled
                onChange={onChangeMetadata('name')}
                loading={loading}
            />
            <FloatingTextField
                label="From"
                value={action?.from ?? ''}
                disabled
                onChange={onChangeMetadata('name')}
                loading={loading}
            />
        </Metadata>
    );
};

const EmailMetadata = (props: MetadataProps<LoadedEmail, EmailTemplate>) => {
    const { expanded, action, template, loading } = props;

    const dispatch = useAppDispatch();
    const { status } = useAppSelector(editingTemplate);

    const onChangeMetadata = (field: string) => (value: string) => {
        if (!template || !action) {
            return;
        }

        dispatch(
            updateEmailMetadata({
                actionId: action.id,
                templateId: template.id,
                [field]: value
            })
        );
    };

    return (
        <Metadata expanded={loading ? false : expanded}>
            <FloatingTextField
                label="Name"
                value={action?.name ?? ''}
                disabled
                onChange={onChangeMetadata('name')}
                loading={loading}
            />
            <FloatingTextField
                label="Subject"
                value={template?.template.subject ?? ''}
                disabled={!showTemplateEditor(status)}
                onChange={onChangeMetadata('subject')}
                loading={loading}
            />
            <FloatingTextField
                label="To"
                value={action?.to ?? ''}
                disabled
                onChange={onChangeMetadata('to')}
                loading={loading}
            />
            <FloatingTextField
                label="From"
                value={action?.from ?? ''}
                disabled
                onChange={onChangeMetadata('from')}
                loading={loading}
            />
            <FloatingTextField
                label="Reply To"
                value={action?.replyTo || ''}
                disabled
                onChange={onChangeMetadata('replyTo')}
                loading={loading}
            />
            <FloatingTextField
                label="Preview"
                value={template?.template.preview ?? ''}
                disabled={!showTemplateEditor(status)}
                onChange={onChangeMetadata('preview')}
                loading={loading}
            />
        </Metadata>
    );
};

const MoreBar = styled.div`
    width: 100%;
    ${mix.padding({ padding: [1] })}
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
`;

const MoreButton = (props: { expanded?: boolean }) => {
    const { expanded } = props;

    const icon = expanded ? 'ChevronsUp' : 'ChevronsDown';

    return (
        <>
            <Icon icon={icon} clickable />
            <Spacer />
            <Text level="small" profile="secondary">
                {expanded ? 'Less' : 'More'}
            </Text>
            <Spacer />
            <Icon icon={icon} clickable />
        </>
    );
};

const Spacer = styled.span`
    width: ${mix.unit({ size: 1 })};
`;

const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-evenly;
    ${mix.bg({ hue: 'grey', shade: '9' })};
    ${mix.type({ level: 'body1' })};
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
`;

const Tab = styled.div<{ selected?: boolean }>`
    ${mix.type({ level: 'body2' })};
    min-width: ${mix.unit({ size: 15 })};
    text-align: center;
    flex: 1;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ selected }) =>
        !selected &&
        css`
            &::after {
                content: '';
                height: 60%;
                position: absolute;
                right: -1px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                ${mix.bg({ hue: 'grey', shade: '7' })}
            }
        `}

    ${({ selected }) =>
        selected &&
        css`
            cursor: initial;
            background: white;
        `}
`;

const TabLabel = styled.div`
    ${mix.padding({ padding: [1, 2] })};
    text-align: center;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const tabName = (template: SegmentedTemplate) => {
    if (template.name) {
        return template.name;
    }

    if (!template.types) {
        return 'Default Template';
    }

    return template.types.join(', ');
};

const LoadingHeader = (props: { action: UnloadedAction }) => {
    const tabs = (
        <Tabs>
            <Tab selected={true} key="hello" onClick={() => null}>
                <TabLabel>Default Template</TabLabel>
            </Tab>
        </Tabs>
    );

    return (
        <Container>
            {tabs}

            {props.action.action == 'email' && (
                <>
                    <EmailMetadata expanded={false} loading />
                    <MoreBar onClick={() => null}>
                        <MoreButton expanded={false} />
                    </MoreBar>
                </>
            )}

            {props.action.action == 'sms' && (
                <SMSMetadata expanded={false} loading />
            )}
        </Container>
    );
};
