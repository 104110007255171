import React from 'react';
import styled from 'styled-components';
import { Badge } from '../Badge';
import { mix } from '../util';

interface CategoryProps {
    title: string;
    count: number;
    children?: React.ReactNode;
}

export const Category = ({ title, count, children }: CategoryProps) => {
    if (count === 0) {
        return null;
    }

    return (
        <Container>
            <HeadingContainer>
                <ActionSubHeading>{title}</ActionSubHeading>
                <Badge size={1} count={count} color="var(--gray-400)" />
            </HeadingContainer>
            <Items>{children}</Items>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 2 })};
`;

const ActionSubHeading = styled.h2`
    font-size: 28px;
    font-weight: 400;
    color: var(--gray-600);
    line-height: 36px;
    margin: 0;
`;

const Items = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })}
`;

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;
