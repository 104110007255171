import { useAppDispatch, useAppSelector } from '$state';
import { editTemplate, saveTemplate } from '$state/concerns/automations';
import {
    editingTemplate,
    showTemplateEditor,
    viewingAction
} from '$state/queries/automations';
import {
    Action,
    CommTemplate,
    EmailTemplate,
    LoadedEmail,
    LoadedSMS,
    SegmentedTemplate,
    SMSTemplate
} from '$state/types/automations';
import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import styled from 'styled-components';
import { EmailViewer } from './EmailViewer';
import { SMSViewer } from './SMSViewer';
import { TemplateHeader } from './TemplateHeader';
import { Edit, EmailEditor } from './EmailEditor';
import { types as selectTypes } from '$state/queries/client';
import { ConfirmationModal } from '$ui/Flo/ConfirmationModal';

interface Props {
    onClose: () => void;
}

export const TemplateViewer = (props: Props) => {
    const { onClose } = props;

    const action = useAppSelector(viewingAction);
    const editing = useAppSelector(editingTemplate);
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<null | string>(null);
    const [draftTemplate, setDraftTemplate] =
        React.useState<null | CommTemplate>(null);
    const dispatch = useAppDispatch();
    const types = useAppSelector(selectTypes);

    React.useEffect(() => {
        if (!action?.loaded) {
            return;
        }

        const template = findTemplate(action, selectedTab);

        if (!template) {
            // Sets the default initial tab, which is the first template
            setSelectedTab(action.templates[0].id);
        }
    }, [action, selectedTab]);

    const save = (
        actionId: string,
        templateId: string,
        template: CommTemplate
    ) => {
        dispatch(
            saveTemplate({
                templateId,
                action: actionId,
                template
            })
        );
        setModalOpen(false);
    };

    const confirmSave = (
        actionId: string,
        templateId: string,
        template: CommTemplate,
        requiresCustomisation: boolean
    ) => {
        if (requiresCustomisation) {
            setModalOpen(true);
            setDraftTemplate(template);
            return;
        }

        save(actionId, templateId, template);
    };

    const finishEditing = (finalise: boolean) => {
        if (!draftTemplate || action?.action !== 'email') {
            return;
        }

        save(
            action.id,
            selectedTab as string,
            {
                ...draftTemplate,
                finished_customising: finalise
            } as EmailTemplate
        );
    };

    if (!action) {
        onClose();
        return null;
    }

    return (
        <>
            <Container>
                <TemplateHeader
                    action={action}
                    onTabChange={(id) =>
                        !showTemplateEditor(editing.status) &&
                        setSelectedTab(id)
                    }
                    selectedTab={selectedTab}
                    types={types}
                />
                {action.action == 'email' &&
                    showTemplateEditor(editing.status) && (
                        <EmailEditor
                            key={selectedTab}
                            email={findTemplate(action, selectedTab)!}
                            requiresCustomisation={action.requiresCustomisation}
                            state={editing.status}
                            onSave={(
                                template: CommTemplate,
                                requiresCustomisation: boolean
                            ) => {
                                confirmSave(
                                    action.id,
                                    selectedTab as string,
                                    template,
                                    requiresCustomisation
                                );
                            }}
                        />
                    )}
                {action.action == 'email' &&
                    !showTemplateEditor(editing.status) && (
                        <>
                            <EmailViewer
                                email={findTemplate(action, selectedTab)!}
                            />
                            <Edit>
                                <Button
                                    rounded
                                    icon="Edit2"
                                    size="medium"
                                    disabled={!action?.loaded}
                                    onClick={() =>
                                        dispatch(
                                            editTemplate({
                                                templateId:
                                                    selectedTab as string
                                            })
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                            </Edit>
                        </>
                    )}
                {action.action == 'sms' && (
                    <SMSViewer sms={findTemplate(action, selectedTab)!} />
                )}
            </Container>
            {modalOpen && (
                <ConfirmationModal
                    icon={{ name: 'CheckCircle', hue: 'primary' }}
                    title="Finalise the email?"
                    positiveButtonLabel="Yes, finalise this email"
                    neutralButtonLabel="No, make more changes"
                    onConfirm={() => finishEditing(true)}
                    onCancel={() => finishEditing(false)}
                >
                    This email requires customisation - is this now complete?
                </ConfirmationModal>
            )}
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
`;

const findTemplate = ((action: Action, selectedTab: string | null) => {
    if (!action?.loaded || !selectedTab) {
        return null;
    }

    const index = action.templates.findIndex(
        (t: SegmentedTemplate) => t.id == selectedTab
    );

    return action.templates[index]?.template;
}) as ((action: LoadedEmail, selectedTab: string | null) => EmailTemplate) &
    ((action: LoadedSMS, selectedTab: string | null) => SMSTemplate) &
    ((action: Action, selectedTab: string | null) => null);
