import {
    Action,
    AutomationsLoadedState,
    AutomationsState,
    AutomationsStatus,
    LoadedWorkflow,
    TemplateStatus,
    Workflow
} from '$state/types/automations';
import { RootState } from '..';

export const automationsState = (state: RootState): AutomationsState =>
    state.automations;

export const isLoaded = (
    state: AutomationsState
): state is AutomationsLoadedState => state.status == AutomationsStatus.LOADED;

export const workflows = (root: RootState) => {
    const state = automationsState(root);
    if (!isLoaded(state)) {
        return [];
    }

    return Object.values(state.workflows);
};

export const workflowLoaded = (flow: Workflow): flow is LoadedWorkflow => {
    return flow.loaded;
};

export const workflow = (id: string) => (root: RootState) => {
    const state = automationsState(root);

    return state.workflows?.[id];
};

export const workflowNodes = (id: string) => (root: RootState) => {
    const state = automationsState(root);
    const flow = workflow(id)(root);
    if (!flow || !workflowLoaded(flow)) {
        return undefined;
    }

    return flow.nodes.map((id) => state.nodes[id]);
};

export const editorOpen = (root: RootState) => {
    return automationsState(root).editor.open;
};

export const viewingAction = (root: RootState): Action | null => {
    return automationsState(root).editor.action;
};

export const editingTemplate = (
    root: RootState
): {
    status: TemplateStatus;
    template: string | null;
} => ({
    status: automationsState(root).editor.editingStatus,
    template: automationsState(root).editor.template
});

export const showTemplateEditor = (status: TemplateStatus) => {
    return status !== TemplateStatus.IDLE;
};
