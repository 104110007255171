import React from 'react';
import { StatusContainer } from '$ui/Timeline/Parts/Attachments/Images';
import { Icon } from '$ui/Flo/Icon';

interface LoadAttachmentProps {
    onLoad: () => void;
}

export const LoadAttachment = React.forwardRef<
    HTMLDivElement,
    LoadAttachmentProps
>((props, ref) => {
    const { onLoad } = props;
    return (
        <StatusContainer ref={ref}>
            <Icon
                icon="LoadAttachmentIcon"
                size={7}
                onClick={onLoad}
                clickable
            />
        </StatusContainer>
    );
});
LoadAttachment.displayName = 'LoadAttachment';
