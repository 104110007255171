import { FlowNodeType } from '$types';
import { SelectField } from '$ui/Flo/Field';
import React from 'react';
import { Step } from './Step';
import { StepProps } from './types';

interface SourceValue {
    source: string;
}

export interface Props extends StepProps<SourceValue> {
    type: FlowNodeType.source;
    sources: ('Unknown' | string)[];
}

export const SourceStep = (props: Props) => {
    const { initial } = props;
    const onChange = (source: string) =>
        props.onChange({
            source
        });

    return (
        <Step {...props}>
            <SelectField
                label="Lead source"
                value={initial?.source || 'Unknown'}
                valueChanged={onChange}
                options={props.sources}
                editable
                padLeft={6}
                labelPortion={25}
            />
        </Step>
    );
};
