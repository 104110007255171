import { FlowNodeType } from '$types';
import { AreaField } from '$ui/Flo/Field';
import { Text } from '$ui/Flo/Text';
import React from 'react';
import { Step } from './Step';
import { StepProps } from './types';

interface NoteValue {
    note: string;
}

export interface Props extends StepProps<NoteValue> {
    type: FlowNodeType.note;
    subtitle?: string;
    placeholder?: string;
}

export const NoteStep = (props: Props) => {
    const { initial } = props;
    const onChange = (note: string) =>
        props.onChange({
            note
        });

    return (
        <Step {...props}>
            {props.subtitle && <Text level="body1">{props.subtitle}</Text>}
            <AreaField
                title="flow-custom-note"
                placeholder={
                    props.placeholder ||
                    'e.g patient cannot afford treatment right now'
                }
                initialValue={initial?.note}
                editable
                valueChanged={onChange}
                padLeft={6}
            />
        </Step>
    );
};
