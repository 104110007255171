import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileDoc = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.2H66.8L67.3 23.13C67.235 22.6294 67.0106 22.1631 66.66 21.8L45.85 1C45.7351 0.883071 45.6076 0.779114 45.47 0.69L45.37 0.63C45.2633 0.564082 45.1532 0.503993 45.04 0.45L44.86 0.4C44.7669 0.359574 44.6696 0.329398 44.57 0.31C44.4086 0.278042 44.2445 0.261302 44.08 0.26H10.68C10.017 0.26 9.38107 0.523392 8.91223 0.992233C8.44338 1.46107 8.17999 2.09696 8.17999 2.76V29.64C8.17999 30.303 8.44338 30.9389 8.91223 31.4078C9.38107 31.8766 10.017 32.14 10.68 32.14C11.343 32.14 11.9789 31.8766 12.4478 31.4078C12.9166 30.9389 13.18 30.303 13.18 29.64V5.29H41.58V23.43C41.5826 24.0905 41.8455 24.7234 42.3116 25.1914C42.7777 25.6593 43.4095 25.9247 44.07 25.93L62.33 26.03V74.44H13.18V70.57C13.18 69.907 12.9166 69.2711 12.4478 68.8022C11.9789 68.3334 11.343 68.07 10.68 68.07C10.017 68.07 9.38107 68.3334 8.91223 68.8022C8.44338 69.2711 8.17999 69.907 8.17999 70.57V76.94C8.17999 77.603 8.44338 78.2389 8.91223 78.7078C9.38107 79.1766 10.017 79.44 10.68 79.44H64.83C65.493 79.44 66.1289 79.1766 66.5978 78.7078C67.0666 78.2389 67.33 77.603 67.33 76.94V23.54C67.3297 23.426 67.3197 23.3123 67.3 23.2ZM46.58 20.94V8.83L58.76 21L46.58 20.94Z" />
            <path d="M28.87 45.56C28.4609 45.5077 28.0454 45.5742 27.6731 45.7515C27.3007 45.9288 26.9872 46.2095 26.77 46.56C26.3045 47.7002 26.1028 48.9309 26.18 50.16C26.0943 51.3626 26.293 52.5685 26.76 53.68C26.9815 54.0287 27.2972 54.3077 27.6706 54.4847C28.044 54.6616 28.4598 54.7293 28.87 54.68C29.2802 54.7293 29.696 54.6616 30.0694 54.4847C30.4427 54.3077 30.7584 54.0287 30.98 53.68C31.4511 52.5695 31.6532 51.3634 31.57 50.16C31.65 48.9298 31.4447 47.6977 30.97 46.56C30.7527 46.2095 30.4392 45.9288 30.0669 45.7515C29.6945 45.5742 29.2791 45.5077 28.87 45.56Z" />
            <path d="M17.71 46.71C17.5336 46.3825 17.2411 46.1328 16.89 46.01C16.3822 45.8521 15.8514 45.7811 15.32 45.8H13.63V54.47H15.32C15.7012 54.507 16.0858 54.4619 16.4481 54.3377C16.8103 54.2135 17.1417 54.0131 17.42 53.75C17.8787 53.1122 18.1238 52.3456 18.12 51.56C18.12 51.13 18.12 50.56 18.12 49.76C18.1253 49.1457 18.0986 48.5315 18.04 47.92C18.0055 47.4998 17.8937 47.0895 17.71 46.71Z" />
            <path d="M53.66 35.14H3.17001C2.50697 35.14 1.87109 35.4034 1.40225 35.8722C0.933406 36.3411 0.670013 36.977 0.670013 37.64V62.57C0.670013 63.233 0.933406 63.8689 1.40225 64.3378C1.87109 64.8066 2.50697 65.07 3.17001 65.07H53.66C54.3231 65.07 54.9589 64.8066 55.4278 64.3378C55.8966 63.8689 56.16 63.233 56.16 62.57V37.64C56.16 36.977 55.8966 36.3411 55.4278 35.8722C54.9589 35.4034 54.3231 35.14 53.66 35.14ZM21.19 53.07C21.0524 53.8766 20.7567 54.648 20.32 55.34C19.864 56.0327 19.1929 56.5563 18.41 56.83C17.4141 57.1589 16.3684 57.3112 15.32 57.28H10.45V43H15.32C16.334 42.9771 17.3456 43.1086 18.32 43.39C19.0771 43.6123 19.743 44.0714 20.22 44.7C20.6752 45.3325 20.989 46.0555 21.14 46.82C21.3226 47.8429 21.4064 48.881 21.39 49.92C21.4139 50.9738 21.347 52.0277 21.19 53.07ZM33.49 55.72C32.59 56.92 31.04 57.53 28.87 57.53C26.7 57.53 25.15 56.92 24.25 55.72C23.35 54.52 22.92 52.72 22.92 50.2C22.7783 48.2443 23.2427 46.2924 24.25 44.61C25.15 43.37 26.7 42.75 28.87 42.75C31.04 42.75 32.59 43.37 33.49 44.61C34.5008 46.2912 34.9687 48.2432 34.83 50.2C34.83 52.69 34.38 54.54 33.49 55.72ZM40.14 53.83C40.3872 54.1432 40.7119 54.3864 41.082 54.5355C41.4521 54.6846 41.8547 54.7344 42.25 54.68C43.4456 54.6719 44.6388 54.575 45.82 54.39H46.01L46.08 57H45.94C44.6443 57.3009 43.3201 57.4619 41.99 57.48C41.0198 57.5161 40.0519 57.3633 39.14 57.03C38.4257 56.7448 37.8201 56.2407 37.41 55.59C36.9849 54.8916 36.6997 54.1172 36.57 53.31C36.4021 52.2451 36.3252 51.1679 36.34 50.09C36.34 47.33 36.72 45.46 37.51 44.36C38.3 43.26 39.76 42.7 41.85 42.7C43.2519 42.7384 44.6446 42.9397 46 43.3H46.14L46 45.93H45.81C43.19 45.53 41.36 45.51 40.64 45.93C40.2283 46.2346 39.9491 46.6856 39.86 47.19C39.6381 48.3022 39.5475 49.4367 39.59 50.57C39.5052 51.6852 39.694 52.8043 40.14 53.83Z" />
        </svg>
    );
};
