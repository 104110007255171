import React, { CSSProperties } from 'react';
import { InboxItem, InboxItemState } from './InboxItem';
import { Conversation } from '$state/types/inbox';
import { CommType } from '$types/patient';
import { throttle, memoize } from '@/utils/fn';
import { FixedSizeList, ListOnScrollProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { InboxChannelFilter } from '$types/app';

const SCROLL_THROTTLE_MS = 300;
const MSG_HEIGHT_PX = 60;
// 10 times the height of a message
const SCROLL_THRESHOLD_COEEF = 10;
const SCROLL_THRESHOLD_PX = MSG_HEIGHT_PX * SCROLL_THRESHOLD_COEEF;

interface InboxProps {
    onSelect: (conv: Conversation) => void; // to API for fetching conversation and setting to Read
    conversations: Conversation[]; // list of conversations to display
    selected?: string; // default selected conversation
    checked?: string[]; // default checked conversations
    onCheck: (id: string) => void;
    onUncheck: (id: string) => void;
    onMarkAsRead: (id: string) => void;
    onMarkAsUnread: (id: string) => void;
    onStarred: (id: string) => void;
    onUnstarred: (id: string) => void;
    loading?: boolean;
    loadingCount?: number;
    expanded: boolean;
    onScroll?: (props: ListOnScrollProps) => void;
    token?: string | null;
    loadingNext?: boolean;
    fetchNextPage: (token: string) => void;
    channel?: InboxChannelFilter;
}

const getConvoState = memoize(
    (conversation: Conversation, selected): InboxItemState => {
        if (selected && conversation.patient_id === selected) {
            if (conversation.unread_count > 0) {
                return {
                    type: 'open-unread',
                    count: conversation.unread_count,
                };
            }

            return {
                type: 'open',
            };
        }

        if (conversation.unread) {
            return {
                type: 'unread',
                count: conversation.unread_count,
            };
        }

        return {
            type: 'read',
        };
    },
);

export const InboxList = (props: InboxProps) => {
    const {
        onSelect,
        onCheck,
        onUncheck,
        onMarkAsRead,
        onMarkAsUnread,
        onStarred,
        onUnstarred,
        checked = [],
        selected = '',
        conversations,
        loading = false,
        loadingCount = 3,
        expanded = true,
        token,
        loadingNext = false,
        fetchNextPage,
    } = props;
    const innerRef = React.useRef();
    const outerRef = React.useRef();

    const onScroll = throttle(
        { interval: SCROLL_THROTTLE_MS },
        ({ scrollOffset }: ListOnScrollProps) => {
            const { current: target } = innerRef;
            const { current: outer } = outerRef;
            if (!target || !outer) {
                return;
            }
            if (loadingNext || !token) {
                return;
            }
            // due to the way fixed list works, we need to get the clientHeight of the inner div
            // and the offsetHeight of the outer div to calculate the scrollBottom
            const { clientHeight: clientHeightInner } = target;
            const { offsetHeight: offsetHeightOuter } = outer;
            // scrollBottom is the distance from the bottom
            const scrollBottom =
                clientHeightInner - offsetHeightOuter - scrollOffset;
            // scrollThreshold is the distance from the bottom at which we should fetch the next page
            const scrollThreshold = SCROLL_THRESHOLD_PX;

            if (scrollBottom <= scrollThreshold) {
                fetchNextPage(token);
            }
        },
    );

    const handleSelected = React.useCallback(
        (convo: Conversation) => {
            onSelect(convo);
        },
        [onSelect],
    );

    if (loading) {
        const items = [...Array(loadingCount)].map((_, i) => {
            return <LoadingItem key={i} />;
        });

        return <>{items}</>;
    }

    const items = conversations.map((conversation, i) => {
        return (
            <InboxItem
                conversation={conversation}
                key={conversation.patient_id}
                state={getConvoState(conversation, selected)}
                onClick={handleSelected}
                selected={checked.includes(conversation.patient_id)}
                expanded={expanded}
                onCheck={onCheck}
                onUncheck={onUncheck}
                onMarkAsRead={onMarkAsRead}
                onMarkAsUnread={onMarkAsUnread}
                onStarred={onStarred}
                onUnstarred={onUnstarred}
                hoveredMenuPosition={i === 0 ? 'bottom' : 'top'}
            />
        );
    });

    loadingNext && items.push(<LoadingItem key="loading" />);

    const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
        return (
            <div style={style} key={index} data-key={index}>
                {items[index]}
            </div>
        );
    };
    Row.displayName = 'ListRow';

    return (
        <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
                <FixedSizeList
                    innerRef={innerRef}
                    outerRef={outerRef}
                    height={height}
                    itemCount={items.length}
                    itemSize={60}
                    width={width}
                    onScroll={onScroll}
                >
                    {Row}
                </FixedSizeList>
            )}
        </AutoSizer>
    );
};

const LoadingItem = () => {
    return (
        <InboxItem
            conversation={{
                patient_id: '',
                first_name: '',
                last_name: '',
                snippet: '',
                last_message_at: '',
                unread: false,
                unread_count: 0,
                starred: false,
                snoozed: false,
                channel: CommType.Email,
                last_message_inbound: false,
            }}
            state={{ type: 'loading' }}
            onClick={() => null}
            selected={false}
            expanded={false}
            onCheck={() => null}
            onUncheck={() => null}
            onMarkAsRead={() => null}
            onMarkAsUnread={() => null}
            onStarred={() => null}
            onUnstarred={() => null}
        />
    );
};
