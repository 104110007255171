import { Text } from '$ui/Flo/Text';
import { dates } from '$utils';
import React from 'react';

interface Props {
    stage: string | null;
    date?: string;
}

export const CurrentStatus = ({ stage, date }: Props) => {
    const shouldRenderDate = date && stage && !stagesWithoutDue.includes(stage);

    const text = stage ? stages[stage] : 'Patient not in pipeline';

    return (
        <>
            <Text level="body1">{text}</Text>
            {shouldRenderDate && (
                <Text level="body2">due by {dates.long(date)}</Text>
            )}
        </>
    );
};

const stagesWithoutDue = [
    'maybeFuture',
    'lost',
    'complete',
    'wrongNumber',
    'noNumber',
    'didNotAttend',
    'newPatient',
    'sandbox'
];

const stages = {
    newLead: 'Make contact as soon as possible',
    callback1: 'Attempt contact a second time',
    callback2: 'Attempt contact a third time',
    callback3: 'Attempt contact a final time',
    working: 'Discuss a possible consultation',
    thinking: 'Discuss possible treatment',
    consultation: 'Record the consultation outcome',
    txPlanConsult: 'Record the treatment plan consultation outcome',
    inTx: 'Record treatment progress',
    maybeFuture: 'Not interested right now',
    lost: 'Never contact this patient',
    complete: 'Treatment completed',
    wrongNumber: 'Provided an incorrect phone number',
    noNumber: 'Did not provide a phone number',
    didNotAttend: 'Did not attend',
    newPatient: 'Move to the relevant stage',
    sandbox: 'Reactivate this patient',
    wonSale: 'Sale completed',
    blocked: 'This patient is blocked'
};
