import * as React from 'react';
import { Workflow } from '$ui/Automations/Workflow';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { enableWorkflow, loadAutomations } from '$state/concerns/automations';
import {
    automationsState,
    isLoaded,
    workflows
} from '$state/queries/automations';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Heading } from '$ui/Flo/Heading';
import { AutomationsStatus } from '$state/types/automations';
import { FailIcecream } from '$ui/Flo/Fail';
import { useHistory } from 'react-router';
import { Container } from './Elements';
import { Icon } from '$ui/Flo/Icon';
import { usePageTitle } from '@/utils/hooks';

export const Automations = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(automationsState);
    const loaded = isLoaded(state);
    const flows = useAppSelector(workflows);
    const history = useHistory();

    usePageTitle('Automations');

    React.useEffect(() => {
        dispatch(loadAutomations());
    }, []);

    if (state.status === AutomationsStatus.ERROR) {
        return <FailIcecream />;
    }

    const onEnable = (id: string) => (state: boolean) => {
        dispatch(
            enableWorkflow({
                id,
                enabled: state
            })
        );
    };

    let items;
    if (loaded) {
        items = flows.map((flow) => (
            <Spacer key={flow.id}>
                <Workflow
                    name={flow.name}
                    enabled={flow.enabled}
                    onView={() => history.push(`/automations/${flow.id}`)}
                    onEnable={onEnable(flow.id)}
                    requiresCustomisation={flow.requiresCustomisation}
                />
            </Spacer>
        ));
    } else {
        items = Array(9).fill(
            <Spacer>
                <Workflow
                    name=""
                    enabled={true}
                    onView={() => null}
                    onEnable={() => null}
                    loading={true}
                    requiresCustomisation={false}
                />
            </Spacer>
        );
    }

    return (
        <Container>
            <Heading level="h3" align="left" vSpace={2}>
                Automations
            </Heading>
            {items.length > 0 ? (
                items
            ) : (
                <Empty>
                    <Icon icon="Zap" size={8} opacity={1} hue="yellow" />
                    <Heading level="h3" profile="body">
                        You have no automations yet
                    </Heading>
                </Empty>
            )}
        </Container>
    );
};

const Spacer = styled.div`
    ${mix.margin({ margin: [0, 0, 2, 0] })}
`;

const Empty = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5%;
    height: 25vh;
`;
