import styled, { css, keyframes } from 'styled-components';
import { Hue, Shade } from '../types';
import { applyRoundedCorners, mix, palette, scale } from '../util';

interface Props {
    rounded?: boolean;
    width?: number | string;
    height?: number;
    hue?: Hue;
    shade?: Shade;
    block?: boolean;
}

const shine = keyframes`
    0% {
        background-position: right;
    }
    100% {
        background-position: left;
    }
`;

export const Shadow = styled.div<Props>`
    ${applyRoundedCorners}

    display: inline-block;
    ${({ block }) =>
        block &&
        css`
            display: block;
        `};

    height: ${({ theme, height }) =>
        height ? scale(theme, height) + 'px' : 'auto'};

    ${({ width }) => {
        // TODO: refactor this so it's permissive in the units it allows
        if (typeof width === 'string') {
            return css`
                width: calc(${width} * ${mix.unit({ size: 1 })});
            `;
        }

        return css`
            width: ${width ?? 100}%;
        `;
    }};

    background: linear-gradient(
            90deg,
            #0000 33%,
            rgba(255, 255, 255, 0.3) 50%,
            #0000 66%
        )
        ${({ theme, hue, shade }) =>
            palette({ theme, hue: hue ?? 'grey', shade: shade ?? '6' })};
    background-size: 300% 100%;
    animation: ${shine} 1.25s infinite;
`;
