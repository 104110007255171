import { api } from '$api/api';
import { AsyncStatus } from '$types';
import {
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';
import { z } from 'zod';
import { RootState } from '$state';

const Source = z.object({
    name: z.string(),
    custom: z.boolean(),
    count: z.number().int().gte(0)
});

export type Source = z.infer<typeof Source>;

const SourceList = z.array(Source).nonempty();

export type SourceList = z.infer<typeof SourceList>;

interface BaseState {
    status: AsyncStatus;
}

interface DefaultState extends BaseState {
    status: 'init';
}

interface LoadingState extends BaseState {
    status: 'loading';
}

interface LoadedState extends BaseState {
    status: 'loaded';
    sources: SourceList;
}

interface ErroredState extends BaseState {
    status: 'errored';
    error: string;
}

export type SourceState =
    | DefaultState
    | LoadingState
    | LoadedState
    | ErroredState;

// State

export const listSources = createAsyncThunk<SourceList>('sources/list', () =>
    api
        .get('/v3/clients/settings/sources')
        .then(({ data }) => SourceList.parse(data))
);

export const addSource = createAsyncThunk<void, string>(
    'sources/add',
    async (name: string) => {
        await api.post('/v3/clients/settings/sources', { name });
    }
);

export const editSource = createAsyncThunk<
    void,
    { name: string; newName: string }
>('sources/edit', async ({ name, newName }) => {
    await api.post(`/v3/clients/settings/sources/${name}`, { name: newName });
});

export const deleteSource = createAsyncThunk<void, string>(
    'sources/delete',
    async (name: string) => {
        await api.delete(`/v3/clients/settings/sources/${name}`);
    }
);

const initialState: SourceState = {
    status: 'init'
};

export default createReducer<SourceState>(initialState, (builder) => {
    builder.addCase(listSources.pending, (state) => {
        if (state.status !== 'init') {
            return;
        }

        return { status: 'loading' };
    });

    builder.addCase(listSources.fulfilled, (_, action) => {
        return {
            status: 'loaded',
            sources: action.payload
        };
    });

    builder.addCase(listSources.rejected, (_, action) => {
        return {
            status: 'errored',
            error: action.error.message ?? 'Unknown error'
        };
    });

    builder.addCase(addSource.pending, (state, action) => {
        if (state.status !== 'loaded') {
            return;
        }

        const name = action.meta.arg;

        return {
            ...state,
            sources: [...state.sources, { name, custom: true, count: 0 }]
        };
    });

    builder.addCase(editSource.pending, (state, action) => {
        if (state.status !== 'loaded') {
            return;
        }

        const { name, newName } = action.meta.arg;

        const source = state.sources.find((source) => source.name === name);

        if (!source) {
            return;
        }

        source.name = newName;
    });

    builder.addCase(deleteSource.pending, (state, action) => {
        if (state.status !== 'loaded') {
            return;
        }

        const i = state.sources.findIndex(
            (source) => source.name === action.meta.arg
        );

        if (i === -1) {
            return;
        }

        state.sources.splice(i, 1);
    });
});

export const selectSources = (state: RootState): SourceState => state.sources;

export const selectSourceNames = createSelector(
    selectSources,
    (sources): string[] => {
        if (sources.status !== 'loaded') {
            return [];
        }

        return sources.sources.map((source) => source.name);
    }
);
