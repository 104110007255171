import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { slideInRight, slideOutLeft } from '$ui/Flo/animations';

interface SectionProps {
    title?: string;
    children: React.ReactNode;
    sectionMenu?: React.ReactNode | undefined;
    fullWidth?: boolean;
}

export const Section = ({
                            title,
                            children,
                            sectionMenu = undefined,
                            fullWidth = false
                        }: SectionProps) => {
    if (fullWidth) {
        return <FullWidthFrame>{children}</FullWidthFrame>;
    }

    if (sectionMenu) {
        return (
            <FrameWithNav>
                <SectionMenu>{sectionMenu}</SectionMenu>
                <Container>
                    {title && <Title>{title}</Title>}
                    <Content>{children}</Content>
                </Container>
            </FrameWithNav>
        );
    }

    return (
        <Frame>
            <Container>
                {title && <Title>{title}</Title>}
                <Content>{children}</Content>
            </Container>
        </Frame>
    );
};

const FullWidthFrame = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &.section-enter {
        animation: ${slideInRight} 0.3s forwards;
    }

    &.section-exit {
        animation: ${slideOutLeft} 0.3s forwards;
    }
`;

// for the purpose of animating in and out
const Frame = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &.section-enter {
        animation: ${slideInRight} 0.3s forwards;
    }

    &.section-exit {
        animation: ${slideOutLeft} 0.3s forwards;
    }
`;

const FrameWithNav = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;

    &.section-enter {
        animation: ${slideInRight} 0.3s forwards;
    }

    &.section-exit {
        animation: ${slideOutLeft} 0.3s forwards;
    }
`;

const Container = styled.div`
    max-width: ${mix.unit({ size: 90 })};
    flex: 1 1 auto;
    ${mix.padding({ padding: 6 })};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [0, 0, 6] })};
`;

const SectionMenu = styled.div`
    ${mix.margin({ margin: [0, 8, 0, 0] })};
    ${mix.padding({ padding: 2 })};
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-self: flex-start;
    align-items: stretch;
    max-width: 300px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Heading).attrs(() => ({
    level: 'h3',
    align: 'left'
}))`
    ${mix.margin({ margin: [0, 0, 2] })};
`;
