const DISALLOWED_TAGS = [
    'script',
    'iframe',
    'object',
    'embed',
    'style',
    'link',
    'frameset',
    'frame',
    'applet',
    'meta',
    'base',
    'basefont',
    'canvas'
];

/**
 * Sanitize HTML from user input so that it is safe to render in the browser
 * and in an email template (e.g. preventing XSS attacks or weird breakages).
 *
 * It does this by removing a blacklist of unsafe tags and attributes, such
 * as `<script>` tags and `onload` attributes.
 *
 * A whitelist would be safer but far less future-proof, since new tags and
 * elements are introduced by W3C at a rapid pace.
 *
 * @param {string} html
 * @return {string}
 */
export const sanitize = (html: string): string => {
    const parser = new DOMParser();

    const doc = parser.parseFromString(html, 'text/html');

    // strip all disallowed tags
    DISALLOWED_TAGS.forEach((tag) => {
        const elements = doc.getElementsByTagName(tag);

        while (elements.length > 0) {
            elements[0].parentNode?.removeChild(elements[0]);
        }
    });

    // strip all elements with an `on*` attribute
    const elements = Array.from(doc.body.querySelectorAll('*'))
        .filter((element) => element.attributes.length > 0)
        .filter((element) => {
            const attributes = Array.from(element.attributes).filter(
                (attribute) => attribute.name.startsWith('on')
            );

            return attributes.length > 0;
        });

    elements.forEach((element) => {
        const attributes = Array.from(element.attributes).filter((attribute) =>
            attribute.name.startsWith('on')
        );

        attributes.forEach((attribute) => {
            element.removeAttribute(attribute.name);
        });
    });

    // strip all href/src attributes with a javascript protocol
    const hrefElements = Array.from(doc.body.querySelectorAll('[href]'));
    const srcElements = Array.from(doc.body.querySelectorAll('[src]'));

    [...hrefElements, ...srcElements].forEach((element) => {
        const href = element.getAttribute('href');
        const src = element.getAttribute('src');

        if (href && href.startsWith('javascript:')) {
            element.removeAttribute('href');
        }

        if (src && src.startsWith('javascript:')) {
            element.removeAttribute('src');
        }
    });

    return doc.body.innerHTML.trim();
};
