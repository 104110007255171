import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Send } from '$ui/ReplyBar/Actions/Send';

export interface Props {
    children: React.ReactNode;
    onSend: () => void;
    disabled?: boolean;
}

export const Actions = (props: Props) => {
    const {
        children,
        onSend,
        disabled
    } = props;

    return <Container>
        <Left>
            {children}
        </Left>
        <Send onClick={onSend} disabled={disabled} />
    </Container>;
};

const Container = styled.div`
    width: 100%;
    ${mix.padding({ padding: [1.5, 2, 1.5, 2] })};
    display: flex;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    ${mix.gap({ size: 0.5 })};
`;
