import React from 'react';

type Ref = React.RefObject<HTMLInputElement | HTMLTextAreaElement>;

/**
 * Takes a str and a HTMLInputElement ref and splices the str inside the value
 * of the ref at the selection.
 *
 * @param str
 * @param ref
 */
export const spliceAtCursor = (str: string, ref: Ref): string | undefined => {
    if (!ref.current) {
        return;
    }

    const { value, selectionStart: start, selectionEnd: end } = ref.current;

    if (start === null || end === null) {
        return value;
    }

    return [
        value.substring(0, start ?? 0),
        str,
        value.substring(end, value.length)
    ].join('');
};

type RefMap = {
    [name: string]: Ref;
};

type SetFocusFn<T> = (focused: keyof T) => () => void;

type FocusedField<T> =
    | [keyof T, T[keyof T], SetFocusFn<T>]
    | [null, null, SetFocusFn<T>];

/**
 * Responsible for tracking the most recently focused input field in a map of
 * refs.
 */
export const useFocusedField = <T extends RefMap>(
    refMap: T
): FocusedField<typeof refMap> => {
    const [focused, setFocusedName] = React.useState<
        keyof typeof refMap | null
    >(null);

    const setFocus = (focused: keyof typeof refMap) => (): void =>
        setFocusedName(focused);

    if (focused !== null) {
        return [focused, refMap[focused], setFocus];
    }

    return [null, null, setFocus];
};
