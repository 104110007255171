import styled from 'styled-components';
import { mix } from '../util';
import React from 'react';
import { Icon } from '../Icon';

interface Props {
    header?: React.ReactNode;
    body?: React.ReactNode;
    actions?: React.ReactNode;
    onClose: () => void;
    closeOnOutsideClick?: boolean;
    noFullWidth?: boolean;
    showCloseButton?: boolean;
    children?: React.ReactNode;
}

/**
 * A modal dialog.
 *
 * Parent should handle the open/close state.
 */
export const Modal = (props: Props) => {
    const {
        children,
        onClose,
        body,
        header,
        actions,
        closeOnOutsideClick,
        noFullWidth = false,
        showCloseButton = true,
    } = props;

    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (!closeOnOutsideClick) {
                return;
            }

            if (!modalRef.current) {
                return;
            }

            if (modalRef.current.contains(event.target as Node)) {
                return;
            }

            onClose();
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [closeOnOutsideClick, onClose]);

    if (children) {
        return (
            <ModalContainer>
                <ModalContent noFullWidth={noFullWidth} ref={modalRef}>
                    {showCloseButton && (
                        <ModalClose onClick={onClose}>
                            <Icon
                                icon="X"
                                onClick={onClose}
                                clickable
                                size={3}
                            />
                        </ModalClose>
                    )}
                    {children}
                </ModalContent>
            </ModalContainer>
        );
    }

    return (
        <ModalContainer>
            <ModalContent ref={modalRef}>
                {header && <ModalHeader>{header}</ModalHeader>}
                {body && <ModalBody>{body}</ModalBody>}
                {actions && <ModalActions>{actions}</ModalActions>}

                {showCloseButton && (
                    <ModalClose onClick={onClose}>
                        <Icon icon="X" onClick={onClose} clickable size={3} />
                    </ModalClose>
                )}
            </ModalContent>
        </ModalContainer>
    );
};

export const ModalContainer = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.16);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    ${mix.padding({ padding: 4 })};
    ${mix.type({ level: 'body2' })};
    z-index: 10000;
`;

export const ModalContent = styled.div<{ noFullWidth?: boolean }>`
    width: ${(props) => (props.noFullWidth ? '50%' : 'auto')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    ${mix.padding({ padding: 3 })};
    background-color: white;
    position: relative;
    max-width: 700px;
    max-height: 700px;
    ${mix.gap({ size: 3 })};
    ${mix.shadow()};
    border-radius: 6px;
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
    ${mix.type({ level: 'body1', bold: true })};
    font-size: 20px;
    ${mix.color({ profile: 'title' })}
`;

export const ModalClose = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    ${mix.padding({ padding: 1 })};
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
`;

export const ModalActions = styled.div`
    width: 100%;
`;
