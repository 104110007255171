import {
    selectQuery,
    selectReportOptions,
    updateQuery
} from '$state/concerns/patient-filter';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { ConnectedFilter } from '$ui/PatientFilter/ConnectedFilter';
import { ConnectedFilterV2 } from '$ui/PatientFilterV2/ConnectedFilterV2';
import { Toolbar } from '$ui/PatientFilter/Toolbar';
import React from 'react';
import { Selector } from './Selector';
import { withState } from '$state';
import { featureEnabled } from '$state/queries/features';

interface Props {
    run: () => void;
    runExport: () => void;
}

export const ConnectedFilterbar = ({ run, runExport }: Props) => {
    const dispatch = useAppDispatch();
    const reports = useAppSelector(selectReportOptions);
    const query = useAppSelector(selectQuery);
    const patientExportEnabled = useAppSelector(
        withState(featureEnabled, 'patient-export'),
    );
    const changeReport = (report: string) => {
        dispatch(
            updateQuery({
                report
            })
        );
    };

    return (
        <Toolbar>
            {!patientExportEnabled && (
                <ConnectedFilter actionLabel="Search" run={run}>
                    <Selector
                        options={reports}
                        onSelect={changeReport}
                        selected={query.report}
                        placeholder={
                            query.report
                                ? reports.find((r) => r.value === query.report)
                                    ?.label
                                : 'Select a report'
                        }
                    />
                </ConnectedFilter>
            )}
            {patientExportEnabled && (
                <ConnectedFilterV2 runExport={runExport}>
                    <Selector
                        options={reports}
                        onSelect={changeReport}
                        selected={query.report}
                        placeholder={
                            query.report
                                ? reports.find((r) => r.value === query.report)
                                      ?.label
                                : 'Select a report'
                        }
                    />
                </ConnectedFilterV2>
            )}
        </Toolbar>
    );
};
