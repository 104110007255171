import { TypeSystem } from '$ui/Flo/types';

const family = 'Roboto, sans-serif';

const lightWeight = 300 as const;
const normalWeight = 400 as const;
const mediumWeight = 500 as const;

const base = {
    size: '18px',
    weight: lightWeight,
    family
};

export const type: TypeSystem = {
    title: {
        ...base,
        size: '60px'
    },

    subtitle1: {
        ...base,
        weight: mediumWeight
    },

    subtitle2: {
        ...base,
        weight: mediumWeight,
        size: '14px'
    },

    body1: {
        ...base,
        weight: normalWeight,
        size: '18px'
    },

    body2: {
        ...base,
        weight: normalWeight,
        size: '14px'
    },

    small: {
        ...base,
        weight: normalWeight,
        size: '12px'
    },

    h1: {
        ...base,
        size: '48px'
    },

    h2: {
        ...base,
        size: '42px'
    },

    h3: {
        ...base,
        size: '36px'
    },

    h4: {
        ...base,
        size: '32px'
    },

    h5: {
        ...base,
        size: '28px'
    },

    h6: {
        ...base,
        size: '24px'
    },

    button: {
        ...base,
        weight: normalWeight,
        size: '14px'
    }
};
