import React from 'react';
import { Frame } from '$ui/Integrations/Frame';
import { Failed, Repair, Success } from '$ui/Integrations/Screens';
import { SyncState } from '$types/integrations';
import { repair, useWorkflow } from '$ui/Integrations/Workflows/workflows';

interface Props {
    auth: () => Promise<void>;
    sync: () => void;
    onFinish: () => void;
    syncState: SyncState;
}

// TODO: Refactor this when introducing a new integration
export const GmailRepair = (props: Props) => {
    const { auth, sync, onFinish, syncState } = props;

    const { matches, onSync, onSignIn } = useWorkflow({
        healthyState: 'healthy',
        workflow: repair,
        syncState,
        auth,
        sync
    });

    return (
        <Frame
            integration="Gmail"
            mode="Repair"
            next={matches('healthy')}
            back={false}
            last
            onExit={onFinish}
            onFinish={onFinish}
            onNext={() => null}
            onBack={() => null}
        >
            {matches('repair') && (
                <Repair
                    integration="Gmail"
                    onSignIn={onSignIn}
                    signingIn={matches('repair.signingIn')}
                />
            )}

            {matches('healthy') && (
                <Success
                    integration="Gmail"
                    onSync={onSync}
                    repaired
                    syncing={matches('healthy.syncing')}
                />
            )}

            {matches('failed') && (
                <Failed
                    integration="Gmail"
                    mode="repair"
                    onSignIn={onSignIn}
                    signingIn={matches('failed.signingIn')}
                />
            )}
        </Frame>
    );
};
