import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Text } from '$ui/Flo/Text';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    email: string;
    practiceEmail: string;
    onChange: (email: string, isValid?: boolean) => void;
    loading: boolean;
}

export const SendingEmail = (props: Props) => {
    const { email, practiceEmail, onChange, loading } = props;

    const [_, domain] = practiceEmail.split('@');
    const [sendingLocal] = email.split('@');

    const handleChange = (local: string) => {
        onChange(`${local}@${domain}`, true);
    };

    return (
        <Container>
            <Subtitle>
                Change which email address Leadflo sends email from, if
                necessary.
            </Subtitle>
            <Text level="body2">
                By default, Leadflo uses your practice email. Remember: patients
                can reply to this email.
            </Text>
            <FloatingTextField
                type="text"
                loading={loading}
                label="Email address"
                value={sendingLocal}
                onChange={handleChange}
                required
                addOn={`@${domain}`}
            />
        </Container>
    );
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.padding({ padding: [3, 4] })};
    ${mix.gap({ size: 2 })};
    ${mix.type({ level: 'body2' })};
`;

export const Subtitle = styled.div`
    ${mix.color({ profile: 'body' })};
    font-size: 16px;
`;
