import { APIPatients } from '$ui/Patients/APIPatients';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as stages from '$state/concerns/stages';
import { useAppDispatch } from '$state';
import { usePageTitle } from '@/utils/hooks';

export interface PatientsMatchParams {
    namespace: string;
    report: string;
}

export const Patients = (props: RouteComponentProps<PatientsMatchParams>) => {
    usePageTitle('Patients');

    const { namespace, report } = props.match.params;
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(stages.load());
    }, []);

    return <APIPatients report={`${namespace}/${report}`} />;
};
