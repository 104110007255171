import * as React from 'react';
import { Button } from '$ui/Flo/Button';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    children: React.ReactNode;
}
export const AdditionalMenuButton = (props: Props) => {
    const { children } = props;
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleOutsideClick = (event: Event) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
        ) {
            setMenuOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick, true);
        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, []);

    return (
        <MenuWrapper ref={wrapperRef}>
            <Button
                id="menu-button"
                onClick={() => setMenuOpen(!menuOpen)}
                mode="outline"
                hue="grey"
                shade="5"
                rounded
                border="x-small"
            >
                ...
            </Button>
            {menuOpen && <SubMenu data-cy="menu-submenu">{children}</SubMenu>}
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
    position: relative;
    display: flex;
    line-height: 0;
`;

const SubMenu = styled.div`
    position: absolute;
    bottom: calc(100% + ${mix.unit({ size: 1 })});
    right: 0;
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
    border-radius: 4px;
    background: white;
    ${mix.shadow({ level: 1 })};
`;
