import {
    actionUpdated,
    actionWaking,
    commChangedState,
    communicationOccurred,
    formSubmitted,
    integrationStateChanged,
    integrationSyncStateChanged,
} from '$state/events';
import { MessageMap } from '@/ws/service';
import { store } from '$state';
import { fetchTimeline } from '$state/concerns/timeline';
import { listSources } from '$state/concerns/sources';
import * as dentally from '$state/concerns/dentally/patients';
import * as bulk from '$state/concerns/bulk';
import * as patients from '$state/concerns/patient';
import {
    conversationRead,
    conversationUnread,
    patientStarred,
    patientUnstarred,
} from '$state/concerns/inbox';
import { fetchCounts } from '$state/concerns/actions';
import toast from 'react-hot-toast';
import { Notification } from '$ui/Notifications/Notification';
import { removeLabel } from '$state/concerns/client';
import { CommEventUnion, CommEventUnionInbound, Status } from '$types';
import React from 'react';

const routes: MessageMap = {
    'patient.communications.changed-state': (msg) => {
        if (!msg.identity) {
            console.error(
                `Received patient.communications.changed-state without patientId`,
            );
            return;
        }
        const payload = msg.payload as CommEventUnion;
        // temporary until timeline v2 is ready - [sc-4334]
        const status: string =
            typeof payload.status === 'object'
                ? (payload.status as Status).name
                : payload.status;

        store.dispatch(
            commChangedState({
                patientId: msg.identity,
                communication: {
                    ...payload,
                    status: status || 'sent',
                },
                state: payload.status,
            }),
        );
    },

    'patient.opportunity.changed': (msg) => {
        const payload = msg.payload as {
            stage: string;
            next_action_at: string;
        };

        store.dispatch(
            actionUpdated({
                patientId: msg.identity,
                stage: payload.stage,
                nextActionAt: payload.next_action_at,
            }),
        );

        store.dispatch(fetchCounts([]));
    },

    'patient.communication.sent': (msg) => {
        const payload = msg.payload as CommEventUnion;
        // temporary until timeline v2 is ready - [sc-4334]
        const status: string =
            typeof payload.status === 'object'
                ? (payload.status as Status).name
                : payload.status;

        store.dispatch(
            communicationOccurred({
                patientId: msg.identity,
                communication: {
                    ...payload,
                    status: status || 'sent',
                } as CommEventUnion,
                inbound: false,
            }),
        );
    },

    'patient.communication.received': (msg) => {
        const payload = msg.payload as CommEventUnionInbound;
        const status: string =
            typeof payload.status === 'object'
                ? (payload.status as Status).name
                : payload.status;

        store.dispatch(
            communicationOccurred({
                patientId: msg.identity,
                communication: {
                    ...payload,
                    status: status || 'received',
                } as CommEventUnion,
                inbound: true,
            }),
        );
    },

    'form.submission.applied': ({ identity }) => {
        store.dispatch(formSubmitted(identity));
    },

    'conversation.read': ({ identity }) => {
        store.dispatch(
            conversationRead({ patientId: identity, notify: false }),
        );
    },

    'conversation.unread': ({ identity }) => {
        store.dispatch(
            conversationUnread({ patientId: identity, notify: false }),
        );
    },

    'patient.starred': ({ identity }) => {
        store.dispatch(
            patientStarred({
                patientId: identity,
                notify: false,
            }),
        );
    },

    'patient.unstarred': ({ identity }) => {
        store.dispatch(
            patientUnstarred({
                patientId: identity,
                notify: false,
            }),
        );
    },

    'patient.labels.cleanup': (msg) => {
        const payload = msg.payload as string;
        store.dispatch(removeLabel(payload));
    },

    'communication.batch.changed-state': (msg) => {
        const payload = msg.payload as string;
        store.dispatch(bulk.load(payload));
    },

    'notification.sms': ({ payload }) => {
        const {
            communication_id: id,
            patient_name: name,
            patient_id: patientId,
            date,
        } = payload as {
            communication_id: string;
            patient_name: string;
            patient_id: string;
            date: string;
        };

        toast.custom(
            ({ visible }) => {
                return React.createElement(Notification, {
                    visible,
                    icon: 'Smartphone',
                    title: name,
                    action: 'New SMS from',
                    time: date,
                    onView: () => {
                        store.dispatch(
                            patients.openPatient({
                                patientId,
                            }),
                        );
                        toast.dismiss(id);
                    },
                    onClose: () => toast.dismiss(id),
                });
            },
            {
                id,
                duration: Infinity,
                position: 'bottom-right',
            },
        );
    },

    'integrations.sync.state.changed': (msg) => {
        const payload = msg.payload as {
            id: string;
            client_id: number;
            integration: string;
            state: 'queued' | 'syncing' | 'failed' | 'complete';
            queued_at: string;
            syncing_at: string | null;
            completed_at: string | null;
            failed_at: string | null;
            error: string | null;
        };

        store.dispatch(integrationSyncStateChanged(payload));
    },

    'integrations.state.changed': (msg) => {
        const payload = msg.payload as {
            client_id: number;
            integration: string;
            state: 'disconnected' | 'healthy' | 'unhealthy';
        };

        store.dispatch(integrationStateChanged(payload));
        const { integration, state } = payload;

        const integrationCap =
            integration.charAt(0).toUpperCase() + integration.slice(1);

        if (state === 'unhealthy') {
            toast.error(
                () => {
                    return React.createElement(
                        'a',
                        {
                            href: '/settings/integrations/' + integration,
                            style: {
                                color: '#fff',
                                'font-weight': 'bold',
                            },
                        },
                        `Your ${integrationCap} integration is unhealthy. Go to ${integrationCap} settings to fix it.`,
                    );
                },
                { id: integration },
            );
        }
    },

    'dentally.patient.synced': (msg) => {
        const payload = msg.payload as { patient_id: string };
        store.dispatch(dentally.get(payload.patient_id));
    },

    'patient.lead-source.changed': () => {
        store.dispatch(listSources());
    },

    'patient.business-event.created': ({ identity }) => {
        store.dispatch(fetchTimeline(identity));
    },

    'patient.snooze.waking': (msg) => {
        const payload = msg.payload as { stage: string };
        store.dispatch(
            actionWaking({
                patientId: msg.identity,
                stage: payload.stage,
            }),
        );
    },
};

export default routes;
