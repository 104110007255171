import { useAppDispatch, useAppSelector } from '$state/hooks';
import {
    assetUrl,
    changeSettings,
    getSettings,
    selectClientCountry,
    selectSettings,
} from '$state/concerns/client';
import { Details, DetailsValidity } from '$ui/Settings/Forms/Details';
import { Section } from '$ui/Settings/Section';
import { Subsection } from '$ui/Settings/Section/Subsection';
import React from 'react';
import { PracticePayload, SettingStatus } from '$state/types/client';
import { Prompt } from 'react-router';
import { Types } from '$ui/Settings/Forms/Types';
import { Branding } from '$ui/Settings/Forms/Branding';
import { saveAsset } from '$api/client';
import { lang, maybe, phone } from '$utils';
import { usePageTitle } from '@/utils/hooks';
import { PracticeEmail } from '$ui/Settings/Communications/PracticeEmail';
import { ConnectedSourcesEditor } from '$ui/LeadSources';
import { Signature } from '$ui/Settings/Signature';
import {
    PracticeSettings,
    SaveButtonContainer,
} from '@/views/Settings/PracticeV2';
import { showSavedSettingsStatus } from '@/views/Settings/utils';

/**
 * Intended to be an intersection type once branding/types supported.
 */

export const Practice = () => {
    usePageTitle('Practice - Settings');

    // TODO: A lot of this will also be necessary for comms section
    const dispatch = useAppDispatch();
    const practice = useAppSelector(selectSettings)['practice'];
    const assets = useAppSelector(selectSettings)['assets'];
    const country = useAppSelector(selectClientCountry);

    const loading = practice.status === SettingStatus.LOADING;

    const [draftPractice, setPracticeSettings] = React.useState<
        PracticeSettings | undefined
    >(undefined);
    const [draftAssets, setDraftAssets] = React.useState<
        | {
              [name: string]: File;
          }
        | undefined
    >(undefined);

    const [shouldShowSave, setShouldShowSave] = React.useState<boolean>(false);
    const [validity, setIsValid] = React.useState<DetailsValidity>({});

    const logo = useAppSelector(assetUrl('logo'));
    const footer = useAppSelector(assetUrl('footer'));
    const rep = useAppSelector(assetUrl('rep'));

    React.useEffect(() => {
        if (practice.status === SettingStatus.IDLE) {
            dispatch(getSettings('practice'));
        }
        if (assets.status === SettingStatus.IDLE) {
            dispatch(getSettings('assets'));
        }
    }, []);

    React.useEffect(() => {
        showSavedSettingsStatus(practice.status);
    }, [practice.status]);

    const onUpdate = (
        settings: Partial<PracticeSettings>,
        isValid: DetailsValidity,
    ) => {
        const next = {
            ...practice,
            ...settings,
        };
        setShouldShowSave(!lang.isEqual(practice, next));
        setPracticeSettings(next);

        if (isValid) {
            setIsValid({
                ...validity,
                ...isValid,
            });
        }
    };

    const onAssetUpdate = (asset: string, file: File) => {
        setDraftAssets({
            ...draftAssets,
            [asset]: file,
        });
        setShouldShowSave(true);
    };

    const onSave = () => {
        if (draftPractice) {
            dispatch(
                changeSettings({
                    section: 'practice',
                    payload: draftPractice as PracticePayload,
                }),
            );
        }

        if (draftAssets) {
            Object.entries(draftAssets).forEach(([name, file]) => {
                saveAsset(name, file);
            });
        }

        setShouldShowSave(false);
    };

    const isValid = () => {
        return Object.values(validity).every((isValid) => isValid);
    };

    const formattedPractice = {
        ...practice,
        phone: maybe(practice.phone, (p) => phone.format(p, country)),
    };

    const onEmailChange = (email: string, isValid?: boolean) => {
        onUpdate({ email }, { email: isValid });
    };

    return (
        <>
            <Prompt
                when={shouldShowSave}
                message={(location) => {
                    return location.pathname.startsWith('/settings/practice')
                        ? true
                        : 'You have unsaved changes, are you sure you want to leave?';
                }}
            />
            <Section title="Practice">
                <Subsection id="details" title="Details">
                    <Details
                        loading={loading}
                        details={draftPractice ?? formattedPractice}
                        onChange={onUpdate}
                        repPhoto={draftAssets?.['rep'] ?? rep}
                        onPhotoUploaded={(file) => onAssetUpdate('rep', file)}
                    />
                </Subsection>

                <Subsection id="practice-email" title="Practice Email" padded>
                    <PracticeEmail
                        loading={loading}
                        practiceEmail={
                            draftPractice?.email ?? formattedPractice?.email
                        }
                        onChange={onEmailChange}
                    />
                </Subsection>

                <Signature
                    details={draftPractice ?? formattedPractice}
                    repPhoto={draftAssets?.['rep'] ?? rep}
                />

                <Subsection id="branding" title="Branding">
                    <Branding
                        logo={draftAssets?.['logo'] ?? logo}
                        footer={draftAssets?.['footer'] ?? footer}
                        onUpload={onAssetUpdate}
                    />
                </Subsection>

                <Subsection id="types" title="Types">
                    <Types
                        available={
                            draftPractice?.available_types ??
                            practice.available_types ??
                            []
                        }
                        selected={draftPractice?.types ?? practice.types ?? []}
                        onChange={(types) => onUpdate({ types }, {})}
                    />
                </Subsection>

                <Subsection id="sources" title="Lead Sources" padded>
                    <ConnectedSourcesEditor />
                </Subsection>
            </Section>
            <SaveButtonContainer
                shouldShowSave={shouldShowSave && isValid()}
                onClick={onSave}
            />
        </>
    );
};
