import * as React from 'react';
import styled from 'styled-components';
import { applyPadding, palette, scale } from '../util';
import { applyFieldStyle, applyLabelStyle, FieldProps } from './Field';
import * as chrono from 'chrono-node';
import { obj } from '$utils';

interface Props extends FieldProps {
    label: string;
    initialValue?: string;
    valueChanged: (val: string) => void;
}

export const DateField = (props: Props) => {
    const { label, editable, initialValue, valueChanged } = props;
    const [value, setValue] = React.useState(initialValue ?? '');
    const [freeText, setFreeText] = React.useState('');

    React.useEffect(() => {
        if (!initialValue) {
            return;
        }
        setValue(initialValue);
    }, [initialValue]);

    const change = (val: string) => {
        setValue(val);
        valueChanged(val);
    };

    const parse = (text: string) => {
        setFreeText(text);
        const date = chrono.parseDate(text);
        if (!date) {
            return;
        }
        const iso = date.toISOString();
        const dateString = iso.substring(0, iso.indexOf('T'));
        change(dateString);
    };

    return (
        <Wrapper {...props}>
            <Label
                {...props}
                profile="body"
                level="body2"
                bold
                editable={editable}
            >
                {label}
            </Label>

            <Text
                {...props}
                disabled={!editable}
                onChange={(e) => parse(e.target.value)}
                value={freeText}
                title="Date scheduler"
            />

            <DateInput
                {...obj.omit(props, ['placeholder'])}
                disabled={!editable}
                value={value}
                type="date"
                onChange={(e) => change(e.target.value)}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1rem;
    ${({ theme }) => applyPadding({ theme, padding: [1, 4] })};

    @media (min-width: 1600px) {
        ${({ theme }) => applyPadding({ theme, padding: [3, 4] })};
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0;
    }
`;

const Text = styled.input`
    ${applyFieldStyle};
    width: auto;
    flex-grow: 1;
    padding: 1rem;
    border: none;
    @media (max-width: 1600px) {
        flex: 1 1 auto;
    }
`;

const DateInput = styled.input`
    ${applyFieldStyle};

    margin-right: 0;
    border-left: 1px solid
        ${({ theme }) =>
            palette({
                theme,
                hue: 'grey',
                shade: '9',
            })};
    padding: 0 ${({ theme }) => scale(theme, 2)}px;
    cursor: text;
    padding: 1rem;
    border: none;
    margin-left: 1rem;
    @media (max-width: 1600px) {
        margin-left: 0rem;
        flex: 1 1 auto;
    }
`;

const Label = styled.label`
    ${applyLabelStyle};
`;
