import { TemplateContext } from '$state/types/contexts';
import { Template } from '$state/types/templates';
import { Text } from '$ui/Flo/Text';
import React from 'react';
import Mustache from 'mustache';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Button } from '$ui/Flo/Button';
import { ChannelPayload } from '$state/types';
import { maybe } from '$utils';

interface Props {
    template: Template;
    context?: TemplateContext;
    onInsert: (message: ChannelPayload) => void;
}

export const Preview = (props: Props) => {
    const { context, template } = props;
    const { subject, body } = template;

    const render = (template: string): string => {
        if (!context) {
            return template;
        }

        return Mustache.render(template, context);
    };

    const insert = () => {
        switch (props.template.channel) {
            case 'note':
                return props.onInsert({
                    title: maybe(props.template.subject, (subject) =>
                        render(subject)
                    ),
                    content: render(props.template.body)
                });
            case 'email':
                return props.onInsert({
                    subject: maybe(props.template.subject, (subject) =>
                        render(subject)
                    ),
                    body: render(props.template.body)
                });
            case 'sms':
                return props.onInsert({
                    message: render(props.template.body)
                });
            default:
                throw new Error('Unknown template channel');
        }
    };

    return (
        <Container>
            {subject && (
                <Text level="body1" bold>
                    {context ? render(subject) : subject}
                </Text>
            )}
            <Spacer />
            <Text level="body1">{context ? render(body) : body}</Text>
            <Spacer />
            <Button
                hue="green"
                icon="FilePlus"
                shade="5"
                onClick={insert}
                rounded
            >
                Insert Template
            </Button>
        </Container>
    );
};

const Spacer = styled.div`
    ${mix.margin({ margin: [0, 0, 2, 0] })}
`;

const Container = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${mix.bg({ hue: 'grey', shade: '10' })}
    ${mix.padding({ padding: [3] })}
    max-height: 30vh;
    overflow-y: scroll;
`;
