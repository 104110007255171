import { SendingEmail } from '$ui/Settings/Communications/SendingEmail';
import { Section } from '$ui/Settings/Section';
import { Subsection } from '$ui/Settings/Section/Subsection';
import { usePageTitle } from '@/utils/hooks';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import {
    enableSMS,
    getCommsSettings,
    getDns,
    postDnsInstructions,
    postTestEmail,
    resetTestEmail,
    selectClient,
    selectSettings,
    setCommsSettings as persistCommsSettings,
    verifyDns,
} from '$state/concerns/client';
import { Prompt } from 'react-router';
import { CommsSettings, DkimStatus, SettingStatus } from '$state/types/client';
import { SaveButtonContainer } from './PracticeV2';
import { EmailVerification } from '$ui/Settings/Communications/EmailVerification';
import { SendTestEmail } from '$ui/Settings/Communications/SendTestEmail';
import {
    isOpeningLower,
    SmsAutoresponder,
} from '$ui/Settings/Communications/SmsAutoresponder';
import { SmsSetup } from '$ui/Settings/Communications/SmsSetup';
import { withState } from '$state/utils';
import { maybe, phone } from '$utils';
import { showSavedSettingsStatus } from '@/views/Settings/utils';

export const Communications = () => {
    usePageTitle('Communications - Settings');

    const dispatch = useAppDispatch();

    const [shouldShowSave, setShouldShowSave] = React.useState<boolean>(false);

    const client = useAppSelector(withState(selectClient));
    const practice = useAppSelector(selectSettings)['practice'];
    const commsSettings = useAppSelector(selectSettings)['communications'];
    const dns = useAppSelector(selectSettings)['dns'];
    const testEmail = useAppSelector(selectSettings)['testEmail'];

    const [draftCommsSettings, setCommsSettings] = React.useState<
        CommsSettings | undefined
    >(undefined);

    const loading = commsSettings.status === SettingStatus.LOADING;

    const practiceLoading = practice.status === SettingStatus.LOADING;

    const [email, setEmail] = React.useState<{
        email: string;
        isValid: boolean;
    }>({
        email: '',
        isValid: false,
    });

    const [businessHours, setBusinessHours] = React.useState<
        CommsSettings['business_hours']
    >({
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
    });

    const [smsAutoresponder, setSmsAutoresponder] = React.useState<
        CommsSettings['sms_autoresponses']
    >({});

    const [practicePhone, setPracticePhone] = React.useState<{
        number: string;
        isValid: boolean;
    }>({
        number: '',
        isValid: false,
    });

    const dnsLoading = dns.status === SettingStatus.LOADING;

    const handleEmailChange = (email: string, isValid?: boolean) => {
        setEmail({ email: email, isValid: isValid ?? false });
    };

    const handleOnSave = () => {
        draftCommsSettings &&
            dispatch(persistCommsSettings(draftCommsSettings as CommsSettings));
    };

    const handleOnEmailInstructions = (
        toSend: string,
        instructions: string,
    ) => {
        dispatch(
            postDnsInstructions({
                to: toSend,
                body: instructions,
            }),
        );
    };

    const handleOnVerifyDns = () => {
        dispatch(verifyDns());
    };

    const handleSendTestEmail = (email: string) => {
        dispatch(
            postTestEmail({
                to: email,
            }),
        );
    };

    const handleSmsAutoresponderChange = (value: Partial<CommsSettings>) => {
        setBusinessHours((prevHours) => ({
            ...prevHours,
            ...value.business_hours,
        }));
        setSmsAutoresponder(value.sms_autoresponses ?? {});
    };

    const validateHours = (hours: CommsSettings['business_hours']) => {
        if (!hours) return true;
        return Object.values(hours).every((hour) => {
            if (!hour) return true;
            return isOpeningLower(hour.opens, hour.closes);
        });
    };

    const handleEnableSMS = () => {
        practicePhone.isValid &&
            dispatch(enableSMS({ practice_phone: practicePhone.number }));
    };

    // prepare
    useEffect(() => {
        dispatch(getCommsSettings());
        dispatch(getDns());
        dispatch(resetTestEmail());
    }, []);

    useEffect(() => {
        if (!client) {
            return;
        }

        if (!practice) {
            return;
        }

        setPracticePhone({
            number: maybe(practice.phone, (p) =>
                phone.format(p, client.country),
            ),
            isValid: !!practice.phone,
        });
    }, [practice, client]);

    // original commsSettings from API
    useEffect(() => {
        if (!commsSettings) return;
        setCommsSettings({
            sending_email: commsSettings.sending_email,
            business_hours: commsSettings.business_hours,
            sms_autoresponses: commsSettings.sms_autoresponses,
        } as CommsSettings);

        setBusinessHours(
            commsSettings.business_hours as CommsSettings['business_hours'],
        );
        setSmsAutoresponder(commsSettings.sms_autoresponses ?? {});

        showSavedSettingsStatus(commsSettings.status);
    }, [commsSettings]);

    // draft commsSettings changes
    useEffect(() => {
        setCommsSettings({
            sending_email: email.email,
            business_hours: businessHours,
            sms_autoresponses: smsAutoresponder,
        } as CommsSettings);
    }, [email.email, businessHours, smsAutoresponder]);

    // to show save button depending on if there are changes
    useEffect(() => {
        if (!draftCommsSettings || !commsSettings) return;

        const toCompare = [
            'sending_email',
            'business_hours',
            'sms_autoresponses',
        ];

        const isChanged = toCompare.some((key) => {
            // by default compare value with type. if it's an object, we should
            // compare stringified
            const compare = draftCommsSettings?.[key] !== commsSettings?.[key];

            if (key === 'sending_email') {
                return compare && email.isValid;
            }

            if (key === 'business_hours') {
                return (
                    validateHours(draftCommsSettings.business_hours) &&
                    JSON.stringify(draftCommsSettings?.[key]) !==
                        JSON.stringify(commsSettings?.[key])
                );
            }

            if (key === 'sms_autoresponses') {
                return (
                    JSON.stringify(draftCommsSettings?.[key]) !==
                    JSON.stringify(commsSettings?.[key])
                );
            }
            return compare;
        });

        setShouldShowSave(commsSettings?.status === 'loaded' && isChanged);
    }, [draftCommsSettings]);

    const showSaveButton = !practiceLoading && !loading && shouldShowSave;

    return (
        <>
            <Prompt
                when={showSaveButton}
                message={(location) => {
                    return location.pathname.startsWith(
                        '/settings/communications',
                    )
                        ? true
                        : 'You have unsaved changes, are you sure you want to leave?';
                }}
            />
            <Section title="Communications">
                <Subsection id="sending-email" title="Sending Email">
                    <SendingEmail
                        email={
                            email.email === ''
                                ? commsSettings.sending_email ?? ''
                                : email.email
                        }
                        practiceEmail={practice?.email ?? ''}
                        onChange={handleEmailChange}
                        loading={loading || practiceLoading}
                    />
                </Subsection>

                <Subsection id="email-verification" title="Email Verification">
                    <EmailVerification
                        practice={practice?.name ?? ''}
                        dns={{
                            hostname: dns.hostname ?? '',
                            spf: dns.spf ?? '',
                            dkim: dns.dkim ?? {
                                records: [
                                    {
                                        name: 'test',
                                        value: 'test',
                                    },
                                ],
                                status: DkimStatus.FAILED,
                            },
                        }}
                        loading={dnsLoading}
                        onEmailInstructions={handleOnEmailInstructions}
                        onVerify={handleOnVerifyDns}
                    />
                </Subsection>

                <Subsection id="email-testing" title="Email Testing">
                    <SendTestEmail
                        status={testEmail.status}
                        onSend={handleSendTestEmail}
                    />
                </Subsection>

                <Subsection id="sms" title="SMS">
                    <SmsSetup
                        loading={
                            loading || practice.status === SettingStatus.LOADING
                        }
                        practiceNumber={practicePhone.number}
                        routeNumber={commsSettings.sms_status?.number ?? ''}
                        smsEnabled={client?.settings.sms_enabled ?? true}
                        onEnableSMS={handleEnableSMS}
                        onChange={(number, isValid) => {
                            setPracticePhone({
                                number: number,
                                isValid: isValid ?? false,
                            });
                        }}
                        country={client?.country}
                    />
                </Subsection>

                <Subsection id="sms-autoresponder" title="SMS Autoresponder">
                    <SmsAutoresponder
                        loading={loading}
                        onChange={handleSmsAutoresponderChange}
                        settings={{
                            business_hours: businessHours,
                            sms_autoresponses: smsAutoresponder,
                        }}
                    />
                </Subsection>
            </Section>
            <SaveButtonContainer
                shouldShowSave={showSaveButton}
                onClick={handleOnSave}
            />
        </>
    );
};
