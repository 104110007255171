import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { DropdownSelect } from '$ui/PatientFilter/DropdownSelect';

// TODO: Probably move somewhere general
// or move this to Flo
export interface Option {
    label: string;
    value: string;
}

interface Props {
    options: Option[];
    onSelect: (opt: string) => void;
    selected: string | null;
    placeholder?: string;
}

export const Selector = (props: Props) => {
    const { selected, onSelect, options, placeholder } = props;
    return (
        <Container>
            <DropdownSelect
                available={options}
                selected={selected ? [selected] : null}
                onSelect={(sel) => {
                    sel && onSelect(sel.toString());
                }}
                plural="reports"
                icon="Users"
                searchable={false}
                placeholder={placeholder || 'Select a report'}
                multi={false}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-self: start;
    flex-direction: row;
    align-items: start;
    gap: ${mix.unit({ size: 0.5 })};
    position: relative;

    & > * {
        ${mix.margin({ margin: [0, 0, 0, 2] })}
    }
`;
