import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    label: string;
    type: string;
    placeholder: string;
    onChange: (input: string) => void;
    value?: string;
}

export const Field = ({
    label,
    type,
    placeholder,
    onChange,
    value = ''
}: Props) => {
    const _onChange = (e: React.FormEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    const id = `login-${label.toLowerCase()}`;

    return (
        <Container>
            <Input
                id={id}
                type={type}
                placeholder={placeholder}
                onChange={_onChange}
                value={value}
            />
            <Label htmlFor={id}>{label}</Label>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

const TRANSITION_S = '0.5s';

const Label = styled.label`
    ${mix.type({ level: 'subtitle2' })};
    ${mix.color({ profile: 'body' })};
    ${mix.margin({ margin: [0, 0, 2] })};
    display: block;
    line-height: 1;
    transition: ${TRANSITION_S} color;
`;

const Input = styled.input`
    ${mix.padding({ padding: 2 })};
    ${mix.color({ profile: 'body' })};
    ${mix.type({ level: 'body2' })};
    background: #fff;
    border: 2px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    border-radius: 3px;
    display: block;
    line-height: 1;
    transition: ${TRANSITION_S} background-color;

    &:-webkit-autofill {
        background: #fff !important;
    }

    &:autofill {
        background: #fff !important;
    }

    &:focus {
        transition: ${TRANSITION_S} background-color;
        border: 2px solid ${mix.palette({ hue: 'primary', shade: '6' })};
    }

    &:focus + label {
        transition: ${TRANSITION_S} color;
        color: ${mix.palette({ hue: 'primary', shade: '6' })};
    }

    ::placeholder {
        ${mix.color({ profile: 'secondary' })};
    }
`;
