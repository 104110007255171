import { Action as ActionSchema, Node } from '$state/types/automations';
import * as React from 'react';
import { Action } from './Action';
import { Connector, ConnectorType } from './Connector';
import { Delay } from './Delay';

interface Props {
    nodes: Node[];
    onEnable: (id: string, state: boolean) => void;
    onView: (action: ActionSchema) => void;
}

export const Nodes = (props: Props) => {
    const { nodes, onEnable, onView } = props;

    const items = [];
    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        const next = nodes[i + 1] || null;

        switch (node.type) {
            case 'action':
                items.push(
                    <Action
                        action={node}
                        onEnable={(state) => onEnable(node.id, state)}
                        onView={() => onView(node)}
                        key={node.id}
                    />
                );
                break;
            case 'delay':
            case 'wait_until_delay':
                items.push(
                    <Delay first={i === 0} delay={node} key={node.id} />
                );
                break;
        }

        if (!next) {
            continue;
        }
        items.push(
            <Connector
                key={node.id + '-' + next.id}
                type={(node.type + '-' + next.type) as ConnectorType}
            />
        );
    }

    return <>{items}</>;
};
