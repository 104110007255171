import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CommTypeEmail = ({ size = 24, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6057_16648)">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#2E90FA"/>
                <path d="M11.9961 12.4922L6.72656 9.86328V15.0898C6.72656 15.6406 7.17578 16.0898 7.72656 16.0898H16.2734C16.8242 16.0898 17.2734 15.6406 17.2734 15.0898V9.91797L11.9961 12.4922Z" fill="white"/>
                <path d="M12.0039 11.5078L17.2734 8.93359V8.91016C17.2734 8.35937 16.8242 7.91016 16.2734 7.91016H7.72656C7.1875 7.91016 6.74609 8.33594 6.72656 8.875L12.0039 11.5078Z" fill="white"/>
            </g>
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="white" strokeWidth="2"/>
        </svg>

    );
};
