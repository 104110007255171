import styled from 'styled-components';
import { TextColorProps, TypeProps } from '../types';
import { mix } from '../util';

export const Link = styled.a<TypeProps & Partial<TextColorProps>>`
    ${({ profile }) => mix.color({ profile: profile ?? 'link' })}
    ${mix.type}
    text-decoration: underline;
    text-underline-offset: 1px;
`;
