import React, { useLayoutEffect } from 'react';
import { Subsection } from '$ui/Settings/Section/Subsection';
import styled from 'styled-components';
import { Button } from '$ui/Flo/Button';
import { SelectedStatus } from '$ui/Flo/Button/ConfirmDeleteButton';

interface CommonProps {
    html: string;
    status?: SelectedStatus;
}

interface PreviewProps {
    preview: true;
    onSave: () => void;
    onCancel: () => void;
    status?: SelectedStatus;
}

interface EditProps {
    preview?: false;
    onEdit: () => void;
    onDelete: () => void;
    status?: SelectedStatus;
}

type Props = CommonProps & (PreviewProps | EditProps);

export const CustomPreview = (props: Props) => {
    const { html } = props;
    const previewRef = React.useRef<HTMLIFrameElement>(null);

    useLayoutEffect(() => {
        // make iframe height match content height
        const iframe = previewRef.current;
        if (!iframe) {
            return;
        }

        const resize = () => {
            iframe.style.height =
                iframe.contentWindow?.document.body.scrollHeight + 'px';
        };

        iframe.addEventListener('load', resize);

        return () => {
            iframe.removeEventListener('load', resize);
        };
    }, []);

    return (
        <Subsection
            padded
            id="signature"
            title="Signature"
            actions={<Actions {...props} />}
        >
            <PreviewWindow
                ref={previewRef}
                srcDoc={style + html}
                data-testid="custom-signature-preview"
            />
        </Subsection>
    );
};

const Actions = (props: PreviewProps | EditProps) => {
    const { preview, status } = props;

    const disabled =
        status === 'deleting' ||
        status === 'saving' ||
        status === 'saved' ||
        status === 'deleted';

    if (preview) {
        const { onSave, onCancel } = props;

        return (
            <>
                <Button
                    id="custom-preview-cancel"
                    rounded
                    size="med-large"
                    mode="outline"
                    hue="red"
                    onClick={onCancel}
                    disabled={disabled}
                >
                    Back
                </Button>
                <Button
                    rounded
                    id="custom-preview-save"
                    onClick={onSave}
                    size="med-large"
                    hue="primary"
                    async
                    disabled={disabled}
                    labels={{
                        doing: 'Saving...',
                        done: 'Saved',
                        error: 'Error'
                    }}
                    doing={status === 'saving'}
                    done={status === 'saved'}
                >
                    Save
                </Button>
            </>
        );
    }

    const { onEdit, onDelete } = props;
    return (
        <>
            <Button
                id="custom-preview-delete"
                rounded
                size="med-large"
                mode="outline"
                hue="red"
                onClick={onDelete}
                tooltip="Delete your custom signature and use the default Leadflo signature"
                async={true}
                confirm
                labels={{
                    doing: 'Deleting...',
                    done: 'Deleted',
                    error: 'Error'
                }}
                doing={status === 'deleting'}
                done={status === 'deleted'}
                disabled={disabled}
            >
                Delete Signature
            </Button>
            <Button
                id="custom-preview-edit"
                rounded
                size="med-large"
                onClick={onEdit}
                disabled={disabled}
            >
                Edit
            </Button>
        </>
    );
};

const PreviewWindow = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 4px;
`;

const style = `
    <style>
        body {
            background: white;
            margin: 0;
            padding: 8px;
        }

        p {
            margin: 0;
        }
    </style>
`;
