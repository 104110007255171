import React from 'react';
import { Pipeline, PracticeOverview } from './';
import { Tab, TabItemProps } from '$ui/Settings/Tab';

const def: Array<TabItemProps> = [
    {
        icon: 'BarChart2',
        key: 'practice',
        name: 'Practice Overview',
        exact: true,

        component: PracticeOverview
    },
    {
        icon: 'PieChart',
        key: 'pipeline',
        name: 'Pipeline',
        uri: '/reporting/pipeline',
        component: Pipeline
    }
];

export const Reporting = () => {
    return <Tab items={def} path="reporting" active="practice" />;
};
