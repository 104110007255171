import React from 'react';
import { PopupContainer, StatusIcon } from '$ui/Integrations/Elements';
import { Heading } from '$ui/Flo/Heading';
import { Button } from '$ui/Flo/Button';

interface Props {
    status: string;
}

export const PopupStatus = (props: Props) => {
    const { status } = props;
    const [timer, setTimer] = React.useState(5);

    React.useEffect(() => {
        if (timer == 0) {
            closePopup();
        }
        const intervalId = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timer]);

    const closePopup = () => {
        window.close();
    };

    if (status === 'done') {
        return (
            <PopupContainer>
                <StatusIcon mode={'healthy'} />
                <Heading level="body1" profile="body">
                    Connection Successful
                </Heading>
                <Heading level="body1" profile="body">
                    This window will close in {timer}
                </Heading>
                <Button
                    icon="X"
                    mode="outline"
                    border="x-small"
                    rounded
                    onClick={closePopup}
                >
                    Close now
                </Button>
            </PopupContainer>
        );
    }
    return (
        <PopupContainer>
            <StatusIcon mode={'unhealthy'} />
            <Heading level="body1" profile="body">
                Connection Failed
            </Heading>
            <Heading level="body1" profile="body">
                Please try signing in again. This window will close in {timer}
            </Heading>
            <Button
                icon="X"
                mode="outline"
                border="x-small"
                rounded
                onClick={closePopup}
            >
                Close now
            </Button>
        </PopupContainer>
    );
};
