import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Button } from '$ui/Flo/Button';
import { AccordionHeader, AccordionSubItem } from './Parts';
import { Template } from '$state/types/templates';
import { useAppSelector } from '$state';
import { selectChannelTemplates } from '$state/concerns/templates';
import { selectChannel } from '$state/concerns/channels';
import { Channels } from '$state/types';
import { useHistory } from 'react-router';
import { useTemplateParams } from '$ui/Templates/hooks';
import { IconName } from '$ui/Flo/Icon';

interface ConnectedAccordionProps {
    channel: Channels;
}

export const ConnectedAccordion = (props: ConnectedAccordionProps) => {
    const history = useHistory();
    const { templateId } = useTemplateParams();
    const templates = useAppSelector(selectChannelTemplates(props.channel));
    const channel = useAppSelector(selectChannel(props.channel));

    const onTemplateCreated = () => {
        history.push(`/settings/templates/${props.channel}`);
    };

    const onTemplateSelected = (templateId: string) => {
        history.push(`/settings/templates/${props.channel}/${templateId}`);
    };

    return (
        <Accordion
            {...props}
            {...channel}
            selectedTemplateId={templateId}
            templates={templates}
            onTemplateCreated={onTemplateCreated}
            onTemplateSelected={onTemplateSelected}
        />
    );
};

interface AccordionProps {
    icon: IconName;
    label: string;
    templates: Array<Template>;
    onTemplateCreated: () => void;
    selectedTemplateId: string | undefined;
    onTemplateSelected: (id: string) => void;
}

export const Accordion = (props: AccordionProps) => {
    const {
        label,
        icon,
        templates,
        selectedTemplateId,
        onTemplateSelected,
        onTemplateCreated
    } = props;

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onClickCreate = () => {
        onTemplateCreated();
    };

    const subNavAccordion =
        templates?.length > 0 ? (
            templates.map((accordion) => {
                return (
                    <AccordionSubItem
                        {...accordion}
                        key={accordion.id}
                        icon={icon}
                        selectedTemplateId={selectedTemplateId}
                        onTemplateSelected={onTemplateSelected}
                    />
                );
            })
        ) : (
            <EmptyTemplates
                message={`No ${label} Templates`}
                createTemplate={onClickCreate}
            />
        );

    return (
        <SubNav>
            <AccordionHeader
                name={label}
                isOpen={isOpen}
                onClick={onClick}
                onClickCreate={onClickCreate}
            />
            {isOpen && <SubMenu>{subNavAccordion}</SubMenu>}
        </SubNav>
    );
};

interface EmptyMessageProps {
    message: string;
    createTemplate: () => void;
}

const EmptyTemplates = (props: EmptyMessageProps) => {
    const { createTemplate } = props;
    const onClickCreate = () => {
        createTemplate();
    };
    return (
        <EmptyMessage>
            <Message>{props.message}</Message>
            <Button
                mode="outline"
                size="x-small"
                rounded
                hue="green"
                icon="FilePlus"
                onClick={onClickCreate}
            >
                New Template
            </Button>
        </EmptyMessage>
    );
};

const SubNav = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex: 1 1 100%;
    background: white;
`;

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

const EmptyMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${mix.bg({ hue: 'yellow', shade: '10' })};
    ${mix.padding({ padding: 2 })};
`;

const Message = styled.div`
    ${mix.margin({ margin: [0, 0, 1, 0] })};
    ${mix.type({ level: 'body2', bold: true })}
`;
