import { RootState } from '$state/store';
import { IntegrationState, integrationState } from '$state/concerns/integrations';

export const integrationsState = (state: RootState) => state.integrations;

export const isIntegrationLoaded = (state: IntegrationState, name: string): boolean => {
    return state.integrations[name] !== undefined;
}

export const whatsAppEnabled = (state: RootState): boolean => {
    if (!isIntegrationLoaded(integrationState(state), 'whatsapp')) {
        return false;
    }

    return integrationsState(state).integrations['whatsapp'].state === 'healthy';
};
