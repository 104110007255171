import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { RefreshCw } from 'react-feather';
import { TimelineStatus } from '$state/concerns/timeline';
import { applyBg, applyTextColor } from '$ui/Flo/util';

interface Props {
    status: TimelineStatus;
    refresh: () => void;
}

const Button = styled.button`
    ${({ theme }) => applyBg({ theme, hue: 'primary', shade: '6' })};
    ${({ theme }) => applyTextColor({ theme, profile: 'title', dark: true })};
    border-radius: 500rem;
    box-shadow: 0px 2px 2px rgba(17, 25, 48, 0.12),
        0px 4px 10px rgba(44, 57, 93, 0.17);
    outline: none;
    border: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    font-weight: 400;
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    transition: 300ms ease;
`;

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
`;

interface IconProps {
    $spin?: boolean;
}

const Icon = styled(RefreshCw)<IconProps>`
    margin-right: 1rem;
    ${(props) =>
        props.$spin &&
        css`
            animation-name: ${rotate};
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        `}
`;

export const RefreshButton = (props: Props) => {
    const { refresh, status } = props;

    let color: string;
    let disabled = false;
    let loading = false;
    let text: string;
    switch (status) {
        case 'refreshing':
            color = '#698FBA';
            disabled = true;
            loading = true;
            text = 'Refreshing...';
            break;
        case 'error_refreshing':
            color = '#DF4B59';
            disabled = true;
            text = 'Something went wrong :(';
            break;
        default:
            color = '#3F8CE4';
            text = 'Refresh timeline';
    }

    return (
        <Button
            color={color}
            disabled={disabled}
            onClick={refresh}
            data-cy="refresh-comms"
        >
            <Icon size={22} $spin={loading} /> {text}
        </Button>
    );
};
