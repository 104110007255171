import styled from 'styled-components';
import React from 'react';
import { StatusContainer } from '$ui/Timeline/Parts/Attachments/Images';
import { Icon } from '$ui/Flo/Icon';

interface LoadingAttachmentProps {
    onClick: () => void;
}

export const LoadingAttachment = (props: LoadingAttachmentProps) => {
    const { onClick } = props;
    return (
        <LoadingContainer>
            <Icon icon="LoadingCircle" size={7} spin={true} hue="primary" />
            <ButtonContainer>
                <Icon icon="X" size={3} clickable onClick={onClick} />
            </ButtonContainer>
        </LoadingContainer>
    );
};

const LoadingContainer = styled(StatusContainer)`
    position: relative;
`;

const ButtonContainer = styled.span`
    display: flex;
    flex-direction: column;
    position: absolute;
`;
