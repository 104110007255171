import { Attachment } from '@/types';

export enum EventType {
    Comm = 'communication',
    Note = 'note',
    BusinessEvent = 'business_event',
    Attachment = 'attachment',
}

export enum CommType {
    Email = 'email',
    MarketingEmail = 'marketing_email',
    SMS = 'sms',
    PhoneCall = 'call',
    WhatsAppMessage = 'whatsapp_message',
    FormSubmission = 'form_submission',
}

export interface Event {
    type: EventType;
    id: string;
    datetime: string;
}

export interface Comm extends Event {
    comm_type: CommType;
    text_content: string;
    inbound: boolean;
    status: string;
    read_at?: string;
    attachments?: Attachment[] | null;
    identity?: string;
    retry: {
        active: boolean;
        count: number;
    };
}

export interface Call extends Comm {}

export interface Email extends Comm {
    subject: string;
}

export interface SMS extends Comm {}

export interface WhatsApp extends Comm {}

export interface Note extends Event {
    content: string;
    failed?: boolean;
}

export interface FormSubmission extends Comm {
    message: string;
    form: string;
}

export interface BusinessEvent extends Event {
    headline: string;
    description: string;
    note?: string;
    at: string;
    business_event_type: {
        name: string;
        icon: string;
        color: string;
        tooltip?: string;
    };
}

export interface AttachmentEvent extends Event {
    comm_type: CommType;
    inbound: boolean;
    attachments: Attachment[];
}

export type EventPayload =
    | Email
    | Note
    | FormSubmission
    | SMS
    | Call
    | BusinessEvent
    | WhatsApp
    | AttachmentEvent;

export type CommEventUnion = Email | SMS | Call | WhatsApp | FormSubmission;

export type EventUnion =
    | Note
    | CommEventUnion
    | BusinessEvent
    | AttachmentEvent;

export type Events = EventUnion[];
