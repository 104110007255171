import styled, { css, keyframes } from 'styled-components';
import { RefreshCw } from 'react-feather';
import { mix } from '$ui/Flo/util';

interface IconProps {
    $spin?: boolean;
}

export const Icon = styled(RefreshCw)<IconProps>`
    ${mix.margin({ margin: [0, 1, 0, 0] })};

    ${(props) =>
    props.$spin &&
    css`
            animation-name: ${rotate};
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        `}
`;

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
`;
