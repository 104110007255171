import { FloatingTextField, Label } from '$ui/Flo/Field/FloatingTextField';
import { PhotoUpload } from '$ui/Flo/PhotoUpload';
import { Text } from '$ui/Flo/Text';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import { FormGrid } from './FormGrid';
import Select from 'react-select';

export interface DetailsRecord {
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    region?: string | null;
    representative?: string | null;
    signature?: {
        enabled: boolean;
        html: string;
    };
}

export type DetailsValidity = {
    [Property in keyof DetailsRecord]: boolean;
};

interface DetailsProps {
    details: DetailsRecord;
    onChange: (details: DetailsRecord, validity: DetailsValidity) => void;
    loading?: boolean;
    repPhoto: string | File | null;
    onPhotoUploaded: (file: File) => void;
}

export const Details = ({
    details,
    loading = false,
    onChange,
    repPhoto,
    onPhotoUploaded,
}: DetailsProps) => {
    const onUpdate = (
        field: keyof DetailsRecord,
        value: string,
        isValid?: boolean,
    ) => {
        onChange(
            {
                ...details,
                [field]: value,
            },
            {
                [field]: isValid === undefined || isValid,
            },
        );
    };

    const register =
        (field: keyof DetailsRecord) => (value: string, isValid?: boolean) => {
            onUpdate(field, value, isValid);
        };

    const regionOptions = [
        {
            value: 'england-and-wales',
            label: 'England and Wales',
        },
        { value: 'scotland', label: 'Scotland' },
        {
            value: 'northern-ireland',
            label: 'Northern Ireland',
        },
    ];
    const regionValue = regionOptions.find(
        (option) => option.value === details.region,
    );

    return (
        <FormGrid>
            <FloatingTextField
                loading={loading}
                label="Practice Name"
                value={details.name ?? null}
                onChange={register('name')}
                required
                invalidMessage="Enter a practice name"
            />

            <FloatingTextField
                type="phone"
                loading={loading}
                label="Practice Phone Number"
                value={details.phone ?? null}
                onChange={register('phone')}
                required
            />

            <SelectWrapper>
                <Label label={'Practice Region'} />
                <Text level="body2" profile="body">
                    The region affects which public holidays your practice
                    follows.
                </Text>
                <Select
                    className="select"
                    placeholder={'Select a region'}
                    options={regionOptions}
                    value={
                        regionValue ?? {
                            value: 'england-and-wales',
                            label: 'England and Wales',
                        }
                    }
                    isMulti={false}
                    onChange={(option: any) => {
                        register('region')(option.value);
                    }}
                    isSearchable={false}
                    components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                    }}
                    isDisabled={false}
                />
            </SelectWrapper>

            <FloatingTextField
                loading={loading}
                label="Practice Representative"
                value={details.representative ?? null}
                onChange={register('representative')}
                required
                invalidMessage="Enter a representative name"
            />

            <RepPhotoField photo={repPhoto} onChange={onPhotoUploaded} />
        </FormGrid>
    );
};

interface RepPhotoFieldProps {
    photo: string | File | null;
    onChange: (photo: File) => void;
}

const RepPhotoField = ({ photo, onChange }: RepPhotoFieldProps) => {
    return (
        <div style={{ width: '70%' }}>
            <Text level="body2" profile="body" bold>
                <span>Practice Representative Photo</span>
            </Text>
            <PhotoFieldGrid>
                <PhotoCell recommended={true}>
                    <PhotoUpload
                        label="Add a photo"
                        onUpload={onChange}
                        photo={photo}
                        recommended={{ width: '250px', height: '250px' }}
                    />
                </PhotoCell>
                <SecondaryCell>
                    <div>
                        Upload a photo of your treatment co-ordinator here. For
                        best results, use a square photo.
                    </div>
                    {/*<ButtonContainer>
                        <Button
                            onClick={() => onChange(null)}
                            icon="Trash"
                            hue="red"
                            mode="outline"
                        >
                            Remove Photo
                        </Button>
                    </ButtonContainer>*/}
                </SecondaryCell>
            </PhotoFieldGrid>
        </div>
    );
};

const PhotoFieldGrid = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 2 })};
    ${mix.margin({ margin: [2, 0, 0, 0] })};
`;

const PhotoCell = styled.div<{ recommended?: boolean }>`
    width: ${mix.unit({ size: 18 })};
    height: ${mix.unit({ size: 18 })};
    flex: 0 0 auto;
    ${({ recommended }) =>
        recommended &&
        css`
            margin: 0 2rem 2rem 0;
        `}
`;

const SecondaryCell = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;

    .select {
        margin-top: 0.7rem;
    }
`;
