import { createMachine } from 'xstate';

const DONE_WAIT_INTERVAL = 2000;

const editorStates = {
    initial: 'idle',
    states: {
        idle: {
            on: {
                DELETE: { target: 'confirming' },
                SAVE: { target: 'save' }
            }
        },
        confirming: {
            on: {
                CONFIRM: { target: 'delete' },
                CANCEL: { target: 'idle' }
            }
        },
        delete: {
            initial: 'deleting',
            states: {
                deleting: {
                    on: {
                        DONE: { target: 'deleted' },
                        FAIL: { target: 'failed' }
                    }
                },
                deleted: {
                    entry: ['reloadAction'],
                    after: {
                        [DONE_WAIT_INTERVAL]: { target: 'done' }
                    }
                },
                failed: {
                    on: {
                        DELETE: { target: 'deleting' }
                    }
                },
                done: {
                    type: 'final' as const
                }
            },
            onDone: 'done'
        },
        save: {
            initial: 'saving',
            states: {
                saving: {
                    on: {
                        DONE: { target: 'saved' },
                        FAIL: { target: 'failed' }
                    }
                },
                saved: {
                    entry: ['reloadAction'],
                    after: {
                        [DONE_WAIT_INTERVAL]: { target: 'done' }
                    }
                },
                failed: {
                    on: {
                        SAVE: { target: 'saving' }
                    }
                },
                done: {
                    type: 'final' as const
                }
            },
            onDone: 'done'
        },
        done: {
            type: 'final' as const
        }
    }
};

export const fsm = createMachine({
    id: 'segment-editor',
    initial: 'closed',
    states: {
        closed: {
            on: {
                TOGGLE: { target: 'opened' }
            }
        },
        opened: {
            on: {
                TOGGLE: { target: 'closed' }
            },
            onDone: 'closed',
            ...editorStates
        }
    }
});
