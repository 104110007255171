import React from 'react';
import styled, { css } from 'styled-components';
import { ColorToken, TypeLevel } from '../types';
import { applyTextColor, applyType, scale } from '../util';

type NumericLevel = '1' | '2' | '3' | '4' | '5' | '6';
type Tag = 'div' | `h${NumericLevel}`;

export interface HeadingProps {
    children?: React.ReactNode;
    level: TypeLevel;
    dark?: boolean;
    profile?: 'title' | 'body' | 'secondary' | 'disabled';
    bold?: boolean;
    align?: string;
    vSpace?: number;
    lineHeight?: string;
    fontColor?: ColorToken;
}

const getElement = (level: TypeLevel): Tag =>
    (/h{1,6}/.test(level) ? level : 'div') as Tag;

const Wrapper = styled.div<HeadingProps>`
    ${applyType};
    ${(props) =>
        {
            if (props.fontColor) {
                return css`
                  color: var(--${props.fontColor});
                `;
            }

            return applyTextColor({
                profile: 'title',
                ...props
            })
        }
    }
    margin: 0 0 ${({ theme, vSpace }) => scale(theme, vSpace ?? 0)}px;
    display: flex;
    flex-direction: column;
    justify-content: ${({ align }) => align ?? 'center'};
    align-items: ${({ align }) => align ?? 'center'};
    text-align: ${({ align }) => align ?? 'center'};

    ${({ lineHeight }) =>
        lineHeight &&
        css`
            line-height: ${lineHeight};
        `};
`;

export const Heading = ({ children, level, ...props }: HeadingProps) => {
    const Tag = getElement(level);
    return (
        <Wrapper as={Tag} {...props} level={level} role="heading">
            {children}
        </Wrapper>
    );
};
