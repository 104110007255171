import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    initial?: boolean;
}

export const Empty = ({ initial }: Props) => {
    return (
        <Container>
            <Icon />
            {!initial && (
                <Heading level="h5">No patients match your search</Heading>
            )}
            <Heading level="h6" profile="secondary">
                Use the filters above to search for patients
            </Heading>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0;
    height: 100%;
    gap: ${mix.unit({ size: 2 })};
`;

const Icon = () => {
    return (
        <svg
            width="125"
            height="124"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M88.542 108.5V98.167a20.583 20.583 0 00-6.102-14.614A20.919 20.919 0 0067.708 77.5H26.042a20.919 20.919 0 00-14.732 6.053 20.583 20.583 0 00-6.102 14.614V108.5M46.875 56.833c11.506 0 20.833-9.252 20.833-20.666S58.381 15.5 46.875 15.5c-11.506 0-20.833 9.253-20.833 20.667 0 11.414 9.327 20.666 20.833 20.666zM119.792 108.5V98.167a20.565 20.565 0 00-4.368-12.647 20.848 20.848 0 00-11.257-7.348M83.333 16.172a20.847 20.847 0 0111.29 7.348A20.56 20.56 0 0199 36.193a20.56 20.56 0 01-4.376 12.672 20.848 20.848 0 01-11.29 7.348"
                stroke="#237EE7"
                strokeOpacity=".58"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
