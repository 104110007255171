import {
    CountryCode as CountryCodeLib,
    ParseError,
    parsePhoneNumber,
} from 'libphonenumber-js';
import { memoize } from './fn';

export enum CountryCode {
    GB = 'GB',
    IE = 'IE',
}

const USER_ERRORS = [
    'INVALID_COUNTRY',
    'TOO_SHORT',
    'TOO_LONG',
    'NOT_A_NUMBER',
];

export const format = memoize(
    (num: string, country: CountryCode = CountryCode.GB): string => {
        if (!num) {
            return '';
        }
        try {
            const number = parsePhoneNumber(num, country);

            if (!number) {
                return '';
            }

            if (number.country !== country) {
                return number.formatInternational();
            }

            return number.formatNational();
        } catch (e) {
            // libphonenumber throws an exception when a phone number is too
            // short, too long or has an invalid country. We know this is
            // invalid, but we don't want to crash when this happens. Instead,
            // we return the phone number unchanged since formatting is not
            // possible.
            if (e instanceof ParseError && USER_ERRORS.includes(e.message)) {
                return num;
            }

            throw e;
        }
    },
);

export const is = memoize(
    (num: string, country: CountryCode = CountryCode.GB): boolean => {
        if (!num) {
            return false;
        }
        try {
            const number = parsePhoneNumber(num, country as CountryCodeLib);
            if (!number) {
                return false;
            }

            return number.isValid();
        } catch (e) {
            return false;
        }
    },
);
