import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Align, Hue, Shade } from '../types';
import { mix } from '../util';

interface Props {
    /**
     * If `true`, tooltip is open.
     */
    open?: boolean;

    /**
     * Background color hue.
     *
     * @default 'primary'
     */
    hue?: Hue;

    /**
     * Background color shade.
     *
     * @default '5'
     */
    shade?: Shade;

    /**
     * Tooltip content.
     */
    children: React.ReactNode;

    /**
     * If `true`, use light text (for dark backgrounds).
     *
     * @default true
     */
    dark?: boolean;

    /**
     * Horizontal alignment mode of the tooltip.
     *
     * @default 'left'
     */
    align?: Align;

    /**
     * Width of the tooltip.
     */
    width?: number;

    /**
     * Number of milliseconds to wait before signalling to close the tooltip.
     *
     * If `0`, tooltip never signals when to close.
     *
     * @default 5000
     */
    closeDelay?: number;

    /**
     * Size of the tooltip.
     */
    size?: 'x-small' | 'small' | 'medium' | 'large';

    /**
     * A callback that is invoked when the Tooltip is closing.
     */
    onClose?: () => void;
    backgroundColor?: string;
}

export const Tooltip = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        hue = 'primary',
        shade = '6',
        dark = true,
        align = 'left',
        closeDelay = 5000,
        onClose = () => null,
        width,
        open,
        children,
        size = 'medium',
        backgroundColor
    } = props;
    const containerProps = {
        open,
        hue,
        shade,
        dark,
        align,
        width,
        containerSize: size,
        backgroundColor
    };

    React.useEffect(() => {
        if (!open) {
            return;
        }

        if (closeDelay === 0) {
            return;
        }

        if (!onClose) {
            return;
        }

        setTimeout(() => {
            onClose();
        }, closeDelay);
    }, [open]);

    const triangleSize = () => {
        switch (size) {
            case 'x-small':
                return 0.5;
            case 'small':
                return 1;
            case 'large':
                return 3;
            default:
                return 2;
        }
    };

    return (
        <Container ref={ref} {...containerProps}>
            <div
                style={{
                    whiteSpace: width ? 'normal' : 'nowrap',
                    textAlign: align === 'center' ? 'center' : 'left'
                }}
            >
                {children}
            </div>
            <Triangle {...containerProps} size={triangleSize()} />
        </Container>
    );
});

const positionRight = (size: string | undefined) => {
    switch(size) {
        case 'x-small':
            return css`${mix.unit( { size: 0.5 } )}`;
        default:
            return css`-${mix.unit( { size: 1 } )}`;
    }
}

const positionLeft = (size: string | undefined) => {
    switch(size) {
        case 'x-small':
            return css`-${mix.unit( { size: 0.5 } )}`;
        default:
            return css`-${mix.unit( { size: 1 } )}`;
    }
}

interface ContainerProps {
    open?: boolean;
    hue: Hue;
    shade: Shade;
    align: Align;
    width?: number;
    containerSize?: 'x-small' | 'small' | 'medium' | 'large';
    backgroundColor?: string;
}

const SizeExtraSmall = css`
    ${mix.padding({ padding: 1 })};
    ${mix.type({ level: 'small' })};
    top: -${mix.unit({ size: 1 })};
`;

const SizeSmall = css`
    ${mix.padding({ padding: 1 })};
    ${mix.type({ level: 'small' })};

    top: -${mix.unit({ size: 2 })};
`;

const SizeMedium = css`
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'body2' })};
    top: -${mix.unit({ size: 3 })};
`;

const SizeLarge = css`
    ${mix.padding({ padding: 3 })};
    ${mix.type({ level: 'body1' })};
    top: -${mix.unit({ size: 4 })};
`;

const Container = styled.div<ContainerProps>`
    ${({ backgroundColor }) =>
        css `background: ${backgroundColor} !important`};
    ${mix.bg()};
    ${({ containerSize }) => {
        switch (containerSize) {
            case 'x-small':
                return SizeExtraSmall;
            case 'small':
                return SizeSmall;
            case 'large':
                return SizeLarge;
            default:
                return SizeMedium;
        }
    }};

    color: #fff;
    position: absolute;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;
    pointer-events: none;

    ${({ width }) =>
        width &&
        css`
            width: ${mix.unit({ size: width })};
        `};

    ${({ align, open, containerSize }) => {
        if (align === 'center') {
            return css`
                left: 50%;
                transform: translate(-50%, -100%) scale(0);
                transform-origin: bottom center;

                ${open &&
                css`
                    transform: translate(-50%, -100%) scale(1);
                `};
            `;
        }

        if (align === 'right') {
            return css`
                right: ${positionRight(containerSize)};
                transform: translate(${mix.unit({ size: 1 })}, -100%) scale(0);

                ${open &&
                css`
                    transform: translate(${mix.unit({ size: 1 })}, -100%)
                        scale(1);
                `};
            `;
        }

        return css`
            left: ${positionLeft(containerSize)};
            transform: translateY(-100%) scale(0);

            ${open &&
            css`
                transform: translateY(-100%) scale(1);
            `};
        `;
    }}
`;

interface TriangleProps {
    size: number;
}

const Triangle = styled.div<ContainerProps & TriangleProps>`
    position: absolute;
    width: 0;
    height: 0;
    border-left: ${mix.unit()} solid transparent;
    border-right: ${mix.unit()} solid transparent;
    ${({ backgroundColor }) => backgroundColor ? css `border-top:${mix.unit()} solid ${backgroundColor}` : css `border-top:${mix.unit()} solid ${mix.palette()}`};
    bottom: -${mix.unit()};

    ${({ align }) => {
        if (align === 'center') {
            return css`
                left: 50%;
                transform: translateX(-50%);
            `;
        }

        if (align === 'right') {
            return css`
                right: ${mix.unit({ size: 1 })};
            `;
        }

        return css`
            left: ${mix.unit({ size: 1 })};
        `;
    }}
`;
