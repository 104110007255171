import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { NodeType } from '$state/types/automations';

export type ConnectorType = `${NodeType}-${NodeType}`;

interface Props {
    type: ConnectorType;
}

export const Connector = (props: Props) => {
    return (
        <Container type={props.type} data-testid={'connector-' + props.type}>
            <Line />
        </Container>
    );
};

const Container = styled.div<{ type: ConnectorType }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    ${mix.margin({ margin: [0, 0, 0, 2.5] })};
    ${({ type }) => {
        switch (type) {
            case 'action-action':
                return css`
                    height: ${mix.unit({ size: 3 })};
                `;
            default:
                return css`
                    height: ${mix.unit({ size: 2 })};
                `;
        }
    }}
`;

const Line = styled.span`
    width: 0;
    height: 100%;
    border-right: 1px solid ${mix.palette({ hue: 'grey', shade: '5' })};
    position: relative;
    transform: translateX(-50%);
`;
