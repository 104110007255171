import React from 'react';
import {
    DentallyManage,
    DentallyRepair,
    DentallySetup,
    Loading
} from '$ui/Integrations/Workflows';
import { useWorkflowSwitch } from '$ui/Integrations/Workflows/workflows';

export const ConnectedDentally = () => {
    const { integration, loadingStatus, commonProps } =
        useWorkflowSwitch('dentally');

    if (loadingStatus !== 'loaded') {
        return <Loading integration="Dentally" onFinish={commonProps.onFinish} />;
    }

    if (integration.state === 'healthy') {
        return <DentallyManage {...commonProps} />;
    }

    if (integration.state === 'unhealthy') {
        return <DentallyRepair {...commonProps} />;
    }

    return <DentallySetup {...commonProps} />;
};

export * from './DentallyManage';
export * from './DentallyRepair';
export * from './DentallySetup';
