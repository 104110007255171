import {RefObject, useEffect, useRef} from 'react';

/**
 * Sets the page title. Doesn't do much now but eventually we will want to
 * render notification state in the page title, so it makes sense to have a
 * hook for it to build on.
 *
 * @param title
 */
export const usePageTitle = (title: string) => {
    useEffect(() => {
        document.title = `${title} - Leadflo`;
    }, [title]);
};

interface HoverProps<T extends HTMLElement> {
    ref?: RefObject<T>;
    onEnter?: () => void;
    onLeave?: () => void;
}

export const useHover = <T extends HTMLElement>({
    ref,
    onEnter = () => null,
    onLeave = () => null
}: HoverProps<T>) => {
    const internalRef = useRef<T | null>(null);

    useEffect(() => {
        const actualRef = ref ?? internalRef;
        const node = actualRef.current;

        if (node) {
            node.addEventListener('mouseenter', onEnter);
            node.addEventListener('mouseleave', onLeave);
        }

        return () => {
            if (node) {
                node.removeEventListener('mouseenter', onEnter);
                node.removeEventListener('mouseleave', onLeave);
            }
        };
    }, [ref, onEnter, onLeave]);

    return { ref: ref ?? internalRef };
};
