import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconName } from '../Icon';
import { Text } from '../Text';
import { applyUnit, mix } from '../util';

interface Props {
    label?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange: (state: boolean) => void;
    size?: 'big' | 'small' | 'x-small';
    theme?: 'default' | 'blue';
    invisible?: boolean;
    icon?: IconName;
}

export const Check = (props: Props) => {
    const {
        checked,
        disabled,
        onChange,
        label,
        size = 'big',
        theme = 'default',
        invisible = false,
        icon = 'Check'
    } = props;

    const Box = theme == 'default' ? WhiteBox : BlueBox;
    const iconColor = theme == 'default' ? 'green' : 'white';
    let boxSize = 0;
    if (size == 'big') {
        boxSize = 3;
    } else if (size == 'small') {
        boxSize = 2.5;
    } else if (size == 'x-small') {
        boxSize = 2;
    }

    return (
        <Item
            onClick={(event) => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                if (!disabled) {
                    onChange(!checked);
                }
            }}
            disabled={disabled}
            invisible={invisible}
        >
            <Box disabled={disabled} size={boxSize} checked={checked}>
                {checked && (
                    <Icon
                        icon={icon}
                        hue={disabled ? 'grey' : iconColor}
                        opacity={1}
                        size={2}
                        clickable={!disabled}
                    />
                )}
            </Box>
            {label && (
                <Label disabled={disabled}>
                    <Text>{label}</Text>
                </Label>
            )}
        </Item>
    );
};

interface BoxProps {
    disabled?: boolean;
    size: number;
    checked?: boolean;
}

const WhiteBox = styled.span<BoxProps>`
    ${mix.round({ rounded: true })}
    border: 2px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    width: ${({ theme, size }) => applyUnit({ theme, size })};
    height: ${({ theme, size }) => applyUnit({ theme, size })};
    box-sizing: border-box;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
        ${({ disabled }) =>
            !disabled &&
            css`
                border: 2px solid ${mix.palette({ hue: 'grey', shade: '6' })};
            `}
    }

    ${({ disabled }) =>
        disabled &&
        css`
            ${mix.bg({ hue: 'grey', shade: '10' })};

            &:hover {
                cursor: not-allowed;
            }
        `}
`;

const BlueBox = styled(WhiteBox)<BoxProps>`
    ${({ checked }) =>
        checked &&
        css`
            background: var(--primary-600);
            border-color: var(--primary-600);
        `}

    ${({ checked, disabled }) =>
        checked &&
        disabled &&
        css`
            opacity: 0.5;
        `}
`;

const Item = styled.div<{ disabled?: boolean; invisible: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: initial;
        `}

    ${({ invisible }) =>
        invisible &&
        css`
            visibility: hidden;
        `};
`;

const Label = styled.div<{ disabled?: boolean }>`
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
