/**
 * Lowercases the first character of the given string
 *
 * @param str
 */
export const lowerFirst = (str: string): string => {
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const capitalize = <T extends string>(str: T): Capitalize<T> => {
    return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
};

/**
 * Formats the given string in camel case fashion
 *
 * camel('hello world')   -> 'helloWorld'
 * camel('va va-VOOM') -> 'vaVaVoom'
 * camel('helloWorld') -> 'helloWorld'
 *
 * From: https://github.com/rayepps/radash/blob/master/src/string.ts
 */
export const camel = (str: string): string => {
    const parts =
        str
            ?.replace(/([A-Z])+/g, capitalize)
            ?.split(/(?=[A-Z])|[.\-\s_]/)
            .map((x) => x.toLowerCase()) ?? [];
    if (parts.length === 0) return '';
    if (parts.length === 1) return parts[0];
    return parts.reduce((acc, part) => {
        return `${acc}${part.charAt(0).toUpperCase()}${part.slice(1)}`;
    });
};

/**
 * Formats the given string in snake case fashion
 *
 * snake('hello world')   -> 'hello_world'
 * snake('va va-VOOM') -> 'va_va_voom'
 * snake('helloWorld') -> 'hello_world'
 *
 * @param str
 */
export const snake = (str: string): string => {
    const parts =
        str
            ?.replace(/([A-Z])+/g, capitalize)
            ?.split(/(?=[A-Z])|[.\-\s_]/)
            .map((x) => x.toLowerCase()) ?? [];
    if (parts.length === 0) return '';
    if (parts.length === 1) return parts[0];
    return parts.join('_');
};

export const truncate = (str: string, limit = 20): string => {
    if (str.length > limit) {
        return str.substring(0, limit) + '...';
    }

    return str;
}

export const replaceUnderscoresWithSpaces = (str: string): string => {
    return str.replace(/_/g, ' ').toLowerCase();
}
