import { Async } from '$types';

export interface ContextStore {
    [patientId: string]: ContextState;
}

export interface ContextState extends Async {
    context?: TemplateContext;
}

export interface ContextNotLoadedState extends ContextState {
    status: 'init' | 'loading';
}

export interface ContextLoadedState extends ContextState {
    status: 'loaded';
    context: TemplateContext;
}

export interface TemplateContext {
    practice: {
        name: string;
        representative: string;
        phone: string;
        email: string;
    };

    patient: {
        first_name: string;
        last_name: string;
        email: string;
        phone: string;
        type: string;
    };

    referrer: {
        name: string | null;
        email: string | null;
    };
}

export interface PlaceholdersStore extends Async {
    placeholders: Array<Placeholder>;
}

export interface PlaceholderLeaf {
    name: string;
    value: string;
}

export interface PlaceholderParent {
    name: string;
    mergeTags: Array<PlaceholderLeaf>;
}

export type Placeholder = PlaceholderParent | PlaceholderLeaf;

export const isPlaceholderParent = (p: Placeholder): p is PlaceholderParent => {
    return (p as PlaceholderParent).mergeTags != undefined;
};
