import * as React from 'react';
import { mix } from '$ui/Flo/util';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { FieldProps } from '$ui/Flo/Field';

interface Props extends FieldProps {
    name: string;
}

export const TextareaField = (props: Props) => {
    const { name } = props;
    const { register } = useFormContext();

   return  <Field as="textarea" {...register(name)}></Field>;
}

const Field = styled.input`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 1 })};
    border-radius: 4px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--gray-100, #f2f4f7);
    display: block;
    width: 100%;

    ::-webkit-calendar-picker-indicator {
        opacity: 0.5;
    }
`;
