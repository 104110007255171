import { api } from '$api';

export interface Match {
    id: number;
    first_name: string;
    last_name: string;
    email_address: string;
    phone: string;
    date_of_birth: string;
    confidence: number;
    image_url?: string;
}

export interface MatchResponse {
    data: Match[];
    total: number;
}

export interface GetMatchParams {
    query: string;
    confidence?: number;
}

export const getMatches = (params: GetMatchParams) =>
    api
        .get(`/dentally/patients`, {
            params: {
                query: params.query ?? undefined,
                confidence: params.confidence ?? undefined
            }
        })
        .then(({ data }) => data);
