import { Button } from '$ui/Flo/Button';
import { ButtonMode, ButtonSize, ColorToken, Hue, Shade } from '$ui/Flo/types';
import React from 'react';
import { IconName } from '$ui/Flo/Icon';

export interface ButtonState {
    doing?: boolean;
    done?: boolean;
    failed?: boolean;
}

export interface ButtonContent {
    spin: boolean;
    icon: IconName;
    label: string;
}

type State = 'init' | 'doing' | 'done' | 'failed';

export type ContentMap = {
    [state in State]: ButtonContent;
};

interface ButtonProps extends ButtonState {
    disabled?: boolean;
    onClick: React.MouseEventHandler;
    size: ButtonSize;
    label?: string;
    id?: string;
    mode?: ButtonMode;
    tooltip?: string;
    color?: ColorToken;
    disableIcon?: boolean;
}

interface AsyncButtonProps extends ButtonProps {
    hue: Hue;
    shade?: Shade;
    color?: ColorToken;
    content: ContentMap;
}

export const AsyncButton = ({
    onClick,
    id,
    disabled = false,
    size,
    mode,
    hue,
    shade = '6',
    color,
    tooltip,
    content,
    disableIcon,
    ...state
}: AsyncButtonProps) => {
    const { spin, icon, label } = content[transduce(state)];
    return (
        <Button
            id={id}
            onClick={onClick}
            disabled={disabled}
            spin={spin}
            rounded
            icon={!disableIcon ? icon : undefined}
            size={size}
            mode={mode}
            hue={hue}
            shade={shade}
            color={color}
            tooltip={tooltip}
        >
            {label}
        </Button>
    );
};

const transduce = ({ doing, done, failed }: ButtonState): State => {
    if (doing) {
        return 'doing';
    }

    if (done) {
        return 'done';
    }

    if (failed) {
        return 'failed';
    }

    return 'init';
};

interface ContentMapBuilderArgs {
    icon: IconName;
    do: string;
    doing: string;
    done: string;
}

export const buildContentMap = (args: ContentMapBuilderArgs): ContentMap => ({
    init: {
        spin: false,
        icon: args.icon,
        label: args.do,
    },

    doing: {
        spin: true,
        icon: 'Loader',
        label: args.doing,
    },

    done: {
        spin: false,
        icon: 'Check',
        label: args.done,
    },

    failed: {
        spin: false,
        icon: 'RotateCw',
        label: 'Failed. Retry?',
    },
});

export const SaveButton = (props: ButtonProps) => {
    const content = buildContentMap({
        do: props.label ?? 'Save',
        doing: 'Saving',
        done: 'Saved',
        icon: 'Save',
    });

    return (
        <AsyncButton {...props} mode="solid" hue="primary" content={content} />
    );
};

export const DeleteButton = (props: ButtonProps) => {
    const content = buildContentMap({
        do: props.label ?? 'Delete',
        doing: 'Deleting',
        done: 'Deleted',
        icon: 'Trash',
    });
    const { mode = 'text' } = props;

    return (
        <AsyncButton
            {...props}
            mode={mode}
            hue="red"
            shade="7"
            content={content}
        />
    );
};
