import { Button } from '$ui/Flo/Button';
import styled from 'styled-components';
import React from 'react';
import { StatusContainer } from '$ui/Timeline/Parts/Attachments/Images';

interface NotFoundProps {
    onClick: () => void;
}

export const NotFoundAttachment = ({ onClick }: NotFoundProps) => {
    return (
        <NotFoundContainer>
            <span>Oops, something went wrong</span>
            <Button icon="RotateCw" mode="outline" hue="red" onClick={onClick}>
                Try again
            </Button>
        </NotFoundContainer>
    );
};

const NotFoundContainer = styled(StatusContainer)`
    padding: 16px;
    gap: 12px;
`;
