import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileDefault = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 38 46"
        >
            <path d="M24.172 0H5C3.674 0 2.40199 0.526989 1.46399 1.46399C0.526989 2.40199 0 3.674 0 5C0 13.367 0 32.633 0 41C0 43.761 2.239 46 5 46C11.926 46 26.074 46 33 46C35.761 46 38 43.761 38 41C38 41 38 15.475 38 13.828C38 13.033 37.684 12.27 37.121 11.707L26.293 0.878998C25.73 0.315998 24.967 0 24.172 0ZM36 15C36 20.177 36 34.094 36 41C36 42.657 34.657 44 33 44H5C3.343 44 2 42.657 2 41C2 32.633 2 13.367 2 5C2 4.204 2.316 3.441 2.879 2.879C3.441 2.316 4.204 2 5 2H23C23.265 2 23.52 2.105 23.707 2.293C23.895 2.48 24 2.735 24 3V9C24 10.326 24.527 11.598 25.464 12.536C26.402 13.473 27.674 14 29 14H35C35.552 14 36 14.448 36 15ZM26 3.414V9C26 9.796 26.316 10.559 26.879 11.121C27.441 11.684 28.204 12 29 12H34.586L26 3.414Z" />
        </svg>
    );
};
