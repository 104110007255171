import React from 'react';
import { Icon } from '$ui/Flo/Icon';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    negated?: boolean;
    stages: string[];
    requiresCustomisation?: boolean;
}

export const ActionCondition = ({
    negated = false,
    stages,
    requiresCustomisation = false,
}: Props) => {
    return (
        <Container requiresCustomisation={requiresCustomisation}>
            <Icon
                icon="AlertCircle"
                size={2}
                hue="grey"
                shade="9"
                opacity={1}
            />
            <div>
                If the patient {negated ? "isn't" : 'is'} in{' '}
                <Stages stages={stages} />
            </div>
        </Container>
    );
};

interface StagesProps {
    stages: string[];
}

const Stages = ({ stages }: StagesProps) => {
    const rendered = stages.map((stage) => <Stage key={stage}>{stage}</Stage>);

    if (stages.length === 1) {
        return rendered[0];
    }

    const [last, ...rest] = [...rendered].reverse();
    const [initial, ...toAccumulate] = rest.toReversed();
    const first = toAccumulate.reduce(
        (prev, curr) => (
            <>
                {prev}, {curr}
            </>
        ),
        initial,
    );

    return (
        <>
            {first} and {last}
        </>
    );
};

const Stage = styled.span`
    ${mix.type({ level: 'small', bold: true })};
`;

const Container = styled.div<{ requiresCustomisation: boolean }>`
    ${mix.bg({ hue: 'primary', shade: '6' })};
    ${mix.padding({ padding: [0.5] })};
    ${mix.gap({ size: 1 })};
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'title', dark: true })};
    display: flex;
    align-items: center;

    ${({ requiresCustomisation }) =>
        requiresCustomisation &&
        css`
            ${mix.bg({ hue: 'red', shade: '4' })};
        `};
`;
