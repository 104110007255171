/**
 * Store for patient-specific template contexts
 *
 * We store these here so we can make use of these variables when hydrating
 * message templates with patient data.
 */
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import * as api from '$api/patients';
import { RootState } from '$state';
import {
    ContextState,
    ContextStore,
    TemplateContext
} from '$state/types/contexts';
import { AsyncStatus } from '$types';

export const loadContext = createAsyncThunk<TemplateContext, string>(
    'contexts/load',
    (patientId: string): Promise<TemplateContext> => {
        return api.loadTemplateContext(patientId);
    }
);

const initialState: ContextStore = {};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(loadContext.pending, (state, action) => {
            const { arg: patientId } = action.meta;

            state[patientId] = {
                status: 'loading'
            };
        })

        .addCase(loadContext.fulfilled, (state, action) => {
            const { arg: patientId } = action.meta;

            state[patientId] = {
                status: 'loaded',
                context: action.payload
            };
        })

        .addCase(loadContext.rejected, (state, action) => {
            const { arg: patientId } = action.meta;

            state[patientId] = {
                status: 'errored'
            };
        });
});

export const selectContext =
    (patientId: string) =>
    (rootState: RootState): ContextState | undefined =>
        rootState.contexts[patientId];

export const selectStatus =
    (patientId: string) =>
    (rootState: RootState): AsyncStatus => {
        const context = selectContext(patientId)(rootState);

        if (!context) {
            return 'init';
        }

        return context.status;
    };
