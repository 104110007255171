import { ConnectedSearch } from '$ui/Search';
import * as React from 'react';
import styled from 'styled-components';
import LogoSvg from '$resources/logo-o.svg';
import { mix } from '$ui/Flo/util';
import { HeaderNotifications } from '$ui/Notifications/HeaderNotifications';
import { IMessage } from '@novu/notification-center';

interface Props {
    onLogoClicked: () => void;
    onAddPatientClicked: () => void;
    addEnabled?: boolean;
    subscriberId: string;
    subscriberHash: string;
    appId?: string;
    onNotificationClick?: (message: IMessage) => void;
}

const Container = styled.header`
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2000;
    height: 54px;
    background: #111930;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0 2px rgba(255, 255, 255, 0.1);
`;

const LogoButton = styled.a`
    padding: 3rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    font-size: 18px;

    :hover {
        cursor: pointer;
        text-decoration: none;
    }
`;

const Logo = styled(LogoSvg)`
    fill: white;
    opacity: 0.75;
    ${mix.width({ size: 3 })};
    shape-rendering: geometricPrecision;
`;

const AddPatientButton = styled.a`
    background: #35b760;
    color: rgba(255, 255, 255, 0.87);
    padding: 18px;
    display: flex;
    align-items: center;
    text-decoration: none;
    box-sizing: border-box;
    height: 100%;

    :hover {
        cursor: pointer;
        text-decoration: none;
        color: #fff;
    }
`;

const SearchContainer = styled.div`
    height: 100%;
    flex: 1 0;
`;

const NotificationsContainer = styled.div`
    height: 100%;
    padding: 0 ${mix.unit({ size: 1.5 })};
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mix.bg({ hue: 'primary', shade: '2' })};
`;

export const Header = (props: Props) => {
    const {
        onLogoClicked,
        onAddPatientClicked,
        addEnabled = true,
        subscriberId,
        subscriberHash,
        appId,
        onNotificationClick
    } = props;

    return (
        <Container>
            <LogoButton onClick={onLogoClicked}>
                <Logo />
            </LogoButton>

            <SearchContainer>
                <ConnectedSearch />
            </SearchContainer>

            <NotificationsContainer>
                <HeaderNotifications
                    subscriberId={subscriberId}
                    subscriberHash={subscriberHash}
                    appId={appId}
                    onNotificationClick={onNotificationClick}
                />
            </NotificationsContainer>

            {addEnabled && (
                <AddPatientButton
                    onClick={onAddPatientClicked}
                    aria-label="Add New Patient"
                    data-cy="new-patient-button"
                >
                    <i className="zmdi zmdi-plus-circle-o button-add-patient-icon"></i>
                    <span>
                        <span>Add Patient</span>
                    </span>
                </AddPatientButton>
            )}
        </Container>
    );
};
