import { assign, createMachine, AnyEventObject } from 'xstate';
import { deleteTemplate, saveTemplate } from '$api/templates';
import { api } from '$api';
import { Template } from '$state/types/templates';
import { Channels } from '$state/types/timeline';

export interface TemplateContext {
    channel: Channels.SMS | Channels.Email | Channels.WhatsApp;
    id: string;
    name: string;
    body: string;
    subject: string;
    approval: boolean | undefined;
    status: string | null;
    channelApproval: boolean | undefined;
    isUnique: boolean;
}

type TemplateEvent =
    | { type: 'save'; data: Template }
    | { type: 'delete'; data: Template }
    | AnyEventObject;

export const fsm = createMachine<TemplateContext, TemplateEvent>(
    {
        id: 'addTemplate',
        initial: 'checkingFormChannel',
        context: {
            id: '',
            channel: Channels.Email,
            name: '',
            body: '',
            subject: '',
            approval: undefined,
            status: null,
            channelApproval: false,
            isUnique: false,
        },
        states: {
            checkingFormChannel: {
                always: [
                    {
                        cond: (context) => context.channelApproval === true,
                        target: 'approvalFlow',
                    },
                    {
                        cond: (context) => context.channelApproval !== true,
                        target: 'handleTemplate',
                    },
                ],
            },
            handleTemplate: {
                on: {
                    save: {
                        target: 'save',
                        actions: 'storeTemplate',
                    },
                    delete: 'delete',
                },
            },
            approvalFlow: {
                on: {
                    save: 'approval',
                    delete: 'delete',
                },
            },
            approval: {
                on: {
                    save: {
                        target: 'save',
                        actions: 'storeTemplate',
                    },
                    back: 'approvalFlow',
                },
            },
            delete: {
                initial: 'confirm',
                states: {
                    confirm: {
                        on: {
                            delete: 'deleting',
                            back: '#addTemplate.handleTemplate',
                        },
                    },
                    deleting: {
                        invoke: {
                            src: 'deleteTemplate',
                            onDone: {
                                target: 'end',
                                actions: 'toast',
                            },
                        },
                    },
                    end: {
                        type: 'final',
                    },
                },
            },
            save: {
                initial: 'duplicates',
                states: {
                    duplicate: {
                        on: {
                            save: {
                                target: 'duplicates',
                                actions: 'storeTemplate',
                                cond: (context) => {
                                    return context.name.length !== 0;
                                },
                            },
                        },
                    },
                    duplicates: {
                        invoke: {
                            src: 'isUnique',
                            onDone: [
                                {
                                    target: 'saving',
                                    actions: 'storeTemplate',
                                    cond: 'isUnique',
                                },
                                {
                                    target: 'duplicate',
                                },
                            ],
                        },
                    },
                    saving: {
                        invoke: {
                            src: 'saveTemplate',
                            onDone: [
                                {
                                    target: 'end',
                                    actions: 'toast',
                                },
                            ],
                        },
                    },

                    end: {
                        type: 'final',
                    },
                },
            },
        },
    },
    {
        guards: {
            isUnique: (_, event) => {
                // This is because the events we define above and invokable
                // events are the same type and is passed here. This is ugly
                // but this narrows things down to the default `AnyEventObject`
                // type.
                if (
                    event.type ===
                    'done.invoke.addTemplate.save.duplicates:invocation[0]'
                ) {
                    return event.data ? true : false;
                }

                return false;
            },
        },
        actions: {
            storeTemplate: assign((context, event) => {
                if (
                    event.type ===
                    'done.invoke.addTemplate.save.duplicates:invocation[0]'
                ) {
                    context.isUnique = event.data ? true : false;
                }

                if (event.data.name) {
                    context.name = event.data.name;
                }
                if (event.data.body) {
                    context.body = event.data.body;
                }
                if (event.data.subject) {
                    context.subject = event.data.subject;
                }
                if (event.data.approval) {
                    context.approval = event.data.approval;
                }
                if (event.data.status) {
                    context.status = event.data.status;
                }
                if (event.data.id) {
                    context.id = event.data.id;
                }
                return context;
            }),
        },
        services: {
            saveTemplate: (data: TemplateContext, event: TemplateEvent) => {
                const template: Template = {
                    ...data,
                    ...event.data,
                };
                return saveTemplate(template);
            },
            deleteTemplate: (data: TemplateContext, event: TemplateEvent) => {
                const template: Template = {
                    ...data,
                    ...event.data,
                };
                return deleteTemplate(template);
            },
            isUnique: (data: TemplateContext) => {
                return api
                    .get(`/templates/${data.name}`, {
                        params: {
                            id: data.id,
                        },
                    })
                    .then(() => {
                        return false;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            return true;
                        }
                        throw new Error('Error checking for duplicates');
                    });
            },
        },
    },
);
