import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import * as React from 'react';
import styled from 'styled-components';

export const StaleWarning = () => {
    return (
        <Container>
            <Icon icon="AlertCircle" hue="white" opacity={1} size={2} />
            <Text>
                Another user has completed this action. You should refresh
                actions due
            </Text>
        </Container>
    );
};

const Text = styled.span`
    margin-left: ${mix.unit({ size: 1 })};
    ${mix.type({ level: 'small' })};
`;

const Container = styled.div`
    background: var(--warning-500);
    border-radius: 4px 4px 0 0;
    color: #FFFFFF;
    font-family: Roboto, serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    ${mix.padding({ padding: [0.5, 1.5] })};
    z-index: 10;
    position: relative;
    bottom: -1px;
`;
