import React from 'react';
import {
    Failed,
    SetupInstructions,
    SignIn,
    Success
} from '$ui/Integrations/Screens';
import WhatsAppLogo from '$resources/whatsapp.svg';
import { Frame } from '$ui/Integrations/Frame';
import { SyncState } from '$types/integrations';
import {
    setup,
    SetupContext,
    useWorkflow
} from '$ui/Integrations/Workflows/workflows';
import { whatsapp as instructions } from '$ui/Integrations/instructions';

interface Props {
    auth: () => Promise<void>;
    sync: () => void;
    syncState: SyncState;
    onFinish: () => void;
}

// TODO: Refactor this when introducing a new integration
export const WhatsAppSetup = ({ auth, sync, onFinish, syncState }: Props) => {
    const { matches, onSync, onSignIn, onNext, onBack, context } =
        useWorkflow<SetupContext>({
            healthyState: 'success',
            workflow: setup,
            syncState,
            auth,
            sync
        });

    const next = !['signIn', 'failed'].some(matches);
    const back = !matches('instructions');
    const last = matches('success');

    return (
        <Frame
            integration="WhatsApp"
            mode="Set up"
            next={next}
            back={back}
            last={last}
            onExit={onFinish}
            onNext={onNext}
            onFinish={onFinish}
            onBack={onBack}
        >
            {matches('instructions') && (
                <SetupInstructions
                    instructions={instructions}
                    integration="WhatsApp"
                    vimeoEmbedUrl="https://player.vimeo.com/video/768966345?h=40bfdbe736&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    introTitle="Follow the instructions to connect WhatsApp to your Leadflo SMS number"
                    introText=""
                />
            )}

            {matches('signIn') && (
                <SignIn
                    logo={WhatsAppLogo}
                    integration="WhatsApp"
                    onSignIn={onSignIn}
                    signingIn={matches('signIn.signingIn')}
                    introTitle="Leadflo's WhatsApp integration is powered by our integration partner, 360dialog. To enable WhatsApp on your Leadflo account, create and sign into a 360dialog account by clicking the button below"
                    introText="360dialog will open in a new window"
                    signInButtonText="Open 360dialog"
                />
            )}

            {matches('success') && (
                <Success
                    integration="WhatsApp"
                    onSync={onSync}
                    syncing={matches('success.syncing')}
                />
            )}

            {matches('failed') && (
                <Failed
                    integration="WhatsApp"
                    mode={context.failureMode}
                    onSignIn={onSignIn}
                    signingIn={matches('failed.signingIn')}
                />
            )}
        </Frame>
    );
};
