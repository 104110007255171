import { workflowLoaded } from '$state/queries/automations';
import { Trigger, Workflow } from '$state/types/automations';
import { Label } from '$ui/Flo/Label';
import { Shadow } from '$ui/Flo/Shadow';
import { Text } from '$ui/Flo/Text';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
    workflow?: Workflow;
}

export const Triggers = (props: Props) => {
    const workflow = props.workflow;

    if (!workflow || !workflowLoaded(workflow)) {
        return <Loading />;
    }

    return (
        <Container>
            <Subtitle>Started by</Subtitle>
            <Section trigger={workflow.startTrigger} />
            <Subtitle>Ended by</Subtitle>
            <Section trigger={workflow.endTrigger} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    ${mix.round({ rounded: true })}
    ${mix.shadow()}
    width: 100%;
    ${mix.padding({ padding: [4, 0] })}
`;

const Subtitle = styled.span`
    ${mix.type({ level: 'h5', bold: true })}
    ${mix.color({ profile: 'body' })}
    border-color: ${mix.palette({ hue: 'grey', shade: '9' })};
    border-style: solid;
    border-width: 2px 0;
    ${mix.padding({ padding: [0, 3] })}
`;

const Section = ({ trigger }: { trigger: Trigger | null }) => {
    if (!trigger) {
        return (
            <SectionContainer>
                <Text level="body1" profile="secondary">
                    Finishing the automation
                </Text>
            </SectionContainer>
        );
    }
    const stages = trigger.stages.map((stage) => (
        <Label hue="primary" shade="6" key={stage.id}>
            {stage.name}
        </Label>
    ));
    return (
        <SectionContainer>
            <Text level="body1" profile="secondary">
                {trigger.direction == 'in'
                    ? 'Moving into stage...'
                    : 'Moving into stages that are not...'}
            </Text>
            <Spacer />
            <Stages>{stages}</Stages>
            <Spacer size={10} />
            {trigger.referral !== null && (
                <Text level="body1" profile="secondary">
                    {trigger.referral === true
                        ? 'when the patient is a referral'
                        : 'when the patient is not a referral'}
                </Text>
            )}
        </SectionContainer>
    );
};

const Stages = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: ${mix.unit({ size: 1 })};
`;

const Spacer = styled.div<{ size?: number }>`
    height: ${({ size }) => mix.unit({ size: size ?? 1 })};
`;

const SectionContainer = styled.div`
    ${mix.padding({ padding: [1, 3, 1, 3] })}
`;

const Loading = () => {
    return (
        <Container>
            <Subtitle>Started by</Subtitle>
            <SectionContainer>
                <Shadow width={40} height={2} rounded />
                <Spacer />
                <Stages>
                    {Array(2).fill(<Shadow width={20} height={3} rounded />)}
                </Stages>
            </SectionContainer>
            <Subtitle>Ended by</Subtitle>
            <SectionContainer>
                <Shadow width={58} height={2} rounded />
            </SectionContainer>
        </Container>
    );
};
