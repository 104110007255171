import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileOgg = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.33L66.8 23.39L67.3 23.32C67.2372 22.8217 67.0123 22.3579 66.66 22L45.85 1.16C45.7351 1.04307 45.6076 0.939113 45.47 0.849998L45.37 0.789998C45.2632 0.727382 45.153 0.670631 45.04 0.619998L44.86 0.559999C44.7653 0.524056 44.6684 0.493998 44.57 0.469998C44.4086 0.43804 44.2445 0.4213 44.08 0.419998H10.68C10.0178 0.422631 9.38341 0.686869 8.91513 1.15514C8.44686 1.62341 8.18263 2.25777 8.17999 2.92V29.77C8.17999 30.433 8.44338 31.0689 8.91223 31.5378C9.38107 32.0066 10.017 32.27 10.68 32.27C11.343 32.27 11.9789 32.0066 12.4478 31.5378C12.9166 31.0689 13.18 30.433 13.18 29.77V5.42H41.58V23.56C41.58 24.2213 41.842 24.8557 42.3087 25.3242C42.7754 25.7928 43.4087 26.0574 44.07 26.06L62.33 26.16V74.58H13.18V70.7C13.18 70.037 12.9166 69.4011 12.4478 68.9322C11.9789 68.4634 11.343 68.2 10.68 68.2C10.017 68.2 9.38107 68.4634 8.91223 68.9322C8.44338 69.4011 8.17999 70.037 8.17999 70.7V77.08C8.18263 77.7422 8.44686 78.3766 8.91513 78.8449C9.38341 79.3131 10.0178 79.5774 10.68 79.58H64.83C65.4922 79.5774 66.1266 79.3131 66.5948 78.8449C67.0631 78.3766 67.3274 77.7422 67.33 77.08V23.68C67.3299 23.5627 67.3199 23.4456 67.3 23.33ZM46.58 21.08V9L58.76 21.14L46.58 21.08Z" />
            <path d="M15.37 45.69C14.9559 45.6426 14.5369 45.7166 14.164 45.903C13.7912 46.0895 13.4806 46.3802 13.27 46.74C12.8055 47.8805 12.6039 49.1109 12.68 50.34C12.5944 51.5426 12.7931 52.7485 13.26 53.86C13.4798 54.2105 13.7952 54.4908 14.169 54.668C14.5429 54.8452 14.9596 54.9118 15.37 54.86C15.7804 54.9118 16.1971 54.8452 16.571 54.668C16.9448 54.4908 17.2602 54.2105 17.48 53.86C17.9511 52.7495 18.1532 51.5434 18.07 50.34C18.1501 49.1098 17.9447 47.8777 17.47 46.74C17.2594 46.3802 16.9488 46.0895 16.576 45.903C16.2031 45.7166 15.7841 45.6426 15.37 45.69Z" />
            <path d="M53.66 35.27H3.17C2.50777 35.2726 1.87341 35.5369 1.40514 36.0051C0.936869 36.4734 0.672631 37.1078 0.669998 37.77V62.7C0.669998 63.363 0.93339 63.9989 1.40223 64.4678C1.87107 64.9366 2.50696 65.2 3.17 65.2H53.66C54.323 65.2 54.9589 64.9366 55.4278 64.4678C55.8966 63.9989 56.16 63.363 56.16 62.7V37.77C56.1574 37.1078 55.8931 36.4734 55.4249 36.0051C54.9566 35.5369 54.3222 35.2726 53.66 35.27ZM20 55.86C19.1 57.05 17.55 57.66 15.38 57.66C13.21 57.66 11.66 57.05 10.76 55.86C9.86 54.67 9.43 52.86 9.43 50.33C9.2935 48.3747 9.75754 46.4243 10.76 44.74C11.66 43.5 13.21 42.88 15.38 42.88C17.55 42.88 19.1 43.5 20 44.74C21.0108 46.4212 21.4787 48.3732 21.34 50.33C21.33 52.82 20.88 54.68 20 55.86ZM26.63 54C26.9088 54.3305 27.2671 54.5847 27.6712 54.7386C28.0753 54.8926 28.5119 54.9412 28.94 54.88L30.47 54.81V52.41H29.2V49.6H33.61V57.16H33.46C31.8806 57.4356 30.2828 57.5927 28.68 57.63C26.45 57.63 24.9 57.02 24.05 55.81C23.2 54.6 22.8 52.73 22.8 50.18C22.8 47.63 23.24 45.74 24.11 44.6C24.98 43.46 26.49 42.85 28.57 42.85C29.9672 42.8596 31.3603 43.0036 32.73 43.28L33.61 43.45L33.52 46H33.33C30.11 45.66 28.01 45.62 27.21 46.08C26.7734 46.3752 26.4658 46.8258 26.35 47.34C26.1139 48.4436 26.0165 49.5723 26.06 50.7C25.9676 51.8305 26.1637 52.966 26.63 54ZM39.16 54C39.4382 54.3313 39.7965 54.586 40.2007 54.74C40.605 54.894 41.0419 54.9422 41.47 54.88L43 54.78V52.41H41.72V49.6H46.13V57.16H45.99C44.4073 57.4357 42.8061 57.5928 41.2 57.63C38.98 57.63 37.42 57.02 36.58 55.81C35.74 54.6 35.33 52.73 35.33 50.18C35.33 47.63 35.77 45.74 36.63 44.6C37.49 43.46 39 42.88 41.1 42.88C42.494 42.8891 43.8838 43.0331 45.25 43.31L46.14 43.48L46 46H45.82C42.59 45.66 40.49 45.62 39.69 46.08C39.2564 46.3763 38.9524 46.827 38.84 47.34C38.6039 48.4436 38.5065 49.5723 38.55 50.7C38.4701 51.8339 38.68 52.9696 39.16 54Z" />
        </svg>
    );
};
