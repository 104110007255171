import React from 'react';
import { Failed, SignIn, Success } from '$ui/Integrations/Screens';
import DentallyLogo from '$resources/dentally.svg';
import { Frame } from '$ui/Integrations/Frame';
import { SyncState } from '$types/integrations';
import {
    SetupContext,
    setupWithoutInstructions,
    useWorkflow,
} from '$ui/Integrations/Workflows/workflows';

interface Props {
    auth: () => Promise<void>;
    sync: () => void;
    syncState: SyncState;
    onFinish: () => void;
}

// TODO: Refactor this when introducing a new integration
export const DentallySetup = ({ auth, sync, onFinish, syncState }: Props) => {
    const { matches, onSync, onSignIn, onNext, onBack, context } =
        useWorkflow<SetupContext>({
            healthyState: 'success',
            workflow: setupWithoutInstructions,
            syncState,
            auth,
            sync,
        });

    const next = !['signIn', 'failed'].some(matches);
    const back = !matches('signIn');
    const last = matches('success');

    return (
        <Frame
            integration="Dentally"
            mode="Set up"
            next={next}
            back={back}
            last={last}
            onExit={onFinish}
            onNext={onNext}
            onFinish={onFinish}
            onBack={onBack}
        >
            {matches('signIn') && (
                <SignIn
                    logo={DentallyLogo}
                    integration="Dentally"
                    onSignIn={onSignIn}
                    signingIn={matches('signIn.signingIn')}
                />
            )}

            {matches('success') && (
                <Success
                    integration="Dentally"
                    onSync={onSync}
                    syncing={matches('success.syncing')}
                    instructions={`
                            You can now link LeadFlo patients to their Dentally
                            counterpart to sync the data. \n
                            When viewing a patient's timeline, along the left (for
                            actions view) or right (for inbox view) you will see a
                            little toolbar with Dentally Logo. \n
                            Clicking the logo will bring up the Dentally patients
                            that closest match to the LeadFlo Patient.\n
                            You then need to click the Link button to associate
                            these patients together. \n
                            Once done the Patient data will sync and you should be
                            able to see the information from Dentally on the
                            patients profile.
                        `}
                />
            )}

            {matches('failed') && (
                <Failed
                    integration="Dentally"
                    mode={context.failureMode}
                    onSignIn={onSignIn}
                    signingIn={matches('failed.signingIn')}
                />
            )}
        </Frame>
    );
};
