import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { DropdownSelect } from '$ui/PatientFilter/DropdownSelect';
import { Time } from '$ui/PatientFilter/Time';
import React from 'react';
import styled, { css } from 'styled-components';

// import svg
import SortTop from '$resources/icons/sort-top.svg';
import SortDown from '$resources/icons/sort-down.svg';
import { InboxChannelFilter, SortOrder } from '$types/app';
import { useAppDispatch, useAppSelector, withState } from '$state';
import { getSnoozedCount, getStarredCount } from '$state/queries/inbox';
import { fetchSnoozedCount, fetchStarredCount } from '$state/concerns/inbox';

export interface ConnectedFilterBarProps {
    name: string;
    channel: InboxChannelFilter;
    unread: number;
    sort: SortOrder;
    range: { start: Date; end: Date };
    types: string[];
    expanded: boolean;
    selectedTypes: string | string[];
    onDateChange: (range: { start: Date; end: Date }) => void;
    onSortChange: (direction: SortOrder) => void;
    onUnreadClick: () => void;
    onStarredClick: () => void;
    onSnoozedClick: () => void;
    onTypeChange: (types: string | string[]) => void;
    active?: {
        unread: boolean;
        starred: boolean;
        snoozed: boolean;
    };
}

export const ConnectedFilterBar = (props: ConnectedFilterBarProps) => {
    const { name, channel, range, types } = props;

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(
            fetchSnoozedCount({
                view: name,
                channel,
                startAt: range.start.toISOString(),
                endAt: range.end.toISOString(),
                types
            })
        );

        dispatch(
            fetchStarredCount({
                view: name,
                channel,
                startAt: range.start.toISOString(),
                endAt: range.end.toISOString(),
                types
            })
        );
    }, [name, channel, range.start, range.end, types]);

    const starredCount = useAppSelector(withState(getStarredCount, name));
    const snoozedCount = useAppSelector(withState(getSnoozedCount, name));

    return (
        <FilterBar starred={starredCount} snoozed={snoozedCount} {...props} />
    );
};

export interface FilterBarProps {
    unread: number;
    starred: number;
    snoozed: number;
    sort: SortOrder;
    range: { start: Date; end: Date };
    types: string[];
    expanded: boolean;
    selectedTypes: string | string[];
    onDateChange: (range: { start: Date; end: Date }) => void;
    onSortChange: (direction: SortOrder) => void;
    onUnreadClick: () => void;
    onStarredClick: () => void;
    onSnoozedClick: () => void;
    onTypeChange: (types: string | string[]) => void;
    active?: {
        unread: boolean;
        starred: boolean;
        snoozed: boolean;
    };
}

export const FilterBar = (props: FilterBarProps) => {
    const {
        unread,
        starred,
        snoozed,
        sort = 'desc',
        range,
        types,
        expanded,
        selectedTypes,
        onDateChange,
        onSortChange,
        onUnreadClick,
        onStarredClick,
        onSnoozedClick,
        onTypeChange,
        active = {
            unread: false,
            starred: false,
            snoozed: false
        }
    } = props;

    const handleDateChange = (range: { start: Date; end: Date }) => {
        onDateChange(range);
    };

    const handleSortChange = () => {
        if (sort === 'asc') {
            onSortChange('desc');
        } else {
            onSortChange('asc');
        }
    };

    const handleUnreadClick = () => {
        onUnreadClick();
    };

    const handleStarredClick = () => {
        onStarredClick();
    };

    const handleSnoozedClick = () => {
        onSnoozedClick();
    };

    const handleTypeChange = (types: string | string[]) => {
        onTypeChange(types);
    };

    return (
        <Bar>
            <Buttons>
                <Btn
                    onClick={() => handleUnreadClick()}
                    active={active.unread ?? false}
                    data-cy="filter-btn-Unread"
                >
                    <Icon icon="Mail" size={1.5} />
                    Unread {unread > 0 && `(${unread})`}
                </Btn>

                <Btn
                    onClick={() => handleStarredClick()}
                    active={active.starred ?? false}
                >
                    <Icon icon="Star" size={1.5} />
                    Starred {starred > 0 && `(${starred})`}
                </Btn>
                <Btn
                    onClick={() => handleSnoozedClick()}
                    active={active.snoozed ?? false}
                >
                    <Icon icon="Moon" size={1.5} clickable />
                    Include snoozed {snoozed > 0 && `(${snoozed})`}
                </Btn>
            </Buttons>

            {expanded && (
                <Selectors>
                    <DropdownSelect
                        padding={[0.5, 1]}
                        iconHue="grey"
                        available={types}
                        selected={selectedTypes}
                        onSelect={(selected) =>
                            handleTypeChange(selected ?? [])
                        }
                        plural="types"
                        icon="PieChart"
                        multi
                    />
                    <Picker>
                        <Time
                            textSize="small"
                            padding={[0, 1]}
                            alignBox="end"
                            iconHue="grey"
                            onChange={(range) =>
                                handleDateChange({
                                    start: range.start,
                                    end: range.end
                                })
                            }
                            start={range.start}
                            end={range.end}
                        />
                    </Picker>
                    <Sort onClick={() => handleSortChange()}>
                        {sort === 'desc' ? <SortTopIcon /> : <SortDownIcon />}
                    </Sort>
                </Selectors>
            )}
        </Bar>
    );
};

const Bar = styled.div`
    max-height: 42px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    width: 100%;
    ${mix.type({ level: 'small' })};
    ${mix.padding({ padding: [1, 2] })};
    ${mix.gap({ size: 1 })};
    position: relative;
    background: white;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${mix.gap({ size: 1 })};
`;

export const Btn = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    ${mix.gap({ size: 1 })};
    ${mix.color({ profile: 'body' })};
    ${mix.padding({ padding: [0.5, 1.5, 0.5, 1] })};
    ${mix.round({ rounded: true })};
    ${({ active }) =>
        active
            ? mix.bg({ hue: 'primary', shade: '10' })
            : css`
                  background: var(--gray-100);
              `};
    cursor: pointer !important;
    transition: all 0.2s ease;
`;

const Selectors = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${mix.gap({ size: 1 })};
    ${mix.color({ profile: 'body' })};
`;

const Picker = styled.div`
    display: flex;
    justify-self: baseline;
    height: 100%;
`;

const Sort = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const svgStyle = css`
    width: 20px;
    height: 20px;
    cursor: pointer !important;

    & > path {
        fill: ${mix.palette({ hue: 'grey', shade: '5' })};
    }
`;

const SortTopIcon = styled(SortTop)`
    ${svgStyle};
`;
const SortDownIcon = styled(SortDown)`
    ${svgStyle};
`;
