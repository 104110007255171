import {
    ClientLoadedState,
    ClientState,
    ClientStatus,
} from '$state/concerns/client';
import { RootState } from '$state/store';

export const clientState = (state: RootState) => state.client;

export const client = (state: RootState) => {
    return clientState(state).client;
};

export const isClientLoaded = (
    state: ClientState,
): state is ClientLoadedState => state.status === ClientStatus.LOADED;

export const smsEnabled = (state: RootState): boolean => {
    if (!isClientLoaded(clientState(state))) {
        return false;
    }
    return clientState(state).client!.settings.sms_enabled;
};

export const emailEnabled = (state: RootState): boolean => {
    if (!isClientLoaded(clientState(state))) {
        return false;
    }
    return clientState(state).client!.settings.email_enabled;
};

export const referralsEnabled = (state: RootState): boolean => {
    if (!isClientLoaded(clientState(state))) {
        return false;
    }
    return clientState(state).client!.settings.referrals_enabled;
};

export const labels = (state: RootState): string[] => {
    const client = clientState(state);
    if (!isClientLoaded(client)) {
        return [];
    }
    return client.labels;
};

export const types = (state: RootState): string[] => {
    const client = clientState(state);
    if (!isClientLoaded(client)) {
        return [];
    }
    return client.tx_types;
};

export const pipeline = (state: RootState): string => {
    const client = clientState(state);
    if (!isClientLoaded(client)) {
        // Defaults to pipeline if not loaded, as this would
        // be the safer option
        return 'pipeline';
    }
    return client.client.settings.pipeline;
};
