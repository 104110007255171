import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
    remaining: number;
    category: string;
    pageSize: number;
    onLoadMore?: () => void;
}

export const LoadMore = (props: Props) => {
    const { remaining, category, onLoadMore, pageSize } = props;
    const showNext = remaining >= pageSize ? pageSize : remaining;

    return (
        <Container onClick={onLoadMore}>
            <Heading>
                Show {showNext} more <b>{category}</b>
            </Heading>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 200ms all ease-out;
    background: white;
    ${mix.height({ size: 6 })};
    border: 1px solid var(--gray-300);
    color: var(--gray-400);
    border-radius: 4px;

    &:hover {
        background: var(--gray-200);
        color: var(--gray-600);
    }
`;

const Heading = styled.span`
    ${mix.type({ level: 'body2' })};
    line-height: 1;
`;
