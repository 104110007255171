import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Hue } from '$ui/Flo/types';
import { Icon } from '$ui/Actions/Refresh/Icon';

interface Props {
    onClick: () => void;
    loading: boolean;
    label: React.ReactNode;
    color: Hue;
    shade?: string;
}

export const SingleAction = (props: Props) => {
    const { loading, onClick, color, label } = props;

    return (
        <Button
            disabled={loading}
            onClick={onClick}
            key="refresh-button"
            data-qa="action-refresh-button"
            color={color}
        >
            <Icon size={16} $spin={loading} /> {label}
        </Button>
    );
};

const Button = styled.button<{ color: Hue }>`
    ${({ color }) => {
        if (color === 'green') {
            return css`
                background: var(--success-500);
            `;
        }

        if (color === 'yellow') {
            return css`
                background: var(--warning-500);
            `;
        }

        if (color === 'red') {
            return css`
                background: var(--error-500);
            `;
        }

        return css`
            background: var(--primary-500);
        `;
    }};
    ${mix.padding({ padding: [1.5] })};
    ${mix.height({ size: 4 })};

    border-radius: 500rem;
    outline: none;
    border: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: 300ms ease;
    bottom: 0;
    pointer-events: auto;
    color: #ffffff;
    font-size: 12px;
    line-height: 1;

    &.action-refresh-enter {
        bottom: 100%;
    }

    &.action-refresh-enter.action-refresh-enter-active {
        bottom: 0;
        transition: all 300ms ease;
    }

    &.action-refresh-exit {
        bottom: 0;
    }

    &.action-refresh-exit.action-refresh-exit-active {
        bottom: 100%;
        transition: all 300ms ease;
    }
`;
