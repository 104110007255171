import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

type Props = {
    id: string;
    continueLabel?: string;
    onContinue: () => void;
    onBack?: () => void;
};

export const Footer: React.FC<Props> = ({
    id,
    continueLabel = 'Continue',
    onContinue,
    onBack,
}) => {
    return (
        <Container>
            {onBack && (
                <SecondaryButton data-testid={id + '-back'} onClick={onBack}>
                    Go back
                </SecondaryButton>
            )}
            <PrimaryButton data-testid={id + '-continue'} onClick={onContinue}>
                {continueLabel}
            </PrimaryButton>
        </Container>
    );
};

const Container = styled.div`
    ${mix.padding({ padding: [1.5, 3] })};
    ${mix.gap({ size: 1 })};
    display: flex;
    border-top: 1px solid var(--gray-100);
`;

const Button = styled.button`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.height({ size: 5.5 })};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
`;

const PrimaryButton = styled(Button)`
    background: var(--primary-500);
    color: white;
    border: none;
`;

const SecondaryButton = styled(Button)`
    background: transparent;
    border: 1px solid var(--gray-200);
    color: var(--gray-500);
`;
