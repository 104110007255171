import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CollapseDown = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 24 24"
        >
            <path d="M11.5213 16.8779C11.8198 17.0407 12.1806 17.0407 12.479 16.8779L23.479 10.8779C23.9639 10.6134 24.1425 10.006 23.8781 9.52115C23.6136 9.0363 23.0062 8.85764 22.5213 9.1221L12.0002 14.8609L1.47904 9.1221C0.994193 8.85764 0.386756 9.0363 0.122294 9.52115C-0.142169 10.006 0.0364887 10.6134 0.521337 10.8779L11.5213 16.8779Z" />
        </svg>
    );
};
