import { useAppSelector } from '$state/hooks';
import { IntegrationBar } from '$ui/Dentally';
import * as React from 'react';
import DentallySvg from '$resources/dentally.svg';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { withState } from '$state/utils';
import * as dentally from '$state/concerns/dentally/patients';

const DentallyLogo = styled(DentallySvg)``;

interface BarProps {
    id: string;
    position: 'left' | 'right';
    onSelect?: (integration: string) => void;
    selected?: string;
}

export const Integrations: React.FC<BarProps> = ({
    id,
    position,
    onSelect,
    selected
}) => {
    const dentallyState = useAppSelector(withState(dentally.selectPatient, id));

    const linked =
        dentally.syncing(dentallyState) || dentally.synced(dentallyState);

    const [active, setActive] = React.useState('');

    React.useEffect(() => {
        setActive(selected ?? '');
    }, [selected]);

    if (dentally.disabled(dentallyState)) {
        return null;
    }

    const handleActive = (integration: string) => {
        setActive(integration);
        if (onSelect) {
            onSelect(integration);
        }
    };

    return (
        <IntegrationBarWrapper>
            <IntegrationBar
                integrations={{
                    dentally: {
                        icon: <DentallyLogo />,
                        name: 'Dentally',
                        status: linked ? 'linked' : 'unlinked',
                        onClick: () => handleActive('dentally')
                    }
                }}
                active={selected ?? active}
                size={5}
                // swap tooltip position (bar is left tooltip is right and vice versa)
                position={position !== 'left' ? 'left' : 'right'}
            />
        </IntegrationBarWrapper>
    );
};

const IntegrationBarWrapper = styled.div`
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    ${mix.padding({ padding: [1] })};
    ${mix.bg({ hue: 'grey', shade: '10', alpha: 0.5 })}
`;
