import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const LoadAttachmentIcon = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={className}
             style={style}
             onClick={onClick}
             width={size}
             height={size}
             fill="white"
             xmlSpace="preserve"
             viewBox="0 0 44 44"
        >
            <g>
                <rect width="44" height="44" fill="#fff" rx="22" />
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M29.7071 21.2929C30.0976 21.6834 30.0976 22.3166 29.7071 22.7071L22.7071 29.7071C22.3166 30.0976 21.6834 30.0976 21.2929 29.7071L14.2929 22.7071C13.9024 22.3166 13.9024 21.6834 14.2929 21.2929C14.6834 20.9024 15.3166 20.9024 15.7071 21.2929L21 26.5858L21 15C21 14.4477 21.4477 14 22 14C22.5523 14 23 14.4477 23 15L23 26.5858L28.2929 21.2929C28.6834 20.9024 29.3166 20.9024 29.7071 21.2929Z"
                      fill="#98A2B3" color={color}/>
            </g>
        </svg>
    );
};
