import { FormSubmission } from '$types/patient';
import { FormSubmission as FormSubmissionV2 } from '$types/timeline-v2';
import * as React from 'react';
import * as Parts from './Parts';

interface Props {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
    submission: FormSubmission | FormSubmissionV2;
}

export const FormSubmissionEvent = (props: Props) => {
    const { submission } = props;

    return (
        <Parts.Container inbound>
            <Parts.Avatar color="gray-100" inbound {...props} />
            <Parts.Event inbound>
                <Parts.Content
                    type={submission.type}
                    title={submission.form}
                    color="gray-100"
                    inbound
                >
                    {submission.message || "Patient didn't leave a message"}
                </Parts.Content>
                <Parts.Metadata
                    inbound
                    type="Form Submission"
                    icon="CheckSquare"
                >
                    <Parts.Status
                        inbound
                        status="Received"
                        date={submission.datetime}
                    />
                </Parts.Metadata>
            </Parts.Event>
        </Parts.Container>
    );
};
