import { Check } from '$ui/Flo/Check';
import { Monogram } from '$ui/Flo/Monogram';
import { mix } from '$ui/Flo/util';
import { dates } from '$utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { Badge } from '$ui/Flo/Badge';
import { Icon, IconName } from '$ui/Flo/Icon';
import MailOpenSvg from '$resources/icons/mail-open.svg';
import StarFilledSvg from '$resources/icons/star-filled.svg';
import { Shadow } from '$ui/Flo/Shadow';
import { useHover } from '@/utils/hooks';
import { Conversation } from '$state/types/inbox';
import { ColorToken } from '$ui/Flo/types';

export interface InboxItemProps {
    conversation: Conversation;
    state: InboxItemState;
    selected: boolean;
    expanded: boolean;
    hovered?: boolean;
    onCheck: (id: string) => void;
    onUncheck: (id: string) => void;
    onClick: (convo: Conversation) => void;
    onMarkAsRead: (id: string) => void;
    onMarkAsUnread: (id: string) => void;
    onStarred: (id: string) => void;
    onUnstarred: (id: string) => void;
    hoveredMenuPosition?: 'top' | 'bottom';
}

interface StateUnread {
    type: 'unread';
    count: number;
}

interface StateRead {
    type: 'read';
}

interface StateOpen {
    type: 'open';
}

interface StateLoading {
    type: 'loading';
}

interface StateOpenUnread {
    type: 'open-unread';
    count: number;
}

export type InboxItemState =
    | StateUnread
    | StateRead
    | StateOpen
    | StateLoading
    | StateOpenUnread;
type StateType = InboxItemState['type'];

export const InboxItem = React.memo((props: InboxItemProps) => {
    const {
        conversation,
        state,
        selected,
        expanded,
        hovered = false,
        onCheck,
        onUncheck,
        onClick,
        onMarkAsRead,
        onMarkAsUnread,
        onStarred,
        onUnstarred,
        hoveredMenuPosition = 'top',
    } = props;

    const {
        patient_id: id,
        first_name,
        last_name,
        snippet,
        channel,
        last_message_at: date,
        starred = false,
        snoozed,
    } = conversation;

    const name = [first_name, last_name].join(' ');

    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(hovered);

    const [moreActions, setMoreActions] = React.useState(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => {
            setHover(false);
            setMoreActions(false);
        },
    });

    const handleCheck = () => {
        if (selected) {
            onUncheck(id);
        } else {
            onCheck(id);
        }
    };

    const channelIcon = {
        Email: 'CommTypeEmail',
        'Marketing Email': 'CommTypeEmail',
        SMS: 'CommTypeSMS',
        'Form Submissions': 'CheckSquare',
        'Phone Call': 'PhoneCall',
        WhatsApp: 'CommTypeWhatsApp'
    }[channel] as IconName;

    const iconSize = {
        Email: 2,
        'Marketing Email': 2,
        SMS: 2,
        'Form Submissions': 1.5,
        'Phone Call': 2,
        WhatsApp: 2
    }[channel];

    const iconOpacity = {
        Email: 1,
        'Marketing Email': 1,
        SMS: 1,
        'Form Submissions': 0.6,
        'Phone Call': 1,
        WhatsApp: 1
    }[channel];

    if (state.type === 'loading') {
        return (
            <Wrapper selected={false} state={state.type}>
                <Select>
                    <Shadow rounded height={2.5} />
                </Select>

                <StarredIcon
                    starred={true}
                    onStarred={() => null}
                    onUnstarred={() => null}
                    showText={false}
                />

                <Patient>
                    <Monogram size={4.5} name="" loading />
                </Patient>
                <Info>
                    <NameAndTime>
                        <Shadow
                            rounded
                            width={30}
                            height={2}
                            hue="grey"
                            shade="8"
                            style={{ marginBottom: '8px' }}
                        />
                        <Shadow
                            rounded
                            width={10}
                            height={1}
                            hue="grey"
                            shade="8"
                        />
                    </NameAndTime>
                    <SnippetAndCount>
                        <Shadow
                            rounded
                            width={80}
                            height={1}
                            hue="grey"
                            shade="8"
                        />
                        <Shadow
                            rounded
                            width={3}
                            height={1}
                            hue="grey"
                            shade="8"
                        />
                    </SnippetAndCount>
                </Info>
            </Wrapper>
        );
    }

    return (
        <Wrapper
            data-qa="conversation"
            selected={selected}
            state={state.type}
            ref={hoverRef}
            onClick={() => onClick(conversation)}
        >
            <Select>
                <Check
                    checked={selected}
                    onChange={handleCheck}
                    theme="blue"
                    size="small"
                />
            </Select>

            <StarredIcon
                starred={starred}
                onStarred={() => onStarred(id)}
                onUnstarred={() => onUnstarred(id)}
            />

            <PatientInfo
                name={name}
                channel={channel}
                state={state}
                date={date}
                hover={hover}
                channelIcon={channelIcon}
                iconOpacity={iconOpacity}
                iconSize={iconSize}
                snippet={snippet}
                snoozed={snoozed}
            />

            {!expanded && hover && (
                <HoverActions
                    onClick={(event) => {
                        stopPropagation(event);
                    }}
                >
                    {moreActions && (
                        <Popup hoveredMenuPosition={hoveredMenuPosition}>
                            <PopupItem>
                                <MarkAsIcon
                                    read={
                                        state.type === 'read' ||
                                        state.type === 'open'
                                    }
                                    onMarkAsRead={() => onMarkAsRead(id)}
                                    onMarkAsUnread={() => onMarkAsUnread(id)}
                                    showText
                                />
                            </PopupItem>
                        </Popup>
                    )}
                    <MoreActions
                        applyBg={moreActions}
                        onClick={(event) => {
                            stopPropagation(event);
                            setMoreActions(!moreActions);
                        }}
                    >
                        <Icon icon="MoreHorizontal" size={2} clickable />
                    </MoreActions>
                </HoverActions>
            )}
            {expanded && hover && (
                <HoverActions>
                    <MarkAsIcon
                        read={state.type === 'read' || state.type === 'open'}
                        onMarkAsRead={() => onMarkAsRead(id)}
                        onMarkAsUnread={() => onMarkAsUnread(id)}
                    />
                </HoverActions>
            )}
        </Wrapper>
    );
});

InboxItem.displayName = 'InboxItem';

interface PatientInfoProps {
    name: string;
    channel: string;
    state: InboxItemState;
    channelIcon?: IconName;
    iconSize?: number;
    iconOpacity?: number;
    date: string;
    hover: boolean;
    snippet: string;
    snoozed: boolean;
}

export const PatientInfo = React.memo((props: PatientInfoProps) => {
    const {
        name,
        channel,
        state,
        channelIcon,
        iconSize,
        iconOpacity,
        date,
        hover,
        snippet,
        snoozed
    } = props;

    return (
        <>
            <Patient>
                <Monogram name={name} size={4.5} />
                <Channel title={channel} snoozed={snoozed}>
                    <Icon
                        icon={snoozed ? 'Moon' : channelIcon ?? 'Mail'}
                        size={1.25}
                        {...(channelIcon && {
                            opacity: iconOpacity,
                            size: iconSize
                        })}
                        {...(snoozed && {
                            hue: 'white',
                            shade: '5',
                            opacity: 1,
                        })}
                    />
                </Channel>
            </Patient>
            <Info>
                <NameAndTime>
                    <NameWrapper>
                        <Name state={state.type}>{name}</Name>
                    </NameWrapper>

                    {!hover && <Time>{date && dates.delta(date)}</Time>}
                </NameAndTime>
                <SnippetAndCount>
                    <SnippetWrapper>
                        <Snippet state={state.type}>{snippet}</Snippet>
                    </SnippetWrapper>
                    {!hover &&
                        (state.type === 'unread' ||
                            state.type === 'open-unread') && (
                            <Badge count={state.count} size={0.75} />
                        )}
                </SnippetAndCount>
            </Info>
        </>
    );
});

PatientInfo.displayName = 'PatientInfo';

export const MarkAsIcon: React.FC<{
    read: boolean;
    onMarkAsRead: () => void;
    onMarkAsUnread: () => void;
    showText?: boolean;
    color?: ColorToken;
}> = ({ read, onMarkAsRead, onMarkAsUnread, showText = false, color }) => {
    if (read) {
        return (
            <Btn
                onClick={(event) => {
                    stopPropagation(event);
                    onMarkAsUnread();
                }}
                title={!showText ? 'Mark as unread' : ''}
            >
                <Icon  icon="Mail" size={2} color={color} opacity={color ? 1 : 0.5} clickable />
                {showText && <Text>Mark as unread</Text>}
            </Btn>
        );
    }

    return (
        <Btn
            onClick={(event) => {
                stopPropagation(event);
                onMarkAsRead();
            }}
            title={!showText ? 'Mark as read' : ''}
        >
            <MailOpenIcon color={color} />
            {showText && <Text>Mark as read</Text>}
        </Btn>
    );
};

const stopPropagation = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
};

export const StarredIcon: React.FC<{
    starred: boolean;
    onStarred: () => void;
    onUnstarred: () => void;
    showText?: boolean;
}> = ({ starred, onStarred, onUnstarred, showText = false }) => {
    if (starred) {
        return (
            <Btn
                onClick={(event) => {
                    stopPropagation(event);
                    onUnstarred();
                }}
                title="Unstar conversation"
            >
                {!showText && <StarFilledIcon />}
                {showText && (
                    <>
                        <Icon icon="Star" size={2} clickable />
                        <Text>Unstar conversation</Text>
                    </>
                )}
            </Btn>
        );
    }

    return (
        <Btn
            onClick={(event) => {
                stopPropagation(event);
                onStarred();
            }}
            title="Star conversation"
        >
            {!showText && <Icon icon="Star" size={2} clickable />}
            {showText && (
                <>
                    <StarFilledIcon />
                    <Text>Star conversation</Text>
                </>
            )}
        </Btn>
    );
};

const unreadTitle = css`
    ${mix.color({ profile: 'title' })};
    ${mix.type({ level: 'body2', bold: true })};
`;

const defaultTitle = css`
    ${mix.color({ profile: 'body' })};
    ${mix.type({ level: 'body2' })};
`;

const unreadSnippet = css`
    ${mix.color({ profile: 'body' })};
    ${mix.type({ level: 'small' })};
`;

const defaultSnippet = css`
    ${mix.color({ profile: 'secondary' })};
    ${mix.type({ level: 'small' })};
`;

const unread = css`
    ${mix.bg({ hue: 'grey', shade: '9', alpha: 1 })};
`;

const read = css`
    ${mix.bg({ hue: 'grey', shade: '9', alpha: 0.5 })};
`;

const open = css`
    background-color: white;
`;

const unreadChecked = css`
    ${mix.bg({ hue: 'primary', shade: '9', alpha: 0.8 })};
`;

const readChecked = css`
    ${mix.bg({ hue: 'primary', shade: '9', alpha: 0.4 })};
`;

const openChecked = css`
    ${mix.bg({ hue: 'primary', shade: '9', alpha: 0.2 })};
`;

const Wrapper = styled.div<{
    selected: boolean;
    state: StateType;
}>`
    ${mix.color({ profile: 'body' })};
    ${({ selected, state }) => {
        if (selected) {
            switch (state) {
                case 'unread':
                    return unreadChecked;
                case 'read':
                    return readChecked;
                case 'open':
                    return openChecked;
                case 'open-unread':
                    return openChecked;
                default:
                    return '';
            }
        } else {
            switch (state) {
                case 'unread':
                    return unread;
                case 'read':
                    return read;
                case 'open':
                    return open;
                case 'open-unread':
                    return open;
                default:
                    return '';
            }
        }
    }};

    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};

    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.gap({ size: 1.5 })};
    min-height: 60px;
    ${mix.padding({ padding: [0, 1.5] })};
    ${mix.type({ level: 'body2' })};
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        ${mix.shadow({ level: 1 })};
    }
`;

const Select = styled.div`
    min-width: 20px;
    max-height: 20px;
`;

const Patient = styled.div`
    position: relative;
`;

const Channel = styled.div<{ snoozed?: boolean }>`
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    z-index: 10;

    ${({ snoozed }) => {
        if (snoozed === true) {
            return css`
                background: ${mix.palette({
                    hue: 'primary',
                    shade: '6',
                })};
            `;
        }
        return '';
    }};
`;

const Info = styled.div`
    width: 100%;
`;

const NameAndTime = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const NameWrapper = styled.div`
    position: relative;
    width: 100%;

    &:before {
        content: '&nbsp;';
        visibility: hidden;
    }
`;

const Name = styled.div<{ state: StateType }>`
    ${({ state }) => (state === 'unread' ? unreadTitle : defaultTitle)};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Time = styled.div`
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'secondary' })};
    white-space: nowrap;
`;

const SnippetAndCount = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${mix.gap({ size: 1 })};
`;

const SnippetWrapper = styled.div`
    position: relative;
    width: 100%;

    &:before {
        content: '&nbsp;';
        visibility: hidden;
    }
`;

const Snippet = styled.span<{ state: StateType }>`
    ${({ state }) => (state === 'unread' ? unreadSnippet : defaultSnippet)};

    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const HoverActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    ${mix.gap({ size: 2 })};
    ${mix.padding({ padding: [0, 0.5] })};
    position: relative;
`;

const Btn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.gap({ size: 1 })};
    line-height: 1;
`;

const MoreActions = styled(Btn)<{ applyBg?: boolean }>`
    ${mix.padding({ padding: [1] })};
    ${({ applyBg }) => {
        if (applyBg) {
            return css`
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
            `;
        }
        return '';
    }};
`;

const Popup = styled.div<{ hoveredMenuPosition: 'top' | 'bottom' }>`
    position: absolute;
    background: white;
    ${mix.shadow({ level: 1 })};
    display: flex;
    flex-direction: column;
    ${mix.padding({ padding: [0.5, 0.5] })};
    ${mix.round({ rounded: true })};
    ${({ hoveredMenuPosition }) => {
        if (hoveredMenuPosition === 'top') {
            return css`
                top: 0;
                transform: translateY(-100%);
            `;
        }
        return css`
            bottom: 0;
            transform: translateY(100%);
        `;
    }};
    right: 0;
`;

const PopupItem = styled.div`
    display: block;
    flex-direction: row;
    align-items: center;
    ${mix.round({ rounded: true })};

    /* so that padding also trigger onClick */

    & > * {
        ${mix.padding({ padding: [1] })};
    }

    &:hover {
        ${mix.bg({ hue: 'grey', shade: '10' })};
    }
`;

const Text = styled.span`
    ${mix.type({ level: 'small' })};
    line-height: 1;
    white-space: nowrap;
`;

const svgStyling = css`
    width: 16px;
    height: 16px;
    cursor: pointer !important;
    color: ${mix.palette({ hue: 'grey', shade: '5' })};
`;

const MailOpenIcon = styled(MailOpenSvg)<{ color?: ColorToken }>`
    ${svgStyling};
    ${({ color }) =>
        color && css`
            color: var(--${color});
        `
        };
`;

export const StarFilledIcon = styled(StarFilledSvg)<{ size?: number }>`
    ${svgStyling};
    width: ${({ size }) => size && mix.unit({ size })};
    height: ${({ size }) => size && mix.unit({ size })};
    color: ${mix.palette({ hue: 'yellow', shade: '5' })};
`;
