import { Button } from '$ui/Flo/Button';
import { Icon, IconName } from '$ui/Flo/Icon';
import { Monogram } from '$ui/Flo/Monogram';
import { Circular } from '$ui/Flo/Progress';
import { Shadow } from '$ui/Flo/Shadow';
import { TypeLevel } from '$ui/Flo/types';
import { applyType, mix } from '$ui/Flo/util';
import { dates, lang, num } from '$utils';
import React from 'react';
import styled from 'styled-components';
import { bolden, Origin } from './Emboldened';

// https://developer.dentally.co/#the-patient-object
export interface DentallyResult {
    id: number; // dentally_id
    first_name: string;
    last_name: string;
    image_url?: string;
    email_address: string | null;
    phone: string | null;
    date_of_birth: string;
}

export interface ResultItemProps {
    patient?: DentallyResult;
    score?: number; // matching score
    query?: string;
    loading?: boolean; // when loading patient is empty | undefined
    onLink?: (dentally_id: string) => void;
    onView?: (dentally_id: string) => void;
    bold?: boolean; // whether the bold function should be applied
}

export const ResultItem = (props: ResultItemProps) => {
    const {
        patient,
        score,
        query = '',
        onLink,
        onView,
        loading = false,
        bold = true // bold by default
    } = props;

    const origin = { name: '', dob: '', email: '', phone: '' };

    if (!loading && patient) {
        origin.name = `${patient.first_name} ${patient.last_name}`;
        origin.dob = dates.toDate(patient.date_of_birth);
        origin.email = patient.email_address ?? '';
        origin.phone = patient.phone ?? '';
    }

    return (
        <Wrapper
            $loading={loading}
            data-testid={`result-${patient?.id ?? 'loading'}`}
        >
            <PatientBlock>
                <Row>
                    <PatientImage patient={patient} score={score} />
                    <Column
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Label>
                            <LabelContent
                                loading={loading}
                                query={query}
                                origin={origin}
                                originKey="name"
                                level="body1"
                                bold={bold}
                            />
                        </Label>
                        <Label>
                            <LabelContent
                                loading={loading}
                                query={query}
                                origin={origin}
                                originKey="dob"
                                bold={bold}
                            />
                        </Label>
                    </Column>
                </Row>
            </PatientBlock>
            <PatientContact>
                <Column>
                    <Label>
                        <LabelContent
                            loading={loading}
                            query={query}
                            origin={origin}
                            originKey="email"
                            icon="Mail"
                            bold={bold}
                        />
                    </Label>
                    <Label>
                        <LabelContent
                            loading={loading}
                            query={query}
                            origin={origin}
                            originKey="phone"
                            icon="Phone"
                            bold={bold}
                        />
                    </Label>
                </Column>
            </PatientContact>
            {!loading && (
                <Buttons>
                    <Button
                        size="medium"
                        rounded
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            onLink && onLink(patient?.id.toString() ?? '');
                        }}
                        icon="Link"
                        align="center"
                    >
                        Link
                    </Button>

                    <Button
                        size="medium"
                        mode="outline"
                        rounded
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            onView && onView(patient?.id.toString() ?? '');
                        }}
                        icon="ExternalLink"
                        align="center"
                    >
                        View
                    </Button>
                </Buttons>
            )}
        </Wrapper>
    );
};

interface LabelContentProps {
    loading: boolean;
    query: string;
    origin: Origin;
    originKey: keyof Origin;
    level?: TypeLevel;
    icon?: IconName;
    bold?: boolean;
}

const LabelContent = (props: LabelContentProps) => {
    const { loading, query, origin, originKey, level, icon, bold } = props;

    if (loading) {
        return <LoadingBar />;
    }

    if (lang.isEmpty(origin[originKey])) return <></>;

    const originBold = bolden(
        origin as Origin,
        query.split(' ').filter((v) => v !== '') ?? []
    );

    if (origin && !bold) {
        return (
            <>
                {icon && <Icon icon={icon} />}
                <Text level={level ?? 'body2'}>
                    <div>{origin[originKey]}</div>
                </Text>
            </>
        );
    }

    return (
        <>
            {icon && <Icon icon={icon} />}
            <Text level={level ?? 'body2'}>
                <div>{query ? originBold[originKey] : origin[originKey]}</div>
            </Text>
        </>
    );
};

const LoadingText = styled(Shadow)`
    ${mix.margin({ margin: [0.25, 0.25, 0.25, 1] })}
`;

const LoadingBar = () => {
    return (
        <LoadingText
            hue="grey"
            shade="8"
            rounded
            height={2}
            width={num.randomInt(50, 100)}
        />
    );
};

const Wrapper = styled.div<{ $loading: boolean }>`
    ${mix.type({ level: 'body2' })};
    display: grid;
    ${({ $loading }) =>
        $loading
            ? 'grid-template-columns: 1fr'
            : 'grid-template-columns: 3fr 1fr'};
    width: 100%;
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    ${mix.padding({ padding: [2, 0] })}
    ${mix.gap({ size: 1 })}
`;

const PatientBlock = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    ${mix.gap({ size: 1 })}
`;

const PatientContact = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    ${mix.gap({ size: 1 })}
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
`;

const Buttons = styled.div`
    grid-area: 1 / 2 / 3 / 3;
    ${mix.gap({ size: 0.5 })}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;

    & > div {
        min-width: ${mix.unit({ size: 12 })};
    }
`;

const Text = styled.div<{ level?: TypeLevel }>`
    ${({ theme, level }) => level && applyType({ theme: theme, level: level })}
    margin-left: 1rem;
`;

export const PatientImage = (props: {
    patient?: DentallyResult;
    score?: number;
}) => {
    const { patient, score } = props;
    const IMAGE_RADIUS = 4;
    return (
        <IndicatorWrapper radius={IMAGE_RADIUS * 2}>
            <PatientAvatarWrapper>
                <Avatar patient={patient} />
            </PatientAvatarWrapper>
            {score && score > 0 && (
                <Indicator>
                    <Circular
                        radius={IMAGE_RADIUS}
                        thickness={0.5}
                        rates={{
                            success: score
                        }}
                        hues={{
                            track: 'none',
                            success: scoreColor(score)
                        }}
                        rounded
                    />
                </Indicator>
            )}
        </IndicatorWrapper>
    );
};

export const Avatar: React.FC<{ patient?: DentallyResult }> = (props) => {
    const { patient } = props;

    if (patient && patient.image_url && !lang.isEmpty(patient.image_url)) {
        return <PatientAvatar src={patient.image_url} />;
    }

    if (patient && !patient.image_url) {
        const name = patient
            ? patient.first_name + ' ' + patient.last_name
            : 'Not Available';

        return <Monogram name={name} />;
    }
    // must be loading
    return <Monogram name="" color="gray-300" />;
};

const scoreColor = (score: number) => {
    score = score * 100;
    if (score <= 33) {
        return 'red';
    } else if (score <= 66) {
        return 'primary';
    } else {
        return 'green';
    }
};

const IndicatorWrapper = styled.div<{ radius: number }>`
    ${({ radius }) => mix.sq({ size: radius })}
    position: relative;
    display: flex;
    margin: auto;
`;

export const PatientAvatarWrapper = styled.div`
    height: 100%;
    width: inherit;
    border-radius: 50%;
    position: relative;
    background: transparent;
    transform: scale(0.8);
    overflow: hidden;
`;

const PatientAvatar = styled.img`
    display: inline;
    height: auto;
    width: 100%;
`;

const Indicator = styled.svg`
    width: 100%;
    height: 100%;
    position: absolute;
`;
