import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { color } from './colors';
import { type } from './typography';
import { ThemeDef } from '$ui/Flo/types';

interface Props {
    children: React.ReactNode;
}

export const theme = (): ThemeDef => ({
    unit: 8,
    color,
    type
});

export const Theme = (props: Props) => {
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
