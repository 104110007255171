import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileAvi = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.33L66.8 23.39L67.3 23.32C67.2372 22.8217 67.0123 22.3579 66.66 22L45.85 1.16C45.7351 1.04307 45.6076 0.939115 45.47 0.85L45.37 0.79C45.2632 0.727384 45.153 0.670633 45.04 0.62L44.86 0.56C44.7653 0.524057 44.6684 0.494 44.57 0.47C44.4086 0.438042 44.2445 0.421302 44.08 0.42H10.68C10.0178 0.422633 9.38341 0.68687 8.91513 1.15514C8.44686 1.62341 8.18263 2.25777 8.17999 2.92V29.77C8.17999 30.433 8.44338 31.0689 8.91223 31.5378C9.38107 32.0066 10.017 32.27 10.68 32.27C11.343 32.27 11.9789 32.0066 12.4478 31.5378C12.9166 31.0689 13.18 30.433 13.18 29.77V5.42H41.58V23.56C41.58 24.2213 41.842 24.8557 42.3087 25.3242C42.7754 25.7928 43.4087 26.0574 44.07 26.06L62.33 26.16V74.58H13.18V70.7C13.18 70.037 12.9166 69.4011 12.4478 68.9322C11.9789 68.4634 11.343 68.2 10.68 68.2C10.017 68.2 9.38107 68.4634 8.91223 68.9322C8.44338 69.4011 8.17999 70.037 8.17999 70.7V77.08C8.18263 77.7422 8.44686 78.3766 8.91513 78.8449C9.38341 79.3131 10.0178 79.5774 10.68 79.58H64.83C65.4922 79.5774 66.1266 79.3131 66.5948 78.8449C67.0631 78.3766 67.3274 77.7422 67.33 77.08V23.68C67.3299 23.5627 67.3199 23.4456 67.3 23.33ZM46.58 21.08V9L58.76 21.14L46.58 21.08Z" />
            <path d="M53.66 35.27H3.16998C2.50775 35.2726 1.8734 35.5369 1.40512 36.0051C0.936853 36.4734 0.672616 37.1078 0.669983 37.77V62.7C0.669983 63.363 0.933375 63.9989 1.40222 64.4678C1.87106 64.9366 2.50694 65.2 3.16998 65.2H53.66C54.323 65.2 54.9589 64.9366 55.4277 64.4678C55.8966 63.9989 56.16 63.363 56.16 62.7V37.77C56.1574 37.1078 55.8931 36.4734 55.4248 36.0051C54.9566 35.5369 54.3222 35.2726 53.66 35.27ZM21.91 57.41L21.36 54.89H16.76L16.21 57.41H13L16.22 43.12H21.87L25.1 57.41H21.91ZM33.61 57.41H28.11L24.79 43.12H28.12L30.54 54.6H31.18L33.6 43.12H36.93L33.61 57.41ZM41.61 57.41H38.38V43.12H41.56L41.61 57.41Z" />
            <path d="M18.71 45.81L17.37 52.08H20.75L19.41 45.81H18.71Z" />
        </svg>
    );
};
