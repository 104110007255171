export const gmail = `Hi,

Please can you prepare our Google Workspace account so we can integrate
our Gmail email address with Leadflo?

You can find instructions to follow in this video:

{video}

The client ID mentioned in the video is:

137916347900-9tved8luqeuchl3q748kdcf8klud2am1.apps.googleusercontent.com

The OAuth scopes mentioned in the video are:

https://mail.google.com/
https://www.googleapis.com/auth/userinfo.email
https://www.googleapis.com/auth/userinfo.profile
`;

export const outlook = `Hi,

Please can you prepare our Office365 account so we can integrate our Outlook
email address with Leadflo?

You can find instructions to follow in this video:

{video}`;

export const whatsapp = `Hi,

Please can you prepare our WhatsApp account so we can integrate our system?

You can find instructions to follow in this video:

{video}`;
