import { RootState } from '$state';
import { FeatureState, FeatureStatus } from '../types';
import { createSelector } from '@reduxjs/toolkit';

export const featureState = (state: RootState): FeatureState => state.features;

export const featuresLoaded = (state: FeatureState) =>
    state.status === FeatureStatus.LOADED;

export const featureSet = (state: FeatureState) => [...new Set(state.features)];

export const featureEnabled = createSelector(
    [(_, feature: string) => feature, featureState],
    (feature, features: FeatureState) => featureSet(features).includes(feature)
);
