import React from 'react';
import { mix } from '$ui/Flo/util';
import { Circular } from '$ui/Flo/Progress';
import styled from 'styled-components';
import { useAppSelector } from '$state';
import { selectBulkStatus } from '$state/concerns/bulk';

interface ConnectedBulkStatusProps {
    batchId: string;
}

export const ConnectedBulkStatus = ({ batchId }: ConnectedBulkStatusProps) => {
    const status = useAppSelector(selectBulkStatus(batchId));

    if (!status) {
        return null;
    }

    return <BulkStatus
        channel={status.channel === 'Email' ? 'emails' : 'SMS'}
        sent={status.sent}
        total={status.total}
        failed={status.failed}
    />;
};

interface BulkStatusProps {
    channel: 'emails' | 'SMS';
    total: number;
    sent: number;
    failed: number;
}

export const BulkStatus = (props: BulkStatusProps) => {
    const { total, sent, failed } = props;
    return (
        <Container>
            <Circular
                radius={4}
                rates={{
                    success: sent * (1 / total),
                    failure: failed * (1 / total)
                }}
            />
            <TextColumn>
                <ProgressText>
                    {sent}/{total}
                </ProgressText>
                <Label>Bulk emails sent</Label>
                <FailedText>{failed} failed to send</FailedText>
            </TextColumn>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
`;

const TextColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 0.5 })};
`;

const ProgressText = styled.div`
    ${mix.type({ level: 'body1', bold: true })};
    ${mix.color({ profile: 'title', dark: true })};
    line-height: 1;
`;

const Label = styled.div`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.color({ profile: 'body', dark: true })};
    line-height: 1;
`;

const FailedText = styled.div`
    ${mix.type({ level: 'small', bold: true })};
    color: ${mix.palette({ hue: 'red', shade: '7' })};
    line-height: 1;
`;
