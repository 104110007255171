import * as React from 'react';
import {
    DateRangePicker,
    Range,
    RangeKeyDict,
    StaticRange
} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
    endOfDay,
    endOfToday,
    isEqual,
    isSameDay,
    startOfDay,
    startOfToday,
    subDays
} from 'date-fns';
import { dates } from '$utils';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { BoxSize, Hue, TypeLevel, TypeSystem } from '$ui/Flo/types';
import { epoch } from '@/utils/date';

interface Props {
    onChange: (range: { start: Date; end: Date }) => void;
    start: Date;
    end: Date;
    iconHue?: Hue;
    alignBox?: 'start' | 'end';
    textSize?: TypeLevel;
    padding?: BoxSize;
    withSorter?: boolean;
    allowFuture?: boolean;
    label?: string;
    ranges?: StaticRange[];
}

export const Time = (props: Props) => {
    const { onChange, start, end } = props;
    const [open, setOpen] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const {
        iconHue = 'primary',
        alignBox = 'start',
        textSize = 'body2',
        padding = [1],
        withSorter,
        allowFuture = false,
        label,
        ranges = undefined
    } = props;

    const range: Range[] = [
        {
            startDate: start,
            endDate: end,
            key: 'selection'
        }
    ];

    let staticRanges: StaticRange[] = [
        {
            label: 'Today',
            range: () => ({
                startDate: startOfToday(),
                endDate: endOfToday()
            }),
            isSelected: () => false
        },

        {
            label: 'Yesterday',
            range: () => ({
                startDate: subDays(startOfToday(), 1),
                endDate: subDays(endOfToday(), 1)
            }),
            isSelected: () => false
        },

        {
            label: 'Past week',
            range: () => ({
                startDate: subDays(startOfToday(), 7),
                endDate: subDays(endOfToday(), 1)
            }),
            isSelected: () => false
        },

        {
            label: 'Past month',
            range: () => ({
                startDate: subDays(startOfToday(), 30),
                endDate: subDays(endOfToday(), 1)
            }),
            isSelected: () => false
        },

        {
            label: 'Past quarter',
            range: () => ({
                startDate: subDays(startOfToday(), 90),
                endDate: subDays(endOfToday(), 1)
            }),
            isSelected: () => false
        },

        {
            label: 'Past year',
            range: () => ({
                startDate: subDays(startOfToday(), 365),
                endDate: subDays(endOfToday(), 1)
            }),
            isSelected: () => false
        },

        {
            label: label ?? 'All time',
            range: () => ({
                startDate: epoch,
                endDate: endOfToday()
            }),
            isSelected: () => false
        }
    ];
    ranges && (staticRanges = [...staticRanges, ...ranges]);

    const onSelect = ({ selection }: RangeKeyDict) => {
        if (!selection.endDate || !selection.startDate) {
            return;
        }

        onChange({
            start: startOfDay(selection.startDate),
            end: endOfDay(selection.endDate)
        });
    };

    const handleOutsideClick = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleOutsideClick, true);
        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, []);

    return (
        <Container ref={ref}>
            <Button
                start={start}
                end={end}
                onClick={() => setOpen(!open)}
                open={open}
                iconHue={iconHue}
                textSize={textSize}
                padding={padding}
                withSorter={withSorter}
                label={label}
            />
            {open && (
                <PickerBox direction={alignBox}>
                    <DateRangePicker
                        onChange={onSelect}
                        months={1}
                        direction="horizontal"
                        ranges={range}
                        maxDate={allowFuture ? undefined : new Date()}
                        staticRanges={staticRanges}
                    />
                </PickerBox>
            )}
        </Container>
    );
};

const Button = ({
    start,
    end,
    onClick,
    open,
    iconHue,
    textSize,
    padding,
    withSorter,
    label
}: {
    start: Date;
    end: Date;
    onClick: () => void;
    open: boolean;
    iconHue?: Hue;
    textSize: keyof TypeSystem;
    padding: BoxSize;
    withSorter?: boolean;
    label?: string;
}) => {
    let text: string;
    if (isEqual(epoch, start)) {
        text = label ?? 'All time';
    } else if (isSameDay(start, end)) {
        text = dates.shortDate(start);
    } else {
        text = `${dates.shortDate(start)} - ${dates.shortDate(end)}`;
    }
    return (
        <Box onClick={onClick} padding={padding} withSorter={withSorter}>
            <Icon
                clickable
                icon="Calendar"
                size={2}
                opacity={1}
                hue={iconHue ?? 'primary'}
            />
            <Text textSize={textSize}>{text}</Text>
            <Icon
                clickable
                icon={open ? 'ChevronUp' : 'ChevronDown'}
                size={2}
                opacity={1}
                hue="grey"
                shade="5"
            />
        </Box>
    );
};

const Box = styled.div<{ padding: BoxSize; withSorter?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    align-self: center;
    ${mix.type({ level: 'body2' })}
    cursor: pointer;
    ${({ padding }) => mix.padding({ padding })};
    ${mix.round({ rounded: true })};
    background: var(--gray-100);
    gap: ${mix.unit({ size: 1 })};
    height: 100%;

    ${({ withSorter }) =>
        withSorter &&
        css`
            border-radius: 0 4px 4px 0;
        `};

    &:hover {
        cursor: pointer;
    }
`;

const Text = styled.span<{ textSize: keyof TypeSystem }>`
    ${({ textSize }) => mix.type({ level: textSize })};
    ${mix.color({ profile: 'body' })}
    line-height: 1;
    white-space: nowrap;
`;

const PickerBox = styled.div<{ direction: string }>`
    position: absolute;
    top: 100%;
    ${mix.padding({ padding: [1] })}
    ${mix.round({ rounded: true })}
    ${mix.shadow()}
    ${mix.type({ level: 'body1' })}
    background: white;
    z-index: 500;
    ${({ direction }) => (direction === 'start' ? 'left: 0' : 'right: 0')}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;
