import React from 'react';
import { Heading } from '$ui/Flo/Heading';

interface Props {
    title: string;
    children: React.ReactNode;
}

export const Intro = ({ title, children }: Props) => {
    return (
        <>
            <Heading level="body1">{title}</Heading>
            <Heading level="body2" profile="body">
                {children}
            </Heading>
        </>
    );
};
