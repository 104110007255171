import React from 'react';
import { useAppDispatch, useAppSelector, useSources } from '$state';
import {
    selectSort,
    viewMeta,
    viewStages
} from '$state/queries/actions';
import {
    actionsSorted,
    fetchActions,
    filterReferrals,
    filterSnooze
} from '$state/concerns/actions';
import { ToolbarV3 } from './ToolbarV3';
import { ReferralsFilter, SnoozedStatus } from '$types/app';
import {
    labels as labelsQuery,
    referralsEnabled,
    types as typesQuery
} from '$state/queries/client';
import { withState } from '$state/utils';
import { ActionsQuery } from '$state/types';

interface Props {
    title: string;
}

export const ConnectedToolbar = (props: Props) => {
    const { title } = props;
    const sortOrder = useAppSelector(selectSort);
    const refEnabled = useAppSelector(referralsEnabled);

    const dispatch = useAppDispatch();

    const toggleSortOrder = () =>
        dispatch(actionsSorted(sortOrder === 'desc' ? 'asc' : 'desc'));

    const onReferralsFiltered = (value: ReferralsFilter) =>
        dispatch(filterReferrals(value));

    const onSnoozeFiltered = (value: SnoozedStatus) =>
        dispatch(filterSnooze(value));

    const stages = useAppSelector(withState(viewStages, title));
    const meta = useAppSelector(withState(viewMeta, title));
    const types = useAppSelector(typesQuery);
    const labels = useAppSelector(labelsQuery);
    const sources = useSources();

    const updateQuery = (actionsQuery: Partial<ActionsQuery>) => {
        dispatch(
            fetchActions({
                stages,
                forView: title,
                query: {
                    ...meta.query,
                    ...actionsQuery
                }
            })
        );

        onSnoozeFiltered(
            actionsQuery.includeSnoozed ? 'showSnoozed' : 'hideSnoozed'
        );

        onReferralsFiltered(actionsQuery.referrals ?? 'both');
    };

    return (
        <ToolbarV3
            sort={sortOrder}
            onSorted={toggleSortOrder}
            referralsEnabled={refEnabled}
            updateQuery={updateQuery}
            query={meta.query}
            range={meta.query?.range}
            types={types}
            sources={sources}
            labels={labels}
        />
    );
};
