import React from 'react';
import { Icon } from '$ui/Flo/Icon';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Shadow } from '$ui/Flo/Shadow';
import { ConfirmationModal } from '$ui/Flo/ConfirmationModal';

interface LoadingSourceProps {
    loading: true;
}

interface LoadedSourceProps {
    loading?: false;
    custom?: boolean;
    editing?: boolean;
    count: number;
    value: string;
    existing?: string[];
    onEdit?: () => void;
    onDelete?: () => void;
    onSave?: (value: string) => void;
    onCancel?: () => void;
}

type SourceProps = LoadingSourceProps | LoadedSourceProps;

export const Source = (props: SourceProps) => {
    const editingRef = React.useRef<HTMLInputElement>(null);
    const [valid, setValid] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);

    const { loading } = props;

    const isEditing = !loading && props.editing === true;

    const isValid = () => {
        if (loading) {
            return false;
        }

        if (!editingRef.current) {
            return false;
        }

        const { existing } = props;
        const { value } = editingRef.current;

        return value !== '' && !existing?.includes(value);
    };

    const watchForChange = () => {
        setValid(isValid());
    };

    React.useEffect(() => {
        setValid(isValid());

        const { current } = editingRef;

        if (!current) {
            return;
        }

        const onKeyboardAction = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && isValid() && isEditing) {
                props.onSave?.(current.value ?? props.value);
                return;
            }

            if (event.key === 'Escape' && isEditing) {
                props.onCancel?.();
                return;
            }
        };

        current.addEventListener('keyup', onKeyboardAction);
        current.addEventListener('input', watchForChange);

        return () => {
            current.removeEventListener('keyup', onKeyboardAction);
            current.removeEventListener('input', watchForChange);
        };
    }, [isValid]);

    React.useLayoutEffect(() => {
        if (isEditing) {
            editingRef.current?.focus();
        }
    }, [isEditing]);

    if (loading) {
        return (
            <Container>
                <Shadow block rounded height={5} width={100} />
            </Container>
        );
    }

    const { custom, value } = props;

    if (!custom) {
        return (
            <Container>
                <Input disabled value={value} />
            </Container>
        );
    }

    const { editing } = props;

    if (editing) {
        const { onSave, onCancel } = props;

        const onConfirm = () => {
            if (!valid) {
                return;
            }

            onSave?.(editingRef.current?.value ?? value);
        };

        return (
            <Container>
                <Input ref={editingRef} custom editing defaultValue={value} />
                <IconContainer>
                    <Icon
                        opacity={valid ? 0.5 : 0.25}
                        clickable={valid}
                        icon="Check"
                        size={2}
                        onClick={onConfirm}
                    />
                    <Icon clickable icon="X" size={2} onClick={onCancel} />
                </IconContainer>
            </Container>
        );
    }

    const { onEdit = () => null, onDelete, count } = props;

    const onDeleting = () => {
        setDeleting(true);
    };

    return (
        <>
            {deleting && (
                <ConfirmationModal
                    icon={{ name: 'Trash2', hue: 'red' }}
                    title="Permanently delete this lead source?"
                    positiveButtonLabel="Yes, permanently delete it"
                    neutralButtonLabel="No, do not delete it"
                    onConfirm={() => {
                        onDelete?.();
                        setDeleting(false);
                    }}
                    onCancel={() => setDeleting(false)}
                >
                    <DeleteConfirmationContainer>
                        <div>
                            Are you sure you want to permanently delete the lead
                            source '{value}'?
                        </div>

                        {count > 0 && (
                            <div>
                                It is currently assigned to {count} patients,
                                whose lead source will be changed to 'Unknown'.
                            </div>
                        )}

                        {count <= 0 && (
                            <div>
                                It is currently not assigned to any patients,
                                and is safe to delete.
                            </div>
                        )}
                    </DeleteConfirmationContainer>
                </ConfirmationModal>
            )}
            <Container>
                <Input as="div" onClick={onEdit} custom>
                    {value}
                </Input>
                <IconContainer>
                    <Icon
                        clickable
                        icon="Trash2"
                        size={2}
                        onClick={onDeleting}
                    />
                </IconContainer>
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
`;

interface DefaultInputProps {
    custom?: false;
}

interface EditingInputProps {
    custom: true;
    editing?: boolean;
}

type InputProps = DefaultInputProps | EditingInputProps;

const Input = styled.input<InputProps>`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.padding({ padding: [1.25, 2] })};
    border: 1px solid var(--gray-300);
    border-radius: ${mix.unit({ size: 0.5 })};
    background: var(--gray-200);
    color: var(--gray-500);
    width: 100%;

    ${(props) => {
        const { custom } = props;

        if (!custom) {
            return;
        }

        const { editing } = props;

        if (!editing) {
            return css`
                border: 1px solid var(--gray-300);
                background: var(--gray-100);
                color: var(--gray-900);

                &:hover {
                    cursor: pointer;
                }
            `;
        }

        return css`
            border: 1px solid var(--gray-400);
            background: var(--gray-050);
            color: var(--gray-900);
        `;
    }};
`;

const IconContainer = styled.div`
    position: absolute;
    right: ${mix.unit({ size: 2 })};
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;

const DeleteConfirmationContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 2 })};
    align-items: center;
`;
