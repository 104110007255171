import React from 'react';
import styled, { css } from 'styled-components';
import { applyBg, applyTextColor, applyType } from '../util';

interface AlertBadgeProps {
    alert: true;
    size?: number;
    color?: string;
}

interface CounterBadgeProps {
    alert?: false;
    count: number;
    size?: number;
    color?: string;
}

type BadgeProps = AlertBadgeProps | CounterBadgeProps;

interface WrapperProps {
    size: number;
    color?: string;
}

const Wrapper = styled.span<WrapperProps>`
    ${({ theme }) => applyTextColor({ theme, dark: true, profile: 'title' })};
    ${({ theme }) => applyType({ theme, bold: true, level: 'small' })};
    ${({ theme, color }) => {
        if (color) {
            return css`
                background: ${color};
            `;
        }
        return applyBg({ theme, hue: 'red', shade: '6' });
    }};
    border-radius: 999px;
    height: 21px;
    width: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    text-align: center;
    line-height: 1;
    font-size: 10px;
    font-weight: 700;
`;

const clampCount = (count: number): string => {
    if (count > 99) {
        return '99+';
    }
    return count.toString();
};

export const Badge = (props: BadgeProps) => {
    const { alert, size = 1, color } = props;

    if (!alert) {
        const { count } = props;
        return (
            <Wrapper size={size} color={color}>
                {clampCount(count)}
            </Wrapper>
        );
    }

    return (
        <Wrapper size={size} color={color}>
            !
        </Wrapper>
    );
};
