import { BatchMessage, BatchMessageStatus, BulkState } from '$state/types/bulk';
import {
    createAction,
    createAsyncThunk,
    createReducer,
    createSelector,
} from '@reduxjs/toolkit';
import { RootState } from '$state';
import * as api from '$api/bulk';
import * as toasts from '$ui/Flo/ToastV2';
import * as uuid from 'uuid';

const filterMessageByStatus = (
    status: BatchMessageStatus,
    messages: Array<BatchMessage>,
) => {
    return messages.filter((message) => message.state === status);
};

export const togglePatient = createAction<string>('bulk/togglePatient');

export const toggleAll = createAction('bulk/toggleAll');

export const clear = createAction('bulk/clear');

type SendArgs = api.SendBulkMessageRequest & {
    total: number;
};

const toastChannel = (channel: 'Email' | 'SMS') =>
    channel === 'Email' ? 'email' : 'SMS';

export const send = createAsyncThunk<void, SendArgs>(
    'bulk/send',
    async (request) => {
        const channel = toastChannel(request.payload.channel);

        toasts.info({
            id: request.id,
            message: `Queuing ${request.total} bulk ${channel} messages`,
        });

        await api.send(request);

        toasts.success({
            id: request.id,
            message: `Queued ${request.total} bulk ${channel} messages`,
        });
    },
);

export const load = createAsyncThunk<api.BatchPayload, string>(
    'bulk/load',
    async (batchId, thunkAPI) => {
        const batch = await api.load(batchId);

        // TODO: Find a better place / mechanism to do this
        // This makes it difficult to test but is the only sane place to do
        // side-effectful code.
        const state = thunkAPI.getState() as RootState;
        const batchState = state.bulk.batches[batch.id];
        const total = batchState?.total ?? 0;
        const sent = filterMessageByStatus('Sent', batch.messages).length;
        const failed = filterMessageByStatus('Failed', batch.messages).length;

        if (total !== 0 && sent + failed >= total) {
            const channel = toastChannel(batchState.channel);
            toasts.success({
                id: batch.id,
                message: `Sent ${sent} bulk ${channel} messages`,
            });
        }

        return batch;
    },
);

export const initialState: BulkState = {
    selected: null,
    batches: {},
};

export default createReducer<BulkState>(initialState, (builder) => {
    builder
        .addCase(clear, (state) => {
            state.selected = null;
        })

        .addCase(togglePatient, (state, action) => {
            const patientId = action.payload;

            if (state.selected === 'all') {
                return;
            }

            if (state.selected === null) {
                state.selected = [patientId];
                return;
            }

            if (state.selected.includes(patientId)) {
                state.selected = state.selected.filter(
                    (id) => id !== patientId,
                );
                return;
            }

            state.selected.push(patientId);
        })

        .addCase(toggleAll, (state) => {
            if (state.selected === 'all') {
                state.selected = null;
                return;
            }

            state.selected = 'all';
        })

        .addCase(send.pending, (state, action) => {
            const { id, total, payload } = action.meta.arg;

            state.batches[id] = {
                total,
                channel: payload.channel,
                messages: [],
            };
        })

        .addCase(send.fulfilled, (state, action) => {
            const { id, total, payload } = action.meta.arg;

            state.batches[id] = {
                total,
                channel: payload.channel,
                messages: Array(total).map(() => ({
                    id: uuid.v4(),
                    state: 'Sending',
                })),
            };
        })

        .addCase(load.fulfilled, (state, action) => {
            const { id, messages } = action.payload;

            if (!state.batches[id]) {
                return;
            }

            state.batches[id].messages = messages;
        });
});

export const selectSelected = ({ bulk }: RootState) => bulk.selected;

interface Status {
    channel: 'Email' | 'SMS';
    total: number;
    sent: number;
    failed: number;
}

export const selectBulkStatus =
    (batchId: string) =>
    ({ bulk }: RootState): Status | undefined => {
        const batch = bulk.batches[batchId];

        if (!batch) {
            return;
        }

        const { channel, total, messages } = batch;

        return {
            channel: channel,
            total: total,
            sent: filterMessageByStatus('Sent', messages).length,
            failed: filterMessageByStatus('Failed', messages).length,
        };
    };

export const selectPendingBatches = createSelector(
    [({ bulk }: RootState) => bulk],
    (bulk) =>
        Object.entries(bulk.batches)
            .filter(([_, { total, messages }]) => {
                const sent = filterMessageByStatus('Sent', messages);
                const failed = filterMessageByStatus('Failed', messages);
                return sent.length + failed.length < total;
            })
            .map(([id]) => id),
);
