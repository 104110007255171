import { SettingStatus } from '$state/types/client';
import { error as toastError, success as toastSuccess } from '$ui/Flo/ToastV2';

export const showSavedSettingsStatus = (status: string, message?: string) => {
    switch (status) {
        case SettingStatus.ERROR_SAVING:
            toastError({
                id: SettingStatus.ERROR_SAVING,
                message: message || 'Oops. Something went wrong!',
            });

            break;
        case SettingStatus.SAVED:
            toastSuccess({
                id: SettingStatus.SAVED,
                message: message || 'Setting successfully applied!',
            });

            break;
        default:
            return;
    }
};
