import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';

interface Props {
    name: string;
    isOpen?: boolean;
    onClick: () => void;
    onClickCreate: () => void;
}

export const AccordionHeader = (props: Props) => {
    const { name, isOpen, onClick, onClickCreate } = props;
    return (
        <Header>
            <HeaderTitle onClick={onClick}>
                <HeaderTitleIcon
                    clickable
                    icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
                    size={2}
                />
                <Title>{name} Templates</Title>
            </HeaderTitle>
            <HeaderIcon
                clickable
                onClick={onClickCreate}
                icon="PlusCircle"
                size={2}
            />
        </Header>
    );
};

const Header = styled.div`
    ${mix.padding({ padding: 2 })};
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;
const HeaderIcon = styled(Icon)`
    flex: 0 0 auto;
    ${mix.margin({ margin: [0, 0, 0, 2] })};
`;

const HeaderTitle = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
`;

const HeaderTitleIcon = styled(Icon)`
    flex: 0 0 auto;
    ${mix.margin({ margin: [0, 2, 0, 0] })};
`;

const Title = styled.div`
    flex: 1 1 auto;
    ${mix.type({ level: 'body2', bold: true })}
`;
