import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';

export const SelectTemplate = () => {

    return <Container>
        <Message>Select a template</Message>
        <Icon icon="ArrowRight" size={2} opacity={0.6} />
    </Container>;
};

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: ${mix.unit({ size: 1 })};
    background-color: var(--gray-050);
    color: var(--gray-400);
`;

const Message = styled.div`
    color: var(--gray-400);
    font-size: 14px;
    font-family: Roboto, sans-serif;
`;

