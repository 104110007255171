import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

export const StaleConversation = () => {

    return <Container>
        <Title>Restart the conversation by sending a template</Title>
        <Description>You can only write a custom message within 24hrs of the last reply from the patient</Description>
    </Container>;
};

const Container = styled.div`
    width: 100%;
    background-color: var(--gray-050);
    ${mix.padding({ padding: [1.5, 2, 1.5, 2] })};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    ${mix.gap({ size: 1 })};
    min-height: 102px;
`;

const Title = styled.div`
    color: var(--gray-900);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
`;

const Description = styled.div`
    color: var(--gray-600);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
`;
