import React, { useEffect } from 'react';
import { Container, Subtitle } from './SendingEmail';
import { Text } from '$ui/Flo/Text';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Button } from '$ui/Flo/Button';
import styled from 'styled-components';
import { phone } from '$utils';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Code } from './EmailVerification';
import { Shadow } from '$ui/Flo/Shadow';
import { CountryCode } from '@/utils/phone';

interface Props {
    smsEnabled: boolean;
    practiceNumber: string;
    routeNumber: string;
    loading: boolean;
    onChange: (number: string, isValid: boolean) => void;
    onEnableSMS: () => void;
    country?: CountryCode;
}

export const SmsSetup = (props: Props) => {
    const {
        smsEnabled,
        practiceNumber,
        routeNumber,
        loading,
        onChange,
        onEnableSMS,
        country = CountryCode.GB,
    } = props;

    const [number, setNumber] = React.useState(practiceNumber);

    const handleChange = (text: string, isValid?: boolean) => {
        setNumber(text);
        onChange(text, isValid ?? false);
    };

    useEffect(() => {
        setNumber(practiceNumber);
    }, [practiceNumber]);

    if (!smsEnabled) {
        return (
            <Container>
                <Subtitle>
                    To use SMS, you must first set which phone number you want
                    voice calls to be routed to.
                </Subtitle>
                <Text level="body2">
                    Just incase your patient call your SMS mobile number (it
                    happens!).
                </Text>
                <FloatingTextField
                    type="phone"
                    label="Practice phone number"
                    value={number}
                    onChange={handleChange}
                    loading={loading}
                />
                <Action>
                    <Button
                        mode="solid"
                        hue="green"
                        icon={loading ? 'RotateCw' : 'CheckCircle'}
                        size="large"
                        rounded
                        disabled={loading}
                        spin={loading}
                        onClick={onEnableSMS}
                    >
                        Enable SMS
                    </Button>
                </Action>
            </Container>
        );
    }

    return (
        <Container>
            <Subtitle>
                This is the number Leadflo sends text messages from.{' '}
            </Subtitle>
            <Text level="body2">
                Give the number a call to test it routes to the correct number
            </Text>
            <RouteNumber>
                <Icon icon="Phone" size={3} />
                {loading && <Shadow height={2.25} width={30} />}
                {!loading && <Code fontSize={18}>{routeNumber}</Code>}
            </RouteNumber>
            <Text level="body2">
                Calling the number should route the call to your practice phone
                number {loading && <Shadow height={1.75} width={15} />}{' '}
                {!loading && `(${phone.format(practiceNumber, country)})`}
            </Text>
        </Container>
    );
};

const Action = styled.div`
    display: flex;
`;

const RouteNumber = styled.div`
    display: flex;
    align-items: center;
    ${mix.padding({ padding: [2] })};
    ${mix.gap({ size: 2 })};
    ${mix.bg({ hue: 'grey', shade: '9' })};
`;
