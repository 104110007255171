import React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { Tooltip } from '$ui/Flo/Tooltip';
import { useHover } from '@/utils/hooks';

interface FailedIconProps {
    reason?: string;
}

export const FailedIcon = ({ reason }: FailedIconProps) => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    return <Container>
        <Holder ref={hoverRef}>
            <Tooltip
                align="center"
                shade={'1'}
                size="x-small"
                open={hover}
            >
                {reason || 'Error Sending Message'}
            </Tooltip>
            <Icon icon="AlertCircle" opacity={1} size={2.5} color="error-500"></Icon>
        </Holder>
    </Container>;
};

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: ${mix.unit({ size: 2 })};
`;

const Holder = styled.div`
    position: relative;
`;
