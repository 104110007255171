import { css } from 'styled-components';
import { ThemedProps, TypeProps } from '../types';

export const applyType = ({
    theme,
    bold,
    level,
    lineHeight
}: ThemedProps<TypeProps>) => {
    const { family, size, weight } = theme.type[level];
    return css`
        font-family: ${family};
        font-size: ${size};
        font-weight: ${bold ? 500 : weight};
        line-height: ${lineHeight ?? '1.5'};
    `;
};
