import { PatientFilterState, ReportQuery } from '$state/types';
import { PatientsQuery } from '$state/types/patients';
import { lang } from '$utils';
import { isoDateTime } from '@/utils/date';
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { endOfToday, subDays } from 'date-fns';
import { RootState } from '..';

const initialState: PatientFilterState = {
    reports: [
        { value: 'pipeline/newLeads', label: 'Pipeline / New Leads' },
        {
            value: 'pipeline/consultsBooked',
            label: 'Pipeline / Consults Booked'
        },
        {
            value: 'pipeline/txPlanConsults',
            label: 'Pipeline / Tx Plan Consults Booked'
        },
        { value: 'pipeline/goneAhead', label: 'Pipeline / Gone Ahead' },
        { value: 'pipeline/lost', label: 'Pipeline / Lost' },
        {
            value: 'pipeline/failedToAttend',
            label: 'Pipeline / Failed to Attend'
        },
        { value: 'overview/leadsReceived', label: 'Overview / Leads Received' },
        {
            value: 'overview/consultsBooked',
            label: 'Overview / Consults Booked'
        },
        { value: 'overview/goneAhead', label: 'Overview / Gone Ahead' },
        { value: 'overview/losses', label: 'Overview / Losses' }
    ],
    query: {
        start: isoDateTime(subDays(Date.now(), 30)),
        end: isoDateTime(endOfToday()),
        types: null,
        labels: null,
        sources: null,
        stages: null,
        report: null
    },
    changed: false
};

export const updateQuery = createAction<Partial<ReportQuery & PatientsQuery>>(
    'reporting/updateQuery'
);

export default createReducer<PatientFilterState>(initialState, (builder) => {
    builder.addCase(updateQuery, (state, action) => {
        const query = {
            ...state.query,
            ...action.payload
        };
        state.changed = !lang.isEqual(state.query, query);
        state.query = query;
    });
});

export const selectPatientFilter = (state: RootState) => state.patientFilter;

export const selectChanged = (state: RootState) =>
    selectPatientFilter(state).changed;

export const selectQuery = (state: RootState) =>
    selectPatientFilter(state).query;

export const selectReportOptions = createSelector(
    [selectPatientFilter],
    ({ reports }) => reports
);
