import React from 'react';
import { createMachine } from 'xstate';
import { useMachine } from '@xstate/react';
import { Button } from '$ui/Flo/Button';
import { DeleteButton } from '$ui/Flo/AsyncButton';
import { ButtonSize, ButtonMode } from '$ui/Flo/types';

export type SelectedStatus =
    | 'init'
    | 'saving'
    | 'saved'
    | 'saving.failed'
    | 'deleting'
    | 'deleted'
    | 'deleting.failed';

const flow = createMachine({
    predictableActionArguments: true,
    id: 'delete',
    initial: 'idle',
    states: {
        idle: {
            on: {
                CONFIRM: {
                    target: 'confirming'
                }
            }
        },
        confirming: {
            after: {
                5000: { target: 'idle' }
            }
        }
    }
});

interface Props {
    id?: string;
    label?: string;
    onDelete: () => void;
    status?: SelectedStatus;
    size?: ButtonSize;
    mode?: ButtonMode;
    tooltip?: string;
}

export const ConfirmDeleteButton = ({
    onDelete,
    status,
    label = 'Delete Template',
    id,
    size = 'medium',
    mode = 'text',
    tooltip
}: Props) => {
    const [current, send] = useMachine(flow);

    if (current.matches('idle')) {
        return (
            <DefaultDeleteButton
                onDelete={() => send('CONFIRM')}
                label={label}
                id={id}
                size={size}
                mode={mode}
                tooltip={tooltip}
            />
        );
    }

    return (
        <ConfirmingDeleteButton
            id={id}
            onDelete={onDelete}
            status={status}
            size={size}
            mode={mode}
            tooltip={tooltip}
        />
    );
};

export const DefaultDeleteButton = ({
    onDelete,
    label,
    id,
    size = 'medium',
    mode = 'text',
    tooltip
}: Props) => (
    <Button
        rounded
        hue="red"
        shade="7"
        mode={mode}
        icon="Trash"
        onClick={onDelete}
        id={id}
        size={size}
        tooltip={tooltip}
    >
        {label}
    </Button>
);

export const ConfirmingDeleteButton = ({
    onDelete,
    status,
    size = 'medium',
    mode = 'text',
    tooltip,
    id
}: Props) => (
    <DeleteButton
        id={id}
        label="Are You Sure?"
        disabled={status !== 'init'}
        doing={status === 'deleting'}
        done={status === 'deleted'}
        size={size}
        onClick={onDelete}
        mode={mode}
        tooltip={tooltip}
    />
);
