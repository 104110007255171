import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon, IconName } from '$ui/Flo/Icon';
import { useHistory } from 'react-router';
import { Channels } from '$state/types';
import { useAppDispatch } from '$state';
import { closePatient } from '$state/concerns/patient';
import { Delete } from '$ui/TemplatesV2/Parts/Delete';
import * as templates from '$state/concerns/templates';
import { Template } from '$state/types/templates';
import { Modal } from '$ui/Flo/Modal/Modal';

interface AccordionSubItem {
    id: string;
    name: string;
    channel: Channels;
    icon: IconName;
    editable?: boolean;
    template: Template;
}

export const AccordionSubItem = (props: AccordionSubItem) => {
    const history = useHistory();
    const { id, name, channel, icon, template } = props;

    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = React.useState(false);

    const onEdit = () => {
        history.push(`/settings/templates/${channel}/edit/${id}`);
        dispatch(closePatient());
    };

    const onClose = () => {
        setShowModal(false);
    };

    const onDelete = async () => {
        dispatch(templates.deleteTemplate(template));
        onClose();
    };

    return (
        <SubItem>
            <SubItemIcon clickable icon={icon} size={2} />
            <Title onClick={onEdit}>{name}</Title>
            <ActionIcons>
                <ActionItemIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(true);
                    }}
                    clickable
                    icon="Trash2"
                    size={2}
                />
                <ActionItemIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        onEdit();
                    }}
                    clickable
                    icon="Edit3"
                    size={2}
                />
            </ActionIcons>

            {showModal && (
                <Modal closeOnOutsideClick onClose={onClose}>
                    <Delete onClose={onClose} onDelete={onDelete} />
                </Modal>
            )}
        </SubItem>
    );
};

const ActionItemIcon = styled(Icon)`
    :hover {
        stroke: var(--gray-600, #e3342f);
    }
`;

const SubItem = styled.div<{ isOpen?: boolean }>`
    ${mix.padding({ padding: [1.5, 2] })}
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${mix.unit({ size: 1 })};
    user-select: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    background: var(--gray-100, #f2f4f7);

    &:hover {
        cursor: pointer;
    }
`;

const SubItemIcon = styled(Icon)`
    flex: 0 0 auto;
`;

const ActionIcons = styled.div`
    display: flex;
    column-gap: ${mix.unit({ size: 2 })};
    justify-self: flex-end;
`;

const Title = styled.div`
    flex: 1 1 auto;
    ${mix.type({ level: 'body2', bold: false })};
    font-size: ${mix.unit({ size: 2 })};
`;
