import React from 'react';
import { SnoozeState } from '$state/types/snooze';
import { Icon } from '$ui/Flo/Icon';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { SnoozeInfo } from '$ui/Snooze/Info';
import { endType } from '$ui/Snooze/utils';

interface Props {
    endsAt: string;
    comment?: string | null;
    state: SnoozeState;
    onWakened: () => void;
}

export const SnoozeView = (props: Props) => {
    const { state, onWakened } = props;

    return (
        <Container>
            <SnoozeInfo {...props} />
            <Button data-cy="end-snooze-button" onClick={onWakened}>
                <Icon icon="Moon" hue="red" shade="6" opacity={1} clickable />
                {endType(state)} snooze
            </Button>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 4 })};
`;

const Button = styled.button`
    ${mix.gap({ size: 1 })};
    ${mix.padding({ padding: 1 })};
    ${mix.type({ level: 'body2', bold: true })};
    background: var(--error-100);
    color: var(--error-500);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    line-height: 1;
    border-radius: ${mix.unit({ size: 0.5 })};

    &:hover {
        cursor: pointer;
    }
`;
