import { dates } from '$utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { TimePicker } from '../TimePicker';
import { mix } from '../util';
import { Icon } from '$ui/Flo/Icon';
import { disablePastHours, disablePastMinutes } from '@/utils/date';
import { roundToNearestMinutes, set } from 'date-fns';

interface Options {
    scale: 'small' | 'medium';
    variant: 'white' | 'grey';
}

interface Props extends Partial<Options> {
    label?: string;
    value: string;
    interval?: number;
    onChange?: (value: string) => void;
    min?: Date
}

export const TimeField = (props: Props) => {
    const {
        label,
        value,
        interval = 5,
        scale = 'small',
        variant = 'white',
        onChange,
        min
    } = props;

    const pickerRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClickOutside = React.useCallback((event: MouseEvent) => {
        if (
            pickerRef.current &&
            !pickerRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    }, []);

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        if (min instanceof Date) {
            // Validate that current value is not out of the allowed range
            const currentTime = new Date(`1970-01-01T${value}`);
            const minTime = new Date(min);

            if (disablePastHours(minTime, currentTime.getHours()) || disablePastMinutes(minTime, currentTime.getHours(), currentTime.getMinutes())) {
                minTime.setMinutes(minTime.getMinutes() + interval); // to make sure that we are always looking for feature time
                const rounded = roundToNearestMinutes(minTime, {
                    nearestTo: 15
                });
                onChange && onChange(
                    dates.militaryTime(
                        set(minTime, {
                            hours: rounded.getHours(),
                            minutes: rounded.getMinutes()
                        })
                    )
                );
            }
        }
    }, [min, value]);

    return (
        <Container ref={pickerRef}>
            <FieldContainer
                scale={scale}
                variant={variant}
                onClick={() => setOpen(!open)}
            >
                {label && <Label>{label}</Label>}
                <div>{dates.time(`1970-01-01T${value}`)}</div>
                {!label && <Icon icon="Clock" />}
            </FieldContainer>
            {open && (
                <PickerContainer>
                    <TimePicker
                        interval={interval}
                        value={value}
                        onChange={onChange ?? (() => null)}
                        min={min}
                    />
                </PickerContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const FieldContainer = styled.div<Options>`
    ${mix.padding({ padding: 1 })};
    ${mix.gap({ size: 4 })};
    display: flex;
    border: 1px solid hsla(220, 17%, 93%, 1);
    border-radius: 2px;
    background: white;
    justify-content: space-between;
    font-weight: 500;
    color: hsla(215, 18%, 34%, 1);
    user-select: none;
    height: 100%;
    align-items: center;

    ${({ scale }) =>
        scale === 'small'
            ? css`
                  ${mix.type({ level: 'small' })};
                  border-radius: 2px;
              `
            : css`
                  ${mix.type({ level: 'body2' })};
                  border-radius: 4px;
              `};

    ${({ variant }) =>
        variant === 'white'
            ? css`
                  border: 1px solid hsla(220, 17%, 93%, 1);
                  background: white;
              `
            : css`
                  border: 1px solid var(--gray-200, #eaecf0);
                  background: var(--gray-100, #f2f4f7);
              `};

    &:hover {
        cursor: pointer;
    }
`;

const Label = styled.div`
    color: hsla(218, 15%, 65%, 1);
    flex: 1 0 auto;
`;

const PickerContainer = styled.div`
    position: absolute;
    transform: translateY(2px);
    z-index: 2000;
`;
