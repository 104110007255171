import * as React from 'react';
import styled, { css } from 'styled-components';
import { Hue, Shade } from '../types';
import { applyBg, applyTextColor, applyType, mix } from '../util';
import { Icon, IconName } from '../Icon';

interface Props {
    children: string | React.ReactNode;
    hue?: Hue;
    shade?: Shade;
    round?: boolean;
    onClick?: () => void;
    mode?: 'default' | 'outline';
    deletable?: boolean;
    onDelete?: () => void;
    trailingIcon?: IconName;
}

export const Label = (props: Props) => {
    const {
        children,
        hue = 'grey',
        shade = '10' as Shade,
        round = false,
        onClick,
        mode = 'default',
        deletable = false,
        onDelete,
        trailingIcon
    } = props;

    const dark = parseInt(shade) < 7;

    const handleTrailingIconClick = (
        e: React.MouseEvent<unknown, MouseEvent>
    ) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (onDelete) {
            onDelete();
        }
    };

    return (
        <Box
            hue={hue}
            shade={shade}
            round={round}
            onClick={onClick}
            mode={mode}
            clickable={!!onClick}
            trailingIcon={!!trailingIcon}
        >
            <Text dark={dark}>
                {children}
                {deletable && trailingIcon && (
                    <Icon
                        icon={trailingIcon}
                        size={1.5}
                        clickable
                        onClick={handleTrailingIconClick}
                    />
                )}
            </Text>
        </Box>
    );
};

const Box = styled.div<{
    hue: Hue;
    shade: Shade;
    round: boolean;
    mode: 'default' | 'outline';
    clickable: boolean;
    trailingIcon: boolean;
}>`
    ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')};
    ${({ trailingIcon }) =>
        trailingIcon
            ? mix.padding({ padding: [0.5, 1, 0.5, 1.4] })
            : mix.padding({ padding: [0.5, 1] })};
    display: inline-block;
    ${({ mode, hue, shade }) =>
        mode === 'default'
            ? applyBg
            : css`
                  border: 1px solid ${mix.palette({ hue, shade })};
              `};
    ${({ round }) => (round ? 'border-radius: 1.25rem;' : '')};
`;

const Text = styled.span<{ dark?: boolean }>`
    ${({ theme }) => applyType({ theme, level: 'small' })}
    ${({ theme, dark }) => applyTextColor({ theme, profile: 'title', dark })}
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    align-items: center;
    ${mix.gap({ size: 0.5 })};
`;
