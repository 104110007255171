import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileOdt = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.33L66.8 23.39L67.3 23.32C67.2372 22.8217 67.0123 22.3579 66.66 22L45.85 1.16C45.7351 1.04307 45.6076 0.939113 45.47 0.849998L45.37 0.789998C45.2632 0.727382 45.153 0.670631 45.04 0.619998L44.86 0.559999C44.7653 0.524056 44.6684 0.493998 44.57 0.469998C44.4086 0.43804 44.2445 0.4213 44.08 0.419998H10.68C10.0178 0.422631 9.38341 0.686869 8.91513 1.15514C8.44686 1.62341 8.18263 2.25777 8.17999 2.92V29.77C8.17999 30.433 8.44338 31.0689 8.91223 31.5378C9.38107 32.0066 10.017 32.27 10.68 32.27C11.343 32.27 11.9789 32.0066 12.4478 31.5378C12.9166 31.0689 13.18 30.433 13.18 29.77V5.42H41.58V23.56C41.58 24.2213 41.842 24.8557 42.3087 25.3242C42.7754 25.7928 43.4087 26.0574 44.07 26.06L62.33 26.16V74.58H13.18V70.7C13.18 70.037 12.9166 69.4011 12.4478 68.9322C11.9789 68.4634 11.343 68.2 10.68 68.2C10.017 68.2 9.38107 68.4634 8.91223 68.9322C8.44338 69.4011 8.17999 70.037 8.17999 70.7V77.08C8.18263 77.7422 8.44686 78.3766 8.91513 78.8449C9.38341 79.3131 10.0178 79.5774 10.68 79.58H64.83C65.4922 79.5774 66.1266 79.3131 66.5948 78.8449C67.0631 78.3766 67.3274 77.7422 67.33 77.08V23.68C67.3299 23.5627 67.3199 23.4456 67.3 23.33ZM46.58 21.08V9L58.76 21.14L46.58 21.08Z" />
            <path d="M16 45.69C15.5857 45.6413 15.1662 45.7147 14.7931 45.9013C14.42 46.0878 14.1096 46.3794 13.9 46.74C13.4253 47.8777 13.2199 49.1098 13.3 50.34C13.2195 51.5423 13.418 52.747 13.88 53.86C14.1032 54.2096 14.4206 54.4889 14.7957 54.6659C15.1708 54.8428 15.5883 54.9101 16 54.86C16.4104 54.9118 16.8271 54.8452 17.201 54.668C17.5748 54.4908 17.8902 54.2105 18.11 53.86C18.5769 52.7485 18.7756 51.5426 18.69 50.34C18.7661 49.1109 18.5645 47.8805 18.1 46.74C17.8894 46.3802 17.5788 46.0895 17.206 45.903C16.8331 45.7166 16.4141 45.6426 16 45.69Z" />
            <path d="M31.3 46.85C31.1305 46.5148 30.836 46.2598 30.48 46.14C29.9722 45.9821 29.4414 45.9111 28.91 45.93H27.22V54.6H28.91C29.2894 54.633 29.6714 54.5859 30.0314 54.4619C30.3915 54.3379 30.7214 54.1396 31 53.88C31.4509 53.2389 31.692 52.4738 31.69 51.69C31.69 51.26 31.69 50.69 31.69 49.89C31.6953 49.2757 31.6686 48.6615 31.61 48.05C31.5817 47.6343 31.4766 47.2274 31.3 46.85Z" />
            <path d="M53.66 35.27H3.17001C2.50778 35.2726 1.87343 35.5369 1.40516 36.0051C0.936884 36.4734 0.672647 37.1078 0.670013 37.77V62.7C0.670013 63.363 0.933406 63.9989 1.40225 64.4678C1.87109 64.9366 2.50697 65.2 3.17001 65.2H53.66C54.3231 65.2 54.9589 64.9366 55.4278 64.4678C55.8966 63.9989 56.16 63.363 56.16 62.7V37.77C56.1574 37.1078 55.8931 36.4734 55.4249 36.0051C54.9566 35.5369 54.3222 35.2726 53.66 35.27ZM20.66 55.86C19.76 57.05 18.2 57.66 16.04 57.66C13.88 57.66 12.32 57.05 11.42 55.86C10.52 54.67 10.08 52.86 10.08 50.33C9.94132 48.3732 10.4092 46.4212 11.42 44.74C12.31 43.5 13.87 42.88 16.04 42.88C18.21 42.88 19.76 43.5 20.66 44.74C21.666 46.4232 22.1335 48.3736 22 50.33C22 52.82 21.52 54.68 20.64 55.86H20.66ZM34.78 53.2C34.6406 54.0092 34.3452 54.7836 33.91 55.48C33.4624 56.1871 32.7895 56.7226 32 57C31.0006 57.3287 29.9516 57.4809 28.9 57.45H24V43.12H28.87C29.884 43.0971 30.8956 43.2286 31.87 43.51C32.6253 43.7361 33.2901 44.1945 33.77 44.82C34.2252 45.4525 34.539 46.1755 34.69 46.94C34.8947 47.9472 34.9985 48.9722 35 50C35.0206 51.0711 34.947 52.1418 34.78 53.2ZM46.37 46H42.68V57.41H39.5V46H35.77V43.12H46.37V46Z" />
        </svg>
    );
};
