import { applyRoundedCorners, mix } from '$ui/Flo/util';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
    show: boolean;
}

export const RequiresCustomisation = (props: Props) => {
    const { show } = props;
    if (!show) return null;
    return <Customisation>Requires Customisation</Customisation>;
};

const Customisation = styled.div`
    ${({ theme }) => applyRoundedCorners({ theme, rounded: true })}
    ${mix.bg({ hue: 'red', shade: '5' })}
    color: white;
    ${mix.padding({ padding: 0.5 })}
    ${mix.type({ level: 'small' })}
    ${mix.margin({ margin: [0, 2, 0, 0] })}
`;
