import * as api from '$api/dentally/financial';
import { FinancialResponse } from '$api/dentally/financial';
import { RootState } from '$state';
import {
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';

export const getFinancial = createAsyncThunk(
    'dentally/financial/get',
    (patientId: string): Promise<FinancialResponse> =>
        api.getFinancial(patientId)
);

export interface FinancialStore {
    [patientId: string]: FinancialState;
}

export interface FinancialState {
    status: string;
    data?: FinancialResponse;
}

const initialState: FinancialStore = {};

export default createReducer(initialState, (builder) => {
    builder.addCase(getFinancial.pending, (state, action) => {
        const patientId = action.meta.arg;
        state[patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(getFinancial.fulfilled, (state, action) => {
        const patientId = action.meta.arg;
        state[patientId] = {
            status: 'loaded',
            data: action.payload
        };
    });

    builder.addCase(getFinancial.rejected, (state, action) => {
        const patientId = action.meta.arg;
        state[patientId] = {
            status: 'error'
        };
    });
});

const financialState = (state: RootState) => state.financial;

export const selectFinancialData = createSelector(
    [(_, patientId: string) => patientId, financialState],
    (patientId, financial: FinancialStore) => {
        return financial[patientId]?.data;
    }
);

export const selectFinancialStatus = createSelector(
    [(_, patientId: string) => patientId, financialState],
    (patientId, financial: FinancialStore) => {
        return financial[patientId]?.status;
    }
);
