import { Icon } from '$ui/Flo/Icon';
import { alpha, mix } from '$ui/Flo/util';
import { array, fn } from '$utils';
import React from 'react';
import styled, { css } from 'styled-components';

export interface Item {
    name: string;
    checked: boolean;
    disabled: boolean;
}

interface Props {
    items: Item[];
    onClick: (items: Item[]) => void;
    disabled?: boolean;
}

export const Checklist = ({ items, onClick, disabled }: Props) => {
    const sortedItems = array.sortBy(items, fn.prop<Item>('disabled'), 'desc');

    const onItemClick = (clickedItem: Item) => {
        onClick(
            items.map((item) => {
                if (clickedItem.name !== item.name) {
                    return item;
                }

                return {
                    ...item,
                    checked: !item.checked
                };
            })
        );
    };

    return (
        <StyledContainer>
            {sortedItems.map((item) => {
                return (
                    <ChecklistItem
                        key={item.name}
                        item={item}
                        onClick={onItemClick}
                        disabled={disabled}
                    />
                );
            })}
        </StyledContainer>
    );
};

interface CheckProps {
    disabled?: boolean;
    item: Item;
    onClick: (item: Item) => void;
}

const ChecklistItem = ({
    item,
    onClick,
    disabled: globallyDisabled = false
}: CheckProps) => {
    const disabled = globallyDisabled || item.disabled;
    const Label = disabled ? StyledDisabledLabel : StyledLabel;

    const onItemClick = () => {
        if (disabled) {
            return;
        }

        onClick(item);
    };

    return (
        <Label onClick={onItemClick}>
            <Checkbox {...item} disabled={disabled} /> {item.name}
        </Label>
    );
};

const Checkbox = ({ checked, disabled }: Item) => {
    const alpha = disabled ? 0.5 : 0.8;
    const check = checked ? (
        <Icon icon="Check" hue="white" opacity={alpha} />
    ) : null;
    return <StyledCheckbox alpha={alpha}>{check}</StyledCheckbox>;
};

interface StyledCheckboxProps {
    alpha: number;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
    ${({ theme, alpha: a }) =>
        css`
            box-shadow: inset 0 0 0 2px
                ${alpha({
                    theme,
                    hue: 'primary',
                    shade: '10',
                    alpha: a
                })};
        `};

    width: ${mix.unit({ size: 2 })};
    height: ${mix.unit({ size: 2 })};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
`;

const StyledLabel = styled.label`
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body', dark: true })};
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 1 })};
    line-height: 1;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;

const StyledDisabledLabel = styled(StyledLabel)`
    ${mix.color({ profile: 'disabled', dark: true })};

    &:hover {
        cursor: not-allowed;
    }
`;
