import { SMSTemplate } from '$state/types/automations';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled from 'styled-components';
import { highlightMergeTags } from './mergeTags';

interface Props {
    sms: SMSTemplate | null;
}

export const SMSViewer = (props: Props) => {
    const { sms } = props;

    if (!sms) {
        return null;
    }

    return (
        <Mount>
            <Phone>
                <Topbar />
                <Frame>
                    <Message>
                        {highlightMergeTags(sms.template)}
                        <Speechmark />
                    </Message>
                </Frame>
            </Phone>
        </Mount>
    );
};

const Mount = styled.div`
    ${mix.bg({ hue: 'grey', shade: '10' })}
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1 1 auto;
`;

const Phone = styled.div`
    border: 8px solid ${mix.palette({ hue: 'grey', shade: '2' })};
    width: 280px;
    height: 580px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
`;

const Frame = styled.div`
    ${mix.padding({ padding: [2] })}
    background: white;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-radius: 0 0 20px 20px;
`;

const Message = styled.div`
    ${mix.bg({ hue: 'green', shade: '6' })}
    color: white;
    width: 80%;
    border-radius: 15px;
    ${mix.padding({ padding: [1.25] })}
    ${mix.type({ level: 'body2' })}
    position: relative;
`;

const Topbar = styled.div`
    ${mix.bg({ hue: 'grey', shade: '10' })}
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: ${mix.unit({ size: 6 })};
    flex: 0 1 auto;
`;

const Speechmark = styled.span`
    position: absolute;
    width: 12px;
    height: 12px;
    ${mix.bg({ hue: 'green', shade: '6' })}
    border-radius: 0 0 0 15px;
    bottom: 0;
    right: -3px;

    &::after {
        content: '';
        background: white;
        height: 13px;
        width: 7px;
        border-radius: 0 0 0 7px;
        position: absolute;
        bottom: 0;
        left: 9px;
    }
`;
