import React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Button } from '$ui/Flo/Button';
import { Shadow } from '$ui/Flo/Shadow';

interface NextProps {
    next: boolean;
    last: boolean;
    onNext: () => void;
    onFinish: () => void;
    loading?: boolean;
}

export interface FooterProps extends NextProps {
    back: boolean;
    onBack: () => void;
}

export const Footer = (props: FooterProps) => {
    const { back, onBack, loading, ...nextProps } = props;

    const nextButton = loading ? (
        <Shadow width="11.125" height={4.5} rounded />
    ) : (
        <NextButton {...nextProps} />
    );

    const backButton = loading ? (
        <Shadow width="11.375" height={4.5} rounded />
    ) : (
        <Button onClick={onBack} rounded iconSize="small" icon="ArrowLeft">
            Back
        </Button>
    );

    return (
        <Container back={back}>
            {back && backButton}
            {nextButton}
        </Container>
    );
};

export const NextButton = (props: NextProps) => {
    const { next, last, onNext, onFinish } = props;

    if (!next) {
        return null;
    }

    const label = last ? 'Finish' : 'Next';
    const onClick = last ? onFinish : onNext;

    return (
        <Button
            onClick={onClick}
            rounded
            iconSize="small"
            icon="ArrowRight"
            align="right"
        >
            {label}
        </Button>
    );
};

const Container = styled.div<{ back: boolean }>`
    ${mix.padding({ padding: 2 })};
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    display: flex;
    justify-content: space-between;

    ${({ back }) =>
        !back &&
        css`
            justify-content: flex-end;
        `};
`;
