type Cancellable = { cancel: () => void };

export type DebounceFunction<TArgs extends any[]> = {
    (...args: TArgs): void;
    /**
     * Cancels the debounced function
     */
    cancel(): void;
    /**
     * Checks if there is any invocation debounced
     */
    isPending(): boolean;
    /**
     * Runs the debounced function immediately
     */
    flush(...args: TArgs): void;
};

/**
 * Given a delay and a function returns a new function
 * that will only call the source function after delay
 * milliseconds have passed without any invocations.
 *
 * The debounce function comes with a `cancel` method
 * to cancel delayed `func` invocations and a `flush`
 * method to invoke them immediately
 *
 * From: https://github.com/rayepps/radash/blob/master/src/curry.ts
 */
export const debounce = <TArgs extends any[]>(
    { delay }: { delay: number },
    func: (...args: TArgs) => any,
) => {
    let timer: NodeJS.Timeout | undefined = undefined;
    let active = true;

    const debounced: DebounceFunction<TArgs> = (...args: TArgs) => {
        if (active) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                active && func(...args);
                timer = undefined;
            }, delay);
        } else {
            func(...args);
        }
    };
    debounced.isPending = () => {
        return timer !== undefined;
    };
    debounced.cancel = () => {
        active = false;
    };
    debounced.flush = (...args: TArgs) => func(...args);

    return debounced;
};

/**
 * Given an interval and a function returns a new function
 * that will only call the source function if interval milliseconds
 * have passed since the last invocation
 *
 * From: https://github.com/rayepps/radash/blob/master/src/curry.ts
 *
 * Adapted to support Lodash cancel
 */
export const throttle = <TArgs extends any[], TReturn>(
    { interval }: { interval: number },
    func: (...args: TArgs) => TReturn,
): ((...args: TArgs) => TReturn) & Cancellable => {
    let ready = true;
    let timeout: number | undefined;

    const throttled = (...args: TArgs) => {
        if (!ready) return;
        func(...args);
        ready = false;
        timeout = window.setTimeout(() => {
            ready = true;
        }, interval);
    };

    throttled.cancel = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    };

    return throttled as unknown as ((...args: TArgs) => TReturn) & Cancellable;
};

/**
 * Accepts a key and returns a function that accepts an object and returns
 * the value at the key.
 */
export const prop =
    <T extends object, TKey extends keyof T = keyof T>(key: TKey) =>
    (obj: T) =>
        obj[key];

type MemoizedFn<TArgs extends any[], TReturn> = (...args: TArgs) => TReturn;

/**
 * Creates a memoized version of the given function that will only call the
 * source function for a given set of arguments once.
 */
export const memoize = <TArgs extends any[], TReturn>(
    fn: MemoizedFn<TArgs, TReturn>,
): MemoizedFn<TArgs, TReturn> => {
    const cache = new Map<string, TReturn>();
    return (...args: TArgs) => {
        const key = JSON.stringify(args);
        if (cache.has(key)) {
            return cache.get(key)!;
        }
        const result = fn(...args);
        cache.set(key, result);
        return result;
    };
};

/**
 * A function that returns the value it is given.
 *
 * @param x
 */
export const identity = <T>(x: T) => x;
