import React from 'react';
import { SnoozeStatus } from '$state/types/snooze';
import { StepProps } from './types';
import { FlowNodeType } from '$types/flows';
import { Step } from '$ui/Actions/Flow/Steps/Step';
import { endType, SnoozeInfo } from '$ui/Snooze';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Check } from '$ui/Flo/Check';

interface SnoozeValue {
    endSnooze: boolean;
}

export interface Props extends StepProps<SnoozeValue> {
    type: FlowNodeType.snooze;
    snooze: SnoozeStatus;
}

export const EndSnoozeStep = (props: Props) => {
    const { snooze, initial } = props;

    const onChange = () => {
        props.onChange({
            endSnooze: !initial?.endSnooze
        });
    };

    const tense = snooze.state === 'asleep' ? 'is' : 'was';

    return (
        <Step {...props} title={`Patient ${tense} snoozed`}>
            <Container>
                <Question>
                    Do you want to {endType(snooze.state).toLowerCase()} the
                    patient's snooze?
                </Question>
                <SnoozeInfo
                    endsAt={snooze.ends_at}
                    comment={snooze.comment}
                    state={snooze.state}
                />
                {/* TODO: Allow check to support a label */}
                <CheckContainer>
                    <Check
                        label={`${endType(snooze.state)} the patient's snooze`}
                        onChange={onChange}
                        checked={initial?.endSnooze}
                    />
                </CheckContainer>
            </Container>
        </Step>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 4 })};
    ${mix.padding({ padding: [0, 4] })};
`;

const Question = styled.div`
    ${mix.type({ level: 'body1' })};
    color: var(--gray-400);
`;

const CheckContainer = styled.div`
    ${mix.type({ level: 'body2' })};
    display: flex;
    align-items: center;
    ${mix.gap({ size: 2 })};
    color: var(--gray-600);
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;
