import { AuthArgs } from '$state/types';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Field } from './Field';

interface Props {
    onSubmit: (details: AuthArgs) => void;
    error?: string | null;
}

/**
 * Implemented as a separate form so we could, if they become unauthenticated,
 * show a modal instead of redirecting them to the login page in-app.
 */
export const Form = ({ onSubmit, error = null }: Props) => {
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit({
            email,
            password,
        });
    };

    return (
        <Container onSubmit={_onSubmit}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Field
                label="Email"
                type="email"
                placeholder="Enter your email address"
                onChange={setEmail}
                value={email}
            />
            <Field
                label="Password"
                type="password"
                placeholder="Enter your password"
                onChange={setPassword}
                value={password}
            />
            <Button id="login-submit">Login</Button>
        </Container>
    );
};

const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 3 })};
`;

const Button = styled.button`
    ${mix.bg({ hue: 'primary', shade: '6' })};
    ${mix.padding({ padding: 2 })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.type({ level: 'subtitle2' })};
    border: 0;
    border-radius: 3px;
    width: ${mix.unit({ size: 40 })};
    line-height: 1;

    &:hover {
        cursor: pointer;
    }
`;

const ErrorMessage = styled.div`
    ${mix.bg({ hue: 'red', shade: '6' })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'subtitle2' })};
`;
