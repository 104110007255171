import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled, { css } from 'styled-components';

interface Props {
    onClose: () => void;
}

export const Success = (props: Props) => {
    const { onClose } = props;

    return (
        <Container state="success" data-cy="action-success">
            <Info>
                <StatesIcon
                    state="success"
                    icon="CheckCircle"
                    opacity={1}
                    size={2}
                />
                <div>Awesome work! This action is complete.</div>
            </Info>
            <IconContainer state="success" onClick={onClose}>
                <StatesIcon
                    state="success"
                    icon="X"
                    opacity={1}
                    size={2}
                    clickable
                />
            </IconContainer>
        </Container>
    );
};

export const Snoozed = ({ onClose }: Props) => {
    return (
        <Container state="snoozed" data-cy="action-snoozed">
            <Info>
                <StatesIcon state="snoozed" icon="Moon" opacity={1} size={2} />
                <div>
                    We've snoozed this patient and will send you a reminder when
                    their snooze ends
                </div>
            </Info>
            <IconContainer state="snoozed" onClick={onClose}>
                <StatesIcon
                    state="snoozed"
                    icon="X"
                    opacity={1}
                    size={2}
                    clickable
                />
            </IconContainer>
        </Container>
    );
};

export const SnoozeExtended = ({ onClose }: Props) => {
    return (
        <Container state="snoozed" data-cy="action-snoozed">
            <Info>
                <StatesIcon state="snoozed" icon="Moon" opacity={1} size={2} />
                <div>
                    We've extended this patients snooze and will send you a reminder when their snooze ends
                </div>
            </Info>
            <IconContainer state="snoozed" onClick={onClose}>
                <StatesIcon
                    state="snoozed"
                    icon="X"
                    opacity={1}
                    size={2}
                    clickable
                />
            </IconContainer>
        </Container>
    );
};

interface StatesProps {
    state: 'success' | 'snoozed';
}

const Info = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    ${mix.gap({ size: 1 })};
`;

const Container = styled.div<StatesProps>`
    ${mix.type({ level: 'body2' })}
    ${mix.round({ rounded: true })}
    ${mix.padding({ padding: [0, 1.5] })}
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--success-600);
    background-color: var(--success-100);
    ${mix.height({ size: 6 })};
    text-rendering: geometricPrecision;

    ${({ state }) =>
        state === 'snoozed' &&
        css`
            color: #6942eb;
            background: #ebe9fd;
        `}
`;

const IconContainer = styled.div<StatesProps>`
    ${mix.sq({ size: 4 })};
    border-radius: 16px;
    background: rgba(3, 152, 85, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    ${({ state }) =>
        state === 'snoozed' &&
        css`
            background: rgba(105, 66, 235, 0.08);
        `}
`;

const StatesIcon = styled(Icon)<StatesProps>`
    stroke: var(--success-600);

    ${({ state }) =>
        state === 'snoozed' &&
        css`
            stroke: #6942eb;
        `}
`;
