import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    placeholder: string; // The field placeholder
    name: string; // The field name, used for parents to identify
    multiline: boolean; // If false, enter presses will be blocked
    next?: string; // If provided, on enter will focus this field.
    toNextField: (next: string) => void; // Focuses another fields `name`
    value: string;
    maxLength?: number;
    onInput: (value: string) => void;
    onFocus?: (e: React.FocusEvent) => void;
}

export const Field = React.forwardRef<HTMLTextAreaElement, Props>(
    function Field(props, ref) {
        const {
            placeholder,
            name,
            multiline,
            toNextField,
            next,
            onInput,
            value,
            onFocus = () => null,
            maxLength
        } = props;

        const blockEnter = (
            e: React.KeyboardEvent<HTMLTextAreaElement>
        ): void => {
            if (multiline || e.key !== 'Enter') {
                const t = e.currentTarget;
                t.style.height = '';
                t.style.height = t.scrollHeight + 'px';
                return;
            }

            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.preventDefault();
            if (next) {
                toNextField(next);
            }
        };

        const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
            onInput(e.currentTarget.value);
            if (multiline) {
                e.currentTarget.style.height = '';
                e.currentTarget.style.height =
                    e.currentTarget.scrollHeight + 'px';
            }
        };

        return (
            <Container>
                <TextBox
                    onChange={onChange}
                    placeholder={placeholder}
                    key={name}
                    name={name}
                    title={name}
                    data-qa={'field-' + name}
                    role="textarea"
                    ref={ref}
                    onKeyDown={blockEnter}
                    value={value ?? ''}
                    rows={1}
                    maxLength={maxLength}
                    onFocus={onFocus}
                    haveMaxLength={!!maxLength}
                />
                {maxLength && (
                    <LengthIndicator
                        length={value?.length ?? 0}
                        maxLength={maxLength}
                    >
                        {value?.length ?? 0}/{maxLength}
                    </LengthIndicator>
                )}
            </Container>
        );
    }
);

const Container = styled.div`
    position: relative;
    border-bottom: 1px solid var(--gray-100);

    &:last-child {
        border-bottom: none;
    }
`;

interface TextboxProps {
    name: string;
    haveMaxLength?: boolean
}

// Span must be used as textarea so that it can auto-expand without JS hacks
const TextBox = styled.textarea<TextboxProps>`
    ${mix.padding({ padding: 1 })};
    border: none;
    background: none;
    width: 100%;
    resize: none;
    overflow: hidden;
    font-family: Roboto, serif;
    font-size: 15px;
    line-height: 22px;
    color: var(--gray-900);

    ${({ haveMaxLength }) =>
        haveMaxLength &&
        css`
          padding-right: 55px;
        `
}

    &::placeholder {
        color: var(--gray-400);
    }
`;

interface LengthIndicatorProps {
    length: number;
    maxLength: number;
}

const LengthIndicator = styled.div<LengthIndicatorProps>`
    ${mix.type({ level: 'small' })};
    position: absolute;
    right: 0;
    bottom: 8px;
    padding: 2px 4px;
    border-radius: 3px;
    color: var(--gray-600);

    ${({ length, maxLength }) =>
        length >= maxLength &&
        css`
            color: var(--error-100);
            background: var(--error-700);
        `};
`;
