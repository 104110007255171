import { RootState } from '..';
import { PatientEditorOpenState, PatientEditorState } from '../types';

export const state = (state: RootState): PatientEditorState =>
    state.patient.editor;

export const isOpen = (
    editorState: PatientEditorState
): editorState is PatientEditorOpenState => editorState.open;

export const patientId = (editorState: PatientEditorOpenState): string =>
    editorState.patientId;

export const contactId = (editorState: PatientEditorOpenState): number =>
    editorState.contactId;
