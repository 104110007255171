import {
    Action,
    APIWorkflow,
    CommTemplate,
    Delay,
    LoadedAction,
    UnloadedWorkflow
} from '$state/types/automations';
import { api } from './api';
import { Placeholder } from '$state/types/contexts';

export const loadAutomations = (): Promise<UnloadedWorkflow[]> => {
    return api.get('/automations').then((res) => {
        return res.data.map((automation: any) => {
            return {
                ...automation,
                requiresCustomisation: automation.requires_customisation
            } as UnloadedWorkflow;
        });
    });
};

export const testAutomation = (id: string, email: string): Promise<void> => {
    return api.post(`/automations/${id}/test`, {
        email
    });
};

export const enableAutomation = (
    id: string,
    enabled: boolean
): Promise<void> => {
    return api.put(`/automations/${id}/enable`, {
        enabled
    });
};

export const loadWorkflow = (id: string): Promise<APIWorkflow> =>
    api.get(`/automations/${id}`).then((res) => {
        return {
            ...res.data,
            requiresCustomisation: res.data.requires_customisation,
            startTrigger: res.data.start_trigger,
            endTrigger: res.data.end_trigger,
            nodes: res.data.nodes.map((node: any) => {
                return {
                    ...node,
                    requiresCustomisation: node.requires_customisation
                } as Action | Delay;
            })
        } as APIWorkflow;
    });

export const loadAction = (action: string): Promise<LoadedAction> =>
    api.get(`/automations/actions/${action}`).then((res) => {
        return {
            loaded: true,
            ...res.data,
            requiresCustomisation: res.data.requires_customisation
        };
    });

export const enableAction = (id: string, enabled: boolean): Promise<void> => {
    return api.put(`/automations/actions/${id}/enable`, {
        enabled
    });
};

export const saveTemplate = (
    action: string,
    templateId: string,
    template: CommTemplate
): Promise<void> => {
    return api.put(
        `/automations/actions/${action}/templates/${templateId}`,
        template
    );
};

export const createSegmentedTemplate = (
    action: string,
    segments: string[]
): Promise<void> => {
    return api.post(`/automations/actions/${action}/templates`, { segments });
};

export const changeSegments = (
    action: string,
    templateId: string,
    segments: string[]
): Promise<void> => {
    return api.put(
        `/automations/actions/${action}/templates/${templateId}/segments`,
        {
            segments
        }
    );
};

export const deleteSegmentedTemplate = (
    action: string,
    templateId: string
): Promise<void> => {
    return api.delete(`/automations/actions/${action}/templates/${templateId}`);
};

export const loadPlaceholders = (): Promise<Placeholder[]> =>
    api
        .get('/automations/template-context/placeholders')
        .then(({ data }) => data);
