import React from 'react';
import styled from 'styled-components';
import { TypeColorShade, TypeLevel } from '../types';
import { applyTextColor, applyType } from '../util';

interface TextProps {
    bold: boolean;
    level: TypeLevel;
    profile: TypeColorShade;
    dark: boolean;
    children?: React.ReactNode;
    inline?: boolean;
}

const Wrapper = styled.div<TextProps>`
    ${applyType}
    ${applyTextColor}
`;

export const Text = (props: Partial<TextProps>) => {
    const {
        children,
        level = 'body1',
        profile = 'body',
        dark = false,
        bold = false,
        inline = false
    } = props;

    const el = inline ? 'span' : 'div';
    return (
        <Wrapper {...{ bold, level, profile, dark }} as={el}>
            {children}
        </Wrapper>
    );
};
