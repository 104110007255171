import { api } from '$api/api';

interface BulkMessageRequest {
    id: string;
    payload: {
        channel: 'Email' | 'SMS';
        message: string;
        subject?: string;
    };
}

interface PatientBulkMessageRequest extends BulkMessageRequest {
    patient_ids: Array<string>;
}

interface ReportBulkMessageRequest extends BulkMessageRequest {
    report: string;
    from: string;
    to: string;
    types: Array<string> | null;
    labels: Array<string> | null;
    sources: Array<string> | null;
}

export type SendBulkMessageRequest =
    | PatientBulkMessageRequest
    | ReportBulkMessageRequest;

export const send = (request: SendBulkMessageRequest): Promise<void> =>
    api.post('/communications/bulk', request);

export interface BatchPayload {
    id: string;
    messages: Array<{
        id: string;
        state: 'Sending' | 'Sent' | 'Failed';
        at: string;
    }>;
}

export const load = (batchId: string): Promise<BatchPayload> =>
    api.get(`/communications/bulk/${batchId}`).then(({ data }) => data);
