import React from 'react';
import { AttachmentEvent as TAttachmentEvent, CommType } from '$types/patient';
import * as Parts from '$ui/Timeline/Parts';
import { OnlyAttachments } from '$ui/Timeline/Parts/Attachments/OnlyAttachments';
import {
    AttachmentEvent as AttachmentEventV2,
    CommType as CommTypeV2,
} from '$types/timeline-v2';

interface Props {
    patientName: string;
    practiceName: string;
    attachment: TAttachmentEvent | AttachmentEventV2;
    representative: {
        photo: string | null;
        name: string | null;
    };
}

export const AttachmentEvent = (props: Props) => {
    const { attachment } = props;

    const color = attachment.inbound ? 'gray-100' : 'primary-400';
    const text_content =
        'Attachments: ' +
        attachment.attachments.map((attachment) => attachment.name).join(', ');

    return (
        <Parts.Container inbound={attachment.inbound} title={text_content}>
            <Parts.Avatar
                type={DefineType(attachment.comm_type)}
                color={color}
                inbound={attachment.inbound}
                onlyAttachments={true}
                {...props}
            />
            <OnlyAttachments
                color={color}
                event={attachment}
                attachmentsEnabled
            />
        </Parts.Container>
    );
};

const DefineType = (commType: CommType | CommTypeV2) => {
    switch (commType) {
        case CommType.WhatsApp:
        case CommTypeV2.WhatsAppMessage:
            return 'WhatsApp';
        default:
            return 'Email';
    }
};
