import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import { Shade } from '$ui/Flo/types';
import { range } from '@/utils/array';

const ELLIP = '&hellip;' as const;
type PageNum = typeof ELLIP | number;

const NEXT_JUMP = 1; // How many pages the next arrow skips
const FIRST_PAGE = 1;

export interface Props {
    pages: number;
    page: number;
    onPageChange: (page: number) => void;
}

// See: https://github.com/mui/material-ui/blob/master/packages/mui-material/src/usePagination/usePagination.js#L38
export const calculatePages = (page: number, count: number): PageNum[] => {
    const { min, max } = Math;

    // Number of pages to render **around** current page
    const siblings = 1;

    // Number of pages to render at start and end of items before ellipses
    const boundaries = 1;

    const startPages = range(1, min(boundaries, count));
    const endPages = range(max(count - boundaries + 1, boundaries + 1), count);

    const siblingsStart = max(
        min(page - siblings, count - boundaries - siblings * 2 - 1),
        boundaries + 2
    );

    const siblingsEnd = min(
        max(page + siblings, boundaries + siblings * 2 + 2),
        endPages.length > 0 ? endPages[0] - 2 : count - 1
    );

    const leftEllipsis = () => {
        if (siblingsStart > boundaries + 2) {
            return [ELLIP];
        }

        if (boundaries + 1 < count - boundaries) {
            return [boundaries + 1];
        }

        return [];
    };

    const rightEllipsis = () => {
        if (siblingsEnd < count - boundaries - 1) {
            return [ELLIP];
        }

        if (count - boundaries > boundaries) {
            return [count - boundaries];
        }

        return [];
    };

    return [
        ...startPages,
        ...leftEllipsis(),
        ...range(siblingsStart, siblingsEnd),
        ...rightEllipsis(),
        ...endPages
    ];
};

export const Paginator = (props: Props) => {
    const { page, pages, onPageChange } = props;

    const next = () => {
        if (page >= pages) {
            return;
        }
        onPageChange(page + NEXT_JUMP);
    };

    const previous = () => {
        if (page <= FIRST_PAGE) {
            return;
        }
        onPageChange(page - NEXT_JUMP);
    };

    const previousAvailable = page > FIRST_PAGE;
    const nextAvailable = page < pages;

    return (
        <Container>
            <Button onClick={previous} disabled={!previousAvailable}>
                <Icon
                    icon="ArrowLeft"
                    hue="grey"
                    opacity={1}
                    clickable={previousAvailable}
                    size={2}
                />
                Previous
            </Button>

            <Pages>
                <Numbers {...props} />
            </Pages>

            <Button onClick={next} disabled={!nextAvailable}>
                Next
                <Icon
                    icon="ArrowRight"
                    hue="grey"
                    opacity={1}
                    clickable={nextAvailable}
                    size={2}
                />
            </Button>
        </Container>
    );
};

const Numbers = (props: Props) => {
    const { pages, page } = props;
    const items = calculatePages(page, pages);

    return (
        <>
            {items.map((p, i) => {
                if (p === ELLIP) {
                    return <Item key={i}>&hellip;</Item>;
                }

                return (
                    <Page key={i} {...props}>
                        {p}
                    </Page>
                );
            })}
        </>
    );
};

const Page = (props: Props & { children: number }) => {
    const { children, page, onPageChange } = props;
    const available = children != page;

    const select = () => {
        if (!available) {
            return;
        }

        onPageChange(children);
    };
    return (
        <Item
            selected={children == page}
            available={available}
            onClick={select}
        >
            {children}
        </Item>
    );
};

const ACTIVE_SHADE: Shade = '9';

const Item = styled.span<{ selected?: boolean; available?: boolean }>`
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'disabled' })};
    ${mix.round({ rounded: true })}
    ${mix.padding({ padding: 0 })}
    height: ${mix.unit({ size: 4.5 })};
    min-width: ${mix.unit({ size: 4.5 })};
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ selected }) =>
        selected &&
        css`
            ${mix.bg({ hue: 'grey', shade: ACTIVE_SHADE })};
            ${mix.type({ level: 'body2', bold: true })};
            ${mix.color({ profile: 'body' })};
        `}

    ${({ available }) =>
        available &&
        css`
            cursor: pointer;
            ${mix.color({ profile: 'secondary' })};

            &:hover {
                ${mix.bg({ hue: 'grey', shade: ACTIVE_SHADE })};
                ${mix.type({ level: 'body2', bold: true })};
            }
        `}
`;

const Pages = styled.div`
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 1 })};
`;

const Button = styled.div<{ disabled?: boolean }>`
    ${mix.padding({ padding: [0, 2] })};
    ${mix.type({ level: 'body2' })};
    height: ${mix.unit({ size: 4.5 })};
    color: ${mix.palette({ hue: 'grey', shade: '5' })};
    display: flex;
    align-items: center;
    line-height: 1;
    gap: ${mix.unit({ size: 1 })};
    transition: 0.25s background-color, 0.25s color;
    border-radius: 3px;

    &:hover {
        cursor: pointer;
        ${mix.bg({ hue: 'grey', shade: ACTIVE_SHADE })};
        transition: 0.25s background-color, 0.25s color;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;

            &:hover {
                cursor: default;
                background: transparent;
            }
        `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    ${mix.type({ level: 'body2' })}
    ${mix.color({ profile: 'body' })}
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
`;
