import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Subsection } from '$ui/Settings/Section/Subsection';
import { Shadow } from '$ui/Flo/Shadow';

export const LoadingSignature = () => {

    return (
        <Subsection id="signature" title="Signature">
            <Loading>
                <Shadow width={100} height={2.5} rounded />
            </Loading>
        </Subsection>
    );
};

const Loading = styled.span`
    ${mix.padding({ padding: [4] })}
    ${mix.round({ rounded: true })}
    display: flex;
    box-sizing: content-box;
    align-items: center;
    height: calc(1em + 18px);
`;
