import { FlowNodeType } from '$types';
import React from 'react';
import { DateField, AreaField } from '$ui/Flo/Field';
import { Step } from './Step';
import { StepProps } from './types';

interface ScheduleValue {
    date?: string;
    note?: string;
}
export interface Props extends StepProps<ScheduleValue> {
    type: FlowNodeType.schedule;
    label: string;
    defaultDays?: number;
}

// TODO: Move this stuff to a general project-wide util package
const daysToSeconds = (days = 0) => days * 24 * 60 * 60;

export const defaultDate = (days?: number) =>
    new Date(new Date().valueOf() + 1000 * daysToSeconds(days))
        .toISOString()
        .substring(0, 10);

export const ScheduleStep = (props: Props) => {
    const { onChange, defaultDays, initial = {} } = props;

    const [state, setState] = React.useState<ScheduleValue>({
        date: defaultDate(defaultDays),
        ...initial
    });

    const change = (val: ScheduleValue) => {
        setState({
            ...state,
            ...val
        });

        onChange({
            ...state,
            ...val
        });
    };

    React.useEffect(() => {
        onChange(state);
    }, [state.date, state.note]);

    return (
        <Step {...props}>
            <DateField
                label="Date"
                placeholder="e.g 'in 2 weeks'"
                labelPortion={10}
                editable
                valueChanged={(val) => change({ date: val })}
                initialValue={initial?.date ?? defaultDate(defaultDays)}
                padLeft={6}
            />

            <AreaField
                title="flow-schedule-notes"
                label="Is there anything to note about the patient?"
                placeholder="e.g 'pt is on holiday atm'"
                initialValue={initial?.note}
                valueChanged={(val) => change({ note: val })}
                editable
                padLeft={6}
            />
        </Step>
    );
};
