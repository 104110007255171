import { IconName } from '$ui/Flo/Icon';
import { Template } from '$state/types/templates';
import { CommType } from '$types/patient';
import { CommType as CommTypeV2 } from '$types/timeline-v2';

export interface EmailPayload {
    subject: string;
    body: string;
}

export interface SMSPayload {
    message: string;
}

export interface WhatsAppPayload {
    message: string;
    template?: Template;
}

export interface NotePayload {
    title?: string;
    content: string;
}

export type ChannelPayload =
    | SMSPayload
    | WhatsAppPayload
    | EmailPayload
    | NotePayload;

export enum Channels {
    Note = 'note',
    Email = 'email',
    SMS = 'sms',
    FormSubmissions = 'Form Submissions',
    WhatsApp = 'WhatsApp',
}

export const channelMatch = (type: CommType | CommTypeV2): CommType => {
    switch (type) {
        case CommType.Email:
        case CommTypeV2.Email:
            return CommType.Email;
        case CommType.SMS:
        case CommTypeV2.SMS:
            return CommType.SMS;
        case CommType.WhatsApp:
        case CommTypeV2.WhatsAppMessage:
            return CommType.WhatsApp;
        default:
            return CommType.Email;
    }
};

export type ChannelState = {
    [Channel in Channels]: ChannelConfig;
};

export type ChannelRules = {
    [Channel in keyof Partial<typeof Channels>]: () => boolean;
};

export type MemoizedChannelRules = {
    [Channel in keyof Partial<typeof Channels>]: boolean;
};

/**
 * Defines a channel that a user can communicate through, such as taking notes,
 * sending emails or SMS.
 */
export interface ChannelConfig {
    /**
     * A unique key used to identify the channel programatically.
     */
    channel: Channels;

    /**
     * A human-readable name for the channel, used in UI elements.
     */
    label: string;

    /**
     * The name of a Feather icon that represents the channel.
     */
    icon: IconName;

    /**
     * A set of user input fields, used to accept and validate communications
     * sent over this channel.
     */
    fields: ChannelField[];

    /**
     * The message to render when the channel is disabled for a patient.
     */
    disabledMessage?: string;

    /**
     * True if the channel is user writable
     */
    writable?: boolean;

    /**
     * True if the channel is templatable
     */
    templatable?: boolean;

    /**
     * True if the channel requires approval
     */
    approval?: boolean;

    /**
     * True if using the channel can end a snooze
     */
    canEndSnooze?: boolean;

    /**
     * Used for WhatsApp channel.
     * True if there was sent message from patient to client
     * in previous 24hours.
     */
    checkOngoing?: boolean;
}

/**
 * Defines a field of user input for a user to communicate via a channel.
 */
export interface ChannelField {
    placeholder: string;
    name: string;
    multiline: boolean;
    nextField?: string;
    required?: boolean;

    /**
     * The maximum number of characters allowed in the field. If not specified,
     * the field will not have a maximum length.
     */
    maxLength?: number;
}

export interface ReplyState {
    channel: Channels;
    fields: Partial<ChannelPayload>;
    endSnooze?: boolean;
}
