import {
    useAppSelector,
    usePlaceholdersLoader,
    useTemplateLoader
} from '$state/hooks';
import * as React from 'react';
import { ReplyBar } from '$ui/ReplyBar/ReplyBar';
import { ChannelConfig, Channels, ReplyState } from '$state/types';
import { selectTemplatableChannels } from '$state/concerns/channels';
import { selectChannelTemplates } from '$state/concerns/templates';
import { Template } from '$state/types/templates';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import { ContextState } from '$state/types/contexts';
import { selectPlaceholders } from '$state/concerns/placeholders';
import { withState } from '$state';
import { SnoozeStatus } from '$state/types/snooze';
import { whatsAppEnabled } from '$state/queries/integrations';

interface ConnectedReplyBarProps {
    send: () => void;
    sendTemplate?: (template: Template, message: string) => void;
    channels: Array<ChannelConfig>;
    enabledChannels: Array<Channels>;
    stalledChannels?: Array<Channels>;
    state: ReplyState;
    onStateChanged: (state: ReplyState) => void;
    templateContext?: ContextState;
    integrated?: boolean;
    showPlaceholders?: boolean;
    snooze?: SnoozeStatus | null;
}

export const ConnectedReplyBar = (props: ConnectedReplyBarProps) => {
    const {
        channels,
        templateContext,
        send,
        sendTemplate,
        enabledChannels,
        stalledChannels,
        state,
        onStateChanged,
        integrated,
        showPlaceholders = false,
        snooze = null
    } = props;

    usePlaceholdersLoader();
    useTemplateLoader();

    const placeholders = useAppSelector(selectPlaceholders);

    const templatableChannels = useAppSelector(
        withState(selectTemplatableChannels, {
            SMS: useAppSelector(smsEnabled),
            Email: useAppSelector(emailEnabled),
            WhatsApp: useAppSelector(whatsAppEnabled)
        })
    );

    const templates: Template[] | undefined = useAppSelector(
        selectChannelTemplates(state.channel)
    );

    return (
        <ReplyBar
            channels={channels}
            send={send}
            sendTemplate={sendTemplate}
            state={state}
            stateChanged={onStateChanged}
            enabledChannels={enabledChannels}
            stalledChannels={stalledChannels}
            templatableChannels={templatableChannels}
            templateContext={templateContext}
            templates={templates}
            placeholders={placeholders}
            integrated={integrated}
            showPlaceholders={showPlaceholders}
            snooze={snooze}
        />
    );
};
