import { Button } from '$ui/Flo/Button';
import React from 'react';
import { Container, Subtitle } from './SendingEmail';
import { Text } from '$ui/Flo/Text';
import styled from 'styled-components';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { SettingStatus } from '$state/types/client';

import { IconName } from '$ui/Flo/Icon';

interface Props {
    status: SettingStatus;
    onSend: (email: string) => void;
}

export const SendTestEmail = (props: Props) => {
    const { status, onSend } = props;

    const [email, setEmail] = React.useState<{
        email: string;
        isValid?: boolean;
    }>({
        email: '',
        isValid: false
    });

    const [icon, setIcon] = React.useState<IconName>('Mail');
    const [text, setText] = React.useState<string>('Send');

    React.useEffect(() => {
        switch (status) {
            case SettingStatus.LOADED:
                setIcon('Check');
                setText('Sent');
                break;
            case SettingStatus.LOADING:
                setIcon('RotateCw');
                setText('Sending');
                break;
            case SettingStatus.ERROR:
                setIcon('Mail');
                setText('Try again');
                break;
            default:
                setIcon('Mail');
                setText('Send');
                break;
        }
    }, [status]);

    return (
        <Container>
            <Subtitle>
                Test how Leadflo delivers email to a real address.
            </Subtitle>
            <Text level="body2">
                For accurate results, send tests to a Gmail or Outlook email.
            </Text>

            <FloatingTextField
                type="email"
                loading={false}
                label="Email address"
                value={email.email}
                placeholder={'Enter email address'}
                onChange={(email, isValid) => setEmail({ email, isValid })}
                required
            />
            <Action>
                <Button
                    onClick={() => {
                        email.isValid && onSend(email.email);
                    }}
                    mode="solid"
                    icon={icon}
                    size="large"
                    rounded
                    spin={status === SettingStatus.LOADING}
                    disabled={
                        !email.isValid ||
                        status === SettingStatus.LOADING ||
                        status === SettingStatus.LOADED
                    }
                >
                    {text}
                </Button>
            </Action>
        </Container>
    );
};

const Action = styled.div`
    display: flex;
`;
