import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import { Attachment } from '$types';
import { Icon as FloIcon } from '$ui/Flo/Icon';
import { useHover } from '@/utils/hooks';
import { truncate } from '@/utils/str';
import { extractFormat, getIconNameFromMime } from '@/utils/files';
import { fetchAttachment } from '$api';
import { downloadFromBuffer } from '@/utils/files';

interface FilesProps {
    files: Attachment[];
    alternative?: boolean;
}

interface ContainerProps {
    isMultiple: boolean;
}

export const Files = ({ files, alternative }: FilesProps) => {
    const isMultiple = files.length > 1;

    return (
        <Container isMultiple={isMultiple}>
            {files.map((file) => (
                <File
                    key={file.name}
                    name={file.name}
                    mime={file.type}
                    alternative={alternative}
                    url={file.url}
                    isSingle={!isMultiple}
                />
            ))}
        </Container>
    );
};

interface FileProps {
    name: string;
    mime: string;
    url: string;
    alternative?: boolean;
    isSingle?: boolean;
}

const File = ({ name, mime, url, alternative, isSingle }: FileProps) => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    const handleDownload = async (name: string, url: string) => {
        const data = await fetchAttachment(url);
        downloadFromBuffer(data, name);
    };

    return (
        <FileHolder
            ref={hoverRef}
            isHovered={hover}
            alternative={alternative}
            isSingle={isSingle}
        >
            <FloIcon
                icon={getIconNameFromMime(mime)}
                size={4}
                hue="black"
                opacity={1}
            />
            <Details>
                <Name>{truncate(name, 18)}</Name>
                <Format>{extractFormat(name).toUpperCase()}</Format>
            </Details>
            {hover && (
                <IconHolder>
                    <FloIcon
                        icon="DownloadCloud"
                        hue="black"
                        opacity={0.8}
                        onClick={() => handleDownload(name, url)}
                        clickable={true}
                    />
                </IconHolder>
            )}
        </FileHolder>
    );
};

const Container = styled.div<ContainerProps>`
    display: grid;
    gap: ${mix.unit({ size: 0.5 })};
    width: 100%;

    ${({ isMultiple }) =>
        isMultiple &&
        css`
            grid-template-columns: 50% 50%;
        `};
`;

interface FileHolderProps {
    isHovered: boolean;
    alternative?: boolean;
    isSingle?: boolean;
}

const FileHolder = styled.div<FileHolderProps>`
    border-radius: ${mix.unit({ size: 0.5 })};
    padding: ${mix.unit({ size: 1 })};
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 48px;
    position: relative;

    ${({ isSingle }) => isSingle && css`
      padding-right: ${mix.unit({ size: 6 })};
    `}

    ${({ alternative }) => {
        if (alternative) {
            return css`
                border: none;
                background-color: #ffffff;
            `;
        }

        return css`
            border: 1px solid;
            border-color: var(--gray-100);
        `;
    }}

    ${({ isHovered, alternative }) =>
        isHovered &&
        !alternative &&
        css`
            background-color: var(--gray-100);
            border-color: var(--gray-200);
        `};
`;

const Details = styled.div`
    color: var(--gray-600);
    font-family: Roboto, serif;
    margin-left: ${mix.unit({ size: 1 })};
`;

const Name = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
`;

const Format = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
`;

const IconHolder = styled.div`
    position: absolute;
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    top: ${mix.unit({ size: 1 })};
    right: ${mix.unit({ size: 1 })};
    bottom: ${mix.unit({ size: 1 })};
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
