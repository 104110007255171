import { fetchEmail } from '$api/inbox';
import { setup, fromPromise, assign } from 'xstate5';
import { openEmailArguments, openEmailArgumentsV2 } from '$types/patient';

export const fsm = setup({
    types: {
        context: {} as {
            patientId: string | null;
            args: openEmailArguments | openEmailArgumentsV2 | null;
            full_content: string | null;
        },
        events: {} as {
            type: 'get';
            patientId?: string | null;
            args?: openEmailArguments | openEmailArgumentsV2 | null;
        },
        output: {} as { full_content: string | null },
    },
    actors: {
        getEmail: fromPromise(
            async ({
                input,
            }: {
                input: { patientId: string; commId: string };
            }) => {
                const response = await fetchEmail(
                    input.patientId,
                    input.commId,
                );
                return response;
            },
        ),
    },
}).createMachine({
    id: 'getEmail',
    initial: 'init',
    context: {
        patientId: null,
        args: null,
        full_content: null,
    },
    states: {
        init: {
            on: {
                get: 'loading',
            },
        },
        loading: {
            invoke: {
                src: 'getEmail',
                input: ({ event }) => ({
                    patientId: event.patientId as string,
                    commId: event.args?.commName as string,
                }),
                onDone: {
                    target: 'success',
                    actions: assign({
                        full_content: ({ event }) => {
                            return event.output.full_content;
                        },
                    }),
                },
                onError: {
                    target: 'error',
                },
            },
        },
        success: {
            on: {
                get: 'init',
            },
        },
        error: {
            after: {
                1000: 'init',
            },
            on: {
                get: 'loading',
            },
        },
    },
    output: ({ context }) => ({
        full_content: context.full_content,
    }),
});
