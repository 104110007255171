import { useAppDispatch, useAppSelector } from '@/state';
import * as editor from '$state/queries/editor';
import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../Flo/Button';
import { closePatient } from '$state/concerns/patient';
import { TimelineContainer as Activities } from '$ui/Inbox/TimelineContainer';
import { Patient } from './Patient';
import { mix, scale } from '$ui/Flo/util';
import { Slideout } from '$ui/Shared/Slideout';

const ActivitiesCell = styled.div`
    background: #fff;
    grid-column-start: activities;
    overflow-y: hidden;
`;

const PatientCell = styled.div`
    grid-column-start: patient;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-left: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
`;

const Layout = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns:
        [activities] 2fr
        [patient] ${({ theme }) => scale(theme, 60)}px;
`;

export const Editor = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(editor.state);
    const open = editor.isOpen(state);

    const onClose = () => dispatch(closePatient());

    const activities = () => {
        if (!editor.isOpen(state)) {
            return null;
        }

        return <Activities patientId={editor.patientId(state)} />;
    };

    const patient = (barPosition: 'left' | 'right') => {
        if (!editor.isOpen(state)) {
            return null;
        }

        return (
            <Patient id={editor.patientId(state)} barPosition={barPosition}>
                <ButtonWrapper>
                    <Button
                        id="patient-editor-close"
                        mode="text"
                        icon="X"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </ButtonWrapper>
            </Patient>
        );
    };

    return (
        <>
            <Slideout open={open} onClose={onClose}>
                <Layout>
                    <ActivitiesCell>{activities()}</ActivitiesCell>
                    <PatientCell>{patient('right')}</PatientCell>
                </Layout>
            </Slideout>
        </>
    );
};

const ButtonWrapper = styled.div`
    text-align: right;
    position: absolute;
    right: ${mix.unit({ size: 1 })};

    & > div {
        position: absolute;
        transform: translateX(-100%);
        z-index: 4000;
    }
`;
