import React from 'react';
import { openEmailArguments, openEmailArgumentsV2 } from '$types/patient';
import { ConnectedTimeline } from '$ui/Timeline/ConnectedTimeline';
import { EmailModal } from '$ui/EmailModal';
import styled from 'styled-components';
import { ModalBackground, ModalOverlay } from '$ui/Flo/Modal';
import { Channels } from '$state/types';
import { fsm } from './TimelineContainer.fsm';
import { useMachine } from '@xstate5/react';

interface Props {
    patientId: string;
    selectChannelAfterLoad?: Channels;
    showRefreshButton?: boolean;
}

// temporary until we come to integrate new modal with
// activities component system wide, w/o increasing complexity
// of the Inbox view
/**
 * Temporary until we come to integrate new modal with activities component
 * system wide, w/o increasing complexity of the Inbox view.
 *
 * Memoized because this is quite a large component tree and is used in
 * several places where re-renders are common.
 */
export const TimelineContainer = React.memo(
    ({
        patientId,
        selectChannelAfterLoad,
        showRefreshButton = true,
    }: Props) => {
        const [subject, setSubject] = React.useState<string>('');
        const [state, send, output] = useMachine(fsm);

        const open = async (
            args: openEmailArguments | openEmailArgumentsV2,
        ) => {
            send({
                type: 'get',
                patientId: patientId,
                args: args,
            });

            setSubject(args.subject);
        };

        const close = () => {
            send({ type: 'get' });
        };

        return (
            <Container>
                {state.value != 'init' && (
                    <ModalOverlay>
                        <ModalBackground onClick={close} />
                        <EmailModal
                            state={state.value}
                            subject={subject}
                            onClose={close}
                            body={
                                output.getSnapshot().context.full_content ?? ''
                            }
                        />
                    </ModalOverlay>
                )}

                <ConnectedTimeline
                    patientId={patientId}
                    openEmailModal={open}
                    selectChannelAfterLoad={selectChannelAfterLoad}
                    showRefreshButton={showRefreshButton}
                />
            </Container>
        );
    },
);

TimelineContainer.displayName = 'TimelineContainer';

const Container = styled.div`
    flex: 2;
    position: relative;
    overflow: hidden;
    height: 100%;
`;
