import * as React from 'react';
import styled from 'styled-components';
import {
    applyFieldStyle,
    applyFieldWrapStyle,
    applyInputStyle,
    applyLabelStyle,
    FieldProps
} from './Field';
import isEmail from 'validator/lib/isEmail';
import { Icon } from '$ui/Flo/Icon';
import { useClickToCopy } from '$ui/Flo/util/hooks';
import { fn } from '$utils';

interface Props extends FieldProps {
    id?: string;
    label: string;
    value: string;
    valueChanged: (value: string) => void;
    transformOnCopy?: (value: string) => string;
}

export const TextField = (props: Props) => {
    const {
        label,
        editable,
        value,
        valueChanged,
        transformOnCopy = fn.identity
    } = props;

    const fieldRef = React.useRef<HTMLInputElement>(null);
    const [validation, setValidation] = React.useState<boolean | undefined>(
        undefined
    );

    const onCopy = useClickToCopy({
        enabled: !editable,
        transform: transformOnCopy,
        value,
        onCopied: () => {
            fieldRef.current?.select();
        }
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        checkValid(val);
        valueChanged(val);
    };

    const checkValid = (val: string) => {
        if (!val) return;
        if (props.id == 'email') {
            setValidation(isEmail(val.trim()));
        }
    };

    return (
        <Wrapper {...props}>
            <Label
                bold
                level="body2"
                profile="body"
                onClick={() =>
                    editable && fieldRef.current && fieldRef.current.focus()
                }
                {...props}
            >
                {label}
                {props.id == 'email' &&
                    editable &&
                    value &&
                    validation !== undefined && (
                        <LabelValidation valid={validation}>
                            {validation ? 'Valid' : 'Invalid'}
                        </LabelValidation>
                    )}
            </Label>
            <InputContainer {...props}>
                <Input
                    data-cy={props.id}
                    level="body2"
                    profile="body"
                    readOnly={!editable}
                    editable={editable}
                    onChange={onChange}
                    onClick={onCopy}
                    {...props}
                    value={value}
                    data-qa={'text-input-' + label}
                    ref={fieldRef}
                />
                {!editable && <ClickToCopyIcon onClick={onCopy} />}
            </InputContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${applyFieldWrapStyle}
`;

const Label = styled.label`
    ${applyLabelStyle}
`;

const LabelValidation = styled.span<{ valid: boolean }>`
    padding: 0.1rem 1rem;
    border-radius: 15px;
    margin-left: 1rem;
    background: ${({ valid }) => (valid ? 'green' : 'red')};
    color: white;
`;

const InputContainer = styled.div`
    ${applyFieldStyle};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
    position: relative;
`;

const ClickToCopyIcon = styled(Icon).attrs({
    icon: 'Copy',
    hue: 'primary',
    shade: '6',
    opacity: 1,
    clickable: true
})``;

const Input = styled.input`
    ${applyInputStyle}
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
