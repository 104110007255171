import * as React from 'react';
import { Text } from '../Text';
import styled, { css, CSSProperties } from 'styled-components';
import { mix } from '../util';
import { Shadow } from '../Shadow';
import validator from 'validator';
import { Icon } from '../Icon';
import { phone } from '$utils';
import { useClickToCopy } from '$ui/Flo/util/hooks';

export interface Props {
    type?: 'text' | 'email' | 'phone';
    label?: string;
    labelBold?: boolean;
    placeholder?: string;
    value: string | null;
    onChange: (text: string, isValid?: boolean) => void;
    disabled?: boolean;
    loading?: boolean;
    required?: boolean;
    multiline?: boolean;
    clickToCopy?: boolean;
    onFocus?: (e: React.FocusEvent) => void;
    autosize?: boolean;
    invalidMessage?: string;
    addOn?: string;
    addOnAlign?: 'left' | 'right';
    styles?: CSSProperties;
    innerStyles?: CSSProperties;
    disableError?: boolean;
    rows?: number;
}

export const FloatingTextField = React.forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
        const {
            type = 'text',
            value,
            label,
            labelBold = true,
            placeholder,
            disabled,
            loading,
            required,
            autosize = false,
            multiline = false,
            clickToCopy = false,
            invalidMessage,
            onChange,
            onFocus = () => null,
            addOn,
            addOnAlign = 'right',
            styles,
            innerStyles,
            disableError = false,
            rows,
        } = props;

        const onCopy = useClickToCopy({
            enabled: clickToCopy,
            value,
        });

        const [error, setError] = React.useState<string | undefined>(undefined);
        const internalRef = React.useRef<HTMLInputElement | null>(null);

        React.useImperativeHandle<
            HTMLInputElement | null,
            HTMLInputElement | null
        >(ref, () => internalRef.current);

        React.useEffect(() => {
            if (!autosize) {
                return;
            }

            const { current } = internalRef;

            if (!current) {
                return;
            }

            current.style.height = '1px';
            setTimeout(() => {
                current.style.height = `${current.scrollHeight}px`;
            }, 1);
        }, [autosize]);

        const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            let msg: undefined | string = undefined;
            if (
                required &&
                validator.isEmpty(value, { ignore_whitespace: true })
            ) {
                msg = invalidMessage ?? 'Must not be empty';
            }

            if (type === 'email' && !validator.isEmail(value)) {
                msg = 'Enter a valid email address';
            }

            if (type === 'phone' && !phone.is(value)) {
                msg = 'Enter a valid phone number';
            }

            if (multiline) {
                e.target.style.height = 'auto';
                e.target.style.height = e.target.scrollHeight + `px`;
            }

            if (!disableError) setError(msg);
            onChange(value, !msg);
        };

        if (loading) {
            return (
                <div>
                    <ErrorContainer error={error}>
                        <Label label={label} bold={labelBold} />
                    </ErrorContainer>
                    <Loading>
                        <Shadow width={40} height={2.5} rounded />
                    </Loading>
                </div>
            );
        }

        if (disabled) {
            return (
                <div>
                    <ErrorContainer error={error}>
                        <Label label={label} bold={labelBold} />
                    </ErrorContainer>
                    <InputGroup>
                        <Input
                            disabled={disabled}
                            multiline={multiline}
                            clickToCopy={clickToCopy}
                            onClick={onCopy}
                            addOnAlign={addOnAlign}
                        >
                            <InnerInput
                                as={multiline ? 'textarea' : 'input'}
                                disabled={disabled}
                                ref={internalRef}
                                multiline={multiline}
                                value={value ?? ''}
                                clickToCopy={clickToCopy}
                            />
                            {clickToCopy && <ClickToCopyIcon />}
                        </Input>
                        {addOn && <AddOn align={addOnAlign}>{addOn}</AddOn>}
                    </InputGroup>
                </div>
            );
        }

        return (
            <div>
                <ErrorContainer error={error}>
                    <Label label={label} bold={labelBold} />
                </ErrorContainer>
                <InputGroup>
                    <Input
                        multiline={multiline}
                        clickToCopy={clickToCopy}
                        onClick={onCopy}
                        addOnAlign={addOnAlign}
                        style={styles}
                    >
                        <InnerInput
                            as={multiline ? 'textarea' : 'input'}
                            multiline={multiline}
                            onChange={onInput}
                            value={value ?? ''}
                            ref={internalRef}
                            onFocus={onFocus}
                            clickToCopy={clickToCopy}
                            placeholder={placeholder}
                            style={innerStyles}
                            rows={rows}
                        />
                        {clickToCopy && <ClickToCopyIcon />}
                    </Input>
                    {addOn && <AddOn align={addOnAlign}>{addOn}</AddOn>}
                </InputGroup>
            </div>
        );
    },
);

FloatingTextField.displayName = 'FloatingTextField';

interface ErrorContainerProps {
    error?: string;
    children: React.ReactNode;
}

const ErrorContainer = ({ error, children }: ErrorContainerProps) => {
    return (
        <StyledErrorContainer>
            <div>{children}</div>
            {error && (
                <ErrorMessage>
                    {error}
                    <Icon hue="red" size={2} icon="AlertCircle" />
                </ErrorMessage>
            )}
        </StyledErrorContainer>
    );
};

const StyledErrorContainer = styled.div`
    ${mix.margin({ margin: [0, 0, 0.5] })};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ErrorMessage = styled.div`
    ${mix.type({ level: 'small' })};
    color: ${mix.palette({ hue: 'red', shade: '4' })};
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 0.5 })};
`;

interface LabelProps {
    label: string | undefined;
    bold?: boolean;
}

export const Label = ({ label, bold = true }: LabelProps) => {
    if (!label) {
        return null;
    }
    return (
        <Text level="body2" profile="body" bold={bold}>
            <label>{label}</label>
        </Text>
    );
};

const Loading = styled.span`
    ${mix.padding({ padding: [0.5, 1.5] })}
    ${mix.round({ rounded: true })}
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '7' })};
    background: white;
    display: flex;
    box-sizing: content-box;
    align-items: center;
    height: calc(1em + 18px);
`;

const InnerInput = styled.div<{
    disabled?: boolean;
    multiline?: boolean;
    clickToCopy?: boolean;
    rows?: number;
}>`
    border: none;
    outline: none;
    ${mix.type({ level: 'body2' })}
    line-height: 1;
    box-sizing: border-box;
    width: calc(100% - (${mix.unit({ size: 2 })}));
    height: 100%;
    ${mix.padding({ padding: 1.5 })}

    ${({ multiline, rows }) =>
        multiline &&
        css`
            overflow: hidden;
            resize: vertical;
            min-height: ${rows ? `${rows * 2}rem` : '8rem'};
            line-height: 1.4;
        `}

    ${({ clickToCopy }) =>
        clickToCopy &&
        css`
            &:hover {
                cursor: pointer;
            }
        `};
`;

const Input = styled.span<{
    disabled?: boolean;
    multiline?: boolean;
    clickToCopy?: boolean;
    addOnAlign?: 'left' | 'right';
}>`
    outline: none;
    ${mix.round({ rounded: true })}
    ${mix.type({ level: 'body1' })}
    width: 100%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    line-height: 1;
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '7' })};
    position: relative;

    ${({ disabled }) =>
        disabled &&
        css`
            border: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
            ${mix.color({ profile: 'secondary' })}
            background: ${mix.palette({ hue: 'grey', shade: '9' })}
        `}

    ${({ disabled }) =>
        !disabled &&
        css`
            background: white;
            ${mix.color({ profile: 'body' })};
        `}

    ${({ clickToCopy }) =>
        clickToCopy &&
        css`
            &:hover {
                cursor: pointer;
            }
        `};

    ${({ addOnAlign }) =>
        addOnAlign === 'left' &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `}

    ${({ addOnAlign }) =>
        addOnAlign === 'right' &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `}
`;

const ClickToCopyIcon = styled(Icon).attrs({
    clickable: true,
    icon: 'Copy',
})`
    position: absolute;
    right: ${mix.unit({ size: 1 })};
    top: 50%;
    transform: translateY(-50%);
`;

const InputGroup = styled.div`
    display: flex;
`;

const AddOn = styled.div<{ align: 'left' | 'right' }>`
    ${mix.padding({ padding: 1.5 })};
    ${mix.color({ profile: 'secondary' })}
    ${mix.round({ rounded: true })};
    ${mix.type({ level: 'body2' })};
    background: ${mix.palette({ hue: 'grey', shade: '9' })};
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    flex: 1 0 auto;

    ${({ align }) =>
        align === 'left' &&
        css`
            border-right: none;
        `}

    ${({ align }) =>
        align === 'right' &&
        css`
            border-left: none;
            border-radius: 0 4px 4px 0;
        `}
`;
