import { mix } from '$ui/Flo/util';
import styled, { css } from 'styled-components';

export const FormGrid = styled.div<{ column?: number }>`
    ${({ column }) =>
        column &&
        css`
            grid-template-columns: repeat(${column}, 1fr);
        `};
    display: grid;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [3, 4] })};
`;
