import { Button } from '$ui/Flo/Button';
import React from 'react';

import { IconName } from '$ui/Flo/Icon';

interface Props {
    integration: string;
    onSignIn: () => void;
    signingIn?: boolean;
    text?: string;
}

export const SignInButton = ({
    integration,
    onSignIn,
    text,
    signingIn = false
}: Props) => {
    const verb = signingIn ? 'Signing' : 'Sign';
    const icon: IconName = signingIn ? 'Loader' : 'LogIn';

    return (
        <Button
            spin={signingIn}
            disabled={signingIn}
            onClick={onSignIn}
            border="x-small"
            rounded
            icon={icon}
        >
            {text ||`${verb} in to ${integration}`}
        </Button>
    );
};
