import { Check } from '$ui/Flo/Check';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

import MailOpen from '$resources/icons/mail-open.svg';
import { StarFilledIcon } from './InboxItem';

export interface BulkActionBarProps {
    checked: boolean;
    selected: number;
    unread: boolean;
    read: boolean;
    starred: boolean;
    unstarred: boolean;
    expanded: boolean;
    onSelectAll: () => void;
    onDeselect: () => void;
    onMarkAsRead: () => void;
    onMarkAsUnread: () => void;
    onMarkAsStarred: () => void;
    onMarkAsUnstarred: () => void;
    onRefresh: () => void;
}

export const BulkActionBar = (props: BulkActionBarProps) => {
    const {
        checked,
        selected,
        expanded,
        read,
        unread,
        starred,
        unstarred,
        onSelectAll,
        onDeselect,
        onMarkAsRead,
        onMarkAsUnread,
        onMarkAsStarred,
        onMarkAsUnstarred,
        onRefresh
    } = props;

    const handleCheck = () => {
        if (checked) {
            onDeselect();
        } else {
            onSelectAll();
        }
    };

    return (
        <Bar>
            <Actions expanded={expanded}>
                <DefaultAction>
                    <Deselect title={checked ? 'Deselect all' : 'Select all'}>
                        <Check
                            checked={checked}
                            onChange={() => handleCheck()}
                            theme="blue"
                            size="small"
                            icon="Minus"
                        />
                        {checked && <Text>{selected + ' selected'}</Text>}
                    </Deselect>

                    <Btn onClick={onRefresh} title="Refresh inbox">
                        <Icon icon="RotateCw" clickable size={2.5} />
                    </Btn>
                </DefaultAction>

                <Group>
                    {checked && (
                        <>
                            {unread && (
                                <Btn
                                    onClick={onMarkAsRead}
                                    title="Mark as read"
                                >
                                    <MarkAsReadIcon />
                                </Btn>
                            )}
                            {read && (
                                <Btn
                                    onClick={onMarkAsUnread}
                                    title="Mark as unread"
                                >
                                    <Icon icon="Mail" clickable size={2.5} />
                                </Btn>
                            )}
                            {starred && (
                                <Btn
                                    onClick={onMarkAsUnstarred}
                                    title="Unstar conversation"
                                >
                                    <Icon icon="Star" clickable size={2.5} />
                                </Btn>
                            )}
                            {unstarred && (
                                <Btn
                                    onClick={onMarkAsStarred}
                                    title="Star conversation"
                                >
                                    <StarFilledIcon size={2.5} />
                                </Btn>
                            )}
                        </>
                    )}
                </Group>
            </Actions>
        </Bar>
    );
};

const Bar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    ${mix.padding({ padding: [1, 1.5] })};
    ${mix.type({ level: 'body2' })};
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
`;

const Deselect = styled.div`
    display: flex;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;

const Actions = styled.div<{ expanded: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    ${mix.gap({ size: 2 })};
    width: 100%;
    ${({ expanded }) => !expanded && `justify-content: space-between;`}
`;

const DefaultAction = styled.div`
    display: flex;
    align-items: center;
    ${mix.gap({ size: 2 })};
`;

const Btn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    ${mix.gap({ size: 1 })}
`;

const Group = styled.div`
    display: flex;
    ${mix.gap({ size: 2 })};
    align-items: center;
    flex-direction: row;
`;

const MarkAsReadIcon = styled(MailOpen)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    & > path {
        fill: ${mix.palette({ hue: 'grey', shade: '5' })};
    }
`;

const Text = styled.span`
    ${mix.type({ level: 'body2' })};
    line-height: 1;
    white-space: nowrap;

    &:first-letter {
        text-transform: uppercase;
    }
`;
