import * as React from 'react';
import { LegacyV2ActionSchema } from '$types/legacy';
import { ActionSummary } from './ActionSummary';
import { Patient } from '$ui/Patient/Patient';
import styled from 'styled-components';
import { TimelineContainer } from '$ui/Inbox/TimelineContainer';
import { mix } from '$ui/Flo/util';
import { ActionRecord } from '$state/types';
import { Snoozed, SnoozeExtended, Success } from './States';
import { StaleWarning } from './StaleWarning';
import { newActionRemoveSingle } from '$state/concerns/actions';
import { useAppDispatch } from '$state';
import { SnoozeEndedWarning } from '$ui/Actions/SnoozeEndedWarning';
import { CountryCode } from '@/utils/phone';

interface Props {
    action: ActionRecord<LegacyV2ActionSchema>;
    onToggle: (state: boolean) => void;
    country: CountryCode;
}

export const Action = (props: Props) => {
    const dispatch = useAppDispatch();
    const { action: record, onToggle, country } = props;
    const { state, stale, isNew } = props.action;
    const ref = React.useRef<HTMLDivElement>(null);
    const open = state == 'open';
    const action = record.action;
    const isWaking = record.action.snooze?.state === 'waking';

    React.useEffect(() => {
        if (open && ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
            dispatch(newActionRemoveSingle(action.patient_id));
        }
    }, [open]);

    if (record.state == 'hidden') {
        return null;
    }

    if (record.state == 'complete') {
        return <Success onClose={() => onToggle(false)} />;
    }

    if (record.state == 'snoozed') {
        return <Snoozed onClose={() => onToggle(false)} />;
    }

    if (record.state == 'snoozeExtended') {
        return <SnoozeExtended onClose={() => onToggle(false)} />;
    }

    return (
        <Container ref={ref}>
            {stale && <StaleWarning />}
            {isWaking && !stale && record.action.snooze && (
                <SnoozeEndedWarning endsAt={record.action.snooze?.ends_at} />
            )}
            <ActionSummary
                action={action}
                onClick={() => onToggle(!open)}
                open={open}
                isNew={isNew}
                isStale={stale}
                isWaking={isWaking}
                country={country}
            />
            {open && (
                <Tray>
                    <PatientLayout>
                        <Patient id={action.patient_id} withoutSummary lite />
                    </PatientLayout>
                    <TimelineContainer patientId={action.patient_id} />
                </Tray>
            )}
        </Container>
    );
};

const PatientLayout = styled.div`
    border-right: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 ${mix.unit({ size: 62.5 })};
`;

const Tray = styled.div`
    display: flex;
    flex-direction: row;
    height: 75vh;
    overflow: hidden;
    background: white;
    border: 1px solid var(--gray-100);
    border-radius: 0 0 3px 3px;
    align-items: stretch;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
`;
