export type Pipeline = FlowMap & { nodes: NodeMap };

export interface Button {
    label: string;
}

export interface ButtonGroup {
    goal: Button;
    defer: Button;
    discard: Button;
}

export interface NextActionContext {
    note?: string;
    date?: string;
    nextAction: string;
    source?: string;
    endSnooze?: boolean;
}

export interface Flow {
    buttons: ButtonGroup;
    goal?: string;
    defer?: string;
    discard?: string;
}

export interface FlowMap {
    [stage: string]: Flow;
}

export enum FlowNodeType {
    choices = 'choices',
    note = 'note',
    schedule = 'schedule',
    confirm = 'confirm',
    finish = 'finish',
    addDays = 'addDays',
    changeStage = 'changeStage',
    source = 'source',
    estimate = 'estimate',
    snooze = 'snooze'
}

export interface Step {
    key: string;
    type: FlowNodeType;
    title: string;
    subtitle?: string;
    placeholder?: string;
}

export interface Nextable {
    next: string;
}

export const NextableNodes = [
    FlowNodeType.note,
    FlowNodeType.schedule,
    FlowNodeType.confirm,
    FlowNodeType.addDays,
    FlowNodeType.changeStage,
    FlowNodeType.source,
    FlowNodeType.estimate,
    FlowNodeType.snooze
];

// Nodes that are automatically executed as part of a pipeline,
// so when going back, a double back should be performed
export const DoubleBackNodes = [
    FlowNodeType.addDays,
    FlowNodeType.changeStage,
    FlowNodeType.source,
    FlowNodeType.snooze
];

export interface ChoicesNode extends Step {
    type: FlowNodeType.choices;
    mapping?: string; // Mapping is the key the step fills in the context, with the choices key as a value
    choices: {
        value: string; // If mapping is present, this is the value that's filled in the context, otherwise the option value
        label: string;
        target?: string; // The next node to move to when choice is taken
    }[];
}

export interface SourceNode extends Step, Nextable {
    type: FlowNodeType.source;
}

export interface SnoozeNode extends Step, Nextable {
    type: FlowNodeType.snooze;
}

export interface EstimateNode extends Step, Nextable {
    type: FlowNodeType.estimate;
    choices?: string[];
}

export interface NoteNode extends Step, Nextable {
    type: FlowNodeType.note;
}

export interface ScheduleNode extends Step, Nextable {
    type: FlowNodeType.schedule;
    defaultDays: number;
}

export interface ConfirmNode extends Step, Nextable {
    type: FlowNodeType.confirm;
}

export interface AddDaysNode extends Nextable {
    key: string;
    type: FlowNodeType.addDays;
    days: number;
}

export interface ChangeStageNode extends Nextable {
    key: string;
    type: FlowNodeType.changeStage;
    to: string;
}

// FinishNode is special, in that it indicates that the flow
// has finished, and the result should be sent
export interface FinishNode {
    key: string;
    type: FlowNodeType.finish;
}

// Each node is the data required to render a step
export type FlowNode =
    | ChoicesNode
    | NoteNode
    | ScheduleNode
    | ConfirmNode
    | AddDaysNode
    | ChangeStageNode
    | SnoozeNode
    | SourceNode
    | EstimateNode
    | FinishNode;

// Access to each node based on key
export interface NodeMap {
    [key: string]: FlowNode;
}

// FlowSrare is the state maintained by a step.
// The step fills the context with it's mapping, and then it gets added to history.
// This means we can also retain the state of each step, as it should be possible to apply
// the steps context to each step.
// At the end, we reduce the history into a single context by merging them on top of each other.
// We can then pass the context to a note generator, then the confirmation screen, and then send it to the API.
// The context can contain any value, which things in the pipeline can read (eg note generator), but it must
// finally be transformed to a `NextActionPayload` somehow.
export interface FlowState {
    node: string; // The key of the current node
    provisionalNext: string | null; // If the next node is known (eg a choice is selected, or the node only has next like on NoteNode) then the step can peek ahead. This allows it to determine what the buttons should show
    context: Partial<NextActionContext>;
    previous: null | FlowState;
}
