import { ClientDns, DkimStatus } from '$state/types/client';
import React from 'react';
import { Container, Subtitle } from './SendingEmail';
import { Text } from '$ui/Flo/Text';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Button } from '$ui/Flo/Button';
import { Modal } from '$ui/Flo/Modal/Modal';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Shadow } from '$ui/Flo/Shadow';
import { times } from '@/utils/array';

interface Props {
    practice: string;
    dns: ClientDns;
    loading: boolean;
    onVerify: () => void;
    onEmailInstructions: (email: string, instructions: string) => void;
}

export const EmailVerification = (props: Props) => {
    const { practice, dns, loading, onVerify, onEmailInstructions } = props;

    const [showModal, setShowModal] = React.useState(false);

    return (
        <Container>
            <Subtitle>
                Before Leadflo can send out email on behalf of your practice, we
                need to verify your domain via DNS.
            </Subtitle>
            <Text level="body2">
                Use your DNS provider to create these records.
            </Text>
            <GenerateDnsTable
                loading={loading}
                verified={dns.dkim.status === DkimStatus.SUCCESS}
                title="SPF"
                values={[
                    {
                        name: 'Type',
                        value: 'TXT'
                    },
                    {
                        name: 'Hostname',
                        value: dns.hostname
                    },
                    {
                        name: 'Value',
                        value: dns.spf,
                        code: true
                    }
                ]}
            />
            {!loading &&
                dns.dkim.records.length > 1 &&
                dns.dkim.records.map((record, index) => (
                    <GenerateDnsTable
                        key={index}
                        loading={loading}
                        verified={dns.dkim.status === DkimStatus.SUCCESS}
                        title="Domain Key"
                        values={[
                            {
                                name: 'Type',
                                value: 'CNAME'
                            },
                            {
                                name: 'Hostname',
                                value: record.name
                            },
                            {
                                name: 'Value',
                                value: record.value,
                                code: true
                            }
                        ]}
                    />
                ))}

            {loading &&
                times(3, (i) => (
                    <GenerateDnsTable
                        key={i}
                        loading={loading}
                        verified={dns.dkim.status === DkimStatus.SUCCESS}
                        title="Domain Key"
                        values={[
                            {
                                name: 'Type',
                                value: 'CNAME'
                            },
                            {
                                name: 'Hostname',
                                value: 'dkim' + i
                            },
                            {
                                name: 'Value',
                                value: 'dkim' + i,
                                code: true
                            }
                        ]}
                    />
                ))}

            <Buttons>
                <Button
                    mode="solid"
                    hue="primary"
                    icon="CheckCircle"
                    rounded
                    size="large"
                    onClick={onVerify}
                    disabled={loading}
                >
                    Verify settings
                </Button>

                <Button
                    mode="outline"
                    hue="primary"
                    icon="Mail"
                    rounded
                    size="large"
                    onClick={() => setShowModal(true)}
                >
                    Email Instructions
                </Button>
            </Buttons>
            {showModal && (
                <Modal
                    showCloseButton
                    onClose={() => setShowModal(false)}
                    closeOnOutsideClick
                    header="Email Instructions"
                    body={
                        <EmailInstructions
                            practice={practice}
                            dns={dns}
                            onSend={(email, instructions) => {
                                onEmailInstructions(email, instructions);
                                setShowModal(false);
                            }}
                        />
                    }
                />
            )}
        </Container>
    );
};

const GenerateDnsTable = ({
    loading,
    verified,
    title,
    values
}: {
    loading: boolean;
    verified: boolean;
    title: string;
    values: { name: string; value: string; code?: boolean }[];
}) => {
    return (
        <Dns>
            <Header>
                <Title>{title}</Title>
                <Verify verified={verified} loading={loading} />
            </Header>
            <Table>
                <colgroup>
                    <col style={{ width: '20%' }} span={1} />
                    <col style={{ width: '80%' }} span={1} />
                </colgroup>
                <Tbody>
                    {values.map((value, index) => (
                        <tr key={index}>
                            <td>
                                {loading && (
                                    <Shadow
                                        height={2}
                                        width={
                                            Math.floor(
                                                Math.random() * (100 - 20 + 1)
                                            ) + 20
                                        }
                                    />
                                )}
                                {!loading && (
                                    <Text level="body2" bold>
                                        {value.name}
                                    </Text>
                                )}
                            </td>
                            <td>
                                {loading && (
                                    <Shadow
                                        height={2}
                                        width={
                                            Math.floor(
                                                Math.random() * (100 - 20 + 1)
                                            ) + 20
                                        }
                                    />
                                )}
                                {!loading && value.code && (
                                    <Code>{value.value}</Code>
                                )}
                                {!loading && !value.code && value.value}
                            </td>
                        </tr>
                    ))}
                </Tbody>
            </Table>
        </Dns>
    );
};

interface InstructionsArgs {
    practice: string;
    dns: ClientDns;
}

const instructions = (args: InstructionsArgs) => {
    const { practice, dns } = args;

    const record = (type: string, name: string, value: string) => {
        return `
            <p>
                <b>Type: </b> ${type} <br />
                <b>Hostname: </b> ${name} <br />
                <b>Value: </b> ${value}
            </p>
        `;
    };

    return `
        <p>Hi,</p>

        <p>
            Our practice is now using Leadflo, a CRM tailor-made for
            dental practices.
        </p>

        <p>
            With Leadflo, we can send both manual and automated emails to our
            patients. For this to work, we need to amend our DNS records to verify we own our domain and show other
            email providers our emails are not spam.
        </p>

        <p>
            We first need to change our SPF record (or add one if it
            doesn't exist):
        </p>

        ${record('TXT', dns.hostname, dns.spf)}

        <p>And then we need to add each of these CNAME records:</p>

        ${dns.dkim.records
            .map((rec) => record('CNAME', rec.name, rec.value))
            .join('\n')}

        <p>
            Please let me know when this is done so that I can get our emails
            working with Leadflo.
        </p>

        <p>
            Thank you, <br>
            ${practice}
        </p>
    `;
};

interface EmailInstructionsProps {
    practice: string;
    dns: ClientDns;
    onSend: (email: string, instructions: string) => void;
}

const EmailInstructions = (props: EmailInstructionsProps) => {
    const { practice, dns, onSend } = props;

    const [email, setEmail] = React.useState<{
        email: string;
        isValid?: boolean;
    }>({
        email: '',
        isValid: false
    });

    const text = instructions({
        practice,
        dns
    });

    return (
        <Instructions>
            <FloatingTextField
                type="email"
                loading={false}
                label="To"
                value={email.email}
                placeholder={'Enter email address'}
                onChange={(text, isValid) => setEmail({ email: text, isValid })}
                required
            />

            <Body>
                <Text level="body2" bold>
                    Email content
                </Text>
                <InstructionsText
                    dangerouslySetInnerHTML={{
                        __html: text
                    }}
                ></InstructionsText>
            </Body>

            <Action>
                <Button
                    mode="solid"
                    hue="primary"
                    icon="Mail"
                    rounded
                    size="large"
                    onClick={() => email.isValid && onSend(email.email, text)}
                >
                    Send
                </Button>
            </Action>
        </Instructions>
    );
};

const Verify = ({
    verified,
    loading
}: {
    verified: boolean;
    loading: boolean;
}) => {
    if (loading) {
        return <Icon icon="RotateCw" size={2} hue="white" shade="10" spin />;
    }

    if (verified) {
        return (
            <VerifyContainer verified={verified}>
                <Text level="body2" bold dark={false}>
                    Verified
                </Text>
                <Icon
                    icon="Check"
                    size={1.5}
                    hue="green"
                    shade="7"
                    thickness={5}
                />
            </VerifyContainer>
        );
    }

    return (
        <VerifyContainer verified={verified}>
            <Text level="body2" bold dark={false}>
                Not valid
            </Text>
            <Icon icon="X" size={1.5} hue="red" shade="7" thickness={5} />
        </VerifyContainer>
    );
};

const VerifyContainer = styled.div<{ verified: boolean }>`
    display: flex;
    flex-direction: row;
    ${mix.gap({ size: 0.5 })};
    ${mix.type({ level: 'body2' })};
    align-items: center;

    & > div {
        color: ${({ verified }) =>
            verified
                ? mix.palette({ hue: 'green', shade: '7' })
                : mix.palette({ hue: 'red', shade: '7' })} !important;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${mix.bg({ hue: 'primary', shade: '2' })}
    ${mix.padding({ padding: [1, 2] })}
    border-radius: 6px 6px 0 0;
`;

const Dns = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.type({ level: 'body2' })};
`;

const Title = styled.div`
    color: ${mix.palette({ hue: 'white', shade: '8' })};
    ${mix.type({ level: 'body2', bold: true })};
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    ${mix.color({ profile: 'body' })}
`;

const Tbody = styled.tbody`
    & > tr > td {
        ${mix.padding({ padding: [1, 1.25] })};
    }

    & > tr:nth-child(even) {
        background: ${mix.palette({ hue: 'grey', shade: '9' })};
    }

    & > tr:last-child {
        border-radius: 0 0 6px 6px;
    }
`;

export const Code = styled.code<{ fontSize?: number }>`
    display: inline-block;
    width: 100%;
    word-break: break-all;
    padding: initial;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : `inherit`)};
    ${mix.color({ profile: 'body' })};
    background: none;
    border-radius: 0;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    ${mix.gap({ size: 2 })}
`;

const Instructions = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 3 })};
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
`;

const InstructionsText = styled.div`
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body' })};
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    ${mix.round({ rounded: true })};
    ${mix.padding({ padding: [1.5] })};
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
`;

const Action = styled.div`
    display: inline-block;
`;
