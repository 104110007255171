import * as React from 'react';
import { useAppSelector } from '$state';
import { selectChannel } from '$state/concerns/channels';
import { Channels } from '$state/types';
import { useHistory } from 'react-router';
import { AccordionHeader, AccordionSubItem, TemplateBar } from '$ui/TemplatesV2/Parts';
import { IconName } from '$ui/Flo/Icon';
import { Template } from '$state/types/templates';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface ConnectedAccordionProps {
    channel: Channels;
}

export const ConnectedAccordion = (props: ConnectedAccordionProps) => {
    const history = useHistory();
    const channel = useAppSelector(selectChannel(props.channel));

    const onTemplateCreated = () => {
        history.push(`/settings/templates/${props.channel}`);
    };

    return (
        <TemplateBar channel={channel} onClick={onTemplateCreated} />
    )
};

interface AccordionProps {
    icon: IconName;
    label: string;
    templates: Array<Template>;
}

export const Accordion = (props: AccordionProps) => {
    const {
        label,
        icon,
        templates,
    } = props;

    const [isOpen, setIsOpen] = React.useState<boolean>(true);

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const subNavAccordion =
        templates?.length > 0 && (
            templates.map((accordion) => {
                return (
                    <AccordionSubItem
                        {...accordion}
                        key={accordion.id}
                        icon={icon}
                        template={accordion}
                    />
                );
            })
        );

    return (
        <SubNav>
            <AccordionHeader
                name={label}
                isOpen={isOpen}
                onClick={onClick}
            />
            {isOpen && <SubMenu>{subNavAccordion}</SubMenu>}
        </SubNav>
    );
};


const SubNav = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex: 1 1 100%;
    background: white;
`;

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${mix.unit({ size: 0.5 })}
`;
