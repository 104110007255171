// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isEqual = <TType>(x: TType, y: TType): boolean => {
    if (Object.is(x, y)) return true;
    if (x instanceof Date && y instanceof Date) {
        return x.getTime() === y.getTime();
    }
    if (x instanceof RegExp && y instanceof RegExp) {
        return x.toString() === y.toString();
    }
    if (
        typeof x !== 'object' ||
        x === null ||
        typeof y !== 'object' ||
        y === null
    ) {
        return false;
    }
    const keysX = Reflect.ownKeys(x as unknown as object) as (keyof typeof x)[];
    const keysY = Reflect.ownKeys(y as unknown as object);
    if (keysX.length !== keysY.length) return false;
    for (let i = 0; i < keysX.length; i++) {
        if (!Reflect.has(y as unknown as object, keysX[i])) return false;
        if (!isEqual(x[keysX[i]], y[keysX[i]])) return false;
    }
    return true;
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: any): value is Function => {
    return !!(value && value.constructor && value.call && value.apply);
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isString = (value: unknown): value is string => {
    return typeof value === 'string' || value instanceof String;
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isInt = (value: unknown): value is number => {
    return isNumber(value) && value % 1 === 0;
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isNumber = (value: unknown): value is number => {
    try {
        return Number(value) === value;
    } catch {
        return false;
    }
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isSymbol = (value: any): value is symbol => {
    return !!value && value.constructor === Symbol;
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isDate = (value: unknown): value is Date => {
    return Object.prototype.toString.call(value) === '[object Date]';
};

// from: https://github.com/rayepps/radash/blob/master/src/typed.ts
export const isEmpty = (value: unknown) => {
    if (value === true || value === false) return true;
    if (value === null || value === undefined) return true;
    if (isNumber(value)) return value === 0;
    if (isDate(value)) return isNaN(value.getTime());
    if (isFunction(value)) return false;
    if (isSymbol(value)) return false;
    const length = (value as { length: unknown }).length;
    if (isNumber(length)) return length === 0;
    const size = (value as { size: unknown }).size;
    if (isNumber(size)) return size === 0;
    const keys = Object.keys(value as object).length;
    return keys === 0;
};
