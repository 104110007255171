import * as React from 'react';
import styled from 'styled-components';
import { CommTypeLeadFlo } from '$ui/Flo/Icon/Custom';

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    fill: grey;
`;

const Logo = styled(CommTypeLeadFlo)`
    animation-name: spin-clockwise;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 10rem;
    height: auto;
    margin-bottom: 2rem;
    opacity: 0.3;
`;

const Text = styled.span`
    font-weight: 300;
    font-size: 22px;
    color: grey;
`;

interface Props {
    text?: string;
}

/**
 * Covers an element in a loading spinner, which optionally takes line of text.
 */
export const Loading = (props: Props) => (
    <LoadingContainer>
        <Logo />
        {props.text && <Text>{props.text}</Text>}
    </LoadingContainer>
);
