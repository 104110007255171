import * as React from 'react';
import styled, { css } from 'styled-components';
import {
    applyFieldStyle,
    applyFieldWrapStyle,
    applyInputStyle,
    applyLabelStyle,
    FieldProps,
} from './Field';

interface Props extends FieldProps {
    label: string;
    value: string | null;
    valueChanged: (value: string) => void;
    options: string[];
}

export const SelectField = (props: Props) => {
    const { label, editable, value, valueChanged, options } = props;

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value;
        valueChanged(val);
    };

    const inOptions = value ? options.includes(value) : true;

    return (
        <Wrapper {...props}>
            <Label
                {...props}
                bold
                level="body2"
                profile="body"
                htmlFor={'select-' + label}
            >
                {label}
            </Label>
            <InputContainer {...props}>
                <Select
                    {...props}
                    data-cy={label}
                    level="body2"
                    profile="body"
                    disabled={!editable}
                    value={value || ''}
                    onChange={onChange}
                    id={'select-' + label}
                >
                    <option disabled value="" hidden>
                        Select an option
                    </option>

                    {!inOptions && (
                        <option disabled value={value || ''}>
                            {value}
                        </option>
                    )}

                    {options.map((val) => (
                        <option value={val} key={val}>
                            {val}
                        </option>
                    ))}
                </Select>
            </InputContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${applyFieldWrapStyle}
`;

const Label = styled.label`
    ${applyLabelStyle}
`;

const Select = styled.select`
    ${applyInputStyle}

    cursor: initial;
    opacity: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ${({ editable, theme }) =>
        editable &&
        css`
            background-image: url("data:image/svg+xml;utf8,<svg fill='${theme
                .color.grey
                .dark}' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 99%;
            background-position-y: 2px;
        `}
`;

const InputContainer = styled.div`
    ${applyFieldStyle}
    flex: 1 0 auto;
    position: relative;
`;
