import * as api from '$api/dentally/match';
import { MatchResponse } from '$api/dentally/match';
import { RootState } from '$state/store';
import {
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export interface GetParams {
    patientId: string;
    params: api.GetMatchParams;
}

export const getMatches = createAsyncThunk(
    'dentally/match/get',
    ({ params }: GetParams): Promise<MatchResponse> => api.getMatches(params)
);

export interface MatchStore {
    [patientId: string]: MatchState;
}

export interface MatchState {
    status: string;
    data?: MatchResponse['data'];
    total?: MatchResponse['total'];
}

const initialState: MatchStore = {};

export default createReducer(initialState, (builder) => {
    builder.addCase(getMatches.pending, (state, action) => {
        const { arg: getParams } = action.meta;
        state[getParams.patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(getMatches.fulfilled, (state, action) => {
        const { arg: getParams } = action.meta;
        state[getParams.patientId] = {
            status: 'loaded',
            data: action.payload['matches'],
            total: action.payload['total']
        };
    });

    builder.addCase(getMatches.rejected, (state, action) => {
        const { arg: getParams } = action.meta;
        const error = action.error as AxiosError;

        if (error.response?.status === 422) {
            state[getParams.patientId] = {
                status: 'invalidInput'
            };
        } else {
            state[getParams.patientId] = {
                status: 'error'
            };
        }
    });
});

const matchState = (state: RootState) => state.match;

export const selectMatch = createSelector(
    [(_, patientId: string) => patientId, matchState],
    (patientId, match: MatchStore) => {
        return match[patientId];
    }
);

export const selectMatchData = createSelector(
    [(_, patientId: string) => patientId, matchState],
    (patientId, match: MatchStore) => {
        return match[patientId]?.data;
    }
);

export const selectMatchStatus = createSelector(
    [(_, patientId: string) => patientId, matchState],
    (patientId, match: MatchStore) => {
        return match[patientId]?.status;
    }
);
