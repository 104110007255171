import { mix } from '$ui/Flo/util';
import styled from 'styled-components';

export const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: stretch;
    ${mix.padding({ padding: [1, 2] })}
    background: white;
    gap: ${mix.unit({ size: 2 })};
`;
