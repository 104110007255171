import * as React from 'react';
import styled from 'styled-components';
import { Time } from './Time';
import { mix } from '$ui/Flo/util';
import { Button } from '$ui/Flo/Button';
import { ReportQuery } from '$state/types';
import { parseISO } from 'date-fns';
import { Stage } from '$state/concerns/stages';
import { DropdownSelect } from './DropdownSelect';
import { Modal } from '$ui/Flo/Modal/Modal';
import { ConfirmExport } from '$ui/PatientFilterV2/ConfirmExport';

export type Filter = 'stages' | 'sources' | 'labels' | 'types';

interface Props {
    actionDisabled: boolean;
    types: string[];
    labels: string[];
    sources: string[];
    stages: Stage[];
    query: ReportQuery;
    update: (query: Partial<ReportQuery>) => void;
    runExport?: () => void;
    children?: React.ReactNode;
    excludeFilters?: Filter[];
}

export const Filter = (props: Props) => {
    const {
        types,
        labels,
        sources,
        stages,
        query,
        update,
        runExport,
        children,
        excludeFilters = []
    } = props;

    const [confirmExport, setConfirmExport] = React.useState(false);
    const closeDialog = () => setConfirmExport(false);
    const exportData = () => {
        runExport && runExport();
        closeDialog();
    }

    const unlessExcluded = (filter: Filter, node: () => React.ReactNode) => {
        if (excludeFilters.includes(filter)) {
            return null;
        }

        return node();
    };

    return (
        <Bar>
            <Selectors>
                <Time
                    onChange={(range) =>
                        update({
                            start: range.start.toISOString(),
                            end: range.end.toISOString()
                        })
                    }
                    start={parseISO(query.start)}
                    end={parseISO(query.end)}
                />
                {children}
            </Selectors>
            <Filters>
                {unlessExcluded('stages', () => (
                    <DropdownSelect
                        available={stages.map((stage) => ({
                            value: stage.key,
                            label: stage.name
                        }))}
                        selected={query.stages}
                        onSelect={(selected) => {
                            update({
                                stages: selected as string[]
                            });
                        }}
                        plural="stages"
                        icon="GitCommit"
                        multi
                    />
                ))}

                {unlessExcluded('sources', () => (
                    <DropdownSelect
                        available={sources}
                        selected={query.sources}
                        onSelect={(selected) =>
                            update({
                                sources: selected as string[]
                            })
                        }
                        plural="sources"
                        icon="Map"
                        multi
                    />
                ))}

                {unlessExcluded('labels', () => (
                    <DropdownSelect
                        available={labels}
                        selected={query.labels}
                        onSelect={(selected) =>
                            update({
                                labels: selected as string[]
                            })
                        }
                        plural="labels"
                        icon="Tag"
                        searchable
                        multi
                    />
                ))}

                {unlessExcluded('types', () => (
                    <DropdownSelect
                        available={types}
                        selected={query.types}
                        onSelect={(selected) =>
                            update({
                                types: selected as string[]
                            })
                        }
                        plural="types"
                        icon="PieChart"
                        multi
                    />
                ))}

                {runExport && (
                    <Button
                        id={'export'}
                        rounded
                        align="center"
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            setConfirmExport(true)
                        }}
                    >
                        Export
                    </Button>
                )}

                {confirmExport && (
                    <Modal closeOnOutsideClick onClose={closeDialog}>
                        <ConfirmExport
                            onClose={closeDialog}
                            onExport={() => exportData()}
                        />
                    </Modal>
                )}
            </Filters>
        </Bar>
    );
};

const Selectors = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;

    & > * {
        margin-left: ${mix.unit({ size: 2 })};
    }
`;

const Bar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
