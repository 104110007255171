/**
 * Produce an integer between lower and upper bounds, inclusive.
 */
export function randomInt(lower: number, upper: number): number {
    return Math.floor(Math.random() * (upper - lower + 1)) + lower;
}

/**
 * Clamps n between lower and upper bounds, inclusive.
 */
export function clamp(n: number, lower: number, upper: number): number {
    return Math.max(lower, Math.min(n, upper));
}
