import {
    ActionState,
    AuthState,
    Config,
    ConfigStatus,
    FeatureState
} from './types';
import { SourceState } from '$state/concerns/sources';

const KEY = 'leadflo__state';

interface CachedState {
    auth: AuthState;
    config: {
        refreshing: false;
        status: ConfigStatus;
        config?: Config;
    };
    features: FeatureState;
    actions: ActionState;
    sources: SourceState;
}

export const put = (state: CachedState) => {
    localStorage.setItem(KEY, JSON.stringify(state));
};

export const get = (): CachedState | Record<string, never> => {
    const value = localStorage.getItem(KEY);

    if (!value) {
        return {};
    }
    try {
        const state: CachedState = JSON.parse(value);
        return {
            auth: state.auth,
            features: state.features,
            config: {
                refreshing: false,
                status: state.config?.status,
                config: state.config?.config
            },
            actions: state.actions,
            sources: state.sources ?? { status: 'init' }
        };
    } catch (e) {
        console.warn('Could not load state from LocalStorage: ', e);
        return {};
    }
};

export const remove = () => {
    localStorage.removeItem(KEY);
};
