import * as React from 'react';
import styled from 'styled-components';
import {
    applyBg,
    applyPadding,
    applyTextColor,
    applyType,
    scale
} from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';

export interface Props {
    onClose: () => void;
    header?: React.ReactNode;
    body?: React.ReactNode;
}

export const SlideOutModal = (props: Props) => {
    const { onClose, body, header } = props;

    return (
        <ModalOverlay>
            <ModalContainer>
                {header && (
                    <ModalHeader>
                        <CloseIcon
                            onClick={onClose}
                            icon="X"
                            hue="white"
                            size={4}
                        />
                        {header}
                    </ModalHeader>
                )}
                {body && <ModalBody>{body}</ModalBody>}
            </ModalContainer>
        </ModalOverlay>
    );
};

export const ModalOverlay = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: 2 })};
    ${({ theme }) => applyBg({ theme, hue: 'grey', shade: '1', alpha: 0.2 })};
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
`;

export const ModalContainer = styled.div`
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 90%;
    pointer-events: auto;
    position: relative;
    z-index: 10000;
    max-width: fit-content;
`;

export const ModalBackground = styled.div`
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
`;

export const ModalHeader = styled.div`
    ${({ theme }) => applyBg({ theme, hue: 'primary', shade: '2' })};
    ${({ theme }) => applyPadding({ theme, padding: 2 })};
    position: relative;
`;

export const ModalBody = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: 2 })};
    ${({ theme }) => applyTextColor({ theme, profile: 'body' })};
    ${({ theme }) => applyType({ theme, level: 'body1' })};
    ${({ theme }) => applyBg({ theme, hue: 'grey', shade: '10' })};
    height: 100%;
    position: relative;
    overflow: auto;
`;

const CloseIcon = styled(Icon)`
    position: absolute;
    right: ${({ theme }) => scale(theme, 2)}px;

    :hover {
        cursor: pointer;
    }
`;
