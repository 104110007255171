import React from 'react';
import { times } from '@/utils/array';
import { Icon } from '$ui/Flo/Icon';
import styled, { css } from 'styled-components';
import { mix } from '../util';
import { dates } from '$utils';
import { addHours, set } from 'date-fns';
import { clamp } from '@/utils/num';
import {
    convertTo24Hour,
    disableMeridiemChange,
    disablePastHours,
    disablePastMinutes,
} from '@/utils/date';

interface Props {
    /**
     * 24-hour time value the time picker in RFC3339 format. Throws if invalid
     * format.
     */
    value: string;

    /**
     * Number of minutes between each possible option. If the increment is
     * greater than 60, only hours will be selectable. Highlights the nearest
     * time to the value e.g if interval is 5 and the given time is 16:01:00,
     * the component will select 4:00pm by default.
     */
    interval: number;

    /**
     * Callback for when the time value changes, in 24-hour format RFC3339
     * format
     *
     * @param value
     */
    onChange: (value: string) => void;

    /**
     * Limit time to specific date and time
     *
     * @param Date
     */
    min?: Date;
}

/**
 * The maximum number of rows to display in a column.
 */
const ROWS_AROUND = 2;

/**
 * Represents a window of rows to display in a column.
 */
type Window = [number, number];

/**
 * Returns the start and end index of the array containing the element at the
 * given index and the elements around it, up to the given number of elements.
 *
 * If the index is out of bounds, an error is thrown.
 *
 * If the index is within the first `around` elements, the window will start at
 * the element given by the index. If the index is within the last `around`, the
 * window will end at the element given by the index, with the first
 * `around * 2` elements before it.
 *
 * @param arr
 * @param index
 * @param around
 */
export const window = <T,>(arr: T[], index: number, around: number): Window => {
    if (index < 0 || index >= arr.length) {
        throw new Error(
            `Index out of bounds. Index: ${index}, length: ${arr.length}`,
        );
    }

    if (arr.length <= around * 2 + 1) {
        return [0, arr.length - 1];
    }

    if (index < around) {
        return [0, 2 * around];
    }

    if (index >= arr.length - around) {
        return [arr.length - 1 - around * 2, arr.length - 1];
    }

    const start = Math.max(0, index - around);
    const end = Math.min(arr.length - 1, index + around);

    return [start, end];
};

const formatTimePart = (value: number) => {
    return value.toString().padStart(2, '0');
};

/**
 * A simple time picker component that provides a user-friendly interface for
 * choosing times.
 *
 * Choosing seconds is unsupported since that's not a common use-case.
 *
 * Users can select hours and minutes by:
 *
 * 1. Scrolling and clicking
 * 2. Using the provided arrows
 * 3. Using the keyboard
 *
 * If the component can display all choices in one screen, scrolling will be
 * disabled e.g for the AM/PM selector or if the interval is 30 minutes.
 *
 * If the component does not have any choices to display within a column, that
 * column will be invisible.
 *
 * The maximum interval is 60 minutes. If the interval is greater than 60, an
 * error is thrown.
 *
 * When the component first renders, the time picker will be preselected with
 * the nearest time to the given value, with the possible values _around_ the
 * given value also visible.
 *
 * @param value
 * @param interval
 * @param onChange
 * @param min
 * @constructor
 */
export const TimePicker = ({ value, interval, onChange, min }: Props) => {
    if (interval > 60) {
        throw new Error(
            'Interval greater than 60. Interval must be less than 60. Got: ' +
                interval,
        );
    }

    if (!/^\d{2}:\d{2}:\d{2}$/.test(value)) {
        throw new Error(
            'Invalid time format. Must be of the format HH:MM:SS. Got: ' +
                value,
        );
    }

    const date = new Date(`1970-01-01T${value}`);
    const time = dates.time(date, 'hh mm aaa');
    const [hour, rawMinute, meridiem] = time.split(' ');

    const minute = formatTimePart(
        Math.round(parseInt(rawMinute, 10) / interval) * interval,
    );

    const hours = times(12, (i) => formatTimePart(i + 1));
    const minutes = times(60 / interval, (i) => formatTimePart(i * interval));

    const [hourStart, hourEnd] = window(
        hours,
        hours.indexOf(hour),
        ROWS_AROUND,
    );
    const hourRows = hours.slice(hourStart, hourEnd + 1);

    const [minuteStart, minuteEnd] = window(
        minutes,
        minutes.indexOf(minute),
        ROWS_AROUND,
    );
    const minuteRows = minutes.slice(minuteStart, minuteEnd + 1);

    const handleMeridiemChange = (newMeridiem: string) => {
        if (newMeridiem === meridiem) {
            return;
        }

        if (newMeridiem === 'am' && !disableMeridiemChange(min)) {
            onChange(dates.time(addHours(date, -12), 'HH:mm:ss'));
        }

        onChange(dates.time(addHours(date, 12), 'HH:mm:ss'));
    };

    const setHourIndex = (newIndex: number) => {
        if (
            !disablePastHours(
                min,
                convertTo24Hour(parseInt(hours[newIndex]), meridiem),
                interval,
            )
        ) {
            // things get funky around 12am/pm since 12am is 0 in 24-hour time
            if (hours[newIndex] === '12' && meridiem === 'pm') {
                onChange(
                    dates.militaryTime(
                        set(date, {
                            hours: 12,
                        }),
                    ),
                );
                return;
            }

            if (hours[newIndex] === '12' && meridiem === 'am') {
                onChange(
                    dates.militaryTime(
                        set(date, {
                            hours: 0,
                        }),
                    ),
                );
                return;
            }

            const newHour =
                parseInt(hours[newIndex], 10) + (meridiem === 'am' ? 0 : 12);

            onChange(
                dates.militaryTime(
                    set(date, {
                        hours: newHour,
                    }),
                ),
            );
        }
    };

    const handleHourChange = (direction: 'up' | 'down') => {
        const index = hours.indexOf(hour);

        const newIndex =
            direction === 'up'
                ? clamp(index - 1, 0, hours.length - 1)
                : clamp(index + 1, 0, hours.length - 1);

        setHourIndex(newIndex);
    };

    const setMinuteIndex = (newIndex: number) => {
        if (
            !disablePastMinutes(
                min,
                convertTo24Hour(parseInt(hour), meridiem),
                parseInt(minutes[newIndex]),
            )
        ) {
            onChange(
                dates.militaryTime(
                    set(date, {
                        minutes: parseInt(minutes[newIndex], 10),
                    }),
                ),
            );
        }
    };

    const handleMinuteChange = (direction: 'up' | 'down') => {
        const index = minutes.indexOf(minute);

        const newIndex =
            direction === 'up'
                ? clamp(index - 1, 0, minutes.length - 1)
                : clamp(index + 1, 0, minutes.length - 1);

        setMinuteIndex(newIndex);
    };

    const onHoursScrolled = (e: React.WheelEvent<HTMLDivElement>) => {
        handleHourChange(e.deltaY > 0 ? 'down' : 'up');
    };

    const onMinutesScrolled = (e: React.WheelEvent<HTMLDivElement>) => {
        handleMinuteChange(e.deltaY > 0 ? 'down' : 'up');
    };

    return (
        <Container>
            <Time>
                <Controls>
                    <Control onClick={() => handleHourChange('up')}>
                        <ControlIcon icon="ChevronUp" />
                    </Control>
                    <Control onClick={() => handleMinuteChange('up')}>
                        <ControlIcon icon="ChevronUp" />
                    </Control>
                </Controls>
                <TimeGrid>
                    <Col
                        onWheel={(e) => {
                            e.persist();
                            onHoursScrolled(e);
                        }}
                    >
                        {hourRows.map((h) => (
                            <Button
                                key={h}
                                selected={h === hour}
                                onClick={() => {
                                    const pastHoursDisabled = disablePastHours(
                                        min,
                                        convertTo24Hour(parseInt(h), meridiem),
                                        interval,
                                    );

                                    if (pastHoursDisabled) {
                                        return;
                                    }

                                    setHourIndex(hours.indexOf(h));
                                }}
                                disabled={disablePastHours(
                                    min,
                                    convertTo24Hour(parseInt(h), meridiem),
                                    interval,
                                )}
                            >
                                {h}
                            </Button>
                        ))}
                    </Col>

                    <Col>
                        <Cell>:</Cell>
                        <Cell>:</Cell>
                        <Cell>:</Cell>
                        <Cell>:</Cell>
                        <Cell>:</Cell>
                    </Col>

                    <Col
                        onWheel={(e) => {
                            e.persist();
                            onMinutesScrolled(e);
                        }}
                    >
                        {minuteRows.map((m) => (
                            <Button
                                key={m}
                                selected={m === minute}
                                onClick={() => {
                                    const pastMinutesDisabled =
                                        disablePastMinutes(
                                            min,
                                            convertTo24Hour(
                                                parseInt(hour),
                                                meridiem,
                                            ),
                                            parseInt(m),
                                        );

                                    if (pastMinutesDisabled) {
                                        return;
                                    }

                                    setMinuteIndex(minutes.indexOf(m));
                                }}
                                disabled={disablePastMinutes(
                                    min,
                                    convertTo24Hour(parseInt(hour), meridiem),
                                    parseInt(m),
                                )}
                            >
                                {m}
                            </Button>
                        ))}
                    </Col>
                </TimeGrid>
                <Controls>
                    <Control onClick={() => handleHourChange('down')}>
                        <ControlIcon icon="ChevronDown" />
                    </Control>
                    <Control onClick={() => handleMinuteChange('down')}>
                        <ControlIcon icon="ChevronDown" />
                    </Control>
                </Controls>
            </Time>

            <Meridiem>
                <Button
                    selected={meridiem === 'am'}
                    onClick={() => {
                        if (disableMeridiemChange(min)) {
                            return;
                        }

                        handleMeridiemChange('am');
                    }}
                    disabled={disableMeridiemChange(min)}
                >
                    AM
                </Button>
                <Button
                    selected={meridiem === 'pm'}
                    onClick={() => handleMeridiemChange('pm')}
                >
                    PM
                </Button>
            </Meridiem>
        </Container>
    );
};

const Container = styled.div`
    ${mix.width({ size: 21 })};
    ${mix.gap({ size: 2 })};
    ${mix.padding({ padding: 1 })};
    display: flex;
    border: 1px solid hsla(228, 20%, 95%, 1);
    border-radius: 6px;
    box-shadow: 0 8px 16px hsla(0, 0%, 0%, 0.08);
    background: white;
`;

const Time = styled.div`
    ${mix.gap({ size: 0.5 })};
    display: flex;
    flex-direction: column;
`;

const Controls = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TimeGrid = styled.div`
    display: flex;
`;

const Control = styled.div`
    ${mix.width({ size: 4 })};
    ${mix.height({ size: 2 })};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
`;

const ControlIcon = styled(Icon).attrs({ clickable: true })`
    user-select: none;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const Cell = styled.div`
    ${mix.width({ size: 4 })};
    ${mix.height({ size: 3.25 })};
    ${mix.type({ level: 'small' })};
    display: flex;
    align-items: center;
    justify-content: center;
    color: hsla(215, 18%, 34%, 1);
    border-radius: 4px;
    user-select: none;
`;

const Button = styled(Cell)<{ selected?: boolean; disabled?: boolean }>`
    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.05);
    }

    ${({ selected }) =>
        selected &&
        css`
            background: hsla(209, 100%, 91%, 1);
            color: hsla(218, 68%, 31%, 1);

            &:hover {
                cursor: pointer;
                background: hsla(209, 100%, 91%, 1);
            }
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            color: rgba(0, 0, 0, 0.05);

            &:hover {
                cursor: default;
            }
        `};
`;

const Meridiem = styled(Col)`
    ${mix.margin({ margin: [2.5, 0, 0, 0] })};
`;
