import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { MarkAsIcon, StarFilledIcon } from './InboxItem';

export interface TimelineActionBarProps {
    refreshing: boolean;
    read: boolean;
    starred: boolean;
    onMarkAsRead: () => void;
    onMarkAsUnread: () => void;
    onStarred: () => void;
    onUnstarred: () => void;
    onRefresh: () => void;
}

export const TimelineActionBar = (props: TimelineActionBarProps) => {
    const {
        refreshing,
        read,
        starred,
        onMarkAsRead,
        onMarkAsUnread,
        onStarred,
        onUnstarred,
        onRefresh
    } = props;

    const handleRefresh = () => {
        if (!refreshing) {
            onRefresh();
        }
    };

    return (
        <Bar>
            <Actions>
                <Btn onClick={handleRefresh} title="Refresh timeline">
                    <Icon
                        color="gray-600"
                        opacity={1}
                        icon="RotateCw"
                        clickable={!refreshing}
                        size={2}
                        spin={refreshing}
                    />
                    Refresh
                </Btn>
                <Btn
                    title={read ? 'Mark as unread' : 'Mark as read'}
                    onClick={() => read ? onMarkAsUnread() : onMarkAsRead()}
                >
                    <MarkAsIcon
                        color="gray-600"
                        read={read}
                        onMarkAsRead={onMarkAsRead}
                        onMarkAsUnread={onMarkAsUnread}
                    />
                </Btn>
                <Btn
                    title={
                        starred ? 'Unstar conversation' : 'Star conversation'
                    }
                    onClick={() => starred ? onUnstarred() : onStarred()}
                >
                    {starred ? (
                        <StarFilledIcon
                            clickable
                            size={2}
                            onClick={onUnstarred}
                        />
                    ) : (
                        <Icon
                            color="gray-600"
                            icon="Star"
                            clickable
                            size={2}
                            opacity={1}
                            onClick={onStarred}
                        />
                    )}
                </Btn>
            </Actions>
        </Bar>
    );
};

const Bar = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 9;
`;

const Actions = styled.div`
    width: 100%;
    ${mix.height({ size: 8 })};
    ${mix.padding({ padding: [2] })};
    ${mix.gap({ size: 0.5 })};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 99;
`;

const Btn = styled.div`
    display: flex;
    ${mix.height({ size: 4 })};
    ${mix.padding({ padding: [0.5, 1, 0.5, 1] })};
    justify-content: center;
    align-items: center;
    ${mix.gap({ size: 0.5 })};
    border-radius: ${mix.unit({ size: 4 })};
    background-color: var(--primary-100);
    color: var(--grey-600);
    cursor: pointer;
    font-family: Roboto, serif;
    font-size: 14px;
    line-height: 20px;
`;
