import {
    isPlaceholderParent,
    PlaceholderLeaf,
    PlaceholdersStore
} from '$state/types/contexts';
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import * as api from '$api/automations';
import { RootState } from '$state';

export const loadPlaceholders = createAsyncThunk('placeholders/load', () =>
    api.loadPlaceholders()
);

const initialState: PlaceholdersStore = {
    status: 'init',
    placeholders: []
};

export default createReducer(initialState, (builder) => {
    builder.addCase(loadPlaceholders.pending, (state) => {
        state.status = 'loading';
    });

    builder.addCase(loadPlaceholders.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.placeholders = action.payload;
    });
});

export const selectStatus = ({ placeholders }: RootState) =>
    placeholders.status;

export const selectPlaceholders = ({ placeholders }: RootState) =>
    placeholders.placeholders;

export const selectFlatPlaceholders = (
    rootState: RootState
): PlaceholderLeaf[] =>
    selectPlaceholders(rootState).flatMap((parent) => {
        if (isPlaceholderParent(parent)) {
            return parent.mergeTags.map((child) => ({
                name: `${parent.name} ${child.name}`,
                value: child.value
            }));
        }
        return parent;
    });

export const selectPatientPlaceholders = (
    rootState: RootState
): PlaceholderLeaf[] =>
    selectFlatPlaceholders(rootState).filter(({ value }) =>
        /^\{\{.*?\}\}$/.test(value)
    );
