import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const LoadingCircle = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={className}
             style={style}
             onClick={onClick}
             width={size}
             height={size}
             fill="white"
             xmlSpace="preserve"
             viewBox="0 0 44 44"
        >
            <g>
                <rect width="44" height="44" rx="22" fill="white" />
                <path d="M22 3C26.0124 3 29.9218 4.27024 33.1679 6.62868C36.414 8.98711 38.8302 12.3127 40.0701 16.1287"
                      stroke={color} strokeWidth="2" strokeLinecap="round" />
            </g>
        </svg>
    );
};
