import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileDocx = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.2H66.8L67.3 23.13C67.235 22.6294 67.0106 22.1631 66.66 21.8L45.85 1C45.7351 0.883071 45.6076 0.779114 45.47 0.69L45.37 0.63C45.2633 0.564082 45.1532 0.503993 45.04 0.45L44.86 0.4C44.7669 0.359574 44.6696 0.329398 44.57 0.31C44.4086 0.278042 44.2445 0.261302 44.08 0.26H10.68C10.017 0.26 9.38107 0.523392 8.91223 0.992233C8.44338 1.46107 8.17999 2.09696 8.17999 2.76V29.64C8.17999 30.303 8.44338 30.9389 8.91223 31.4078C9.38107 31.8766 10.017 32.14 10.68 32.14C11.343 32.14 11.9789 31.8766 12.4478 31.4078C12.9166 30.9389 13.18 30.303 13.18 29.64V5.29H41.58V23.43C41.5826 24.0905 41.8455 24.7234 42.3116 25.1914C42.7777 25.6593 43.4095 25.9247 44.07 25.93L62.33 26.03V74.44H13.18V70.57C13.18 69.907 12.9166 69.2711 12.4478 68.8022C11.9789 68.3334 11.343 68.07 10.68 68.07C10.017 68.07 9.38107 68.3334 8.91223 68.8022C8.44338 69.2711 8.17999 69.907 8.17999 70.57V76.94C8.17999 77.603 8.44338 78.2389 8.91223 78.7078C9.38107 79.1766 10.017 79.44 10.68 79.44H64.83C65.493 79.44 66.1289 79.1766 66.5978 78.7078C67.0666 78.2389 67.33 77.603 67.33 76.94V23.54C67.3297 23.426 67.3197 23.3123 67.3 23.2ZM46.58 20.94V8.83L58.76 21L46.58 20.94Z"/>
            <path d="M11.88 46.71C11.7036 46.3825 11.4111 46.1328 11.06 46.01C10.5521 45.8528 10.0214 45.7818 9.49 45.8H7.8V54.47H9.49C9.87112 54.5063 10.2556 54.4609 10.6177 54.3367C10.9799 54.2126 11.3114 54.0125 11.59 53.75C12.0445 53.1101 12.2891 52.3449 12.29 51.56C12.29 51.13 12.29 50.56 12.29 49.76C12.2953 49.1457 12.2686 48.5315 12.21 47.92C12.1708 47.5006 12.0592 47.0912 11.88 46.71Z" />
            <path d="M23 45.56C22.5909 45.5077 22.1754 45.5742 21.8031 45.7515C21.4308 45.9288 21.1173 46.2095 20.9 46.56C20.4253 47.6977 20.2199 48.9298 20.3 50.16C20.2168 51.3634 20.4189 52.5695 20.89 53.68C21.1116 54.0287 21.4272 54.3077 21.8006 54.4847C22.174 54.6616 22.5898 54.7293 23 54.68C23.4102 54.7293 23.826 54.6616 24.1994 54.4847C24.5728 54.3077 24.8884 54.0287 25.11 53.68C25.5769 52.5685 25.7756 51.3626 25.69 50.16C25.7661 48.9309 25.5645 47.7005 25.1 46.56C24.8827 46.2095 24.5692 45.9288 24.1969 45.7515C23.8246 45.5742 23.4091 45.5077 23 45.56Z" />
            <path d="M53.66 35.14H3.17C2.50696 35.14 1.87107 35.4034 1.40223 35.8722C0.93339 36.3411 0.669998 36.977 0.669998 37.64V62.57C0.669998 63.233 0.93339 63.8689 1.40223 64.3378C1.87107 64.8066 2.50696 65.07 3.17 65.07H53.66C54.323 65.07 54.9589 64.8066 55.4278 64.3378C55.8966 63.8689 56.16 63.233 56.16 62.57V37.64C56.16 36.977 55.8966 36.3411 55.4278 35.8722C54.9589 35.4034 54.323 35.14 53.66 35.14ZM15.36 53.07C15.2216 53.8811 14.9224 54.6563 14.48 55.35C14.0266 56.0388 13.3588 56.559 12.58 56.83C11.5841 57.1589 10.5384 57.3112 9.49 57.28H4.62V43H9.49C10.5041 42.9754 11.5159 43.1069 12.49 43.39C13.2486 43.6148 13.917 44.0732 14.4 44.7C14.8552 45.3325 15.169 46.0555 15.32 46.82C15.5026 47.8429 15.5863 48.881 15.57 49.92C15.5906 50.9741 15.5203 52.028 15.36 53.07ZM27.66 55.73C26.76 56.92 25.21 57.53 23.04 57.53C20.87 57.53 19.32 56.92 18.42 55.73C17.52 54.54 17.08 52.73 17.08 50.2C16.9413 48.2432 17.4092 46.2912 18.42 44.61C19.32 43.37 20.87 42.75 23.04 42.75C25.21 42.75 26.76 43.37 27.66 44.61C28.6659 46.2932 29.1335 48.2436 29 50.2C29 52.69 28.54 54.55 27.66 55.73ZM34.31 53.83C34.5556 54.1424 34.8786 54.3852 35.247 54.5343C35.6153 54.6834 36.0162 54.7336 36.41 54.68C37.6122 54.6728 38.8122 54.5759 40 54.39H40.19L40.25 57H40.11C38.798 57.3045 37.4568 57.4655 36.11 57.48C35.1366 57.5151 34.1656 57.3624 33.25 57.03C32.5404 56.7413 31.939 56.2377 31.53 55.59C31.1009 54.8935 30.8153 54.1184 30.69 53.31C30.5221 52.2451 30.4452 51.1679 30.46 50.09C30.46 47.37 30.85 45.44 31.63 44.36C32.41 43.28 33.88 42.7 35.96 42.7C37.35 42.7231 38.7326 42.9077 40.08 43.25H40.22L40.13 45.84H39.94C37.29 45.44 35.48 45.42 34.77 45.84C34.5581 46.0023 34.3807 46.2053 34.2485 46.4371C34.1163 46.669 34.0318 46.925 34 47.19C33.7781 48.3022 33.6875 49.4366 33.73 50.57C33.6546 51.6877 33.8538 52.8069 34.31 53.83ZM49.09 57.28V57.19L46.66 52.66L44.12 57.28H40.73L44.85 50.42L40.74 43H44.28V43.1L46.71 47.92L49.29 43H52.63L48.52 50.29L52.64 57.29L49.09 57.28Z"/>
        </svg>
    );
};
