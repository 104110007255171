import { RootState } from '$state';
import { ActionRecord, ActionState, ActionStatus } from '$state/types';
import { createSelector } from 'reselect';
import {
    ActionRoute,
    LegacyV2ActionSchema,
    ReferralsFilter,
    SnoozedStatus,
    SortOrder,
    TopNavItem
} from '$types';
import { array } from '$utils';

export const actionState = (state: RootState): ActionState => state.actions;

export const viewMeta = createSelector(
    [actionState, (_, view: string) => view],
    (state, view) => {
        return (
            state.views[view] || {
                state: ActionStatus.INITIAL_LOAD
            }
        );
    }
);

export const viewStages = createSelector([viewMeta], (view): string[] => {
    return view.categories.reduce(
        (stages: string[], cat) => stages.concat(cat.stages),
        []
    );
});

/**
 * Returns the sort order of actions due
 */
export const selectSort = (rootState: RootState): SortOrder =>
    actionState(rootState).sort;

export const selectReferralsFilter = (rootState: RootState): ReferralsFilter =>
    actionState(rootState).filters.referrals;

export const selectSnoozeFilter = (rootState: RootState): SnoozedStatus =>
    actionState(rootState).filters.snooze;

export type ActionCategory = {
    category: string;
    actions: ActionRecord<LegacyV2ActionSchema>[];
    displayTotal: number;
};

/**
 * Selects all actions with filters applied for the given view, returning an
 * array of actions grouped by category.
 */
export const actionsForView = createSelector(
    [
        actionState,
        viewMeta,
        selectSort,
        selectReferralsFilter,
        selectSnoozeFilter
    ],
    (
        actionState,
        view,
        sortOrder,
        referralsFilter,
        snoozeFilter
    ): ActionCategory[] => {
        return view.categories.map((cat) => {
            const actions = array.sortBy(
                cat.actions
                    .map(
                        (id) =>
                            actionState.actions[
                                id
                            ] as ActionRecord<LegacyV2ActionSchema>
                    )
                    .filter(
                        (record) =>
                            record.action.initialized &&
                            record.state != 'hidden'
                    )
                    .filter(
                        ({ action }) =>
                            referralsFilter === 'both' ||
                            (referralsFilter === 'referrals' &&
                                action.referred) ||
                            (referralsFilter === 'non-referrals' &&
                                !action.referred)
                    )
                    .filter(
                        ({ action }) =>
                            !snoozeFilter ||
                            snoozeFilter === 'showSnoozed' ||
                            (snoozeFilter === 'hideSnoozed' && action.snooze && action.snooze.state === 'waking') ||
                            (snoozeFilter === 'hideSnoozed' && !action.snooze)
                    ),
                (a) => a.action.date,
                sortOrder
            );

            return {
                category: cat.name,
                actions,
                displayTotal: cat.displayTotal
            };
        });
    }
);

export const updatedActionsInView = createSelector(
    [viewMeta, actionState],
    (view, { actions }) => {
        const actionIds = view.categories.reduce(
            (ids: string[], cat) => ids.concat(cat.actions),
            []
        );

        return actionIds.filter((id) => {
            const action = actions[id];
            return (
                action &&
                action.stale &&
                action.action.initialized &&
                !action.isWaking &&
                action.state !== 'hidden' &&
                action.state !== 'complete'
            );
        }).length;
    }
);

export const newActionsInView = createSelector(
    [viewMeta, actionState],
    (view, { actions }) => {
        const actionIds = view.categories.reduce(
            (ids: string[], cat) => ids.concat(cat.actions),
            []
        );

        return actionIds.filter((id) => {
            const action = actions[id];
            return (
                action &&
                action.stale &&
                !action.action.initialized &&
                !action.isWaking &&
                action.state !== 'hidden' &&
                action.state !== 'complete'
            );
        }).length;
    }
);

export const wakingActionsInView = createSelector(
    [viewMeta, actionState],
    (view, { actions }) => {
        const actionIds = view.categories.reduce(
            (ids: string[], cat) => ids.concat(cat.actions),
            []
        );

        return actionIds.filter((id) => {
            const action = actions[id];
            return (
                action &&
                action.isWaking &&
                action.state !== 'hidden' &&
                action.state !== 'complete'
            );
        }).length;
    }
)

export const countsForStages = ({ counts }: ActionState, stages: string[]) => {
    const result = {};
    stages.forEach((stage) => (result[stage] = counts[stage] || 0));
    return result;
};

/**
 * Returns the total of the counts in a list of stages
 */
export const totalCountForStages = (
    actions: ActionState,
    stages: string[]
): number => {
    const counts = countsForStages(actions, stages);
    let total = 0;
    for (const stage in counts) {
        total += counts[stage];
    }
    return total;
};

export const actionNavConfigs = createSelector([actionState], (actions) => {
    // Sections must be hardcoded as the section isn't an entity
    // within the store. Unlikely to ever need to be data driven in
    // this instance.
    const sections: { [view: string]: TopNavItem } = {
        Leads: {
            name: 'Leads',
            icon: 'CheckCircle',
            submenu: []
        },
        Consultations: {
            name: 'Consultations',
            icon: 'Calendar',
            submenu: []
        }
    };

    for (const viewName in actions.views) {
        const view = actions.views[viewName];
        const section = view.section;
        const stages = view.categories.reduce(
            (stages: string[], cat) => stages.concat(cat.stages),
            []
        );
        const count = totalCountForStages(actions, stages);

        sections[section].submenu!.push({
            name: viewName,
            destination: view.path,
            count: count
        });
    }

    return Object.values(sections);
});

/**
 * Returns routing config for actions due
 */
export const actionsRoutes = createSelector(
    [actionState],
    (state): ActionRoute[] => {
        return Object.entries(state.views)
            .map((view) => {
                return {
                    title: view[0],
                    path: view[1].path
                };
            })
            .reverse(); // Must be reversed as new leads needs to come last for
        // routing
    }
);
