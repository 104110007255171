import { css } from 'styled-components';
import {
    applyTextColor,
    applyType,
    palette,
    scale,
    applyBg
} from '$ui/Flo/util';
import { TextColorProps, ThemedProps, TypeProps } from '../types';

export interface FieldProps {
    disabled?: boolean;
    editable?: boolean;
    error?: string;
    padLeft?: number;
    labelPortion?: number;
    placeholder?: string;
}

export const applyInputStyle = ({
    dark,
    profile,
    theme,
    bold,
    level,
    editable,
    labelPortion
}: ThemedProps<TypeProps & TextColorProps & FieldProps>) => css`
    ${applyType({ theme, bold, level })}
    ${applyTextColor({ theme, dark, profile })}
    outline: none;
    border: none;
    background: none;
    display: inline-block;
    padding: 0;
    flex: 1 0 auto;

    ${!editable &&
    css`
        cursor: pointer;
    `}
    ${labelPortion &&
    css`
        width: ${100 - labelPortion}%;
    `}
    ::placeholder {
        color: hsl(0, 0%, 62.5%);
        font-style: italic;
    }
`;

export const applyLabelStyle = ({
    theme,
    bold,
    level,
    dark,
    profile,
    labelPortion
}: ThemedProps<FieldProps & TypeProps & TextColorProps>) => css`
    ${applyType({ theme, bold, level })}
    ${applyTextColor({ theme, dark, profile })}
    display: inline-block;
    margin-bottom: 0;

    ${labelPortion &&
    css`
        width: ${labelPortion}%;
    `}
`;

export const applyFieldStyle = ({
    editable,
    theme,
    padLeft,
    disabled = false
}: ThemedProps<FieldProps>) => css`
    display: flex;
    box-sizing: content-box;
    padding: ${scale(theme, 0.75)}px 0;
    padding-left: ${padLeft ? scale(theme, padLeft) : 0}px;

    ${editable &&
    css`
        ${applyBg({ theme, hue: 'grey', shade: '10', alpha: 0.5 })};
        border-radius: ${({ theme }) => scale(theme, 1)}px;
    `}

    ${disabled &&
    editable &&
    css`
        box-shadow: 0px 1px 0px 0px
            ${palette({ theme, hue: 'grey', shade: '6' })};
        background: ${palette({ theme, hue: 'grey', shade: '3' })};
        opacity: 0.5;
    `}
`;

export const applyFieldWrapStyle = ({
    theme,
    padLeft
}: ThemedProps<FieldProps>) => css`
    display: flex;
    align-items: center;
    box-sizing: content-box;
    margin-bottom: ${scale(theme, 1)}px;
    padding-left: ${padLeft ? scale(theme, padLeft) : 0}px;
`;

export const applyWrapperStyle = ({ theme }: ThemedProps<FieldProps>) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${scale(theme, 1)}px;
`;
