import * as React from 'react';
import { Icon } from '$ui/Flo/Icon';
import styled from 'styled-components';

interface Props {
    limitExtender: boolean;
    toggle: () => void;
}

export const Extender = (props: Props) => {
    const { limitExtender, toggle } = props;

    return <Container>
        <Icon
            size={3}
            icon={limitExtender ? 'CollapseUp' : 'CollapseDown'}
            hue="grey"
            opacity={1}
            clickable={true}
            onClick={toggle}
        />
    </Container>;
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
