import { openTemplate } from '$state/concerns/automations';
import { editorOpen } from '$state/queries/automations';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { Slideout } from '$ui/Shared/Slideout';
import * as React from 'react';
import { TemplateViewer } from './TemplateViewer';

export const ActionEditor = () => {
    const open = useAppSelector(editorOpen);
    const dispatch = useAppDispatch();

    const onClose = () =>
        dispatch(
            openTemplate({
                open: false,
                action: null
            })
        );

    return (
        <>
            <Slideout open={open} onClose={onClose}>
                {open && <TemplateViewer onClose={onClose} />}
            </Slideout>
        </>
    );
};
