import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface ConfirmExportProps {
    onClose: () => void;
    onExport: () => void;
}

export const ConfirmExport = ({
       onClose,
       onExport,
   }: ConfirmExportProps) => {
    return (
        <div>
            <Content>
                <TitleModal>Are you sure you want to export?</TitleModal>
                <MessageModal>
                    By confirming, the export process will start in the background.
                    You will receive an email with a link to download the exported data once the process is complete.
                    This may take a few minutes depending on the amount of data.
                </MessageModal>
            </Content>
            <ActionBar>
                <ButtonGroup>
                    <Button
                        rounded
                        mode={'outline'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        <CustomButton>Cancel</CustomButton>
                    </Button>
                    <Button
                        rounded
                        hue={'primary'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onExport();
                        }}
                    >
                        <CustomButton color={'white'}>Export</CustomButton>
                    </Button>
                </ButtonGroup>
            </ActionBar>
        </div>
    );
};

const ActionBar = styled.div`
    border-top: 1px solid var(--gray-200);
`;

const TitleModal = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-600);
`;

const MessageModal = styled.div`
    font-size: 16px;
    color: var(--gray-600);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1.5 })};
    justify-content: space-between;
    ${mix.padding({ padding: [1.5, 3] })};
`;

const Content = styled.div`
    ${mix.padding({ padding: [1.5] })};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [3] })};
`;

const CustomButton = styled.div<{ color?: string }>`
    width: 140px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : 'var(--gray-500)')};
`;
