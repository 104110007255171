import { WhatsApp } from '$types/patient';
import * as React from 'react';
import * as Parts from './Parts';

interface Props {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
    message: WhatsApp;
    retry?: (comm: WhatsApp) => void;
    attachmentsEnabled?: boolean;
}

export const WhatsAppMessageEvent = (props: Props) => {
    const { message } = props;

    return (
        <Parts.Container inbound={message.inbound}>
            {buildEvent(props)}
        </Parts.Container>
    );
};

const buildEvent = (props: Props) => {
    const { message, retry, attachmentsEnabled } = props;
    const color = message.inbound ? 'gray-100' : 'primary-500';
    const status: string = message.status.toLowerCase();
    const failed = status === 'failed';

    return (
        <>
            <Parts.Avatar
                type="WhatsApp"
                color={color}
                inbound={message.inbound}
                {...props}
            />
            <Parts.Event
                role="listitem"
                data-cy="comm"
                inbound={message.inbound}
            >
                <Parts.Content
                    type={message.type}
                    inbound={message.inbound}
                    color={color}
                >
                    {message.text_content}
                </Parts.Content>

                <Parts.Metadata
                    failed={failed}
                    retrying={message.retry}
                    retry={() => retry && retry(message)}
                    inbound={message.inbound}
                >
                    <Parts.Status
                        status={status}
                        date={message.datetime}
                        inbound={message.inbound}
                    />
                </Parts.Metadata>
                {attachmentsEnabled && message.attachments && (
                    <Parts.Attachments attachments={message.attachments} />
                )}
            </Parts.Event>
            {(failed || message.retry?.active) && <Parts.FailedIcon />}
        </>
    );
};
