import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CollapseUp = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 24 24"
        >
            <path d="M11.5211 7.1221C11.8196 6.9593 12.1803 6.9593 12.4788 7.1221L23.4788 13.1221C23.9636 13.3866 24.1423 13.994 23.8778 14.4789C23.6134 14.9637 23.0059 15.1424 22.5211 14.8779L11.9999 9.13909L1.4788 14.8779C0.993949 15.1424 0.386512 14.9637 0.122049 14.4789C-0.142413 13.994 0.0362445 13.3866 0.521093 13.1221L11.5211 7.1221Z" />
        </svg>
    );
};
