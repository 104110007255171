import React from 'react';
import {
    Container,
    Intro,
    LogoContainer,
    SignInButton
} from '$ui/Integrations/Elements';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    logo: React.ElementType;
    integration: string;
    introTitle?: string;
    introText?: string;
    signInButtonText?: string;
    onSignIn: () => void;
    signingIn?: boolean;
}

export const SignIn = ({
    logo: Logo,
    integration,
    onSignIn,
    introTitle,
    introText,
    signInButtonText,
    signingIn = false
}: Props) => {
    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <IntroWrapper>
                <Intro
                    title={introTitle ?? `Sign in to ${integration} to connect your account to Leadflo.`}
                >
                    {introText ?? 'This will open a new window.'}
                </Intro>
            </IntroWrapper>
            <SignInButton
                text={signInButtonText}
                integration={integration}
                onSignIn={onSignIn}
                signingIn={signingIn}
            />
        </Container>
    );
};

const IntroWrapper = styled.div`
    max-width: 700px;
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 4 })};
`;
