import React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { ColorToken } from '$ui/Flo/types';

interface Props {
    type: 'success' | 'error' | 'warning' | 'info';
    children: React.ReactNode;
}

export const Alert = ({ type, children }: Props) => {
    return (
        <Container type={type}>
            <Icon icon="Info" color={color(type)} />
            <div>{children}</div>
        </Container>
    );
};

type ContainerProps = Pick<Props, 'type'>;

const Container = styled.div<ContainerProps>`
    ${mix.type({ level: 'small' })};
    ${mix.padding({ padding: 1.5 })};
    ${mix.gap({ size: 1 })}
    align-items: center;
    border-radius: ${mix.unit({ size: 0.75 })};
    font-size: 14px;
    display: flex;
    line-height: 1;

    ${({ type }) => {
        return css`
            color: var(--${color(type)});
        `;
    }};

    ${({ type }) => {
        if (type === 'success') {
            return css`
                background-color: var(--success-050);
            `;
        }

        if (type === 'warning') {
            return css`
                background-color: var(--warning-050);
            `;
        }

        if (type === 'error') {
            return css`
                background-color: var(--error-050);
            `;
        }

        return css`
            background-color: var(--gray-100);
        `;
    }}
`;

const color = (type: Props['type']): ColorToken => {
    if (type === 'success') {
        return 'success-600';
    }

    if (type === 'warning') {
        return 'warning-600';
    }

    if (type === 'error') {
        return 'error-600';
    }

    return 'gray-600';
};
