import React from 'react';
import toast, { Toaster, resolveValue } from 'react-hot-toast';
import { mix } from '$ui/Flo/util';
import styled, { css } from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { ColorToken } from '$ui/Flo/types';

interface ToastArgs {
    id: string;
    message: React.JSX.Element | string;
    action?: {
        handler: () => void;
        text: string;
    };
}

export const success = ({ id, message, action }: ToastArgs) => {
    const data = (
        <>
            <IconHolder color="success-050">
                <Icon
                    icon="CheckCircle"
                    color="success-600"
                    opacity={1}
                    size={2}
                />
            </IconHolder>
            {message}
            {action && <Button onClick={action.handler}>{action.text}</Button>}
        </>
    );

    toast.success(data, { id });
};

export const error = ({ id, message, action }: ToastArgs) => {
    const data = (
        <>
            <IconHolder color="error-050">
                <Icon icon="XCircle" color="error-600" opacity={1} size={2} />
            </IconHolder>
            {message}
            {action && <Button onClick={action.handler}>{action.text}</Button>}
        </>
    );

    toast.error(data, { id });
};

export const info = ({ id, message, action }: ToastArgs) => {
    const data = (
        <>
            <IconHolder color="primary-050">
                <Icon icon="Info" color="primary-600" opacity={1} size={2} />
            </IconHolder>
            {message}
            {action && <Button onClick={action.handler}>{action.text}</Button>}
        </>
    );

    toast(data, { id });
};

export const Toasts = () => {
    return (
        <Toaster
            position="top-center"
            toastOptions={{
                duration: 3000,
            }}
        >
            {(t) => (
                <Container>
                    {t.icon}
                    {resolveValue(t.message, t)}
                </Container>
            )}
        </Toaster>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: ${mix.unit({ size: 7.5 })};
    border: 1px solid var(--gray-200);
    border-radius: ${mix.unit({ size: 1 })};
    ${mix.gap({ size: 1 })};
    ${mix.padding({ padding: 1.5 })};
    box-shadow: 0 2px 4px 0 #0000000a;
    font-family: Roboto, serif;
    font-weight: 400;
    font-size: ${mix.unit({ size: 1.75 })};
    color: var(--gray-600);
    background: white;
`;

const IconHolder = styled.div<{ color: ColorToken }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${mix.unit({ size: 4.5 })};
    width: ${mix.unit({ size: 4.5 })};
    border-radius: 50%;

    ${({ color }) => css`
        background-color: var(--${color});
    `}
`;

const Button = styled.button`
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    ${mix.height({ size: 4.5 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: white;
    background-color: var(--primary-500);
    gap: ${mix.unit({ size: 1 })};

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background-color: var(--primary-200);
    }

    &:hover:not([disabled]) {
        background-color: var(--primary-600);
    }
`;
