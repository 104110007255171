import React from 'react';
import styled from 'styled-components';
import { Source } from './Source';
import {
    addSource,
    deleteSource,
    editSource,
    listSources,
    selectSources,
    SourceState,
} from '$state/concerns/sources';
import { Button } from '$ui/Flo/Button';
import { mix } from '$ui/Flo/util';
import { range } from '@/utils/array';
import { useAppDispatch, useAppSelector } from '$state';
import { Icon } from '$ui/Flo/Icon';
import { v4 as uuid } from 'uuid';

export const ConnectedSourcesEditor = () => {
    const dispatch = useAppDispatch();
    const sources = useAppSelector(selectSources);

    React.useEffect(() => {
        if (sources.status === 'init') {
            dispatch(listSources());
        }
    }, []);

    const onRetry = () => {
        dispatch(listSources());
    };

    const onAdd = (source: string) => {
        dispatch(addSource(source));
    };

    const onEdit = (oldName: string, newName: string) => {
        dispatch(
            editSource({
                name: oldName,
                newName,
            }),
        );
    };

    const onDelete = (name: string) => {
        dispatch(deleteSource(name));
    };

    return (
        <SourceEditor
            onRetry={onRetry}
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={onDelete}
            {...sources}
        />
    );
};

interface SourceEditorProps {
    onRetry?: () => void;
    onAdd?: (source: string) => void;
    onEdit?: (oldName: string, newName: string) => void;
    onDelete?: (name: string) => void;
}

export const SourceEditor = (props: SourceState & SourceEditorProps) => {
    const { status, onRetry, onAdd, onEdit, onDelete } = props;

    const [sourcesToAdd, setSourcesToAdd] =
        React.useState<Array<string> | null>(null);

    const addDraftSource = () => {
        setSourcesToAdd((sources) => [...(sources ?? []), uuid()]);
    };

    const addSource = (source: string, id: string) => {
        onAdd?.(source);
        cancelAddSource(id);
    };

    const cancelAddSource = (id: string) => {
        setSourcesToAdd((sources) => {
            if (!sources) {
                return null;
            }

            return sources.filter((x) => x !== id);
        });
    };

    const [sourcesToEdit, setSourcesToEdit] =
        React.useState<Array<string> | null>(null);

    const editDraftSource = (name: string) => {
        setSourcesToEdit((sources) => [...(sources ?? []), name]);
    };

    const editSource = (oldName: string, newName: string) => {
        onEdit?.(oldName, newName);
        cancelEditSource(oldName);
    };

    const cancelEditSource = (name: string) => {
        setSourcesToEdit((sources) => {
            if (!sources) {
                return null;
            }

            return sources.filter((x) => x !== name);
        });
    };

    if (status === 'loading' || status === 'init') {
        return (
            <Container>
                <Cols>
                    {range(0, 11).map((i) => (
                        <Cell key={i}>
                            <Source loading />
                        </Cell>
                    ))}
                </Cols>

                <div>
                    <Button size="med-large" rounded disabled>
                        Add Source
                    </Button>
                </div>
            </Container>
        );
    }

    if (status === 'errored') {
        return (
            <ErrorContainer>
                <ErrorMessage>
                    <Icon icon="AlertCircle" size={2} hue="red" />
                    We couldn't load your lead sources right now. Please try
                    again.
                </ErrorMessage>

                <div>
                    <Button rounded hue="red" onClick={() => onRetry?.()}>
                        Try again
                    </Button>
                </div>
            </ErrorContainer>
        );
    }

    const { sources } = props;

    const existingSources = sources.map((source) => source.name);

    return (
        <>
            <Container>
                <Cols>
                    {sources.map((source) => (
                        <Cell key={'existing-source-' + source.name}>
                            <Source
                                custom={source.custom}
                                value={source.name}
                                count={source.count}
                                editing={sourcesToEdit?.includes(source.name)}
                                existing={existingSources}
                                onEdit={() => {
                                    editDraftSource(source.name);
                                }}
                                onSave={(name) => {
                                    editSource(source.name, name);
                                }}
                                onCancel={() => {
                                    cancelEditSource(source.name);
                                }}
                                onDelete={() => {
                                    onDelete?.(source.name);
                                }}
                            />
                        </Cell>
                    ))}

                    {sourcesToAdd?.map((id) => (
                        <Cell key={'new-source-' + id}>
                            <Source
                                custom={true}
                                value={''}
                                count={0}
                                editing={true}
                                existing={existingSources}
                                onSave={(source) => {
                                    addSource(source, id);
                                }}
                                onCancel={() => {
                                    cancelAddSource(id);
                                }}
                            />
                        </Cell>
                    ))}
                </Cols>
                <div>
                    <Button onClick={addDraftSource} size="med-large" rounded>
                        Add Source
                    </Button>
                </div>
            </Container>
        </>
    );
};

const ErrorContainer = styled.div`
    ${mix.type({ level: 'body2' })};
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 2 })};
`;

const ErrorMessage = styled.div`
    background: var(--error-200);
    color: var(--error-800);
    border-radius: 4px;
    ${mix.padding({ padding: 2 })};
    display: flex;
    align-items: center;
    line-height: 1;
    ${mix.gap({ size: 1 })};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 3 })};
`;

const Cols = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    ${mix.gap({ size: 1 })};
`;

const Cell = styled.div``;
