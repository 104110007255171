import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import { Item, TopLevelItem } from '$state/types/settings';
import { Icon } from '$ui/Flo/Icon';
import { Badge } from '$ui/Flo/Badge';

type OnClickFn = (section: string, subsection?: string) => void;

interface NavProps {
    section?: string;
    subsection?: string;
    onSectionClicked: OnClickFn;
    items: Array<TopLevelItem>;
}

const isItemSelected = (key: string | undefined, item: Item, index: number) =>
    key === item.key || (!key && index === 0);

/**
 * Requirements:
 *
 * * Render a list of top-level pages
 * * Add more padding-top to the first element
 * * Add more padding-bottom to the last element
 * * Highlight the selected top level page
 * * Renders subsections if they exist
 * * Highlights selected subsection if exists otherwise the first
 * * Passes up the section when clicking on an top-level item
 * * Passes up the section and subsection when clicking subsection item
 */
export const Nav = (props: NavProps) => {
    const { items, section, subsection, onSectionClicked } = props;

    const navItems = items.map((item, index) => {
        return (
            <NavItem
                key={item.key}
                item={item}
                onSectionClicked={onSectionClicked}
                subsection={subsection}
                selected={isItemSelected(section, item, index)}
            />
        );
    });

    return <div>{navItems}</div>;
};

interface NavItemProps {
    item: TopLevelItem;
    section?: string;
    subsection?: string;
    selected: boolean;
    onSectionClicked: OnClickFn;
}

const NavItem = (props: NavItemProps) => {
    const { item, subsection, selected, onSectionClicked } = props;

    return (
        <StyledNavItem
            selected={selected}
            onClick={() => onSectionClicked(item.key)}
        >
            <StyledNavItemHeader>
                <Icon
                    opacity={0.75}
                    hue={selected ? 'primary' : 'grey'}
                    icon={item.icon}
                />
                <StyledHeading
                    selected={selected}
                    level="subtitle1"
                    align="left"
                >
                    {item.name}
                </StyledHeading>
                {item.alert && <Badge alert />}
            </StyledNavItemHeader>
            <SubItemContainer
                selected={selected}
                subsection={subsection}
                onSectionClicked={onSectionClicked}
                item={item}
            />
        </StyledNavItem>
    );
};

interface SubItemContainerProps {
    selected: boolean;
    subsection?: string;
    onSectionClicked: OnClickFn;
    item: TopLevelItem;
}

const SubItemContainer = (props: SubItemContainerProps) => {
    const { selected, subsection, item, onSectionClicked } = props;

    if (!item.items) {
        return null;
    }

    const subitems = item.items.map((subitem, index) => {
        return (
            <SubItem
                key={subitem.key}
                subsection={subitem.key}
                name={subitem.name}
                selected={isItemSelected(subsection, subitem, index)}
                onClick={(subsection: string) => {
                    onSectionClicked(item.key, subsection);
                }}
            />
        );
    });

    return (
        <StyledSubItemContainer selected={selected}>
            <StyledSubItemContainerInner>
                {subitems}
            </StyledSubItemContainerInner>
        </StyledSubItemContainer>
    );
};

interface SubItemProps {
    subsection: string;
    name: string;
    selected: boolean;
    onClick: (subsection: string) => void;
}

const SubItem = (props: SubItemProps) => {
    const { subsection, name, selected, onClick } = props;

    const level: 'body2' | 'subtitle2' = selected ? 'subtitle2' : 'body2';

    return (
        <StyledSubItem
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(subsection);
            }}
        >
            <StyledHeading selected={selected} level={level} align="left">
                {name}
            </StyledHeading>
        </StyledSubItem>
    );
};

interface SelectedProp {
    selected: boolean;
}

const StyledNavItem = styled.div<SelectedProp>`
    &:hover {
        cursor: pointer;
    }

    ${({ selected }) =>
        selected &&
        css`
            ${mix.bg({ hue: 'grey', shade: '9' })};
        `};
`;

const StyledNavItemHeader = styled.div`
    ${mix.padding({ padding: 2 })};
    display: flex;
    align-items: center;
    ${mix.gap({ size: 1 })};
    border-bottom: 1px solid
        ${mix.alpha({ hue: 'grey', shade: '8', alpha: 0.5 })};
`;

const StyledHeading = styled(Heading)<SelectedProp>`
    flex-grow: 1;

    ${({ selected }) =>
        selected &&
        css`
            color: ${mix.palette({ hue: 'primary', shade: '6' })};
        `};
`;

const StyledSubItemContainer = styled.div<SelectedProp>`
    max-height: 0;
    overflow: hidden;
    transition: 0.5s max-height;

    ${({ selected }) =>
        selected &&
        css`
            max-height: 100vh;
            transition: 0.5s max-height;
            border-bottom: 1px solid
                ${mix.alpha({ hue: 'grey', shade: '8', alpha: 0.5 })};
        `}
`;

const StyledSubItemContainerInner = styled.div`
    ${mix.padding({ padding: [2, 5] })}
`;

const StyledSubItem = styled.div`
    ${mix.padding({ padding: [0.5, 0] })}
    &:hover {
        cursor: pointer;
    }
`;
