import React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Btn } from '$ui/Inbox/FilterBar';
import { DropdownSelect } from '$ui/PatientFilter/DropdownSelect';
import { Time } from '$ui/PatientFilter/Time';
import { epoch, isoDateTime } from '@/utils/date';
import { endOfToday, startOfToday, addDays } from 'date-fns';
import SortTop from '$resources/icons/sort-top.svg';
import SortDown from '$resources/icons/sort-down.svg';
import {
    ReferralsFilter as ReferralsFilterValue,
    SnoozedStatus as SnoozeFilterValue,
    SortOrder
} from '$types/app';
import { ActionsQuery } from '$state/types';

interface Props {
    sort: SortOrder;
    onSorted: () => void;
    referralsEnabled?: boolean;
    range?: { start: string; end: string };
    types: string[];
    sources: string[];
    labels: string[];
    query?: ActionsQuery;
    updateQuery: (query: Partial<ActionsQuery>) => void;
}

export const ToolbarV3 = (props: Props) => {
    const {
        query,
        sort,
        onSorted,
        referralsEnabled,
        range = {
            start: isoDateTime(epoch),
            end: isoDateTime(new Date())
        },
        types = [],
        sources = [],
        labels = [],
        updateQuery
    } = props;
    return (
        <Container>
            <Selectors>
                <Sort sort={sort} onClick={onSorted} />
                <Time
                    onChange={(selected) => {
                        updateQuery({
                            range: {
                                start: isoDateTime(selected.start),
                                end: isoDateTime(selected.end)
                            }
                        });
                    }}
                    start={new Date(range.start)}
                    end={new Date(range.end)}
                    withSorter={true}
                    iconHue="grey"
                    allowFuture={true}
                    label="Due & overdue"
                    ranges={[
                        {
                            label: 'Tomorrow',
                            range: () => ({
                                startDate: addDays(startOfToday(), 1),
                                endDate: addDays(endOfToday(), 1)
                            }),
                            isSelected: () => false
                        }
                    ]}
                />
            </Selectors>
            <Filters>
                <SnoozeToggle
                    snoozeFilter={
                        query?.includeSnoozed ? 'showSnoozed' : 'hideSnoozed'
                    }
                    onSnoozeFiltered={(value) => {
                        updateQuery({
                            includeSnoozed: value === 'showSnoozed'
                        });
                    }}
                />
                <DropdownSelect
                    available={sources}
                    selected={query?.sources ?? []}
                    onSelect={(selected) => {
                        updateQuery({
                            sources: selected as string[]
                        });
                    }}
                    plural="sources"
                    icon="Map"
                    multi
                    iconHue="grey"
                    dropdownAlign="right"
                />
                <DropdownSelect
                    available={labels}
                    selected={query?.labels ?? []}
                    onSelect={(selected) => {
                        updateQuery({
                            labels: selected as string[]
                        });
                    }}
                    plural="labels"
                    icon="Tag"
                    searchable
                    multi
                    iconHue="grey"
                    dropdownAlign="right"
                />
                <DropdownSelect
                    available={types}
                    selected={query?.types ?? []}
                    onSelect={(selected) => {
                        updateQuery({
                            types: selected as string[]
                        });
                    }}
                    plural="types"
                    icon="PieChart"
                    multi
                    iconHue="grey"
                    dropdownAlign="right"
                />

                {referralsEnabled && (
                    <ReferralsFilter
                        referralsFilter={query?.referrals ?? 'both'}
                        onReferralsFiltered={(value) => {
                            updateQuery({
                                referrals: value
                            });
                        }}
                    />
                )}
            </Filters>
        </Container>
    );
};

interface SortProps {
    onClick: () => void;
    sort: SortOrder;
    children?: React.ReactNode;
}

const Sort = ({ sort, onClick, children }: SortProps) => {
    return (
        <SortContainer onClick={onClick}>
            {sort === 'desc' ? <SortTopIcon /> : <SortDownIcon />}
            {children && <SortText>{children}</SortText>}
        </SortContainer>
    );
};

interface ReferralsFilterProps {
    referralsFilter: ReferralsFilterValue;
    onReferralsFiltered: (value: ReferralsFilterValue) => void;
}

const ReferralsFilter = (props: ReferralsFilterProps) => {
    const { referralsFilter, onReferralsFiltered } = props;

    const available = [
        { value: 'both', label: 'Referrals: Show all patients' },
        { value: 'referrals', label: 'Referrals: Show referrals only' },
        { value: 'non-referrals', label: 'Referrals: Show non-referrals only' }
    ];

    return (
        <DropdownSelect
            placeholder="Show/Hide Referrals"
            available={available}
            selected={referralsFilter}
            onSelect={(selected) => {
                onReferralsFiltered(selected as ReferralsFilterValue);
            }}
            plural="referrals"
            icon="Filter"
            multi={false}
            iconHue="grey"
            dropdownAlign="right"
        />
    );
};

interface SnoozeFilterProps {
    snoozeFilter: SnoozeFilterValue;
    onSnoozeFiltered: (value: SnoozeFilterValue) => void;
}

const SnoozeToggle = (props: SnoozeFilterProps) => {
    const { snoozeFilter, onSnoozeFiltered } = props;

    const handleSnoozedClick = () => {
        if (snoozeFilter === 'showSnoozed') {
            onSnoozeFiltered('hideSnoozed');
        } else {
            onSnoozeFiltered('showSnoozed');
        }
    };

    return (
        <Btn
            data-cy="snoozed"
            onClick={() => handleSnoozedClick()}
            active={snoozeFilter === 'showSnoozed'}
        >
            <Icon icon="Moon" size={2} clickable />
            Include snoozed
        </Btn>
    );
};

const Container = styled.div`
    background: white;
    ${mix.padding({ padding: [1, 2] })};
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '10' })};
    display: flex;
    align-items: stretch;
    justify-content: space-between;
`;

const SortText = styled.div`
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body' })};
    line-height: 1;
`;

const SortContainer = styled.div`
    background: var(--gray-100);
    ${mix.padding({ padding: [0, 1, 0, 1] })};
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 3px 0 0 3px;

    &:hover {
        cursor: pointer;
    }
`;

// New styles
const Selectors = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    gap: ${mix.unit({ size: 0.5 })};
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;

    & > * {
        margin-left: ${mix.unit({ size: 2 })};
    }
`;

const svgStyle = css`
    width: ${mix.unit({ size: 2.5 })};
    height: ${mix.unit({ size: 2.5 })};
    cursor: pointer !important;

    & > path {
        fill: ${mix.palette({ hue: 'grey', shade: '5' })};
    }
`;

const SortTopIcon = styled(SortTop)`
    ${svgStyle};
`;
const SortDownIcon = styled(SortDown)`
    ${svgStyle};
`;
