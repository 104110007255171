import React from 'react';
import { Frame } from '$ui/Integrations/Frame';
import { Failed, Manage } from '$ui/Integrations/Screens';
import DentallyLogo from '$resources/dentally.svg';
import { SyncState } from '$types/integrations';
import { manage, useWorkflow } from '$ui/Integrations/Workflows/workflows';

interface Props {
    auth: () => Promise<void>;
    sync: () => void;
    disconnect: () => void;
    onFinish: () => void;
    syncState: SyncState;
}

// TODO: Refactor this when introducing a new integration
export const DentallyManage = (props: Props) => {
    const { auth, sync, disconnect, onFinish, syncState } = props;

    const { matches, onSync, onSignIn } = useWorkflow({
        healthyState: 'healthy',
        workflow: manage,
        syncState,
        auth,
        sync,
    });

    return (
        <Frame
            integration="Dentally"
            mode="Manage"
            next
            back={false}
            last
            onExit={onFinish}
            onNext={() => null}
            onFinish={onFinish}
            onBack={() => null}
        >
            {matches('healthy') && (
                <Manage
                    logo={DentallyLogo}
                    integration="Dentally"
                    onDisconnect={disconnect}
                    onSync={onSync}
                    syncing={matches('healthy.syncing')}
                    instructions={`
                        You can now link LeadFlo patients to their Dentally
                        counterpart to sync the data. \n
                        When viewing a patient's timeline, along the left (for
                        actions view) or right (for inbox view) you will see a
                        little toolbar with Dentally Logo. \n
                        Clicking the logo will bring up the Dentally patients
                        that closest match to the LeadFlo Patient.\n
                        You then need to click the Link button to associate
                        these patients together. \n
                        Once done the Patient data will sync and you should be
                        able to see the information from Dentally on the
                        patients profile.
                    `}
                />
            )}

            {matches('failed') && (
                <Failed
                    integration="Dentally"
                    mode="sync"
                    onSignIn={onSignIn}
                    signingIn={matches('failed.signingIn')}
                />
            )}
        </Frame>
    );
};
