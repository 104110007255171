import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { slideInRight, slideOutLeft } from '$ui/Flo/animations';

interface SectionProps {
    title?: string;
    children: React.ReactNode;
    sectionMenu?: React.ReactNode | undefined;
    fullWidth?: boolean;
}

export const SectionV2 = ({
                            title,
                            children,
                            sectionMenu = undefined,
                            fullWidth = false
                        }: SectionProps) => {
    if (fullWidth) {
        return <FullWidthFrame>{children}</FullWidthFrame>;
    }

    if (sectionMenu) {
        return (
            <FrameWithNav>
                <SectionMenu>{sectionMenu}</SectionMenu>
                <Container>
                    {title && <Title>{title}</Title>}
                    <Content>{children}</Content>
                </Container>
            </FrameWithNav>
        );
    }

    return (
        <Frame>
            <DefaultContainer>
                {title && <Title>{title}</Title>}
                <Content>{children}</Content>
            </DefaultContainer>
        </Frame>
    );
};

const FullWidthFrame = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &.section-enter {
        animation: ${slideInRight} 0.3s forwards;
    }

    &.section-exit {
        animation: ${slideOutLeft} 0.3s forwards;
    }
`;

// for the purpose of animating in and out
const Frame = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &.section-enter {
        animation: ${slideInRight} 0.3s forwards;
    }

    &.section-exit {
        animation: ${slideOutLeft} 0.3s forwards;
    }
`;

const FrameWithNav = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.section-enter {
        animation: ${slideInRight} 0.3s forwards;
    }

    &.section-exit {
        animation: ${slideOutLeft} 0.3s forwards;
    }
`;

const Container = styled.div<{ $withMenu?: boolean }>`
    height: 80%;
    width: 100%;
    flex: 1 1 auto;
    ${mix.padding({ padding: 3 })};
`;

const DefaultContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${mix.unit({ size: 90 })};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 3 })};
    ${mix.padding({ padding: [0, 0, 6] })};
    height: 100%;
`;

const SectionMenu = styled.div`
    ${mix.margin({ margin: [0, 8, 0, 0] })};
    ${mix.padding({ padding: [0, 0, 0, 3] })};
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 0 1 auto;
    align-self: flex-start;
    align-items: stretch;
    background: #fff;
    border-bottom: 1px solid var(--gray-100);
    column-gap: 12px;
`;

const Title = styled(Heading).attrs(() => ({
    level: 'h3',
    align: 'left'
}))`
    ${mix.margin({ margin: [0, 0, 2] })};
    align-self: flex-start;
`;
