import * as React from 'react';
import { Button } from '../../Flo/Button';
import styled from 'styled-components';
import { mix, scale } from '../../Flo/util';
import { useAppSelector } from '$state/hooks';
import * as flows from '$state/queries/flows';

interface Props {
    stage: string | null;
    onClick: (subflow: string) => void;
    disabled?: boolean;
}

const ButtonGroup = styled.div`
    ${mix.padding({ padding: [0, 3] })}
    display: flex;
    ${mix.margin({ margin: [1, 0, 3, 0] })}
    flex-direction: column;

    min-width: ${({ theme }) => scale(theme, 25)}px;
    width: 100%;

    & > * {
        width: 100%;
        margin-bottom: ${({ theme }) => scale(theme, 1)}px;

        :last-child {
            margin-bottom: 0;
        }
    }
`;

export const Buttons = ({ stage, onClick, disabled }: Props) => {
    const flow = useAppSelector(flows.forStage(stage));
    const buttons = flow && flow.buttons;

    return (
        <>
            <ButtonGroup>
                {buttons && buttons.goal && (
                    <Button
                        id="flow-goal-btn"
                        disabled={disabled}
                        icon="Calendar"
                        onClick={() => onClick(flow['goal']!)}
                        align="center"
                        rounded
                        iconHue="white"
                    >
                        {buttons.goal.label}
                    </Button>
                )}
                {buttons && buttons.defer && (
                    <Button
                        id="flow-defer-btn"
                        disabled={disabled}
                        icon="RotateCw"
                        mode="outline"
                        onClick={() => onClick(flow['defer']!)}
                        align="center"
                        rounded
                    >
                        {buttons.defer.label}
                    </Button>
                )}
                {buttons && buttons.discard && (
                    <Button
                        id="flow-discard-btn"
                        disabled={disabled}
                        icon="Trash"
                        hue="red"
                        mode="outline"
                        onClick={() => onClick(flow['discard']!)}
                        align="center"
                        rounded
                    >
                        {buttons.discard.label}
                    </Button>
                )}
            </ButtonGroup>
        </>
    );
};
