import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Actions/Refresh/Icon';

interface Props {
    onClick: () => void;
    loading: boolean;
    countNew: number;
    countUpdated: number;
    countWaking: number
}

export const MultiAction = (props: Props) => {
    const { loading, onClick, countNew, countUpdated, countWaking } = props;

    return (
        <ButtonGroup>
            <RefreshButton>
                <Icon size={16} $spin={loading} />
            </RefreshButton>
            {countWaking > 0 &&
                <WakingButton
                    onClick={onClick}
                    key="refresh-button-waking"
                    data-qa="action-refresh-button-waking"
                >
                    {countWaking} snooze{countWaking > 1 ? 's' : ''} ending
                </WakingButton>
            }
            {countNew > 0 &&
                <NewButton
                    onClick={onClick}
                    key="refresh-button-new"
                    data-qa="action-refresh-button-new"
                >
                    {countNew} new action{countNew > 1 ? 's' : ''}
                </NewButton>
            }
            {countUpdated > 0 &&
                <UpdatedButton
                    onClick={onClick}
                    key="refresh-button-update"
                    data-qa="action-refresh-button-update"
                >
                    {countUpdated} updated action
                    {countUpdated > 1 ? 's' : ''}
                </UpdatedButton>
            }
        </ButtonGroup>
    );
};

const buttonStyle = css`
    ${mix.padding({ padding: [1.5] })};
    ${mix.height({ size: 4 })};

    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    color: #ffffff;
    font-size: 12px;
    line-height: 1;
`;

const RefreshButton = styled.button`
    background: #475467;
    color: #FFFFFF;
    border-radius: 500rem 0 0 500rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 0;
    }
`;

const NewButton = styled.button`
    background: var(--primary-500);
    ${buttonStyle}

    &:last-child {
        border-radius: 0 500rem 500rem 0;
    }
`;

const UpdatedButton = styled.button`
    background: var(--warning-500);
    ${buttonStyle}

    &:last-child {
        border-radius: 0 500rem 500rem 0;
    }
`;

const WakingButton = styled.button`
    background: var(--error-500);
    ${buttonStyle}

    &:last-child {
        border-radius: 0 500rem 500rem 0;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    gap: 1px;
`;
