import { api } from '$api/api';
import Pusher, { Channel, Options } from 'pusher-js';
import { assign, createActor, fromPromise, setup } from 'xstate5';
import routes from './routes';

export interface Message {
    identity: string;
    payload: unknown;
}

export interface MessageMap {
    [event: string]: (msg: Message) => void;
}

interface ClientArgs {
    clientId: number;
    enabled: boolean;
    key: string;
    cluster: string;
    hostname: string;
    port: string;
    secure: boolean;
    authEndpoint: string;
}

export function start(args: ClientArgs) {
    actor.send({
        type: 'start',
        args,
    });
}

export function stop() {
    actor.send({
        type: 'stop',
    });
}

function createClient(args: ClientArgs): Pusher | undefined {
    const {
        enabled,
        key,
        cluster,
        hostname,
        port,
        authEndpoint,
        secure = true,
    } = args;

    if (!enabled) {
        return;
    }

    const numericPort = parseInt(port);

    const options: Options = {
        cluster,
        wsHost: hostname,
        forceTLS: secure,
        channelAuthorization: {
            endpoint: authEndpoint,
            transport: 'ajax',
            customHandler: async (params, callback) => {
                try {
                    const { data } = await api.postForm<{
                        auth: string;
                    }>('/broadcasting/auth', {
                        socket_id: params.socketId,
                        channel_name: params.channelName,
                    });

                    callback(null, {
                        auth: data.auth,
                    });
                } catch (e) {
                    if (e instanceof Error) {
                        callback(e, null);
                    }
                    throw e;
                }
            },
        },
    };

    if (secure) {
        return new Pusher(key, {
            ...options,
            wssPort: numericPort,
        });
    }

    return new Pusher(key, {
        ...options,
        wsPort: numericPort,
    });
}

type ConnectionState = {
    channelName: string;
    client?: Pusher;
    channel?: Channel;
};

const socketsMachine = setup({
    types: {
        context: {} as {
            connection: ConnectionState;
        },

        events: {} as { type: 'start'; args: ClientArgs } | { type: 'stop' },
    },

    actors: {
        connect: fromPromise<ConnectionState, ClientArgs>(async ({ input }) => {
            const client = createClient(input);

            const channelName = `private-events.${input.clientId}`;
            const channel = client?.subscribe(channelName);

            Object.entries(routes).forEach((entry) => channel?.bind(...entry));

            return { channelName, client, channel };
        }),

        disconnect: fromPromise<ConnectionState, ConnectionState>(
            async ({ input }) => {
                const { client, channel } = input;

                Object.entries(routes).forEach((entry) =>
                    channel?.unbind(...entry),
                );

                client?.unsubscribe(input.channelName);

                return {
                    channelName: input.channelName,
                    client: undefined,
                    channel: undefined,
                };
            },
        ),
    },
}).createMachine({
    initial: 'idle',

    context: {
        connection: {
            channelName: '',
            client: undefined,
            channel: undefined,
        },
    },

    states: {
        idle: {
            on: {
                start: {
                    target: 'starting',
                },
            },
        },

        starting: {
            invoke: {
                id: 'connect',
                src: 'connect',
                input: ({ event }) => {
                    if (event.type !== 'start') {
                        throw new Error(
                            'Cannot invoke `connect` on event that is not' +
                                ' `start`. Event type is: ' +
                                event.type,
                        );
                    }

                    return event.args;
                },
                onDone: {
                    target: 'started',
                    actions: assign(({ event }) => {
                        return {
                            connection: {
                                channelName: event.output.channelName,
                                client: event.output.client,
                                channel: event.output.channel,
                            },
                        };
                    }),
                },
            },
        },

        started: {
            on: {
                stop: {
                    target: 'stopping',
                },
            },
        },

        stopping: {
            invoke: {
                id: 'disconnect',
                src: 'disconnect',
                input: ({ context }) => {
                    return context.connection;
                },
                onDone: {
                    target: 'idle',
                },
            },
        },
    },
});

const actor = createActor(socketsMachine);

actor.start();
