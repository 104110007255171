import { LoadingField } from '$ui/Flo/Field/LoadingField';
import * as React from 'react';
import { NameBlock } from './NameBlock';
import { Button } from '$ui/Flo/Button';
import {
    FieldsWrapper,
    LEFT_PADDING,
    NameBlockWrapper,
    PadLeft,
    PatientEditorContainer,
    Wrapper
} from './Attributes';

interface Props {
    lite?: boolean;
}

const fieldNames = (lite: boolean) => {
    const baseFields = [
        'First Name',
        'Last Name',
        'Type',
        'Lead Source',
        'Email',
        'Phone'
    ];
    return lite ? baseFields : [...baseFields, 'Marketing Consent'];
};

export const LoadingAttributes = ({ lite = false }: Props) => {
    const labelPortion = 33;

    const fields = fieldNames(lite).map((field) => {
        return (
            <LoadingField
                label={field}
                padLeft={LEFT_PADDING}
                labelPortion={labelPortion}
                rounded
                key={field}
            />
        );
    });

    return (
        <Wrapper gap={3}>
            <div>
                <NameBlockWrapper>
                    <NameBlock
                        state={{ name: '' }}
                        loading
                        dentallyState={{
                            state: 'loading'
                        }}
                    />
                </NameBlockWrapper>
            </div>

            <PatientEditorContainer>
                <div>
                    <FieldsWrapper padding={LEFT_PADDING}>
                        {fields}
                    </FieldsWrapper>
                </div>
                <PadLeft scalar={LEFT_PADDING}>
                    <Button
                        disabled
                        icon="Edit"
                        mode="outline"
                        hue="primary"
                        rounded
                        border="x-small"
                    >
                        Edit patient
                    </Button>
                </PadLeft>
            </PatientEditorContainer>
        </Wrapper>
    );
};
