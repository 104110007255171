import * as React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../Text';
import { palette, scale } from '../util';

interface Props {
    label: string;
    onSelect: () => void;
    selected?: boolean;
}

export const Option = (props: Props) => {
    const { label, onSelect, selected } = props;
    return (
        <Line onClick={() => onSelect()}>
            <Bullet selected={selected} />
            <Text level="body1" profile="body">
                {label}
            </Text>
        </Line>
    );
};

const Line = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Bullet = styled.span<{ selected?: boolean }>`
    border-radius: 999px;
    border: 2px solid
        ${({ theme }) => palette({ theme, hue: 'grey', shade: '6' })};
    background: ${({ theme }) => theme.color.lightest};
    width: ${({ theme }) => scale(theme, 3)}px;
    height: ${({ theme }) => scale(theme, 3)}px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-right: ${({ theme }) => scale(theme, 2)}px;

    &:after {
        content: '';
        position: absolute;
        border-radius: 999px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background: ${({ theme }) =>
            palette({ theme, hue: 'green', shade: '6' })};
        transition: all 100ms ease-out;
    }

    ${({ selected }) =>
        selected &&
        css`
            &:after {
                width: ${({ theme }) => scale(theme, 2)}px;
                height: ${({ theme }) => scale(theme, 2)}px;
            }
        `}

    &:hover:after {
        width: ${({ theme }) => scale(theme, 2)}px;
        height: ${({ theme }) => scale(theme, 2)}px;
    }
`;
