import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { dates } from '$utils';
import { Button } from '$ui/Flo/Button';
import { useAppDispatch } from '$state';
import { endSnooze, extendSnooze } from '$state/concerns/patient';
import { DropdownSelect } from './DropdownSelect';
import { SnoozeStatus } from '$state/types/snooze';
import { addMinutes, formatISO, roundToNearestMinutes } from 'date-fns';
import { Icon } from '$ui/Flo/Icon';
import { snoozeDate, SnoozeForm } from '$ui/Snooze';

interface Props {
    id: string;
    firstName: string;
    lastName: string;
    snooze: SnoozeStatus | null;
}

export const WakeUp = (props: Props) => {
    const { id, firstName, lastName, snooze } = props;

    const dispatch = useAppDispatch();

    const [formOpen, setFormOpen] = React.useState(false);

    const onEndSnooze = () => {
        dispatch(endSnooze(id));
    };

    const onSelected = (selected: number) => {
        if (selected > 0) {
            const endAt = roundToNearestMinutes(
                addMinutes(new Date(), selected),
                {
                    nearestTo: 15,
                },
            );
            dispatch(
                extendSnooze({
                    patientId: id,
                    endsAt: formatISO(endAt),
                    comment: snooze?.comment,
                }),
            );
        } else {
            setFormOpen(true);
        }
    };

    return (
        <Wrapper>
            <TitleWrapper>
                <Title data-testid="snooze-title">
                    {firstName} {lastName} was snoozed until{' '}
                    {dates.time(snooze?.ends_at)} {snoozeDate(snooze?.ends_at)}
                </Title>
                <Subtitle data-testid="snooze-subtitle">
                    Do you want to end their snooze?
                </Subtitle>
            </TitleWrapper>
            <CtaWrapper>
                {snooze?.comment && <Note>{snooze.comment}</Note>}
                <ActionsWrapper>
                    <Action>
                        <DropdownSelect onSelect={onSelected} />
                    </Action>
                    <Action>
                        <Button
                            id="snooze-ending-button"
                            mode="solid"
                            hue="red"
                            icon="Moon"
                            rounded
                            size="medium"
                            align="center"
                            onClick={onEndSnooze}
                        >
                            End snooze
                        </Button>
                    </Action>
                </ActionsWrapper>
            </CtaWrapper>
            <SnoozeFormContainer
                patientId={id}
                open={formOpen}
                onClose={() => setFormOpen(false)}
            />
        </Wrapper>
    );
};

interface SnoozeContainerProps {
    patientId: string;
    open: boolean;
    onClose: () => void;
}

const SnoozeFormContainer = (props: SnoozeContainerProps) => {
    const { patientId, open, onClose } = props;
    const dispatch = useAppDispatch();

    return (
        <>
            <FlowContainer open={open}>
                <CloseButtonContainer>
                    <CloseButton open={open} onClick={onClose}>
                        <Icon icon="X" clickable />
                        Close
                    </CloseButton>
                </CloseButtonContainer>
                <FlowModal>
                    {open && (
                        <div
                            css={`
                                ${mix.padding({ padding: 4 })};
                            `}
                        >
                            <SnoozeForm
                                onSnooze={(snooze) => {
                                    dispatch(
                                        extendSnooze({
                                            patientId,
                                            ...snooze,
                                        }),
                                    );
                                    onClose();
                                }}
                            />
                        </div>
                    )}
                </FlowModal>
            </FlowContainer>
        </>
    );
};

const Wrapper = styled.div`
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    ${mix.padding({ padding: 4 })};
    width: 100%;
    min-height: 200px;
    transform-origin: bottom center;
    position: absolute;
    bottom: 0;
    z-index: 101;
    background-color: #ffffff;
    display: flex;
    justify-items: center;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
`;

const TitleWrapper = styled.div`
    height: auto;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
`;

const Subtitle = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Roboto', sans-serif;
`;

const CtaWrapper = styled.div`
    margin-top: ${mix.unit({ size: 2 })};
`;

const Note = styled.div`
    border-radius: ${mix.unit({ size: 0.5 })};
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    min-height: 36px;
    text-align: center;
    background: var(--gray-100);
`;

const ActionsWrapper = styled.div`
    margin-top: ${mix.unit({ size: 1 })};
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    gap: ${mix.unit({ size: 1 })};
`;

const Action = styled.div`
    width: 100%;
    display: grid;
`;

interface FlowProps {
    open: boolean;
}

const FlowContainer = styled.div<FlowProps>`
    transform: scale(0);
    transition: transform 0.25s;
    min-height: 200px;
    transform-origin: bottom center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3001;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${({ open }) =>
        open &&
        css`
            transform: scale(1);
            transition: transform 0.25s;
        `};
`;

const FlowModal = styled.div`
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    ${mix.padding({ padding: 0.5 })};
`;

const CloseButton = styled.div<FlowProps>`
    ${mix.padding({ padding: [1, 2] })};
    background: white;
    color: var(--gray-600);
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;
    ${mix.gap({ size: 0.5 })};
    border-radius: ${mix.unit({ size: 0.5 })};
    z-index: 1000;

    &:hover {
        cursor: pointer;
    }
`;
