import { Icon, IconName } from '$ui/Flo/Icon';
import { alpha, mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from '$ui/Flo/Tooltip';
import { useHover } from '@/utils/hooks';
import { Hue, Shade } from '$ui/Flo/types';

interface MetadataProps {
    type?: string;
    icon?: IconName;
    children?: React.ReactNode;
    tooltip?: string | null;
    hue?: Hue;
    shade?: Shade;
    opacity?: number;
    failed?: boolean;
    retrying?: { active: boolean; count: number };
    retry?: () => void;
    inbound?: boolean;
}

export const Metadata = ({
    icon,
    type,
    retrying,
    children = null,
    tooltip = null,
    hue = 'black',
    shade = '5',
    opacity = 0.65,
    failed = false,
    retry = () => null,
    inbound = false,
}: MetadataProps) => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);
    const [disableRetrying, setDisableRetrying] = React.useState(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    const hasTooltip = !!tooltip;

    const retryCommunication = () => {
        if (retrying && !retrying?.active && !disableRetrying) {
            retry?.();

            // Check if next sending should be disabled for 30 seconds.
            // After 3 attempts disable retrying for 30 seconds.
            if ((retrying!.count + 1) % 3 === 0) {
                setDisableRetrying(true);

                setTimeout(() => {
                    setDisableRetrying(false);
                }, 30_000);
            }
        }
    };

    return (
        <Container reverse={inbound}>
            <Type
                ref={hoverRef}
                hue={hue}
                shade={shade}
                opacity={opacity}
                hasTooltip={hasTooltip}
                hover={hover}
            >
                {tooltip && (
                    <Tooltip
                        align="center"
                        shade={'1'}
                        size="x-small"
                        open={hover}
                    >
                        {tooltip}
                    </Tooltip>
                )}
                {type && (
                    <TypeIcon
                        icon={icon ?? defaultIcon(type)}
                        hue={hue}
                        shade={shade}
                        opacity={opacity}
                        size={1.5}
                    />
                )}
                {type}
                {(failed || retrying?.active) && (
                    <Failed
                        disabled={disableRetrying}
                        onClick={() => retryCommunication()}
                    >
                        <TypeIcon
                            icon="RotateCw"
                            color={disableRetrying ? 'error-200' : 'error-500'}
                            opacity={1}
                            size={1.5}
                            spin={retrying?.active}
                        />
                        Send again
                    </Failed>
                )}
            </Type>
            {children}
        </Container>
    );
};

/**
 * This allows us to define default icons for the type, whilst allowing
 * the icon to be overridden where it makes more sense (e.g BusinessEvents).
 *
 * @param {string} type
 * @return {IconName}
 */
const defaultIcon = (type: string): IconName => {
    switch (type) {
        case 'WhatsApp':
            return 'WhatsApp';
        case 'SMS':
            return 'Smartphone';
        case 'Note':
            return 'FileText';
        default:
            return 'Mail';
    }
};

const Container = styled.div<{ reverse: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${mix.unit({ size: 1 })};

    ${({ reverse }) =>
        reverse &&
        css`
            flex-direction: row-reverse;
        `}
`;

interface TypeProps {
    hue: Hue;
    shade: Shade;
    opacity?: number;
    hasTooltip: boolean;
    hover: boolean;
}

const Type = styled.div<TypeProps>`
    ${mix.type({ level: 'small' })};
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 0.5 })};
    line-height: 1;
    position: relative;

    ${({ hover, hasTooltip }) => {
        if (hover && hasTooltip) {
            return css`
                text-decoration: underline;
            `;
        }
        return css`
            text-decoration: none;
        `;
    }};

    ${({ theme, hue, shade, opacity }) => {
        return css`
            color: ${alpha({ theme, hue, shade, alpha: opacity })};
        `;
    }};
`;

const TypeIcon = styled(Icon)`
    stroke-width: 1px;
`;

const Failed = styled.div<{ disabled: boolean }>`
    color: var(${({ disabled }) => (disabled ? '--error-200' : '--error-500')});
    display: flex;
    align-items: center;
    line-height: 1;
    gap: ${mix.unit({ size: 0.5 })};
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
`;
