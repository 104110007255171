import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Template } from '$state/types/templates';
import { ChannelConfig, ChannelPayload, ReplyState } from '$state/types';
import { SelectTemplate } from '$ui/ReplyBar/Templates/SelectTemplate';
import { Preview } from '$ui/ReplyBar/Templates/Preview';
import { TemplateContext } from '$state/types/contexts';
import { ListTemplate } from '$ui/ReplyBar/Templates/ListTemplate';
import { array, fn } from '$utils';

interface Props {
    channel: ChannelConfig,
    templates: Template[] | undefined;
    context?: TemplateContext;
    expended?: boolean;
    onInsert: (state: Partial<ReplyState>) => void;
    onSend: (template: Template, message: string) => void;
    isStalledConversation: boolean;
}

export const BrowseTemplates = (props: Props) => {
    const {
        channel,
        templates = [],
        context,
        expended,
        onInsert,
        onSend,
        isStalledConversation
    } = props;

    const [template, setTemplate] = React.useState<Template|null>(null);

    const insertPreview = (message: ChannelPayload) => {
        onInsert({
            fields: message
        });
    };

    const sortedTemplates = array.sortBy(templates, fn.prop<Template>('status'));

    const isApproved = (template: Template) => template.status && template.status.toUpperCase() === 'APPROVED';

    return <Container expended={expended}>
        <PreviewTemplate isPreviewing={!!template}>
            {!template && <SelectTemplate />}
            {template &&
                <Preview
                    template={template}
                    context={context}
                    onInsert={insertPreview}
                    onSend={onSend}
                    isStalledConversation={isStalledConversation}
                />
            }
        </PreviewTemplate>
        <ListTemplates>
            {sortedTemplates.map(template =>
                <ListTemplate
                    key={template.id}
                    channel={channel}
                    template={template}
                    setTemplate={setTemplate}
                    disabled={isStalledConversation && !isApproved(template)}
                />
            )}
        </ListTemplates>
    </Container>;
};

interface ExtendedProps {
    expended?: boolean;
}

const Container = styled.div<ExtendedProps>`
    width: 100%;
    height: 132px;
    transition: height 0.25s;
    transform-origin: bottom left;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: white;
    color: var(--gray-600);
    border-left: 1px solid var(--gray-100);

    ${({ expended }) =>
        expended &&
        css`
            height: 320px;
            transition: height 0.25s;
        `
    }
`;

interface PreviewTemplate {
    isPreviewing: boolean
}

const PreviewTemplate = styled.div<PreviewTemplate>`
    height: ${({ isPreviewing }) => isPreviewing ? '95%' : '100%'};
    flex: 1;
    overflow-y: scroll;
`;

const ListTemplates = styled.div`
    height: 100%;
    width: 200px;
    ${mix.padding({ padding: 0.5 })};
    overflow-x: hidden;
    overflow-y: scroll;
    border-left: 1px solid var(--gray-100);
`;

