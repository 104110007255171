import * as React from 'react';
import styled from 'styled-components';
import { Shadow as FloShadow } from '$ui/Flo/Shadow';
import { mix } from '$ui/Flo/util';

export const Shadow = () => {
    return (
        <Block>
            <FloShadow rounded shade="8" width={100} height={7} />
        </Block>
    );
};

const Block = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
`;
