import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { closePatient } from '$state/concerns/patient';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '$state';
import { ChannelConfig } from '$state/types';

interface Props {
    channel: ChannelConfig
    expended?: boolean;
}

export const NoTemplates = (props: Props) => {
    const { channel, expended } = props;

    const history = useHistory();
    const dispatch = useAppDispatch();

    const goToTemplates = () => {
        history.push(`/settings/templates/${channel.channel}`);
        dispatch(closePatient());
    };

    return <Container expended={expended}>
        <Icon icon="Layers" size={3} />
        <Message>You have no templates</Message>
        <Button onClick={goToTemplates}>
            <Icon icon="Plus" size={2} hue="white" opacity={1} clickable={true} />
            Create a template
        </Button>
    </Container>;
};

interface ContainerProps {
    expended?: boolean;
}

const Container = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    height: 132px;
    transition: height 0.25s;
    transform-origin: bottom left;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${mix.unit({ size: 1.5 })};
    background-color: var(--gray-050);
    color: var(--gray-600);

    ${({ expended }) =>
        expended &&
        css`
            height: 320px;
            transition: height 0.25s;
        `
    }
`;

const Message = styled.div`
    color: var(--gray-600);
    font-size: 14px;
    font-family: Roboto, sans-serif;
`;

const Button = styled.button`
    ${mix.padding({ padding: [1, 1.5, 1, 1.5] })};
    background-color: var(--primary-500);
    font-size: 12px;
    line-height: 16px;
    color: white;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    gap: ${mix.unit({ size: 1 })};
    display: flex;
    justify-content: center;
    align-items: center;
`;

