import { Icon, IconName } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { SearchResult, SearchState } from '$state/types';
import * as patients from '$state/concerns/patient';
import { useAppDispatch, useAppSelector, useDebounce } from '$state/hooks';
import { phone } from '$utils';

export interface SearchProps {
    state: SearchState;
    results?: SearchResult[];
    onQueryEntered: (query: string) => void;
    onResultClicked: (patientId: string) => void;
    onCancel: () => void;
}

type ResultProps = SearchResult & { onClick: () => void };

const Result = ({ name, email, phone: phoneNumber, onClick }: ResultProps) => {
    return (
        <ResultContainer data-cy="search-bar-result" onClick={onClick}>
            <ResultHeader>
                <ResultIcon icon="User" size={2} hue="white" />
                {name}
            </ResultHeader>
            <ResultLine>
                <ResultIcon icon="AtSign" size={2} hue="white" />
                {email}
            </ResultLine>
            <ResultLine>
                <ResultIcon icon="Phone" size={2} hue="white" />
                {phone.format(phoneNumber)}
            </ResultLine>
        </ResultContainer>
    );
};

const Results = ({ state, results = [], onResultClicked }: SearchProps) => {
    switch (state) {
        case 'searching':
            return (
                <ResultsList>
                    <EmptyResultContainer>
                        <ResultIcon icon="Loader" size={2} spin hue="white" />
                        Searching&hellip;
                    </EmptyResultContainer>
                </ResultsList>
            );

        case 'found':
            return (
                <ResultsList>
                    {results.map((result: SearchResult) => (
                        <Result
                            key={result.id}
                            onClick={() => onResultClicked(result.id)}
                            {...result}
                        />
                    ))}
                </ResultsList>
            );

        case 'not_found':
            return (
                <ResultsList>
                    <EmptyResultContainer>
                        <ResultIcon icon="AlertTriangle" hue="white" />
                        No results found
                    </EmptyResultContainer>
                </ResultsList>
            );

        default:
            return null;
    }
};

export const Search = (props: SearchProps) => {
    const input = React.useRef<HTMLInputElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const onQueryEntered = useDebounce(
        350,
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const query = e.target.value;

            if (query.length > 3) {
                props.onQueryEntered(query);
            }
        },
    );

    const onCancel = () => {
        props.onCancel();

        if (!input.current) {
            return;
        }

        input.current.value = '';
    };

    const onResultClicked = (patientId: string) => {
        onCancel();
        props.onResultClicked(patientId);
    };

    React.useEffect(() => {
        const closeSearch = (e: MouseEvent) => {
            if (!wrapperRef?.current?.contains(e.target as Node)) {
                props.onCancel();
            }
        };
        document.body.addEventListener('click', closeSearch);
        return () => document.body.removeEventListener('click', closeSearch);
    }, []);

    const iconName: IconName = props.state === 'start' ? 'Search' : 'X';

    return (
        <Wrapper ref={wrapperRef}>
            <SearchIcon icon={iconName} hue="white" onClick={onCancel} />
            <Input
                data-cy="search-bar"
                ref={input}
                placeholder="Search..."
                onChange={onQueryEntered}
            />
            <Results {...props} onResultClicked={onResultClicked} />
        </Wrapper>
    );
};

export const ConnectedSearch = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(patients.searchState);
    const results = useAppSelector(patients.searchResults);

    const search = (query: string) => dispatch(patients.search(query));
    const cancel = () => dispatch(patients.clearSearch());
    const resultClicked = (patientId: string) => {
        dispatch(
            patients.openPatient({
                patientId,
            }),
        );
    };

    return (
        <Search
            state={state}
            results={results}
            onQueryEntered={search}
            onResultClicked={resultClicked}
            onCancel={cancel}
        />
    );
};

const Wrapper = styled.div`
    ${mix.bg({ hue: 'primary', shade: '2' })};

    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
`;

const Input = styled.input`
    ${mix.padding({ padding: [2, 2, 2, 0] })};
    ${mix.color({ dark: true, profile: 'body' })};
    ${mix.type({ bold: false, level: 'body1' })};

    background: transparent;
    border: none;
    outline: 0;
    flex-grow: 1;

    ::placeholder {
        ${mix.color({ dark: true, profile: 'secondary' })};
    }
`;

const SearchIcon = styled(Icon)`
    ${mix.margin({ margin: 2 })};
    width: ${mix.unit({ size: 2 })};
    height: ${mix.unit({ size: 2 })};
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
    }
`;

const ResultsList = styled.div`
    ${mix.bg({ hue: 'primary', shade: '1' })}
    position: absolute;
    border-radius: 0 0 4px 4px;
    top: 100%;
    width: 100%;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
    max-height: ${mix.unit({ size: 42 })};
    overflow: auto;
`;

const Container = styled.div`
    ${mix.padding({ padding: 2 })};
    ${mix.color({ dark: true, profile: 'body' })};
    ${mix.type({ bold: false, level: 'body1' })};
`;

const ResultContainer = styled(Container)`
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);

    &:hover {
        background: hsla(0, 0%, 100%, 0.2);
        cursor: pointer;
    }
`;

const ResultIcon = styled(Icon)`
    margin-right: ${mix.unit({ size: 2 })};
`;

const ResultLine = styled.div`
    font-size: 14px;
    margin-bottom: ${mix.unit({ size: 0.5 })};
    display: flex;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ResultHeader = styled(ResultLine)`
    ${mix.type({ bold: true, level: 'body1' })}
`;

const EmptyResultContainer = styled(Container)`
    display: flex;
    align-items: center;
    font-style: italic;
`;
