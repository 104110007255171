import React from 'react';
import { FormGrid } from './FormGrid';
import { PhotoUpload } from '$ui/Flo/PhotoUpload';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Text } from '$ui/Flo/Text';

interface Props {
    onUpload: (asset: string, photo: File) => void;
    logo: string | File | null;
    footer: string | File | null;
}

export const Branding = (props: Props) => {
    const { onUpload, logo, footer } = props;

    return (
        <FormGrid>
            <Text level="body2" bold profile="body">
                Practice Logo
            </Text>
            <LogoContainer>
                <PhotoUpload
                    label="Add your logo"
                    onUpload={(photo) => onUpload('logo', photo)}
                    photo={logo}
                    recommended={{ width: '200px', height: '200px'}}
                />
            </LogoContainer>
            <Text level="body2" bold profile="body">
                Email Footer
            </Text>
            <FooterContainer>
                <PhotoUpload
                    label="Add your email footer"
                    onUpload={(photo) => onUpload('footer', photo)}
                    photo={footer}
                    recommended={{ width: '500px', height: '150px'}}
                />
            </FooterContainer>
        </FormGrid>
    );
};

const LogoContainer = styled.div`
    flex: 0 0 auto;
    height: ${mix.unit({ size: 20 })};
    width: ${mix.unit({ size: 20 })};
    margin: 0 2rem 2rem 0;
`;

const FooterContainer = styled.div`
    display: block;
    height: ${mix.unit({ size: 20 })};
    width: 100%;
    margin: 0 2rem 2rem 0;
`;
