import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Heading } from '$ui/Flo/Heading';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Container, Intro } from '$ui/Integrations/Elements';

const VIDEO_ID_REGEX = /^https:\/\/player.vimeo.com\/video\/(\d+)\?/;

interface Props {
    vimeoEmbedUrl: string;
    integration: string;
    instructions: string;
    introTitle?: string;
    introText?: string;
}

export const SetupInstructions = ({
    vimeoEmbedUrl,
    integration,
    instructions,
    introTitle,
    introText,
}: Props) => {
    const vimeoId = vimeoEmbedUrl.replace(VIDEO_ID_REGEX, '$1');
    const videoUrl = `https://vimeo.com/${vimeoId}`;

    return (
        <Container>
            <Intro
                title={
                    introTitle ??
                    `First, you need to whitelist Leadflo with ${integration}.`
                }
            >
                {introText ?? 'Follow the instruction in the video:'}
            </Intro>
            <div>
                <iframe
                    title={`Video on how to setup ${integration} with Leadflo`}
                    src={vimeoEmbedUrl}
                    width="640"
                    height="480"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <ClickToCopyInstructions>
                <Heading align="left" level="body1">
                    Copy instructions
                </Heading>
                <Heading align="left" level="body2" profile="body">
                    If somebody adminsters your {integration} account, copy the
                    instructions below to send them to your administrator.
                </Heading>
                <ClickToCopyField>
                    <FloatingTextField
                        clickToCopy
                        disabled
                        multiline
                        autosize
                        value={instructions.replace('{video}', videoUrl)}
                        onChange={() => null}
                    />
                </ClickToCopyField>
            </ClickToCopyInstructions>
        </Container>
    );
};

const ClickToCopyField = styled.div`
    min-width: 640px;
`;

const ClickToCopyInstructions = styled.div`
    min-width: 640px;
    ${mix.gap({ size: 2 })};
    display: flex;
    flex-direction: column;
`;
