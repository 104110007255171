import React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { ConnectedReplyBar } from '$ui/ReplyBar';
import { useAppDispatch, useAppSelector, withState } from '$state';
import {
    selectEnabledChannelKeys,
    selectWritableChannels,
} from '$state/concerns/channels';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import {
    ChannelPayload,
    Channels,
    EmailPayload,
    ReplyState,
    SMSPayload,
} from '$state/types';
import * as bulk from '$state/concerns/bulk';
import { selectQuery } from '$state/concerns/patient-filter';
import * as uuid from 'uuid';
import { whatsAppEnabled } from '$state/queries/integrations';
import { featureEnabled } from '$state/queries/features';
import { Icon } from '$ui/Flo/Icon';
import { useResizeObserver } from '@/utils/resize-observer';
import { Extender } from '$ui/ReplyBar/Extender';

interface BulkActionBarProps {
    total: number;
}

export const BulkActionBarV2 = ({ total }: BulkActionBarProps) => {
    const dispatch = useAppDispatch();
    const selected = useAppSelector(bulk.selectSelected);
    const query = useAppSelector(selectQuery);
    const whatsAppFeatureEnabled = useAppSelector(
        withState(featureEnabled, 'whatsapp'),
    );

    const [composingMessage, setComposingMessage] =
        React.useState<boolean>(false);
    const [displayExtender, setDisplayExtender] =
        React.useState<boolean>(false);
    const [limitExtender, setLimitExtender] = React.useState<boolean>(false);
    const [initialHeight, setInitialHeight] = React.useState<number | null>(
        null,
    );

    const [message, setMessage] = React.useState<ReplyState>({
        channel: Channels.Email,
        fields: {},
    });

    const toggleExtender = () => {
        setLimitExtender(!limitExtender);
    };

    const onResize = React.useCallback(
        (target: HTMLDivElement) => {
            initialHeight
                ? setDisplayExtender(initialHeight < target.clientHeight)
                : setInitialHeight(target.clientHeight);
        },
        [initialHeight],
    );

    const observerRef = useResizeObserver(onResize);

    const channels = useAppSelector(
        withState(selectWritableChannels, {
            Note: false,
            SMS: useAppSelector(smsEnabled),
            Email: useAppSelector(emailEnabled),
            WhatsApp: useAppSelector(whatsAppEnabled) && whatsAppFeatureEnabled,
        }),
    );

    const enabledChannels = useAppSelector(
        withState(selectEnabledChannelKeys, {}),
    );

    const selectedTotal = selected === 'all' ? total : selected?.length ?? 0;

    const send = () => {
        if (query.report === null) {
            throw new Error('query.report must be set at this point');
        }

        if (selected === null) {
            return;
        }

        setMessage({
            channel: message.channel,
            fields: {},
        });

        // TODO: Normalise from/to to start/end
        const request =
            selected === 'all'
                ? {
                      ...query,
                      report: query.report,
                      from: query.start,
                      to: query.end,
                  }
                : { patient_ids: selected };

        const id = uuid.v4();

        let fields: Partial<ChannelPayload>;

        switch (message.channel) {
            case Channels.Email:
                fields = message.fields as Partial<EmailPayload>;
                return dispatch(
                    bulk.send({
                        ...request,
                        id,
                        total: selectedTotal,
                        payload: {
                            channel: 'Email',
                            message: fields.body as string,
                            subject: fields.subject as string,
                        },
                    }),
                );

            case Channels.SMS:
                fields = message.fields as Partial<SMSPayload>;
                return dispatch(
                    bulk.send({
                        ...request,
                        id,
                        total: selectedTotal,
                        payload: {
                            channel: 'SMS',
                            message: fields.message as string,
                        },
                    }),
                );
        }

        throw new Error(
            `Unrecognised bulk messaging channel: ${message.channel}`,
        );
    };

    const disabled = selected === null || selected.length === 0;

    return (
        <>
            {(displayExtender || limitExtender) && (
                <Extender
                    limitExtender={limitExtender}
                    toggle={toggleExtender}
                />
            )}
            <Container open={composingMessage}>
                <ReplayBarHolder
                    ref={observerRef}
                    limitExtender={limitExtender}
                    maxHeight={initialHeight}
                >
                    {composingMessage && !disabled && (
                        <ConnectedReplyBar
                            send={send}
                            channels={channels}
                            enabledChannels={enabledChannels}
                            state={message}
                            onStateChanged={(state) => setMessage(state)}
                            integrated
                            showPlaceholders
                        />
                    )}
                </ReplayBarHolder>
                <ActionBar
                    disabled={disabled}
                    open={composingMessage}
                    selected={selectedTotal}
                    total={total}
                    onComposeMessage={() =>
                        setComposingMessage(!composingMessage)
                    }
                />
            </Container>
        </>
    );
};

interface ActionBarProps {
    disabled?: boolean;
    open?: boolean;
    selected: number;
    total: number;
    onComposeMessage: () => void;
}

export const ActionBar = (props: ActionBarProps) => {
    const {
        open = false,
        disabled = false,
        selected,
        total,
        onComposeMessage,
    } = props;

    return (
        <ActionBarHolder open={open}>
            <div>
                <div>
                    {selected}/{total}
                </div>
                <Label>patients selected</Label>
            </div>
            {!open && (
                <ComposeButton onClick={onComposeMessage} disabled={disabled}>
                    <Icon
                        icon="MessageSquare"
                        hue="white"
                        opacity={1}
                        clickable={true}
                    />
                    Compose Message
                </ComposeButton>
            )}
            {open && (
                <CloseButton onClick={onComposeMessage} disabled={disabled}>
                    <Icon icon="X" clickable={true} />
                    Close
                </CloseButton>
            )}
        </ActionBarHolder>
    );
};

const Container = styled.div<{ open: boolean }>`
    ${({ open }) =>
        open &&
        css`
            border: 1px solid var(--gray-200);
            box-shadow: 0px 4px 16px 0px #00000014;
            border-radius: ${mix.unit({ size: 0.5 })};
        `};
`;

interface ReplayBarHolder {
    limitExtender: boolean;
    maxHeight: number | null;
}

const ReplayBarHolder = styled.div<ReplayBarHolder>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    max-height: 75vh;

    ${({ limitExtender, maxHeight }) =>
        limitExtender &&
        maxHeight &&
        css`
            max-height: ${maxHeight}px;
        `}
`;

const ActionBarHolder = styled.div<{ open: boolean }>`
    ${mix.round({ rounded: true })};
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.color({ profile: 'title' })};
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-shadow: 0px 4px 16px 0px #00000014;
    border: 1px solid var(--gray-200);
    border-radius: ${mix.unit({ size: 0.5 })};

    ${({ open }) =>
        open &&
        css`
            border: none;
            border-top: 1px solid var(--gray-100);
            border-radius: 0 0 3px 3px;
            box-shadow: none;
        `};
`;

const Label = styled.div`
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'secondary' })};
`;

const ComposeButton = styled.button`
    ${mix.padding({ padding: [0, 2, 0, 2] })};
    ${mix.height({ size: 3.5 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: white;
    background-color: var(--primary-500);
    gap: ${mix.unit({ size: 1 })};

    &:disabled {
        background-color: var(--primary-200);
    }

    &:hover:not([disabled]) {
        background-color: var(--primary-600);
    }
`;

const CloseButton = styled.button`
    ${mix.padding({ padding: 1 })};
    ${mix.height({ size: 3.5 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--gray-600);
    background-color: var(--gray-100);
    gap: ${mix.unit({ size: 1 })};

    &:hover:not([disabled]) {
        background-color: var(--gray-200);
    }
`;
