import React from 'react';
import { RoutingHeader, RoutingSidebar } from './Routing';

interface Props {
    children: React.ReactNode;
}

/**
 * App layout during logged in state.
 */
export const Content = ({ children }: Props) => {
    return (
        <div className="app ">
            <RoutingHeader />

            <div className="main-container" style={{ paddingTop: '54px' }}>
                <RoutingSidebar />

                <div
                    id="content"
                    className="content-container content-container--v3"
                    style={{
                        height: 'calc(100vh - 54px)',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
