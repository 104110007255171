import { Icon } from '$ui/Flo/Icon';
import styled, { css } from 'styled-components';
import React from 'react';
import { mix } from '$ui/Flo/util';
import { IntegrationState } from '$types/integrations';

interface Props {
    state: IntegrationState;
}

export const IntegrationStatusIcon = ({ state }: Props) => {
    if (state === 'disconnected') {
        return null;
    } else if (state === 'disconnecting') {
        return <Container state={state}>
            <Icon thickness={2} icon="AlertCircle" hue="white" size={2} opacity={1} />
        </Container>
    } else if (state === 'healthy') {
        return (
            <Container state={state}>
                <Icon
                    thickness={2}
                    icon="Check"
                    hue="white"
                    size={2}
                    opacity={1}
                />
            </Container>
        );
    } else {
        return (
            <Container state={state}>
                <Icon thickness={2} icon="X" hue="white" size={2} opacity={1} />
            </Container>
        );
    }
};

const Container = styled.div<Props>`
    ${mix.sq({ size: 2.5 })};

    ${({ state }) => {
        switch (state) {
            case 'unhealthy':
                return css`
                    background: red;
                `;
            case 'healthy':
                return css`
                    background: green;
                `;
            case 'disconnecting':
                return css`
                    background: var(--warning-500);
                `;
            case 'disconnected':
                return css``;
        }
    }}
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
