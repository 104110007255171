import React from 'react';
import { Header, HeaderProps } from '$ui/Integrations/Frame/Header';
import { Footer, FooterProps } from '$ui/Integrations/Frame/Footer';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props extends HeaderProps, FooterProps {
    children: React.ReactNode;
    loading?: boolean;
}

export const Frame = (props: Props) => {
    return (
        <Container>
            <Header {...props} />
            <Content>{props.children}</Content>
            <Footer {...props} />
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;
    height: 100%;
`;

const Content = styled.div`
    ${mix.padding({ padding: [10, 0, 10] })};
    overflow: auto;
`;
