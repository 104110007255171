import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CommTypeSMS = ({ size = 24, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6057_16647)">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#667085"/>
                <path d="M14.3365 5.34595H9.66352C9.44489 5.34532 9.22829 5.38792 9.02617 5.4713C8.82406 5.55467 8.64043 5.67718 8.48583 5.83178C8.33123 5.98637 8.20872 6.17001 8.12535 6.37212C8.04197 6.57423 7.99938 6.79083 8.00001 7.00947V16.9905C8.00001 17.913 8.75615 18.6541 9.66352 18.6541H14.3365C14.5551 18.6547 14.7717 18.6121 14.9738 18.5287C15.1759 18.4453 15.3596 18.3228 15.5142 18.1682C15.6688 18.0136 15.7913 17.83 15.8747 17.6279C15.958 17.4258 16.0006 17.2092 16 16.9905V7.00947C16.0006 6.79083 15.958 6.57423 15.8747 6.37212C15.7913 6.17001 15.6688 5.98637 15.5142 5.83178C15.3596 5.67718 15.1759 5.55467 14.9738 5.4713C14.7717 5.38792 14.5551 5.34532 14.3365 5.34595ZM12.0076 17.5803C11.5841 17.5803 11.2514 17.2476 11.2514 16.8242C11.2514 16.4008 11.5841 16.0681 12.0076 16.0681C12.431 16.0681 12.7637 16.4008 12.7637 16.8242C12.7637 17.2476 12.431 17.5803 12.0076 17.5803ZM15.2439 15.2665H8.75615V7.56901H15.2439V15.2665Z" fill="white"/>
            </g>
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="white" strokeWidth="2"/>
        </svg>
    );
};
