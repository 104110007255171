import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';
import React, { useEffect } from 'react';
import * as placeholders from '$state/concerns/placeholders';
import * as templates from '$state/concerns/templates';
import * as contexts from '$state/concerns/contexts';
import {
    listSources,
    selectSourceNames,
    selectSources,
} from '$state/concerns/sources';
import { debounce } from '@/utils/fn';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Triggers the load of a client's templates if the templates are not already
 * loaded.
 */
export const useTemplateLoader = () => {
    const dispatch = useAppDispatch();
    const templatesStatus = useAppSelector(templates.selectStatus);

    React.useEffect(() => {
        if (templatesStatus === 'init') {
            dispatch(templates.loadTemplates());
        }
    }, [templatesStatus]);
};

/**
 * Triggers the load of the available placeholders (merge tags) if not already
 * loaded.
 *
 * Used by both canned responses / templates and the automations editor.
 */
export const usePlaceholdersLoader = () => {
    const dispatch = useAppDispatch();
    const placeholderStatus = useAppSelector(placeholders.selectStatus);

    React.useEffect(() => {
        if (placeholderStatus === 'init') {
            dispatch(placeholders.loadPlaceholders());
        }
    }, [placeholderStatus]);
};

/**
 * Triggers the load of a patient's template context if the context is not
 * already loaded.
 *
 * @param patientId
 */
export const useContextLoader = (patientId: string) => {
    const dispatch = useAppDispatch();
    const contextStatus = useAppSelector(contexts.selectStatus(patientId));

    React.useEffect(() => {
        if (contextStatus === 'init') {
            dispatch(contexts.loadContext(patientId));
        }
    }, [contextStatus, patientId]);
};

/**
 * Selects lead sources from the store, and triggers the load of sources if
 * they are not already loaded.
 */
export const useSources = (): string[] => {
    const dispatch = useAppDispatch();
    const state = useAppSelector(selectSources);

    useEffect(() => {
        if (state.status === 'init') {
            dispatch(listSources());
        }
    }, [state.status]);

    return useAppSelector(selectSourceNames);
};

/**
 * Debounce hook.
 * Use to delay setting the state value.
 * @param delay
 */
export const _useDebounce = (
    delay = 350,
): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [value, setValue] = React.useState<string>('');
    const [valueQuery, setValueQuery] = React.useState<string>('');

    useEffect(() => {
        const delayFn = setTimeout(() => setValue(valueQuery), delay);
        return () => clearTimeout(delayFn);
    }, [valueQuery, delay]);

    return [value, setValueQuery];
};

export const useDebounce = <T extends (...args: any[]) => any>(
    delay: number,
    fn: T,
) => {
    const debouncedFn = React.useMemo(() => debounce({ delay }, fn), []);

    React.useEffect(() => {
        return () => {
            debouncedFn.cancel();
        };
    }, [debouncedFn]);

    return debouncedFn;
};
