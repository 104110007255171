import { ReportingState, ReportQuery } from '$state/types';
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import * as api from '$api';

export const loadPipelineReport = createAsyncThunk<
    api.PipelineReportPayload,
    ReportQuery
>('reporting/pipeline', (arg) => {
    return api.getPipelineReport(
        arg.start,
        arg.end,
        arg.types,
        arg.labels,
        arg.sources
    );
});

export const loadOverviewReport = createAsyncThunk<
    api.OverviewReportPayload,
    ReportQuery
>('reporting/overview', (arg) => {
    return api.getOverviewReport(
        arg.start,
        arg.end,
        arg.types,
        arg.labels,
        arg.sources
    );
});

const initialState: ReportingState = {
    pipeline: {
        status: 'idle'
    },
    overview: {
        status: 'idle'
    }
};

export default createReducer<ReportingState>(initialState, (builder) => {
    builder.addCase(loadOverviewReport.pending, (state) => {
        state.overview = { status: 'loading' };
    });

    builder.addCase(loadPipelineReport.pending, (state) => {
        state.pipeline = { status: 'loading' };
    });

    builder.addCase(loadPipelineReport.rejected, (state) => {
        state.pipeline = { status: 'errored' };
    });

    builder.addCase(loadOverviewReport.rejected, (state) => {
        state.overview = { status: 'errored' };
    });

    builder.addCase(loadPipelineReport.fulfilled, (state, action) => {
        state.pipeline.status = 'loaded';
        const current = action.payload.current;
        const previous = action.payload.previous;
        // Lost is calculated as the sum of all causes of being lost
        current.lost = current.not_interested + current.maybe_future;
        previous.lost = previous.not_interested + previous.maybe_future;
        state.pipeline.metrics = {
            current: current,
            previous: previous
        };
    });

    builder.addCase(loadOverviewReport.fulfilled, (state, action) => {
        state.overview.status = 'loaded';
        state.overview.metrics = {
            current: action.payload.current,
            previous: action.payload.previous
        };
    });
});
