import {
    FlowState,
    FlowNode,
    ChoicesNode,
    NodeMap,
    NextActionContext,
    NextableNodes,
    Nextable,
    DoubleBackNodes
} from '$types/flows';

/** From the state, returns the current node */
export const currentNode = (nodes: NodeMap, state: FlowState): FlowNode =>
    nodes[state.node];

/** From the state, if a next node is selected, returns that node */
export const nextNode = (nodes: NodeMap, state: FlowState): FlowNode | null =>
    state.provisionalNext ? nodes[state.provisionalNext] : null;

/** From a choices node, returns the node of the selected choice */
export const choicesNextNode = (node: ChoicesNode, selected?: string) => {
    return node.choices.find((choice) => choice.value === selected)?.target;
};

/** Returns whether the currently selected node is the first in the flow */
export const first = (state: FlowState): boolean => state.previous === null;

export const final = (state: FlowState): boolean =>
    state.provisionalNext === 'finish';

export const proceedable = (state: FlowState): boolean =>
    !!state.provisionalNext;

export const back = (nodes: NodeMap, state: FlowState): FlowState | null => {
    if (state.previous == null) {
        return null;
    }

    const prev = state.previous;
    if (DoubleBackNodes.includes(currentNode(nodes, prev).type)) {
        return back(nodes, prev);
    }

    return prev;
};

// should only be called if state provisionalNext isn't finish
// Choices steps should've already set provisionalNext
export const next = (nodes: NodeMap, state: FlowState): FlowState => {
    const selected = nextNode(nodes, state);
    if (!selected) {
        return state;
    }

    const newState: FlowState = {
        node: selected.key,
        context: {},
        previous: state,
        provisionalNext: null
    };

    if (NextableNodes.includes(selected.type)) {
        newState.provisionalNext = (selected as Nextable).next;
    }

    return newState;
};

export const context = (state: FlowState): Partial<NextActionContext> => {
    let context: Partial<NextActionContext> = {};
    let next: FlowState | null = state;
    while (next !== null) {
        context = { ...next.context, ...context };
        next = next.previous;
    }
    return context;
};

export const start = (nodes: NodeMap, starting: string): FlowState => {
    const state: FlowState = {
        node: starting,
        context: {},
        provisionalNext: null,
        previous: null
    };

    const node = nodes[starting];
    if (NextableNodes.includes(node.type)) {
        state.provisionalNext = (node as Nextable).next;
    }

    return state;
};

export const addDays = (state: FlowState, days: number): FlowState => {
    const result = new Date(Date.now() + 3600 * 1000 * 24 * days);
    return {
        ...state,
        context: { date: result.toISOString() }
    };
};

export const changeNextAction = (
    state: FlowState,
    nextAction: string
): FlowState => {
    return {
        ...state,
        context: { nextAction }
    };
};
