import React from 'react';
import {
    Container,
    Intro,
    SignInButton,
    StatusIcon
} from '$ui/Integrations/Elements';

interface Props {
    integration: string;
    mode: 'sync' | 'connect' | 'repair';
    onSignIn: () => void;
    signingIn?: boolean;
}

export const Failed = ({
    integration,
    mode,
    onSignIn,
    signingIn = false
}: Props) => {
    return (
        <Container>
            <StatusIcon mode="unhealthy" />
            <Intro title={`${integration} failed to ${mode}`}>
                We've let someone know. Otherwise, you can re-attempt it by
                signing in again.
            </Intro>
            <SignInButton
                integration={integration}
                onSignIn={onSignIn}
                signingIn={signingIn}
            />
        </Container>
    );
};
