import { Button } from '$ui/Flo/Button';
import { Icon } from '$ui/Flo/Icon';
import { Tooltip } from '$ui/Flo/Tooltip';
import { mix } from '$ui/Flo/util';
import { useHover } from '@/utils/hooks';
import React, { useEffect } from 'react';
import styled from 'styled-components';

interface Props {
    onEnter: (label: string) => void;
    onChange: (label: string) => void;
    handleRecentLabels: (display: boolean) => void;
    errors?: React.ReactNode;
    disabled?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const MAX_LABEL_LENGTH = 50;

export const AddLabelField: React.FC<Props> = ({
    onEnter,
    onChange,
    errors,
    handleRecentLabels,
    disabled = false,
    inputRef = null
}) => {
    const fieldId = 'label-input';
    const [query, setQuery] = React.useState('');
    const [isEditing, setIsEditing] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [inFocus, setInFocus] = React.useState(false);

    const disabledRef = React.useRef<HTMLDivElement>(null);
    const [showDisabledHover, setShowDisabledHover] = React.useState(false);

    useHover({
        ref: disabledRef,
        onEnter: () => setShowDisabledHover(true),
        onLeave: () => setShowDisabledHover(false)
    });

    useEffect(() => {
        onChange(query);
    }, [query]);

    useEffect(() => {
        if (isEditing) {
            inputRef?.current?.focus();
            handleRecentLabels(isEditing);
        }
    }, [isEditing]);

    useEffect(() => {
        handleRecentLabels(isEditing && inFocus);
    }, [inFocus]);

    const handleFocusStart = () => {
        setInFocus(true);
    };

    const handleFocusEnd = () => {
        setTimeout(() => {
            // Check if input field is maybe in focus again
            if (document.activeElement?.id !== fieldId) {
                setInFocus(false);
            }
        }, 200);
    };

    if (disabled) {
        return (
            <AddLabelButton ref={disabledRef}>
                <Tooltip
                    size="small"
                    open={showDisabledHover}
                    align="center"
                    hue="red"
                >
                    <>Patient have reached the maximum number of labels</>
                </Tooltip>
                <Button
                    icon="Plus"
                    size="medium"
                    hue="grey"
                    rounded
                    align="center"
                    mode="outline"
                    onClick={() => null}
                    disabled
                >
                    Add Label
                </Button>
            </AddLabelButton>
        );
    }

    if (!isEditing) {
        return (
            <AddLabelButton>
                <Button
                    icon="Plus"
                    size="medium"
                    hue="primary"
                    rounded
                    align="center"
                    mode="outline"
                    onClick={() => setIsEditing(true)}
                >
                    Add Label
                </Button>
            </AddLabelButton>
        );
    }

    return (
        <Bar>
            <Tooltip open={showError} align="center" hue="red" size="small">
                <>Label must be less than {MAX_LABEL_LENGTH} characters</>
            </Tooltip>
            {errors}
            <Icon icon="Tag" size={1.75} shade="1" />
            <Input
                id={fieldId}
                ref={inputRef}
                value={query}
                onChange={(e) => {
                    const { value } = e.target;
                    setQuery(value);
                    setShowError(value.length > MAX_LABEL_LENGTH);
                    if (value.length > MAX_LABEL_LENGTH) {
                        setQuery(value.slice(0, MAX_LABEL_LENGTH));
                        return;
                    }
                }}
                placeholder="Type a label and press enter"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onEnter(query);
                        setQuery('');
                        setShowError(false);
                    }
                }}
                onFocus={() => handleFocusStart()}
                onBlur={() => handleFocusEnd()}
            />
            {query.length > 0 && (
                <Icon
                    icon="X"
                    size={1.75}
                    onClick={() => {
                        setQuery('');
                        setShowError(false);
                    }}
                    clickable
                />
            )}
        </Bar>
    );
};

const AddLabelButton = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
`;

const Bar = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    ${mix.round({ rounded: true })};
    ${mix.bg({ hue: 'grey', shade: '10', alpha: 0.5 })};
    ${mix.padding({ padding: [0.75, 1] })};
    ${mix.gap({ size: 1 })};
`;

const Input = styled.input`
    ${mix.type({ level: 'body2' })};
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    ${mix.padding({ padding: [0] })};

    &::placeholder {
        color: ${mix.palette({ hue: 'grey', shade: '5' })};
    }
`;
