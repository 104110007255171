import { RootState } from '$state/store';
import {
    Conversation,
    ConversationsStatus,
    InboxState
} from '$state/types/inbox';
import {
    InboxChannelFilter,
    InboxRoute,
    InboxStatusFilter,
    NavCountingStrategy
} from '$types/app';
import { Comm } from '$types/patient';
import { createSelector } from '@reduxjs/toolkit';
import { selectNavChannels } from '$state/concerns/channels';
import { featureEnabled } from './features';
import { withState } from '$state/utils';
import { Channels } from '$state/types';

export const inboxState = (state: RootState): InboxState => state.inbox;

export const inboxNavConfigs = createSelector(
    [inboxState, selectNavChannels, withState(featureEnabled, 'whatsapp')],
    ({ views }, navChannels, whatsAppEnabled) => {
        const submenu = Object.entries(views)
            .filter(([_, view]) => {
                const { channel } = view;
                if (channel === 'all') {
                    return true;
                }

                if (channel === Channels.WhatsApp) {
                    return whatsAppEnabled;
                }

                return navChannels.includes(channel);
            })
            .map(([title, view]) => ({
                name: title,
                destination: view.path,
                count: view.count ?? 0
            }));

        return [
            {
                name: 'Inbox',
                icon: 'Inbox',
                submenu,
                countingStrategy: NavCountingStrategy.FIRST
            }
        ];
    }
);

export const inboxRoutes = createSelector(
    [inboxState],
    (state: InboxState): InboxRoute[] => {
        const { views } = state;
        return Object.entries(views).map(([name, view]) => {
            const { path, parameters } = view;
            return {
                name,
                path,
                ...parameters
            };
        });
    }
);

interface ConversationFilter {
    unread: InboxStatusFilter;
    channel: InboxChannelFilter;
}

/**
 * Returns whether the given conversation matches the provided filter
 */
export const convoMatches = (
    filter: ConversationFilter,
    conv: Conversation
): boolean => {
    return (
        (filter.unread === 'all' || conv.unread) &&
        (filter.channel === 'All' || conv.channel === filter.channel)
    );
};

/**
 * Returns whether a given communication matches the filter
 * parameters from a view
 */
export const commMatches = (
    filter: ConversationFilter,
    comm: Comm
): boolean => {
    return (
        (filter.unread === 'all' || comm.read_at == null) &&
        (filter.channel === 'All' || comm.comm_type === filter.channel)
    );
};

export const listConversations = createSelector(
    [
        (state, { channel }) => {
            return getViewState(state, channel);
        }
    ],
    (view) => Object.values(view.conversations)
);

export const conversationsMap = createSelector(
    [
        (state, { channel }) => {
            return getViewState(state, channel);
        }
    ],
    (view) => view.conversations
);

export const getViewState = createSelector(
    [inboxState, (_, name: string) => name],
    ({ views }, name) => {
        const view = views[name];

        if (!view) {
            throw new Error(`View '${view}' is not defined`);
        }

        return view;
    }
);

export const conversationPatientIds = createSelector(
    [(_, { channel }) => channel, getViewState],
    (_, view) => {
        return Object.keys(view.conversations);
    }
);

export const inboxLoaded = createSelector([getViewState], (view) =>
    [ConversationsStatus.LOADED, ConversationsStatus.LOADING_MORE].includes(
        view.state
    )
);

export const conversationsToken = createSelector(
    [getViewState],
    ({ paging }) => paging.next
);

export const conversationsState = createSelector(
    [getViewState],
    ({ state }) => state
);

export const loadingNextConversations = createSelector(
    [conversationsState],
    (state) => state === ConversationsStatus.LOADING_MORE
);

export const emailMessage = (comm: string | null) => (rootState: RootState) => {
    if (!comm) {
        return null;
    }

    const { emails } = inboxState(rootState);
    return emails[comm];
};

export const getUnreadCount = createSelector(
    [inboxState, (_, channel: string) => channel],
    (state, channel) => {
        const view = state.views[channel];
        return view.count ?? 0;
    }
);

export const getUnreadCountWithFilters = createSelector(
    [inboxState, (_, channel: string) => channel],
    (state, channel) => {
        const view = state.views[channel];
        return view.countWithFilters ?? 0;
    }
);

export const getStarredCount = createSelector(
    [inboxState, (_, channel: string) => channel],
    (state, channel) => {
        const view = state.views[channel];
        return view.countStarred ?? 0;
    }
);

export const getSnoozedCount = createSelector(
    [inboxState, (_, channel: string) => channel],
    (state, channel) => {
        const view = state.views[channel];
        return view.countSnoozed ?? 0;
    }
);

export const getFilters = createSelector(
    [inboxState, (_, channel: string) => channel],
    (state, channel) => {
        const view = state.views[channel];
        return view.parameters;
    }
);
