import { Icon } from '$ui/Flo/Icon';
import React from 'react';
import { isToday, isTomorrow, isYesterday } from 'date-fns';
import styled from 'styled-components';
import { dates } from '$utils';
import { mix } from '$ui/Flo/util';
import { SnoozeState } from '$state/types/snooze';

interface Props {
    endsAt: string;
    comment?: string | null;
    state: SnoozeState;
}

const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);

    if (isToday(date)) {
        return 'today';
    }

    if (isTomorrow(date)) {
        return 'tomorrow';
    }

    if (isYesterday(date)) {
        return 'yesterday';
    }

    return 'on ' + dates.long(date);
};

export const SnoozeInfo = ({ endsAt, state, comment }: Props) => {
    const verb = state === 'asleep' ? 'is snoozed' : 'was snoozed';

    return (
        <Container>
            <Info data-testid="snooze-info">
                <Icon icon="AlertCircle" />
                <div>
                    Patient {verb} until {dates.time(endsAt)}{' '}
                    {formatDate(endsAt)}
                </div>
            </Info>
            {comment && <Comment>{comment}</Comment>}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
`;

const Info = styled.div`
    ${mix.padding({ padding: 1.5 })};
    display: flex;
    align-items: center;
    ${mix.type({ level: 'body2' })};
    color: var(--primary-900);
    background: var(--primary-050);
    border-radius: ${mix.unit({ size: 0.75 })};
    ${mix.gap({ size: 1 })};
`;

const Comment = styled.div`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.padding({ padding: [4, 4.5] })};
    border-radius: ${mix.unit({ size: 0.75 })};
    background: var(--gray-100);
    text-align: left;
    color: var(--gray-500);
`;
