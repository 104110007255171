import * as React from 'react';
import styled from 'styled-components';
// temp for hot reloading - should be on root-most react component
import { Text } from '../Flo/Text';
import { palette } from '$ui/Flo/util';

interface Props {
    date: Date;
}

const spacing = '32px';

const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &::before,
    &::after {
        content: '';
        border-top: 1px solid
            ${({ theme }) => palette({ theme, hue: 'grey', shade: '8' })};
        flex: 1 0 ${spacing};
        margin: 0 ${spacing} 0 0;
    }

    &::after {
        margin: 0 0 0 ${spacing};
    }
`;

export const DateLine = ({ date }: Props) => {
    // probably best lift this to configuration
    const formatted = new Intl.DateTimeFormat('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }).format(date);
    return (
        <Line>
            <Text level="body2">{formatted}</Text>
        </Line>
    );
};
