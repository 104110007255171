import * as React from 'react';
import { Button } from '$ui/Flo/Button';
import { PatientStatus } from '$state/types';

interface Props {
    onEdit: () => void;
    onSave: () => void;
    editStatus: PatientStatus;
    editing: boolean;
}

export const EditButton = (props: Props) => {
    const { editing, onEdit, onSave, editStatus } = props;
    switch (editStatus) {
        case PatientStatus.LOADED:
            break;
        case PatientStatus.SAVING:
            return (
                <Button
                    icon="RefreshCw"
                    mode="outline"
                    hue="primary"
                    rounded
                    spin
                    disabled
                >
                    Saving
                </Button>
            );
        case PatientStatus.ERRORED_SAVING_ACTION:
        case PatientStatus.ERRORED_SAVING:
            return (
                <Button icon="RefreshCw" mode="text" hue="red" onClick={onSave}>
                    Something went wrong, try again
                </Button>
            );
    }

    if (editing) {
        return (
            <Btn id="patient-save-button" editing={editing} onClick={onSave} />
        );
    } else {
        return (
            <Btn id="patient-edit-button" editing={editing} onClick={onEdit} />
        );
    }
};

const Btn = (props: { id: string; editing: boolean; onClick: () => void }) => (
    <Button
        id={props.id}
        icon="Edit"
        mode="outline"
        hue="primary"
        rounded
        border="x-small"
        onClick={props.onClick}
    >
        {props.editing ? 'Save' : 'Edit'} patient
    </Button>
);
