import React from 'react';
import { AttachmentEvent, CommEventUnion } from '$types';
import {
    AttachmentEvent as AttachmentEventV2,
    CommEventUnion as CommEventUnionV2,
} from '$types/timeline-v2';
import * as Parts from '$ui/Timeline/Parts';
import { ColorToken } from '$ui/Flo/types';

interface OnlyAttachmentsProps {
    event:
        | CommEventUnion
        | CommEventUnionV2
        | AttachmentEvent
        | AttachmentEventV2;
    attachmentsEnabled?: boolean;
    color?: ColorToken;
}

export const OnlyAttachments = (props: OnlyAttachmentsProps) => {
    const { event, attachmentsEnabled, color } = props;
    const status: string = event.inbound ? 'received' : 'sent';

    return (
        <>
            <Parts.Event inbound={event.inbound}>
                {attachmentsEnabled && event.attachments && (
                    <Parts.Attachments
                        attachments={event.attachments}
                        withoutMessage={true}
                        color={color}
                    />
                )}
                <Parts.Metadata>
                    <Parts.Status status={status} date={event.datetime} />
                </Parts.Metadata>
            </Parts.Event>
        </>
    );
};
