import { store } from '$state';
import { refresh } from '$state/concerns/config';
import { fetchRetry } from '@/utils/fetch-retry';
import * as cache from '$state/cache';

const REFRESH_CHECK_INTERVAL_MS = 5_000;

interface ConfigSource {
    (): {
        refreshing: boolean;
        URL?: string;
    };
}

export const start = (config: ConfigSource) => {
    setInterval(() => {
        const { URL, refreshing } = config();

        if (!URL) {
            return;
        }

        scheduleRefresh({
            refreshing,
            uiVersion: __VERSION__,
            fetchApiVersion: () => apiVersion(URL),
            fetchAvailableVersion: availableVersion,
            dispatch: dispatchScheduledRefresh,
        });
    }, REFRESH_CHECK_INTERVAL_MS);
};

export const apiVersion = async (
    apiBaseUrl: string,
): Promise<string | null> => {
    const response = await fetchRetry(apiBaseUrl + '/health/stateless');
    return response.headers.get('x-api-version') ?? null;
};

export const availableVersion = async (): Promise<string | null> => {
    const response = await fetchRetry(location.origin + '/config.json');

    try {
        const { api_version: version } = await response.json();
        return version ?? null;
    } catch {
        return null;
    }
};

export const dispatchScheduledRefresh = () => {
    store.dispatch(refresh());
};

interface ScheduleRefreshArgs {
    uiVersion: string;
    refreshing: boolean;
    fetchApiVersion: () => Promise<string | null>;
    fetchAvailableVersion: () => Promise<string | null>;
    dispatch: () => void;
}

export const scheduleRefresh = async (
    args: ScheduleRefreshArgs,
): Promise<void> => {
    const {
        dispatch,
        refreshing,
        uiVersion: ui,
        fetchApiVersion,
        fetchAvailableVersion,
    } = args;

    if (ui === 'local') {
        return;
    }

    if (refreshing) {
        return;
    }

    const available = await fetchAvailableVersion();

    if (available === null) {
        return;
    }

    // At this point, UI is up to date so there's no need to refresh
    if (ui === available) {
        return;
    }

    const api = await fetchApiVersion();

    if (api === null) {
        return;
    }

    // We shouldn't need to refresh if API and UI is aligned
    // Nor should we refresh if the API is not yet deployed
    if (api === ui || api !== available) {
        return;
    }

    const versions = [
        api,
        ...(await Promise.all([fetchApiVersion(), fetchApiVersion()])),
    ];

    // If all 3 are equal, there's a better chance the old API has drained
    // preventing a redirect loop.
    if (!versions.every((v) => v === versions[0])) {
        return;
    }

    dispatch();
};

export const forceRefresh = async (): Promise<void> => {
    cache.remove(); // remove local state cache

    // make URL relative to sanitize
    const url = window.location.href.replace(/(^[a-z]+:\/\/(.*?))?\//, '/');

    await fetch(url, {
        headers: new Headers({
            Pragma: 'no-cache',
            Expires: '-1',
            'Cache-Control': 'non-cache',
        }),
    });

    window.location.reload();
};

export default { start };
