import * as React from 'react';
import { openEmailArgumentsV2 } from '$types';
import {
    CommEventUnion,
    Events,
    EventUnion,
    CommType,
    Note,
    Email,
    Call,
    SMS,
    BusinessEvent as BusinessEventV2,
    WhatsApp,
    AttachmentEvent as AttachmentEventV2,
    FormSubmission,
} from '$types/timeline-v2';
import { DateLine } from './DateLine';
import { Failed, Loading } from '$ui/Shared';
import { TimelineStatus } from '$state/concerns/timeline';
import { Theme } from '$ui/Theme';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { BusinessEvent } from './BusinessEvent';
import { AttachmentEvent } from '$ui/Timeline/AttachmentEvent';
import { SMSEvent } from './V2/SMSEvent';
import { FormSubmissionEvent } from './FormSubmissionEvent';
import { PhoneCallEvent } from './PhoneCallEvent';
import { EmailEvent } from './V2/EmailEvent';
import { WhatsAppMessageEvent } from './V2/WhatsAppMessageEvent';
import { NoteEvent } from './NoteEvent';

type openEmailModal = (args: openEmailArgumentsV2) => void;

// excludes form_submission
export type retryComms = Email | SMS | Call | WhatsApp;

interface ActorProps {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
}

interface Props extends ActorProps {
    events: Events;
    openEmailModal: openEmailModal;
    status: TimelineStatus;
    retry?: (comm: retryComms) => void;
    attachmentsEnabled?: boolean;
}

export const TimelineV2 = (props: Props) => {
    const {
        events,
        openEmailModal,
        status,
        retry,
        attachmentsEnabled = false,
        ...actor
    } = props;

    if (status === 'loading') {
        return <Loading text="Loading timeline..." />;
    }

    if (status === 'error_loading') {
        return <Failed />;
    }

    const timeline = [];
    const days = groupEvents(events);
    for (const group of days) {
        timeline.push(
            <DateLine date={group.date} key={group.date.toString()} />,
        );
        timeline.push(
            ...group.events.map((event) =>
                buildEvent(
                    event,
                    openEmailModal,
                    actor,
                    retry,
                    attachmentsEnabled,
                ),
            ),
        );
    }

    return (
        <Theme>
            <Container data-cy="timeline">
                <Pane role="list">{timeline}</Pane>
            </Container>
        </Theme>
    );
};

interface eventGroup {
    date: Date;
    events: Events;
}

const groupEvents = (events: Events): eventGroup[] => {
    const map = {};
    for (const event of events) {
        if (!event) continue;
        const date = new Date(Date.parse(event.datetime));
        const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        const existingEvents = (map[key] || {}).events || [];
        existingEvents.push(event);
        map[key] = {
            date: date,
            events: existingEvents,
        };
    }
    return Object.values(map);
};

const buildEvent = (
    event: EventUnion,
    open: openEmailModal,
    actor: ActorProps,
    retry?: (comm: retryComms) => void,
    attachmentsEnabled?: boolean,
) => {
    switch (event.type) {
        case 'note':
            return <NoteEvent {...actor} note={event as Note} key={event.id} />;
        case 'communication':
            return buildComm(
                event as CommEventUnion,
                open,
                actor,
                retry,
                attachmentsEnabled,
            );
        case 'business_event':
            return (
                <BusinessEvent
                    {...actor}
                    businessEvent={event as BusinessEventV2}
                    key={event.id}
                />
            );
        case 'attachment':
            return (
                <AttachmentEvent
                    {...actor}
                    attachment={event as AttachmentEventV2}
                    key={event.id}
                />
            );
        default:
            return null;
    }
};

const buildComm = (
    comm: CommEventUnion,
    open: openEmailModal,
    actor: ActorProps,
    retry?: (comm: retryComms) => void,
    attachmentsEnabled?: boolean,
) => {
    switch (comm.comm_type) {
        case CommType.Email:
        case CommType.MarketingEmail:
            return (
                <EmailEvent
                    {...actor}
                    email={comm as Email}
                    openModal={open}
                    key={comm.id}
                    retry={retry}
                    attachmentsEnabled={attachmentsEnabled}
                />
            );
        case CommType.SMS:
            return (
                <SMSEvent
                    {...actor}
                    sms={comm as SMS}
                    key={comm.id}
                    retry={retry}
                    attachmentsEnabled={attachmentsEnabled}
                />
            );
        case CommType.PhoneCall:
            return (
                <PhoneCallEvent {...actor} call={comm as Call} key={comm.id} />
            );
        case CommType.WhatsAppMessage:
            return (
                <WhatsAppMessageEvent
                    {...actor}
                    message={comm as WhatsApp}
                    key={comm.id}
                    retry={retry}
                    attachmentsEnabled={attachmentsEnabled}
                />
            );
        case CommType.FormSubmission:
            return (
                <FormSubmissionEvent
                    submission={comm as FormSubmission}
                    key={comm.id}
                    {...actor}
                />
            );
        default:
            return null;
    }
};

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 16px 2rem;
`;

const Pane = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 4 })};
    padding-top: ${mix.unit({ size: 6 })};
`;
