import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconName } from '../Icon';
import { Align, ButtonMode, ButtonSize, ColorToken, Hue, Shade } from '../types';
import {
    alpha,
    applyBg,
    applyGap,
    applyMargin,
    applyPadding,
    applyRoundedCorners,
    applyType,
    mix,
    palette
} from '../util';
import { Tooltip } from '$ui/Flo/Tooltip';

export interface ButtonProps {
    id?: string;
    disabled?: boolean;
    confirm?: boolean;
    spin?: boolean;
    align?: Align;
    icon?: IconName;
    mode: ButtonMode;
    size: ButtonSize;
    rounded: boolean;
    hue: Hue;
    shade: Shade;
    color?: ColorToken;
    border?: 'none' | 'x-small';
    borderHue?: Hue;
    borderShade?: Shade;
    iconSpace?: ButtonSize | 'none';
    iconSize?: ButtonSize;
    iconHue?: Hue;
    iconShade?: Shade;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler;
    tooltip?: string;
}

interface SynchronousProps {
    async?: false;
}

interface AsynchronousProps {
    async: true;
    doing?: boolean;
    done?: boolean;
    error?: boolean;
    labels: AsyncLabels;
}

export interface AsyncLabels {
    doing: string;
    done: string;
    error: string;
}

export type AsyncProps = SynchronousProps | AsynchronousProps;

const Base = styled.button<ButtonProps>`
    ${applyRoundedCorners}
    ${({ theme, size }) => {
        switch (size) {
            case 'x-small':
            case 'small':
                return applyPadding({ theme, padding: [0.5, 1] });
            case 'medium':
                return applyPadding({ theme, padding: [1, 2] });
            case 'med-large':
                return applyPadding({ theme, padding: [1.5, 3] });
            case 'large':
                return applyPadding({ theme, padding: [2, 3] });
        }
    }};

    // type
    ${({ theme, size }) => {
        switch (size) {
            case 'x-small':
                return applyType({ theme, level: 'body2', bold: true });
            case 'small':
                return applyType({ theme, level: 'small', bold: true });
            case 'large':
                return applyType({ theme, level: 'body1', bold: true });
            default:
                return applyType({ theme, level: 'button', bold: true });
        }
    }};

    // border
    ${({ border }) => {
        switch (border) {
            case 'none':
                return css`
                    border: none;
                `;
            case 'x-small':
                return css`
                    border: 1px solid transparent;
                `;
            default:
                return css`
                    border: 1px solid transparent;
                `;
        }
    }};
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: ${({ align }) =>
        align === 'center' ? 'center' : 'space-between'};
    flex-direction: ${({ align }) =>
        align === 'left' || align === 'center' ? 'row' : 'row-reverse'};
    transition: background-color 0.2s;
    background: transparent;
    white-space: nowrap;
    position: relative;

    ${({ theme, iconSpace, icon }) => {
        if (icon) {
            switch (iconSpace) {
                default:
                case 'x-small':
                    return applyGap({ theme, size: 1 });
                case 'small':
                    return applyGap({ theme, size: 2 });
                case 'medium':
                    return applyGap({ theme, size: 3 });
                case 'large':
                    return applyGap({ theme, size: 4 });
                case 'none':
                    return;
            }
        }
        return applyMargin({ theme, margin: 0 });
    }};

    ${({ disabled }) =>
        !disabled &&
        css`
            :hover {
                cursor: pointer;
                transition: background-color 0.2s;
            }
        `}
`;

const Solid = styled(Base)<ButtonProps>`
    color: #fff;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.25;
        `};

    ${({ theme, hue, shade, disabled, borderHue, borderShade, color }) =>
        color ? css `background: var(--${color});` :
        applyBg({
            theme,
            hue: borderHue ?? hue,
            shade: disabled ? '7' : borderShade ?? shade
        })}
    :hover {
        ${({ theme, hue, disabled, borderHue }) =>
            !disabled && applyBg({ theme, hue: borderHue ?? hue, shade: '5' })};
    }
`;

const Text = styled(Base)<ButtonProps>`
    color: ${({ theme, hue, shade, disabled }) =>
        palette({ theme, hue, shade: disabled ? '7' : shade })};

    :hover {
        ${({ theme, hue, disabled }) =>
            !disabled && applyBg({ theme, hue, shade: '9', alpha: 0.25 })}
    }
`;

const Outline = styled(Text)<ButtonProps>`
    border-color: ${({ theme, hue, disabled, borderHue, borderShade }) =>
        alpha({
            theme,
            hue: borderHue ?? hue,
            shade: disabled ? '8' : borderShade ?? '7',
            alpha: 0.2
        })};
`;

const Floating = styled(Solid)<ButtonProps>`
    ${({ theme }) => applyPadding({ theme, padding: [0.5, 2] })};
    border-radius: 100vh;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
`;

const Pale = styled(Base)<ButtonProps>`
    color: ${({ hue, shade }) => mix.palette({ hue, shade })};
    ${({ theme, hue }) => applyBg({ theme, hue, shade: '10' })};

    :hover {
        ${({ theme, hue }) => applyBg({ theme, hue, shade: '9' })}
    }
`;

const Modes = {
    solid: Solid,
    outline: Outline,
    text: Text,
    floating: Floating,
    pale: Pale
};

type ButtonIconProps = Pick<
    ButtonProps,
    | 'icon'
    | 'mode'
    | 'hue'
    | 'spin'
    | 'shade'
    | 'iconSize'
    | 'iconHue'
    | 'iconShade'
>;

const maybeRenderIcon = ({
    spin,
    icon,
    mode,
    hue,
    shade,
    iconSize,
    iconHue,
    iconShade
}: ButtonIconProps) => {
    let size = undefined;
    switch (iconSize) {
        case 'x-small':
            size = 1;
            break;
        case 'small':
            size = 2;
            break;
        case 'medium':
            size = 3;
            break;
        case 'large':
            size = 4;
            break;
    }
    if (icon) {
        let icolor: Hue;
        if (iconHue) {
            icolor = iconHue;
        } else if (['solid', 'floating'].includes(mode)) {
            icolor = 'white';
        } else {
            icolor = hue;
        }

        const ishade = iconShade ?? shade ?? '5';

        return (
            <Icon
                spin={spin}
                icon={icon}
                hue={icolor}
                shade={ishade}
                size={size}
                opacity={1}
                clickable
            />
        );
    }
    return null;
};

export const Button = (props: Partial<ButtonProps> & AsyncProps) => {
    const {
        id,
        children,
        icon,
        disabled = false,
        confirm = false,
        spin = false,
        align = 'left',
        mode = 'solid',
        hue = 'primary',
        rounded = false,
        size = 'medium',
        shade = '6',
        border,
        iconSpace = 'x-small',
        iconSize = 'small',
        iconHue,
        iconShade,
        borderHue,
        borderShade,
        onClick = () => null,
        async,
        tooltip,
        color
    } = props;
    const [hovering, setHovering] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const handleClick: React.MouseEventHandler = (e) => {
        if (disabled) {
            return;
        }

        if (!confirm || confirming) {
            timeoutId && clearTimeout(timeoutId);
            setConfirming(false);
            onClick(e);
            return;
        }

        setConfirming(true);

        setTimeoutId(
            setTimeout(() => {
                setConfirming(false);
            }, 3000)
        );
    };

    const label = () => {
        if (confirming) {
            return 'Are you sure?';
        }

        if (!async) {
            return children;
        }

        const { doing, done, error, labels } = props;

        if (doing) {
            return labels.doing;
        }

        if (done) {
            return labels.done;
        }

        if (error) {
            return labels.error;
        }

        return children;
    };

    const iconProps = (): ButtonIconProps => {
        const defaults = {
            icon,
            mode,
            hue,
            shade,
            spin,
            iconHue,
            iconShade,
            iconSize
        };

        if (!icon) {
            return defaults;
        }

        if (!async) {
            return defaults;
        }

        const { doing, done, error } = props;

        if (doing) {
            return {
                ...defaults,
                spin: true,
                icon: 'Loader'
            };
        }

        if (done) {
            return {
                ...defaults,
                spin: false,
                icon: 'CheckCircle'
            };
        }

        if (error) {
            return {
                ...defaults,
                spin: false,
                icon: 'AlertTriangle'
            };
        }

        return defaults;
    };

    const Wrapper = Modes[mode];
    return (
        <Wrapper
            data-testid={id}
            data-cy={id}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            {...{
                mode,
                hue,
                shade,
                rounded,
                align,
                onClick: handleClick,
                disabled,
                size,
                border,
                icon,
                iconSpace,
                iconSize,
                borderHue,
                borderShade,
                color
            }}
        >
            {tooltip && (
                <Tooltip
                    size="small"
                    width={18}
                    shade="1"
                    align="center"
                    open={hovering}
                >
                    {tooltip}
                </Tooltip>
            )}
            {maybeRenderIcon(iconProps())}
            <ButtonInner iconSpace={iconSpace} icon={!!icon} align={align}>
                {label()}
            </ButtonInner>
        </Wrapper>
    );
};

const ButtonInner = styled.div<{
    iconSpace?: string;
    icon: boolean;
    align: Align;
}>`
    ${({ align }) => {
        if (align !== 'center') return `flex: 1 1 100%;`;
        return;
    }}

    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
`;
