import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { initialized, unauthenticated } from '../events';
import { AuthState, AuthStatus } from '$state/types';
import * as api from '$api';
import { InitPayload } from '$api';

export const init = createAsyncThunk(
    'init',
    async (_, ThunkAPI): Promise<InitPayload> => {
        try {
            const payload = await api.init();
            ThunkAPI.dispatch(initialized(payload));
            return payload;
        } catch (e) {
            ThunkAPI.dispatch(unauthenticated());
            throw e;
        }
    }
);

export const logout = createAsyncThunk('unauth', (_, ThunkAPI) => {
    return Promise.resolve().then(() => ThunkAPI.dispatch(unauthenticated()));
});

const initialState: AuthState = {
    status: AuthStatus.UNAUTHED,
    error: null
};

export default createReducer<AuthState>(initialState, (builder) => {
    builder.addCase(init.fulfilled, (state, action) => {
        state.status = AuthStatus.AUTHED;
        state.role = action.payload.role;
        state.txTypes = action.payload.tx_types;
        state.subscriberId = action.payload.subscriber_id;
        state.subscriberHash = action.payload.subscriber_hash;
    });

    builder.addCase(init.rejected, () => initialState);

    builder.addCase(logout.pending, () => initialState);

    return builder;
});
