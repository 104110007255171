import {mix} from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import {Button} from '$ui/Flo/Button';
import {Controller, FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {TimeField} from '$ui/Flo/TimeField';
import {dates} from '$utils';
import {roundToNearestMinutes} from 'date-fns';
import z from 'zod';
import {Icon} from '$ui/Flo/Icon';
import {DateField, Label, TextareaField} from '$ui/Flo/FormFields';
import {isoDate} from '@/utils/date';

export interface Snooze {
    endsAt: string;
    comment?: string | null;
}

interface Props {
    onSnooze: (snooze: Snooze) => void;
}

interface SnoozeFormArgs {
    endsAtDate: string;
    endsAtTime: string;
    comment?: string | null;
}

const SnoozeSchema = z.object({
    endsAt: z.coerce.date().min(new Date(), {message: 'Please enter a date in the future.'})
});

export const SnoozeForm = ({onSnooze}: Props) => {
    const currentDateTime = new Date();
    // Adding 15 minutes to always display feature timeslot
    const featureTime = new Date(currentDateTime).setMinutes(currentDateTime.getMinutes() + 15);

    const [error, setError] = React.useState<string[] | undefined>(undefined);

    const ref = React.useRef<HTMLFormElement>(null);
    const form = useForm<SnoozeFormArgs>({
        defaultValues: {
            endsAtDate: dates.isoDate(currentDateTime)
        }
    });

    const watchDateChange = form.watch('endsAtDate');

    const submitHandler: SubmitHandler<SnoozeFormArgs> = (data) => {
        const offset = dates.offset(currentDateTime);
        const submitData = {
            endsAt: `${data.endsAtDate}T${data.endsAtTime}.000${offset}`,
            comment: data.comment || null
        };

        const result = SnoozeSchema.safeParse(submitData);
        result.success ? onSnooze(submitData) : setError(result.error.formErrors.fieldErrors.endsAt);
    };

    const limitTime = (selectedDate: string): Date | undefined => {
        return selectedDate === isoDate(currentDateTime)
            ? currentDateTime
            : undefined;
    }

    return (
        <FormProvider {...form}>
            <FormControl ref={ref} onSubmit={form.handleSubmit(submitHandler)}>
                <ControlContainer>
                    <Label text="Snooze patient until"/>
                    <Row>
                        <Col>
                            <DateField name="endsAtDate" min={currentDateTime}/>
                        </Col>
                        <Col>
                            <Controller
                                render={({field}) => (
                                    <TimeField
                                        value={field.value}
                                        onChange={field.onChange}
                                        interval={15}
                                        scale="medium"
                                        variant="grey"
                                        min={limitTime(watchDateChange)}
                                    />
                                )}
                                name="endsAtTime"
                                control={form.control}
                                defaultValue={dates.militaryTime(
                                    roundToNearestMinutes(featureTime, {
                                        nearestTo: 15
                                    })
                                )}
                            />
                        </Col>
                    </Row>
                    {error && (
                        <Row>
                            <ErrorContainer error={error}/>
                        </Row>
                    )}
                </ControlContainer>
                <ControlContainer>
                    <Label text="Why are you snoozing the patient?"/>
                    <TextareaField name="comment"/>
                </ControlContainer>
                <Button
                    rounded
                    icon="Moon"
                    align="center"
                    shade="6"
                    id="snooze-form-button"
                >
                    Snooze
                </Button>
            </FormControl>
        </FormProvider>
    );
};

const FormControl = styled.form`
    display: flex;
    flex-direction: column;
    ${mix.gap({size: 3})};
`;

const ControlContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({size: 1})};
`;

const Row = styled.div`
    display: flex;
    ${mix.gap({size: 1.5})};
`;

const Col = styled.div`
    flex: 1 1;
`;

interface ErrorContainerProps {
    error?: string[];
}

const ErrorContainer = ({error}: ErrorContainerProps) => {
    return (
        <StyledErrorContainer>
            {error && (
                <ErrorMessage>
                    <Icon hue="red" size={2} icon="AlertCircle"/>
                    {error}
                </ErrorMessage>
            )}
        </StyledErrorContainer>
    );
};

const StyledErrorContainer = styled.div`
    ${mix.margin({margin: [0, 0, 0.5]})};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ErrorMessage = styled.div`
    ${mix.type({level: 'small'})};
    color: ${mix.palette({hue: 'red', shade: '4'})};
    display: flex;
    align-items: center;
    gap: ${mix.unit({size: 0.5})};
`;
