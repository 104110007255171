import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';

interface Props {
    integration: string;
    syncing?: boolean;
}

export const SyncStatus = (props: Props) => {
    const { integration, syncing = false } = props;
    return (
        <>
            <Container>
                <Heading level="body1" bold>
                    Leadflo
                </Heading>
                {syncing ? <SyncingBar /> : <IdleBar />}
                <Heading level="body1" bold>
                    {integration}
                </Heading>
            </Container>
            {syncing && (
                <Heading level="body2" profile="secondary">
                    Leadflo is syncing with {integration}. This may take a few
                    minutes.
                </Heading>
            )}
        </>
    );
};

const Container = styled.div`
    ${mix.gap({ size: 2 })};
    display: flex;
    align-items: center;
    ${mix.margin({ margin: [0, 0, 1, 0] })}
`;

const IdleBar = styled.div`
    ${mix.bg({ hue: 'green', shade: '6' })};
    ${mix.rect({ width: 39, height: 0.5 })};
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before,
    &::after {
        ${mix.bg({ hue: 'green', shade: '6' })};
        ${mix.sq({ size: 2 })};
        content: '';
        display: block;
        border-radius: ${mix.unit({ size: 2 })};
    }
`;

// TODO: Create a flow package with animations
const shine = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`;

const SyncingBar = styled.div`
    ${mix.bg({ hue: 'primary', shade: '10' })};
    ${mix.rect({ width: 39, height: 2 })};
    position: relative;
    overflow: hidden;
    border-radius: ${mix.unit({ size: 2 })};

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
                90deg,
                transparent,
                ${mix.alpha({ hue: 'primary', shade: '6', alpha: 0.5 })},
                transparent
            )
            transparent;
        animation: ${shine} 1s infinite;
    }
`;
