import { ColorSystem } from '$ui/Flo/types';
import { createGlobalStyle } from 'styled-components';

export const color: ColorSystem = {
    primary: {
        1: 'hsla(225, 48%, 13%, 1)',
        2: 'hsla(224, 36%, 27%, 1)',
        3: 'hsla(226, 70%, 37%, 1)',
        4: 'hsla(218, 71%, 44%, 1)',
        5: 'hsla(215, 72%, 48%, 1)',
        6: 'hsla(212, 80%, 52%, 1)',
        7: 'hsla(210, 92%, 65%, 1)',
        8: 'hsla(211, 80%, 79%, 1)',
        9: 'hsla(206, 100%, 85%, 1)',
        10: 'hsla(208, 93%, 94%, 1)',
    },

    accent: {
        1: 'hsla(178, 100%, 8%, 1)',
        2: 'hsla(178, 100%, 18%, 1)',
        3: 'hsla(178, 100%, 22%, 1)',
        4: 'hsla(182, 100%, 30%, 1)',
        5: 'hsla(183, 100%, 35%, 1)',
        6: 'hsla(184, 100%, 40%, 1)',
        7: 'hsla(184, 100%, 44%, 1)',
        8: 'hsla(182, 77%, 70%, 1)',
        9: 'hsla(181, 74%, 82%, 1)',
        10: 'hsla(180, 73%, 93%, 1)',
    },

    red: {
        1: 'hsla(354, 50%, 10%, 1)',
        2: 'hsla(354, 50%, 22%, 1)',
        3: 'hsla(354, 70%, 38%, 1)',
        4: 'hsla(354, 71%, 43%, 1)',
        5: 'hsla(354, 71%, 48%, 1)',
        6: 'hsla(354, 80%, 55%, 1)',
        7: 'hsla(354, 73%, 69%, 1)',
        8: 'hsla(354, 59%, 78%, 1)',
        9: 'hsla(354, 57%, 87%, 1)',
        10: 'hsla(353, 61%, 92%, 1)',
    },

    green: {
        1: 'hsla(140, 54%, 10%, 1)',
        2: 'hsla(140, 54%, 22%, 1)',
        3: 'hsla(140, 46%, 33%, 1)',
        4: 'hsla(140, 45%, 38%, 1)',
        5: 'hsla(140, 46%, 43%, 1)',
        6: 'hsla(140, 46%, 51%, 1)',
        7: 'hsla(140, 47%, 60%, 1)',
        8: 'hsla(139, 47%, 66%, 1)',
        9: 'hsla(139, 48%, 85%, 1)',
        10: 'hsla(139, 61%, 94%, 1)',
    },

    yellow: {
        1: 'hsla(36, 93%, 10%, 1)',
        2: 'hsla(36, 93%, 22%, 1)',
        3: 'hsla(48, 100%, 40%, 1)',
        4: 'hsla(48, 99%, 43%, 1)',
        5: 'hsla(48, 99%, 47%, 1)',
        6: 'hsla(48, 100%, 50%, 1)',
        7: 'hsla(48, 85%, 68%, 1)',
        8: 'hsla(48, 82%, 77%, 1)',
        9: 'hsla(48, 72%, 85%, 1)',
        10: 'hsla(43, 59%, 91%, 1)',
    },

    grey: {
        1: 'hsla(0, 0%, 10%, 1)',
        2: 'hsla(0, 0%, 30%, 1)',
        3: 'hsla(0, 0%, 38%, 1)',
        4: 'hsla(0, 0%, 48%, 1)',
        5: 'hsla(0, 0%, 57%, 1)',
        6: 'hsla(0, 0%, 70%, 1)',
        7: 'hsla(0, 0%, 74%, 1)',
        8: 'hsla(0, 0%, 80%, 1)',
        9: 'hsla(0, 0%, 90%, 1)',
        10: 'hsla(0, 0%, 94%, 1)',
    },

    type: {
        dark: {
            title: 'hsla(0, 0%, 100%, 0.85)',
            body: 'hsla(0, 0%, 100%, 0.65)',
            secondary: 'hsla(0, 0%, 100%, 0.45)',
            disabled: 'hsla(0, 0%, 100%, 0.35)',
            link: 'hsla(212, 80%, 52%, 1)',
        },

        light: {
            title: 'hsla(0, 0%, 0%, 0.85)',
            body: 'hsla(0, 0%, 0%, 0.65)',
            secondary: 'hsla(0, 0%, 0%, 0.45)',
            disabled: 'hsla(0, 0%, 0%, 0.25)',
            link: 'hsla(212, 80%, 52%, 1)',
        },
    },

    aliased: {
        white: 'hsla(0, 0%, 94%, 1)',
        black: 'hsla(0, 0%, 10%, 1)',
    },
};

export const Palette = createGlobalStyle`
    :root {
        --white: white;
        --black: black;

        --gray-025: #FCFCFD;
        --gray-050: #F9FAFB;
        --gray-100: #F2F4F7;
        --gray-200: #eaecf0;
        --gray-300: #d0d5dd;
        --gray-400: #98a2b3;
        --gray-500: #667085;
        --gray-600: #475467;
        --gray-700: #344054;
        --gray-800: #1d2939;
        --gray-900: #101828;

        --primary-025: #f5faff;
        --primary-050: #eff8ff;
        --primary-100: #d1e9ff;
        --primary-200: #b2ddff;
        --primary-300: #84caff;
        --primary-400: #53b1fd;
        --primary-500: #2e90fa;
        --primary-600: #1570ef;
        --primary-700: #175cd3;
        --primary-800: #1849a9;
        --primary-900: #194185;

        --error-025: #fffbfa;
        --error-050: #fef3f2;
        --error-100: #fee4e2;
        --error-200: #fecdca;
        --error-300: #fda29b;
        --error-400: #f97066;
        --error-500: #f04438;
        --error-600: #d92d20;
        --error-700: #b42318;
        --error-800: #912018;
        --error-900: #7a271a;

        --warning-025: #fffcf5;
        --warning-050: #fffaeb;
        --warning-100: #fef0c7;
        --warning-200: #fedf89;
        --warning-300: #fec84b;
        --warning-400: #fdb022;
        --warning-500: #f79009;
        --warning-600: #dc6803;
        --warning-700: #b54708;
        --warning-800: #93370d;
        --warning-900: #7a2e0e;

        --success-025: #f6fef9;
        --success-050: #ecfdf3;
        --success-100: #d1fadf;
        --success-200: #a6f4c5;
        --success-300: #6ce9a6;
        --success-400: #32d538;
        --success-500: #12b76a;
        --success-600: #039855;
        --success-700: #027a48;
        --success-800: #05603a;
        --success-900: #054f31;
    }
`;
