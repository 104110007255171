import { PhoneCall } from '$types/patient';
import * as React from 'react';
import * as Parts from './Parts';
import { Call as PhoneCallV2 } from '$types/timeline-v2';

interface Props {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
    call: PhoneCall | PhoneCallV2;
}

export const PhoneCallEvent = (props: Props) => {
    const { call } = props;

    const status: string = call.status.toLowerCase();

    return (
        <Parts.Container inbound>
            <Parts.Avatar color="gray-100" inbound {...props} />
            <Parts.Event
                inbound
                data-cy="comm"
                data-testid={call.id}
                title="Timeline PhoneCall event"
            >
                <Parts.Content type={call.type} color="gray-100" inbound>
                    {call.text_content}
                </Parts.Content>
                <Parts.Metadata inbound type={call.comm_type} icon="PhoneCall">
                    <Parts.Status
                        inbound
                        status={status}
                        date={call.datetime}
                    />
                </Parts.Metadata>
            </Parts.Event>
        </Parts.Container>
    );
};
