import React from 'react';
import {
    GmailManage,
    GmailRepair,
    GmailSetup,
    Loading
} from '$ui/Integrations/Workflows';
import { useWorkflowSwitch } from '$ui/Integrations/Workflows/workflows';

export const ConnectedGmail = () => {
    const { integration, loadingStatus, commonProps } =
        useWorkflowSwitch('gmail');

    if (loadingStatus !== 'loaded') {
        return <Loading integration="Gmail" onFinish={commonProps.onFinish} />;
    }

    if (integration.state === 'healthy') {
        return <GmailManage {...commonProps} />;
    }

    if (integration.state === 'unhealthy') {
        return <GmailRepair {...commonProps} />;
    }

    return <GmailSetup {...commonProps} />;
};

export * from './GmailManage';
export * from './GmailRepair';
export * from './GmailSetup';
