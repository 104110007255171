import { createMachine } from 'xstate';

export const signatureMachine = createMachine({
    predictableActionArguments: true,
    id: 'signature',
    initial: 'init',
    states: {
        init: {
            on: {
                generated: { target: '#signature.generated' },
                custom: { target: '#signature.custom.init' },
                disable: { target: '#signature.generated' }
            }
        },
        generated: {
            on: {
                modify: { target: '#signature.editing.init' }
            }
        },
        custom: {
            initial: 'init',
            states: {
                init: {
                    on: {
                        edit: { target: '#signature.editing.init' },
                        disable: { target: '#signature.custom.disabling' }
                    }
                },
                disabling: {
                    on: {
                        done: { target: '#signature.custom.disabled' }
                    }
                },
                disabled: {
                    after: {
                        1000: { target: '#signature.generated' }
                    }
                }
            }
        },
        editing: {
            initial: 'init',
            states: {
                init: {
                    on: {
                        validate: {
                            target: '#signature.previewing.init'
                        },
                        cancel: { target: '#signature.custom.init' },
                        disable: { target: '#signature.generated' }
                    }
                }
            }
        },
        previewing: {
            initial: 'init',
            states: {
                init: {
                    on: {
                        cancel: { target: '#signature.editing.init' },
                        save: { target: '#signature.previewing.saving' }
                    }
                },
                saving: {
                    on: {
                        cancel: { target: '#signature.editing.init' },
                        done: { target: '#signature.previewing.saved' }
                    }
                },
                saved: {
                    after: {
                        1000: { target: '#signature.custom' }
                    }
                }
            }
        }
    }
});

export default signatureMachine;
