import { mix } from '$ui/Flo/util';
import styled from 'styled-components';

interface EventProps {
    inbound?: boolean;
}

export const Event = styled.div<EventProps>`
    max-width: ${mix.unit({ size: 52 })};
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 0.5 })};
    width: max-content;
`;
