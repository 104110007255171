import { OverviewReport, PipelineReport } from '$state/types';
import { api } from './api';

interface ReportPayload<T> {
    current: T;
    previous: T;
    epoch: string;
}

export type PipelineReportPayload = ReportPayload<PipelineReport>;

export type OverviewReportPayload = ReportPayload<OverviewReport>;

const getReport =
    <T>(uri: string) =>
    (
        from: string,
        to: string,
        types: string[] | null,
        labels: string[] | null,
        sources: string[] | null
    ): Promise<T> => {
        return api
            .get(uri, {
                params: {
                    from,
                    to,
                    types: types ? types : undefined,
                    labels: labels ? labels : undefined,
                    sources: sources ? sources : undefined
                }
            })
            .then((res) => res.data);
    };

export const getPipelineReport = getReport<PipelineReportPayload>(
    '/reporting/pipeline'
);

export const getOverviewReport = getReport<OverviewReportPayload>(
    '/reporting/overview'
);
