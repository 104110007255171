import React from 'react';
import { Frame } from '$ui/Integrations/Frame';
import {
    ButtonGroup,
    Container,
    LogoContainer,
    StatusContainer
} from '$ui/Integrations/Elements';
import { Shadow } from '$ui/Flo/Shadow';

interface Props {
    integration: string;
    onFinish: () => void;
}

export const Loading = ({ integration, onFinish }: Props) => {
    return (
        <Frame
            integration={integration}
            mode="Set up"
            onExit={onFinish}
            back={false}
            onBack={() => null}
            next={false}
            last={false}
            onNext={() => null}
            onFinish={() => null}
            loading
        >
            <Container>
                <LogoContainer>
                    <Shadow rounded width="6.75" height={5.125} />
                </LogoContainer>
                <Shadow rounded width="37" height={3.375} />
                <StatusContainer>
                    <Shadow rounded width="56.375" height={3.375} />
                </StatusContainer>
                <ButtonGroup>
                    <Shadow rounded width="21" height={4.25} />
                    <Shadow rounded width="21.5" height={4.25} />
                </ButtonGroup>
            </Container>
        </Frame>
    );
};
