import { useAppSelector } from '@/state';
import { role } from '@/state/queries/auth';
import { navConfig } from '$state/queries/app';
import * as React from 'react';
import styled from 'styled-components';
import { Item, Navigation } from '../Nav';
import { Theme } from '../Theme';
import { applyBg, mix } from '$ui/Flo/util';
import { selectPendingBatches } from '$state/concerns/bulk';
import { ConnectedBulkStatus } from '$ui/Patients/BulkStatus';

interface Props {
    navigate: (dest: string) => void;
    signOut: () => void;
    currentRoute: string;
    alert: string;
}

export const Sidebar = (props: Props) => {
    const { navigate, signOut, currentRoute, alert } = props;
    const userRole = useAppSelector(role);
    const nav = useAppSelector(navConfig);
    const batches = useAppSelector(selectPendingBatches);

    return (
        <Theme>
            <Container className="nav-bar nav-bar--v3">
                <Navigation
                    navigate={navigate}
                    data={nav}
                    role={userRole}
                    currentRoute={currentRoute}
                />

                <div>
                    {batches.map((batchId) => (
                        <StatusContainer key={batchId}>
                            <ConnectedBulkStatus batchId={batchId} />
                        </StatusContainer>
                    ))}
                    <Item
                        title="Settings"
                        icon="Settings"
                        selected={currentRoute.includes('settings')}
                        key="settings"
                        alert={alert === 'settings'}
                        onClick={() => navigate('/settings/practice')}
                    />
                    <Item title="Logout" icon="ArrowLeft" onClick={signOut} />
                </div>
            </Container>
        </Theme>
    );
};

const Container = styled.div`
    ${({ theme }) => applyBg({ theme, hue: 'primary', shade: '2' })}
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StatusContainer = styled.div`
    ${mix.padding({ padding: 2 })};
    ${mix.bg({ hue: 'primary', shade: '1', alpha: 0.25 })};
    margin-top: 1px;
`;
