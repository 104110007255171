import React from 'react';
import { Patient } from '$types/patients';
import { AsyncStatus } from '$ui/Flo/types';
import { Table } from '$ui/Flo/Table';
import { Empty } from './Empty';
import { Button } from '$ui/Flo/Button';
import styled from 'styled-components';
import { Check } from '$ui/Flo/Check';
import { useAppDispatch, useAppSelector } from '$state';
import { openPatient } from '$state/concerns/patient';
import { BulkSelection } from '$state/types/bulk';
import * as bulk from '$state/concerns/bulk';
import { Text } from '$ui/Flo/Text';
import { selectClientCountry } from '$state/concerns/client';
import { CountryCode } from '@/utils/phone';
import { phone } from '$utils';

interface ConnectedContentProps {
    status: AsyncStatus;
    data: Patient[];
}

/**
 * Responsible for wiring up the patient lists table content to state, e.g
 * selecting patients for bulk actions.
 */
export const ConnectedContent = (props: ConnectedContentProps) => {
    const { status, data } = props;

    const dispatch = useAppDispatch();

    const selected = useAppSelector(bulk.selectSelected);
    const country = useAppSelector(selectClientCountry);

    const onToggleAll = () => {
        dispatch(bulk.toggleAll());
    };

    const onTogglePatient = (patientId: string) => {
        dispatch(bulk.togglePatient(patientId));
    };

    // Clear selection when new data loaded so that patients that don't match
    // the query are not still selected.
    React.useEffect(() => {
        dispatch(bulk.clear());
    }, [status === 'loaded']);

    const onView = (patientId: string) => {
        dispatch(openPatient({ patientId }));
    };

    return (
        <Content
            status={status ?? 'idle'}
            data={data}
            onView={onView}
            selected={selected}
            country={country}
            onToggleAll={onToggleAll}
            onTogglePatient={onTogglePatient}
        />
    );
};

interface ContentProps extends ConnectedContentProps {
    onView: (patientId: string) => void;
    selected: BulkSelection;
    country?: CountryCode;
    onToggleAll: () => void;
    onTogglePatient: (patientId: string) => void;
}

const columns = [
    { name: 'Email' },
    { name: 'Name' },
    { name: 'Phone', width: '20%' },
    { name: 'Stage', width: '15.75%' },
    { name: 'Type', width: '10%' },
    { name: '', width: '82px' },
];

const EmptyInitial = () => <Empty initial />;
const EmptyNothing = () => <Empty />;

export const Content = (props: ContentProps) => {
    const {
        status,
        data,
        onView,
        onToggleAll,
        onTogglePatient,
        selected,
        country = CountryCode.GB,
    } = props;

    const rows = data.map((patient) => {
        const row = [
            patient.email,
            patient.name,
            phone.format(patient.phone, country),
            patient.stage,
            patient.type,
            <Button
                rounded
                key={patient.patient_id}
                onClick={() => onView(patient.patient_id)}
                size="x-small"
            >
                <Text level="small" profile="title" dark>
                    View
                </Text>
            </Button>,
        ];

        const checked =
            selected === 'all' ||
            (Array.isArray(selected) && selected.includes(patient.patient_id));

        return [
            <Check
                checked={checked}
                disabled={selected === 'all'}
                key={`checkbox-${patient.patient_id}`}
                size="small"
                theme="blue"
                onChange={() => {
                    onTogglePatient(patient.patient_id);
                }}
            />,
            ...row,
        ];
    });

    const columnsWithCheckbox = () => {
        return [
            {
                name: (
                    <Check
                        theme="blue"
                        size="small"
                        onChange={onToggleAll}
                        checked={selected === 'all'}
                    />
                ),
                width: '24px',
            },
            ...columns,
        ];
    };

    const patientIdByIndex = {};
    data.forEach((patient, index) => {
        patientIdByIndex[patient.patient_id] = index;
    });

    const selectedRows = (): Array<number> => {
        if (selected === null) {
            return [];
        }

        if (selected === 'all') {
            return Object.values(patientIdByIndex);
        }

        return selected.map((patientId) => patientIdByIndex[patientId]);
    };

    return (
        <Container>
            <Table
                status={status}
                freezeLast
                columns={columnsWithCheckbox()}
                empty={EmptyNothing}
                initial={EmptyInitial}
                selected={selectedRows()}
            >
                {rows}
            </Table>
        </Container>
    );
};

const Container = styled.div`
    background: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1 0;
`;
