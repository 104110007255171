import {
    createAction,
    createAsyncThunk,
    createReducer,
} from '@reduxjs/toolkit';
import { ConfigState, ConfigStatus } from '../types';
import { RootState } from '$state';

/*
 * Contrived example loading the injected environment config using Redux
 * Toolkit, demonstrating utilities that help implement common patterns that
 * emerge when using vanilla Redux actions, thunks and reducers.
 */

export const fetchConfig = createAsyncThunk('config/fetch', async () => {
    const res = await fetch('/config.json');
    return res.json();
});

export const refresh = createAction<void>('config/refresh');

export const setApiVersion = createAction<string>('config/set-version');

const initialState: ConfigState = {
    status: ConfigStatus.IDLE,
    refreshing: false,
};

export default createReducer<ConfigState>(initialState, (builder) =>
    builder
        .addCase(fetchConfig.pending, (state) => {
            state.status = ConfigStatus.LOADING;
        })
        .addCase(fetchConfig.fulfilled, (state, action) => {
            state.status = ConfigStatus.LOADED;
            state.config = action.payload;
        })
        .addCase(setApiVersion, (state, action) => {
            state.api_version = action.payload;
        })
        .addCase(refresh, (state) => {
            state.refreshing = true;
        }),
);

export const refreshing = ({ config }: RootState) => config.refreshing;
