import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { useHover } from '@/utils/hooks';
import { Tooltip } from '$ui/Flo/Tooltip';

export const Automation = () => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    return <IconHolder ref={hoverRef}>
        <Tooltip
            align="right"
            shade={'1'}
            size="x-small"
            open={hover}
        >
            Sent by a Leadflo automation
        </Tooltip>
        <Icon icon="CommTypeLeadFlo" opacity={1} size={2.75}></Icon>
    </IconHolder>
};

const IconHolder = styled.div`
    position: absolute;
    left: -${mix.unit({ size: 0.25 })};
    bottom: -${mix.unit({ size: 1.25 })};
    z-index: 99;
`;
