import * as api from '$api/dentally/match';
import { RootState } from '$state/store';
import {
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';
import { GetParams } from './match';

export const getSuggestions = createAsyncThunk(
    'dentally/suggestion/get',
    ({ params }: GetParams): Promise<api.MatchResponse> =>
        api.getMatches(params)
);

export interface SuggestionStore {
    [patientId: string]: SuggestionState;
}

export interface SuggestionState {
    status: string;
    data?: api.MatchResponse['data'];
    total?: api.MatchResponse['total'];
}

const initialState: SuggestionStore = {};

export default createReducer(initialState, (builder) => {
    builder.addCase(getSuggestions.pending, (state, action) => {
        const { arg: getParams } = action.meta;
        state[getParams.patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(getSuggestions.fulfilled, (state, action) => {
        const { arg: getParams } = action.meta;
        state[getParams.patientId] = {
            status: 'loaded',
            data: action.payload['matches'],
            total: action.payload['total']
        };
    });

    builder.addCase(getSuggestions.rejected, (state, action) => {
        const { arg: getParams } = action.meta;
        state[getParams.patientId] = {
            status: 'error'
        };
    });
});

const suggestionState = (state: RootState) => state.suggestion;

export const selectSuggestionData = createSelector(
    [(_, patientId: string) => patientId, suggestionState],
    (patientId, suggestion: SuggestionStore) => {
        return suggestion[patientId]?.data;
    }
);

export const selectSuggestionStatus = createSelector(
    [(_, patientId: string) => patientId, suggestionState],
    (patientId, suggestion: SuggestionStore) => {
        return suggestion[patientId]?.status;
    }
);
