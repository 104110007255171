import * as React from 'react';
import styled from 'styled-components';
import { applyPadding, scale } from '../util';
import { Option } from './Option';

interface Props {
    options: {
        value: string;
        label: string;
    }[];

    onSelect: (value: string) => void;
    initial?: string;
    padding?: number;
}

export const Options = (props: Props) => {
    const { options, onSelect, initial, padding } = props;
    const [selected, setSelected] = React.useState(initial);

    const select = (value: string) => {
        setSelected(value);
        onSelect(value);
    };

    const opts = options.map((opt) => {
        return (
            <Option
                label={opt.label}
                onSelect={() => select(opt.value)}
                selected={selected === opt.value}
                key={opt.label}
            />
        );
    });

    return <Wrapper padding={padding}>{opts}</Wrapper>;
};

const Wrapper = styled.div<{ padding?: number }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${({ theme, padding }) =>
        padding && applyPadding({ theme, padding: [0, padding] })};

    & > * {
        margin-bottom: ${({ theme }) => scale(theme, 2)}px;
    }
`;
