import React from 'react';
import styled, { css } from 'styled-components';
import { Hue, Shade } from '../types';
import { applyBg, applyPadding, applyRoundedCorners } from '../util';

interface BoxProps {
    rounded: boolean;
    inline: boolean;
    padding: number;
    hue: Hue;
    shade: Shade;
    children?: React.ReactNode;
}

const Wrapper = styled.div<BoxProps>`
    ${applyRoundedCorners};
    ${applyBg};
    ${applyPadding}

    ${({ inline }) =>
        inline &&
        css`
            display: inline-block;
        `};
`;

export const Box = (props: Partial<BoxProps>) => {
    const {
        children,
        rounded = false,
        padding = 2,
        shade = '6',
        hue = 'grey',
        inline = false
    } = props;

    return (
        <Wrapper {...{ inline, padding, shade, hue, rounded }}>
            {children}
        </Wrapper>
    );
};
