// Legacy Styles
import './scss/main.scss';

// React
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactApp } from './ReactApp';

// Sentry
import * as ReactSentry from '@sentry/react';
import * as Integrations from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

// State
import { store } from '$state';
import { logout } from '$state/concerns/auth';
import { baseURL, proxyURL } from '$state/queries/config';
import { fetchConfig, refreshing, setApiVersion } from '$state/concerns/config';

// Services
import * as auth from '@/auth/fsm';
import versioning from '@/router/service';
import api from './api/api';
import * as ws from './ws/service';

// Polyfills
import 'cross-fetch/polyfill';

// temporary until correct home exists
store.dispatch(fetchConfig());

api.start(() => {
    const state = store.getState();
    return {
        getAuthToken: () => {
            const state = auth.actor.getSnapshot();
            return state.context.details?.token ?? null;
        },
        URL: baseURL(state),
        proxyURL: proxyURL(state),
        onAuthError: () => {
            store.dispatch(logout());
            auth.actor.send({ type: 'logout' });
        },
        setApiVersion: (version) => {
            store.dispatch(setApiVersion(version));
        },
    };
});

versioning.start(() => {
    const state = store.getState();
    return {
        URL: baseURL(state),
        refreshing: refreshing(state),
    };
});

store.subscribe(() => {
    const state = store.getState();

    if (state.config.status !== 'loaded') {
        return;
    }

    const { config } = state.config;

    if (!config) {
        return;
    }

    if (config.environment !== 'development') {
        ReactSentry.init({
            dsn: 'https://a7e2eb4238d84050856bffaa58594834@sentry.io/3731371',
            integrations: [
                new Integrations.Angular(),
                new TracingIntegrations.BrowserTracing(),
            ],
            environment: `${config.environment}:frontend`,
        });
    }

    auth.start();
});

ReactSentry.init({
    integrations: [
        new Integrations.Angular(),
        new TracingIntegrations.BrowserTracing(),
    ],
});

auth.actor.on('unauthenticated', () => {
    store.dispatch(logout());
    ws.stop();
});

auth.actor.on('authenticated', ({ details }) => {
    const { sub, client } = details.payload ?? {};

    if (!sub || !client) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        user_id: sub,
        client_name: client.name,
        created_at: client.created_at,
    });

    // Give some time for Tawk to load
    setTimeout(() => {
        try {
            // Try catch as Tawk may be down and the script
            // wouldn't load, so `Tawk_API` may not be available
            Tawk_API.onLoad = function () {
                Tawk_API.setAttributes(
                    {
                        name: details.email,
                        email: details.email,
                        version: __VERSION__,
                    },
                    (err) => console.log('Failed to add name to Tawk: ', err),
                );
            };
        } catch (e) {
            // deliberately do nothing here
        }
    }, 2000);

    const { config: configState } = store.getState();

    if (configState.config) {
        const { config } = configState;

        ws.start({
            clientId: client.id,
            enabled: config.pusher_enabled,
            key: config.pusher_key,
            cluster: config.pusher_cluster,
            hostname: config.pusher_host,
            port: config.ws_port,
            secure: config.ws_secure,
            authEndpoint: `${config.api_base_url}/broadcasting/auth`,
        });
    }
});

declare global {
    interface Window {
        dataLayer?: any[];
    }
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<ReactApp />);
