import { css } from 'styled-components';
import {
    BoxSize,
    MarginProps,
    NormalBoxSize,
    PaddingProps,
    RectProps,
    Scaled,
    ThemedProps
} from '../types';

const normalizeBoxSize = (padding: BoxSize): NormalBoxSize =>
    typeof padding === 'number' ? [padding] : padding;

const scaleBoxSize = (coeff: number, padding: NormalBoxSize): NormalBoxSize =>
    padding.map((mag: number) => coeff * mag) as NormalBoxSize;

const boxSizeToUnit = (padding: NormalBoxSize, unit = 'px') =>
    padding.map((mag: number) => mag + unit);

export const applyPadding = ({
    theme,
    padding: raw
}: ThemedProps<PaddingProps>) => {
    const padding = boxSizeToUnit(
        scaleBoxSize(theme.unit, normalizeBoxSize(raw))
    );
    return css`
        padding: ${padding.join(' ')};
    `;
};

export const applyMargin = ({
    theme,
    margin: raw
}: ThemedProps<MarginProps>) => {
    const margin = boxSizeToUnit(
        scaleBoxSize(theme.unit, normalizeBoxSize(raw))
    );

    return css`
        margin: ${margin.join(' ')};
    `;
};

export const applyWidth = ({ theme, size }: ThemedProps<Scaled>) => {
    return css`
        width: ${theme.unit * size}px;
    `;
};

export const applyHeight = ({ theme, size }: ThemedProps<Scaled>) => {
    return css`
        height: ${theme.unit * size}px;
    `;
};

export const applyRect = ({
    theme,
    width,
    height
}: ThemedProps<RectProps>) => css`
    width: ${theme.unit * width}px;
    height: ${theme.unit * height}px;
`;

export const applySq = ({ theme, size }: ThemedProps<Scaled>) =>
    applyRect({ theme, width: size, height: size });

export const applyGap = ({ theme, size }: ThemedProps<Scaled>) => {
    return css`
        gap: ${theme.unit * size}px;
    `;
};
