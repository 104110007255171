import { createReducer } from '@reduxjs/toolkit';
import { initialized } from '../events';
import { FeatureState, FeatureStatus } from '../types';

const initialState: FeatureState = {
    status: FeatureStatus.IDLE,
    features: []
};

export default createReducer<FeatureState>(initialState, (builder) => {
    builder.addCase(initialized, (state, action) => {
        state.status = FeatureStatus.LOADED;
        state.features = action.payload.features;
    });

    return builder;
});
