import { IconName } from '$ui/Flo/Icon';

const MIME_TYPE_IMAGES: string[] = [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/tiff',
    'image/avif',
];

const MIME_TYPE_FILES: string[] = [
    'audio/aac',
    'video/x-msvideo',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'audio/mpeg',
    'video/mp4',
    'video/mpeg',
    'application/vnd.oasis.opendocument.text',
    'audio/ogg',
    'audio/ogg; codecs=opus',
    'video/ogg',
    'application/ogg',
    'audio/opus',
    'application/pdf',
    'application/rtf',
    'audio/wav',
    'audio/webm',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'audio/3gpp',
    'audio/3gpp2',
];

const MIME_TYPES = MIME_TYPE_FILES.concat(MIME_TYPE_IMAGES);

/**
 * Check if mime type is image
 * @param mime
 */
export const isImage = (mime: string): boolean =>
    MIME_TYPE_IMAGES.includes(mime);

/**
 * Check if mime type is file
 * @param mime
 */
export const isFile = (mime: string): boolean => MIME_TYPE_FILES.includes(mime);

/**
 * Check that mime is supported
 * @param mime
 */
export const isSupportedMimeType = (mime: string): boolean =>
    MIME_TYPES.includes(mime);

/**
 * Extract format (extension) if it is found
 * @param name
 */
export const extractFormat = (name: string): string => {
    const extension = name.split('.').pop();

    return extension ? extension : '';
};

/**
 * Get icon name from mime type.
 * @param mime
 */
export const getIconNameFromMime = (mime: string): IconName => {
    switch (mime.toLowerCase()) {
        case 'audio/aac':
            return 'NounFileAac';
        case 'video/x-msvideo':
            return 'NounFileAvi';
        case 'application/msword':
            return 'NounFileDoc';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'NounFileDocx';
        case 'audio/mpeg':
            return 'NounFileMp3';
        case 'video/mp4':
            return 'NounFileMp4';
        case 'application/vnd.oasis.opendocument.text':
            return 'NounFileOdt';
        case 'audio/ogg':
        case 'audio/ogg; codecs=opus':
        case 'video/ogg':
        case 'application/ogg':
            return 'NounFileOgg';
        case 'audio/opus':
            return 'NounFileOpus';
        case 'application/pdf':
            return 'NounFilePdf';
        case 'application/rtf':
            return 'NounFileRtf';
        case 'audio/wav':
            return 'NounFileWav';
        case 'audio/webm':
        case 'video/webm':
            return 'NounFileWebm';
        case 'video/3gpp':
        case 'video/3gpp2':
        case 'audio/3gpp':
        case 'audio/3gpp2':
            return 'NounFile3gp';
    }

    return 'NounFileDefault';
};

/**
 * Convert data to a base64 encoded string
 * @param data
 */
export const convertToBase64 = (data: ArrayBuffer) =>
    btoa(
        new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

/**
 * Download file from buffer
 * @param data
 * @param name
 */
export const downloadFromBuffer = (data: ArrayBuffer, name: string): void => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
