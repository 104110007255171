import { mix } from '$ui/Flo/util';
import { Theme } from '$ui/Theme';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

// Highlights merge tags with react components
// in a string template.
// Can optionally set as HTML so that instead the HTML
// string will be modified and the merge tag components inserted in
export const highlightMergeTags = ((
    text: string,
    html = false,
): React.ReactNode => {
    const parts = text.split(/(\{\{ ?[a-zA-Z_.]+ ?}})/);

    const rendered = parts.reduce((result: React.ReactNode[], part) => {
        const matches = part.match(/^\{\{ ?([a-zA-Z_.]+) ?}}$/);

        if (!matches) {
            // Just plain string
            return result.concat(part);
        }

        const key = matches[1].trim();
        const tag = (
            <Theme key={key}>
                <Tag data-testid="merge-tag" key={key}>
                    {key}
                </Tag>
            </Theme>
        );
        return result.concat(html ? renderToStaticMarkup(tag) : tag);
    }, []);
    return html ? rendered.join('') : <>{rendered}</>;
}) as ((text: string, html: true) => string) &
    ((text: string, html?: false) => React.ReactNode);

const Tag = styled.span`
    display: inline;
    ${mix.bg({ hue: 'primary', shade: '6' })}
    ${mix.round({ rounded: true })}
    ${mix.padding({ padding: [0.25, 0.5] })}
    color: white;
`;
