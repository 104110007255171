import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CommTypeWhatsApp = ({ size = 24, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6057_16646)">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#41D366"/>
                <path d="M12 6.73685C10.8947 6.73685 9.81575 7.09211 8.92101 7.73685C6.56575 9.44737 6.03943 12.7368 7.7368 15.079L7.07891 17.0395L9.10522 16.3947C9.96049 16.9605 10.9736 17.2632 12 17.2632C14.9079 17.2632 17.2631 14.9079 17.2631 12C17.2631 9.09211 14.9079 6.73685 12 6.73685ZM14.8026 14.1842L14.2368 14.75C13.6447 15.3421 12.0921 14.6842 10.6973 13.3026C9.30259 11.9211 8.69733 10.3553 9.24996 9.76316L9.81575 9.19737C10.0394 8.98685 10.3815 8.98685 10.6052 9.19737L11.4342 10.0263C11.5 10.0921 11.5394 10.1579 11.5658 10.2368C11.6579 10.5263 11.5131 10.829 11.2236 10.9342C10.9473 11.0263 10.7894 11.3026 10.8684 11.579C11.0131 12.1842 11.8158 12.9605 12.4079 13.1053C12.6842 13.1711 12.9605 13.0132 13.0526 12.75C13.0789 12.6711 13.1315 12.5921 13.1973 12.5263C13.421 12.3158 13.7631 12.329 13.9736 12.5395L14.8026 13.3816C15 13.6184 15 13.9605 14.8026 14.1842Z" fill="white"/>
            </g>
            <rect x="1" y="1" width="22" height="22" rx="11" stroke="white" strokeWidth="2"/>
        </svg>

    );
};
