import { api } from '$api';

export interface LinkResponse {
    id: number;
    date_of_birth: string;
    first_name: string;
    last_name: string;
    middle_name: string | null;
    email_address: string;
    phone: string;
}

export const getLink = (patientId: string): Promise<LinkResponse> =>
    api.get(`/dentally/patients/${patientId}`).then(({ data }) => data);

export const link = (patientId: string, id: number): Promise<void> =>
    api.post(`/dentally/links/`, { patient_id: patientId, id: id });

export const unlink = (patientId: string): Promise<void> =>
    api.delete(`/dentally/links/${patientId}`);
