import * as React from 'react';
import * as Parts from '../Parts';
import { SMS as SMSV2 } from '$types/timeline-v2';

interface Props {
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
    sms: SMSV2;
    retry?: (comm: SMSV2) => void;
    attachmentsEnabled?: boolean;
}

export const SMSEvent = (props: Props) => {
    const { sms } = props;

    return (
        <Parts.Container inbound={sms.inbound}>
            {buildEvent(props)}
        </Parts.Container>
    );
};

const buildEvent = (props: Props) => {
    const { sms, retry, attachmentsEnabled } = props;
    const color = sms.inbound ? 'gray-100' : 'primary-500';
    const status: string = sms.status.toLowerCase();
    const failed = status === 'failed';

    return (
        <>
            <Parts.Avatar
                type="SMS"
                color={color}
                inbound={sms.inbound}
                {...props}
            />
            <Parts.Event role="listitem" data-cy="comm" inbound={sms.inbound}>
                <Parts.Content
                    type={sms.type}
                    inbound={sms.inbound}
                    color={color}
                >
                    {sms.text_content}
                </Parts.Content>

                <Parts.Metadata
                    failed={failed}
                    retrying={sms.retry}
                    retry={() => retry && retry(sms)}
                    inbound={sms.inbound}
                >
                    <Parts.Status
                        status={status}
                        date={sms.datetime}
                        inbound={sms.inbound}
                    />
                </Parts.Metadata>
                {attachmentsEnabled && sms.attachments && (
                    <Parts.Attachments attachments={sms.attachments} />
                )}
            </Parts.Event>
            {(failed || sms.retry?.active) && <Parts.FailedIcon />}
        </>
    );
};
