import { api } from '../api';

type Decimal = string;

interface Invoice {
    id: number;
    date: string;
    amount: Decimal;
}

interface Treatment {
    id: number;
    name: string;
    amount: Decimal;
    quantity: number;
}

export interface FinancialResponse {
    dentallyId: number;
    accountId: number;

    invoices: {
        unpaid: Array<Invoice>;
        paid: Array<Invoice>;
    };

    uninvoiced_treatments: Array<Treatment>;

    total: {
        paid: Decimal;
        unpaid: Decimal;
    };
}

export const getFinancial = (patientId: string): Promise<FinancialResponse> =>
    api
        .get(`/dentally/patients/${patientId}/financials`)
        .then(({ data }) => data);
