import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Heading } from '$ui/Flo/Heading';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { AsyncButton, buildContentMap } from '$ui/Flo/AsyncButton';
import { testAutomation } from '$api';
import { Box } from '$ui/Flo/Box';
import { Text } from '$ui/Flo/Text';

interface Props {
    id: string;
}

const buttonContent = buildContentMap({
    do: 'Test',
    doing: 'Testing',
    done: 'Tested',
    icon: 'Mail'
});

// TODO: This is done everywhere and could be generalised to doing/done/failed
type State = 'init' | 'saving' | 'saved' | 'failed';

export const TestTool = ({ id }: Props) => {
    // TOOD: See if useReducer helps clear this up
    const [input, setInput] = React.useState<string>('');
    const [state, setState] = React.useState<State>('init');
    const [error, setError] = React.useState<string | null>(null);

    const onClick = () => {
        setState('saving');
        testAutomation(id, input)
            .then(() => {
                setError(null);
                setState('saved');
                setTimeout(() => setState('init'), 2_000);
            })
            .catch((reason) => {
                setState('failed');
                setError(reason.response.data.message);
            });
    };

    return (
        <Container>
            <Heading level="subtitle1" align="left">
                Test Automation
            </Heading>
            <Form>
                {error && (
                    <Box hue="red" rounded>
                        <Text profile="title" level="body2" dark>
                            {error}
                        </Text>
                    </Box>
                )}
                <FloatingTextField
                    required
                    label="Patient email address"
                    value={input}
                    onChange={(value) => setInput(value)}
                />
                <div>
                    <AsyncButton
                        mode="solid"
                        hue={state === 'failed' ? 'red' : 'primary'}
                        size="small"
                        content={buttonContent}
                        onClick={onClick}
                        disabled={state !== 'init' && state !== 'failed'}
                        doing={state === 'saving'}
                        done={state === 'saved'}
                        failed={state === 'failed'}
                    />
                </div>
            </Form>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    ${mix.round({ rounded: true })}
    ${mix.shadow()}
    width: 100%;
    ${mix.padding({ padding: 2 })}
`;

const Form = styled.div`
    margin-top: ${mix.unit({ size: 2 })};
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 1 })};
`;
