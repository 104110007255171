import { BusinessEvent as BusinessEventModel } from '$types/patient';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Metadata, Status } from './Parts';
import { Icon, IconName } from '$ui/Flo/Icon';
import * as Parts from '$ui/Timeline/Parts';
import { BusinessEvent as BusinessEventV2 } from '$types/timeline-v2';
import { Hue } from '$ui/Flo/types';

interface Props {
    businessEvent: BusinessEventModel | BusinessEventV2;
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
}

export const BusinessEvent = (props: Props) => {
    const { businessEvent } = props;

    const hasNote = !!businessEvent.note;

    return (
        <Container>
            <Parts.Avatar
                stroke="gray-300"
                color="white"
                inbound={false}
                {...props}
            />
            <Holder>
                <Box hasNote={hasNote}>
                    <Headline>{businessEvent.headline}</Headline>
                    <Desc>{businessEvent.description}</Desc>
                </Box>
                <Info>
                    <MetadataWrapper>
                        <Metadata
                            opacity={1}
                            type={businessEvent.business_event_type.name}
                            icon={
                                businessEvent.business_event_type
                                    .icon as IconName
                            }
                            hue={businessEvent.business_event_type.color as Hue}
                            shade="7"
                            tooltip={businessEvent.business_event_type.tooltip}
                        />
                    </MetadataWrapper>
                    <NoteAndStatus>
                        {hasNote && (
                            <Note hasNote>
                                {businessEvent.note}
                                <NoteFooter>
                                    <Icon icon="FileText" size={1.5} /> Note
                                </NoteFooter>
                            </Note>
                        )}
                        <StatusWrapper>
                            <Status
                                date={businessEvent.datetime}
                                disableIndicator
                            />
                        </StatusWrapper>
                    </NoteAndStatus>
                </Info>
            </Holder>
        </Container>
    );
};

const Container = styled.div`
    max-width: ${mix.unit({ size: 52 })};
    min-width: ${mix.unit({ size: 52 })};
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    width: max-content;
    ${mix.gap({ size: 2 })};
`;

const Holder = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 0.5 })};
`;

const Box = styled.div<{ hasNote: boolean }>`
    ${mix.color({ profile: 'body' })};
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: [2] })};
    display: inline-flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
    border: 1px solid hsla(216, 16%, 84%, 1);
    border-radius: ${({ hasNote }) => (hasNote ? '6px 6px 0 6px' : '6px')};
    width: 100%;
`;

const Headline = styled.div`
    font-size: 16px;
    font-weight: 500;
    display: flex;
    ${mix.color({ profile: 'title' })}
`;

const Desc = styled.div`
    ${mix.type({ level: 'body2' })};
    display: flex;
`;

const Info = styled.div`
    display: flex;
    justify-content: space-between;
    ${mix.gap({ size: 2 })}
`;

const Note = styled.div<{ hasNote: boolean }>`
    ${mix.color({ profile: 'body' })}
    ${mix.type({ level: 'body2' })};
    display: flex;
    flex-direction: column;
    ${mix.bg({ hue: 'yellow', shade: '9' })};
    ${mix.padding({ padding: [1, 2] })};
    border-radius: ${({ hasNote }) => (hasNote ? '0px 0px 6px 6px' : '6px')};
    ${mix.gap({ size: 0.5 })}
`;

const NoteFooter = styled.div`
    ${mix.color({ profile: 'body' })};
    ${mix.type({ level: 'small' })};
    display: flex;
    align-items: center;
    width: 100%;
    ${mix.gap({ size: 0.5 })}
`;

const MetadataWrapper = styled.div`
    height: ${mix.unit({ size: 2.25 })};
    display: flex;
    align-items: center;
`;

const NoteAndStatus = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })}
`;

const StatusWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
