import { SnoozeState } from '$state/types/snooze';
import { isToday, isTomorrow } from 'date-fns';
import { dates } from '$utils';

export const endType = (state: SnoozeState) =>
    state === 'asleep' ? 'Cancel' : 'End';

export const snoozeDate = (endDate?: string) => {
    if (!endDate) return 'today';

    const inputDate = new Date(endDate);

    if (isToday(inputDate)) {
        return 'today';
    } else if (isTomorrow(inputDate)) {
        return 'tomorrow';
    } else {
        return `on ${dates.long(endDate)}`;
    }
};

