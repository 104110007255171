import React, { useEffect } from 'react';
import {
    ButtonTypeEnum,
    IMessage,
    NotificationBell,
    NovuProvider,
    PopoverNotificationCenter,
    useMarkNotificationsAs,
    useSocket
} from '@novu/notification-center';
import { HeaderNotification } from './HeaderNotification';
import toast from 'react-hot-toast';
import { Notification } from '$ui/Notifications/Notification';

interface Props {
    subscriberId: string;
    subscriberHash: string;
    appId?: string;
    onNotificationClick?: (message: IMessage) => void;
}

export const HeaderNotifications = (props: Props) => {
    const { appId, subscriberId, subscriberHash, onNotificationClick } = props;

    if (!appId || !subscriberHash || !subscriberId) {
        return <></>;
    }
    return (
        <NovuProvider
            subscriberId={subscriberId}
            subscriberHash={subscriberHash}
            applicationIdentifier={appId}
            styles={{
                bellButton: {
                    root: { color: '#fff', svg: { color: '#fff' } },
                    dot: {
                        rect: {
                            fill: 'var(--error-500)',
                            stroke: 'rgb(44,57,94)',
                            'stroke-width': '2px',
                            width: '12px',
                            height: '12px'
                        }
                    }
                },
                popover: {
                    arrow: {
                        color: '#000'
                    },
                    dropdown: {
                        color: '#000',
                        zIndex: '900 !important'
                    }
                },
                header: {
                    root: {
                        color: '#fff'
                    }
                },
                layout: {
                    root: {
                        background: '#2B3654',
                        color: '#fff'
                    }
                }
            }}
        >
            <Center onNotificationClick={onNotificationClick} />
        </NovuProvider>
    );
};

interface CenterProps {
    onNotificationClick?: (message: IMessage) => void;
}

const Center = (props: CenterProps) => {
    const { onNotificationClick } = props;

    const { socket } = useSocket();
    const { markNotificationsAs } = useMarkNotificationsAs();

    useEffect(() => {
        if (!socket) {
            return;
        }

        socket.on(
            'notification_received',
            ({ message }: { message: IMessage }) => {
                const { _id, templateIdentifier } = message;

                if (templateIdentifier === 'snooze') {
                    const { payload } = message;

                    toast.custom(
                        ({ visible }) => (
                            <Notification
                                visible={visible}
                                title={
                                    payload.patientFirstName +
                                    ' ' +
                                    (payload.patientLastName ?? '')
                                }
                                action="Snooze ended"
                                time={message.createdAt}
                                icon="Moon"
                                onView={() => {
                                    onNotificationClick?.(message);

                                    markNotificationsAs({
                                        messageId: message._id,
                                        seen: true,
                                        read: true
                                    });

                                    toast.dismiss(_id);
                                }}
                                onClose={() => toast.dismiss(_id)}
                            />
                        ),
                        {
                            id: _id,
                            duration: Infinity,
                            position: 'bottom-right'
                        }
                    );
                }
            }
        );

        socket.on('unread_count_changed', ({ unreadCount, hasMore }) => {
            if (unreadCount === 0 && !hasMore) {
                toast.dismiss();
            }
        });

        return () => {
            socket.off('notification_received');
            socket.off('unread_count_changed');
        };
    }, [socket]);

    return (
        <PopoverNotificationCenter
            onNotificationClick={onNotificationClick}
            colorScheme={'dark'}
            showUserPreferences={false}
            footer={() => <></>}
            listItem={(
                notification: IMessage,
                handleActionButtonClick,
                handleNotificationClick
            ) => {
                const { read } = notification;

                return (
                    <HeaderNotification
                        type={notification.templateIdentifier ?? 'snooze'}
                        content={
                            (notification.payload.actionContent as string) ?? ''
                        }
                        name={
                            notification.payload.patientFirstName +
                            ' ' +
                            (notification.payload.patientLastName ?? '')
                        }
                        time={new Date(notification.createdAt).toISOString()}
                        onView={() => {
                            toast.dismiss(notification._id);
                            handleActionButtonClick(ButtonTypeEnum.PRIMARY);
                            handleNotificationClick();
                        }}
                        read={read}
                    />
                );
            }}
        >
            {({ unseenCount }) => {
                return (
                    <NotificationBell
                        unseenCount={unseenCount}
                        unseenBadgeColor="red"
                    />
                );
            }}
        </PopoverNotificationCenter>
    );
};
