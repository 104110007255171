import { Icon, IconName } from '$ui/Flo/Icon';
import { BoxSize, Hue } from '$ui/Flo/types';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import Select, { SelectInstance } from 'react-select';
import styled, { css } from 'styled-components';

interface Props {
    available: string[] | Option[];
    selected: string[] | string | null;
    onSelect: (values: string | string[] | null) => void;
    plural?: string;
    icon?: IconName;
    searchable?: boolean;
    placeholder?: string;
    multi?: boolean;
    disabled?: boolean;
    iconHue?: Hue;
    padding?: BoxSize;
    dropdownAlign?: 'left' | 'right';
}

interface Option {
    label: string;
    value: string;
}

export const DropdownSelect = (props: Props) => {
    const {
        available,
        selected,
        onSelect,
        plural,
        icon,
        searchable,
        placeholder,
        multi,
        disabled,
        iconHue = 'primary',
        padding = [1],
        dropdownAlign = 'left'
    } = props;

    const ref = React.useRef<SelectInstance<Option, true>>(null);

    const options = (opts: string[] | Option[] | null): Option[] => {
        if (opts === null) {
            return [];
        }
        let selectAll;
        if (selected != null && selected.length === available.length) {
            selectAll = 'Deselect All';
        } else {
            selectAll = 'Select All';
        }
        let options = [...opts];
        if (multi) {
            options = [selectAll, ...opts];
        }

        return options.map((opt) => {
            if (typeof opt === 'string') {
                return {
                    value: opt,
                    label: opt
                };
            }

            return opt;
        });
    };

    const values = (val: string[] | Option[] | string | null): Option[] => {
        if (val === null) {
            return [];
        }

        if (typeof val === 'string') {
            return [
                {
                    value: val,
                    label: val
                }
            ];
        }

        return val.map((opt) => {
            if (typeof opt === 'string') {
                return {
                    value: opt,
                    label: opt
                };
            }

            return opt;
        });
    };

    const onChange = (option: any) => {
        if (!multi) {
            onSelect(option?.value);
            return;
        }

        if (
            option.length > 0 &&
            option.find((item: { value: string }) =>
                item.value.toLowerCase().includes('select all')
            )
        ) {
            option = options(available).slice(1);
            if (selected != null && selected.length === available.length) {
                option = [];
            }
        }
        onSelect(option.map((item: { value: string }) => item.value));
    };

    const onIconClick = () => {
        ref.current?.focus();
    };

    const selectedLabel = options(available).find(
        (item) => item.value === (multi ? selected?.[0] : selected)
    )?.label;

    let refinedPlaceholder;
    if (multi) {
        refinedPlaceholder =
            selected === null ||
            selected.length === available.length ||
            selected.length === 0
                ? `All ${plural}`
                : `${selected.length} ${plural} selected`;
    } else {
        refinedPlaceholder = selectedLabel || placeholder;
    }

    const customStyles = {
        menu: (styles: any) => ({
            ...styles,
            width: 'max-content',
            minWidth: '100%'
        }),
        container: (styles: any) => ({
            ...styles,
            zIndex: '600'
        })
    };

    return (
        <Dropdown
            data-cy={plural}
            padding={padding}
            onClick={onIconClick}
            dropdownAlign={dropdownAlign}
        >
            {icon && (
                <Icon
                    icon={icon}
                    size={2}
                    hue={iconHue}
                    opacity={1}
                    clickable
                />
            )}
            <Select
                ref={ref}
                placeholder={refinedPlaceholder}
                className={'types-dropdownselect'}
                classNamePrefix={'dropdownselect'}
                openMenuOnFocus
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={!multi}
                options={options(available)}
                value={values(selected)}
                isMulti={!!multi}
                onChange={onChange}
                isSearchable={searchable}
                components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                }}
                isDisabled={disabled}
                styles={customStyles}
            />
        </Dropdown>
    );
};

export const Dropdown = styled.div<{
    padding: BoxSize;
    dropdownAlign?: string;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--gray-100);
    ${({ padding }) => mix.padding({ padding })};
    ${mix.round({ rounded: true })}
    cursor: pointer;

    & .types-dropdownselect {
        margin-left: ${mix.unit({ size: 1 })};

        .dropdownselect__control,
        .dropdownselect__control:focus-within {
            outline: none;
            height: auto;
            padding: 0;
            width: auto;
            border: none;
            background: transparent;
            border-radius: 0;
            box-shadow: none;
            min-height: auto;
        }

        .dropdownselect__placeholder,
        .dropdownselect__indicator {
            ${mix.color({ profile: 'body' })}
            position: static;
            transform: none;
            max-width: none;
            padding: 0;
        }

        .dropdownselect__indicator svg {
            width: ${mix.unit({ size: 2 })};
            height: ${mix.unit({ size: 2 })};
            fill: ${mix.palette({ hue: 'grey', shade: '5' })};
        }

        .dropdownselect__input-container,
        .dropdownselect__indicator svg {
            &:hover {
                cursor: pointer;
            }
        }

        .dropdownselect__input,
        .dropdownselect__input:hover {
            cursor: pointer;
        }

        .dropdownselect__input-container {
            margin: 0;
            padding: 0;
        }

        .dropdownselect__value-container {
            padding: 0;
        }
    }

    & .rmsc .gray {
        ${mix.color({ profile: 'body' })}
    }
    ${({ dropdownAlign }) =>
        dropdownAlign === 'right' &&
        css`
            & .dropdownselect__menu {
                left: unset;
                right: 0;
            }
        `}
`;
