import React from 'react';
import { useAppSelector } from '$state/hooks';
import { SectionV2 as Section } from '$ui/Settings/Section/indexV2';
import { selectTemplatableChannels } from '$state/concerns/channels';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import * as templates from '$state/concerns/templates';
import { Channels } from '$state/types';
import { Redirect, Route } from 'react-router';
import { Switch, useHistory } from 'react-router-dom';
import { withState } from '$state';
import { usePageTitle } from '@/utils/hooks';
import { whatsAppEnabled } from '$state/queries/integrations';
import { featureEnabled } from '$state/queries/features';
import { ConnectedAccordion as ConnectedAccordionV2 } from '$ui/TemplatesV2';
import { selectChannel } from '$state/concerns/channels';
import { TemplatesList as TemplatesListV2 } from '$ui/TemplatesV2/Parts';
import { ConnectedTemplateEditor } from '$ui/TemplatesV2/Editor';
import { useTemplateParams } from '$ui/TemplatesV2/hooks';
import { Subsection as SubsectionV2 } from '$ui/Settings/Section/SubsectionV2';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Template } from '$state/types/templates';
import { v4 as uuid } from 'uuid';

const ManageTemplates = () => {
    const history = useHistory();
    const { channelName, templateId } = useTemplateParams();
    const channel = useAppSelector(selectChannel(channelName));
    const templateStatus = useAppSelector(templates.selectStatus);
    const template: Template = templateId
        ? useAppSelector(templates.selectTemplate(templateId))
        : {
              id: uuid(),
              name: '',
              subject: '',
              body: '',
              channel: channelName,
              approval: undefined,
              status: null,
              reason: null,
          };

    const title = templateId ? 'Edit Template' : 'Create Template';

    React.useEffect(() => {}, [templateStatus]);
    if (templateStatus !== 'loaded') {
        return null;
    }

    return (
        <Frame>
            <TemplatesNav>
                <Icon
                    size={3}
                    clickable
                    icon={'ArrowLeft'}
                    onClick={() =>
                        history.push(`/settings/templates/${channelName}`)
                    }
                />
                <NavTitle>{title}</NavTitle>
            </TemplatesNav>
            <EditorContainer>
                <SubsectionV2 id="details">
                    <ConnectedTemplateEditor
                        channel={channel}
                        template={template}
                    />
                </SubsectionV2>
            </EditorContainer>
        </Frame>
    );
};

export const TemplatesV2 = () => {
    usePageTitle('Templates - Settings');

    return (
        <Section fullWidth>
            <Switch>
                <Route
                    exact
                    path={'/settings/templates/:channelName'}
                    render={() => (
                        <Frame>
                            <TemplatesListV2 />
                        </Frame>
                    )}
                />
                <Route exact path="/settings/templates">
                    <Redirect
                        exact
                        to={`/settings/templates/${Channels.Email}`}
                    />
                </Route>
                <Route
                    path={'/settings/templates/:channelName/create'}
                    render={() => <ManageTemplates />}
                />
                <Route
                    path={'/settings/templates/:channelName/edit/:templateId'}
                    render={() => <ManageTemplates />}
                />
            </Switch>
        </Section>
    );
};

const Frame = (props: { children: React.ReactNode }) => {
    const whatsAppFeatureEnabled = useAppSelector(
        withState(featureEnabled, 'whatsapp'),
    );

    const channels = useAppSelector(
        withState(selectTemplatableChannels, {
            SMS: useAppSelector(smsEnabled),
            Email: useAppSelector(emailEnabled),
            WhatsApp: useAppSelector(whatsAppEnabled) && whatsAppFeatureEnabled,
        }),
    );

    const accordions = channels.map((channel) => {
        return (
            <ConnectedAccordionV2
                channel={channel.channel}
                key={channel.channel}
            />
        );
    });

    return <Section sectionMenu={accordions}>{props.children}</Section>;
};

const EditorContainer = styled.div`
    min-width: 600px;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 1.5 })};
`;

const TemplatesNav = styled.div`
    display: flex;
    column-gap: ${mix.unit({ size: 2 })};
    align-items: center;
`;

const NavTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: var(--gray-900);
`;
