/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import { store } from '$state';
import { forceRefresh } from '@/router/service';
import { refreshing } from '$state/concerns/config';
import { baseURL } from '$state/queries/config';

export const VersionedRoute = ({
    children,
    component: Component,
    ...rest
}: React.ComponentProps<typeof Route>) => {
    const state = store.getState();
    const url = baseURL(state);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (refreshing(state) && url) {
                    forceRefresh().then(() => console.log('Version mismatch'));
                }

                return <>{Component ? <Component {...props} /> : children}</>;
            }}
        />
    );
};
