import { RootState } from '..';
import {
    PatientMap,
    PatientRecordLoadedState,
    PatientRecordState,
    PatientStatus
} from '../types';

export const state = (state: RootState): PatientMap => state.patient.records;

export const get =
    (id: string) =>
    (rootState: RootState): PatientRecordState =>
        state(rootState)[id] ?? {
            status: PatientStatus.IDLE
        };

export const status = (record: PatientRecordState): PatientStatus =>
    record.status;

export const isLoaded = (
    record: PatientRecordState
): record is PatientRecordLoadedState => {
    return ![
        PatientStatus.LOADING,
        PatientStatus.IDLE,
        PatientStatus.ERRORED
    ].includes(record.status);
};

export const fullName = (record: PatientRecordLoadedState): string =>
    record.patient.first_name + ' ' + record.patient.last_name;

export const maybeFullName = (record: PatientRecordState): string | null => {
    if (!isLoaded(record)) {
        return null;
    }

    const { patient } = record;
    return [patient.first_name, patient.last_name ?? null]
        .filter((x: string | null) => x)
        .join(' ');
};

export const type = (record: PatientRecordLoadedState): string =>
    record.patient.type;

export const stage = (record: PatientRecordLoadedState): string | null =>
    record.patient.stage;

export const source = (id: string) => (root: RootState) =>
    get(id)(root).patient?.source;

export const isNumberValid =
    (patientId: string) =>
    (rootState: RootState): boolean => {
        const record = get(patientId)(rootState);

        if (!isLoaded(record)) {
            return false;
        }

        const { patient } = record;

        if (! patient.phone_number) {
            return false;
        }

        return !patient.phone_number.invalid_reason;
    };
