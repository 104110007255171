import React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Button } from '$ui/Flo/Button';
import { Hue } from '$ui/Flo/types';
import { Icon, IconName } from '$ui/Flo/Icon';

interface IconProps {
    icon?: {
        name: IconName;
        hue: Hue;
    };
}

interface Props extends IconProps {
    title: string;
    children?: React.ReactNode;
    positiveButtonLabel: string;
    neutralButtonLabel: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const ConfirmationModal = (props: Props) => {
    const {
        icon,
        title,
        children: description,
        positiveButtonLabel,
        neutralButtonLabel,
        onConfirm,
        onCancel
    } = props;

    return (
        <Container>
            <Modal>
                <Content>
                    <ModalIcon icon={icon} />
                    <Title>{title}</Title>
                    <Description>
                        <div>{description}</div>
                    </Description>
                </Content>
                <ActionBar>
                    <ButtonGroup>
                        <Button
                            rounded
                            mode="solid"
                            hue={icon?.hue ?? 'primary'}
                            onClick={onConfirm}
                        >
                            {positiveButtonLabel}
                        </Button>
                        <Button
                            rounded
                            mode="outline"
                            hue="primary"
                            onClick={onCancel}
                        >
                            {neutralButtonLabel}
                        </Button>
                    </ButtonGroup>
                </ActionBar>
            </Modal>
        </Container>
    );
};

const ModalIcon = ({ icon }: IconProps) => {
    if (!icon) {
        return null;
    }

    const { name, hue } = icon;

    return (
        <IconContainer hue={hue}>
            <Icon icon={name} size={3} hue={hue} />
        </IconContainer>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
`;

const Modal = styled.div`
    background: white;
    position: absolute;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    white-space: nowrap;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1 })};
    justify-content: center;
`;

const Content = styled.div`
    ${mix.padding({ padding: 3 })};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
`;

const Title = styled.div`
    ${mix.type({ level: 'body1', bold: true })};
    color: var(--gray-900);
`;

const Description = styled.div`
    ${mix.type({ level: 'body1' })};
    color: var(--gray-500);
`;

const ActionBar = styled.div`
    border-top: 1px solid var(--gray-200);
    ${mix.padding({ padding: 3 })};
`;

const IconContainer = styled.div<{ hue: Hue }>`
    ${mix.sq({ size: 6 })};
    border-radius: 50%;
    background: var(--gray-100);
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ hue }) => {
        switch (hue) {
            case 'primary':
                return css`
                    background: var(--primary-050);
                `;
            case 'red':
                return css`
                    background: var(--error-050);
                `;
            case 'green':
                return css`
                    background: var(--success-050);
                `;
            case 'yellow':
                return css`
                    background: var(--warning-050);
                `;
            default:
                return css`
                    background: var(--gray-100);
                `;
        }
    }};
`;
