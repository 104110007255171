import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';

interface SubsectionProps {
    id: string;
    title: string;
    children: React.ReactNode;
    padded?: boolean;
    actions?: React.ReactNode;
}

export const Subsection = ({
    id,
    title,
    children,
    padded,
    actions = null
}: SubsectionProps) => {
    return (
        <Container id={id} title={title} data-testid={id}>
            <SubsectionHeading level="subtitle1" align="left">
                <div>{title}</div>
                <Actions>{actions}</Actions>
            </SubsectionHeading>
            <Content padded={padded ?? false}>{children}</Content>
        </Container>
    );
};

const SubsectionHeading = styled(Heading)`
    ${mix.padding({ padding: [4, 4, 0] })};
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;

const Container = styled.div`
    ${mix.bg({ hue: 'grey', shade: '10', alpha: 0.5 })};
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    border-radius: 6px;
`;

const Content = styled.div<{ padded: boolean }>`
    ${({ padded }) =>
        padded &&
        css`
            ${mix.padding({ padding: 4 })};
        `}
`;
