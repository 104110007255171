import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { ChannelConfig, ReplyState } from '$state/types';
import { ContextState, TemplateContext } from '$state/types/contexts';
import { Template } from '$state/types/templates';
import { NoTemplates } from '$ui/ReplyBar/Templates/NoTemplates';
import { BrowseTemplates } from '$ui/ReplyBar/Templates/BrowseTemplates';

export interface Props {
    channel: ChannelConfig;
    templateContext: ContextState | undefined;
    templates: Template[] | undefined;
    state: ReplyState;
    stateChanged: (state: ReplyState) => void;
    sendTemplate?: (template: Template, message: string) => void;
    isStalledConversation: boolean;
}

export const Templates = (props: Props) => {
    const {
        channel,
        templates = [],
        templateContext,
        state,
        stateChanged,
        sendTemplate,
        isStalledConversation
    } = props;

    const [openBrowser, setOpenBrowser] = React.useState(false);
    const [maximized, setMaximized] = React.useState(false);

    const toggleBrowser = () => {
        setOpenBrowser(!openBrowser);
    }

    const toggleMaximized = () => {
        setMaximized(!maximized);
    }

    const haveTemplates = templates.length > 0;

    const patientTemplateContext: TemplateContext | undefined =
        templateContext?.context;

    const onChange = (next: Partial<ReplyState>) => {
        stateChanged({
            ...state,
            ...next,
            fields: {
                ...state.fields,
                ...next.fields
            }
        });
        toggleBrowser();
    };

    const onSendTemplate = (template: Template, message: string) => {
        if (sendTemplate) {
            sendTemplate(template, message);
            toggleBrowser();
        }
    }

    return <Container>
        <Browser maximized={maximized} open={openBrowser}>
            {openBrowser && <>
                <Header>
                    <Title>{channel.label} Templates</Title>
                    <Actions>
                        <Icon
                            icon={maximized ? 'Minimize2' : 'Maximize2'}
                            size={2}
                            clickable={true}
                            onClick={toggleMaximized}
                        />
                        <Icon icon="X" size={2.5} clickable={true} onClick={toggleBrowser} />
                    </Actions>
                </Header>
                <Body>
                    {haveTemplates &&
                        <BrowseTemplates
                            templates={templates}
                            channel={channel}
                            expended={maximized}
                            context={patientTemplateContext}
                            onInsert={onChange}
                            onSend={onSendTemplate}
                            isStalledConversation={isStalledConversation}
                        />}
                    {!haveTemplates && <NoTemplates expended={maximized} channel={channel} />}
                </Body>
            </>}
        </Browser>
        <Button onClick={toggleBrowser} isActive={openBrowser}>
            <Icon opacity={0.75} icon="Layers" size={2} clickable={true} />
            Templates
        </Button>
    </Container>;
};

const Container = styled.div``;

const Button = styled.button<{ isActive: boolean }>`
    ${mix.padding({ padding: [0, 1, 0, 1] })};
    ${mix.height({ size: 4 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--gray-600);
    background: none;
    gap: ${mix.unit({ size: 1 })};

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: var(--gray-100);
            color: var(--grey-900);
        `
    }

    &:hover {
        background-color: var(--gray-100);
        color: var(--gray-900);
    }
`;

interface BrowserProps {
    maximized: boolean;
    open: boolean;
}

const Browser = styled.div<BrowserProps>`
    transform: scale(0);
    transition: transform 0.25s;
    transform-origin: bottom left;
    position: absolute;
    background: white;
    border: 1px solid var(--gray-100);
    border-radius: ${mix.unit({ size: 0.5 })};
    box-shadow: 0 4px 16px 0 #00000014;
    height: auto;
    max-width: 100%;
    bottom: ${mix.unit({ size: 6.5 })};
    left: ${mix.unit({ size: 2 })};
    right: ${mix.unit({ size: 2 })};
    z-index: 99;

    ${({ maximized }) =>
        !maximized && css`
            max-width: 480px;
        `
    }

    ${({ open }) =>
        open && css`
            transform: scale(1);
            transition: transform 0.25s;
        `
    }
`;

const Header = styled.div`
    height: 40px;
    display: flex;
    justify-content: space-between;
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    border-bottom: 1px solid var(--gray-100);
`;

const Title = styled.div`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-900);
`;

const Actions = styled.div`
    color: var(--gray-900);
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
`;

