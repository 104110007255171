import React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { useTemplateParams } from '$ui/Templates/hooks';
import { useAppSelector } from '$state';
import { selectChannel } from '$state/concerns/channels';
import { ChannelConfig, Channels } from '$state/types';
import { Template } from '$state/types/templates';
import { selectChannelTemplates } from '$state/concerns/templates';
import { Button } from '$ui/Flo/Button';
import { useHistory } from 'react-router';
import { mix } from '$ui/Flo/util';
import { Accordion } from '$ui/TemplatesV2';
import { AccordionSubItem } from '$ui/TemplatesV2/Parts/AccordionSubItem';
import { Shadow } from '$ui/Flo/Shadow';

const EmptyTemplatesList = ({
    channel,
    handleNavigateToCreate,
}: {
    channel: ChannelConfig;
    handleNavigateToCreate: () => void;
}) => {
    return (
        <Container>
            <EmptyContainer>
                <IconContainer>
                    <Icon icon={channel.icon} size={3} />
                </IconContainer>
                <EmptyTitleContainer>
                    {channel.label} Templates
                </EmptyTitleContainer>
                <MessageContainer>
                    You don't have {channel.channel} templates yet. Create your
                    first {channel.channel} template
                </MessageContainer>
                <Button
                    color={'primary-500'}
                    rounded
                    icon={'Plus'}
                    onClick={handleNavigateToCreate}
                >
                    Add Template
                </Button>
            </EmptyContainer>
        </Container>
    );
};

export const TemplatesList = () => {
    const { channelName } = useTemplateParams();
    const history = useHistory();
    const handleNavigateToCreate = () => {
        history.push(`/settings/templates/${channel.channel}/create`);
    };
    const channel = useAppSelector(
        selectChannel(channelName ?? Channels.Email),
    );
    const templates =
        useAppSelector(selectChannelTemplates(channelName)) ?? null;

    const LoadingCard = () => {
        return (
            <LoadingContainer>
                <Shadow rounded height={4} width={'100'} />
                <Shadow rounded height={4} width={'100'} />
                <Shadow rounded height={4} width={'100'} />
            </LoadingContainer>
        );
    };

    if (templates !== null && templates.length === 0) {
        return (
            <EmptyTemplatesList
                channel={channel}
                handleNavigateToCreate={handleNavigateToCreate}
            />
        );
    }
    const showTemplates = () => {
        if (channel.approval) {
            return <TemplatesGroup channel={channel} templates={templates} />;
        } else {
            return (
                <UngroupedContainer>
                    {templates.map((accordion: Template) => {
                        return (
                            <AccordionSubItem
                                {...accordion}
                                key={accordion.id}
                                icon={channel.icon}
                                template={accordion}
                            />
                        );
                    })}
                </UngroupedContainer>
            );
        }
    };

    return (
        <ListContainer>
            <ListTitleContainer>
                <ListTitle>{channel.label} Templates</ListTitle>
                <Button
                    color={'primary-500'}
                    rounded
                    icon={'Plus'}
                    onClick={handleNavigateToCreate}
                >
                    Add Template
                </Button>
            </ListTitleContainer>
            {templates === null ? <LoadingCard /> : showTemplates()}
        </ListContainer>
    );
};

const TemplatesGroup = (props: {
    channel: ChannelConfig;
    templates: Template[];
}) => {
    const { channel, templates } = props;

    const templatesData: { [key: string]: Template[] } =
        templates.reduce((acc, template) => {
            const key: string =
                template.status ??
                (template.approval ? 'PENDING' : 'NOT_SUBMITTED');
            acc[key] = acc[key] || [];
            acc[key].push(template);
            return acc;
        }, {} as { [key: string]: Template[] }) || null;

    const accordions = [];
    for (const status in templatesData) {
        accordions.unshift(
            <Accordion
                icon={channel.icon}
                label={status}
                templates={templatesData[status]}
                key={status}
            />,
        );
    }
    return accordions.map((accordion, index) => (
        <div key={index}>{accordion}</div>
    ));
};

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
`;

const ListTitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const ListTitle = styled.div`
    font-size: 24px;
`;

const Container = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${mix.unit({ size: 90 })};
    align-self: center;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`;

export const UngroupedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    row-gap: 4px;
    flex: 1 1 100%;
`;

const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    row-gap: 16px;
`;

const IconContainer = styled.div`
    border-radius: 100%;
    background-color: #f2f4f7;
    display: grid;
    place-content: center;
    padding: 20px;
`;

const EmptyTitleContainer = styled.div`
    font-size: 18px;
`;

const MessageContainer = styled.div`
    text-align: center;
`;
