import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { isPlaceholderParent, Placeholder } from '$state/types/contexts';
import { Group } from '$ui/Placeholders/Group';

export interface Props {
    placeholders: Array<Placeholder>;
    placeholderSelected: (placeholder: string) => void;
    position?: 'top' | 'bottom';
}

export const Placeholders = (props: Props) => {
    const { placeholders, placeholderSelected, position = 'bottom' } = props;

    const [openBrowser, setOpenBrowser] = React.useState(false);

    const toggleBrowser = () => {
        setOpenBrowser(!openBrowser);
    };

    return (
        <Container>
            <Button
                onClick={(event) => {
                    event.preventDefault();
                    toggleBrowser();
                }}
                isActive={openBrowser}
            >
                <Icon opacity={0.75} icon="Code" size={2} clickable={true} />
                Placeholders
                <Browser open={openBrowser} position={position}>
                    {openBrowser && (
                        <>
                            <Body>
                                {placeholders.map(
                                    (placeholder) =>
                                        isPlaceholderParent(placeholder) && (
                                            <Group
                                                key={placeholder.name}
                                                name={placeholder.name}
                                                placeholderSelected={
                                                    placeholderSelected
                                                }
                                                values={placeholder.mergeTags}
                                                toggleBrowser={toggleBrowser}
                                            />
                                        ),
                                )}
                            </Body>
                        </>
                    )}
                </Browser>
            </Button>
        </Container>
    );
};

const Container = styled.div`
    min-height: 32px;
`;

const Button = styled.button<{ isActive: boolean }>`
    ${mix.padding({ padding: [0, 1, 0, 1] })};
    ${mix.height({ size: 4 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--gray-600);
    background: none;
    gap: ${mix.unit({ size: 1 })};
    ${({ isActive }) =>
        isActive &&
        css`
            background-color: var(--gray-100);
            color: var(--grey-900);
        `}

    &:hover {
        background-color: var(--gray-100);
        color: var(--gray-900);
    }
`;

interface BrowserProps {
    open: boolean;
    position: 'top' | 'bottom';
}

const Browser = styled.div<BrowserProps>`
    width: auto;
    transform: scale(0);
    transition: transform 0.25s;
    position: absolute;
    ${mix.padding({ padding: [0.5] })};
    background: white;
    border: 1px solid var(--gray-100);
    border-radius: ${mix.unit({ size: 0.5 })};
    box-shadow: 0 2px 4px 0 #0000000a;
    height: auto;
    gap: ${mix.unit({ size: 0.5 })};
    z-index: 199;
    left: 0;
    font-family: Roboto, serif;

    ${({ position }) => {
        if (position === 'top') {
            return css`
                bottom: ${mix.unit({ size: 5 })};
                transform-origin: bottom left;
            `;
        }
        return css`
            top: ${mix.unit({ size: 5 })};
            transform-origin: top left;
        `;
    }}

    ${({ open }) =>
        open &&
        css`
            transform: scale(1);
            transition: transform 0.25s;
        `}
`;

const Body = styled.div`
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-600);
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 0.5 })};
    position: relative;
`;
