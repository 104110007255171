import React from 'react';
import { ColorToken } from '$ui/Flo/types';
import { mix } from '$ui/Flo/util';
import styled, { css } from 'styled-components';
import { Heading } from '$ui/Flo/Heading';
import { Link } from '$ui/Flo/Link';
import { EventType } from '$types/patient';
import { EventType as EventTypeV2 } from '$types/timeline-v2';
import Linkify from 'react-linkify';

interface ContentProps {
    title?: string;
    inbound: boolean;
    color: ColorToken;
    children: React.ReactNode;
    type: EventType | EventTypeV2;
}

export const Content = (props: ContentProps) => {
    const { title, color, inbound, children } = props;

    const linkDecorator = (
        decoratedHref: string,
        decoratedText: string,
        key: number,
    ) => {
        if (
            !decoratedText.includes('leadflo') ||
            props.type === 'communication'
        ) {
            return decoratedText;
        }
        return (
            <Link
                level="body2"
                target="_blank"
                href={decoratedHref}
                key={key}
                rel="noreferrer"
            >
                View report
            </Link>
        );
    };

    return (
        <Body color={color} inbound={inbound}>
            {title && (
                <Heading level="body1" dark={!inbound} align="left" bold>
                    {title}
                </Heading>
            )}
            <Linkify componentDecorator={linkDecorator}>{children}</Linkify>
        </Body>
    );
};

export const Body = styled.div<Pick<ContentProps, 'color' | 'inbound'>>`
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body' })};
    position: relative;
    border-radius: 5px;
    white-space: pre-line;
    overflow-wrap: break-word;
    ${({ color }) => {
        return css`
            background-color: var(--${color});
        `;
    }};
    ${({ inbound }) => {
        return (
            !inbound &&
            css`
                color: hsla(0, 0%, 100%, 0.9);
            `
        );
    }};
`;
