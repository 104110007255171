import { TopNavItem } from '@/types';
import { createSelector } from 'reselect';
import { actionNavConfigs } from './actions';
import { inboxNavConfigs } from './inbox';

export const navConfig = createSelector(
    [actionNavConfigs, inboxNavConfigs],
    (actions, inbox): TopNavItem[] => {
        return [
            ...inbox,
            ...actions,
            {
                name: 'Reporting',
                destination: '/reporting',
                icon: 'BarChart',
            },
            {
                name: 'Automations',
                destination: '/automations',
                icon: 'Zap',
            },
        ].filter((nav) => !!nav) as TopNavItem[];
    },
);
