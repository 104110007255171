import { RootState } from '$state/store';
import { Selector } from '@reduxjs/toolkit';

/** All other items in an array */
export type Tail<A> = A extends [any, ...infer Rest] ? Rest : never;

export function withState<T extends Selector<RootState, ReturnType<T>>>(
    f: T,
    ...args: Tail<Parameters<T>>
): (state: RootState) => ReturnType<T> {
    return (state: RootState) => f(state, ...args);
}
