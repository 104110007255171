import {
    selectChanged,
    selectQuery,
    updateQuery
} from '$state/concerns/patient-filter';
import { useAppDispatch, useAppSelector, useSources } from '$state/hooks';
import {
    labels as labelsQuery,
    types as typesQuery
} from '$state/queries/client';
import { ReportQuery } from '$state/types';
import React from 'react';
import { Filter } from './Filter';
import { selectStages } from '$state/concerns/stages';

interface Props {
    runExport?: () => void;
    children?: React.ReactNode;
    excludeFilters?: Filter[];
}

export const ConnectedFilterV2 = ({
    runExport,
    children,
    excludeFilters
}: Props) => {
    const dispatch = useAppDispatch();
    const types = useAppSelector(typesQuery);
    const labels = useAppSelector(labelsQuery);
    const sources = useSources();
    const query = useAppSelector(selectQuery);
    const stages = useAppSelector(selectStages);
    const changed = useAppSelector(selectChanged);

    const update = (query: Partial<ReportQuery>) => {
        dispatch(updateQuery(query));
    };

    return (
        <Filter
            actionDisabled={!changed}
            types={types}
            labels={labels}
            sources={sources}
            stages={stages}
            query={query}
            update={update}
            excludeFilters={excludeFilters}
            runExport={runExport}
        >
            {children}
        </Filter>
    );
};
