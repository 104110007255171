import { DuplicateReportContext as TDuplicateReport } from '$ui/AddPatient/fsm';
import { Alert } from '$ui/Flo/Alert';
import { ResultItem } from '$ui/AddPatient/ResultItem';
import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

type DuplicateReportProps = TDuplicateReport & {
    onEditPatient: (patientId: string) => void;
};

export const DuplicateReport = (props: DuplicateReportProps) => {
    if (!props.email && !props.phone) {
        return null;
    }

    const duplicateFormOfContact = () => {
        const { email, phone } = props;

        if (email && phone) {
            return 'email and phone';
        }

        if (email) {
            return 'email';
        }

        if (phone) {
            return 'phone';
        }

        return 'nothing';
    };

    const { patient, onEditPatient } = props;

    return (
        <Container>
            <Alert type="info">
                A patient exists with this {duplicateFormOfContact()}
            </Alert>
            <ResultItem
                patientId={patient.id}
                name={patient.name}
                onEditPatient={onEditPatient}
            />
        </Container>
    );
};

const Container = styled.div`
    ${mix.gap({ size: 2 })};
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    max-width: 100%;
`;
