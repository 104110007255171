import React from 'react';
import { Options } from '$ui/Flo/Options';
import { Step } from './Step';
import { FlowNodeType } from '$types';
import { StepProps } from './types';

export interface ChoiceValue {
    value: string;
    target?: string;
}

export interface Props extends StepProps<ChoiceValue> {
    type: FlowNodeType.choices;
    choices: {
        value: string;
        label: string;
        target?: string;
    }[];
}

export const ChoicesStep = (props: Props) => {
    const { initial } = props;

    const onChange = (value: string) => {
        const choice = props.choices.find((choice) => choice.value === value);

        if (typeof choice === 'undefined') {
            throw new Error('Selected choice must exist in set of choices');
        }

        props.onChange(choice);
    };

    return (
        <Step {...props}>
            <Options
                initial={initial?.value}
                options={props.choices}
                onSelect={onChange}
                padding={6}
            />
        </Step>
    );
};
