import config from './config';
import timeline from './timeline';
import auth from './auth';
import features from './features';
import actions from './actions';
import patient from './patient';
import flows from './flows';
import inbox from './inbox';
import channels from './channels';
import client from './client';
import reporting from './reporting';
import automations from './automations';
import patientFilter from './patient-filter';
import contexts from './contexts';
import templates from './templates';
import placeholders from './placeholders';
import bulk from './bulk';
import stages from './stages';
import integrations from './integrations';
import link from './dentally/link';
import match from './dentally/match';
import suggestion from './dentally/suggestion';
import financial from './dentally/financial';
import dentallyPatients from './dentally/patients';
import sources from './sources';
import { combineReducers } from 'redux';

const dentally = combineReducers({
    patients: dentallyPatients
});

export {
    config,
    timeline,
    auth,
    features,
    actions,
    patient,
    flows,
    inbox,
    channels,
    client,
    reporting,
    automations,
    patientFilter,
    contexts,
    templates,
    placeholders,
    bulk,
    stages,
    integrations,
    link,
    match,
    suggestion,
    financial,
    dentally,
    sources
};
