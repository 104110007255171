import React from 'react';
import {
    Controller,
    Control,
    FieldError,
    useFormContext,
} from 'react-hook-form';
import {
    useCheckbox,
    useFocusRing,
    useId,
    useRadio,
    useRadioGroup,
    VisuallyHidden,
} from 'react-aria';
import styled, { css } from 'styled-components';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { mix } from '$ui/Flo/util';
import { Alert } from '$ui/Flo/Alert';
import { Icon } from '$ui/Flo/Icon';
import { useToggleState } from 'react-stately';
import { MarketingDetailsContext } from '$ui/AddPatient/fsm';

type MarketingDetailsProps = {
    txTypes: Array<string>;
    leadSources: Array<string>;
};

export const MarketingDetails = ({
    txTypes,
    leadSources,
}: MarketingDetailsProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext<{ marketing: MarketingDetailsContext }>();

    return (
        <FormContainer>
            <Section>
                <TreatmentTypeSelector
                    control={control}
                    txTypes={txTypes}
                    error={errors.marketing?.treatmentType}
                />
            </Section>

            <Section>
                <ReferralSourceSelector
                    control={control}
                    leadSources={leadSources}
                    error={errors.marketing?.referralSource}
                />
            </Section>

            <Section>
                <GDPRToggle control={control} />
            </Section>
        </FormContainer>
    );
};

type FieldProps = {
    control: Control<{ marketing: MarketingDetailsContext }>;
    error?: FieldError;
};

type TreatmentTypeSelectorProps = Pick<MarketingDetailsProps, 'txTypes'> &
    FieldProps;

const TreatmentTypeSelector = ({
    txTypes,
    control,
    error,
}: TreatmentTypeSelectorProps) => {
    return (
        <>
            <Controller
                control={control}
                name="marketing.treatmentType"
                rules={{ required: 'Please select a treatment type' }}
                render={({ field }) => {
                    const state = useRadioGroupState(field);
                    const { radioGroupProps, labelProps } = useRadioGroup(
                        {
                            ...field,
                            'aria-label': 'What type of treatment?',
                        },
                        state,
                    );
                    return (
                        <>
                            <Label {...labelProps}>
                                What type of treatment?
                            </Label>
                            <ButtonGroup {...radioGroupProps}>
                                {txTypes.map((type) => (
                                    <TreatmentType
                                        key={type}
                                        type={type}
                                        state={state}
                                    />
                                ))}
                            </ButtonGroup>
                        </>
                    );
                }}
            />
            <ErrorMessage error={error} />
        </>
    );
};

type TreatmentTypeProps = {
    type: string;
    state: RadioGroupState;
};

const TreatmentType = ({ type, state }: TreatmentTypeProps) => {
    const ref = React.useRef(null);
    const { inputProps, isSelected } = useRadio(
        {
            value: type,
            'aria-label': type,
        },
        state,
        ref,
    );
    const { isFocusVisible, focusProps } = useFocusRing();
    return (
        <>
            <ToggleButton selected={isSelected} focused={isFocusVisible}>
                <VisuallyHidden>
                    <input {...inputProps} {...focusProps} ref={ref} />
                </VisuallyHidden>
                {type}
            </ToggleButton>
        </>
    );
};

type ReferralSourceSelectorProps = Pick<MarketingDetailsProps, 'leadSources'> &
    FieldProps;

const ReferralSourceSelector = ({
    leadSources,
    control,
    error,
}: ReferralSourceSelectorProps) => {
    return (
        <>
            <Controller
                control={control}
                name="marketing.referralSource"
                rules={{ required: 'Please select a referral source' }}
                render={({ field }) => {
                    const id = useId();
                    return (
                        <>
                            <Label htmlFor={id}>
                                Where did the patient hear about us?
                            </Label>
                            <Select id={id} {...field}>
                                <option value="">Select option</option>
                                {leadSources.map((source) => (
                                    <option key={source} value={source}>
                                        {source}
                                    </option>
                                ))}
                            </Select>
                        </>
                    );
                }}
            />
            <ErrorMessage error={error} />
        </>
    );
};

const GDPRToggle = ({ control }: FieldProps) => {
    return (
        <Controller
            control={control}
            name="marketing.consented"
            render={({ field }) => {
                const ref = React.useRef<HTMLInputElement | null>(null);
                const state = useToggleState({
                    ...field,
                    value: '1',
                });
                const { inputProps, labelProps } = useCheckbox(
                    {
                        ...field,
                        value: '1',
                        'aria-label':
                            'Has this patient verbally consented' +
                            ' to marketing communications?',
                    },
                    state,
                    ref,
                );
                return (
                    <>
                        <ToggleWrapper {...labelProps}>
                            <VisuallyHidden>
                                <input {...inputProps} ref={ref} />
                            </VisuallyHidden>
                            <Label as="div">
                                Has this patient verbally consented to marketing
                                communications?
                            </Label>
                            <div>
                                <ToggleSwitch selected={state.isSelected} />
                            </div>
                        </ToggleWrapper>
                        {state.isSelected ? (
                            <Alert type="success">
                                We can send this patient marketing emails
                            </Alert>
                        ) : (
                            <Alert type="warning">
                                We cannot send this patient marketing emails
                            </Alert>
                        )}
                    </>
                );
            }}
        />
    );
};

type ErrorMessageProps = {
    error?: FieldError;
};

// TODO: Refactor to a single reusable component
const ErrorMessage = ({ error }: ErrorMessageProps) => {
    return (
        <div
            css={`
                visibility: hidden;
                ${mix.type({ level: 'small' })};
                color: var(--error-600);
                display: flex;
                align-items: center;
                ${mix.gap({ size: 0.5 })}
                ${!!error &&
                css`
                    visibility: visible;
                `}
            `}
        >
            <Icon icon="AlertCircle" color="error-600" opacity={1} />
            <div>{error?.message ?? 'For visibility calculations'}</div>
        </div>
    );
};

// Styled Components
const FormContainer = styled.div`
    ${mix.type({ level: 'body2' })};
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const Label = styled.label`
    color: var(--gray-600);
    font-weight: 600;
    display: block;
`;

const ButtonGroup = styled.div`
    display: flex;
    ${mix.gap({ size: 0.5 })};
    flex-wrap: wrap;
`;

const ToggleWrapper = styled.label`
    display: flex;
`;

type ToggleButtonProps = {
    selected: boolean;
    focused: boolean;
};

const ToggleButton = styled.label<ToggleButtonProps>`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    ${mix.height({ size: 4.5 })};
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-300);
    color: var(--gray-600);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: var(--gray-100);
    }

    ${({ selected }) =>
        selected &&
        css`
            background-color: var(--primary-100);
            color: var(--gray-900);
            border-color: var(--primary-100);

            &:hover {
                background-color: var(--primary-100);
            }
        `};
`;

const Select = styled.select`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 1.5 })};
    ${mix.height({ size: 6 })};
    border-radius: 4px;
    border: 1px solid var(--gray-300);
`;

const ToggleSwitch = styled.div<{ selected: boolean }>`
    ${mix.width({ size: 4.5 })};
    ${mix.height({ size: 2.5 })};
    padding: 1px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: 1px solid var(--gray-200);

    ${({ selected }) => {
        return (
            selected &&
            css`
                border-color: var(--primary-500);
                background-color: var(--primary-500);
            `
        );
    }};

    &::after {
        content: '';
        background: var(--gray-300);
        ${mix.sq({ size: 2 })};
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 1px;
        transition: 0.15s transform linear;

        ${({ selected }) =>
            selected &&
            css`
                background: white;
                transform: translateX(100%);
                justify-self: end;
            `};
    }
`;
