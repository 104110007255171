import * as React from 'react';
import styled, { css } from 'styled-components';
import { Heading } from '../Heading';
import { ColorToken } from '../types';
import { mix } from '../util';

interface SizeProp {
    size?: number;
}

interface Props extends SizeProp {
    name: string;
    loading?: boolean;
    color?: ColorToken;
    dark?: boolean;
    fontColor?: ColorToken
}

export const Monogram = (props: Props) => {
    const {
        name,
        loading,
        fontColor,
        size = 8,
        color = 'primary-500',
        dark = true
    } = props;

    if (loading) {
        return <Circle color="gray-400" size={size ?? 8} />;
    }

    const nameArr = name.trim().split(' ');
    let initials;
    if (nameArr.length == 1) {
        initials = nameArr[0].charAt(0);
    } else {
        const first = nameArr[0];
        const last = nameArr[nameArr.length - 1];
        initials = first.charAt(0) + last.charAt(0);
    }

    return (
        <Circle size={size} color={color} data-qa="monogram">
            <Heading fontColor={fontColor} dark={dark} level={size > 4 ? 'body1' : 'small'}>
                {initials.toUpperCase()}
            </Heading>
        </Circle>
    );
};

interface CircleProps extends SizeProp {
    color: ColorToken;
    hidden?: boolean;
}

export const Circle = styled.div<CircleProps>`
    ${({color}) =>
        css `background-color: var(--${color});`};
    ${mix.padding({ padding: 2 })}
    box-sizing: border-box;
    border-radius: 50px;
    width: ${mix.unit()};
    height: ${mix.unit()};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    flex: 0 0 auto;
    position: relative;
    ${({ hidden }) =>
        hidden &&
        css`
            overflow: hidden;
        `}
`;
