import {
    useAppDispatch,
    useAppSelector,
    useContextLoader,
    withState,
} from '$state';
import {
    isOnGoingCommunication,
    selectReplyBar,
} from '$state/queries/timeline';
import {
    sendComm,
    sendCommApproved,
    takeNote,
    updateReplyState,
} from '$state/concerns/timeline';
import { v4 as uuid } from 'uuid';
import {
    EmailPayload,
    NotePayload,
    ReplyState,
    SMSPayload,
    WhatsAppPayload,
} from '$state/types';
import { CommType } from '$types';
import { CommType as CommTypeV2 } from '$types/timeline-v2';
import {
    selectEnabledChannelKeys,
    selectOngoingChannels,
    selectWritableChannels,
} from '$state/concerns/channels';
import { isNumberValid } from '$state/queries/patient';
import { selectContext } from '$state/concerns/contexts';
import * as React from 'react';
import { ConnectedReplyBar } from '$ui/ReplyBar/ConnectedReplyBar';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import { selectSnooze } from '$state/queries/snooze';
import { whatsAppEnabled } from '$state/queries/integrations';
import { featureEnabled } from '$state/queries/features';
import styled, { css } from 'styled-components';
import { useResizeObserver } from '@/utils/resize-observer';
import { Extender } from '$ui/ReplyBar/Extender';
import { Template } from '$state/types/templates';

interface Props {
    patientId: string;
}

export const TimelineReplyBar = ({ patientId }: Props) => {
    const dispatch = useAppDispatch();

    const snooze = useAppSelector(withState(selectSnooze, patientId));

    const whatsAppFeatureEnabled = useAppSelector(
        withState(featureEnabled, 'whatsapp'),
    );
    const timelineV2Enabled = useAppSelector(
        withState(featureEnabled, 'timeline-v2'),
    );

    // selects enabled channels based on rules
    const channels = useAppSelector(
        withState(selectWritableChannels, {
            SMS: useAppSelector(smsEnabled),
            Email: useAppSelector(emailEnabled),
            WhatsApp: useAppSelector(whatsAppEnabled) && whatsAppFeatureEnabled,
        }),
    );

    const enabledChannels = useAppSelector(
        withState(selectEnabledChannelKeys, {
            SMS: useAppSelector(isNumberValid(patientId)),
            WhatsApp: useAppSelector(isNumberValid(patientId)),
        }),
    );

    const stalledChannels = useAppSelector(
        withState(selectOngoingChannels, {
            WhatsApp: !useAppSelector(
                isOnGoingCommunication(patientId, [
                    CommType.WhatsApp,
                    CommTypeV2.WhatsAppMessage,
                ]),
            ),
        }),
    );

    const state = useAppSelector(selectReplyBar(patientId, enabledChannels));

    const [displayExtender, setDisplayExtender] =
        React.useState<boolean>(false);
    const [limitExtender, setLimitExtender] = React.useState<boolean>(false);
    const [initialHeight, setInitialHeight] = React.useState<number | null>(
        null,
    );

    const toggleExtender = () => {
        setLimitExtender(!limitExtender);
    };

    const onResize = React.useCallback(
        (target: HTMLDivElement) => {
            setDisplayExtender((initialHeight ?? 0) < target.clientHeight);
        },
        [initialHeight],
    );

    const observerRef = useResizeObserver(onResize);

    React.useLayoutEffect(() => {
        setInitialHeight(observerRef.current?.clientHeight ?? null);
    }, [initialHeight]);

    const resetReplyState = () => {
        dispatch(
            updateReplyState({
                patientId,
                state: {
                    channel: state.channel,
                    fields: {},
                },
            }),
        );
    };

    const send = () => {
        // TODO: Refactor Channels/CommType enums and make this more generic
        switch (state.channel) {
            case 'note':
                dispatch(
                    takeNote({
                        id: uuid(),
                        patientId,
                        ...(state.fields as NotePayload),
                    }),
                );
                break;
            case 'email':
                dispatch(
                    sendComm({
                        id: uuid(),
                        patientId,
                        channel: CommType.Email,
                        payload: state.fields as EmailPayload,
                        endSnooze: state.endSnooze,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            case 'sms':
                dispatch(
                    sendComm({
                        id: uuid(),
                        patientId,
                        channel: CommType.SMS,
                        payload: state.fields as SMSPayload,
                        endSnooze: state.endSnooze,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            case 'WhatsApp': {
                const fields = state.fields as WhatsAppPayload;
                dispatch(
                    sendComm({
                        id: uuid(),
                        patientId,
                        channel: CommType.WhatsApp,
                        payload: fields,
                        endSnooze: state.endSnooze,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            }
        }

        resetReplyState();
    };

    const sendTemplate = (template: Template, message: string) => {
        switch (state.channel) {
            case 'WhatsApp': {
                const fields = {
                    message,
                    template,
                } as WhatsAppPayload;

                dispatch(
                    sendCommApproved({
                        id: uuid(),
                        patientId,
                        template_id: template.id,
                        channel: CommType.WhatsApp,
                        payload: fields,
                        endSnooze: state.endSnooze,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            }
        }

        resetReplyState();
    };

    const onChange = (state: ReplyState) => {
        dispatch(
            updateReplyState({
                patientId,
                state,
            }),
        );
    };

    useContextLoader(patientId);

    const templateContext = useAppSelector(selectContext(patientId));

    return (
        <>
            {(displayExtender || limitExtender) && (
                <Extender
                    limitExtender={limitExtender}
                    toggle={toggleExtender}
                />
            )}
            <Container
                ref={observerRef}
                limitExtender={limitExtender}
                maxHeight={initialHeight}
            >
                <ConnectedReplyBar
                    channels={channels}
                    send={send}
                    sendTemplate={sendTemplate}
                    enabledChannels={enabledChannels}
                    stalledChannels={stalledChannels}
                    templateContext={templateContext}
                    state={state}
                    onStateChanged={onChange}
                    snooze={snooze}
                />
            </Container>
        </>
    );
};

interface ContainerProps {
    limitExtender: boolean;
    maxHeight: number | null;
}

const Container = styled.div<ContainerProps>`
    box-shadow: 0 0 16px 0 #00000014;
    position: relative;
    height: 100%;

    ${({ limitExtender, maxHeight }) =>
        limitExtender &&
        maxHeight &&
        css`
            max-height: ${maxHeight}px;
        `}
`;
