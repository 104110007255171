import { obj } from '$utils';
import * as React from 'react';
import styled from 'styled-components';
import { applyPadding, scale } from '../util';
import { applyFieldStyle, applyLabelStyle, FieldProps } from './Field';

interface Props extends FieldProps {
    title?: string;
    label?: string;
    initialValue?: string;
    valueChanged: (value: string) => void;
}

export const AreaField = (props: Props) => {
    const { label, valueChanged, editable, initialValue = '' } = props;
    const [value, setValue] = React.useState(initialValue);

    const change = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = e.target.value;
        setValue(val);
        valueChanged(val);
    };
    return (
        <Wrapper {...obj.omit(props, ['title'])}>
            {label && (
                <Label
                    {...obj.omit(props, ['title'])}
                    level="body2"
                    profile="body"
                    bold
                    htmlFor="textarea-input"
                >
                    {label}
                </Label>
            )}
            <Input
                {...props}
                id="textarea-input"
                onChange={change}
                rows={4}
                disabled={!editable}
                value={value}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: [3, 4] })};
    display: flex;
    flex-direction: column;
    box-shadow: none;
`;

const Label = styled.label`
    ${applyLabelStyle};

    margin-bottom: ${({ theme }) => scale(theme, 1)}px;
`;

const Input = styled.textarea`
    ${applyFieldStyle};
    padding: 1rem;
    border: none;
    resize: none;
    cursor: initial;
`;
