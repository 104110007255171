import * as api from '$api/dentally/link';
import { LinkResponse } from '$api/dentally/link';
import {
    createAsyncThunk,
    createReducer,
    createSelector
} from '@reduxjs/toolkit';
import { RootState } from '$state';
import { getFinancial } from './financial';

interface LinkPayload {
    patientId: string;
    id: number;
}

export const getLink = createAsyncThunk(
    'dentally/link/get',
    (patientId: string): Promise<LinkResponse> => {
        return api.getLink(patientId);
    }
);

export const link = createAsyncThunk<void, LinkPayload>(
    'dentally/link/link',
    async ({ patientId, id }, ThunkAPI) => {
        await api.link(patientId, id);
        ThunkAPI.dispatch(getLink(patientId));
        ThunkAPI.dispatch(getFinancial(patientId));
    }
);

export const unlink = createAsyncThunk(
    'dentally/link/unlink',
    async (patientId: string): Promise<void> => {
        await api.unlink(patientId);
    }
);

export interface LinkStore {
    [patientId: string]: LinkState;
}

export interface LinkState {
    status: string;
    link?: LinkResponse;
}

// create link initial state
const initialState: LinkStore = {};

// create link reducer
export default createReducer(initialState, (builder) => {
    builder.addCase(getLink.pending, (state, action) => {
        const { arg: patientId } = action.meta;
        state[patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(getLink.fulfilled, (state, action) => {
        const { arg: patientId } = action.meta;
        state[patientId] = {
            status: 'linked',
            link: action.payload
        };
    });

    builder.addCase(getLink.rejected, (state, action) => {
        const { arg: patientId } = action.meta;
        state[patientId] = {
            status: 'unlinked'
        };
    });

    builder.addCase(unlink.pending, (state, action) => {
        const { arg: patientId } = action.meta;
        state[patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(unlink.fulfilled, (state, action) => {
        const { arg: patientId } = action.meta;
        state[patientId] = {
            status: 'unlinked'
        };
    });

    builder.addCase(unlink.rejected, (state, action) => {
        const { arg: patientId } = action.meta;
        state[patientId] = {
            status: 'failed'
        };
    });

    builder.addCase(link.pending, (state, action) => {
        const { arg: params } = action.meta;
        state[params.patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(link.fulfilled, (state, action) => {
        const { arg: params } = action.meta;
        state[params.patientId] = {
            status: 'loading'
        };
    });

    builder.addCase(link.rejected, (state, action) => {
        const { arg: params } = action.meta;
        state[params.patientId] = {
            status: 'failed'
        };
    });
});

export const linkState = (state: RootState): LinkStore => state.link;

export const isLinked = createSelector(
    [(_, patientId: string) => patientId, linkState],
    (patientId, link: LinkStore) => {
        return Boolean(link[patientId]?.link);
    }
);

export const selectLinked = createSelector(
    [(_, patientId: string) => patientId, linkState],
    (patientId, link: LinkStore) => {
        return link[patientId]?.link;
    }
);

export const selectLinkStatus = createSelector(
    [(_, patientId: string) => patientId, linkState],
    (patientId, link: LinkStore) => {
        return link[patientId]?.status;
    }
);
