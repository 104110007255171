import { Button } from '$ui/Flo/Button';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

export interface LinkBoxModalProps {
    open: boolean;
    body: React.ReactNode;
    onClose?: () => void;
}

export const LinkBoxModal = (props: LinkBoxModalProps) => {
    const { open, body, onClose } = props;

    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                handleClose();
            }
        };

        if (open) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [open, onClose]);

    const handleClose = React.useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <Wrapper show={open}>
            <Filler />
            <CloseButton>
                <Button
                    mode="solid"
                    icon="X"
                    rounded
                    hue="primary"
                    onClick={handleClose}
                    align="center"
                >
                    Close
                </Button>
            </CloseButton>
            <ModalBody ref={modalRef}>{body}</ModalBody>
        </Wrapper>
    );
};

/*
 * parents of this component should have position: relative, width and height
 * so that this component can attach to it
 */

const Wrapper = styled.div<{ show: boolean }>`
    ${mix.type({ level: 'body2' })}
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    ${mix.bg({ hue: 'grey', shade: '5', alpha: 0.5 })}
`;

const Filler = styled.div`
    flex: 1;
`;

const CloseButton = styled.div`
    margin-left: auto;
    ${mix.padding({ padding: [1] })}
`;

const ModalBody = styled.div`
    overflow: auto;
    background: white;
    display: flex;
`;
