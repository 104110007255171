import { RootState } from '../store';
import { FlowMap, Flow, NodeMap } from '$types';

export const flows = (state: RootState): FlowMap & { nodes: NodeMap } =>
    state.flows;

export const forStage = (stage: string | null) => (state: RootState): Flow =>
    stage && flows(state)[stage] ? flows(state)[stage] : flows(state)['none'];

export const nodes = (state: RootState) => flows(state).nodes;
