import chroma from 'chroma-js';
import { css } from 'styled-components';
import {
    AlphaProps,
    PaletteProps,
    TextColorProps,
    ThemedProps
} from '../types';

export const applyTextColor = ({
    theme,
    dark,
    profile
}: ThemedProps<TextColorProps>) => {
    const mode = dark ? 'dark' : 'light';
    return css`
        color: ${theme.color.type[mode][profile]};
    `;
};

export const palette = ({ theme, hue, shade }: ThemedProps<PaletteProps>) =>
    theme.color.aliased[hue] ?? theme.color[hue][shade];

export const alpha = ({
    theme,
    hue,
    shade,
    alpha = 1
}: ThemedProps<AlphaProps>) => {
    const color = palette({ theme, hue, shade });
    return chroma(color).alpha(alpha).css();
};

export const applyBg = (props: ThemedProps<AlphaProps>) =>
    css`
        background: ${alpha(props)};
    `;

export const applyFill = (props: ThemedProps<AlphaProps>) =>
    css`
        fill: ${alpha(props)};
    `;
