import React from 'react';
import { useMachine } from '@xstate/react';
import { fsm } from './fsm';
import { MenuButton } from './MenuButton';
import { Checklist, Item } from './Checklist';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { DeleteButton, SaveButton } from '$ui/Flo/AsyncButton';
import { lang } from '$utils';
import { ConfirmationModal } from '$ui/Flo/ConfirmationModal';

/**
 * The segment editor component is responsible for adding a new email covering a set
 * of segments or modifying an existing email's set of segments.
 *
 * Behaviour:
 *
 * - [x] The editor should be hidden when closed
 * - [x] The editor should be visible and floating when open
 * - [ ] The checklist should be disabled when saving
 * - [ ] The checklist should be disabled when deleting
 * - [x] The delete button should be visible when in 'edit' mode
 * - [x] The delete button should not be visible in 'add' mode
 * - [ ] The delete button should be disabled when deleting
 * - [ ] The delete button should be disabled when saving
 * - [x] The delete button icon should show a spinner when deleting
 * - [ ] The delete button icon should show a tick for 5 seconds when done
 * - [x] Clicking the delete button should ask for confirmation
 * - [x] The save button should always be present
 * - [ ] The save button should be disabled if no segments checked
 * - [x] The save button should be disabled when saving
 * - [x] The save button should be disabled when deleting
 * - [x] The save button icon should show a spinner when saving
 * - [ ] The save button should show a tick for 5 seconds when done
 *
 * Events:
 *
 * - Open
 * - Close
 * - Delete
 * - Confirm Delete
 * - Save
 * - Fail
 * - Retry
 *
 * States:
 *
 * - closed (Initial)
 * - opened.idle
 * - opened.delete.confirming
 * - opened.delete.deleting
 * - opened.delete.deleted
 * - opened.delete.failed
 * - opened.save.saving
 * - opened.save.saved
 * - opened.save.failed
 */

interface Props {
    mode: 'add' | 'edit';
    segments: Item[];
    saveFn: (_: string[]) => Promise<unknown>;
    deleteFn: () => Promise<unknown>;
    onDone?: () => void;
}

export const SegmentEditor = ({
    mode,
    segments: initialSegments,
    saveFn,
    deleteFn,
    onDone = () => null
}: Props) => {
    const [segments, setSegments] = React.useState<Item[]>(initialSegments);

    const [state, send] = useMachine(fsm, {
        actions: {
            reloadAction: () => {
                onDone();
            }
        }
    });

    React.useEffect(() => {
        setSegments(initialSegments);
    }, [initialSegments]);

    const onSave = () => {
        send('SAVE');

        const selectedSegments = segments
            .filter(({ checked, disabled }: Item) => checked && !disabled)
            .map(({ name }: Item) => name);

        saveFn(selectedSegments)
            .then(() => send('DONE'))
            .then(() => send('FAIL'));
    };

    const onDelete = () => {
        send('DELETE');
        if (state.matches('opened.delete.failed')) {
            console.log('failed');
            deleteFn()
                .then(() => send('DONE'))
                .catch(() => send('FAIL'));
        }
    };

    const onConfirmDelete = () => {
        send('CONFIRM');
        deleteFn()
            .then(() => send('DONE'))
            .catch(() => send('FAIL'));
    };

    const onCancelDelete = () => {
        send('CANCEL');
    };

    const saving = state.matches('opened.save.saving');
    const saved = state.matches('opened.save.saved');
    const saveFailed = state.matches('opened.save.failed');
    const saveDisabled =
        lang.isEqual(initialSegments, segments) ||
        (!state.matches('opened.save.failed') && !state.matches('opened.idle'));

    const confirming = state.matches('opened.confirming');
    const deleting = state.matches('opened.delete.deleting');
    const deleted = state.matches('opened.delete.deleted');
    const deleteFailed = state.matches('opened.delete.failed');
    const deleteDisabled =
        !state.matches('opened.delete.failed') && !state.matches('opened.idle');

    const checklistDisabled =
        state.matches('opened.save') || state.matches('opened.delete');

    return (
        <Container>
            <MenuButton
                mode={mode}
                active={state.matches('opened')}
                onClick={() => send('TOGGLE')}
            />
            {state.matches('opened') && (
                <EditorContainer mode={mode}>
                    <Checklist
                        disabled={checklistDisabled}
                        items={segments}
                        onClick={setSegments}
                    />
                    <ButtonGroup>
                        <SaveButton
                            size="x-small"
                            disabled={saveDisabled}
                            doing={saving}
                            done={saved}
                            failed={saveFailed}
                            onClick={onSave}
                        />
                        {mode === 'edit' && (
                            <DeleteButton
                                size="x-small"
                                disabled={deleteDisabled}
                                doing={deleting}
                                done={deleted}
                                failed={deleteFailed}
                                onClick={onDelete}
                            />
                        )}
                    </ButtonGroup>
                </EditorContainer>
            )}
            {confirming && (
                <ConfirmationModal
                    icon={{ name: 'AlertCircle', hue: 'red' }}
                    title="Permanently delete this email segment?"
                    positiveButtonLabel="Yes, delete it"
                    neutralButtonLabel="No, do not delete it"
                    onConfirm={onConfirmDelete}
                    onCancel={onCancelDelete}
                >
                    Once deleted, it cannot be restored and must be recreated
                    from scratch.
                </ConfirmationModal>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

interface EditorContainerProps {
    mode: 'add' | 'edit';
}

const EditorContainer = styled.div<EditorContainerProps>`
    position: absolute;
    ${mix.padding({ padding: 2 })};
    ${mix.bg({ hue: 'primary', shade: '2' })};
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
    min-width: ${mix.unit({ size: 29.5 })};
    z-index: 1;

    ${({ mode }) => {
        switch (mode) {
            case 'add':
                return css`
                    left: 0;
                `;

            case 'edit':
                return css`
                    right: 0;
                `;
        }
    }}
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: space-between;
`;
