// Define the context
import { AnyEventObject, assign, createMachine } from 'xstate';
import { api } from '$api';
import { isoDate } from '@/utils/date';
import { dropRight } from '@/utils/array';

export type ContactDetailsContext = {
    name: string;
    email: string;
    phone: string;
};

export type MarketingDetailsContext = {
    treatmentType: string;
    referralSource: string;
    consented: boolean;
};

export type DetailsContext = {
    contact: ContactDetailsContext;
    marketing: MarketingDetailsContext;
    labels: Array<string>;
};

type DuplicatePatientContext = {
    patient: {
        id: string;
        name: string;
        email: string;
        phone: string;
    };
    email: boolean;
    phone: boolean;
};

type UniquePatientContext = {
    email: false;
    phone: false;
};

export type DuplicateReportContext =
    | DuplicatePatientContext
    | UniquePatientContext;

export const nextActions = [
    'enquiry',
    'thinkingConsult',
    'booking',
    'thinkingTx',
    'inTx',
] as const;

export type NextAction = typeof nextActions[number];

export type NextActionContext = {
    action: NextAction;
    date: string;
    note: string;
};

export type PatientContext = {
    details: DetailsContext;
    duplicates: DuplicateReportContext;
    nextAction: NextActionContext;
};

export type AddPatientEvent =
    | { type: 'search'; contactDetails: ContactDetailsContext }
    | { type: 'continue'; data: Partial<PatientContext> }
    | { type: 'select' }
    | { type: 'back' }
    | { type: 'confirm' }
    | AnyEventObject;

type AddPatientResponse = {
    success: boolean;
    id: string;
};

// Define the state machine
export const fsm = createMachine<PatientContext, AddPatientEvent>(
    {
        predictableActionArguments: true,

        id: 'addPatient',
        initial: 'details',

        context: {
            duplicates: {
                email: false,
                phone: false,
            },

            details: {
                contact: {
                    name: '',
                    email: '',
                    phone: '',
                },
                marketing: {
                    treatmentType: '',
                    referralSource: '',
                    consented: false,
                },
                labels: [],
            },

            nextAction: {
                action: 'booking',
                date: isoDate(new Date()),
                note: '',
            },
        },

        states: {
            details: {
                initial: 'initial',

                states: {
                    initial: {
                        on: {
                            search: {
                                target: 'searching',
                                actions: 'storeContactDetails',
                            },
                        },
                    },
                    searching: {
                        invoke: {
                            src: 'searchForDuplicates',
                            onDone: [
                                {
                                    target: 'duplicates',
                                    cond: 'hasDuplicates',
                                    actions: 'storeDuplicates',
                                },
                                {
                                    target: 'unique',
                                    actions: 'storeDuplicates',
                                },
                            ],
                        },
                    },
                    duplicates: {
                        on: {
                            search: {
                                target: 'searching',
                                actions: 'storeContactDetails',
                            },
                        },
                    },
                    unique: {
                        on: {
                            search: {
                                target: 'searching',
                                actions: 'storeContactDetails',
                            },
                            continue: {
                                target: '#addPatient.nextAction',
                                actions: 'storeForm',
                            },
                        },
                    },
                },
            },
            nextAction: {
                on: {
                    continue: {
                        target: 'confirmation',
                        actions: 'storeForm',
                    },
                    back: 'details.unique',
                },
            },
            confirmation: {
                on: {
                    back: 'nextAction',
                    confirm: 'saving',
                },
            },
            saving: {
                invoke: {
                    src: 'savePatient',
                    onDone: [
                        {
                            target: 'end',
                            actions: 'toast',
                        },
                    ],
                },
            },
            end: {
                type: 'final',
            },
        },
    },
    {
        actions: {
            storeContactDetails: assign((context, event) => {
                if (event.type === 'search') {
                    return {
                        ...context,
                        details: {
                            ...context.details,
                            contact: event.contactDetails,
                        },
                    };
                }

                return context;
            }),

            storeDuplicates: assign((context, event) => {
                if (
                    event.type ===
                    'done.invoke.addPatient.details.searching:invocation[0]'
                ) {
                    return {
                        ...context,
                        duplicates: event.data ?? {
                            email: false,
                            phone: false,
                        },
                    };
                }

                return context;
            }),

            storeForm: assign((context, event) => {
                if (event.type !== 'continue') {
                    return context;
                }

                const { data } = event;

                if (data.nextAction) {
                    return {
                        ...context,
                        nextAction: data.nextAction,
                    };
                }

                if (data.details) {
                    return {
                        ...context,
                        details: data.details,
                    };
                }

                return context;
            }),
        },

        guards: {
            hasDuplicates: (_, event) => {
                // This is because the events we define above and invokable
                // events are the same type and is passed here. This is ugly
                // but this narrows things down to the default `AnyEventObject`
                // type.
                if (
                    event.type ===
                    'done.invoke.addPatient.details.searching:invocation[0]'
                ) {
                    return (
                        (event.data?.email ?? false) ||
                        (event.data?.phone ?? false)
                    );
                }

                return false;
            },
        },

        services: {
            searchForDuplicates: (context) => {
                const { details } = context;

                const params: Partial<
                    Pick<ContactDetailsContext, 'email' | 'phone'>
                > = {};

                if (details?.contact.email) {
                    params.email = details.contact.email;
                }

                if (details?.contact.phone) {
                    params.phone = details.contact.phone;
                }

                return api
                    .get('/actions/exists', { params })
                    .then(({ data }) => data);
            },

            savePatient: (context) => {
                const { contact, marketing, labels } = context.details;
                const { nextAction } = context;

                const [firstName, lastName] = (() => {
                    const names = contact.name.split(' ');

                    if (names.length === 1) {
                        return [names[0]];
                    } else {
                        return [
                            dropRight(names).join(' '),
                            names[names.length - 1],
                        ];
                    }
                })();

                return api
                    .post<AddPatientResponse>('/v2/actions', {
                        firstName,
                        lastName,
                        phone: contact.phone,
                        email: contact.email,
                        gdpr: marketing.consented,
                        txType: marketing.treatmentType,
                        date: nextAction.date,
                        stage: 'newPatient',
                        nextAction: nextAction.action,
                        note: nextAction.note,
                        source: marketing.referralSource,
                        labels,
                    })
                    .then(({ data }) => data);
            },
        },
    },
);
