import * as React from 'react';
import styled from 'styled-components';
import { Submenu } from './Submenu';
import { Text } from '$ui/Flo/Text';
import { Badge } from '$ui/Flo/Badge';
import { applyBg, mix } from '$ui/Flo/util';
import { Icon, IconName } from '$ui/Flo/Icon';

interface Props {
    title: string;
    icon: IconName;
    selected?: boolean;
    alert?: boolean;
    counter?: number;
    children?: React.ReactNode;
    onClick?: () => void;
}

export const Item = (props: Props) => {
    const {
        title,
        icon,
        children,
        onClick,
        counter = 0,
        selected = false,
        alert = false,
    } = props;

    const i = <NavIcon icon={icon} hue="white" />;

    return (
        <>
            <Box selected={selected} onClick={onClick} data-qa={title}>
                <Label>
                    {i} <Text dark>{title}</Text>
                </Label>
                {counter > 0 && <Badge count={counter} />}
                {alert && <Badge alert />}
            </Box>

            {children && <Submenu open={selected}>{children}</Submenu>}
        </>
    );
};

const NavIcon = styled(Icon)`
    ${mix.margin({ margin: [0, 2, 0, 0] })};
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface BoxProps {
    selected: boolean;
}

const Box = styled.div<BoxProps>`
    ${mix.padding({ padding: 2 })};
    ${mix.bg({ hue: 'primary', shade: '1', alpha: 0.5 })};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: ${(props) => (props.selected ? 'initial' : 'pointer')};
    transition: all 200ms ease;
    position: relative;
    border-bottom: 1px solid ${mix.palette({ hue: 'primary', shade: '2' })};

    &:hover {
        ${mix.bg({ hue: 'primary', shade: '1' })};
    }

    ${({ selected, theme }) =>
        selected && applyBg({ theme, hue: 'primary', shade: '1' })}
`;
