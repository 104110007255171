import { EmailTemplate } from '$state/types/automations';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
    email: EmailTemplate | null;
}

export const EmailViewer = (props: Props) => {
    const { email } = props;

    if (!email) {
        return null;
    }

    return (
        <Mount
            dangerouslySetInnerHTML={{
                __html: email.html
            }}
        ></Mount>
    );
};

const Mount = styled.div`
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
    ${mix.padding({ padding: [4, 0, 0, 0] })}
`;
