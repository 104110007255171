import { api } from '$api/api';
import { data } from '$api/utils';

interface IntegrationsPayload {
    integration: string;
    display_name: string;
    description: string;
    logo: string;
    state: 'healthy' | 'unhealthy' | 'disconnected';
    latest_sync: SyncPayload | null;
}

interface SyncPayload {
    id: string;
    state: 'queued' | 'syncing' | 'complete' | 'failed';
    queued_at: string;
    syncing_at: string | null;
    completed_at: string | null;
    failed_at: string | null;
    error: string | null;
}

export const load = (): Promise<Array<IntegrationsPayload>> =>
    data(api.get('/integrations'));

interface ConnectionPayload {
    url: string;
}

export const connect = (integration: string): Promise<ConnectionPayload> =>
    data(api.put(`/integrations/${integration}/connection`));

export const disconnect = (integration: string): Promise<void> =>
    data(api.delete(`/integrations/${integration}/connection`));

export const sync = (integration: string): Promise<void> =>
    data(api.post(`/integrations/${integration}/sync`));
