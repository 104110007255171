import React, { useEffect, useState } from 'react';
import { GeneratedPreview } from '$ui/Settings/Signature/GeneratedPreview';
import { CustomPreview } from '$ui/Settings/Signature/CustomPreview';
import { Editor } from '$ui/Settings/Signature/Editor';
import { DetailsRecord } from '$ui/Settings/Forms/Details';
import { useAppDispatch } from '$state';
import { signatureMachine } from './Machine';
import { useMachine } from '@xstate/react';
import { SelectedStatus } from '$ui/Flo/Button/ConfirmDeleteButton';
import { LoadingSignature } from '$ui/Settings/Signature/Loading';
import { removeSignature, saveSignature } from '$api';
import { disableSignature, updateSignature } from '$state/concerns/client';

interface Props {
    details: DetailsRecord;
    repPhoto: string | File | null;
}

export const Signature = ({ details, repPhoto }: Props) => {
    const dispatch = useAppDispatch();
    const html = details.signature?.html ?? '';
    const [htmlPreview, setHtmlPreview] = useState<string>(html);
    const [current, send] = useMachine(signatureMachine);
    const history = current.history?.value;
    const generated = current.matches('generated');
    const previewing = current.matches('previewing');
    const editing = current.matches('editing');

    const handleStatus = (): SelectedStatus => {
        if (current.matches({ previewing: 'saving' })) {
            return 'saving';
        }
        if (current.matches({ previewing: 'saved' })) {
            return 'saved';
        }
        if (current.matches({ custom: 'disabling' })) {
            return 'deleting';
        }
        if (current.matches({ custom: 'disabled' })) {
            return 'deleted';
        }

        return 'init';
    };

    useEffect(() => {
        if (details.signature) {
            const isCustom = details.signature.enabled;
            isCustom ? send('custom') : send('generated');

            if (details.signature.html) {
                setHtmlPreview(details.signature.html);
            }
        }
    }, [details.signature]);

    const onModify = () => {
        send('modify');
    };

    if (!details.signature) {
        return <LoadingSignature />;
    }

    if (generated) {
        return (
            <GeneratedPreview
                details={details}
                repPhoto={repPhoto}
                onModify={onModify}
            />
        );
    }

    if (previewing) {
        return (
            <CustomPreview
                html={htmlPreview}
                preview={true}
                status={handleStatus()}
                onSave={() => {
                    send('save');
                    saveSignature({ html: htmlPreview }).then(() => {
                        send('done');
                        dispatch(updateSignature(htmlPreview));
                    });
                }}
                onCancel={() => {
                    send('cancel');
                }}
            />
        );
    }

    if (editing) {
        const onEditorModify = (html: string) => {
            setHtmlPreview(html);
            send('validate');
        };

        if (history === 'generated') {
            return (
                <Editor
                    html={htmlPreview}
                    onModify={onEditorModify}
                    onCancel={() => {
                        send('disable'); // reverts back to generated
                    }}
                />
            );
        }
        return (
            <Editor
                html={htmlPreview}
                onModify={onEditorModify}
                onCancel={() => {
                    send('cancel'); // reverts back to custom
                }}
            />
        );
    }

    return (
        <CustomPreview
            html={htmlPreview}
            status={handleStatus()}
            onDelete={() => {
                send('disable');
                removeSignature().then(() => {
                    send('done');
                    dispatch(disableSignature());
                });
            }}
            onEdit={() => {
                send('edit');
            }}
        />
    );
};
