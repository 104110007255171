import { LegacyV2ActionSchema } from '$types/legacy';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Icon as FloIcon } from '$ui/Flo/Icon';
import { Hue } from '$ui/Flo/types';
import { dates, phone as phoneUtil } from '$utils';
import { CountryCode } from '@/utils/phone';

interface Props {
    action: LegacyV2ActionSchema;
    open?: boolean;
    isNew?: boolean;
    isStale?: boolean;
    onClick: () => void;
    isWaking?: boolean;
    country: CountryCode;
}

export const ActionSummary = (props: Props) => {
    const { stage, snooze } = props.action;
    const isSnoozing = !!(snooze && snooze.state !== 'waking');

    return (
        <Container
            data-cy="action-summary"
            onClick={props.onClick}
            open={props.open}
            isNew={props.isNew}
            isStale={props.isStale}
            isWakingUp={props.isWaking}
        >
            {icon(stage, isSnoozing)}
            <Title isSnoozing={isSnoozing}>
                {heading(props.action, props.country)}
            </Title>
            {dueOn(props.action, props.isWaking)}
        </Container>
    );
};

const heading = (action: LegacyV2ActionSchema, country: CountryCode) => {
    const {
        stage,
        phone: unformattedPhone,
        first_name,
        last_name,
        type,
    } = action;

    const phone = phoneUtil.format(unformattedPhone, country);
    const name = [first_name, last_name].join(' ');

    const callback = (attempt: number) => {
        const attempts = ['2nd', '3rd', 'Final'];
        return (
            <>
                Call <b>{name}</b> about <b>{type}</b> treatment on{' '}
                <b>{phone}</b> <em>({attempts[attempt - 1]} attempt)</em>
            </>
        );
    };

    return {
        newLead: (
            <>
                Call <b>{name}</b> about <b>{type}</b> treatment on{' '}
                <b>{phone}</b>
            </>
        ),
        callback1: callback(1),
        callback2: callback(2),
        callback3: callback(3),
        working: (
            <>
                Continue discussions with <b>{name}</b> about <b>{type}</b>{' '}
                treatment on <b>{phone}</b>
            </>
        ),
        thinking: (
            <>
                Chase <b>{name}</b> on <b>{phone}</b> about treatment decision
            </>
        ),
        consultation: (
            <>
                Record the outcome of <b>{name}</b> initial consultation
            </>
        ),
        txPlanConsult: (
            <>
                Record the outcome of <b>{name}</b> treatment plan consultation
            </>
        ),
        inTx: (
            <>
                Record progress of <b>{name}</b> treatment
            </>
        ),
    }[stage];
};

const icon = (stage: string, isSnoozing: boolean) => {
    const props = {
        hue: 'primary' as Hue,
        size: 2.5,
        opacity: 1,
        className: 'action-summary__icon',
    };

    if (['working', 'thinking'].includes(stage)) {
        return (
            <FloIcon
                icon="MessageCircle"
                {...props}
                opacity={isSnoozing ? 0.5 : 1}
            />
        );
    }

    return ['newLead', 'callback1', 'callback2', 'callback3'].includes(
        stage,
    ) ? (
        <FloIcon icon="Phone" {...props} opacity={isSnoozing ? 0.5 : 1} />
    ) : (
        <FloIcon icon="Calendar" {...props} opacity={isSnoozing ? 0.5 : 1} />
    );
};

const dueOn = (action: LegacyV2ActionSchema, isWaking: boolean | undefined) => {
    const { snooze } = action;
    if (snooze && !isWaking) {
        return (
            <DueText isSnoozing={true}>
                <ActionIcon icon="Moon" isSnoozing={true} opacity={1} />
                Snoozed ends {dates.delta(snooze.ends_at)}
            </DueText>
        );
    }
    return (
        <DueText>
            <ActionIcon isSnoozing={false} icon="Calendar" />{' '}
            {dates.long(action.date, false)}
        </DueText>
    );
};

const DueText = styled.span<{ isSnoozing?: boolean }>`
    ${mix.type({ level: 'small' })}
    ${mix.color({ profile: 'secondary' })}
    margin-left: auto;
    margin-right: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${mix.gap({ size: 0.5 })};

    ${({ isSnoozing }) =>
        isSnoozing &&
        css`
            color: var(--primary-500);
        `}
`;

const Title = styled.span<{ isSnoozing: boolean }>`
    ${mix.type({ level: 'body2' })}
    font-size: 16px; // looks far better at 16px
    display: inline;
    text-rendering: geometricPrecision;

    ${({ isSnoozing }) =>
        isSnoozing &&
        css`
            opacity: 0.5;
        `}
`;

const invert = css`
    ${mix.bg({ hue: 'primary', shade: '1' })}
    ${DueText} {
        color: white;
    }

    ${Title} {
        color: white;
    }

    .action-summary__icon {
        stroke: white;
    }
`;

const Container = styled.div<{
    open?: boolean;
    isNew?: boolean;
    isStale?: boolean;
    isWakingUp?: boolean;
}>`
    background: var(--gray-100);
    color: var(--gray-600);
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    ${mix.padding({ padding: [0, 2] })}
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1;
    ${mix.gap({ size: 1.5 })};
    ${mix.height({ size: 7 })};
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
        ${invert}
        svg {
            stroke: var(--gray-400);
        }
    }

    ${({ isWakingUp, isStale }) =>
        isWakingUp &&
        !isStale &&
        css`
            border: 1px solid;
            border-color: var(--error-500);
            border-radius: ${mix.unit({ size: 0.5 })};
            border-top-left-radius: 0;
        `}

    ${({ isStale }) =>
        isStale &&
        css`
            border: 1px solid;
            border-color: var(--warning-500);
            border-radius: ${mix.unit({ size: 0.5 })};
            border-top-left-radius: 0;
        `}

    ${({ open }) => open && invert}

    ${({ isNew, open, isWakingUp }) =>
        isNew &&
        !open &&
        !isWakingUp &&
        css`
            box-shadow: 0 0 0 2px var(--primary-400);
        `}
`;

const ActionIcon = styled(FloIcon)<{
    isSnoozing: boolean;
}>`
    stroke: var(--gray-600);

    ${({ isSnoozing }) =>
        isSnoozing &&
        css`
            stroke: var(--primary-500);
        `}
`;
