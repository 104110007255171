import { mix } from '$ui/Flo/util';
import styled, { css } from 'styled-components';

interface ContainerProps {
    inbound?: boolean;
    clickable?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    gap: ${mix.unit({ size: 2 })};

    ${({ inbound }) => {
        return (
            !inbound &&
            css`
                flex-direction: row-reverse;
            `
        );
    }};

    ${({ clickable }) => {
        return (
            clickable &&
            css`
                &:hover {
                    cursor: pointer;
                }
            `
        );
    }}
`;
