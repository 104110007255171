import { Label } from '$ui/Flo/Label';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    labels: string[];
    onClick: (label: string) => void;
}

export const RecentLabels = (props: Props) => {
    const { labels, onClick } = props;
    return (
        <Panel>
            <Heading>Recent labels</Heading>
            <Labels>
                {labels.map((label) => (
                    <Label
                        mode="outline"
                        round
                        hue="grey"
                        shade="9"
                        key={label}
                        onClick={() => {
                            onClick(label);
                        }}
                    >
                        {label}
                    </Label>
                ))}
            </Labels>
        </Panel>
    );
};

const Panel = styled.div`
    ${mix.color({ profile: 'body' })};
    position: absolute;
    width: 100%;
    ${mix.round({ rounded: true })};
    background: white;
    ${mix.padding({ padding: [2] })};
    ${mix.gap({ size: 1 })};
    ${mix.shadow({ shadow: '1' })};
    z-index: 1;
    display: flex;
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    ${mix.gap({ size: 1 })}
    flex-direction: column;
    max-height: 200px;
`;

const Heading = styled.div`
    ${mix.type({ level: 'small', bold: true })};
    ${mix.color({ profile: 'body' })};
    text-transform: uppercase;
`;

const Labels = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    ${mix.gap({ size: 1 })};
`;
