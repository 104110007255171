import { fn } from '$utils';

// https://dev.to/namirsab/comment/2050
export const range = (start: number, end: number): number[] => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
};

/**
 * Given an array of items of objects and a key, returns an array of values for that key.
 */
export const pluck = <T extends object, TKey extends keyof T>(
    items: T[],
    key: TKey
): T[TKey][] => items.map(fn.prop(key));

/**
 * Given a list of items returns a new list with only
 * unique items. Accepts an optional identity function
 * to convert each item in the list to a comparable identity
 * value
 *
 * From: https://github.com/rayepps/radash/blob/master/src/array.ts
 */
export const unique = <T, K extends string | number | symbol>(
    array: readonly T[],
    toKey?: (item: T) => K
): T[] => {
    const valueMap = array.reduce((acc, item) => {
        const key = toKey
            ? toKey(item)
            : (item as any as string | number | symbol);
        if (acc[key]) return acc;
        acc[key] = item;
        return acc;
    }, {} as Record<string | number | symbol, T>);
    return Object.values(valueMap);
};

/**
 * Convert an array to a dictionary by mapping each item
 * into a dictionary key & value
 *
 * From: https://github.com/rayepps/radash/blob/master/src/array.ts
 */
export const keyBy = <T, Key extends string | number | symbol, Value = T>(
    array: readonly T[],
    getKey: (item: T) => Key,
    getValue: (item: T) => Value = (item) => item as unknown as Value
): Record<Key, Value> => {
    return array.reduce((acc, item) => {
        acc[getKey(item)] = getValue(item);
        return acc;
    }, {} as Record<Key, Value>);
};

/**
 * Given an array of items, a function selecting a value and an sort order (defaulting
 * to 'asc'), returns a new, sorted array
 */
export const sortBy = <T>(
    array: readonly T[],
    selector: (item: T) => any,
    order: 'asc' | 'desc' = 'asc'
): T[] => {
    const dir = 1;

    const sorted = [...array].sort((a, b) => {
        const aVal = selector(a);
        const bVal = selector(b);

        if (aVal < bVal) {
            return -dir;
        }

        if (aVal > bVal) {
            return dir;
        }

        return 0;
    });
    return order === 'asc' ? sorted : sorted.reverse();
};

/**
 * Samples a single element from an array
 */
export const sample = <T>(array: readonly T[]): T => {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
};

/**
 * Given a positive number and a function, returns an array of the function called
 * with the numbers 0 to n-1
 */
export const times = <T>(n: number, fn: (i: number) => T): T[] => {
    return range(0, n - 1).map(fn);
};

/**
 * Get n unique, random elements from an array
 */
export const sampleSize = <T>(array: readonly T[], n: number): T[] => {
    const shuffled = [...array].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, n);
};

/**
 * Get the maximum value of an array of comparable values
 */
export const max = <T>(array: readonly T[]): T => {
    return array.reduce((acc, item) => {
        if (item > acc) return item;
        return acc;
    });
};

/**
 * Creates a slice of array with n elements dropped from the end, where n
 * defaults to 1
 */
export const dropRight = <T>(array: readonly T[], n = 1): T[] => {
    return array.slice(0, array.length - n);
};

/**
 * Get the last element of an array, or undefined if the array is empty
 */
export const last = <T>(array: readonly T[]): T | undefined => {
    return array[array.length - 1];
};
