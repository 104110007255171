import React from 'react';
import {
    Container,
    StatusContainer,
    StatusIcon,
    SyncStatus,
} from '$ui/Integrations/Elements';
import { Heading } from '$ui/Flo/Heading';
import { Button } from '$ui/Flo/Button';
import { str } from '$utils';
import styled from 'styled-components';

interface Props {
    integration: string;
    syncing?: boolean;
    repaired?: boolean;
    onSync: () => void;
    instructions?: string;
}

export const Success = (props: Props) => {
    const { integration, syncing, repaired } = props;

    const text = repaired
        ? `${integration} integration repaired`
        : `Your account has successfully integrated with ${integration}`;

    return (
        <Container>
            <StatusIcon mode={syncing ? 'syncing' : 'healthy'} />
            <Heading level="body1" profile="body">
                {text}
            </Heading>
            <StatusContainer>
                <SyncStatus integration={integration} syncing={syncing} />
            </StatusContainer>
            <SyncActions {...props} />
        </Container>
    );
};

const SyncActions = (props: Props) => {
    const { integration, repaired, syncing, onSync, instructions } = props;

    const verb = repaired ? 'resync' : 'sync';

    if (syncing) {
        return (
            <Heading level="body1" profile="body">
                We are now {verb}ing your {integration} account with Leadflo.
                This may take a couple of minutes.
            </Heading>
        );
    }

    return (
        <Container>
            <Heading level="body1" profile="body">
                You can now {verb} your {integration} account with Leadflo
            </Heading>

            {instructions && (
                <InstructionsContainer>
                    <Heading level="body2" profile="body">
                        {instructions}
                    </Heading>
                </InstructionsContainer>
            )}

            <Button
                icon="RotateCcw"
                mode="outline"
                border="x-small"
                rounded
                onClick={onSync}
            >
                {str.capitalize(verb)} with {integration}
            </Button>
        </Container>
    );
};

const InstructionsContainer = styled.div`
    max-width: 800px;
`;
