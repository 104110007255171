import { Heading } from '$ui/Flo/Heading';
import { Text } from '$ui/Flo/Text';
import { Toggle } from '$ui/Flo/Toggle';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Container } from './Elements';

import { Nodes } from '$ui/Automations/Nodes';
import {
    useAppDispatch,
    useAppSelector,
    usePlaceholdersLoader
} from '$state/hooks';
import {
    workflow,
    workflowLoaded,
    workflowNodes
} from '$state/queries/automations';
import {
    enableAction,
    enableWorkflow,
    loadAction,
    loadWorkflow,
    openTemplate
} from '$state/concerns/automations';
import { Triggers } from '$ui/Automations/Triggers';
import { mix } from '$ui/Flo/util';
import { Action as ActionSchema, Workflow } from '$state/types/automations';
import { Shadow } from '$ui/Flo/Shadow';
import { Action } from '$ui/Automations/Action';
import { Connector } from '$ui/Automations/Connector';
import { Link } from 'react-router-dom';
import { TestTool } from '$ui/Automations/TestTool';
import { usePageTitle } from '@/utils/hooks';

interface MatchParams {
    id: string;
}

type Props = RouteComponentProps<MatchParams>;

export const Automation = (props: Props) => {
    const id = props.match.params.id;
    const dispatch = useAppDispatch();

    usePlaceholdersLoader();

    React.useEffect(() => {
        dispatch(loadWorkflow(id));
    }, [id]);


    const flow = useAppSelector(workflow(id));
    const flowNodes = useAppSelector(workflowNodes(id));

    usePageTitle(`${flow?.name ?? 'Loading'} - Automations`);

    if (!flow || !workflowLoaded(flow) || !flowNodes) {
        return <Loading workflow={flow} />;
    }

    const openNode = (action: ActionSchema) => {
        dispatch(
            loadAction({
                action: action.id
            })
        );
        dispatch(openTemplate({ open: true, action }));
    };

    const nodes = (
        <Nodes
            nodes={flowNodes}
            onEnable={(action, enabled) =>
                dispatch(enableAction({ id: action, enabled }))
            }
            onView={openNode}
        />
    );

    const onEnableWorkflow = (id: string) => (enabled: boolean) => {
        dispatch(enableWorkflow({ id, enabled }));
    };

    return (
        <Container>
            <TitleBar>
                <Heading level="h3" align="left">
                    <Link to="/automations">
                        <Text level="small">Automations</Text>
                    </Link>
                    {flow.name}
                </Heading>
                <Toggler>
                    <Text level="button" profile="body">
                        ENABLED
                    </Text>
                    <span style={{ width: '8px' }} />
                    <Toggle
                        enabled={flow.enabled}
                        onChange={onEnableWorkflow(id)}
                    />
                </Toggler>
            </TitleBar>

            <Layout>
                <LeftColumn>
                    <div>{nodes}</div>
                </LeftColumn>
                <RightColumn>
                    <Triggers workflow={flow} />
                    <TestTool id={id} />
                </RightColumn>
            </Layout>
        </Container>
    );
};

const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${mix.margin({ margin: [0, 0, 4, 0] })}
`;

const LeftColumn = styled.div`
    grid-column-start: left;
`;

const RightColumn = styled.div`
    grid-column-start: right;
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
`;

const Toggler = styled.div`
    display: flex;
    flex-direction: row;
`;

const Layout = styled.div`
    display: grid;
    grid-template-columns: [left] 2fr [right] 1fr;
    align-items: start;
    column-gap: ${mix.unit({ size: 5 })};
`;

const Loading = ({ workflow }: { workflow: Workflow | undefined }) => (
    <Container>
        <TitleBar>
            <Heading level="h3" align="left">
                <span style={{ width: '50vw' }} />
                <Text level="small">Automations</Text>
                {workflow?.name || <Shadow height={6} width={40} rounded />}
            </Heading>
            <Toggler>
                <Text level="button" profile="body">
                    ENABLED
                </Text>
                <span style={{ width: '8px' }} />
                <Toggle
                    enabled={workflow?.enabled || true}
                    disabled
                    onChange={() => null}
                />
            </Toggler>
        </TitleBar>

        <Layout>
            <div>
                <Action
                    onView={() => null}
                    onEnable={() => null}
                    loading
                    action={{
                        id: 'abc',
                        type: 'action',
                        loaded: false,
                        enabled: true,
                        name: 'loading',
                        action: 'email',
                        requiresCustomisation: false,
                        condition: null
                    }}
                />
                {Array(6).fill(
                    <>
                        <Connector type="action-action" />
                        <Action
                            onView={() => null}
                            onEnable={() => null}
                            loading
                            action={{
                                id: 'abc',
                                type: 'action',
                                loaded: false,
                                enabled: true,
                                name: 'loading',
                                action: 'email',
                                requiresCustomisation: false,
                                condition: null
                            }}
                        />
                    </>
                )}
            </div>
            <Triggers workflow={workflow} />
        </Layout>
    </Container>
);
