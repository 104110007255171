import { success } from '$ui/Flo/ToastV2';
import { fn } from '$utils';
import React from 'react';

interface UseClickToCopyArgs<T> {
    enabled: boolean;
    value: string | null;
    transform?: (value: string) => string;
    onCopied?: (e: React.MouseEvent<T>) => void;
}

export const useClickToCopy = <T>({
    enabled,
    value,
    transform = fn.identity,
    onCopied = () => null,
}: UseClickToCopyArgs<T>) => {
    return (e: React.MouseEvent<T>) => {
        if (!value) {
            return;
        }

        if (!enabled) {
            return;
        }

        navigator.clipboard
            .writeText(transform(value))
            .then(() =>
                success({
                    id: value,
                    message: 'Copied to clipboard!',
                }),
            )
            .then(() => onCopied(e));
    };
};
