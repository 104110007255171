import { api } from './api';
import { Template } from '$state/types/templates';

export interface LoadTemplatePayload {
    name: string;
    templates: Array<Template>;
    approval: boolean;
}

export const loadTemplates = (): Promise<Array<LoadTemplatePayload>> =>
    api.get('/templates').then(({ data }) => data);

export const saveTemplate = (template: Template): Promise<void> =>
    api.put(`/templates/${template.id}`, template);

export const deleteTemplate = (template: Template): Promise<void> =>
    api.delete(`/templates/${template.id}`);
