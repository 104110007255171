import React from 'react';
import { Heading } from '$ui/Flo/Heading';
import { Button } from '$ui/Flo/Button';
import {
    ButtonGroup,
    Container,
    LogoContainer,
    StatusContainer,
    SyncStatus,
} from '$ui/Integrations/Elements';
import styled from 'styled-components';

interface Props {
    logo: React.ElementType;
    integration: string;
    syncing?: boolean;
    onDisconnect: () => void;
    onSync: () => void;
    instructions?: string;
}

export const Manage = (props: Props) => {
    const {
        logo: Logo,
        integration,
        onDisconnect,
        onSync,
        syncing,
        instructions,
    } = props;
    return (
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <Heading level="body1">
                Your integration with {integration} is healthy
            </Heading>
            <StatusContainer>
                <SyncStatus integration={integration} syncing={syncing} />
            </StatusContainer>

            {instructions && (
                <InstructionsContainer>
                    <Heading level="body2" profile="body">
                        {instructions}
                    </Heading>
                </InstructionsContainer>
            )}

            <ButtonGroup>
                <Button
                    onClick={onDisconnect}
                    rounded
                    border="x-small"
                    hue="red"
                    mode="outline"
                    icon="CloudOff"
                >
                    Disconnect {integration}
                </Button>
                {!syncing && (
                    <Button
                        onClick={onSync}
                        rounded
                        border="x-small"
                        mode="outline"
                        icon="RotateCcw"
                    >
                        Resync with {integration}
                    </Button>
                )}
            </ButtonGroup>
        </Container>
    );
};

const InstructionsContainer = styled.div`
    max-width: 800px;
`;
