import { Button } from '$ui/Flo/Button';
import React from 'react';
import { createMachine } from 'xstate';
import { useMachine } from '@xstate/react';

const flow = createMachine({
    id: 'back',
    initial: 'idle',
    states: {
        idle: {
            on: {
                EXIT: {
                    target: 'confirming'
                }
            }
        },
        confirming: {
            after: {
                5000: { target: 'idle' }
            }
        }
    }
});

interface Props {
    onExit: () => void;
}

export const BackButton = ({ onExit }: Props) => {
    const [current, send] = useMachine(flow);

    if (current.matches('idle')) {
        return <DefaultBackButton onExit={() => send('EXIT')} />;
    }

    return <ConfirmingBackButton onExit={onExit} />;
};

export const DefaultBackButton = ({ onExit }: Props) => (
    <Button
        rounded
        hue="grey"
        shade="5"
        mode="text"
        icon="ArrowLeft"
        onClick={onExit}
    >
        Back to Integrations
    </Button>
);

export const ConfirmingBackButton = ({ onExit }: Props) => (
    <Button
        rounded
        hue="red"
        shade="5"
        mode="text"
        icon="ArrowLeft"
        onClick={onExit}
    >
        Are you sure?
    </Button>
);
