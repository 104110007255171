import React from 'react';
import { Align, Hue, Shade } from '$ui/Flo/types';
import { Metric } from './Metric';
import { useAppSelector } from '$state/hooks';
import { selectMetric } from '$state/queries/reporting';
import { useHistory } from 'react-router';
import { History } from 'history';

interface Props {
    id: string | null;
    report: string;
    name: string;
    label: string;
    hue?: Hue;
    shade?: Shade;
    tooltip?: string;
    tooltipAlign?: Align;
    fill?: boolean;
    unit?: 'absolute' | 'interval';
}

export const ConnectedMetric = (props: Props) => {
    const metric = useAppSelector(selectMetric(props.report, props.name));
    const history = useHistory();

    return (
        <Metric
            {...props}
            {...metric}
            id={props.id}
            onClick={props.id ? openMetric(history, props.id) : undefined}
        />
    );
};

interface BrokenProps extends Props {
    name: 'lost' | 'losses';
}

export const ConnectedBrokenMetric = (props: BrokenProps) => {
    const metric = useAppSelector(selectMetric(props.report, props.name));
    const notInterested = useAppSelector(
        selectMetric(props.report, 'not_interested')
    );
    const maybeFuture = useAppSelector(
        selectMetric(props.report, 'maybe_future')
    );
    const blocked = useAppSelector(selectMetric(props.report, 'blocked'));
    const history = useHistory();

    // TODO: Refactor these so they follow lost, maybeFuture and blocked
    const breakdown = [
        { value: notInterested.value, label: 'Will never be interested' },
        { value: maybeFuture.value, label: 'Not interested right now' },
        { value: blocked.value, label: 'Blocked', uncounted: true }
    ];

    return (
        <Metric
            {...props}
            {...metric}
            breakdown={breakdown}
            id={props.id}
            onClick={props.id ? openMetric(history, props.id) : undefined}
        />
    );
};

const openMetric = (history: History, report: string) => () => {
    history.push(`/patients/${report}`);
};
