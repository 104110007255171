import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '$state';
import { Template } from '$state/types/templates';
import { Icon } from '$ui/Flo/Icon';
import { ChannelConfig } from '$state/types';
import { closePatient } from '$state/concerns/patient';
import { useHover } from '@/utils/hooks';

interface Props {
    channel: ChannelConfig;
    template: Template;
    setTemplate: (template: Template) => void;
    disabled: boolean;
}

export const ListTemplate = (props: Props) => {
    const { channel, template, setTemplate, disabled } = props;

    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);

    const history = useHistory();
    const dispatch = useAppDispatch();

    const onEdit = (template: Template) => {
        history.push(
            `/settings/templates/${channel.channel}/edit/${template.id}`,
        );
        dispatch(closePatient());
    };

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    const tooltip = disabled && template.status && tooltipText(template.status);

    return (
        <List
            ref={hoverRef}
            onClick={() => !disabled && setTemplate(template)}
            disabled={disabled}
        >
            {tooltip && hover && <Tooltip>{tooltip}</Tooltip>}

            {!(tooltip && hover) && (
                <>
                    <Icon
                        icon={channel.icon}
                        size={1.5}
                        clickable={true}
                        opacity={iconOpacity(hover, disabled)}
                    />
                    <Name>{template.name}</Name>
                    <Icon
                        icon="Edit3"
                        size={1.5}
                        clickable={true}
                        opacity={iconOpacity(hover, disabled)}
                        onClick={() => onEdit(template)}
                    />
                </>
            )}
        </List>
    );
};

const iconOpacity = (hover: boolean, disabled: boolean): number => {
    if (disabled) {
        return 0.4;
    }
    return hover ? 1 : 0.7;
};

const tooltipText = (status: string) =>
    status === 'NOT_SUBMITTED'
        ? 'Not submitted for approval'
        : 'Pending approval';

const List = styled.div<{ disabled: boolean }>`
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${mix.unit({ size: 1 })};
    ${mix.padding({ padding: [0, 1, 0, 1] })};
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-600);
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    cursor: pointer;
    font-weight: 400;

    ${({ disabled }) => {
        if (disabled) {
            return css`
                background-color: var(--gray-100);
                color: var(--gray-400);
            `;
        }

        return css`
            &:hover {
                background-color: var(--gray-100);
                color: var(--gray-900);
            }
        `;
    }}
`;

const Name = styled.span`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const Tooltip = styled.span`
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-style: italic;
    color: var(--gray-600);
`;
