import { mix } from '$ui/Flo/util';
import { Nav } from '$ui/Settings/Nav';
import React from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { TopLevelItem } from '$state/types/settings';

export interface TabItemProps extends TopLevelItem {
    exact?: boolean;
    uri?: string;
    component: React.ComponentType<any>;
}

export interface TabProps {
    items: Array<TabItemProps>;
    path: string;
    active?: string;
}

export const Tab = (props: TabProps) => {
    const { items, path, active } = props;
    const history = useHistory();
    const location = useLocation();

    const onSectionClicked = (section: string, subsection?: string) => {
        const hash = subsection ? `#${subsection}` : '';
        history.push(`/${path}/${section}${hash}`);
    };

    if (location.pathname === `/${path}` && active) {
        onSectionClicked(active);
    }

    const section = location.pathname.replace(
        new RegExp(`^/${path}/([a-z-]+).*`),
        '$1'
    );

    const subsection = location.hash.replace('#', '');

    const [currentSubsection, setSubsection] =
        React.useState<string>(subsection);

    React.useLayoutEffect(() => {
        if (subsection !== currentSubsection) {
            document.getElementById(subsection)?.scrollIntoView({
                behavior: 'smooth'
            });
            setSubsection(subsection);
        }
    }, [subsection]);

    // so the transition doesn't trigger on sub-URIs inside reporting pages

    const rootUri = location.pathname.replace(
        new RegExp(`^/${path}/([a-z-]+).*`),
        '$1'
    );

    return (
        <>
            <Grid>
                <NavCell>
                    <Nav
                        section={section || undefined}
                        subsection={subsection || undefined}
                        onSectionClicked={onSectionClicked}
                        items={items}
                    />
                </NavCell>
                <SectionCell>
                    <TransitionGroup component={null}>
                        <CSSTransition
                            timeout={300}
                            classNames="section"
                            key={rootUri}
                        >
                            <Switch location={location}>
                                {items.map((item) => (
                                    <Route
                                        key={item.key}
                                        exact={item.exact}
                                        path={
                                            item.uri ?? `/${path}/${item.key}`
                                        }
                                        component={item.component}
                                    />
                                ))}
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </SectionCell>
            </Grid>
        </>
    );
};

const Grid = styled.div`
    display: grid;
    height: calc(100vh - 54px);
    grid-template-columns:
        [nav] ${mix.unit({ size: 28 })}
        [section] 1fr;
`;

const NavCell = styled.div`
    ${mix.bg({ hue: 'grey', shade: '10' })};
    grid-column-start: nav;
    display: flex;
    flex-direction: column;
`;

const SectionCell = styled.div`
    background: white;
    grid-column-start: section;
    overflow: auto;
    position: relative;
`;
