import React from 'react';
import { useWorkflowSwitch } from '$ui/Integrations/Workflows/workflows';
import {
    Loading,
    OutlookManage,
    OutlookRepair,
    OutlookSetup
} from '$ui/Integrations/Workflows';

export const ConnectedOutlook = () => {
    const { integration, loadingStatus, commonProps } =
        useWorkflowSwitch('outlook');

    if (loadingStatus !== 'loaded') {
        return (
            <Loading integration="Outlook" onFinish={commonProps.onFinish} />
        );
    }

    if (integration.state === 'healthy') {
        return <OutlookManage {...commonProps} />;
    }

    if (integration.state === 'unhealthy') {
        return <OutlookRepair {...commonProps} />;
    }

    return <OutlookSetup {...commonProps} />;
};

export * from './OutlookSetup';
export * from './OutlookManage';
export * from './OutlookRepair';
