import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';

interface SubsectionProps {
    id: string;
    title?: string;
    children: React.ReactNode;
    padded?: boolean;
    actions?: React.ReactNode;
}

export const Subsection = ({
    id,
    children,
    padded,
}: SubsectionProps) => {
    return (
        <Container id={id} data-testid={id}>
            <Content padded={padded ?? false}>{children}</Content>
        </Container>
    );
};

const Container = styled.div`
    background-color: var(--gray-100);
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    border-radius: 6px;
    max-width: ${mix.unit({ size: 90 })};
`;

const Content = styled.div<{ padded: boolean }>`
    ${({ padded }) =>
        padded &&
        css`
            ${mix.padding({ padding: 4 })};
        `}
`;
