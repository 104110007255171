import { RootState } from '../store';
import { AuthState, AuthStatus } from '../types';

export const authState = (state: RootState): AuthState => state.auth;

export const withAuthState =
    <T>(f: (state: AuthState) => T) =>
    (rootState: RootState) =>
        f(authState(rootState));

export const isAuthed = withAuthState(
    (state: AuthState) => state.status === AuthStatus.AUTHED
);

export const isAuthErrored = withAuthState(
    (state: AuthState) => state.status === AuthStatus.AUTH_ERRORED
);

export const authError = withAuthState((state: AuthState) => state.error);

export const token = withAuthState((state: AuthState) => state.token);

export const role = withAuthState((state: AuthState) => state.role || 'user');

export const txTypes = withAuthState((state: AuthState) => state.txTypes ?? []);

export const subscriberId = withAuthState(
    (state: AuthState) => state.subscriberId ?? ''
);

export const subscriberHash = withAuthState(
    (state: AuthState) => state.subscriberHash ?? ''
);
