import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useLocation } from 'react-router';
import { ConnectedIntegrationCards } from '$ui/Integrations/IntegrationCards';
import {
    ConnectedDentally,
    ConnectedGmail,
    ConnectedOutlook,
    ConnectedWhatsApp
} from '$ui/Integrations/Workflows';
import { useAppDispatch } from '$state';
import { load } from '$state/concerns/integrations';
import { Section } from '$ui/Settings/Section';
import { usePageTitle } from '@/utils/hooks';

export const Integrations = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    usePageTitle('Integrations - Settings');

    React.useEffect(() => {
        dispatch(load());
    }, []);

    return (
        <Section fullWidth>
            <Container>
                <Switch location={location}>
                    <Route exact path="/settings/integrations">
                        <ConnectedIntegrationCards />
                    </Route>
                    <Route exact path="/settings/integrations/gmail">
                        <ConnectedGmail />
                    </Route>
                    <Route exact path="/settings/integrations/outlook">
                        <ConnectedOutlook />
                    </Route>
                    <Route exact path="/settings/integrations/dentally">
                        <ConnectedDentally />
                    </Route>
                    <Route exact path="/settings/integrations/whatsapp">
                        <ConnectedWhatsApp />
                    </Route>
                </Switch>
            </Container>
        </Section>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    width: 100%;
`;
