import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

export interface Props {
    onClick: () => void;
    disabled?: boolean;
}

export const Send = (props: Props) => {
    const { disabled = false } = props;

    const maybeOnClick = () => {
        if (disabled) {
            return;
        }

        props.onClick();
    };

    return (
        <Button data-qa="send" onClick={maybeOnClick} disabled={disabled}>
            Send
        </Button>
    );
};

const Button = styled.button`
    ${mix.padding({ padding: [0, 2, 0, 2] })};
    ${mix.height({ size: 4 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: white;
    background-color: var(--primary-500);
    gap: ${mix.unit({ size: 1 })};

    &:disabled {
        color: var(--gray-400);
        background-color: var(--gray-100);
    }

    &:hover:not([disabled]) {
        background-color: var(--primary-600);
    }
`;
