import * as React from 'react';
import { ActionStatus } from '$state/types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { SingleAction } from '$ui/Actions/Refresh/SingleAction';
import { Hue } from '$ui/Flo/types';
import { MultiAction } from '$ui/Actions/Refresh/MultiAction';

interface Props {
    countNew: number;
    countUpdated: number;
    countWaking: number;
    status: ActionStatus;
    visible?: boolean;
    onRefresh: () => void;
}

export const Refresh = (props: Props) => {
    const {
        countNew,
        countUpdated,
        countWaking,
        onRefresh,
        status,
        visible = false
    } = props;

    if (status !== ActionStatus.REFRESHING && status !== ActionStatus.OK) {
        return null;
    }

    const isLoading = status === ActionStatus.REFRESHING;

    return (
        <Container>
            <CSSTransition
                classNames="action-refresh"
                timeout={300}
                in={visible}
                unmountOnExit
            >
                {shouldDisplayButtonGroup(status, countNew, countUpdated, countWaking) ? (
                    <MultiAction
                        loading={isLoading}
                        onClick={onRefresh}
                        countNew={countNew}
                        countUpdated={countUpdated}
                        countWaking={countWaking}
                    />
                ) : (
                    <SingleAction
                        loading={isLoading}
                        onClick={onRefresh}
                        color={selectColor(countNew, countUpdated, countWaking)}
                        label={getText(status, countNew, countUpdated, countWaking)}
                    />
                )}
            </CSSTransition>
        </Container>
    );
};

const selectColor = (countNew: number, countUpdated: number, countWaking: number): Hue => {
    if (countNew > 0) {
        return 'primary';
    }

    if (countUpdated > 0) {
        return 'yellow';
    }

    if (countWaking > 0) {
        return 'red';
    }

    return 'green';
}


const getText = (
    status: ActionStatus,
    countNew: number,
    countUpdated: number,
    countWaking: number
): React.ReactNode => {
    if (status === ActionStatus.REFRESHING) {
        return 'Updating';
    }

    if (countNew > 0) {
        return (
            <>
                {countNew} new action{countNew > 1 ? 's' : ''}
            </>
        );
    }

    if (countUpdated > 0) {
        return (
            <>
                {countUpdated} updated action{countUpdated > 1 ? 's' : ''}
            </>
        );
    }

    if (countWaking > 0) {
        return (
            <>
                {countWaking} snooze{countWaking > 1 ? 's' : ''} ending
            </>
        );
    }

    return 'Refresh actions due';
};

const shouldDisplayButtonGroup = (
    status: ActionStatus,
    countNew: number,
    countUpdated: number,
    countWaking: number
) => {
    if (status === ActionStatus.REFRESHING) {
        return false;
    }

    let counters = 0;
    [countNew, countUpdated, countWaking].forEach(count => {
        if (count > 0) {
            counters++;
        }
    });

    return counters > 1;
};

const Container = styled.div`
    width: calc(100% - 295px);
    height: 7rem;
    position: fixed;
    top: 54px;
    z-index: 100;
    pointer-events: none;
`;
