import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { replaceUnderscoresWithSpaces } from '@/utils/str';

interface Props {
    name: string;
    isOpen?: boolean;
    onClick: () => void;
}

export const AccordionHeader = (props: Props) => {
    const { name, isOpen, onClick } = props;
    return (
        <Header onClick={onClick}>
            <HeaderTitle>
                <Title>{replaceUnderscoresWithSpaces(name)} Templates</Title>
            </HeaderTitle>
            <HeaderTitleIcon
                clickable
                icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
                size={2}
            />
        </Header>
    );
};

const Header = styled.div`
    ${mix.padding({ padding: [0, 0, 2, 0] })};
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
    }
`;

const HeaderTitle = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
`;

const HeaderTitleIcon = styled(Icon)`
    flex: 0 0 auto;
`;

const Title = styled.div`
    flex: 1 1 auto;
    &::first-letter {
        text-transform: uppercase;
    }
    ${mix.type({ level: 'body2', bold: true })}
`;
