import { Icon } from '$ui/Flo/Icon';
import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Hue } from '$ui/Flo/types';

export const Delete = ({
    onClose,
    onDelete,
}: {
    onClose: () => void;
    onDelete: () => void;
}) => {
    return (
        <div>
            <Content>
                <IconContainer hue={'red'}>
                    <Icon icon={'Trash2'} size={3} hue={'red'} opacity={1} />
                </IconContainer>
                <TitleModal>Delete Template</TitleModal>
                <MessageModal>
                    Are you sure you want to delete this template?
                </MessageModal>
            </Content>
            <ActionBar>
                <ButtonGroup>
                    <Button
                        rounded
                        mode={'outline'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        <CustomButton>Cancel</CustomButton>
                    </Button>
                    <Button
                        rounded
                        hue={'red'}
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}
                    >
                        <CustomButton color={'white'}>Delete</CustomButton>
                    </Button>
                </ButtonGroup>
            </ActionBar>
        </div>
    );
};

const ActionBar = styled.div`
    border-top: 1px solid var(--gray-200);
`;

const TitleModal = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-600);
`;

const MessageModal = styled.div`
    font-size: 16px;
    color: var(--gray-600);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1.5 })};
    justify-content: space-between;
    ${mix.padding({ padding: [1.5, 3] })};
`;

const Content = styled.div`
    ${mix.padding({ padding: [1.5] })};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [3] })};
`;

const CustomButton = styled.div<{ color?: string }>`
    width: 140px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : 'var(--gray-500)')};
`;

const IconContainer = styled.div<{ hue: Hue }>`
    ${mix.sq({ size: 6 })};
    border-radius: 50%;
    background: var(--gray-100);
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ hue }) => {
        switch (hue) {
            case 'primary':
                return css`
                    background: var(--primary-050);
                `;
            case 'red':
                return css`
                    background: var(--error-050);
                `;
            case 'green':
                return css`
                    background: var(--success-050);
                `;
            case 'yellow':
                return css`
                    background: var(--warning-050);
                `;
            default:
                return css`
                    background: var(--gray-100);
                `;
        }
    }};
`;
