import React from 'react';
import styled from 'styled-components';
import { mix } from '../util';
import { Icon as FloIcon } from '../Icon';
import { Attachment } from '$types';
import { useHover } from '@/utils/hooks';
import { DisplayImage } from '$ui/Timeline/Parts/Attachments/Image';

interface LightboxAttachmentProps {
    attachments: Attachment[];
    onClose: () => void;
    onDownload?: (attachment: Attachment) => void;
    index?: number;
}

/**
 * A lightbox for attachments
 *
 * Parent should handle the open/close state.
 */
export const AttachmentLightbox = (props: LightboxAttachmentProps) => {
    const { onClose, onDownload, attachments, index } = props;

    const modalRef = React.useRef<HTMLDivElement>(null);

    const [hover, setHover] = React.useState<boolean>(false);
    const [attachment, setAttachment] = React.useState<Attachment>(
        attachments[index ?? 0],
    );

    React.useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (!modalRef.current) {
                return;
            }

            const target = event.target as Node;

            if (modalRef.current.contains(target) || target.nodeName === 'A') {
                return;
            }

            onClose();
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [onClose]);

    useHover({
        ref: modalRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    const handleNext = () => {
        const currentIndex = attachment ? attachments.indexOf(attachment) : 0;
        if (currentIndex >= attachments.length - 1) {
            setAttachment(attachments[0]);
        } else {
            setAttachment(attachments[currentIndex + 1]);
        }
    };

    const handlePrevious = () => {
        const currentIndex = attachment ? attachments.indexOf(attachment) : 0;
        if (currentIndex <= 0) {
            setAttachment(attachments[attachments.length - 1]);
        } else {
            setAttachment(attachments[currentIndex - 1]);
        }
    };

    return (
        <LightboxContainer>
            <LightboxContent
                ref={modalRef}
                data-testid={`modal-${attachment.name}`}
            >
                {hover && (
                    <ImageOverlay>
                        <Name>{attachment && attachment.name}</Name>
                        <Actions>
                            {onDownload && attachment && (
                                <IconHolder>
                                    <FloIcon
                                        icon="DownloadCloud"
                                        hue="white"
                                        opacity={1}
                                        onClick={() => onDownload(attachment)}
                                        clickable={true}
                                    />
                                </IconHolder>
                            )}
                            <IconHolder>
                                <FloIcon
                                    icon="X"
                                    hue="white"
                                    opacity={1}
                                    onClick={() => onClose()}
                                    clickable={true}
                                />
                            </IconHolder>
                        </Actions>
                    </ImageOverlay>
                )}
                <LeftButtonHolder
                    onClick={() => handlePrevious()}
                    data-testid="modal-prev"
                >
                    <PrevButton>
                        <FloIcon
                            icon="ArrowLeftCircle"
                            hue="grey"
                            opacity={1}
                            onClick={() => handlePrevious()}
                            clickable={true}
                            size={3}
                        />
                    </PrevButton>
                </LeftButtonHolder>
                <DisplayImage image={attachment} toggleLightbox={onClose} />
                <RightButtonHolder
                    onClick={() => handleNext()}
                    data-testid="modal-next"
                >
                    <NextButton>
                        <FloIcon
                            icon="ArrowRightCircle"
                            hue="grey"
                            opacity={1}
                            onClick={() => handleNext()}
                            clickable={true}
                            size={3}
                        />
                    </NextButton>
                </RightButtonHolder>
            </LightboxContent>
        </LightboxContainer>
    );
};

export const LightboxContainer = styled.div`
    position: absolute;
    background-color: #00000032;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    ${mix.type({ level: 'body2' })};
    z-index: 10000;
    overflow: hidden;
`;

export const LightboxContent = styled.div`
    position: relative;
    max-width: 80vw;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: white;
    border-radius: 6px;
    ${mix.shadow()};
    overflow: hidden;
`;
export const ImageWrap = styled.div`
    cursor: pointer;
    border-radius: ${mix.unit({ size: 0.5 })};
    overflow: hidden;
    object-fit: contain;
    width: 100%;
    height: 100%;

    display: flex;
    max-width: 100%;
    max-height: 100%;

    > img {
        flex: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: middle;
        max-width: 100%;
        max-height: 80vh;
    }
`;

const ImageOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    padding: ${mix.unit({ size: 1 })};
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: space-between;
    z-index: 3;
`;

const Name = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto, serif;
    font-weight: 400;
    color: #ffffff;
    height: 32px;
`;

const Actions = styled.div`
    display: flex;
    justify-content: right;
    gap: ${mix.unit({ size: 1 })};
`;

const IconHolder = styled.div`
    background: #00000052;
    width: 32px;
    height: 32px;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const LeftButtonHolder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20%;
    cursor: pointer;
`;

const RightButtonHolder = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20%;
    cursor: pointer;
`;

const PrevButton = styled.div`
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    left: ${mix.unit({ size: 2 })};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const NextButton = styled.div`
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    right: ${mix.unit({ size: 2 })};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
