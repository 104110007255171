import * as React from 'react';
import { ActionCategory as ActionCategorySchema } from '$state/queries/actions';
import { LegacyV2ActionSchema } from '$types/legacy';
import { Action } from '$ui/Actions/Action';
import { ActionRecord } from '@/state/types';
import { CountryCode } from '@/utils/phone';
import { LoadMore } from '$ui/Actions/LoadMore';
import { Category } from '$ui/Flo/Category';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface Props {
    category: ActionCategorySchema;
    loadMore: (load: number) => void;
    toggleAction: (patientId: string, state: boolean) => void;
    includeTitle?: boolean;
    pageSize: number;
    country: CountryCode;
}

export const ActionCategory = (props: Props) => {
    const {
        category,
        loadMore,
        toggleAction,
        includeTitle,
        pageSize,
        country,
    } = props;
    const { displayTotal, actions, category: name } = category;

    const components = [];
    for (let i = 0; i < actions.length; i++) {
        if (i === displayTotal && actions.length > displayTotal) {
            components.push(
                <LoadMore
                    key={`loadMore-${name}`}
                    category={name}
                    remaining={actions.length - displayTotal}
                    pageSize={pageSize}
                    onLoadMore={() => loadMore(pageSize)}
                />,
            );
            break;
        }
        const action = actions[i];
        const builtAction = buildAction(
            actions[i],
            (state) => toggleAction(actions[i].action.patient_id, state),
            country,
        );
        action.action.snooze?.state === 'waking'
            ? components.unshift(builtAction)
            : components.push(builtAction);
    }

    if (!includeTitle) {
        return <Container>{components}</Container>;
    }

    return (
        <Category title={name} count={actions.length} key={name}>
            {components}
        </Category>
    );
};

const buildAction = (
    action: ActionRecord<LegacyV2ActionSchema>,
    onToggle: (state: boolean) => void,
    country: CountryCode,
) => {
    return (
        <Action
            key={action.action.patient_id}
            action={action}
            onToggle={onToggle}
            country={country}
        />
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
`;
