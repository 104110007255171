interface BaseWorkflow {
    loaded: boolean;
    id: string;
    name: string;
    enabled: boolean;
    requiresCustomisation: boolean;
}

export interface UnloadedWorkflow extends BaseWorkflow {
    loaded: false;
}

export interface APIWorkflow extends BaseWorkflow {
    startTrigger: Trigger;
    endTrigger: Trigger | null;
    nodes: (Action | Delay)[];
}

export interface LoadedWorkflow extends BaseWorkflow {
    loaded: true;
    startTrigger: Trigger;
    endTrigger: Trigger | null;
    nodes: string[];
}

export type Workflow = UnloadedWorkflow | LoadedWorkflow;

export interface Trigger {
    direction: 'in' | 'out';
    stages: { id: string; name: string }[];
    referral: null | boolean;
}

export type NodeType = 'action' | 'delay' | 'wait_until_delay';

export interface BaseNode {
    id: string;
    type: NodeType;
}

export interface Delay extends BaseNode {
    type: 'delay';
    unit: 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years';
    duration: number;
    delay: 'simple';
}

export interface WorkflowDelay extends BaseNode {
    type: 'wait_until_delay';
    workflows: string[];
    negated: boolean;
}

export interface ActionCondition {
    negated: boolean;
    stages: string[];
}

export interface BaseAction extends BaseNode {
    type: 'action';
    name: string;
    action: 'sms' | 'email';
    enabled: boolean;
    requiresCustomisation: boolean;
    loaded: boolean;
    condition: ActionCondition | null;
}

export interface UnloadedAction extends BaseAction {
    loaded: false;
}

export interface LoadedEmail extends BaseAction {
    loaded: true;
    action: 'email';
    from: string;
    to: string;
    replyTo: string | null;
    templates: SegmentedTemplate<EmailTemplate>[];
}

export interface LoadedSMS extends BaseAction {
    loaded: true;
    action: 'sms';
    from: string;
    to: string;
    templates: SegmentedTemplate<SMSTemplate>[];
}

export interface SMSTemplate {
    template: string;
    finished_customising: boolean;
}

export interface EmailTemplate {
    html: string;
    definition: string;
    subject: string;
    preview: string | null;
    finished_customising: boolean;
}

export type CommTemplate = SMSTemplate | EmailTemplate;

export interface SegmentedTemplate<T extends CommTemplate = CommTemplate> {
    id: string;
    name: string | null; // If null, the name will be generated from the types, but can optionally be named
    types: string[] | null;
    template: T;
}

export type LoadedAction = LoadedEmail | LoadedSMS;

export type Action = LoadedAction | UnloadedAction;

export type Node = Action | Delay | WorkflowDelay;

export enum AutomationsStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error'
}

export enum TemplateStatus {
    IDLE = 'idle',
    EDITING = 'editing',
    SAVING = 'saving',
    ERROR = 'error'
}

export interface AutomationsState {
    status: AutomationsStatus;
    workflows: {
        [id: string]: Workflow;
    };
    nodes: {
        [id: string]: Action | Delay | WorkflowDelay;
    };
    editor: {
        open: boolean;
        action: Action | null; // Action gets copied and loaded in, so it can be modified independently
        editingStatus: TemplateStatus;
        template: string | null;
    };
}

export interface InitialAutomationsState extends AutomationsState {
    status: AutomationsStatus.IDLE | AutomationsStatus.LOADING;
}

export interface AutomationsLoadedState extends AutomationsState {
    status: AutomationsStatus.LOADED;
}
