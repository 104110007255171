import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Tab } from './Tab';
import { mix } from '$ui/Flo/util';
import { ChannelConfig, Channels, ReplyState } from '$state/types';

export interface Props {
    selectedChannel: ChannelConfig;
    channels: ChannelConfig[];
    state: ReplyState;
    stateChanged: (state: ReplyState) => void;
    enabledChannels?: Channels[];
}

export const ChannelTabs = (props: Props) => {
    const {
        state,
        stateChanged,
        channels,
        enabledChannels = [],
        selectedChannel
    } = props;

    const barRef = React.useRef<HTMLInputElement>(null);
    const innerBarRef = React.useRef<HTMLInputElement>(null);
    const [fullBar, setFullBar] = React.useState<boolean>(true);
    const [initialBarWidth, setInitialBarWidth] = React.useState<number>(0);

    const onChange = (next: Partial<ReplyState>) => {
        stateChanged({
            ...state,
            ...next
        });
    };

    useEffect(() => {
        const tab = channels.find((chan) => chan.channel === state.channel);
        if (!tab) {
            onChange({ channel: channels[0].channel });
        }
    }, [selectedChannel]);

    const handleResize = () => setFullBar(!!barRef.current && barRef.current.clientWidth >= initialBarWidth);

    const displayFullBar = (barWidth: number, innerBarWidth: number): boolean => innerBarWidth <= barWidth;

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        if (initialBarWidth === 0 && barRef.current && innerBarRef.current) {
            setFullBar(displayFullBar(barRef.current.clientWidth, innerBarRef.current.clientWidth));
            setInitialBarWidth(innerBarRef.current.clientWidth);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [initialBarWidth]);

    return (
        <TabBar ref={barRef}>
            <TabBarInner ref={innerBarRef}>
                {channels.map((tab) => (
                    <Tab
                        channel={tab}
                        onSelect={() => onChange({ channel: tab.channel })}
                        selected={state.channel === tab.channel}
                        key={tab.channel}
                        disabled={!enabledChannels.includes(tab.channel)}
                        fullBar={fullBar}
                    />
                ))}
            </TabBarInner>
        </TabBar>
    );
};

const TabBarInner = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    gap: ${mix.unit({ size: 1 })};
`;

const TabBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--gray-100);
    height: 36px;
    ${mix.padding({ padding: [0, 0, 0, 1] })};
`;
