import * as React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { useTextField, useId } from 'react-aria';
import { mix } from '$ui/Flo/util';
import { Button } from '$ui/Flo/Button';
import { Controller, useForm } from 'react-hook-form';
import { Stage, selectStages, load } from '$state/concerns/stages';
import { changeStageWithNote } from '$state/concerns/patient';
import { Loading } from '$ui/Shared';

type ConnectedChangeStageFormProps = {
    onClose: () => void;
    onComplete: () => void;
};
export const ConnectedChangeStageForm: React.FC<
    ConnectedChangeStageFormProps & {
        patientId: string;
        currentStage: string;
        patientName: string;
    }
> = ({ onClose, onComplete, patientId, currentStage, patientName }) => {
    const dispatch = useAppDispatch();
    const stages = useAppSelector(selectStages);

    React.useEffect(() => {
        if (stages.length === 0) {
            dispatch(load());
        }
    }, []);

    if (stages.length === 0) {
        return <Loading />;
    }

    const stagesToRemove = ['maybeFuture', 'lost', 'blocked', currentStage];
    const availableStages = stages.filter(
        (stage) => !stagesToRemove.includes(stage.key),
    );

    const current = stages.find((stage) => stage.key === currentStage);

    if (!current) {
        return null;
    }

    return (
        <ChangeStageForm
            data-cy="change-stage-form"
            patientId={patientId}
            stages={availableStages}
            currentStage={current}
            onEditStage={(stage: string, note: string | null) => {
                const newStage = stages.find(
                    (stageItem) => stageItem.key === stage,
                );
                if (newStage === undefined) {
                    throw new Error('New stage not found');
                }
                dispatch(
                    changeStageWithNote({
                        patientId,
                        stage: newStage,
                        note,
                        patientName,
                    }),
                );
                onComplete();
            }}
            onClose={onClose}
            onComplete={onComplete}
        />
    );
};

export type ChangeStageForm = Pick<
    ConnectedChangeStageFormProps,
    'onClose' | 'onComplete'
> & {
    patientId: string;
    stages: Stage[];
    currentStage: Stage;
    onEditStage: (newStage: string, note: string | null) => void;
};

export type ChangeStageDetails = {
    newStage: string;
    currentStage: Stage;
    note: string;
};

export const ChangeStageForm = (props: ChangeStageForm) => {
    const { stages, onEditStage, currentStage } = props;

    const form = useForm<ChangeStageDetails>({
        mode: 'all',
        defaultValues: {
            newStage: '',
            note: '',
        },
    });

    const currentStageRef = React.useRef<HTMLInputElement>(null);
    const noteRef = React.useRef<HTMLTextAreaElement>(null);

    return (
        <Form
            {...form}
            onSubmit={form.handleSubmit((data) => {
                onEditStage(data.newStage, data.note);
            })}
            data-testid="stage-change-form"
            data-cy="stage-change-form"
        >
            <Controller
                name="currentStage"
                control={form.control}
                disabled={true}
                render={({ field }) => {
                    const { inputProps, labelProps } = useTextField(
                        {
                            ...field,
                            label: 'Current stage',
                            value: currentStage.name,
                        },
                        currentStageRef,
                    );

                    return (
                        <InputWrapper>
                            <Label {...labelProps}>
                                Current stage
                                <InputDisabled
                                    {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
                                    data-testid="current-stage"
                                    data-cy="current-stage"
                                />
                            </Label>
                        </InputWrapper>
                    );
                }}
            />

            <Controller
                name="newStage"
                control={form.control}
                rules={{
                    required: 'Please select a new stage',
                }}
                render={({ field }) => {
                    const id = useId();
                    return (
                        <InputWrapper>
                            <Label htmlFor={id}>
                                New stage{' '}
                                <LabelError
                                    hasError={
                                        form.formState.errors?.newStage
                                            ? true
                                            : false
                                    }
                                >
                                    {form.formState.errors?.newStage?.message}
                                </LabelError>
                            </Label>
                            <SelectField
                                id={id}
                                {...field}
                                hasError={
                                    form.formState.errors.newStage
                                        ? true
                                        : false
                                }
                                data-testid="new-stage-select"
                                data-cy="new-stage-select"
                            >
                                <option value="">Select a stage</option>
                                {stages.map((stage) => (
                                    <option key={stage.key} value={stage.key}>
                                        {stage.name}
                                    </option>
                                ))}
                            </SelectField>
                        </InputWrapper>
                    );
                }}
            />

            <Controller
                name="note"
                control={form.control}
                defaultValue=""
                render={({ field }) => {
                    const { inputProps, labelProps } = useTextField(
                        {
                            ...field,
                            label: 'Note',
                            inputElementType: 'textarea',
                        },
                        noteRef,
                    );
                    return (
                        <InputWrapper>
                            <Label {...labelProps}>
                                Note
                                <TextArea
                                    {...(inputProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
                                    data-testid="stage-note"
                                    data-cy="change-stage-note"
                                />
                            </Label>
                        </InputWrapper>
                    );
                }}
            />
            <ButtonWrapper>
                <Button
                    onClick={props.onClose}
                    mode="outline"
                    hue="grey"
                    align="center"
                    id="change-stage-cancel"
                    rounded
                >
                    Cancel
                </Button>
                <Button align="center" id="stage-change-submit" rounded>
                    Change stage
                </Button>
            </ButtonWrapper>
        </Form>
    );
};

const Form = styled.form`
    ${mix.gap({ size: 1.5 })};
    display: flex;
    flex-direction: column;
    min-width: 400px;
`;

const ButtonWrapper = styled.div`
    ${mix.padding({ padding: [1, 0] })};
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    ${mix.gap({ size: 1 })};
    > button {
        flex: 1 0;
    }
`;

const InputWrapper = styled.div<{ error?: string }>`
    ${mix.gap({ size: 1 })}
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    ${mix.type({ level: 'body2', bold: true })};
    position: relative;
    font-size: 14px;
    color: var(--gray-600);
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const SelectField = styled.select<{ hasError?: boolean }>`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 1 })};
    ${mix.height({ size: 6 })};
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background-color: white;

    ${({ hasError }) => hasError && `border-color: red;`}
`;

const InputDisabled = styled.input<{ hasError?: boolean }>`
    ${mix.padding({ padding: 1.5 })};
    ${mix.type({ level: 'body2' })};
    border: none;
    outline: none;
    border-radius: 4px;
    width: 100%;
    background-color: var(--gray-100);
`;

const TextArea = styled.textarea<{ hasError?: boolean }>`
    ${mix.padding({ padding: 1.5 })};
    ${mix.type({ level: 'body2' })};
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    width: 100%;
`;

const LabelError = styled.span<{ hasError: boolean }>`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    ${mix.type({ level: 'body2' })};
    color: var(--error-600);
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
    ${({ hasError }) => hasError && `display: block;`};
`;
