import { SnoozeState } from '$state/types/snooze';
import { Button } from '$ui/Flo/Button';
import React from 'react';

interface SnoozeButtonProps {
    onClick: () => void;
    state?: SnoozeState;
    disabled?: boolean;
}

export const SnoozeButton = (props: SnoozeButtonProps) => {
    const { onClick, state, disabled } = props;

    if (state === 'asleep') {
        return (
            <Button
                id="snoozing-button"
                mode="solid"
                hue="primary"
                icon="Moon"
                onClick={onClick}
                rounded
                disabled={disabled}
                align="center"
            >
                Snoozing
            </Button>
        );
    }

    if (state === 'waking') {
        return (
            <Button
                id="snooze-ending-button"
                mode="pale"
                hue="red"
                shade="5"
                iconHue="red"
                icon="Moon"
                onClick={onClick}
                rounded
                disabled={disabled}
                align="center"
            >
                Snooze ending
            </Button>
        );
    }

    return (
        <Button
            id="start-snooze-button"
            mode="outline"
            hue="grey"
            shade="5"
            iconHue="grey"
            icon="Moon"
            borderShade="5"
            onClick={onClick}
            rounded
            disabled={disabled}
            align="center"
        >
            Snooze
        </Button>
    );
};
