import React from 'react';
import styled from 'styled-components';
// to your Redux actions
import { useButton } from '@react-aria/button';
import { Monogram } from '$ui/Flo/Monogram';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';

interface ResultItemProps {
    patientId: string;
    name: string;
    onEditPatient: (patientId: string) => void;
}

export const ResultItem = ({
    patientId,
    name,
    onEditPatient,
}: ResultItemProps) => {
    const handleEditClick = () => {
        onEditPatient(patientId);
    };

    const editButtonRef = React.useRef<HTMLButtonElement>(null);
    const { buttonProps } = useButton(
        {
            onPress: handleEditClick,
            'aria-label': 'Edit patient record',
            elementType: 'button',
        },
        editButtonRef,
    );

    return (
        <Container>
            <Monogram size={4.75} name={name} />
            <Name>{name}</Name>
            <EditButton {...buttonProps} ref={editButtonRef}>
                <Icon clickable opacity={1} icon="Edit3" color="white" /> Edit
            </EditButton>
        </Container>
    );
};

const Container = styled.div`
    ${mix.type({ level: 'body2', bold: true })};
    display: flex;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;

const Name = styled.div`
    flex-grow: 1;
    color: var(--gray-600);
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const EditButton = styled.button`
    ${mix.gap({ size: 1 })};
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    ${mix.height({ size: 4.5 })};
    font-weight: 600;
    font-size: 14px;
    color: white;
    background-color: var(--primary-500);
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    &:hover {
        background-color: var(--primary-600);
    }
`;
