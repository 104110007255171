import * as React from 'react';
import styled, {css} from 'styled-components';
import {mix} from '$ui/Flo/util';

interface Props {
    enabled: boolean;
    onChange: (state: boolean) => void;
    disabled?: boolean;
}

export const Toggle = (props: Props) => {
    const {enabled, onChange, disabled} = props;

    return (
        <Button
            disabled={!!disabled}
            enabled={enabled}
            onClick={() => onChange(!enabled)}
        />
    );
};

const Button = styled.div<{ enabled: boolean; disabled?: boolean }>`
    border-radius: 999px;
    width: ${mix.unit({size: 5})};
    height: ${mix.unit({size: 3})};
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
    position: relative;
    transition: all 200ms ease;

    ${({disabled}) =>
        !disabled &&
        css`
            cursor: pointer;
        `}

    ${({enabled, disabled}) => enabled && !disabled && css`background: var(--primary-600);`};

    ${({enabled, disabled}) => enabled && disabled && css`background: var(--primary-800);`};

    ${({enabled, disabled}) => !enabled && !disabled && css`background: var(--gray-500);`};

    ${({enabled, disabled}) => !enabled && disabled && css`background: var(--gray-700);`};

    &::after {
        content: '';
        position: absolute;
        height: ${mix.unit({size: 2.25})};
        width: ${mix.unit({size: 2.25})};
        background: white;
        border-radius: 999px;
        top: 50%;
        transform: translateY(-50%) translateX(20%);
        left: 0;
        transition: all 200ms ease;

        ${({enabled}) =>
            enabled &&
            css`
                transform: translateY(-50%) translateX(100%);
            `}
    }
`;
