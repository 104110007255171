import { AsyncStatus } from '$ui/Flo/types';
import { mix } from '$ui/Flo/util';
import { Paginator } from '$ui/Patients/Paginator';
import React from 'react';
import styled from 'styled-components';

interface Props {
    pages: number;
    page: number;
    status: AsyncStatus;
    onPageChange: (page: number) => void;
}

export const Footer = (props: Props) => {
    const { pages, page, onPageChange } = props;
    return (
        <Bar>
            <Paginator pages={pages} page={page} onPageChange={onPageChange} />
        </Bar>
    );
};

const Bar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${mix.padding({ padding: [1] })}
    background: white;
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
`;
