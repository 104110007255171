import { CommType } from './patient';
import { IconName } from '$ui/Flo/Icon';

export interface NavItem {
    name: string; // The name of the navigation item
    roles?: string[]; // The roles to see the nav item. If undefined, all can see
}

export interface SubNavItem extends NavItem {
    count: number; // The count in the badge for this subnav item
    destination: string; // The destination to navigate to
}

export interface TopNavItem extends NavItem {
    icon: IconName; // The icon for this section
    submenu?: SubNavItem[]; // The slideout submenu for this section
    destination?: string; // The destination. Submenu and destination should not be set
    countingStrategy?: NavCountingStrategy;
}

export interface Navigation {
    items: TopNavItem[];
}

export interface ActionRoute {
    title: string; // Also a reference to the route's views name in state.actions.views
    path: string;
}

export type InboxStatusFilter = 'unread' | 'read' | 'all';
export type StarredStatus = 'starred' | 'unstarred' | 'all';
export type SnoozedStatus = 'showSnoozed' | 'hideSnoozed';

export type InboxChannelFilter =
    | CommType
    | 'Form Submissions'
    | 'All'
    | 'Unread';

export interface InboxRoute {
    name: string;
    path: string;
    channel: InboxChannelFilter;
}

export enum NavCountingStrategy {
    TOTALS = 'totals',
    FIRST = 'first'
}

export type SortOrder = 'asc' | 'desc';

export type ReferralsFilter = 'both' | 'referrals' | 'non-referrals';

export type AsyncStatus = 'init' | 'loading' | 'loaded' | 'errored';

export interface Async {
    status: AsyncStatus;
}
