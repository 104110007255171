import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFilePdf = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.2H66.8L67.3 23.13C67.235 22.6294 67.0106 22.1631 66.66 21.8L45.85 1C45.7351 0.883073 45.6076 0.779116 45.47 0.690001L45.37 0.630002C45.2633 0.564084 45.1532 0.503995 45.04 0.450002L44.86 0.400002C44.7669 0.359576 44.6696 0.3294 44.57 0.310002C44.4086 0.278044 44.2445 0.261304 44.08 0.260002H10.68C10.017 0.260002 9.38107 0.523394 8.91223 0.992235C8.44339 1.46108 8.18 2.09696 8.18 2.76V29.64C8.18 30.303 8.44339 30.9389 8.91223 31.4078C9.38107 31.8766 10.017 32.14 10.68 32.14C11.343 32.14 11.9789 31.8766 12.4478 31.4078C12.9166 30.9389 13.18 30.303 13.18 29.64V5.29H41.58V23.43C41.5826 24.0905 41.8455 24.7234 42.3116 25.1914C42.7777 25.6593 43.4095 25.9247 44.07 25.93L62.33 26.03V74.44H13.18V70.57C13.18 69.907 12.9166 69.2711 12.4478 68.8022C11.9789 68.3334 11.343 68.07 10.68 68.07C10.017 68.07 9.38107 68.3334 8.91223 68.8022C8.44339 69.2711 8.18 69.907 8.18 70.57V76.94C8.18 77.603 8.44339 78.2389 8.91223 78.7078C9.38107 79.1766 10.017 79.44 10.68 79.44H64.83C65.493 79.44 66.1289 79.1766 66.5978 78.7078C67.0666 78.2389 67.33 77.603 67.33 76.94V23.54C67.3297 23.426 67.3197 23.3123 67.3 23.2ZM46.58 20.94V8.83L58.76 21L46.58 20.94Z" />
            <path d="M30.55 46.71C30.3793 46.3779 30.0847 46.1265 29.73 46.01C29.2222 45.8521 28.6914 45.7811 28.16 45.8H26.47V54.47H28.16C28.5426 54.5058 28.9285 54.4602 29.2922 54.3361C29.6558 54.212 29.9891 54.0123 30.27 53.75C30.7394 53.1159 30.9951 52.3489 31 51.56C31 51.13 31 50.56 31 49.76C31.0053 49.1457 30.9786 48.5315 30.92 47.92C30.873 47.4972 30.7475 47.0868 30.55 46.71Z" />
            <path d="M16.5 45.76H14.4V50.45H16.5C17.8 50.45 18.44 49.65 18.44 48C18.5056 47.3892 18.3495 46.7752 18 46.27C17.8014 46.0821 17.5646 45.9392 17.3057 45.8512C17.0468 45.7632 16.772 45.7321 16.5 45.76Z" />
            <path d="M53.66 35.14H3.17C2.50696 35.14 1.87107 35.4034 1.40223 35.8722C0.933392 36.3411 0.67 36.977 0.67 37.64V62.57C0.67 63.233 0.933392 63.8689 1.40223 64.3378C1.87107 64.8066 2.50696 65.07 3.17 65.07H53.66C54.323 65.07 54.9589 64.8066 55.4278 64.3378C55.8966 63.8689 56.16 63.233 56.16 62.57V37.64C56.16 36.977 55.8966 36.3411 55.4278 35.8722C54.9589 35.4034 54.323 35.14 53.66 35.14ZM20.34 51.87C19.8299 52.345 19.2276 52.7101 18.5704 52.9423C17.9132 53.1746 17.2153 53.2691 16.52 53.22H14.4V57.28H11.22V43H16.52C19.93 43 21.65 44.69 21.65 48C21.7414 49.4124 21.2705 50.8035 20.34 51.87ZM34 53.07C33.8606 53.8792 33.5651 54.6536 33.13 55.35C32.6723 56.0348 32.006 56.5538 31.23 56.83C30.2306 57.1587 29.1816 57.3109 28.13 57.28H23.29V43H28.16C29.1741 42.9757 30.1859 43.1072 31.16 43.39C31.9171 43.6123 32.583 44.0714 33.06 44.7C33.521 45.3278 33.8416 46.0474 34 46.81C34.1877 47.8323 34.2714 48.8709 34.25 49.91C34.263 50.9687 34.1793 52.0265 34 53.07ZM45.62 45.8H39.55V49.55H44.55V52.36H39.55V57.28H36.37V43H45.62V45.8Z" />
        </svg>
    );
};
