/**
 * Mixin support
 *
 * Purpose of this module is to provide simplified mixins that are more
 * ergonomic to use for cases where current `apply*` style mixins do not require
 * parameters.
 *
 * There is some support for parameters but the styled component must accept the
 * same parameter name, which should help in a lot of cases.
 *
 * In addition, this module provides helpers to compose mixins following common
 * patterns encountered "in the wild".
 *
 * Exports an object containing a set of `apply*` mixin functions hoisted so the
 * `theme` property is no longer required.
 *
 * Example usage:
 *
 * ```ts
 * const Container = styled.div`
 *   ${mix.padding({ padding: 2 })};
 *   ${mix.round()};
 *   ${mix.type({ level: 'body2' })};
 *   ${mix.textColor({ profile: 'secondary' })};
 *   color: ${mix.palette({ hue: 'green' })};
 * `;
 * ```
 */

import { FlattenSimpleInterpolation } from 'styled-components';
import {
    alpha,
    applyBg,
    applyFill,
    applyGap,
    applyHeight,
    applyMargin,
    applyPadding,
    applyRect,
    applyRoundedCorners,
    applyShadow,
    applySq,
    applyTextColor,
    applyType,
    applyUnit,
    applyWidth,
    palette
} from './shared';
import { ThemedProps } from '../types';

type MixinFn<T> = (
    props: T
) => FlattenSimpleInterpolation | string | undefined | false;

/**
 * Takes a vanilla `apply` mixin function and returns a mixin function that can
 * be used without passing in the `theme` prop.
 *
 * The `apply` mixin function **must** take `theme` as a property.
 *
 * @param fn An `apply` mixin function
 * @returns A themed mixin function
 */
export const mixin = <T>(fn: MixinFn<ThemedProps<T>>) => {
    return (props: Partial<T> = {}) => {
        return ({ theme, ...params }: ThemedProps<Partial<T>>) =>
            fn({ theme, ...params, ...props } as ThemedProps<Required<T>>);
    };
};

export default {
    // Theme
    unit: mixin(applyUnit),

    // Box
    padding: mixin(applyPadding),
    margin: mixin(applyMargin),
    width: mixin(applyWidth),
    height: mixin(applyHeight),
    rect: mixin(applyRect),
    sq: mixin(applySq),
    gap: mixin(applyGap),

    // Type
    type: mixin(applyType),

    // Color
    color: mixin(applyTextColor),
    bg: mixin(applyBg),
    palette: mixin(palette),
    alpha: mixin(alpha),
    fill: mixin(applyFill),

    // Shape
    round: mixin(applyRoundedCorners),

    // Border
    shadow: mixin(applyShadow)
};
