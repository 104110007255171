import React from 'react';
import styled, { css } from 'styled-components';
import { Hue } from '$ui/Flo/types';
import { Icon, IconName } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';

interface Props {
    mode: 'healthy' | 'unhealthy' | 'syncing';
}

export const StatusIcon = ({ mode }: Props) => {
    let icon: IconName = 'CheckCircle';

    if (mode === 'syncing') {
        icon = 'RotateCw';
    }

    if (mode === 'unhealthy') {
        icon = 'CloudOff';
    }

    let hue: Hue = 'green';

    if (mode === 'syncing') {
        hue = 'primary';
    }

    if (mode === 'unhealthy') {
        hue = 'red';
    }

    return (
        <Container hue={hue}>
            <Icon
                opacity={1}
                hue={hue}
                shade="6"
                size={6}
                icon={icon}
                thickness={1}
                spin={mode === 'syncing'}
            />
        </Container>
    );
};

interface ContainerProps {
    hue: Hue;
}

const Container = styled.div<ContainerProps>`
    ${mix.sq({ size: 10 })};

    ${({ hue }) =>
        css`
            ${mix.bg({ hue, shade: '6', alpha: 0.1 })}
        `};

    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
