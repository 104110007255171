import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { dates } from '$utils';
import {
    ContactDetailsContext,
    MarketingDetailsContext,
    NextActionContext,
} from '$ui/AddPatient/fsm';
import { Footer } from '$ui/AddPatient/Footer';

export type ConfirmationProps = Pick<ContactDetailsContext, 'name'> &
    Pick<MarketingDetailsContext, 'treatmentType'> &
    NextActionContext & {
        onBack: () => void;
        onContinue: () => void;
    };

export const Confirmation: React.FC<ConfirmationProps> = ({
    name,
    treatmentType,
    action,
    date,
    note,
    onBack,
    onContinue,
}) => {
    const indefiniteArticle = /^[aeiouAEIOU]/.test(treatmentType) ? 'an' : 'a';

    return (
        <Container>
            <ConfirmationWrapper>
                <Title>
                    <span>Just to double check</span>&hellip;
                </Title>
                <Item>
                    <IconWrapper>
                        <Icon icon="User" opacity={1} size={2.5} />
                    </IconWrapper>
                    <Text>
                        <span>
                            {name} is {indefiniteArticle}
                        </span>{' '}
                        <strong>{treatmentType}</strong> patient
                    </Text>
                </Item>
                <Item>
                    <IconWrapper>
                        <Icon icon="Calendar" opacity={1} size={2.5} />
                    </IconWrapper>
                    <Text>
                        <NextActionText date={date} action={action} />
                    </Text>
                </Item>
                {note !== '' && (
                    <Item>
                        <IconWrapper>
                            <Icon icon="FileText" opacity={1} size={2.5} />
                        </IconWrapper>
                        <Text>
                            <strong>You noted</strong> "{note}"
                        </Text>
                    </Item>
                )}
            </ConfirmationWrapper>
            <Footer
                id="confirm"
                onContinue={onContinue}
                onBack={onBack}
                continueLabel="That's correct"
            />
        </Container>
    );
};

type NextActionTextProps = Pick<ConfirmationProps, 'date' | 'action'>;

const NextActionText = ({ date, action }: NextActionTextProps) => {
    const formattedDate = dates.long(date);

    if (action === 'enquiry') {
        return (
            <>
                They have made an enquiry and we will contact them on{' '}
                <strong>{formattedDate}</strong>
            </>
        );
    }

    if (action === 'thinkingConsult') {
        return (
            <>
                They need time to think about a consultation. We will follow up
                on <strong>{formattedDate}</strong>
            </>
        );
    }

    if (action === 'thinkingTx') {
        return (
            <>
                They need time to think about treatment. We will follow up on{' '}
                <strong>{formattedDate}</strong>
            </>
        );
    }

    if (action === 'inTx') {
        return (
            <>
                They want to go ahead with treatment. Their next appointment is
                on <strong>{formattedDate}</strong>
            </>
        );
    }

    return (
        <>
            They have a consultation booked on <strong>{formattedDate}</strong>
        </>
    );
};

const Container = styled.div`
    ${mix.padding({ padding: [3, 0, 0] })};
    ${mix.gap({ size: 3 })};
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const Title = styled.h2`
    ${mix.type({ level: 'h6' })};
    margin: 0;
    color: var(--gray-400);
`;

const ConfirmationWrapper = styled.div`
    ${mix.padding({ padding: [0, 3] })};
    ${mix.type({ level: 'body2' })};
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 2 })};
    flex: 1 0 auto;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    ${mix.gap({ size: 1.5 })};
`;

const IconWrapper = styled.div`
    ${mix.sq({ size: 5.5 })};
    background: var(--gray-100);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 ${mix.unit({ size: 5.5 })};
`;

const Text = styled.div`
    font-size: 16px;
`;
