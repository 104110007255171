import { array } from '$utils';
import React from 'react';
import styled, { css } from 'styled-components';
import { FailIcecream } from '../Fail';
import { Heading } from '../Heading';
import { Shadow } from '../Shadow';
import { AsyncStatus } from '../types';
import { mix } from '../util';

/**
 * Flo Table
 *
 * A generic Table component for rendering data tables in the Leadflo style,
 * supporting:
 *
 * 1. Loading state
 * 2. Frozen columns
 * 3. Error state
 * 4. Empty state
 * 5. Fixed columns with truncation
 *
 * A Table needs:
 *
 * 1. A list of columns (an empty string is permissible)
 * 2. If the last column is frozen
 * 3. A list of cells as a list of react nodes or renderable primitives
 * 4. Current state
 * 5. An optional empty state (defaulting to a generic empty state)
 * 6. An optional initial state (regardless of emptiness)
 * 7. An optional set of column widths
 */

interface Column {
    name: React.ReactNode;
    width?: string;
}

interface Props {
    status: AsyncStatus;
    columns: Column[];
    children: Array<Array<React.ReactNode>>;
    initial?: React.FC;
    empty?: React.FC;
    freezeLast?: boolean;
    selected?: Array<number>;
}

export const Table = (props: Props) => {
    const { columns, freezeLast = false } = props;

    const headers = columns.map((column, index) => {
        const isLast = index === columns.length - 1;
        return (
            <TableHeaderCell key={index} freeze={isLast && freezeLast}>
                {column.name}
            </TableHeaderCell>
        );
    });

    return (
        <StyledTable>
            <ColGroup columns={columns} />
            <TableHeader>
                <tr>{headers}</tr>
            </TableHeader>
            <TableBody {...props} />
        </StyledTable>
    );
};

interface ColGroupProps {
    columns: Column[];
}

const ColGroup = ({ columns }: ColGroupProps) => {
    const cols = columns.map((column, i) => {
        return (
            <col key={i} span={1} style={{ width: column.width ?? 'auto' }} />
        );
    });

    return <colgroup>{cols}</colgroup>;
};

const TableBody = (props: Props) => {
    const {
        columns,
        status,
        children,
        empty: Empty = DefaultEmpty,
        initial: Initial,
        freezeLast = false,
        selected = []
    } = props;

    if (status === 'error') {
        return (
            <FullscreenContent columns={columns}>
                <FailIcecream />
            </FullscreenContent>
        );
    }

    if (status === 'loading') {
        const cells = array.times(20, () => {
            return columns.map((_, i) => {
                return <Shadow key={i} height={2} />;
            });
        });

        return (
            <TableRows freezeLast={freezeLast} selected={selected}>
                {cells}
            </TableRows>
        );
    }

    if (status === 'idle' && Initial) {
        return (
            <FullscreenContent columns={columns}>
                <Initial />
            </FullscreenContent>
        );
    }

    if (children.length < 1) {
        return (
            <FullscreenContent columns={columns}>
                <Empty />
            </FullscreenContent>
        );
    }

    return (
        <TableRows freezeLast={freezeLast} selected={selected}>
            {children}
        </TableRows>
    );
};

interface TableRowsProps {
    children: Array<Array<React.ReactNode>>;
    freezeLast: boolean;
    selected?: Array<number>;
}

const TableRows = (props: TableRowsProps) => {
    const { children, freezeLast, selected = [] } = props;

    const rows = children.map((row, index) => {
        const cells = row.map((cell, index) => {
            const freeze = freezeLast && index === row.length - 1;
            return (
                <TableCell key={index} freeze={freeze}>
                    {cell}
                </TableCell>
            );
        });
        return (
            <TableRow key={index} selected={selected.includes(index)}>
                {cells}
            </TableRow>
        );
    });

    return <tbody>{rows}</tbody>;
};

interface FullscreenContentProps {
    columns: Column[];
    children: React.ReactNode;
}

const FullscreenContent = ({ columns, children }: FullscreenContentProps) => {
    return (
        <tbody>
            <tr>
                <td colSpan={columns.length}>{children}</td>
            </tr>
        </tbody>
    );
};

const DefaultEmpty = () => {
    return (
        <DefaultEmptyContainer>
            <Heading level="h3">Nothing to see here!</Heading>
            <Heading level="h5" profile="secondary">
                We might need more time to collect this information.
            </Heading>
        </DefaultEmptyContainer>
    );
};

const DefaultEmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0;
`;

const StyledTable = styled.table`
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: separate;
`;

const TableRow = styled.tr<{ selected?: boolean }>`
    ${({ selected }) =>
        selected &&
        css`
            ${mix.bg({ hue: 'grey', shade: '10', alpha: 0.5 })}
        `}
`;

interface TableCellProps {
    freeze: boolean;
}

const TableCell = styled.td<TableCellProps>`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 2 })};
    ${mix.color({ profile: 'body' })};
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 1px;
    min-width: 50px;

    ${({ freeze }) =>
        freeze &&
        css`
            position: sticky;
        `}
`;

const TableHeader = styled.thead`
    ${mix.bg({ hue: 'grey', shade: '10' })};
    position: sticky;
    top: 0;
    z-index: 4;
`;

const TableHeaderCell = styled.th<TableCellProps>`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.padding({ padding: 2 })};
    text-align: left;

    ${({ freeze }) =>
        freeze &&
        css`
            position: sticky;
        `}
`;
