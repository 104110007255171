import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { mix } from '../util';
import { Tooltip } from '$ui/Flo/Tooltip';

interface Props {
    label: string;
    onUpload: (photo: File) => void;
    photo: string | File | null;
    recommended?: {
        width: string;
        height: string;
    };
}

const ALLOWED_FILE_TYPES = [
    'image/apng',
    'image/jpeg',
    'image/png',
    'image/webp'
];

export const PhotoUpload = (props: Props) => {
    const { label, onUpload, photo, recommended } = props;

    const uploader = React.useRef<HTMLInputElement>(null);
    const [validationMessage, setValidationMessage] = React.useState<
        string | null
    >(null);

    const onClick = () => {
        if (!uploader.current) {
            return;
        }
        uploader.current.click();
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValidationMessage(null);

        const files = event.target.files;

        if (!files || files.length === 0) {
            return;
        }

        if (!ALLOWED_FILE_TYPES.includes(files[0].type)) {
            setValidationMessage('Please select a valid image to upload');
            return;
        }

        onUpload(files[0]);
    };

    let photoURL: null | string = null;
    if (photo && photo instanceof File) {
        photoURL = URL.createObjectURL(photo);
    }

    if (photo && typeof photo === 'string') {
        photoURL = photo;
    }

    return (
        <Container onClick={onClick}>
            <Tooltip size="small" open={!!validationMessage} hue="red">
                {validationMessage}
            </Tooltip>
            {recommended && (
                <PhotoRuler
                    width={recommended.width}
                    height={recommended.height}
                />
            )}
            {photo && (
                <Preview
                    src={photoURL as string}
                    onLoad={() => URL.revokeObjectURL(photoURL as string)}
                />
            )}

            <Direction hide={!!photo}>
                <Icon icon="Camera" size={3} opacity={0.4} />
                <Text level="body1" bold profile="secondary">
                    {label}
                </Text>
            </Direction>

            <Uploader
                type="file"
                ref={uploader}
                onChange={onFileChange}
                accept={'image/*'}
                multiple={false}
            />
        </Container>
    );
};

interface RulerProps {
    width: string;
    height: string;
}

const PhotoRuler = (props: RulerProps) => {
    const { width, height } = props;

    return (
        <Ruler>
            {width && (
                <RulerWidth>
                    <Text level="body2" profile="body">
                        {width}
                    </Text>
                </RulerWidth>
            )}
            {height && (
                <RulerHeight>
                    <Text level="body2" profile="body">
                        {height}
                    </Text>
                </RulerHeight>
            )}
        </Ruler>
    );
};

const Ruler = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const RulerWidth = styled.div<{ width?: string }>`
    position: absolute;
    bottom: -1.5rem;
    width: 100%;
    height: 1rem;
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'body' })};
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) calc(50% - 1px),
        currentColor calc(50%),
        rgba(0, 0, 0, 0) calc(50% + 1px)
    );
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        display: inline-block;
        position: absolute;
        ${mix.bg({ hue: 'grey', shade: '10' })};
        padding: 0 0.5rem;
        ${mix.type({ level: 'small' })};
    }

    :before {
        border-left: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
        content: '';
        position: absolute;
        left: 0;
        width: 1rem;
        height: 0.5rem;
    }

    :after {
        border-right: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
        content: '';
        position: absolute;
        right: 0;
        width: 1rem;
        height: 0.5rem;
    }
`;

const RulerHeight = styled.div<{ height?: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1.5rem;
    width: 1rem;
    text-align: center;
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'body' })};
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) calc(50% - 1px),
        currentcolor calc(50%),
        rgba(0, 0, 0, 0) calc(50% + 1px)
    );
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        position: absolute;
        display: inline-block;
        transform: rotate(90deg);
        ${mix.bg({ hue: 'grey', shade: '10' })};
        padding: 0 0.3rem;
        ${mix.type({ level: 'small' })};
    }

    :before {
        border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
        content: '';
        position: absolute;
        top: 0;
        width: 0.5rem;
        height: 1rem;
    }

    :after {
        border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
        content: '';
        position: absolute;
        bottom: 0;
        width: 0.5rem;
        height: 1rem;
    }
`;

const Preview = styled.img`
    display: block;
    width: auto;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 200ms ease;

    &:hover {
        opacity: 0.2;
    }
`;

const Uploader = styled.input`
    display: none;
`;

const Direction = styled.div<{ hide?: boolean }>`
    ${mix.padding({ padding: [1] })}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${mix.unit({ size: 1 })};
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    ${({ hide }) =>
        hide &&
        css`
            opacity: 1;
            transition: 200ms ease;
        `}
`;

const Container = styled.div`
    ${mix.bg({ hue: 'grey', shade: '8', alpha: 0.25 })}
    ${mix.round({ rounded: true })}
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: all 200ms ease;
    position: relative;

    &:hover {
        ${mix.bg({ hue: 'grey', shade: '6', alpha: 0.25 })}
    }
`;
