import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Icon, IconName } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { dates } from '$utils';

interface Props {
    icon?: IconName;
    title: string;
    action: string;
    time: string;
    onView?: () => void;
    onClose?: () => void;
    visible?: boolean;
}

const delta = (time: string) => dates.delta(time, new Date(), true);

export function Notification(props: Props) {
    const {
        icon,
        title,
        action,
        time,
        onView,
        onClose,
        visible = true
    } = props;
    const [duration, setDuration] = React.useState<string>(delta(time));

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setDuration(delta(time));
        }, 10_000);

        return () => clearInterval(intervalId);
    }, [time]);

    return (
        <Container visible={visible}>
            <Left>
                {icon && <NotificationIcon icon={icon} size={6} hue="white" />}

                <Content>
                    <div>{action}</div>
                    <Title>{title}</Title>
                    <Time>{duration}</Time>
                </Content>
            </Left>
            <Right>
                {onView && <Button onClick={onView}>View</Button>}
                {onClose && <Button onClick={onClose}>Close</Button>}
            </Right>
        </Container>
    );
}

const transitionIn = keyframes`
    from {
        transform: translate3d(0, 200%, 0) scale(.6);
        opacity: .5;
    }

    to {
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
    }
`;

const transitionOut = keyframes`
    from {
        transform: translate3d(0, 0, -1px) scale(1);
        opacity: 1;
    }

    to {
        transform: translate3d(0, 150%, -1px) scale(.6);
        opacity: 0;
    }
`;

const Container = styled.div<{
    visible: boolean;
}>`
    position: relative;
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body', dark: true })};
    ${mix.bg({ hue: 'primary', shade: '2' })};
    border-radius: 3px;
    display: flex;
    ${mix.width({ size: 49 })};
    overflow: hidden;
    will-change: transform;
    animation: ${transitionIn} 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) forwards;

    ${({ visible }) =>
        !visible &&
        css`
            animation: ${transitionOut} 0.4s forwards
                cubic-bezier(0.06, 0.71, 0.55, 1);
        `}
`;

const Left = styled.div`
    ${mix.padding({ padding: [2, 4, 2, 2] })};
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 2 })};
    flex: 1 1 auto;
    position: relative;
`;

const NotificationIcon = styled(Icon)`
    stroke-width: 1px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const Title = styled.div`
    ${mix.type({ level: 'body1', bold: true })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.width({ size: 22 })};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Time = styled.div`
    ${mix.type({ level: 'small' })};
`;

const Right = styled.div`
    ${mix.bg({ hue: 'primary', shade: '1' })};
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
`;

const Button = styled.div`
    ${mix.padding({ padding: [1, 4] })};
    ${mix.type({ level: 'body2' })};
    border-bottom: 1px solid rgba(255, 255, 255, 20%);
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
        border-bottom: 1px solid transparent;
    }

    &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 20%);
    }
`;
