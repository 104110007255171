import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ConfigLoadedState, ConfigState, ConfigStatus } from '../types';

export const configState = (state: RootState): ConfigState => state.config;

export const isConfigured = createSelector(
    configState,
    (config: ConfigState): config is ConfigLoadedState =>
        config.status === 'loaded',
);

export const baseURL = createSelector(
    [configState],
    (config) => config.config?.api_base_url,
);

export const selectUIBaseURL = createSelector(
    [configState],
    (config) => config.config?.ui_base_url,
);

export const proxyURL = createSelector(
    [configState],
    (config) => config.config?.proxy_base_url,
);

export const novuAppId = createSelector(
    [configState],
    (config) => config.config?.novu_app_id,
);

export const oldVersion = createSelector(
    configState,
    (config: ConfigState): boolean => {
        // __VERSION__ comes from webpack DefinePlugin
        if (
            config.status !== ConfigStatus.LOADED ||
            !config.api_version ||
            !__VERSION__ ||
            __VERSION__ === 'local'
        ) {
            return false;
        }

        return config.api_version != __VERSION__;
    },
);
