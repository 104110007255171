import * as React from 'react';
import { Button } from '$ui/Flo/Button';
import { applyPadding, scale } from '$ui/Flo/util';
import styled from 'styled-components';
import { Heading } from '../../../Flo/Heading';
import { NextActionContext } from '$types';
import { Align, ButtonMode, Hue } from '$ui/Flo/types';
import { IconName } from '$ui/Flo/Icon';

const ButtonGroup = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: [3, 4] })};
    border-top: 1px solid hsl(0, 0%, 92%);
    display: flex;
    justify-content: space-between;
`;

interface ForwardButtonProps {
    final: boolean;
    proceedable: boolean;
    onClick: () => void;
}

export const ForwardButton = ({
    final,
    onClick,
    proceedable
}: ForwardButtonProps) => {
    const text = final ? 'Confirm' : 'Next';
    const qa = final ? 'flow-confirm-button' : 'flow-next-button';
    const icon: IconName = final ? 'CheckCircle' : 'ArrowRight';
    const hue: Hue = proceedable ? 'primary' : 'grey';
    const mode: ButtonMode = proceedable ? 'solid' : 'text';

    const props = {
        id: 'flow-step-next',
        rounded: true,
        disabled: !proceedable,
        hue,
        mode,
        icon,
        onClick,
        align: 'right' as Align
    };

    return (
        <Button {...props} data-qa={qa}>
            {text}
        </Button>
    );
};

interface BackButtonProps {
    first: boolean;
    onClick: () => void;
}

export const BackButton = ({ first, onClick }: BackButtonProps) => {
    if (first) {
        return (
            <Button
                mode="text"
                icon="XCircle"
                hue="red"
                onClick={onClick}
                data-qa="flow-cancel-button"
            >
                Cancel
            </Button>
        );
    }

    return (
        <Button
            mode="text"
            icon="ArrowLeft"
            onClick={onClick}
            data-qa="flow-back-button"
        >
            Back
        </Button>
    );
};

const Content = styled.div`
    margin: 0 0 ${({ theme }) => scale(theme, 3)}px;
`;

export interface MoveableStepProps {
    onNext: (state: string, context?: Partial<NextActionContext>) => void;
    onBack: () => void;
}

export interface StepProps {
    title: string;
    children: React.ReactNode;
    proceedable?: boolean;
    onNext: () => void;
    onBack: () => void;
    first?: boolean;
    final?: boolean;
}

export const Step = (props: StepProps) => {
    const {
        title,
        children,
        onBack,
        onNext,
        first = false,
        final = false,
        proceedable = false
    } = props;
    return (
        <div>
            <Padding vertical={4} horizontal={6}>
                <Heading level="h6" align="center">
                    {title}
                </Heading>
            </Padding>
            <Content>{children}</Content>
            <ButtonGroup>
                <BackButton first={first} onClick={onBack} />
                <ForwardButton
                    proceedable={proceedable}
                    final={final}
                    onClick={onNext}
                />
            </ButtonGroup>
        </div>
    );
};

const Padding = styled.div<{ vertical: number; horizontal: number }>`
    ${({ theme, vertical, horizontal }) =>
        applyPadding({ theme, padding: [vertical, horizontal] })};
`;
