import { Link } from '$ui/Flo/Link';
import { Text } from '$ui/Flo/Text';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { DetailsRecord } from '../../Forms/Details';
import { Subsection } from '$ui/Settings/Section/Subsection';
import { Button } from '$ui/Flo/Button';

interface Props {
    details: DetailsRecord;
    repPhoto: string | File | null;
    onModify?: () => void;
}

export const GeneratedPreview = (props: Props) => {
    const { onModify } = props;

    const actions = (
        <>
            <Button
                id="signature-editor-modify"
                size="med-large"
                rounded
                mode="outline"
                onClick={onModify}
                tooltip="Add a custom HTML signature"
            >
                Add custom signature
            </Button>
        </>
    );

    return (
        <Subsection id="signature" title="Signature" actions={actions}>
            <Signature {...props} />
        </Subsection>
    );
};

export const Signature = (props: Props) => {
    const { details, repPhoto } = props;

    let photoURL: null | string = null;
    if (repPhoto && repPhoto instanceof File) {
        photoURL = URL.createObjectURL(repPhoto);
    }
    if (repPhoto && typeof repPhoto === 'string') {
        photoURL = repPhoto;
    }

    return (
        <Container data-testid="generated-preview">
            {photoURL && (
                <Image
                    src={photoURL}
                    onLoad={() => URL.revokeObjectURL(photoURL as string)}
                />
            )}
            <Details>
                {details.representative && (
                    <Text level="body2" bold>
                        {details.representative}
                    </Text>
                )}
                <Text level="body2" bold={!details.representative}>
                    {details.name}
                </Text>
                <Link href={`mailto:${details.email}`} level="body2">
                    {details.email}
                </Link>
                <Link href={`tel:${details.phone}`} level="body2">
                    {details.phone}
                </Link>
            </Details>
        </Container>
    );
};

const Image = styled.img`
    width: auto;
    height: ${mix.unit({ size: 18 })};
    object-fit: contain;
    ${mix.round({ rounded: true })}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    ${mix.padding({ padding: [2, 4, 3] })}
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: ${mix.unit({ size: 1 })};
    ${mix.padding({ padding: [1, 3] })}
`;
