import { Monogram } from '$ui/Flo/Monogram';
import { ColorToken, Hue, Shade, ThemeType } from '$ui/Flo/types';
import { mix, palette } from '$ui/Flo/util';
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { CommType } from '$ui/Timeline/Parts/CommType';
import { Automation } from '$ui/Timeline/Parts/Automation';

export interface AvatarProps {
    inbound: boolean;
    patientName: string;
    representative: {
        photo: string | null;
        name: string | null;
    };
    practiceName: string;
    color?: ColorToken;
    stroke?: ColorToken;
    hue?: Hue;
    shade?: Shade;
    onlyAttachments?: boolean;
    type?: 'Email' | 'SMS' | 'WhatsApp';
    isAutomation?: boolean;
}

export const Avatar = (props: AvatarProps) => {
    const theme = useContext(ThemeContext) as ThemeType;
    const { hue = 'primary', shade = '6', stroke } = props;

    if (props.onlyAttachments) {
        return (
            <Holder>
                <Photo {...props} color="primary-500" />
                <CommType {...props} />
            </Holder>
        );
    }

    return (
        <Container>
            <Arrow
                inbound={props.inbound}
                stroke={stroke}
                color={
                    props.color
                        ? `var(--${props.color})`
                        : palette({
                              theme,
                              hue,
                              shade,
                          })
                }
            >
                <svg
                    width="9"
                    height="17"
                    viewBox="0 0 9 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        id="Polygon 1"
                        fill="#2E90FA"
                        points="0,0 8,8 0,16"
                    />

                    {stroke && (
                        <>
                            <line x1={0} y1={0} x2={0} y2={16} stroke="white" />
                            <polyline fill="none" points="-1 -1 8.5,8.5 0,17" />
                        </>
                    )}
                </svg>
            </Arrow>
            <Photo {...props} color="primary-500" />
            <CommType {...props} />
            {props.isAutomation && <Automation />}
        </Container>
    );
};

interface ArrowProps {
    inbound: boolean;
    color: string;
    stroke?: string;
}

const Container = styled.div`
    position: relative;
    height: fit-content;
`;

const Arrow = styled.div<ArrowProps>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: ${mix.unit({ size: 2 })};

    ${({ stroke }) => {
        return (
            stroke &&
            css`
                svg {
                    left: -1px;
                    position: relative;
                }

                svg > polyline {
                    stroke: var(--${stroke});
                }
            `
        );
    }};

    ${({ inbound }) => {
        if (inbound) {
            return (
                inbound &&
                css`
                    right: ${mix.unit({ size: -2 })};

                    svg {
                        transform: rotate(180deg);
                    }
                `
            );
        }

        return css`
            left: ${mix.unit({ size: -2 })};
        `;
    }};

    svg > polygon {
        ${({ color }) => {
            return css`
                fill: ${color};
            `;
        }};
    }
`;

/**
 * Rules:
 *
 * - If inbound, show arrow pointing left
 * - if outbound, show arrow pointing right
 * - If inbound, show monogram of patient name
 * - If outbound, show representative photo
 * - If outbound and no rep photo, show monogram of rep name
 * - If outbound and no rep photo or name, show monogram of practice name
 */
const Photo = ({
    inbound,
    patientName,
    representative,
    practiceName
}: AvatarProps) => {
    if (inbound) {
        return (
            <Monogram
                color="gray-100"
                fontColor="primary-500"
                size={6}
                name={patientName}
            />
        );
    }

    if (representative.photo) {
        return <PhotoImg src={representative.photo} />;
    }

    if (representative.name) {
        return (
            <Monogram
                dark={true}
                color="primary-500"
                size={6}
                name={representative.name}
            />
        );
    }

    return (
        <Monogram
            dark={true}
            color="primary-500"
            size={6}
            name={practiceName}
        />
    );
};

const PhotoImg = styled.img`
    width: ${mix.unit({ size: 6 })};
    height: ${mix.unit({ size: 6 })};
    border-radius: ${mix.unit({ size: 6 })};
`;

const Holder = styled.div`
    position: relative;
    height: fit-content;
`;
