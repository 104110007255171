import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CheckDouble = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             className={className}
             style={style}
             onClick={onClick}
             width={size}
             height={size}
             fill="none"
             viewBox="0 0 16 9"
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.4222 0.484028C10.7071 0.71718 10.7491 1.1372 10.516 1.42216L4.92067 8.26086C4.46823 8.81384 3.66031 8.9119 3.08872 8.48321L0.933332 6.86667C0.63878 6.64575 0.579085 6.22789 0.799998 5.93333C1.02091 5.63878 1.43878 5.57909 1.73333 5.8L3.88872 7.41654L9.48403 0.577841C9.71718 0.292878 10.1372 0.250877 10.4222 0.484028Z"
                  fill={color} />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.58293 7.5201L7.73531 8.442C8.30587 8.89844 9.1375 8.81066 9.60018 8.24516L15.1826 1.42216C15.4158 1.1372 15.3738 0.71718 15.0888 0.484028C14.8039 0.250877 14.3838 0.292878 14.1507 0.577841L8.56824 7.40084L7.4273 6.48809L6.58293 7.5201Z"
                  fill={color} />
        </svg>
    );
};
