import { Subsection } from '$ui/Settings/Section/Subsection';
import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Button } from '$ui/Flo/Button';
import { mix } from '$ui/Flo/util';
import { SubmitHandler, useForm } from 'react-hook-form';
import { sanitize } from '@/utils/html';
import { Text } from '$ui/Flo/Text';

interface Props {
    html: string;
    onModify?: (html: string) => void;
    onCancel?: () => void;
}

interface Inputs {
    html: string;
}

export const Editor = (props: Props) => {
    const { html } = props;
    const { register, handleSubmit, formState } = useForm<Inputs>({
        mode: 'onChange'
    });

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        props.onModify?.(sanitize(data.html));
    };

    const onCancel: MouseEventHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.onCancel?.();
    };

    // for some reason, required to pass the happy path test. possibly due
    // to it being a proxy.
    formState.isDirty;

    const actions = (
        <>
            <Button
                id="signature-editor-cancel"
                size="med-large"
                rounded
                hue="red"
                mode="outline"
                confirm={formState.isDirty}
                onClick={onCancel}
            >
                Cancel
            </Button>
            <Button
                id="signature-editor-preview"
                size="med-large"
                rounded
                disabled={!formState.isValid}
            >
                Preview
            </Button>
        </>
    );

    return (
        <form
            data-testid="signature-editor-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Subsection
                padded
                id="signature"
                title="Signature"
                actions={actions}
            >
                <Text level="body2">
                    Paste or type in your custom signature's HTML code below,
                    and we'll include it in any automated emails from Leadflo.
                </Text>
                <TextArea
                    data-testid="signature-editor-textarea"
                    defaultValue={html}
                    {...register('html', { required: true })}
                />
            </Subsection>
        </form>
    );
};

const TextArea = styled.textarea`
    ${mix.height({ size: 18 })};
    ${mix.padding({ padding: 1.5 })};
    width: 100%;
    resize: none;
    border: 1px solid var(--gray-300, #d0d5dd);
    border-radius: 4px;
    margin-top: ${mix.unit({ size: 2 })}
`;
