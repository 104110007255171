import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Text } from '$ui/Flo/Text';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    practiceEmail?: string | null;
    onChange: (email: string, isValid?: boolean) => void;
    loading: boolean;
}

export const PracticeEmail = (props: Props) => {
    const { practiceEmail, onChange, loading } = props;

    return (
        <Container>
            <Text level="body2">
                The practice email is your default email address. This is where
                notifications will go, where emails are sent out from and what
                patients will reply to.
            </Text>
            <FloatingTextField
                type="email"
                loading={loading}
                label="Email address"
                value={practiceEmail ?? ''}
                onChange={onChange}
                required
            />
        </Container>
    );
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 2 })};
    ${mix.type({ level: 'body2' })};
`;

export const Subtitle = styled.div`
    ${mix.color({ profile: 'body' })};
    font-size: 16px;
`;
