import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileAac = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.33L66.8 23.39L67.3 23.32C67.2372 22.8217 67.0123 22.3579 66.66 22L45.85 1.16C45.7351 1.04307 45.6076 0.939113 45.47 0.849998L45.37 0.789998C45.2632 0.727382 45.153 0.670631 45.04 0.619998L44.86 0.559999C44.7653 0.524056 44.6684 0.493998 44.57 0.469998C44.4086 0.43804 44.2445 0.4213 44.08 0.419998H10.68C10.0178 0.422631 9.38341 0.686869 8.91513 1.15514C8.44686 1.62341 8.18263 2.25777 8.17999 2.92V29.77C8.17999 30.433 8.44338 31.0689 8.91223 31.5378C9.38107 32.0066 10.017 32.27 10.68 32.27C11.343 32.27 11.9789 32.0066 12.4478 31.5378C12.9166 31.0689 13.18 30.433 13.18 29.77V5.42H41.58V23.56C41.58 24.2213 41.842 24.8557 42.3087 25.3242C42.7754 25.7928 43.4087 26.0574 44.07 26.06L62.33 26.16V74.58H13.18V70.7C13.18 70.037 12.9166 69.4011 12.4478 68.9322C11.9789 68.4634 11.343 68.2 10.68 68.2C10.017 68.2 9.38107 68.4634 8.91223 68.9322C8.44338 69.4011 8.17999 70.037 8.17999 70.7V77.08C8.18263 77.7422 8.44686 78.3766 8.91513 78.8449C9.38341 79.3131 10.0178 79.5774 10.68 79.58H64.83C65.4922 79.5774 66.1266 79.3131 66.5948 78.8449C67.0631 78.3766 67.3274 77.7422 67.33 77.08V23.68C67.3299 23.5627 67.3199 23.4456 67.3 23.33ZM46.58 21.08V9L58.76 21.14L46.58 21.08Z" />
            <path d="M15.9 45.81L14.56 52.08H17.94L16.6 45.81H15.9Z" />
            <path d="M28.24 45.81L26.9 52.08H30.28L28.94 45.81H28.24Z" />
            <path d="M53.66 35.27H3.17001C2.50778 35.2726 1.87343 35.5369 1.40516 36.0051C0.936884 36.4734 0.672647 37.1078 0.670013 37.77V62.7C0.670013 63.363 0.933406 63.9989 1.40225 64.4678C1.87109 64.9366 2.50697 65.2 3.17001 65.2H53.66C54.3231 65.2 54.9589 64.9366 55.4278 64.4678C55.8966 63.9989 56.16 63.363 56.16 62.7V37.77C56.1574 37.1078 55.8931 36.4734 55.4249 36.0051C54.9566 35.5369 54.3222 35.2726 53.66 35.27ZM19.1 57.41L18.55 54.89H14L13.45 57.41H10.21L13.43 43.12H19.08V43.26L22.26 57.41H19.1ZM31.45 57.41L30.89 54.89H26.29L25.74 57.41H22.55L25.77 43.12H31.42V43.26L34.6 57.41H31.45ZM39.17 54C39.4156 54.3124 39.7386 54.5552 40.107 54.7043C40.4753 54.8534 40.8763 54.9036 41.27 54.85C42.4656 54.8427 43.6589 54.7458 44.84 54.56H45.04L45.11 57.22H44.97C43.6741 57.5198 42.35 57.6807 41.02 57.7C40.0476 57.7328 39.0776 57.5835 38.16 57.26C37.4461 56.9734 36.8432 56.4651 36.44 55.81C36.0124 55.1164 35.7269 54.3448 35.6 53.54C35.4274 52.4723 35.3504 51.3914 35.37 50.31C35.37 47.59 35.76 45.66 36.54 44.58C37.32 43.5 38.79 42.92 40.87 42.92C42.2603 42.9476 43.6428 43.1355 44.99 43.48H45.13L45 46.06H44.8C42.16 45.66 40.35 45.65 39.63 46.06C39.2265 46.3704 38.9522 46.8193 38.86 47.32C38.6381 48.4322 38.5475 49.5667 38.59 50.7C38.508 51.831 38.7073 52.9647 39.17 54Z" />
        </svg>
    );
};
