export enum SettingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    LOADED = 'loaded',
    ERROR = 'error',

    SAVING = 'saving',
    SAVED = 'saved',
    ERROR_SAVING = 'error_saving',
}

interface Settings {
    status: SettingStatus;
}

export interface PracticePayload {
    name: string;
    region: string;
    representative: string | null;
    phone: string | null;
    email: string | null;
    types: string[];
    available_types: string[];
    signature: {
        enabled: boolean;
        html: string;
    };
}

export type PracticeSettings = Settings & Partial<PracticePayload>;

export interface LoadedPracticeSettings extends PracticePayload {
    status: SettingStatus.LOADED;
}

export interface Assets {
    [asset: string]: string;
}

export type AssetSettings = Settings & Assets;

export interface LoadedAssets extends Assets {
    status: SettingStatus.LOADED;
}

export interface BusinessHour {
    opens: string;
    closes: string;
}

export interface Autoresponder {
    message: string | null;
    enabled: boolean;
}

export interface BusinessHours {
    monday: BusinessHour | null;
    tuesday: BusinessHour | null;
    wednesday: BusinessHour | null;
    thursday: BusinessHour | null;
    friday: BusinessHour | null;
    saturday: BusinessHour | null;
    sunday: BusinessHour | null;
}

export interface CommsSettings {
    sending_email: string;
    business_hours: BusinessHours;
    sms_autoresponses: {
        [type: string]: Autoresponder;
    };
}

export interface SmsStatus {
    number: string;
}

export interface GetCommsSettings extends CommsSettings {
    sms_status: SmsStatus | null;
}

export interface SettingsMap {
    practice: PracticeSettings | LoadedPracticeSettings;
    assets: AssetSettings | LoadedAssets;
    communications: Settings & Partial<GetCommsSettings>;
    dns: Settings & Partial<ClientDns>;
    testEmail: Settings;
}

export enum DkimStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    NOT_STARTED = 'NOT_STARTED',
}

export interface Records {
    name: string;
    value: string;
}

export interface ClientDns {
    hostname: string;
    spf: string;
    dkim: {
        status: DkimStatus;
        records: Records[];
    };
}
