import fetchBuilder from 'fetch-retry-ts';

const retryingFetch = fetchBuilder(fetch);

/** Define max retries */
const MAX_RETRIES = 5;

/**
 * Implement method that would retry fetch.
 * Currently, it would retry on any network failures.
 * If needed on specific add specific error add property retryOn: []
 * @param input
 * @param init
 */
export const fetchRetry = (input: RequestInfo | URL, init?: RequestInit) => {
    if (!__RETRIES__) {
        return fetch(input, init);
    }

    return retryingFetch(input, {
        ...init,
        retries: MAX_RETRIES,
        retryDelay: (attempt: number): number => Math.pow(2, attempt) * 1000,
    });
};
