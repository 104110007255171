import { Action as ActionSchema } from '$state/types/automations';
import { mix } from '$ui/Flo/util';
import { Text } from '$ui/Flo/Text';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '$ui/Flo/Icon';
import { Hue, Shade } from '$ui/Flo/types';
import { Toggle } from '$ui/Flo/Toggle';
import { Button } from '$ui/Flo/Button';
import { Shadow } from '$ui/Flo/Shadow';
import { RequiresCustomisation } from './RequiresCustomisation';
import { ActionCondition } from '$ui/Automations/ActionCondition';

interface Props {
    action: ActionSchema;
    onEnable: (state: boolean) => void;
    onView: () => void;
    loading?: boolean;
    requiresCustomisation?: boolean;
}

export const Action = (props: Props) => {
    const { onEnable, onView, action: item, loading } = props;
    const { name, enabled, action, requiresCustomisation = false } = item;
    return (
        <Container requiresCustomisation={requiresCustomisation}>
            {!loading && item.condition && (
                <ActionCondition
                    {...item.condition}
                    requiresCustomisation={requiresCustomisation}
                />
            )}
            <Inner>
                <Section>
                    {!loading && (
                        <>
                            {icon(action, requiresCustomisation)}
                            <Text level="button" profile="body">
                                {name.toUpperCase()}
                            </Text>
                        </>
                    )}
                    {loading && (
                        <>
                            <span style={{ width: '200px' }}>
                                <Shadow
                                    height={5}
                                    width={Math.random() * 75 + 25}
                                    rounded
                                />
                            </span>
                        </>
                    )}
                </Section>
                <Section>
                    <RequiresCustomisation show={requiresCustomisation} />
                    <MarginRight>
                        <Toggle
                            enabled={requiresCustomisation ? false : enabled}
                            onChange={onEnable}
                            disabled={loading ? true : requiresCustomisation}
                        />
                    </MarginRight>
                    <Button
                        align="center"
                        mode="outline"
                        size="small"
                        onClick={() => onView()}
                        rounded
                        disabled={loading}
                    >
                        View
                    </Button>
                </Section>
            </Inner>
        </Container>
    );
};

const icon = (action: 'email' | 'sms', requiresCustomisation: boolean) => {
    const props = {
        hue: (requiresCustomisation ? 'red' : 'primary') as Hue,
        shade: (requiresCustomisation ? '4' : '6') as Shade,
        size: 3.5,
        opacity: 1
    };

    switch (action) {
        case 'email':
            return (
                <MarginRight>
                    <Icon icon="Mail" {...props} />
                </MarginRight>
            );
        case 'sms':
            return (
                <MarginRight>
                    <Icon icon="MessageSquare" {...props} />
                </MarginRight>
            );
    }
};

const Container = styled.div<{ requiresCustomisation?: boolean }>`
    ${mix.round({ rounded: true })}
    overflow: hidden;
    ${mix.shadow()}
    border-left: 3px solid ${mix.palette({ hue: 'primary', shade: '5' })};

    ${({ requiresCustomisation }) =>
        requiresCustomisation &&
        css`
            border: 1px solid ${mix.palette({ hue: 'red', shade: '4' })};
            border-left: 3px solid ${mix.palette({ hue: 'red', shade: '4' })};
        `};
`;

const Inner = styled.div`
    ${mix.padding({ padding: [2] })}
    background: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const MarginRight = styled.span`
    ${mix.margin({ margin: [0, 2, 0, 0] })}
    display: flex;
    justify-content: center;
`;

const Section = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
