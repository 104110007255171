import { loadPipelineReport } from '$state/concerns/reporting';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import {
    ConnectedBrokenMetric,
    ConnectedMetric
} from '$ui/Reporting/ConnectedMetric';
import * as React from 'react';
import styled from 'styled-components';
import { selectChanged, selectQuery } from '$state/concerns/patient-filter';
import { Cell, Frame, Grid, HorizontalLine } from '$ui/Reporting/Layout';
import { usePageTitle } from '@/utils/hooks';
import { withState } from '$state';
import { featureEnabled } from '$state/queries/features';

export const Pipeline = () => {
    const dispatch = useAppDispatch();
    const query = useAppSelector(selectQuery);
    const queryChanged = useAppSelector(selectChanged);
    const patientExportEnabled = useAppSelector(
        withState(featureEnabled, 'patient-export'),
    );

    React.useEffect(() => {
        if (patientExportEnabled && queryChanged) {
            dispatch(loadPipelineReport(query));
        }
    }, [query, queryChanged, patientExportEnabled]);

    const runReport = () => {
        dispatch(loadPipelineReport(query));
    };

    usePageTitle('Pipeline - Reporting');

    return (
        <Frame title="Pipeline" run={runReport} description={description}>
            <Grid>
                <Cell right>
                    <ConnectedMetric
                        id="pipeline/newLeads"
                        report="pipeline"
                        name="new_leads"
                        label="New leads"
                        fill
                    />
                </Cell>
                <Cell right down>
                    <ConnectedMetric
                        id="pipeline/consultsBooked"
                        report="pipeline"
                        name="consults_booked"
                        label="Consults booked"
                        fill
                    />
                </Cell>
                <Cell right>
                    <ConnectedMetric
                        id="pipeline/txPlanConsults"
                        report="pipeline"
                        name="tx_plan_consults"
                        label="Tx plan consults booked"
                        tooltip="Not every lead goes through this stage, some will progress to treatment direct from consultation"
                        fill
                    />
                </Cell>
                <Cell>
                    <ConnectedMetric
                        id="pipeline/goneAhead"
                        report="pipeline"
                        name="won"
                        label="Gone ahead"
                        fill
                    />
                </Cell>
                <FailedCell>
                    <ConnectedMetric
                        id="pipeline/failedToAttend"
                        report="pipeline"
                        name="failed_to_attend"
                        label="FTA initial consult"
                        hue="red"
                        tooltip="Patients that failed to attend their initial consultation"
                    />
                </FailedCell>
                <LostCell>
                    <ConnectedBrokenMetric
                        id="pipeline/lost"
                        report="pipeline"
                        name="lost"
                        label="Lost"
                        hue="red"
                        tooltip="Total losses do not include patients you have blocked"
                        tooltipAlign="right"
                    />
                </LostCell>
            </Grid>
            <HorizontalLine />
            <Grid>
                <ConnectedMetric
                    id={null}
                    report="pipeline"
                    name="consult_rate"
                    label="Conversion rate to consult"
                    hue="green"
                />
                <ConnectedMetric
                    id={null}
                    report="pipeline"
                    name="won_rate"
                    label="Conversion rate to won"
                    hue="green"
                />
            </Grid>
        </Frame>
    );
};

const description = `The Pipeline report indicates the performance of your sales funnel, from new lead all the way to starting treatment. Use this report to find and fix bottlenecks in your sales process. This, however, only reports on patients that enter Leadflo as new leads - patients added as consultations or in treatment, for example, are not included in this report. In addition, it only reports on the progression of new leads in the selected time period.`;

const FailedCell = styled.div`
    grid-column: 2;
`;

const LostCell = styled.div`
    grid-column: 4;
`;
