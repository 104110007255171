import { EmailTemplate, TemplateStatus } from '$state/types/automations';
import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import UnlayerEditor from 'react-email-editor';
import styled from 'styled-components';
import { useAppSelector } from '$state';
import { selectPlaceholders } from '$state/concerns/placeholders';

interface Props {
    email: EmailTemplate;
    state: TemplateStatus;
    onSave: (email: EmailTemplate, requiresCustomisation: boolean) => void;
    requiresCustomisation: boolean;
}

export const EmailEditor = (props: Props) => {
    const { email, state, onSave, requiresCustomisation } = props;

    const mergeTags = useAppSelector(selectPlaceholders);

    const editorRef = React.useRef<UnlayerEditor>(null);

    // There's an issue with Unlayer with functional components
    // and the ref doesn't work quite right
    // This is my own solution and seems to work 100% of the time
    // https://github.com/unlayer/react-email-editor/issues/22
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        if (ready) {
            editorRef.current!.loadDesign(JSON.parse(email.definition));
        }
    }, [ready]);

    const save = () => {
        editorRef.current!.exportHtml((data) => {
            onSave(
                {
                    subject: email.subject,
                    preview: email.preview,
                    definition: JSON.stringify(data.design),
                    html: data.html,
                    finished_customising: false
                },
                requiresCustomisation
            );
        });
    };

    return (
        <>
            <UnlayerEditor
                ref={editorRef}
                onLoad={() => setReady(true)}
                options={{
                    tools: {
                        html: {
                            enabled: false
                        },
                        timer: {
                            enabled: false
                        },
                        form: {
                            enabled: false
                        },
                        menu: {
                            enabled: false
                        },
                        social: {
                            enabled: false
                        }
                    },
                    mergeTags
                }}
            />
            <Edit>
                <Button
                    icon="Download"
                    disabled={state != TemplateStatus.EDITING}
                    hue={state == TemplateStatus.ERROR ? 'red' : 'primary'}
                    onClick={save}
                    rounded
                >
                    Save
                </Button>
            </Edit>
        </>
    );
};

export const Edit = styled.div`
    position: absolute;
    right: 2vw;
    bottom: 2vw;
`;
