import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { FieldProps } from '$ui/Flo/Field';

interface Props extends FieldProps {
    text: string
}

export const Label = (props: Props) => {
    const { text } = props;

    return  <StyledLabel>{text}</StyledLabel>;
}

const StyledLabel = styled.label`
    ${mix.type({ level: 'body2', bold: true })};
`;
