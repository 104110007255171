import { useHistory } from 'react-router';
import { Text } from '$ui/Flo/Text';
import { Button } from '$ui/Flo/Button';
import React from 'react';
import { Heading } from '$ui/Flo/Heading';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import OutlookSvg from '$resources/outlook.svg';
import GmailSvg from '$resources/gmail.svg';
import WhatsAppSvg from '$resources/whatsapp.svg';
import DentallySvg from '$resources/dentally.svg';
import { ButtonMode, Hue } from '$ui/Flo/types';
import { IntegrationStatusIcon } from '$ui/Integrations/IntegrationStatusIcon';
import { selectIntegrations, selectStatus } from '$state/concerns/integrations';
import { useAppSelector } from '$state';
import { Integration, IntegrationState } from '$types/integrations';
import { Shadow } from '$ui/Flo/Shadow';

export interface IntegrationProps {
    state: IntegrationState;
    integration: string;
    description: string;
    displayName: string;
    logo: string;
    loading?: boolean;
}

export type Data = Record<string, IntegrationProps>;

export type MultipleIntegrationProps = {
    integrations: Array<Integration>;
    loading?: boolean;
};

export const ConnectedIntegrationCards = () => {
    const status = useAppSelector(selectStatus);
    const integrations = useAppSelector(selectIntegrations);

    return (
        <IntegrationCards
            integrations={integrations}
            loading={status === 'loading'}
        />
    );
};

export const IntegrationCards = (props: MultipleIntegrationProps) => {
    const { integrations, loading } = props;

    if (loading) {
        return (
            <IntegrationLayout>
                <Heading level="h2" align="left">
                    Integrations
                </Heading>
                <Cards>
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                </Cards>
            </IntegrationLayout>
        );
    }

    const cards = integrations.map((integration) => (
        <IntegrationCard key={integration.integration} {...integration} />
    ));

    return (
        <IntegrationLayout>
            <Heading level="h2" align="left">
                Integrations
            </Heading>
            <Cards>{cards}</Cards>
        </IntegrationLayout>
    );
};

export const LoadingCard = () => {
    return (
        <Container>
            <Shadow rounded width="8" height={8} />
            <LabelStatusBar>
                <Shadow rounded width="10" height={4} />
            </LabelStatusBar>
            <Shadow rounded width="31" height={3} />
            <Shadow rounded width="12.5" height={4.5} />
        </Container>
    );
};

export const IntegrationCard = (props: IntegrationProps) => {
    const { state, integration, displayName, description } = props;
    const history = useHistory();
    const { hue, mode, label } = buttonPropertiesByIntegrationState[state];

    return (
        <Container>
            {integration === 'gmail' && <GmailLogo />}
            {integration === 'outlook' && <OutlookLogo />}
            {integration === 'dentally' && <DentallyLogo />}
            {integration === 'whatsapp' && <WhatsAppLogo />}
            <LabelStatusBar>
                <Text bold>{displayName}</Text>
                <IntegrationStatusIcon state={state} />
            </LabelStatusBar>
            <Text>{description}</Text>
            <Button
                rounded
                mode={mode}
                hue={hue}
                align="right"
                icon="ArrowRight"
                size="medium"
                onClick={() =>
                    history.push(`/settings/integrations/${integration}`)
                }
            >
                {label}
            </Button>
        </Container>
    );
};

const buttonPropertiesByIntegrationState: {
    [key in IntegrationState]: ButtonProps;
} = {
    healthy: {
        label: 'Manage',
        mode: 'outline',
        hue: 'primary'
    },
    unhealthy: {
        label: 'Repair',
        mode: 'outline',
        hue: 'red'
    },
    disconnected: {
        label: 'Set Up',
        mode: 'solid',
        hue: 'primary'
    },
    disconnecting: {
        label: 'Manage',
        mode: 'solid',
        hue: 'yellow'
    }
};

type ButtonProps = {
    label: string;
    mode: ButtonMode;
    hue: Hue;
};

const IntegrationLayout = styled.div`
    ${mix.padding({ padding: 2 })};
    ${mix.gap({ size: 2 })};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${mix.gap({ size: 2 })};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 6px;
    border-radius: 6px;
    ${mix.gap({ size: 2 })};
    ${mix.padding({ padding: 4 })};
    ${mix.bg({ hue: 'grey', shade: '10', alpha: 0.5 })}
`;

const LabelStatusBar = styled.div`
    display: flex;
    flex-direction: row;
    ${mix.gap({ size: 1 })};
    align-items: center;
`;

const OutlookLogo = styled(OutlookSvg)`
    ${mix.height({ size: 8 })};
    ${mix.fill({ hue: 'primary', shade: '6' })};
`;

const GmailLogo = styled(GmailSvg)`
    ${mix.height({ size: 8 })};
    ${mix.fill({ hue: 'primary', shade: '6' })};
`;

const DentallyLogo = styled(DentallySvg)`
    ${mix.height({ size: 8 })}
    ${mix.fill({ hue: 'primary', shade: '6' })}
`;

const WhatsAppLogo = styled(WhatsAppSvg)`
    ${mix.height({ size: 8 })}
    ${mix.fill({ hue: 'primary', shade: '6' })}
`;
