import * as React from 'react';
import styled from 'styled-components';
import { AlertCircle } from 'react-feather';

interface Props {
    text?: string;
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Icon = styled(AlertCircle)`
    color: #df4b59;
    margin-bottom: 2rem;
    width: 10rem;
    height: auto;
`;

const Text = styled.span`
    font-size: 22px;
    font-weight: 300;
    color: grey;
`;

export const Failed = (props: Props) => {
    const { text = 'Oops! Something went wrong :(' } = props;

    return (
        <Container>
            <Icon />
            <Text role="alert">{text}</Text>
        </Container>
    );
};
