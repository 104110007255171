export function maybe<_, __>(
    a: undefined,
    fn: (a: undefined) => undefined
): undefined;
export function maybe<_, __>(a: null, fn: (a: null) => undefined): null;
export function maybe<T, U>(a: T | undefined | null, fn: (a: T) => U): U;
export function maybe<T, U>(
    a: T | null | undefined,
    fn: (a: T) => U
): U | null | undefined {
    return a === null || a == undefined ? (a as null | undefined) : fn(a);
}
