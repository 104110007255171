import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

export const Container = styled.div`
    ${mix.gap({ size: 4 })};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LogoContainer = styled.div`
    ${mix.padding({ padding: [4, 0] })};
`;

export const StatusContainer = styled.div`
    ${mix.padding({ padding: [4, 0] })};
`;

export const PopupContainer = styled(Container)`
    ${mix.padding({ padding: [4, 0] })};
`;

export const ButtonGroup = styled.div`
    ${mix.gap({ size: 1 })};
    display: flex;
`;
