import {
    Autoresponder,
    BusinessHour,
    CommsSettings
} from '$state/types/client';
import React from 'react';
import { Container, Subtitle } from './SendingEmail';
import { Text } from '$ui/Flo/Text';
import { Check } from '$ui/Flo/Check';
import styled from 'styled-components';
import { Shadow } from '$ui/Flo/Shadow';
import { FloatingTextField } from '$ui/Flo/Field/FloatingTextField';
import { Toggle } from '$ui/Flo/Toggle';
import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { TimeField } from '$ui/Flo/TimeField';

interface Settings {
    business_hours: CommsSettings['business_hours'];
    sms_autoresponses: CommsSettings['sms_autoresponses'];
}

interface Props {
    loading: boolean;
    settings: Settings;
    onChange: (value: Settings) => void;
}

const DefaultHours: BusinessHour = {
    opens: '09:00:00',
    closes: '17:00:00'
};

export const SmsAutoresponder = (props: Props) => {
    const { loading, settings, onChange } = props;

    return (
        <Container>
            <Subtitle>
                Leadflo can automatically reply to new leads with a text
                message.
            </Subtitle>
            <Text level="body2">
                These messages have an extremely high level of engagement. We
                recommend you enable these and configure what messages are sent
                out and when.
            </Text>
            <Hours>
                {loading &&
                    Array.from({ length: 7 }).map((_, i) => (
                        <BusinessHourItem
                            key={i}
                            day={i.toString()}
                            value={DefaultHours}
                            onChange={() => null}
                            loading
                        />
                    ))}
                {!loading &&
                    settings.business_hours &&
                    Object.entries(settings.business_hours).map(
                        ([day, value]) => (
                            <BusinessHourItem
                                key={day}
                                day={day}
                                value={value}
                                onChange={(val) => {
                                    onChange({
                                        ...settings,
                                        business_hours: {
                                            ...settings.business_hours,
                                            [day]: val
                                        }
                                    });
                                }}
                            />
                        )
                    )}
            </Hours>
            <Autoresponses>
                {loading &&
                    Array.from({ length: 4 }).map((_, i) => (
                        <AutoresponseItem
                            key={i}
                            type={i.toString()}
                            value={{
                                enabled: false,
                                message: ''
                            }}
                            onChange={() => null}
                            loading
                        />
                    ))}
                {!loading &&
                    settings.sms_autoresponses &&
                    Object.entries(settings.sms_autoresponses).map(
                        ([type, value]) => (
                            <AutoresponseItem
                                key={type}
                                type={type}
                                value={value}
                                onChange={(val) => {
                                    onChange({
                                        ...settings,
                                        sms_autoresponses: {
                                            ...settings.sms_autoresponses,
                                            [type]: val
                                        }
                                    });
                                }}
                            />
                        )
                    )}
            </Autoresponses>
        </Container>
    );
};

const AutoresponseItem = ({
    type,
    loading,
    value,
    onChange
}: {
    type: string;
    loading?: boolean;
    value: Autoresponder;
    onChange: (value: Autoresponder) => void;
}) => {
    if (loading) {
        return (
            <AutoresponseWrapper>
                <Title>
                    <Shadow width={25} height={2} rounded />
                    <Shadow width={10} height={2} rounded />
                </Title>
                <Field>
                    <Shadow width={100} height={10} rounded />
                </Field>
            </AutoresponseWrapper>
        );
    }

    return (
        <AutoresponseWrapper>
            <Title>
                <Text level="body2" bold>
                    <Type>{type.replace(/_/g, ' ')}</Type>
                </Text>
                <Toggle
                    enabled={value.enabled}
                    onChange={(enabled) => {
                        onChange({
                            ...value,
                            enabled
                        });
                    }}
                />
            </Title>
            <Field>
                <FloatingTextField
                    multiline
                    autosize
                    value={value.message}
                    onChange={(message) => {
                        onChange({
                            ...value,
                            message
                        });
                    }}
                />
            </Field>
        </AutoresponseWrapper>
    );
};

const BusinessHourItem = ({
    loading,
    day,
    value,
    onChange
}: {
    loading?: boolean;
    day: string;
    value: BusinessHour;
    onChange: (value: BusinessHour | null) => void;
}) => {
    const [draftValue, setDraftValue] = React.useState(value);
    const [isValid, setIsValid] = React.useState(true);

    React.useEffect(() => {
        if (loading) {
            return;
        }

        if (draftValue) {
            const { opens, closes } = draftValue;
            setIsValid(isOpeningLower(opens, closes));
        }

        onChange(draftValue);
    }, [draftValue]);

    if (loading) {
        return (
            <OpeningAndClosingTime>
                <Row gap={2}>
                    <Select>
                        <Shadow rounded height={2.5} />
                    </Select>
                    <Shadow height={2} rounded width={25} />
                </Row>
                <Row gap={1}>
                    <TimeAt>
                        <Shadow height={2} rounded />
                    </TimeAt>
                    <TimeAt>
                        <Shadow height={2} rounded />
                    </TimeAt>
                </Row>
            </OpeningAndClosingTime>
        );
    }

    return (
        <BHWrapper>
            {!isValid && (
                <ErrorMessage>
                    Opening time must be before closing time
                    <Icon icon="AlertCircle" size={2} hue="red" />
                </ErrorMessage>
            )}
            <OpeningAndClosingTime>
                <Row gap={2}>
                    <Check
                        theme="blue"
                        checked={!!value}
                        onChange={(checked) => {
                            if (checked) {
                                if (!draftValue) {
                                    setDraftValue(DefaultHours);
                                }
                                onChange(draftValue);
                            } else {
                                onChange(null);
                            }
                        }}
                    />
                    <Day>{day}</Day>
                </Row>
                <Row gap={1}>
                    {value === null && <Closed>Closed</Closed>}
                    {value?.opens && (
                        <TimeField
                            label="Opens at"
                            value={value.opens}
                            onChange={(v) =>
                                setDraftValue({ ...value, opens: v })
                            }
                            interval={30}
                        />
                    )}
                    {value?.closes && (
                        <TimeField
                            label="Closes at"
                            value={value.closes}
                            onChange={(v) =>
                                setDraftValue({ ...value, closes: v })
                            }
                            interval={30}
                        />
                    )}
                </Row>
            </OpeningAndClosingTime>
        </BHWrapper>
    );
};

export function isOpeningLower(open: string, close: string): boolean {
    const time1 = new Date(`2000-01-01T${open}`);
    const time2 = new Date(`2000-01-01T${close}`);

    return time1 < time2;
}

const Hours = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 1 })};
`;

const BHWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div<{ gap?: number }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${({ gap }) => mix.gap({ size: gap })};
    width: 50%;
    ${mix.type({ level: 'body2' })};
    ${mix.color({ profile: 'body' })};
`;

const Day = styled.div`
    text-transform: capitalize;
`;

const Select = styled.div`
    min-width: 20px;
    max-height: 20px;
`;

const Closed = styled.div`
    color: ${mix.palette({ hue: 'grey', shade: '5' })} !important;
    text-transform: capitalize;
    ${mix.padding({ padding: 1 })}
`;

const TimeAt = styled.div`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 1 })};
    border-radius: 2px;
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '8' })};
    background: white;
    display: flex;
    width: 50%;
    justify-content: space-between;
`;

const Autoresponses = styled.div`
    ${mix.color({ profile: 'body' })};
    ${mix.type({ level: 'body2' })};
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 3 })}
`;

const AutoresponseWrapper = styled.div`
    ${mix.color({ profile: 'body' })};
    display: flex;
    flex-direction: column;
    width: 100%;
    ${mix.gap({ size: 1 })};
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Field = styled.div`
    ${mix.color({ profile: 'body' })};
    width: 100%;
`;

const Type = styled.div`
    text-transform: capitalize;
`;

const ErrorMessage = styled.div`
    color: ${mix.palette({ hue: 'red', shade: '5' })};
    ${mix.type({ level: 'small' })};
    align-self: flex-end;
    ${mix.gap({ size: 0.5 })};
    display: flex;
    align-items: center;
`;

const OpeningAndClosingTime = styled.div`
    display: flex;
    height: 36px;
    align-items: center;
`;
