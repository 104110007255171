import * as React from 'react';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon, IconName } from '$ui/Flo/Icon';
import { useHistory } from 'react-router';
import { Channels } from '$state/types';
import { useAppDispatch } from '$state';
import { closePatient } from '$state/concerns/patient';

interface AccordionSubItem {
    id: string;
    name: string;
    channel: Channels;
    icon: IconName;
    selectedTemplateId: string | undefined;
    onTemplateSelected: (id: string) => void;
    editable?: boolean;
}

export const AccordionSubItem = (props: AccordionSubItem) => {
    const history = useHistory();
    const {
        id,
        name,
        channel,
        icon,
        selectedTemplateId,
        onTemplateSelected,
        editable
    } = props;

    const dispatch = useAppDispatch();

    const onClick = () => {
        onTemplateSelected(id);
    };

    const onEdit = () => {
        history.push(`/settings/templates/${channel}/${id}`);
        dispatch(closePatient());
    };

    return (
        <SubItem isOpen={selectedTemplateId === id} onClick={onClick}>
            <SubItemIcon clickable icon={icon} size={2} />
            <Title>{name}</Title>
            {editable && (
                <EditIcon onClick={onEdit} clickable icon="Edit" size={2} />
            )}
        </SubItem>
    );
};

const SubItem = styled.div<{ isOpen?: boolean }>`
    ${mix.padding({ padding: [1, 2] })}
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${mix.unit({ size: 2.5 })};
    user-select: none;

    ${({ isOpen }) =>
        isOpen &&
        css`
            ${mix.bg({ hue: 'grey', shade: '9' })};
            color: ${mix.palette({ hue: 'primary', shade: '6' })};
        `}
    &:hover {
        cursor: pointer;
    }
`;

const SubItemIcon = styled(Icon)`
    flex: 0 0 auto;
`;

const EditIcon = styled(Icon)`
    justify-self: flex-end;
`;

const Title = styled.div`
    flex: 1 1 auto;
    ${mix.type({ level: 'body2', bold: false })}
`;
