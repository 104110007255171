import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const CommTypeLeadFlo = ({ size = 20, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="20" rx="10" fill="#0F1A30" shapeRendering="geometricPrecision" />
            <path d="M15.7832 9.89176C15.7832 9.07369 15.6304 8.30729 15.3257 7.59307C15.0206 6.87886 14.6007 6.25401 14.065 5.71852C13.5292 5.18286 12.9043 4.76256 12.1901 4.45747C11.4762 4.15272 10.7095 4 9.89176 4C9.07334 4 8.30694 4.15272 7.5929 4.45747C6.87886 4.76256 6.254 5.18268 5.71817 5.71852C5.18268 6.25401 4.76221 6.87886 4.45747 7.59307C4.15237 8.30729 4 9.07369 4 9.89176C4 10.71 4.15237 11.4762 4.45747 12.1904C4.76221 12.9045 5.18251 13.5293 5.71817 14.065C6.254 14.6005 6.87886 15.0211 7.5929 15.3259C8.30694 15.631 9.07317 15.7833 9.89176 15.7833V18C9.89176 18 13.914 14.2158 14.065 14.065C14.1441 13.9859 14.2198 13.9043 14.2938 13.8213L14.2963 13.8189C14.7227 13.3405 15.0663 12.798 15.3257 12.1904C15.6304 11.4762 15.7832 10.71 15.7832 9.89176ZM7.36635 12.4359C7.03978 12.1088 6.78042 11.7256 6.58772 11.2865C6.39486 10.8477 6.29851 10.3826 6.29851 9.89176C6.29851 9.40078 6.39486 8.93581 6.58772 8.49701C6.78042 8.05822 7.03978 7.67511 7.36635 7.34767C7.69256 7.02058 8.07445 6.76384 8.51202 6.57778C8.94942 6.39189 9.413 6.29869 9.90258 6.29869C10.3918 6.29869 10.8554 6.39172 11.2931 6.57778C11.7305 6.76384 12.1123 7.02058 12.4388 7.34767C12.765 7.67511 13.0207 8.05822 13.2061 8.49701C13.3915 8.93598 13.4843 9.40078 13.4843 9.89176C13.4843 10.3826 13.3916 10.8479 13.2061 11.2865C13.0207 11.7256 12.765 12.1088 12.4388 12.4359C12.1123 12.7633 11.7304 13.02 11.2931 13.2057C10.8554 13.3918 10.3918 13.4848 9.90258 13.4848C9.413 13.4848 8.94942 13.392 8.51202 13.2057C8.07445 13.02 7.69256 12.7633 7.36635 12.4359Z" shapeRendering="geometricPrecision" fill="white" />
        </svg>

    );
};
