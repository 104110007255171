import * as React from 'react';
import styled from 'styled-components';
import {
    applyBg,
    applyPadding,
    scale,
    mix
} from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';

export interface Props {
    onClose: () => void;
    header?: React.ReactNode;
    body?: React.ReactNode;
}

export const LightModal = (props: Props) => {
    const { onClose, body, header } = props;

    return (
        <ModalOverlay>
            <ModalContainer>
                {header && (
                    <ModalHeader>
                        <CloseIcon
                            onClick={onClose}
                            icon="X"
                            hue="white"
                            size={4}
                        />
                        {header}
                    </ModalHeader>
                )}
                {body && <ModalBody>{body}</ModalBody>}
            </ModalContainer>
        </ModalOverlay>
    );
};

export const ModalOverlay = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: 2 })};
    ${({ theme }) => applyBg({ theme, hue: 'grey', shade: '1', alpha: 0.2 })};
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
`;

export const ModalContainer = styled.div`
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 90%;
    pointer-events: auto;
    position: relative;
    z-index: 10000;
    max-width: fit-content;
`;

export const ModalBackground = styled.div`
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
`;

export const ModalHeader = styled.div`
    position: relative;
    background-color: var(--white);
    ${mix.padding({ padding: [1.5] })};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--gray-900);
    border-bottom: 1px solid var(--gray-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ModalBody = styled.div`
    height: 100%;
    position: relative;
    overflow: auto;
    background-color: var(--white);
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    ${mix.padding({ padding: [2] })};
`;

const CloseIcon = styled(Icon)`
    position: absolute;
    right: ${({ theme }) => scale(theme, 2)}px;

    :hover {
        cursor: pointer;
    }
`;
