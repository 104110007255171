import {
    CommsSettings,
    GetCommsSettings,
    ClientDns,
    PracticePayload,
} from '$state/types/client';
import { api } from './api';

export const getPracticeSettings = (): Promise<PracticePayload> => {
    return api.get('/v3/clients/settings/practice').then((res) => res.data);
};

export const changePracticeSettings = (
    payload: Omit<PracticePayload, 'availableTypes'>,
): Promise<void> => {
    return api.put('/v3/clients/settings/practice', payload);
};

export const saveAsset = (name: string, file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('asset', file);
    return api.post(`/v3/clients/assets/${name}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export interface AssetList {
    [asset: string]: string; // URL of the asset
}

export const listAssets = (): Promise<AssetList> => {
    return api.get('/v3/clients/assets').then((res) => res.data.assets);
};

export const getCommsSettings = (): Promise<GetCommsSettings> => {
    return api
        .get('/v3/clients/settings/communications')
        .then((res) => res.data);
};

export const setCommsSettings = (payload: CommsSettings): Promise<void> => {
    return api.put('/v3/clients/settings/communications', payload);
};

export const getDns = (): Promise<ClientDns> => {
    return api.get('/v3/clients/settings/dns').then((res) => res.data);
};

export const verifyDns = (): Promise<void> => {
    return api.post('/v3/clients/settings/dns/verification');
};

export const postDnsInstructions = (payload: {
    to: string;
    body: string;
}): Promise<void> => {
    return api.post('/v3/clients/dns/instructions', payload);
};

export const postTestEmail = (payload: { to: string }): Promise<void> => {
    return api.post('/v3/clients/settings/communications/test-email', payload);
};

export const enableSMS = (payload: {
    practice_phone: string;
}): Promise<void> => {
    return api.post('/v3/clients/sms/enable', payload);
};

export const saveSignature = (payload: { html: string }): Promise<void> => {
    return api.put('/v3/clients/settings/signature', payload);
};

export const removeSignature = (): Promise<void> => {
    return api.delete('/v3/clients/settings/signature');
};
