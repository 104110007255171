import { Pipeline } from '$types';

/*
 * FIXME: Glue while migrating from leadflo-api
 *
 * When leadflo-api is retired move pipeline json to a better location and fix the Flow map and restructure the type
 *
 * {
 *   "flows": ...,
 *   "nodes": ...
 * }
 */

import pipeline from '../../../../leadflo-api/src/App/Pipelines/pipeline.json';
import lite from '../../../../leadflo-api/src/App/Pipelines/lite.json';
import pro from '../../../../leadflo-api/src/App/Pipelines/pro.json';
import test from '../../../../leadflo-api/src/App/Pipelines/test.json';

const dict = new Map<string, Pipeline>();
dict.set("pipeline", <any>pipeline);
dict.set("lite", <any>lite);
dict.set("pro", <any>pro);
dict.set("test", <any>test);

export default dict;
