import { LinkBoxModal, Linking } from '$ui/Dentally';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/state';
import { withState } from '$state/utils';
import * as patients from '$state/queries/patient';
import {
    getMatches,
    selectMatchData,
    selectMatchStatus
} from '$state/concerns/dentally/match';
import {
    getSuggestions,
    selectSuggestionData,
    selectSuggestionStatus
} from '$state/concerns/dentally/suggestion';
import { FinancialResponse } from '$api/dentally/financial';
import { lang, phone } from '$utils';
import * as dentally from '$state/concerns/dentally/patients';

interface DentallyPanelProps {
    id: string;
    onClose?: () => void;
    financial?: FinancialResponse;
}

interface Integrations {
    integration: string;
    dentally: DentallyPanelProps;
}

// panel manager
export const IntegrationPanel: React.FC<Integrations> = ({
    integration,
    dentally
}) => {
    switch (integration) {
        case 'dentally':
            return (
                <DentallyPanel id={dentally.id} onClose={dentally.onClose} />
            );
        default:
            return null;
    }
};

const DentallyPanel: React.FC<DentallyPanelProps> = ({ id, onClose }) => {
    const dispatch = useAppDispatch();

    const patient = useAppSelector(patients.get(id));

    const dentallyState = useAppSelector(withState(dentally.selectPatient, id));

    const suggestion = useAppSelector(withState(selectSuggestionData, id));
    const suggestionStatus = useAppSelector(
        withState(selectSuggestionStatus, id)
    );
    const suggestionLoading = suggestionStatus === 'loading';

    const matchStatus = useAppSelector(withState(selectMatchStatus, id));
    const match = useAppSelector(withState(selectMatchData, id));
    const matchLoading = matchStatus === 'loading';

    const loading = {
        link: dentally.loading(dentallyState),
        match: matchLoading === undefined ? true : matchLoading,
        suggestion: suggestionLoading
    };

    // to handle states when searching, linking and unlinking
    // not an actual state for the <Linking> component
    const [state, setState] = React.useState({
        state: 'suggest'
    });

    const [open, setOpen] = React.useState(true);
    const [dentallySearch, setDentallySearch] = React.useState('');

    const handleClear = () => {
        setDentallySearch('');
    };

    const handleClose = React.useCallback(() => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const handleView = (id: string) => {
        window.open(`https://app.dentally.co/patients/${id}/details`, '_blank');
    };

    // search handler, get matches and set state to searching
    const handleSearch = (query: string) => {
        setDentallySearch(query);
        dispatch(
            getMatches({
                patientId: id,
                params: {
                    query: query
                }
            })
        );
        setState({
            state: 'search'
        });
    };

    // Linking state
    const [linkState, setLinkState] = React.useState(false);

    const onLink = useCallback(
        (_id: string, dentallyId: string) => {
            setLinkState(true);
            handleClear();
            dispatch(
                dentally.link({
                    patientId: id,
                    dentallyId: Number(dentallyId)
                })
            );
        },
        [linkState]
    );

    React.useEffect(() => {
        if (dentally.linked(dentallyState)) {
            setLinkState(false);
            setState({
                state: 'linked'
            });
        }
    }, [dentally.linked(dentallyState)]);

    // Unlinking state
    const [unlinkState, setUnlinkState] = React.useState(false);

    const onUnlink = useCallback(
        (_id: string) => {
            handleClear();
            setUnlinkState(true);
            dispatch(dentally.unlink(id));
        },
        [unlinkState]
    );

    React.useEffect(() => {
        if (dentally.unlinked(dentallyState)) {
            setUnlinkState(false);
            setState({
                state: 'suggest'
            });
        }
    }, [dentally.unlinked(dentallyState)]);

    // get default query with name email and phone for suggest
    const getDefaultQuery = () => {
        if (patient.patient) {
            return (
                patient.patient.first_name +
                ' ' +
                patient.patient.last_name +
                ' ' +
                patient.patient.email +
                ' ' +
                phone.format(patient.patient.phone).replace(/\s/g, '')
            );
        }
        return '';
    };

    // get matches for suggest
    React.useEffect(() => {
        if (dentally.unlinked(dentallyState) && state.state === 'suggest') {
            dispatch(
                getSuggestions({
                    patientId: id,
                    params: {
                        query: getDefaultQuery(),
                        confidence: 0.05
                    }
                })
            );
            dispatch(
                getMatches({
                    patientId: id,
                    params: {
                        query: getDefaultQuery()
                    }
                })
            );
        }
    }, [state]);

    if (dentally.linking(dentallyState)) {
        return (
            <LinkBoxModal
                body={
                    <Linking
                        state="linking"
                        id={id}
                        query={dentallySearch}
                        onUnlink={(id) => onUnlink(id)}
                        onClear={() => handleClear()}
                        onView={(id) => handleView(id)}
                    />
                }
                onClose={() => handleClose()}
                open={open}
            />
        );
    }

    if (dentally.linked(dentallyState)) {
        return (
            <LinkBoxModal
                body={
                    <Linking
                        state="linked"
                        patient={dentally.details(dentallyState)}
                        id={id}
                        query={dentallySearch}
                        onUnlink={(id) => onUnlink(id)}
                        onClear={() => handleClear()}
                        onView={(id) => handleView(id)}
                    />
                }
                onClose={() => handleClose()}
                open={open}
            />
        );
    }

    // loading state
    if (
        loading.suggestion ||
        loading.link ||
        loading.match ||
        lang.isEmpty(matchStatus) ||
        lang.isEmpty(suggestionStatus)
    ) {
        return (
            <LinkBoxModal
                body={
                    <Linking
                        id={id}
                        state="loading"
                        query={dentallySearch}
                        onChange={(query) => handleSearch(query)}
                        onClear={() => handleClear()}
                    />
                }
                onClose={handleClose}
                open={open}
            />
        );
    }

    // not linked
    if (dentally.unlinked(dentallyState)) {
        // not linked and suggest
        if (state.state === 'suggest') {
            // not linked and 0 suggests
            if (lang.isEmpty(match) && lang.isEmpty(suggestion)) {
                return (
                    <LinkBoxModal
                        body={
                            <Linking
                                id={id}
                                state="error"
                                onChange={(query) => handleSearch(query)}
                                query={dentallySearch}
                                onClear={() => handleClear()}
                                onView={(id) => handleView(id)}
                            />
                        }
                        onClose={handleClose}
                        open={open}
                    />
                );
            }

            // not linked and valid suggests
            return (
                <LinkBoxModal
                    body={
                        <Linking
                            id={id}
                            patients={suggestion ?? []}
                            state="suggest"
                            query={dentallySearch}
                            onChange={(query) => handleSearch(query)}
                            onLink={(id, dentallyId) => onLink(id, dentallyId)}
                            onClear={() => handleClear()}
                            onView={(id) => handleView(id)}
                        />
                    }
                    onClose={handleClose}
                    open={open}
                />
            );
        }

        // search state
        if (state.state === 'search') {
            // reset when search is empty
            if (lang.isEmpty(dentallySearch)) {
                setState({
                    state: 'suggest'
                });
            }

            // if no match from search return error
            if (
                !lang.isEmpty(dentallySearch) &&
                (!match || lang.isEmpty(match))
            ) {
                return (
                    <LinkBoxModal
                        body={
                            <Linking
                                id={id}
                                state="error"
                                onChange={(query) => handleSearch(query)}
                                query={dentallySearch}
                                onClear={() => handleClear()}
                                onView={(id) => handleView(id)}
                            />
                        }
                        onClose={handleClose}
                        open={open}
                    />
                );
            }

            // if search and found
            return (
                <LinkBoxModal
                    body={
                        <Linking
                            id={id}
                            patients={match ?? []}
                            state="found"
                            query={dentallySearch}
                            onChange={(query) => handleSearch(query)}
                            onLink={(id, dentallyId) => onLink(id, dentallyId)}
                            onClear={() => handleClear()}
                            onView={(id) => handleView(id)}
                        />
                    }
                    onClose={handleClose}
                    open={open}
                />
            );
        }
    }

    return null;
};
