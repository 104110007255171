import * as React from 'react';
import { dates } from '$utils';
import styled, { css } from 'styled-components';
import { applyTextColor, mix } from '$ui/Flo/util';
import { Hue } from '$ui/Flo/types';
import { Tooltip } from '$ui/Flo/Tooltip';
import { useHover } from '@/utils/hooks';
import { Icon } from '$ui/Flo/Icon';

interface Props {
    status?: string;
    date: Date | string;
    disableIndicator?: boolean;
    inbound?: boolean;
}

export const Status = (props: Props) => {
    const {
        status = '',
        date,
        disableIndicator = false,
        inbound = false,
    } = props;

    const dateHoverRef = React.useRef<HTMLDivElement>(null);
    const [dateHovered, setDateHovered] = React.useState(false);

    const statusHoverRef = React.useRef<HTMLDivElement>(null);
    const [statusHovered, setStatusHovered] = React.useState(false);

    const statusLC = status.toLowerCase();

    useHover({
        ref: dateHoverRef,
        onEnter: () => setDateHovered(true),
        onLeave: () => setDateHovered(false),
    });

    useHover({
        ref: statusHoverRef,
        onEnter: () => setStatusHovered(true),
        onLeave: () => setStatusHovered(false),
    });

    const statusIcon = () => {
        if (inbound || disableIndicator) {
            return '';
        }
        if (statusLC === 'sent') {
            return <Icon icon={'Check'} color={'gray-400'}></Icon>;
        }
        if (statusLC === 'received' || statusLC === 'delivered') {
            return <Icon icon={'CheckDouble'} color={'gray-400'}></Icon>;
        }
        if (statusLC === 'read') {
            return <Icon icon={'CheckDouble'} color={'primary-500'}></Icon>;
        }
        if (statusLC === 'deleted') {
            return <Icon icon={'CheckDouble'} color={'error-500'}></Icon>;
        }
        return '';
    };

    return (
        <Container reverse={inbound} title="Status">
            <IconHolder ref={statusHoverRef}>
                {statusLC === 'deleted' && (
                    <Tooltip
                        open={statusHovered}
                        shade={'1'}
                        size="x-small"
                        align={inbound ? 'left' : 'right'}
                    >
                        The user has deleted the message
                    </Tooltip>
                )}
                {statusIcon()}
            </IconHolder>
            <Content hover={dateHovered} ref={dateHoverRef}>
                <Tooltip
                    open={dateHovered}
                    shade={'1'}
                    size="x-small"
                    align={inbound ? 'left' : 'right'}
                >
                    {dates.long(date)} {dates.time(date)}
                </Tooltip>
                {dates.time(date)}
            </Content>
        </Container>
    );
};

const Container = styled.div<{ reverse: boolean }>`
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'body' })};
    display: flex;
    align-items: center;
    gap: ${mix.unit({ size: 0.5 })};
    position: relative;

    ${({ reverse }) =>
        reverse &&
        css`
            flex-direction: row-reverse;
        `}
`;

interface IndicatorProps {
    hover?: boolean;
    hue?: Hue;
}

const Content = styled.div<IndicatorProps>`
    ${({ theme }) => {
        return applyTextColor({ theme, profile: 'body' });
    }};
    ${({ hover }) => {
        if (hover) {
            return css`
                text-decoration: underline;
            `;
        }
        return css`
            text-decoration: none;
        `;
    }};
`;

const IconHolder = styled.div`
    display: flex;
    position: relative;
`;
