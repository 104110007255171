import { ValueBar } from '$ui/Flo/ValueBar';
import React from 'react';

interface LoadingProps {
    loading: true;
}

interface LoadedFinancialBarProps {
    loading?: false;
    currency: string;
    paid: number;
    potential: number;
}

export type FinancialBarProps = LoadingProps | LoadedFinancialBarProps;

export const FinancialBar = (props: FinancialBarProps) => {
    if (props.loading) {
        return <ValueBar loading />;
    }

    const { currency, paid, potential } = props;

    return (
        <ValueBar
            values={[
                {
                    key: 'Paid',
                    value: paid,
                    hue: 'green',
                    shade: '5',
                    prefix: currency
                },
                {
                    key: 'Potential',
                    value: potential,
                    hue: 'primary',
                    shade: '5',
                    prefix: currency
                }
            ]}
        />
    );
};
