import * as React from 'react';
import Select, { SelectInstance } from 'react-select';

export interface Props {
    onSelect: (values: number) => void;
}

interface Option {
    label: string;
    value: string;
}

export const DropdownSelect = (props: Props) => {
    const { onSelect } = props;

    const ref = React.useRef<SelectInstance<Option, true>>(null);

    const onChange = (option: any) => {
        onSelect(parseInt(option.value));
    };

    const availableOptions = [
        { label: '10 mins', value: '10' },
        { label: '30 mins', value: '30' },
        { label: '3 hours', value: '180' },
        { label: '1 day', value: '1440' },
        { label: 'Select a time...', value: '0' }
    ];

    const customStyles = {
        menu: (styles: any) => ({
            ...styles,
            width: 'max-content',
            minWidth: '100%',
        }),
        option: (styles: any) => ({
            ...styles,
            cursor: 'pointer',
        }),
        container: (styles: any) => ({
            ...styles,
            zIndex: '600'
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: 'var(--primary-500)'
        }),
        singleValue: (styles: any) => ({
            ...styles,
            color: 'var(--primary-500)'
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            color: 'var(--primary-500)'
        }),
        control: (styles: any) => ({
            ...styles,
            borderColor: 'var(--primary-100)',
            cursor: 'pointer',
            '&:hover': {
                borderColor: 'var(--primary-100)',
            }
        })
    };

    return <Select
            ref={ref}
            placeholder="Remind me later"
            openMenuOnFocus
            options={availableOptions}
            onChange={onChange}
            components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
            }}
            styles={customStyles}
            menuPlacement="top"
            isSearchable={false}
        />;
};
