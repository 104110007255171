interface FormatArgs {
    value: number | string;
    currency?: string;
    fractionDigits?: number;
}

export const format = ({
    value,
    currency,
    fractionDigits
}: FormatArgs): string => {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    const options = {
        style: 'currency',
        currency: currency ?? 'GBP',
        minimumFractionDigits: fractionDigits ?? 2
    };
    return new Intl.NumberFormat('en-GB', options).format(value);
};
