import { Check } from '$ui/Flo/Check';
import React from 'react';
import { FormGrid } from './FormGrid';

const DEFAULT = 'General';

interface Props {
    available: string[];
    selected: string[];
    onChange: (types: string[]) => void;
}

export const Types = (props: Props) => {
    const selected = props.selected.reduce((map, type) => {
        map[type] = true;
        return map;
    }, {});

    const onClick = (type: string) => (state: boolean) => {
        selected[type] = state;
        props.onChange(
            Object.entries(selected)
                .filter(([_, val]) => val)
                .map(([key, _]) => key)
                .sort((a, b) => a.localeCompare(b)),
        );
    };

    // Places default option first
    const available = [...props.available];
    const disabled = available.splice(available.indexOf(DEFAULT), 1);
    available.unshift(...disabled);

    const boxes = available.map((type) => (
        <Check
            checked={selected[type]}
            disabled={type === DEFAULT}
            onChange={onClick(type)}
            key={type}
            label={type}
            theme="blue"
        />
    ));

    return <FormGrid column={2}>{boxes}</FormGrid>;
};
