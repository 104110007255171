import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { ChannelConfig } from '$state/types';
import { Icon } from '$ui/Flo/Icon';
import { Hue } from '$ui/Flo/types';
import { Tooltip } from '$ui/Flo/Tooltip';
import { useHover } from '@/utils/hooks';

interface Props {
    channel: ChannelConfig;
    onSelect: () => void;
    selected: boolean;
    disabled?: boolean;
    fullBar: boolean;
}

export const Tab = (props: Props) => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);

    const { onSelect, selected, channel, disabled = false, fullBar } = props;

    const onClick = () => {
        if (!disabled) {
            onSelect();
        }
    };

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false)
    });

    const tooltip = disabled && channel.disabledMessage;

    return (
        <Container
            ref={hoverRef}
            onClick={() => onClick()}
            $disabled={disabled}
            $selected={selected}
            role="tab"
            aria-label={`${channel.label} Tab`}
            title={`${channel.label} Tab`}
            data-qa={'tab-' + channel.channel}
        >
            {tooltip && (
                <Tooltip
                    align="center"
                    shade={'1'}
                    size="x-small"
                    open={hover}
                >
                    {tooltip}
                </Tooltip>
            )}

            <Icon
                size={2}
                icon={channel.icon}
                hue={getHue(selected)}
                opacity={getOpacity(disabled)}
                clickable={!disabled}
            />
            {(selected || fullBar) && props.channel.label}
        </Container>
    );
};

interface ContainerProps {
    $selected: boolean;
    $disabled: boolean;
}

const Container = styled.div<ContainerProps>`
    ${mix.padding({ padding: [0.5, 1] })};
    ${mix.type({ level: 'small' })};
    font-family: Roboto, serif;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    gap: ${mix.unit({ size: 1 })};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    color: ${({ $selected, $disabled }) => getColor($selected, $disabled)};

    ${({ $selected }) =>
        $selected &&
        css`
            border-bottom: 1px solid var(--primary-500);
        `}
`;

const getColor = (selected: boolean, disabled: boolean) => {
    if (disabled) {

        return 'var(--gray-400)';
    }

    if (selected) {

        return 'var(--primary-500)';
    }

    return 'var(--gray-600)';
};

const getOpacity = (disabled: boolean): number => (disabled ? 0.5 : 1);
//
const getHue = (selected: boolean): Hue => (selected ? 'primary' : 'type');
