import { createReducer } from '@reduxjs/toolkit';
import { Pipeline } from '$types';
import { initialized } from '$state/events';
import dict from '$state/flows/pipeline';

const initialState: Pipeline = {} as Pipeline;

export default createReducer<Pipeline>(initialState, (builder) => {
    builder.addCase(initialized, (_, action) => {
        return dict.get(action.payload.pipeline);
    });
});
