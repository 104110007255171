import React from 'react';
import { Frame } from '$ui/Integrations/Frame';
import { Failed, Manage } from '$ui/Integrations/Screens';
import Logo from '$resources/outlook.svg';
import { SyncState } from '$types/integrations';
import { manage, useWorkflow } from '$ui/Integrations/Workflows/workflows';

interface Props {
    auth: () => Promise<void>;
    sync: () => void;
    disconnect: () => void;
    onFinish: () => void;
    syncState: SyncState;
}

// TODO: Refactor this when introducing a new integration
export const OutlookManage = (props: Props) => {
    const { auth, sync, disconnect, onFinish, syncState } = props;

    const { matches, onSync, onSignIn } = useWorkflow({
        healthyState: 'healthy',
        workflow: manage,
        syncState,
        auth,
        sync
    });

    return (
        <Frame
            integration="Outlook"
            mode="Manage"
            next
            back={false}
            last
            onExit={onFinish}
            onNext={() => null}
            onFinish={onFinish}
            onBack={() => null}
        >
            {matches('healthy') && (
                <Manage
                    logo={Logo}
                    integration="Outlook"
                    onDisconnect={disconnect}
                    onSync={onSync}
                    syncing={matches('healthy.syncing')}
                />
            )}

            {matches('failed') && (
                <Failed
                    integration="Outlook"
                    mode="sync"
                    onSignIn={onSignIn}
                    signingIn={matches('failed.signingIn')}
                />
            )}
        </Frame>
    );
};
