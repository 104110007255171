import {
    Delay as DelaySchema,
    WorkflowDelay as WorkflowDelaySchema
} from '$state/types/automations';
import { mix } from '$ui/Flo/util';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '$ui/Flo/Text';

interface Props {
    first?: boolean;
    delay: DelaySchema | WorkflowDelaySchema;
}

export const Delay = (props: Props) => {
    const { first = false, delay } = props;
    return (
        <Container>
            <Line first={first} />
            <Circle />
            <Text level="body1" bold>
                Wait {delay.type === 'wait_until_delay' && 'until'}
            </Text>{' '}
            {delay.type === 'wait_until_delay' ? (
                <Workflows delay={delay as WorkflowDelaySchema} />
            ) : (
                <Unit delay={delay as DelaySchema} />
            )}
        </Container>
    );
};

export const Unit = ({ delay }: { delay: DelaySchema }) => {
    const unit =
        delay.duration == 1
            ? delay.unit.substring(0, delay.unit.length - 1)
            : delay.unit;
    return (
        <UnitBox>
            {delay.duration} {unit}
        </UnitBox>
    );
};

export const Workflows = ({ delay }: { delay: WorkflowDelaySchema }) => {
    const { workflows } = delay;
    const unitBoxes = workflows.map((workflow: string) => {
        return (
            <UnitBox key={workflow}>
                Patient is not in <span>{workflow}</span> automation
            </UnitBox>
        );
    });
    return <WorkflowsContainer>{unitBoxes}</WorkflowsContainer>;
};

const WorkflowsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${mix.unit({ size: 1 })};
`;

const UnitBox = styled.span`
    display: flex;
    gap: ${mix.unit({ size: 0.5 })};
    ${mix.padding({ padding: [0.5, 1.5] })}
    ${mix.bg({ hue: 'green', shade: '5' })}
    ${mix.round({ rounded: true })}
    ${mix.color({ profile: 'title', dark: true })}
    ${mix.type({ level: 'button' })}
}

${mix.margin({ margin: [0, 0, 0, 2] })}
> span {
    ${mix.type({ level: 'body2', bold: true })}
}

}
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: ${mix.unit({ size: 2.5 })};

    > div[profile='body'] {
        display: flex;
        flex-direction: row;
        flex: 0 0 ${mix.unit({ size: 14 })};
        justify-content: flex-end;
    }
`;

const Line = styled.span<{ first: boolean }>`
    width: 0;
    height: 100%;
    border-right: 1px solid ${mix.palette({ hue: 'grey', shade: '5' })};
    position: absolute;
    left: 0;
    transform: translateX(-50%);

    ${({ first }) =>
        first &&
        css`
            height: 50%;
            bottom: 0;
        `};
`;

const Circle = styled.span`
    width: 5px;
    height: 5px;
    border-radius: 999px;
    ${mix.bg({ hue: 'grey', shade: '5' })}
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
`;
