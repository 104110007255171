import { Button } from '$ui/Flo/Button';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import DentallySvg from '$resources/dentally.svg';
import { dates } from '$utils';
import { useAppSelector } from '$state/hooks';
import { withState } from '$state/utils';
import { NameBlock } from '$ui/Patient/NameBlock';
import * as dentally from '$state/concerns/dentally/patients';
import { Shadow } from '$ui/Flo/Shadow';

export interface LinkBoxProps {
    id: string;
    onView?: () => void;
    onUnlink?: () => void;
}

export const LinkBox = (props: LinkBoxProps) => {
    const { id } = props;

    const dentallyState = useAppSelector(withState(dentally.selectPatient, id));

    if (!dentally.linked(dentallyState)) {
        return (
            <Wrapper>
                <div
                    style={{
                        minHeight: '106px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <NameBlock
                        loading
                        state={{ name: 'James Bond' }}
                        dentallyState={dentallyState}
                    />
                </div>
                <PatientInfo>
                    <Field loading name="id" label="ID" />
                    <Field loading name="firstName" label="First Name" />
                    <Field loading name="lastName" label="Last Name" />
                    <Field loading name="emailAddress" label="Email Address" />
                    <Field loading name="phone" label="Phone" />
                    <Field loading name="dateOfBirth" label="Date of Birth" />
                </PatientInfo>
                <PatientAction>
                    <Buttons>
                        <Button
                            disabled
                            mode="outline"
                            hue="primary"
                            icon="ExternalLink"
                            rounded
                            align="center"
                            onClick={() => null}
                        >
                            View On Dentally
                        </Button>
                        <Button
                            disabled
                            mode="outline"
                            hue="red"
                            icon="UserX"
                            rounded
                            align="center"
                            onClick={() => null}
                        >
                            Unlink
                        </Button>
                    </Buttons>
                    <DentallyLogo />
                </PatientAction>
            </Wrapper>
        );
    }

    const patient = dentally.details(dentallyState);

    return (
        <Wrapper data-testid="dentally-patient-details">
            <div>
                <NameBlock
                    state={{ name: patient.firstName + ' ' + patient.lastName }}
                    dentallyState={dentallyState}
                />
            </div>
            <PatientInfo>
                <Field name="id" label="ID" value={String(patient.id)} />

                <Field
                    name="firstName"
                    label="First Name"
                    value={patient.firstName}
                />

                <Field
                    name="lastName"
                    label="Last Name"
                    value={patient.lastName}
                />

                <Field
                    name="emailAddress"
                    label="Email Address"
                    value={patient.emailAddress}
                />

                <Field name="phone" label="Phone" value={patient.phone} />

                <Field
                    name="dateOfBirth"
                    label="Date of Birth"
                    value={dates.toDate(patient.dateOfBirth)}
                />
            </PatientInfo>
            <PatientAction>
                <Buttons>
                    <Button
                        mode="outline"
                        hue="primary"
                        icon="ExternalLink"
                        rounded
                        align="center"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            props.onView?.();
                        }}
                    >
                        View On Dentally
                    </Button>
                    <Button
                        mode="outline"
                        hue="red"
                        icon="UserX"
                        rounded
                        align="center"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            props.onUnlink?.();
                        }}
                    >
                        Unlink
                    </Button>
                </Buttons>
                <DentallyLogo />
            </PatientAction>
        </Wrapper>
    );
};

interface LoadedFieldProps {
    loading?: false;
    name: string;
    label: string;
    value: string | null;
}

interface LoadingFieldProps {
    loading: true;
    name: string;
    label: string;
}

type FieldProps = LoadingFieldProps | LoadedFieldProps;

const Field = (props: FieldProps) => {
    const { name, label } = props;

    if (props.loading) {
        return (
            <InfoItem>
                <Label>{label}</Label>
                <Shadow rounded width={75} height={2.625} />
            </InfoItem>
        );
    }

    const { value } = props;

    if (value === null) {
        return null;
    }

    return (
        <InfoItem>
            <Label>{label}</Label>
            <Text data-testid={`value-${name}`}>{value}</Text>
        </InfoItem>
    );
};

const RowStyle = css`
    display: flex;
    flex-direction: row;
`;

const ColumnStyle = css`
    display: flex;
    flex-direction: column;
`;

const HorizontalLineStyle = css`
    ${mix.padding({ padding: [1, 0, 1] })}
    border: 1px solid ${mix.palette({
        hue: 'grey',
        shade: '9',
    })};
    border-bottom: none;
`;

const Wrapper = styled.div`
    ${mix.type({ level: 'body2' })}
    ${mix.color({ profile: 'body' })}
    ${ColumnStyle};
    width: 100%;
    height: 100%;
    position: relative;

    & > div:first-child {
        padding: 0;
    }

    & > div {
        ${mix.padding({ padding: [3] })}
    }

    & > div {
        border: 1px solid
            ${mix.palette({
                hue: 'grey',
                shade: '10',
            })};
        border-bottom: none;
    }

    & > div:last-child {
        border: 1px solid
            ${mix.palette({
                hue: 'grey',
                shade: '10',
            })};
    }
`;

const PatientInfo = styled.div`
    ${HorizontalLineStyle};
    ${ColumnStyle};
`;

const Buttons = styled.div`
    display: inline-flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const Text = styled.div`
    ${mix.type({ level: 'body2' })}
    width: 100%;
`;

const Label = styled.div`
    ${mix.type({ level: 'body2', bold: true })}
    width: 100%;
`;

const InfoItem = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    ${mix.margin({ margin: [1, 0] })}
`;

const PatientAction = styled.div`
    ${RowStyle};
    ${mix.gap({ size: 1 })}
    ${HorizontalLineStyle};
    justify-content: space-between;
    align-items: center;
    height: 100%;

    & > svg {
        height: auto;
    }
`;

const DentallyLogo = styled(DentallySvg)`
    ${mix.height({ size: 8 })}
    ${mix.fill({ hue: 'primary', shade: '6' })}
    align-self: flex-end;
`;
