import { TemplateContext } from '$state/types/contexts';
import { Template } from '$state/types/templates';
import React from 'react';
import Mustache from 'mustache';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { ChannelPayload } from '$state/types';
import { maybe } from '$utils';
import { Icon } from '$ui/Flo/Icon';

interface Props {
    template: Template;
    context?: TemplateContext;
    onInsert: (message: ChannelPayload) => void;
    onSend: (template: Template, message: string) => void;
    isStalledConversation: boolean;
}

export const Preview = (props: Props) => {
    const { context, template, isStalledConversation } = props;
    const { subject, body } = template;

    const render = (template: string): string => {
        if (!context) {
            return template;
        }

        return Mustache.render(template, context);
    };

    const insert = () => {
        switch (props.template.channel) {
            case 'note':
                return props.onInsert({
                    title: maybe(props.template.subject, (subject) =>
                        render(subject),
                    ),
                    content: render(props.template.body),
                });
            case 'email':
                return props.onInsert({
                    subject: maybe(props.template.subject, (subject) =>
                        render(subject),
                    ),
                    body: render(props.template.body),
                });
            case 'sms':
                return props.onInsert({
                    message: render(props.template.body),
                });
            case 'WhatsApp':
                return props.onInsert({
                    message: render(props.template.body),
                });
            default:
                throw new Error('Unknown template channel');
        }
    };

    const send = () => {
        props.onSend(template, render(template.body));
    };

    return (
        <Container>
            {subject && (
                <Subject>{context ? render(subject) : subject}</Subject>
            )}
            <Body>{context ? render(body) : body}</Body>
            <Button onClick={isStalledConversation ? send : insert}>
                <Icon
                    icon={isStalledConversation ? 'Send' : 'ExternalLink'}
                    size={1.5}
                    hue="white"
                    opacity={1}
                    clickable={true}
                />
                {isStalledConversation ? 'Send template' : 'Insert template'}
            </Button>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    height: 100%;
    ${mix.padding({ padding: [1.5, 2, 1.5, 2] })};
`;

const Subject = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    border-bottom: 1px solid var(--gray-100);
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    ${mix.padding({ padding: [0, 0, 1, 0] })};
`;

const Body = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    width: 100%;
    ${mix.padding({ padding: [1, 0, 1, 0] })};
    white-space: pre-line;
`;

const Button = styled.button`
    ${mix.padding({ padding: [1, 1.5, 1, 1.5] })};
    background-color: var(--primary-500);
    font-size: 12px;
    line-height: 16px;
    color: white;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    gap: ${mix.unit({ size: 1 })};
    display: flex;
    justify-content: center;
    align-items: center;
`;
