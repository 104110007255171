import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom';

export const NounFileMp4 = ({ color = 'currentColor', size = 16, onClick, className, style }: IconProps) => {
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 68 80"
        >
            <path d="M67.3 23.33L66.8 23.39L67.3 23.32C67.2372 22.8217 67.0123 22.3579 66.66 22L45.85 1.16C45.7351 1.04307 45.6076 0.939113 45.47 0.849998L45.37 0.789998C45.2632 0.727382 45.153 0.670631 45.04 0.619998L44.86 0.559999C44.7653 0.524056 44.6684 0.493998 44.57 0.469998C44.4086 0.43804 44.2445 0.4213 44.08 0.419998H10.68C10.0178 0.422631 9.38341 0.686869 8.91513 1.15514C8.44686 1.62341 8.18263 2.25777 8.17999 2.92V29.77C8.17999 30.433 8.44338 31.0689 8.91223 31.5378C9.38107 32.0066 10.017 32.27 10.68 32.27C11.343 32.27 11.9789 32.0066 12.4478 31.5378C12.9166 31.0689 13.18 30.433 13.18 29.77V5.42H41.58V23.56C41.58 24.2213 41.842 24.8557 42.3087 25.3242C42.7754 25.7928 43.4087 26.0574 44.07 26.06L62.33 26.16V74.58H13.18V70.7C13.18 70.037 12.9166 69.4011 12.4478 68.9322C11.9789 68.4634 11.343 68.2 10.68 68.2C10.017 68.2 9.38107 68.4634 8.91223 68.9322C8.44338 69.4011 8.17999 70.037 8.17999 70.7V77.08C8.18263 77.7422 8.44686 78.3766 8.91513 78.8449C9.38341 79.3131 10.0178 79.5774 10.68 79.58H64.83C65.4922 79.5774 66.1266 79.3131 66.5948 78.8449C67.0631 78.3766 67.3274 77.7422 67.33 77.08V23.68C67.3299 23.5627 67.3199 23.4456 67.3 23.33ZM46.58 21.08V9L58.76 21.14L46.58 21.08Z" />
            <path d="M31.54 45.89H29.43V50.59H31.54C32.84 50.59 33.47 49.78 33.47 48.14C33.5322 47.522 33.3649 46.9026 33 46.4C32.8075 46.2152 32.5774 46.0741 32.3255 45.9861C32.0736 45.8981 31.8057 45.8653 31.54 45.89Z"/>
            <path d="M53.66 35.27H3.17001C2.50778 35.2726 1.87343 35.5369 1.40516 36.0051C0.936884 36.4734 0.672647 37.1078 0.670013 37.77V62.7C0.670013 63.363 0.933406 63.9989 1.40225 64.4678C1.87109 64.9366 2.50697 65.2 3.17001 65.2H53.66C54.3231 65.2 54.9589 64.9366 55.4278 64.4678C55.8966 63.9989 56.16 63.363 56.16 62.7V37.77C56.1574 37.1078 55.8931 36.4734 55.4249 36.0051C54.9566 35.5369 54.3222 35.2726 53.66 35.27ZM23.57 57.41H20.39V46.64L17.67 56.8H14.57V56.67L11.88 46.67V57.41H8.67001V43.12H13.8L16.12 52.72L18.44 43.12H23.57V57.41ZM35.38 52C34.8693 52.4741 34.2668 52.8384 33.6098 53.0706C32.9528 53.3028 32.2552 53.3979 31.56 53.35H29.43V57.41H26.26V43.12H31.56C34.96 43.12 36.69 44.81 36.69 48.12C36.7371 48.8211 36.6457 49.5246 36.421 50.1903C36.1962 50.856 35.8425 51.471 35.38 52ZM47.84 55.18H46.55V57.41H43.37V55.18H37.63V52.79L41 43.53H44.5L40.9 52.37H43.37V48.58H46.55V52.37H47.84V55.18Z"/>
        </svg>
    );
};
