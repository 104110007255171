import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Icon, IconName } from '$ui/Flo/Icon';
import { useHover } from '@/utils/hooks';
import { Tooltip } from '$ui/Flo/Tooltip';

export interface AvatarCommTypeProps {
    inbound: boolean;
    type?: 'Email' | 'SMS' | 'WhatsApp';
}

export const CommType = ({ type, inbound }: AvatarCommTypeProps) => {
    const hoverRef = React.useRef<HTMLDivElement>(null);
    const [hover, setHover] = React.useState(false);

    useHover({
        ref: hoverRef,
        onEnter: () => setHover(true),
        onLeave: () => setHover(false),
    });

    if (type !== undefined) {
        return (
            <IconHolder ref={hoverRef}>
                <Tooltip
                    align={inbound ? 'left' : 'right'}
                    shade={'1'}
                    size="x-small"
                    open={hover}
                >
                    via {type}
                </Tooltip>
                <Icon icon={SelectIcon(type)} opacity={1} size={3}></Icon>
            </IconHolder>
        );
    }

    return <></>;
};

const SelectIcon = (type: string): IconName => {
    switch (type) {
        case 'WhatsApp':
            return 'CommTypeWhatsApp';
        case 'SMS':
            return 'CommTypeSMS';
        default:
            return 'CommTypeEmail';
    }
};

const IconHolder = styled.div`
    position: absolute;
    right: -${mix.unit({ size: 0.25 })};
    bottom: -${mix.unit({ size: 1.5 })};
    z-index: 12;
`;
