import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    mode: 'add' | 'edit';
    active?: boolean;
    onClick: () => void;
}

export const MenuButton = (props: Props) => {
    const Container = props.active ? ActiveContainer : DefaultContainer;
    return (
        <Container onClick={props.onClick}>
            <ModeIcon {...props} />
        </Container>
    );
};

const ModeIcon = ({ mode, active }: Props) => {
    if (active) {
        return (
            <Icon
                clickable
                icon="X"
                size={2}
                hue="white"
            />
        );
    }

    if (mode === 'add') {
        return (
            <Icon
                clickable
                icon="Plus"
                size={2}
                hue="primary"
            />
        );
    }

    return (
        <Icon
            clickable
            icon="Edit"
            size={2}
            hue="primary"
        />
    );
};

const DefaultContainer = styled.div`
    background: transparent;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color, 0.25s;

    &:hover {
        cursor: pointer;
    }
`;

const ActiveContainer = styled(DefaultContainer)`
    ${mix.bg({ hue: 'primary', shade: '2' })};

    &:hover {
        ${mix.bg({ hue: 'primary', shade: '1' })};
    }
`;
