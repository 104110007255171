import { RootState } from '$state/store';
import { createSelector } from '@reduxjs/toolkit';
import { FetchStatus, RefreshStatus, Timeline } from '$state/concerns/timeline';
import {
    EventUnion as EventUnionV2,
    CommType as CommTypeV2,
    CommEventUnion as CommEventUnionV2,
} from '$types/timeline-v2';
import { Channels, ReplyState } from '$state/types';
import { CommType, EventUnion, CommEventUnion } from '$types';
import { subDays, isAfter } from 'date-fns';

const timelineState = (state: RootState) => {
    return state.timeline;
};

export const selectTimeline = createSelector(
    [timelineState, (_, patientId: string) => patientId],
    (state, patientId): Timeline => {
        return (
            state.timelines[patientId] || {
                events: [],
                metadata: { status: FetchStatus.LOADING },
            }
        );
    },
);

export const selectTimelineRefreshing = createSelector(
    [selectTimeline],
    (timeline: Timeline) => {
        return timeline.metadata.status === RefreshStatus.REFRESHING;
    },
);

export const selectReplyBar =
    (patientID: string, enabledChannels: Channels[]) =>
    (state: RootState): ReplyState => {
        const patientChannel = timelineState(state).replies[patientID];

        if (
            patientChannel &&
            enabledChannels.includes(patientChannel.channel)
        ) {
            return patientChannel;
        }

        return {
            channel: Channels.Note,
            fields: {},
        };
    };

/**
 * Check if in previous 24 hours we have a messages on specific communication type.
 * @param patientId
 * @param commType
 */
export const isOnGoingCommunication =
    (patientId: string, commType: (CommType | CommTypeV2)[]) =>
    (state: RootState): boolean => {
        const timeline = timelineState(state).timelines[patientId];

        if (timeline && timeline.events.length > 0) {
            return (
                timeline.events.filter((event: EventUnion | EventUnionV2) =>
                    isOnGoingEvent(event, commType),
                ).length > 0
            );
        }

        return false;
    };

/**
 * Validate that event is appropriate comm type and it is not passed more than
 * 24 hours of receiving it.
 * @param event
 * @param commType
 */
const isOnGoingEvent = (
    event: EventUnion | EventUnionV2,
    commType: (CommType | CommTypeV2)[],
): boolean => {
    if (event.type !== 'communication') {
        return false;
    }

    if (
        commType.includes((event as CommEventUnion).comm_type) &&
        (event as CommEventUnion).inbound
    ) {
        const previousDay = subDays(new Date(), 1);
        const eventDate = new Date(event.datetime);
        return isAfter(eventDate, previousDay);
    }

    if (
        commType.includes((event as CommEventUnionV2).comm_type) &&
        (event as CommEventUnionV2).inbound
    ) {
        const previousDay = subDays(new Date(), 1);
        const eventDate = new Date(event.datetime);
        return isAfter(eventDate, previousDay);
    }

    return false;
};
