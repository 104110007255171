import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

export const StatusBar = ({ status, reason }: { status: string, reason: string | null }) => {

    const messageHandler = () => {
        switch (status) {
            case 'pending':
                return (<>
                    Templates must be approved by WhatsApp before they can be used to start/restart a conversation.
                    <br /> You'll always be able to use this template during an active conversation even if it is not
                    approved
                </>);
            case 'approved':
                return (<>
                    Templates must be approved by WhatsApp before they can be used to start/restart a conversation.
                    <br /> You'll always be able to use this template during an active conversation even if it is not
                    approved
                </>);
            case 'rejected':
                return `Response from WhatsApp: "${reason}"`;
            default:
                return 'This template has been rejected by WhatsApp. You can edit and resubmit it for approval';
        }
    };

    return (
        <StatusBarContainer status={status}>
            <StatusTitle>
                {status}
            </StatusTitle>
            {status !== 'not submitted' && (
                <Message>
                    {messageHandler()}
                </Message>
            )}
        </StatusBarContainer>
    );
};

const StatusBarContainer = styled.div<{ status: string }>`
    ${mix.padding({ padding: [2] })};
    border-radius: ${mix.unit({ size: 1 })};
    display: flex;
    flex-direction: column;
    row-gap: ${mix.unit({ size: 1 })};
    background-color: ${(props) => {
        switch (props.status) {
            case 'pending':
                return 'var(--warning-050)';
            case 'approved':
                return 'var(--success-050)';
            case 'rejected':
                return 'var(--error-050)';
            default:
                return 'var(--gray-100)';
        }
    }
    };
`;

const StatusTitle = styled.div`
    color: var(--gray-600);
    font-weight: bold;

    &::first-letter {
        text-transform: uppercase;
    }
`;

const Message = styled.div`
    color: var(--gray-600);
    font-size: 14px;
`;
