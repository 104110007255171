import { initialized } from '$state/events';
import { Client } from '$state/types';
import { CountryCode } from '@/utils/phone';
import {
    CommsSettings,
    PracticePayload,
    SettingsMap,
    SettingStatus,
} from '$state/types/client';
import {
    AsyncThunk,
    createAction,
    createAsyncThunk,
    createReducer,
    createSelector,
} from '@reduxjs/toolkit';
import * as api from '$api/client';
import { RootState } from '..';

export const getSettings = createAsyncThunk<unknown, 'assets' | 'practice'>(
    'clients/settings/get',
    (arg) => {
        switch (arg) {
            case 'practice':
                return api.getPracticeSettings();
            case 'assets':
                return api.listAssets();
        }
    },
) as AsyncThunk<PracticePayload, 'practice', Record<string, unknown>> &
    AsyncThunk<api.AssetList, 'assets', Record<string, unknown>>;

export const changeSettings = createAsyncThunk<
    void,
    {
        section: 'practice';
        payload: Omit<PracticePayload, 'availableTypes'>;
    }
>('clients/settings/change', async (arg) => {
    switch (arg.section) {
        case 'practice':
            await api.changePracticeSettings(arg.payload);
    }
});

export enum ClientStatus {
    IDLE = 'idle',
    LOADED = 'loaded',
}

export const removeLabel = createAction(
    'clients/label/remove',
    (label: string) => ({
        payload: label,
    }),
);

export const addLabel = createAction('clients/label/add', (label: string) => ({
    payload: label,
}));

export const addRecentLabel = createAction(
    'clients/label/recent/add',
    (label: string) => ({
        payload: label,
    }),
);

export const getCommsSettings = createAsyncThunk(
    'clients/settings/communications/get',
    () => api.getCommsSettings(),
);

export const setCommsSettings = createAsyncThunk(
    'clients/settings/communications/set',
    async (payload: CommsSettings) => {
        await api.setCommsSettings(payload);
    },
);

export const getDns = createAsyncThunk('clients/settings/dns/get', () =>
    api.getDns(),
);

export const verifyDns = createAsyncThunk(
    'clients/settings/dns/verify',
    (_, ThunkAPI) => api.verifyDns().then(() => ThunkAPI.dispatch(getDns())),
);

export const postDnsInstructions = createAsyncThunk(
    'clients/settings/dns/instructions',
    async (payload: { to: string; body: string }) =>
        await api.postDnsInstructions(payload),
);

export const postTestEmail = createAsyncThunk(
    'clients/settings/test/email',
    async (payload: { to: string }) => await api.postTestEmail(payload),
);

export const resetTestEmail = createAction('clients/settings/test/email/reset');

export const enableSMS = createAsyncThunk(
    'clients/settings/communications/sms/enable',
    async (payload: { practice_phone: string }) => await api.enableSMS(payload),
);

export const updateSignature = createAction(
    'clients/settings/signature/update',
    (html: string) => ({
        payload: html,
    }),
);

export const disableSignature = createAction(
    'clients/settings/signature/disable',
);

export interface ClientState {
    status: ClientStatus;
    // Deprecated, don't reference this client anymore. It's basically
    // a serialised backend client. Use the client settings which
    // instead come from a query
    client?: Client;
    labels?: string[];
    labels_history?: string[];
    tx_types?: string[];
    settings: SettingsMap;
}

export interface ClientLoadedState extends ClientState {
    status: ClientStatus.LOADED;
    client: Client;
    labels: string[];
    labels_history: string[];
    sources: (string | 'Unknown')[];
    tx_types: string[];
    settings: SettingsMap;
}

const initialState: ClientState = {
    status: ClientStatus.IDLE,

    settings: {
        practice: {
            status: SettingStatus.IDLE,
        },
        assets: {
            status: SettingStatus.IDLE,
        },
        communications: {
            status: SettingStatus.IDLE,
        },
        dns: {
            status: SettingStatus.IDLE,
        },
        testEmail: {
            status: SettingStatus.IDLE,
        },
    },
};

export default createReducer<ClientState>(initialState, (builder) => {
    builder
        .addCase(initialized, (state, action) => {
            state.status = ClientStatus.LOADED;
            state.client = action.payload.client;
            state.client.settings = action.payload.settings;
            state.labels = action.payload.labels;
            state.labels_history = action.payload.labels_history;
            state.tx_types = action.payload.tx_types;
        })
        .addCase(getSettings.pending, (state, action) => {
            state.settings[action.meta.arg].status = SettingStatus.LOADING;
        })
        .addCase(getSettings.fulfilled, (state, action) => {
            const existing = state.settings[action.meta.arg];

            state.settings[action.meta.arg] = {
                ...existing,
                ...action.payload,
                status: SettingStatus.LOADED,
            };
        })
        .addCase(getSettings.rejected, (state, action) => {
            state.settings[action.meta.arg].status = SettingStatus.ERROR;
        })
        .addCase(changeSettings.pending, (state, action) => {
            state.settings[action.meta.arg.section].status =
                SettingStatus.SAVING;
        })
        .addCase(changeSettings.fulfilled, (state, action) => {
            state.settings[action.meta.arg.section].status =
                SettingStatus.SAVED;
        })
        .addCase(changeSettings.rejected, (state, action) => {
            state.settings[action.meta.arg.section].status =
                SettingStatus.ERROR_SAVING;
        })
        .addCase(removeLabel, (state, action) => {
            state.labels = state.labels?.filter((l) => l !== action.payload);
        })
        .addCase(addLabel, (state, action) => {
            const label = action.payload;

            if (!state.labels) {
                state.labels = [label];
                return;
            }

            if (
                state.labels.some(
                    (l) => l.toLowerCase() === label.toLowerCase(),
                )
            ) {
                return;
            }

            state.labels = [...state.labels, label];
        })
        .addCase(addRecentLabel, (state, action) => {
            const label = action.payload;

            if (!state.labels_history) {
                state.labels_history = [label];
                return;
            }

            const currentLabels = state.labels_history.filter(
                (l) => l !== label,
            );

            state.labels_history = [label, ...currentLabels];
        })
        .addCase(getCommsSettings.pending, (state) => {
            state.settings.communications.status = SettingStatus.LOADING;
        })
        .addCase(getCommsSettings.rejected, (state) => {
            state.settings.communications.status = SettingStatus.ERROR;
        })
        .addCase(getCommsSettings.fulfilled, (state, action) => {
            state.settings.communications = {
                status: SettingStatus.LOADED,
                ...action.payload,
            };
        })
        .addCase(setCommsSettings.pending, (state, action) => {
            state.settings.communications.status = SettingStatus.SAVING;
            const { business_hours, sms_autoresponses } = action.meta.arg;
            state.settings.communications = {
                ...state.settings.communications,
                business_hours,
                sms_autoresponses,
            };
        })
        .addCase(setCommsSettings.rejected, (state) => {
            state.settings.communications.status = SettingStatus.ERROR_SAVING;
        })
        .addCase(setCommsSettings.fulfilled, (state) => {
            state.settings.communications.status = SettingStatus.SAVED;
        })
        .addCase(getDns.pending, (state) => {
            state.settings.dns.status = SettingStatus.LOADING;
        })
        .addCase(getDns.rejected, (state) => {
            state.settings.dns.status = SettingStatus.ERROR;
        })
        .addCase(getDns.fulfilled, (state, action) => {
            state.settings.dns = {
                status: SettingStatus.LOADED,
                ...action.payload,
            };
        })
        .addCase(verifyDns.pending, (state) => {
            state.settings.dns.status = SettingStatus.LOADING;
        })
        .addCase(postTestEmail.pending, (state) => {
            state.settings.testEmail.status = SettingStatus.LOADING;
        })
        .addCase(postTestEmail.rejected, (state) => {
            state.settings.testEmail.status = SettingStatus.ERROR;
        })
        .addCase(postTestEmail.fulfilled, (state) => {
            state.settings.testEmail.status = SettingStatus.LOADED;
        })
        .addCase(resetTestEmail, (state) => {
            state.settings.testEmail.status = SettingStatus.IDLE;
        })
        .addCase(enableSMS.pending, (state) => {
            state.settings.communications.status = SettingStatus.SAVING;
        })
        .addCase(enableSMS.rejected, (state) => {
            state.settings.communications.status = SettingStatus.ERROR_SAVING;
        })
        .addCase(enableSMS.fulfilled, (state) => {
            state.settings.communications.status = SettingStatus.LOADED;
        })
        .addCase(updateSignature, (state, action) => {
            state.settings.practice.signature = {
                enabled: true,
                html: action.payload,
            };
        })
        .addCase(disableSignature, (state) => {
            state.settings.practice.signature = {
                enabled: false,
                html: '',
            };
        });
});

export const selectSettings = (state: RootState) => {
    return state.client.settings;
};

export const assetUrl =
    (name: string) =>
    (state: RootState): string | null => {
        return state.client.settings?.assets[name] ?? null;
    };

export const selectRepName = (state: RootState): string | null => {
    return state.client.settings?.practice?.representative ?? null;
};

export const selectClientCountry = createSelector(
    (state: RootState) => state.client.client?.country,
    (country) => {
        return country ?? CountryCode.GB;
    },
);

export const getTxTypes = createSelector(
    (state: RootState) => state.client.tx_types,
    (types) => {
        return types ?? [];
    },
);

export const getAccountLabels = createSelector(
    (state: RootState) => state.client.labels,
    (labels) => {
        return labels ?? [];
    },
);

export const getRecentlyUsedLabels = createSelector(
    (state: RootState) => state.client.labels_history,
    (labels) => {
        return labels ?? [];
    },
);

export const selectClient = ({ client }: RootState) => client.client;
