import {
    APIWorkflow,
    AutomationsState,
    AutomationsStatus,
    CommTemplate,
    EmailTemplate,
    LoadedAction,
    LoadedEmail,
    LoadedSMS,
    LoadedWorkflow,
    SMSTemplate,
    TemplateStatus,
    UnloadedAction
} from '$state/types/automations';
import {
    createAction,
    createAsyncThunk,
    createReducer
} from '@reduxjs/toolkit';
import * as api from '$api';
import { Action } from '../types/automations';
import { Draft } from 'immer';

export const loadAutomations = createAsyncThunk('automations/load', () => {
    return api.loadAutomations();
});

export const loadWorkflow = createAsyncThunk<APIWorkflow, string>(
    'automations/load-workflow',
    async (id) => {
        return await api.loadWorkflow(id);
    }
);

export const loadAction = createAsyncThunk<
    LoadedAction,
    {
        action: string;
    }
>('automations/load-action', (args) => {
    return api.loadAction(args.action);
});

export const enableWorkflow = createAsyncThunk<
    void,
    { id: string; enabled: boolean }
>('automations/enable', async (args) => {
    await api.enableAutomation(args.id, args.enabled);
});

export const enableAction = createAsyncThunk<
    void,
    { id: string; enabled: boolean }
>('automations/enable-action', async (args) => {
    await api.enableAction(args.id, args.enabled);
});

export const openTemplate = createAction<{
    action: Action | null;
    open: boolean;
}>('automations/template/open');

export const editTemplate = createAction<{
    templateId: string;
}>('automations/template/edit');

export const updateEmailMetadata = createAction<{
    actionId: string;
    templateId: string;
    subject?: string;
    preview?: string;
}>('automations/updateEmailMetadata');

export const saveTemplate = createAsyncThunk<
    void,
    {
        action: string;
        templateId: string;
        template: CommTemplate;
    }
>('automation/template/save', async (args, thunk) => {
    await api
        .saveTemplate(args.action, args.templateId, args.template)
        .then(() => {
            thunk.dispatch(loadAction({ action: args.action }));
        });
});

const initialState: AutomationsState = {
    status: AutomationsStatus.IDLE,
    workflows: {},
    nodes: {},
    editor: {
        open: false,
        action: null,
        editingStatus: TemplateStatus.IDLE,
        template: null
    }
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(loadAutomations.pending, (state) => {
            state.status = AutomationsStatus.LOADING;
        })
        .addCase(loadAutomations.fulfilled, (state, action) => {
            state.status = AutomationsStatus.LOADED;
            state.workflows = Object.assign(
                {},
                ...action.payload.map((workflow) => ({
                    [workflow.id]: workflow
                }))
            );
        })
        .addCase(loadAutomations.rejected, (state) => {
            state.status = AutomationsStatus.ERROR;
        })
        .addCase(loadWorkflow.fulfilled, (state, action) => {
            const workflow = action.payload as unknown as LoadedWorkflow;
            const nodes = Object.assign(
                {},
                ...action.payload.nodes.map((node) => ({
                    [node.id]: node
                }))
            );
            workflow.nodes = action.payload.nodes.map((node) => node.id);
            workflow.loaded = true;

            state.workflows[workflow.id] = workflow;
            state.nodes = { ...state.nodes, ...nodes };
        })
        .addCase(enableWorkflow.pending, (state, action) => {
            state.workflows[action.meta.arg.id].enabled =
                action.meta.arg.enabled;
        })
        .addCase(enableWorkflow.rejected, (state, action) => {
            state.workflows[action.meta.arg.id].enabled =
                !action.meta.arg.enabled;
        })

        .addCase(enableAction.pending, (state, action) => {
            const node = state.nodes[action.meta.arg.id];
            if (node?.type !== 'action') {
                return;
            }

            node.enabled = action.meta.arg.enabled;
        })
        .addCase(enableAction.rejected, (state, action) => {
            const node = state.nodes[action.meta.arg.id];
            if (node?.type !== 'action') {
                return;
            }

            node.enabled = !action.meta.arg.enabled;
        })
        .addCase(openTemplate, (state, action) => {
            if (action.payload.open) {
                state.editor.open = true;
                state.editor.action = action.payload.action;
                return;
            }
            state.editor.open = false;
            state.editor.action = null;
            state.editor.editingStatus = TemplateStatus.IDLE;
            state.editor.template = null;
        })
        .addCase(loadAction.fulfilled, (state, action) => {
            state.nodes[action.payload.id] = action.payload;

            if (state.editor.action?.id === action.payload.id) {
                state.editor.action = action.payload;
            }
        })
        .addCase(editTemplate, (state, action) => {
            if (state.editor.editingStatus != TemplateStatus.IDLE) {
                throw new Error(
                    `Cannot edit template while in state: ${state.editor.editingStatus}`
                );
            }

            state.editor.editingStatus = TemplateStatus.EDITING;
            state.editor.template = action.payload.templateId;
        })
        .addCase(updateEmailMetadata, (state, action) => {
            const { actionId, templateId, subject, preview } = action.payload;

            const node = state.nodes[actionId];
            if (node.type !== 'action') {
                return;
            }

            if (node.action !== 'email') {
                return;
            }

            updateAction(state, actionId, (node) => {
                node = node as LoadedEmail;

                const template = node.templates.find(
                    (template) => template.id == templateId
                );

                if (!template) {
                    return node;
                }

                template.template.subject =
                    subject ?? template.template.subject;
                template.template.preview =
                    preview ?? template.template.preview;

                return node;
            });
        })
        .addCase(saveTemplate.pending, (state, action) => {
            state.editor.editingStatus = TemplateStatus.SAVING;
            const node = state.nodes[action.meta.arg.action];
            if (node.type != 'action' || node.loaded == false) {
                return;
            }

            if (node.action == 'email') {
                const next = action.meta.arg.template as EmailTemplate;

                updateAction(state, action.meta.arg.action, (node) => {
                    node = node as LoadedEmail;
                    const template = node.templates.find(
                        (template) => template.id == action.meta.arg.templateId
                    );
                    template!.template.definition = next.definition;
                    template!.template.html = next.html;
                    return node;
                });
            }

            if (node.action == 'sms') {
                const next = action.meta.arg.template as SMSTemplate;

                updateAction(state, action.meta.arg.action, (node) => {
                    node = node as LoadedSMS;
                    const template = node.templates.find(
                        (template) => template.id == action.meta.arg.templateId
                    );
                    template!.template.template = next.template;
                    return node;
                });
            }
        })
        .addCase(saveTemplate.fulfilled, (state) => {
            state.editor.editingStatus = TemplateStatus.IDLE;
            state.editor.template = null;
        })
        .addCase(saveTemplate.rejected, (state) => {
            state.editor.editingStatus = TemplateStatus.ERROR;
        });
});

// Allows updating an action
// and also updating the action in the editor.
const updateAction = (
    state: Draft<AutomationsState>,
    id: string,
    cb: (action: LoadedAction) => LoadedAction
) => {
    const action = state.nodes[id];
    if (!action || action.type != 'action' || !action.loaded) {
        return;
    }

    state.nodes[id] = cb(action);

    if (state.editor.action?.id != id) {
        return;
    }

    state.editor.action = state.nodes[id] as UnloadedAction | LoadedAction;
};
