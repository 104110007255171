import { Icon } from '$ui/Flo/Icon';
import { Button } from '$ui/Flo/Button';
import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
    onClose: () => void;
    onSave: () => void;
    approved: boolean;
}

export const Approval = (props: Props) => {
    const form = useFormContext();
    const { onClose, onSave, approved } = props;
    const [isApproved, setIsApproved] = React.useState<boolean>(approved);

    return (
        <div>
            <Content>
                <TitleModal>Send for approval</TitleModal>
                <MessageModal>
                    Do you want to send this template for approval, so that it
                    can be used to start or restart conversations?
                </MessageModal>

                <Controller
                    control={form.control}
                    name="approval"
                    defaultValue={approved}
                    render={({ field }) => {
                        return (
                            <SwitcherWrapper>
                                <SwitcherButton
                                    active={isApproved}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsApproved(true);
                                        form.setValue(field.name, true);
                                    }}
                                >
                                    Yes
                                </SwitcherButton>

                                <SwitcherButton
                                    active={!isApproved}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsApproved(false);
                                        form.setValue(field.name, false);
                                    }}
                                >
                                    No
                                </SwitcherButton>
                            </SwitcherWrapper>
                        );
                    }}
                />

                {isApproved && (
                    <>
                        <CheckContainer>
                            <CheckIcon
                                icon={'CheckCircle'}
                                color={'gray-600'}
                                opacity={1}
                            />
                            <div>
                                You will not be able to use this template to
                                start a conversation until it is approved
                            </div>
                        </CheckContainer>
                        <CheckContainer>
                            <CheckIcon
                                icon={'CheckCircle'}
                                color={'gray-600'}
                                opacity={1}
                            />
                            <div>
                                You can always use this template within an
                                active conversation at any time
                            </div>
                        </CheckContainer>
                    </>
                )}
                {!isApproved && (
                    <CheckContainer>
                        <CheckIcon
                            icon={'CheckCircle'}
                            color={'gray-600'}
                            opacity={1}
                        />
                        <div>
                            You can only use this template within active
                            conversations
                        </div>
                    </CheckContainer>
                )}
            </Content>
            <ActionBar>
                <ButtonGroup>
                    <Button rounded mode={'outline'} onClick={onClose}>
                        <CustomButton>Continue Editing</CustomButton>
                    </Button>
                    <Button
                        rounded
                        hue={'primary'}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        <CustomButton color={'white'}>
                            {isApproved ? 'Send for approval' : 'Save template'}
                        </CustomButton>
                    </Button>
                </ButtonGroup>
            </ActionBar>
        </div>
    );
};

const ActionBar = styled.div`
    border-top: 1px solid var(--gray-200);
`;

const TitleModal = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-600);
`;

const MessageModal = styled.div`
    font-size: 16px;
    color: var(--gray-600);
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1.5 })};
    justify-content: space-between;
    ${mix.padding({ padding: [1.5, 3] })};
`;

const Content = styled.div`
    ${mix.padding({ padding: [1.5] })};
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 2 })};
    ${mix.padding({ padding: [3] })};
`;

const CustomButton = styled.div<{ color?: string }>`
    width: 140px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? props.color : 'var(--gray-500)')};
`;

const CheckContainer = styled.div`
    display: flex;
    gap: ${mix.unit({ size: 1 })};
`;

const CheckIcon = styled(Icon)`
    margin-top: ${mix.unit({ size: 0.5 })};
`;

const SwitcherWrapper = styled.div`
    background: #eaecf0;
    padding: 2px;
    border-radius: 6px;
    display: flex;
    gap: 2px;
`;

const SwitcherButton = styled.button<{ active?: boolean }>`
    background: none;
    color: #98a2b3;
    ${mix.padding({ padding: 1 })};
    border: none;
    border-radius: 4px;
    transition: background-color 0.5s ease;
    cursor: pointer;
    flex: 1;
    ${(props) =>
        props.active &&
        `
         background: #FFFFFF;
         color: #475467;
    `}
`;
