import { useMachine } from '@xstate/react';
import { Integration, SyncState } from '$types/integrations';
import React from 'react';
import { connect } from '$api/integrations';
import { assign, createMachine } from 'xstate';
import { AsyncStatus } from '$types';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '$state';
import {
    disconnect,
    heal,
    load,
    selectIntegration,
    selectStatus,
    selectSyncState,
    sync,
} from '$state/concerns/integrations';
import { v4 as uuid } from 'uuid';
import { selectUIBaseURL } from '$state/queries/config';

interface UseWorkflowSwitchReturn {
    commonProps: {
        auth: () => Promise<void>;
        sync: () => void;
        disconnect: () => void;
        onFinish: () => void;
        syncState: SyncState;
    };
    loadingStatus: AsyncStatus;
    integration: Integration;
}

export const useWorkflowSwitch = (
    integrationName: string,
): UseWorkflowSwitchReturn => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const loadingStatus = useAppSelector(selectStatus);
    const integration = useAppSelector(selectIntegration(integrationName));
    const syncState = useAppSelector(selectSyncState(integrationName));
    const uiBaseURL = useAppSelector(selectUIBaseURL);
    const [connected, setConnected] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (loadingStatus === 'init') {
            dispatch(load());
        }
    }, [loadingStatus]);

    const commonProps = {
        auth: () => {
            if (!uiBaseURL) {
                throw new Error(
                    'Confing must be injected by this point. This ' +
                        'is a serious programming error and the application is ' +
                        'in an invalid state.',
                );
            }

            return popup({
                integration: integration.integration,
                uiBaseURL,
            }).then(() => {
                setConnected(true);
            });
        },

        sync: () => {
            dispatch(
                sync({
                    ...integration,
                    id: uuid(),
                    date: new Date().toISOString(),
                }),
            );
        },

        disconnect: () => {
            dispatch(disconnect(integration));
            history.push('/settings/integrations');
        },

        onFinish: () => {
            if (connected) {
                dispatch(heal(integration));
            }
            history.push('/settings/integrations');
        },

        syncState,
    };

    return {
        integration,
        loadingStatus,
        commonProps,
    };
};

interface UseWorkflowArgs {
    healthyState: string;
    syncState: SyncState;
    auth: () => Promise<void>;
    sync: () => void;
    workflow: Parameters<typeof useMachine>[0];
}

interface UseWorkflowReturn<TContext = unknown> {
    matches: (state: string) => boolean;
    onSync: () => void;
    onSignIn: () => void;
    onNext: () => void;
    onBack: () => void;
    context: TContext;
}

/**
 * Used by integration workflows to initiate and control the relevant state
 * machine that dictates the steps and valid movement through the workflow.
 *
 * @param args
 */
export const useWorkflow = <TContext = unknown>(
    args: UseWorkflowArgs,
): UseWorkflowReturn<TContext> => {
    const { healthyState, syncState, auth, sync, workflow } = args;

    const [current, send] = useMachine(workflow);

    React.useEffect(() => {
        if (!current.matches(healthyState)) {
            return;
        }

        if (['queued', 'syncing'].includes(syncState)) {
            send('SYNC');
            return;
        }

        if (!current.matches(`${healthyState}.syncing`)) {
            return;
        }

        if (syncState === 'complete') {
            send('SUCCEED');
            return;
        }

        if (syncState === 'failed') {
            send('FAIL');
        }
    }, [syncState]);

    const onSync = () => {
        send('SYNC');
        sync();
    };

    const onSignIn = () => {
        send('SIGN_IN');
        auth()
            .then(() => send('SUCCEED'))
            .catch(() => send('FAIL'));
    };

    const onNext = () => {
        send('NEXT');
    };

    const onBack = () => {
        send('BACK');
    };

    return {
        matches: (state: string) => current.matches(state),
        onSync,
        onSignIn,
        onNext,
        onBack,
        context: current.context,
    };
};

const POPUP_WIDTH = 500;
const POPUP_HEIGHT = 666;

interface PopupArgs {
    integration: string;
    uiBaseURL: string;
}

/**
 * Used by workflows to display an authentication popup, in kind with other apps
 * that use OAuth to authenticate integrated APIs.
 *
 * @param integration
 */
export const popup = async ({ integration, uiBaseURL }: PopupArgs) => {
    const { url } = await connect(integration);

    const x = window.screenX + (window.outerWidth - POPUP_WIDTH) / 2;
    const y = (window.outerHeight - POPUP_HEIGHT) / 2;

    const features = [
        'popup=true',
        `width=${POPUP_WIDTH}`,
        `height=${POPUP_HEIGHT}`,
        `left=${x}`,
        `top=${y}`,
        'resizable=false',
    ].join(',');

    const popupWindow = window.open(url, '_blank', features);

    if (!popupWindow) {
        throw new Error('Pop-up window could not be opened.');
    }

    return new Promise<void>((resolve, reject) => {
        const intervalId = setInterval(() => {
            if (popupWindow.closed) {
                reject();
                clearInterval(intervalId);
            }
        }, 250);

        window.addEventListener('message', (e) => {
            if (e.origin !== uiBaseURL) {
                return;
            }

            const { data } = e;

            if (data === 'leadflo:integration:done') {
                resolve();
            }

            if (data === 'leadflo:integration:failed') {
                reject();
            }
        });
    });
};

export interface SetupContext {
    failureMode: 'connect' | 'sync';
}

export const setup = createMachine({
    schema: {
        context: {} as SetupContext,
    },

    context: {
        failureMode: 'connect',
    },

    id: 'setup',
    initial: 'instructions',
    states: {
        instructions: {
            on: {
                NEXT: {
                    target: 'signIn',
                },
            },
        },

        signIn: {
            on: {
                BACK: {
                    target: 'instructions',
                },
            },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },
                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#setup.success',
                        },
                        FAIL: {
                            target: '#setup.failed',
                            actions: [
                                assign({
                                    failureMode: () =>
                                        'connect' as 'sync' | 'connect',
                                }),
                            ],
                        },
                    },
                },
            },
        },

        failed: {
            on: {
                BACK: 'instructions',
            },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },
                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#setup.success',
                        },
                        FAIL: {
                            target: '#setup.failed',
                        },
                    },
                },
            },
        },

        success: {
            on: {
                BACK: 'signIn',
            },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SYNC: {
                            target: 'syncing',
                        },
                    },
                },
                syncing: {
                    on: {
                        SUCCEED: {
                            target: 'idle',
                        },

                        FAIL: {
                            target: '#setup.failed',
                            actions: [
                                assign({
                                    failureMode: () =>
                                        'sync' as 'sync' | 'connect',
                                }),
                            ],
                        },
                    },
                },
            },
        },
    },
});

export const manage = createMachine({
    id: 'manage',
    initial: 'healthy',
    states: {
        healthy: {
            initial: 'idle',

            states: {
                idle: {
                    on: {
                        SYNC: {
                            target: 'syncing',
                        },
                    },
                },

                syncing: {
                    on: {
                        SUCCEED: {
                            target: 'idle',
                        },

                        FAIL: {
                            target: '#manage.failed',
                        },
                    },
                },
            },
        },
        failed: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },
                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#manage.healthy',
                        },

                        FAIL: {
                            target: '#manage.failed',
                        },
                    },
                },
            },
        },
    },
});

export const repair = createMachine({
    id: 'repair',
    initial: 'repair',
    states: {
        repair: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },

                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#repair.healthy',
                        },

                        FAIL: {
                            target: '#repair.failed',
                        },
                    },
                },
            },
        },

        healthy: {
            initial: 'idle',

            states: {
                idle: {
                    on: {
                        SYNC: {
                            target: 'syncing',
                        },
                    },
                },

                syncing: {
                    on: {
                        SUCCEED: {
                            target: 'idle',
                        },

                        FAIL: {
                            target: '#repair.failed',
                        },
                    },
                },
            },
        },

        failed: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },
                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#repair.healthy',
                        },

                        FAIL: {
                            target: 'idle',
                        },
                    },
                },
            },
        },
    },
});

export const setupWithoutInstructions = createMachine({
    schema: {
        context: {} as SetupContext,
    },

    context: {
        failureMode: 'connect',
    },

    id: 'setup',
    initial: 'signIn',
    states: {
        signIn: {
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },
                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#setup.success',
                        },
                        FAIL: {
                            target: '#setup.failed',
                            actions: [
                                assign({
                                    failureMode: () =>
                                        'connect' as 'sync' | 'connect',
                                }),
                            ],
                        },
                    },
                },
            },
        },

        failed: {
            on: {
                BACK: 'signIn',
            },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SIGN_IN: {
                            target: 'signingIn',
                        },
                    },
                },
                signingIn: {
                    on: {
                        SUCCEED: {
                            target: '#setup.success',
                        },
                        FAIL: {
                            target: '#setup.failed',
                        },
                    },
                },
            },
        },

        success: {
            on: {
                BACK: 'signIn',
            },
            initial: 'idle',
            states: {
                idle: {
                    on: {
                        SYNC: {
                            target: 'syncing',
                        },
                    },
                },
                syncing: {
                    on: {
                        SUCCEED: {
                            target: 'idle',
                        },

                        FAIL: {
                            target: '#setup.failed',
                            actions: [
                                assign({
                                    failureMode: () =>
                                        'sync' as 'sync' | 'connect',
                                }),
                            ],
                        },
                    },
                },
            },
        },
    },
});
