import React from 'react';
import {
    Container,
    Intro,
    SignInButton,
    StatusIcon
} from '$ui/Integrations/Elements';

interface Props {
    integration: string;
    onSignIn: () => void;
    signingIn?: boolean;
}

export const Repair = ({ integration, onSignIn, signingIn = false }: Props) => {
    return (
        <Container>
            <StatusIcon mode="unhealthy" />
            <Intro title={`Something went wrong with ${integration}`}>
                Sign into {integration} again so we can attempt to repair it
            </Intro>
            <SignInButton
                integration={integration}
                onSignIn={onSignIn}
                signingIn={signingIn}
            />
        </Container>
    );
};
