import React, { Fragment } from 'react';

export interface Origin {
    [key: string]: string;
}

export const bolden = (origin: Origin, search: string[]) => {
    // replace the search terms with <strong>search term</strong>
    // but only if the search term is not already wrapped in <strong> tags
    const newOrigin = { ...origin };
    Object.keys(newOrigin).forEach((key) => {
        const value = newOrigin[key];
        if (typeof value === 'string') {
            newOrigin[key] = value.replace(
                new RegExp(`(${search.join('|')})`, 'gi'),
                (match) => {
                    if (match.startsWith('<strong>')) {
                        return match;
                    }
                    return `<strong>${match}</strong>`;
                }
            );
        } else {
            newOrigin[key] = value;
        }
    });

    // parse the new origin and return a new object with the react nodes
    // https://stackoverflow.com/questions/39758136/how-to-render-html-string-as-real-html/49777535#49777535
    // TODO: need to add support for phone numbers
    const fragmented = {};
    Object.keys(newOrigin).forEach((key) => {
        const value = newOrigin[key];
        if (typeof value === 'string') {
            const fragments = value.split(/(<strong>.*?<\/strong>)/);
            fragmented[key] = fragments.map((fragment, index) => {
                if (fragment.startsWith('<strong>')) {
                    return (
                        <Fragment key={index}>
                            <strong>
                                {fragment.replace(/<\/?strong>/g, '')}
                            </strong>
                        </Fragment>
                    );
                }
                return fragment;
            });
        } else {
            fragmented[key] = value;
        }
    });

    return fragmented;
};
