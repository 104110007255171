import { Heading } from '$ui/Flo/Heading';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

interface Props {
    title: string;
}

/**
 * A stateless UI component that is displayed whenever there are zero actions
 * due within a category.
 */
export const Empty = ({ title }: Props) => {
    return (
        <Container>
            <EmptyIllustration />
            <EmptyHeading level="h2" profile="body">
                Congratulations!
            </EmptyHeading>
            <EmptyHeading level="h6" profile="secondary">
                You have completed all {normalizeTitle(title)}
            </EmptyHeading>
        </Container>
    );
};

const normalizeTitle = (title: string): string => {
    if (title === 'In Discussion') {
        return 'discussions';
    }

    if (title === 'Considering Tx') {
        return 'patients considering tx';
    }

    return title.toLowerCase();
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0;
`;

const EmptyHeading = styled(Heading)`
    margin-top: ${mix.unit({ size: 2 })};
`;

const EmptyIllustration = () => {
    return (
        <svg
            width="167"
            height="208"
            viewBox="0 0 167 208"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.7195 185V143.719V185ZM132.28 143.719V185V143.719ZM132.794 143.719H34.2066C30.2266 143.719 27 140.491 27 136.508C27.0006 132.526 30.2261 129.298 34.2066 129.298H132.794C136.773 129.298 140 132.526 140 136.508C140 140.49 136.774 143.719 132.794 143.719ZM83.5003 42.7596C72.5249 42.7596 63.6298 33.8626 63.6298 22.8801C63.6298 11.9043 72.5249 3 83.5003 3C94.4723 3 103.368 11.9038 103.368 22.8801C103.369 33.8626 94.4729 42.7596 83.5003 42.7596V42.7596Z"
                stroke="#BED8F4"
                strokeWidth="5.24"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M68 74V95.6683L68.0006 95.6773V129M98.9995 129V95.6773L99 95.6683V74.0011L98.9995 129Z"
                stroke="#BED8F4"
                strokeWidth="5.24"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M122.05 184.443H141.125C146.579 184.443 151 188.829 151 194.241V205H17V194.241C17 188.83 21.4203 184.443 26.8735 184.443H45.4575M67.9742 94.2333C67.9742 98.3286 64.6962 101.65 60.6518 101.65C56.6097 101.65 53.9575 98.3286 53.9575 94.2333V74.6027C53.9575 62.6719 63.7037 53 75.7263 53H92.2731C104.296 53 114.042 62.6719 114.042 74.6027V94.2333C114.042 98.3286 111.39 101.65 107.348 101.65C103.304 101.65 100.025 98.3286 100.025 94.2333H67.9742ZM83.9991 128.728V101.592V128.728Z"
                stroke="#BED8F4"
                strokeWidth="5.24"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.42799 87.7223L3 85.2984M85.8919 157.207L90.3219 166.17C90.4816 166.492 90.7173 166.771 91.0089 166.983C91.3004 167.194 91.6391 167.332 91.9957 167.384L101.901 168.821C103.724 169.085 104.453 171.324 103.133 172.609L95.966 179.585C95.7078 179.836 95.5146 180.146 95.4033 180.488C95.2919 180.83 95.2657 181.195 95.3269 181.549L97.0187 191.399C97.3301 193.213 95.4233 194.597 93.7919 193.74L84.9334 189.089C84.6143 188.922 84.2591 188.834 83.8986 188.834C83.538 188.834 83.1828 188.922 82.8637 189.089L74.0052 193.74C72.3738 194.597 70.4676 193.213 70.7784 191.399L72.4708 181.549C72.5318 181.195 72.5054 180.83 72.3938 180.488C72.2823 180.146 72.0889 179.836 71.8305 179.585L64.6633 172.609C63.3438 171.324 64.0721 169.085 65.8959 168.821L75.8003 167.384C76.157 167.332 76.4958 167.194 76.7875 166.983C77.0792 166.771 77.3152 166.492 77.4751 166.17L81.9047 157.207C82.7193 155.557 85.0756 155.557 85.8919 157.207V157.207ZM130.436 50.6289L132.863 48.205L130.436 50.6289ZM143.532 37.5507L145.96 35.1268L143.532 37.5507ZM145.96 50.6289L143.532 48.205L145.96 50.6289ZM132.863 37.5507L130.436 35.1268L132.863 37.5507ZM154.699 95.581L154.048 94.9315L154.699 95.581ZM164 104.869L163.349 104.219L164 104.869ZM154.699 104.219L154.048 104.869L154.699 104.219ZM164 94.9315L163.349 95.581L164 94.9315ZM24.3408 23.6489L23.6904 23L24.3408 23.6489ZM33.6427 32.9367L32.9917 32.2872L33.6427 32.9367ZM24.3408 32.2872L23.6904 32.9367L24.3408 32.2872ZM33.6427 23L32.9917 23.6489L33.6427 23ZM3 100.801L5.42799 98.3766L3 100.801ZM16.0973 87.7223L18.5247 85.2984L16.0973 87.7223ZM18.5247 100.801L16.0973 98.3766L18.5247 100.801Z"
                stroke="#BED8F4"
                strokeWidth="5.24"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
