// from: https://github.com/rayepps/radash/blob/master/src/object.ts
export const pick = <T extends object, TKeys extends keyof T>(
    obj: T,
    keys: TKeys[]
): Pick<T, TKeys> => {
    if (!obj) return {} as Pick<T, TKeys>;
    return keys.reduce((acc, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) acc[key] = obj[key];
        return acc;
    }, {} as Pick<T, TKeys>);
};

/**
 * Given an object and a list of keys, returns a new object without those keys
 */
export const omit = <T extends object, TKeys extends keyof T>(
    obj: T,
    keys: TKeys[]
): Omit<T, TKeys> => {
    return Object.keys(obj).reduce((acc, key) => {
        if (keys.includes(key as TKeys)) {
            return acc;
        }

        acc[key] = obj[key];
        return acc;
    }, {} as Omit<T, TKeys>);
};

/**
 * Inverts the keys and values of an object
 */
export const invert = <T extends object>(
    obj: T
): { [key: string]: keyof T } => {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [value, key])
    );
};

/**
 * Map over all the keys of an object to return
 * a new object
 *
 * From: https://github.com/rayepps/radash/blob/master/src/object.ts
 */
export const mapKeys = <
    TValue,
    TKey extends string | number | symbol,
    TNewKey extends string | number | symbol
>(
    obj: Record<TKey, TValue>,
    mapFunc: (key: TKey, value: TValue) => TNewKey
): Record<TNewKey, TValue> => {
    const keys = Object.keys(obj) as TKey[];
    return keys.reduce((acc, key) => {
        acc[mapFunc(key as TKey, obj[key])] = obj[key];
        return acc;
    }, {} as Record<TNewKey, TValue>);
};

/**
 * Map over all the values of an object to return
 * a new object
 */
export const mapValues = <
    TValue,
    TKey extends string | number | symbol,
    TNewValue
>(
    obj: Record<TKey, TValue>,
    mapFunc: (value: TValue, key: TKey) => TNewValue
): Record<TKey, TNewValue> => {
    const keys = Object.keys(obj) as TKey[];
    return keys.reduce((acc, key) => {
        acc[key] = mapFunc(obj[key], key as TKey);
        return acc;
    }, {} as Record<TKey, TNewValue>);
};
