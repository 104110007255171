import * as React from 'react';
import styled from 'styled-components';
import { Shadow } from '../Shadow';
import { scale } from '../util';
import { applyFieldStyle, applyLabelStyle } from './Field';
import mix from '$ui/Flo/util/mix';

interface Props {
    label: string;
    padLeft?: number;
    labelPortion: number;
    rounded?: boolean;
}

export const LoadingField = (props: Props) => {
    const { label, padLeft, labelPortion, rounded } = props;
    const padding = padLeft;

    return (
        <Field padLeft={padding}>
            <Label
                level="body2"
                profile="body"
                bold
                labelPortion={labelPortion}
            >
                {label}
            </Label>
            <ShadowWrapper labelPortion={labelPortion} padding={padding}>
                <Shadow
                    block
                    width={80}
                    rounded={rounded}
                    height={2}
                    shade="8"
                />
            </ShadowWrapper>
        </Field>
    );
};

const Field = styled.div<{ padLeft?: number; labelPortion?: number }>`
    ${applyFieldStyle};
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.margin({ margin: [0, 0, 1] })};
`;

const Label = styled.span`
    ${applyLabelStyle};
`;

const ShadowWrapper = styled.div<{
    labelPortion?: number;
    padding?: number;
}>`
    height: 14px;
    width: ${({ labelPortion }) => labelPortion && `${100 - labelPortion}%`};
    padding-left: ${({ theme, padding }) => scale(theme, padding)}px;
`;
