import React from 'react';
import {
    WhatsAppManage,
    WhatsAppRepair,
    WhatsAppSetup,
    Loading
} from '$ui/Integrations/Workflows';
import { useWorkflowSwitch } from '$ui/Integrations/Workflows/workflows';

export const ConnectedWhatsApp = () => {
    const { integration, loadingStatus, commonProps } =
        useWorkflowSwitch('whatsapp');

    if (loadingStatus !== 'loaded') {
        return <Loading integration="whatsapp" onFinish={commonProps.onFinish} />;
    }

    if (integration.state === 'healthy' || integration.state === 'disconnecting') {
        return <WhatsAppManage {...commonProps} />;
    }

    if (integration.state === 'unhealthy') {
        return <WhatsAppRepair {...commonProps} />;
    }

    return <WhatsAppSetup {...commonProps} />;
};

export * from './WhatsAppManage';
export * from './WhatsAppRepair';
export * from './WhatsAppSetup';
