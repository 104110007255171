import { RootState } from '$state/store';
import {
    BusinessEvent,
    Comm,
    CommEventUnion,
    CommType,
    Email,
    Events,
    EventType,
    EventUnion,
    FormSubmission,
    Note,
    PhoneCall,
    SMS,
    WhatsApp,
} from '$types';
import {
    Comm as CommV2,
    CommEventUnion as CommEventUnionV2,
    CommType as CommTypeV2,
    Email as EmailV2,
    EventPayload as EventPayloadV2,
    Events as EventsV2,
    EventType as EventTypeV2,
    EventUnion as EventUnionV2,
} from '$types/timeline-v2';
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
    api,
    BusinessEvent as BEvent,
    Call as CallEvent,
    Email as EmailEvent,
    EventPayload,
    FormSubmission as FormSubmissionEvent,
    getTimeline,
    getTimelineV2,
    Note as NoteEvent,
    retryComm as retryCommApi,
    SMS as SMSEvent,
    takeNote as takeNoteApi,
    TimelinePayload,
    TimelineV2Payload,
    WhatsApp as WhatsAppEvent,
} from '$api';
import { commChangedState, unauthenticated } from '../events';
import { communicationOccurred } from '$state/events';
import { ChannelPayload, Channels, EmailPayload, ReplyState, SMSPayload, WhatsAppPayload } from '$state/types';
import { isoDateTime } from '@/utils/date';
import { IconName } from '$ui/Flo/Icon';
import { Hue } from '$ui/Flo/types';
import { featureEnabled } from '$state/queries/features';
import { channelMatch } from '$state/types/timeline';

export enum FetchStatus {
    OK = 'ok',
    ERROR_LOADING = 'error_loading',
    LOADING = 'loading',
}

export enum RefreshStatus {
    WAITING = 'ok',
    REFRESHING = 'refreshing',
    ERROR_REFRESHING = 'error_refreshing',
}

export enum SendStatus {
    SENDING = 'sending',
    SENT = 'sent',
    ERROR_SENDING = 'error_sending',
}

export type TimelineStatus = FetchStatus | RefreshStatus | SendStatus;

export interface Timeline {
    events: Events | EventsV2;
    metadata: {
        status: TimelineStatus;
    };
}

interface TimelineState {
    replies: {
        [patientID: string]: ReplyState;
    };
    timelines:
        | {
              [patientID: string]: Timeline | never;
          }
        | never[];
}

const initialState: TimelineState = {
    timelines: {},
    replies: {},
};

export const fetchTimeline = createAsyncThunk<
    TimelinePayload | TimelineV2Payload,
    string
>('timeline/fetch', async (patientId: string, ThunkAPI) => {
    const state = ThunkAPI.getState() as RootState;
    const timelineV2Enabled = featureEnabled(state, 'timeline-v2');
    if (timelineV2Enabled) {
        return getTimelineV2(patientId);
    }
    return getTimeline(patientId);
});

export const refreshTimeline = createAsyncThunk<
    TimelinePayload | TimelineV2Payload,
    string
>('timeline/refresh', async (patientId: string, ThunkAPI) => {
    const state = ThunkAPI.getState() as RootState;
    const timelineV2Enabled = featureEnabled(state, 'timeline-v2');
    if (timelineV2Enabled) {
        return getTimelineV2(patientId);
    }
    return getTimeline(patientId);
});

export interface TakeNoteArgs {
    id: string;
    patientId: string;
    title?: string;
    content: string;
}

export const takeNote = createAsyncThunk<void, TakeNoteArgs>(
    'timeline/take-note',
    async (args: TakeNoteArgs) => {
        await takeNoteApi(args.patientId, {
            id: args.id,
            title: args.title,
            content: args.content,
        });
    },
);

export interface SendCommArgs {
    id: string;
    channel: CommType;
    patientId: string;
    endSnooze?: boolean;
    payload: ChannelPayload;
    version?: number;
}

export interface SendCommApprovedTemplateArgs extends SendCommArgs {
    template_id: string;
}

export const sendComm = createAsyncThunk<void, SendCommArgs>(
    'timeline/send-comm',
    async (args: SendCommArgs) => {
        await api.post(`/v3/patients/${args.patientId}/communications`, {
            id: args.id,
            channel: args.channel,
            end_snooze: args.endSnooze,
            ...args.payload,
        });
    },
);

export interface RetryCommArgs {
    id: string;
    patientId: string;
    comm: Comm | CommV2;
}

export const retryComm = createAsyncThunk<void, RetryCommArgs>(
    'timeline/retry-comm',
    async (args: RetryCommArgs, thunkAPI) => {
        if (!args.comm.identity) {
            // Comm wasn't accepted by the API, so we need to just retry the
            // original command Identity is generated by the API, so if it
            // isn't present, the API hasn't accepted it
            thunkAPI.dispatch(
                sendComm({
                    id: args.id,
                    patientId: args.patientId,
                    channel: channelMatch(args.comm.comm_type),
                    payload: commPayload(args.comm),
                }),
            );
            return;
        }
        // Comm was accepted, and was persisted, but failed to send. We can
        // retry it
        await retryCommApi(args.patientId, args.id);
    },
);

export const sendCommApproved = createAsyncThunk<
    void,
    SendCommApprovedTemplateArgs
>(
    'timeline/send-pre-approved-comm',
    async (args: SendCommApprovedTemplateArgs) => {
        await api.post(
            `/v3/patients/${args.patientId}/communications/approved`,
            {
                id: args.id,
                channel: args.channel,
                template_id: args.template_id,
                end_snooze: args.endSnooze,
                ...args.payload,
            },
        );
    },
);

/**
 * Maps from a comm to a payload required for sending a comm.
 *
 * Required for retrying comms that failed at the API (rather than failing on
 * the queue).
 *
 * Comms have a different schema than the sendComm action, so we need to
 * convert them
 */
const commPayload = (comm: Comm | CommV2): ChannelPayload => {
    switch (comm.comm_type) {
        case CommType.Email:
            return {
                subject: (comm as Email).email_data.subject,
                body: comm.text_content,
            };
        case CommTypeV2.Email:
            return {
                subject: (comm as EmailV2).subject,
                body: comm.text_content,
            };
        case CommType.SMS:
        case CommTypeV2.SMS:
            return { message: comm.text_content };
    }
    throw new Error(`Comm type ${comm.comm_type} is not retryable`);
};

/**
 * Sets a patient's reply bar state
 */
export const updateReplyState = createAction<{
    patientId: string;
    state: ReplyState;
}>('updateReplyState');

/**
 * Selects a channel for a patient. For some channels (eg, email)
 * will help the user out by prefilling fields (so for email it constructs
 * a subject line).
 */
export const selectChannel = createAction<SelectChannelArgs>('selectChannel');

interface SelectChannelArgs {
    patientId: string;
    channel: Channels;
}

export const toLegacyEvent = (event: EventPayload): EventUnion => {
    switch (event.type) {
        case 'Email':
            return buildEmailEvent(event);
        case 'Note':
            return buildNoteEvent(event);

        case 'Form Submission':
            return buildFormSubmissionEvent(event);

        case 'SMS':
            return buildSMSEvent(event);

        case 'Phone Call':
            return buildPhoneCallEvent(event);

        case 'Business Event':
            return buildBusinessEvent(event);

        case 'WhatsApp':
            return buildWhatsAppEvent(event);

        case 'Attachment':
            return {
                ...event,
                type: EventType.Attachment,
            };
    }
};

export const buildEmailEvent = (event: EmailEvent): Email => {
    return {
        id: event.id,
        datetime: event.datetime,
        type: EventType.Comm,
        comm_type: event.email_type,
        inbound: event.inbound,
        text_content: event.text_content,
        identity: event.id,
        read_at: event.read_at ?? null,
        status: event.inbound ? 'received' : event.status.toLowerCase(),
        retry: {
            active: false,
            count: 0,
        },
        email_data: {
            from_email: event.from_email,
            to_email: event.to_email,
            subject: event.subject,
        },
        attachments: event.attachments,
    };
};

export const buildNoteEvent = (event: NoteEvent): Note => {
    return {
        id: event.id,
        datetime: event.datetime,
        type: EventType.Note,
        content: event.content,
    };
};

export const buildFormSubmissionEvent = (
    event: FormSubmissionEvent,
): FormSubmission => {
    return {
        id: event.id,
        datetime: event.datetime,
        type: EventType.FormSubmission,
        message: event.message !== '' ? JSON.parse(event.message) : '',
        form: event.form,
        read_at: event.read_at ?? null,
    };
};

export const buildSMSEvent = (event: SMSEvent): SMS => {
    return {
        id: event.id,
        datetime: event.datetime,
        type: EventType.Comm,
        comm_type: CommType.SMS,
        identity: event.id,
        inbound: event.inbound,
        text_content: event.text_content,
        read_at: event.read_at ?? null,
        sms_data: {
            from_number: event.from_number,
            to_number: event.to_number,
        },
        status: event.inbound ? 'received' : event.status.toLowerCase(),
        retry: {
            active: false,
            count: 0,
        },
    };
};

export const buildPhoneCallEvent = (event: CallEvent): PhoneCall => {
    return {
        id: event.id,
        datetime: event.datetime,
        type: EventType.Comm,
        comm_type: CommType.PhoneCall,
        inbound: event.inbound,
        read_at: event.read_at ?? null,
        text_content: event.text_content,
        phone_call_data: {
            from_number: event.from_number,
            to_number: event.to_number,
        },
        status: event.inbound ? 'received' : event.status.toLowerCase(),
        retry: {
            active: false,
            count: 0,
        },
    };
};

const buildBusinessEvent = (event: BEvent): BusinessEvent => {
    return {
        id: event.id,
        datetime: event.datetime,
        headline: event.headline,
        description: event.description,
        note: event.note,
        type: EventType.BusinessEvent,
        business_event_type: {
            name: event.business_event_type.name,
            icon: event.business_event_type.icon as IconName,
            color: event.business_event_type.color as Hue,
            tooltip: event.business_event_type.tooltip,
        },
    };
};

export const buildWhatsAppEvent = (event: WhatsAppEvent): WhatsApp => {
    return {
        id: event.id,
        datetime: event.datetime,
        type: EventType.Comm,
        comm_type: CommType.WhatsApp,
        identity: event.id,
        inbound: event.inbound,
        read_at: event.read_at ?? null,
        text_content: event.text_content,
        message_data: {
            from_number: event.from_number,
            to_number: event.to_number,
        },
        status: event.inbound ? 'received' : event.status.toLowerCase(),
        retry: {
            active: false,
            count: 0,
        },
    };
};

export default createReducer(initialState, (builder) => {
    // Fetch timeline
    builder.addCase(fetchTimeline.fulfilled, (state, action) => {
        const version = action.payload.version ?? 1;
        state.timelines[action.meta.arg] = {
            events: handleTimelineEvents(action.payload.items, version),
            metadata: {
                status: FetchStatus.OK,
            },
        };
    });

    builder.addCase(fetchTimeline.rejected, (state, action) => {
        state.timelines[action.meta.arg] = {
            events: [],
            metadata: {
                status: FetchStatus.ERROR_LOADING,
            },
        };
    });

    // Refresh timeline
    builder.addCase(refreshTimeline.pending, (state, action) => {
        state.timelines[action.meta.arg].metadata.status =
            RefreshStatus.REFRESHING;
    });

    builder.addCase(refreshTimeline.fulfilled, (state, action) => {
        const version = action.payload.version ?? 1;
        state.timelines[action.meta.arg] = {
            events: handleTimelineEvents(action.payload.items, version),
            metadata: {
                status: FetchStatus.OK,
            },
        };
    });

    builder.addCase(refreshTimeline.rejected, (state, action) => {
        state.timelines[action.meta.arg].metadata.status =
            RefreshStatus.ERROR_REFRESHING;
    });

    // Take note
    builder.addCase(takeNote.pending, (state, action) => {
        const payload = action.meta.arg;
        const timeline: Timeline = state.timelines[payload.patientId];
        const events = timeline.events as Events;
        const note: Note = {
            id: payload.id,
            title: payload.title ?? '',
            content: payload.content,
            type: EventType.Note,
            datetime: isoDateTime(),
        };
        events.push(note);
    });

    builder.addCase(takeNote.rejected, (state, action) => {
        const payload = action.meta.arg;
        const timeline: Timeline = state.timelines[payload.patientId];
        const note = timeline.events
            .slice()
            .reverse()
            .find((event) => {
                if (event.type !== 'note') {
                    return false;
                }
                return event.id == payload.id;
            }) as Note;

        note.failed = true;
    });

    // Send comm
    builder.addCase(sendComm.pending, (state, action) => {
        const payload = action.meta.arg;
        const version: number = payload.version ?? 1;
        const timeline: Timeline = state.timelines[payload.patientId];
        const comm = findComm(timeline.events.slice().reverse(), payload.id);
        if (comm) {
            comm.status = 'sending';
            comm.retry.active = true;
            return;
        }
        if (version === 2) {
            const bc = buildCommV2(payload);
            (timeline.events as EventsV2).push(bc);
        } else {
            const bc = buildComm(payload);
            (timeline.events as Events).push(bc);
        }
    });

    builder.addCase(sendComm.rejected, (state, action) => {
        const payload = action.meta.arg;
        const timeline: Timeline = state.timelines[payload.patientId];
        if (!timeline) {
            return;
        }
        const comm = findComm(timeline.events.slice().reverse(), payload.id);
        if (comm) {
            comm.status = 'failed';
            comm.retry.active = false;
        }
    });

    builder.addCase(sendComm.fulfilled, (state, action) => {
        const payload = action.meta.arg;
        const timeline: Timeline = state.timelines[payload.patientId];
        if (!timeline) {
            return;
        }
        const comm = findComm(timeline.events.slice().reverse(), payload.id);
        if (comm) {
            comm.status = 'sent';
            comm.retry.active = false;
        }
    });

    builder.addCase(retryComm.pending, (state, action) => {
        const payload = action.meta.arg;
        const timeline: Timeline = state.timelines[payload.patientId];
        if (!timeline) {
            return;
        }
        const comm = findComm(timeline.events.slice().reverse(), payload.id);
        if (comm) {
            comm.status = 'failed';
            comm.retry.active = true;
            comm.retry.count = comm.retry.count + 1;
        }
    });

    builder.addCase(retryComm.fulfilled, (state, action) => {
        const payload = action.meta.arg;
        const timeline: Timeline = state.timelines[payload.patientId];
        if (!timeline) {
            return;
        }
        const comm = findComm(timeline.events.slice().reverse(), payload.id);
        if (comm) {
            comm.status = 'sent';
            comm.retry.active = false;
        }
    });

    builder.addCase(communicationOccurred.pending, (state, action) => {
        const { patientId, communication } = action.meta.arg;
        const timeline: Timeline = state.timelines[patientId];
        if (!timeline) {
            return;
        }
        const events = timeline.events as Events | EventsV2;
        const comm = findComm(events, communication.id);
        if (comm) {
            return;
        }
        // @ts-expect-error - communication type 'never' - [sc-4599]
        events.push(communication);
    });

    builder.addCase(sendCommApproved.pending, (state, action) => {
        const payload = action.meta.arg;
        const version: number = payload.version ?? 1;
        const timeline: Timeline = state.timelines[payload.patientId];
        const comm = findComm(timeline.events.slice().reverse(), payload.id);
        if (comm) {
            comm.status = 'sending';
        }
        if (version === 2) {
            (timeline.events as EventsV2).push(buildCommV2(payload));
        } else {
            (timeline.events as Events).push(buildComm(payload));
        }
    });

    builder.addCase(commChangedState, (state, action) => {
        const { patientId, state: status, communication } = action.payload;
        const timeline: Timeline = state.timelines[patientId];
        if (!timeline?.events) {
            return;
        }
        const comm = findComm(timeline.events, communication.id);
        if (!comm) {
            return;
        }
        if (typeof status === 'object') {
            comm.status = status.name.toLowerCase();
        } else {
            comm.status = status.toLowerCase() as string;
        }
    });

    builder.addCase(updateReplyState, (state, action) => {
        const { patientId, state: replyState } = action.payload;
        state.replies[patientId] = replyState;
    });

    builder.addCase(selectChannel, (state, action) => {
        const { channel, patientId } = action.payload;
        const timeline: Timeline = state.timelines[patientId];
        if (channel.toLowerCase() != Channels.Email || !timeline) {
            state.replies[patientId] = {
                channel,
                fields: {},
            };
            return;
        }

        const lastEmail = timeline.events
            .slice()
            .reverse()
            .find((event) => {
                if (event.type !== 'communication') {
                    return false;
                }
                const comm = event as Comm | CommV2;
                if (
                    ![
                        CommType.Email,
                        CommType.MarketingEmail,
                        CommTypeV2.Email,
                        CommTypeV2.MarketingEmail,
                    ].includes(comm.comm_type)
                ) {
                    return false;
                }

                return comm.inbound;
            }) as Email | EmailV2 | undefined;

        if (!lastEmail) {
            state.replies[patientId] = {
                channel: channel,
                fields: {},
            };
            return;
        }

        const fields = state.replies[patientId]?.fields as EmailPayload;
        const key: string = 'email_data';
        const subject =
            key in lastEmail
                ? (lastEmail as Email).email_data.subject
                : (lastEmail as EmailV2).subject;
        state.replies[patientId] = {
            channel,
            fields: {
                subject: 'Re: ' + subject,
                body: fields?.body,
            },
        };
    });

    builder.addCase(unauthenticated, () => initialState);
});

const handleTimelineEvents = (
    events: EventPayload[] | EventPayloadV2[],
    version: number,
): Events | EventsV2 => {
    if (version === 2) {
        const eventsV2 = events as EventPayloadV2[];
        return eventsV2.map(mapRetry) as EventsV2;
    }
    const legacyEvents = events as EventPayload[];
    return legacyEvents.map(toLegacyEvent) as Events;
};

const mapRetry = (event: EventPayloadV2): EventUnionV2 => {
    if (event.type === EventTypeV2.Comm) {
        return {
            ...event,
            retry: {
                active: false,
                count: 0,
            },
        };
    }

    return event;
};

const findComm = (
    events: Events | EventsV2,
    id: string,
): Comm | CommV2 | undefined => {
    return events.find((event) => {
        if (event.type !== 'communication') {
            return false;
        }
        return event.id === id;
    }) as Comm | CommV2 | undefined;
};

const buildComm = (args: SendCommArgs): CommEventUnion => {
    const base = {
        id: args.id,
        type: EventType.Comm as EventType.Comm, // ?? Typescript complains
        // without the assertion
        inbound: false,
        datetime: isoDateTime(),
        read_at: isoDateTime(),
        status: 'sending',
        retry: {
            active: false,
            count: 0,
        },
    };

    switch (args.channel) {
        case CommType.Email:
            args.payload = args.payload as EmailPayload;
            return {
                ...base,
                ...{
                    text_content: args.payload.body as string,
                    comm_type: CommType.Email,
                    email_data: {
                        subject: args.payload.subject as string,
                        from_email: '',
                        to_email: '',
                    },
                },
            };
        case CommType.SMS:
            args.payload = args.payload as SMSPayload;
            return {
                ...base,
                ...{
                    text_content: args.payload.message,
                    comm_type: CommType.SMS,
                    sms_data: {
                        from_number: {
                            number: '',
                            invalid_reason: null,
                            invalidated_at: null,
                        },
                        to_number: {
                            number: '',
                            invalidated_at: null,
                            invalid_reason: null,
                        },
                    },
                },
            };
        case CommType.WhatsApp:
            args.payload = args.payload as WhatsAppPayload;
            return {
                ...base,
                ...{
                    text_content: args.payload.message,
                    comm_type: CommType.WhatsApp,
                    message_data: {
                        from_number: {
                            number: '',
                            invalid_reason: null,
                            invalidated_at: null,
                        },
                        to_number: {
                            number: '',
                            invalidated_at: null,
                            invalid_reason: null,
                        },
                    },
                },
            };
        default:
            throw new Error(
                `Tried sending non-allowed channel: ${args.channel}`,
            );
    }
};

const buildCommV2 = (args: SendCommArgs): CommEventUnionV2 => {
    const base = {
        id: args.id,
        type: EventTypeV2.Comm as EventTypeV2.Comm,
        inbound: false,
        datetime: isoDateTime(),
        read_at: isoDateTime(),
        status: 'sending',
        retry: {
            active: false,
            count: 0,
        },
    };

    switch (args.channel) {
        case 'Marketing Email':
        case 'Email':
            args.payload = args.payload as EmailPayload;
            return {
                ...base,
                ...{
                    text_content: args.payload.body as string,
                    comm_type: CommTypeV2.Email,
                    subject: args.payload.subject as string,
                    from_email: '',
                    to_email: '',
                },
            };
        case 'SMS':
            args.payload = args.payload as SMSPayload;
            return {
                ...base,
                ...{
                    text_content: args.payload.message,
                    comm_type: CommTypeV2.SMS,
                    from_number: {
                        number: '',
                        invalid_reason: null,
                        invalidated_at: null,
                    },
                    to_number: {
                        number: '',
                        invalidated_at: null,
                        invalid_reason: null,
                    },
                },
            };
        case 'WhatsApp':
            args.payload = args.payload as WhatsAppPayload;
            return {
                ...base,
                ...{
                    text_content: args.payload.message,
                    comm_type: CommTypeV2.WhatsAppMessage,
                    from_number: {
                        number: '',
                        invalid_reason: null,
                        invalidated_at: null,
                    },
                    to_number: {
                        number: '',
                        invalidated_at: null,
                        invalid_reason: null,
                    },
                },
            };
        default:
            throw new Error(
                `Tried sending non-allowed channel: ${args.channel}`,
            );
    }
};
